var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pt-4" },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "grid-view": "" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.tableHeaders,
              items: _vm.orderingStage ? _vm.selectedItems : _vm.items,
              "hide-actions": "",
              loading: _vm.tableLoading,
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function (props) {
                  return [
                    !props.item.isDivider
                      ? _c(
                          "tr",
                          { key: props.item.id, staticClass: "sortableRow" },
                          [
                            _c("td", [
                              _vm._v(" " + _vm._s(props.item.name) + " "),
                            ]),
                            _c("td", { attrs: { color: "primary" } }, [
                              _vm._v(" " + _vm._s(props.item.type) + " "),
                            ]),
                            _c(
                              "td",
                              [
                                !_vm.orderingStage
                                  ? _c("v-checkbox", {
                                      staticClass: "text-xs-center",
                                      attrs: {
                                        value: props.item,
                                        "hide-details": "",
                                      },
                                      model: {
                                        value: _vm.selectedItems,
                                        callback: function ($$v) {
                                          _vm.selectedItems = $$v
                                        },
                                        expression: "selectedItems",
                                      },
                                    })
                                  : _c("div", { staticClass: "sortHandle" }, [
                                      _c(
                                        "svg",
                                        {
                                          staticStyle: { cursor: "move" },
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "24",
                                            height: "24",
                                            viewBox: "0 0 24 24",
                                            fill: "#757575",
                                          },
                                        },
                                        [
                                          _c("circle", {
                                            attrs: {
                                              cy: "6.5",
                                              cx: "9.5",
                                              r: "1.5",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cy: "6.5",
                                              cx: "14.5",
                                              r: "1.5",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cy: "12.5",
                                              cx: "9.5",
                                              r: "1.5",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cy: "12.5",
                                              cx: "14.5",
                                              r: "1.5",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cy: "18.5",
                                              cx: "9.5",
                                              r: "1.5",
                                            },
                                          }),
                                          _c("circle", {
                                            attrs: {
                                              cy: "18.5",
                                              cx: "14.5",
                                              r: "1.5",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "v-container",
                              [
                                _c("v-label", [
                                  _vm._v(_vm._s(props.item.name)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.reportDetail
        ? _c(
            "v-flex",
            {
              attrs: { xs12: "", "text-xs-center": "", "mt-2": "", "mr-0": "" },
            },
            [
              _c("v-label", { key: _vm.reportDetail.reportStatus }, [
                _vm._v(" " + _vm._s(_vm.reportStatusDisplay) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      !_vm.orderingStage
        ? _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                disabled: _vm.selectedItems.length < 1,
              },
              on: { click: _vm.changeToOrderingStage },
            },
            [_vm._v(" Select ")]
          )
        : _vm._e(),
      _vm.orderingStage
        ? _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                flat: "flat",
                disabled: _vm.reportLoading,
              },
              on: { click: _vm.cancelOrderingStage },
            },
            [_vm._v(" Cancel ")]
          )
        : _vm._e(),
      _vm.orderingStage
        ? _c(
            "v-btn",
            {
              staticClass: "build-btn",
              attrs: {
                color: "primary",
                loading: _vm.reportLoading,
                disabled: _vm.disableBuild,
              },
              on: { click: _vm.buildReport },
            },
            [_vm._v(" Build Report ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }