var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.emergencyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency: ")]),
          _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
        ])
      : _vm._e(),
    _vm.getRequestList.length
      ? _c(
          "div",
          _vm._l(_vm.getRequestList, function (req, index) {
            return _c("div", { key: index, staticClass: "mb-1" }, [
              _c("label", [_vm._v(_vm._s(req.text))]),
              _c("span", [
                _vm._v(" " + _vm._s(req.value) + " "),
                req.text === _vm.headerCost
                  ? _c("span", [_vm._v("£")])
                  : _vm._e(),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Engineer Request Detail")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }