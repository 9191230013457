var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class:
        "num-call-announcing sys-call-info pa-2 " +
        (_vm.customColor ? _vm.customColor : _vm.colorClass),
    },
    [
      _vm.availableWorkers >= 0 && _vm.showAvailableWorkers
        ? _c("span", { staticClass: "badge" }, [
            _vm._v(" " + _vm._s(_vm.availableWorkers) + " "),
          ])
        : _vm._e(),
      _c("label", { attrs: { for: "" } }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "span",
        { staticClass: "text-xs-right sys-call-content" },
        [
          _c(
            "span",
            { staticClass: "num" },
            [
              _vm._v(" " + _vm._s(_vm.value) + " "),
              _vm.showArrow && _vm.arrowDirection
                ? _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" " + _vm._s("arrow_" + _vm.arrowDirection) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.duration
            ? _c("span", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.duration)),
              ])
            : _vm._e(),
          _c("DashboardChart", {
            attrs: {
              "chart-data": _vm.chartData,
              "max-value": _vm.chartMaxValue,
              "chart-type": _vm.chartTypeEnum[_vm.chartTypeEnum.Bar],
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }