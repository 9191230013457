var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "twilio-dashboard" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-md": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "calls-info": "" } },
                _vm._l(Object.keys(_vm.topTiles), function (key) {
                  return _c(
                    "DashboardTopTile",
                    _vm._b(
                      { key: key, attrs: { "dashboard-tile-view": "Twilio" } },
                      "DashboardTopTile",
                      _vm.topTiles[key],
                      false
                    )
                  )
                }),
                1
              ),
              _c(
                "v-layout",
                {
                  staticClass: "system-info syetsm-dashboard",
                  attrs: { wrap: "" },
                },
                _vm._l(Object.keys(_vm.panels), function (key) {
                  return _c(
                    "DashboardPanel",
                    _vm._b(
                      { key: key, attrs: { "flex-class": "xs12 md6 lg3" } },
                      "DashboardPanel",
                      _vm.panels[key],
                      false
                    )
                  )
                }),
                1
              ),
              _c(
                "v-layout",
                { staticClass: "team-info mt-3", attrs: { wrap: "" } },
                _vm._l(Object.keys(_vm.teams), function (key) {
                  return _c("DashboardTeam", {
                    key: key,
                    attrs: { team: _vm.teams[key] },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }