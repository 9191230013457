var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.job
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "pr-1",
                  class: _vm.job.customerContactPreferenceValue
                    ? "icon-space"
                    : "",
                },
                [
                  _c("h4", [
                    _vm._v(" Job: "),
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: { href: "Javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            _vm.$emit(
                              "redirectToJob",
                              _vm.isJobTypeHE() ? _vm.job.jobId : _vm.job.id
                            )
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.titleGenerator(_vm.job)) + " ")]
                    ),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "h4",
                    { staticClass: "job-code grey--text text--darken-1" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.job.addressLine3
                              ? _vm.job.addressLine3 + ", " + _vm.job.postcode
                              : _vm.job.postcode
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.job.customerContactPreferenceValue &&
                  _vm.job.customerContactPreference
                    ? _c(
                        "span",
                        { staticClass: "preference-icon" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "ma-0 ml-1 btn-contactPreference",
                                              attrs: {
                                                icon: "",
                                                primary: "",
                                                small: "",
                                              },
                                              on: {
                                                click:
                                                  _vm.onContactPreferenceIconClick,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "md-icon",
                                                attrs: { color: "primary" },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getCustomerContactPreference(
                                                        _vm.job
                                                          .customerContactPreference
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3526907584
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.job.customerContactPreferenceValue)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "py-2" },
                [_vm._t("JobCardDetails")],
                2
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "py-1",
                  class: _vm.job.dashboardDisplayData
                    ? _vm.job.dashboardDisplayData.etaColor
                    : "grey",
                },
                [
                  _c(
                    "div",
                    { staticClass: "eta-info" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "white--text", attrs: { small: "" } },
                        [_vm._v("date_range")]
                      ),
                      _c("span", [
                        _vm._v(" ETA: "),
                        _c("span", { staticClass: "eta-date-from" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getETADateFormat(_vm.job.etaFrom)) +
                              " "
                          ),
                        ]),
                        _c("span", { staticClass: "eta-date-to" }, [
                          _c("span", [_vm._v(" To")]),
                          _vm._v(
                            " " +
                              _vm._s(_vm.getETADateFormat(_vm.job.etaTo)) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showWIPTemplateListDialog
        ? _c("WIPTemplateList", {
            ref: "refWIPTemplateList",
            attrs: {
              "job-id": _vm.job.jobId,
              "contractor-appointed-detail-id": _vm.job.id,
            },
            on: {
              closeWIPTemplateDialog: function ($event) {
                _vm.showWIPTemplateListDialog = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }