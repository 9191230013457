var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item
      ? _c("div", [
          _vm.item && _vm.item.periodListeningTest
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [
                  _vm._v(
                    "Period listening test carried out with all available water services within property switched off: "
                  ),
                ]),
                _c("span", [_vm._v(_vm._s(_vm.item.periodListeningTest))]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "mb-1" }, [
            _c("label", [
              _vm._v(
                "Any indication of leak detected by way of sound with stick placed on stop cock?: "
              ),
            ]),
            _c("span", [
              _vm._v(_vm._s(_vm.item.isLeakDetected ? "Yes" : "No")),
            ]),
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _c("label", [
              _vm._v(
                " When taps are used, toilets flushed or any other water demand appliance used,is there a noticeable flushing noise heard from the listening stick?: "
              ),
            ]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.item.isNoiseHeardFromListeningStick ? "Yes" : "No")
              ),
            ]),
          ]),
          _vm.item && _vm.item.status
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Status: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.status))]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Save Water Mains Test")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }