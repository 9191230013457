var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "close-on-content-click": true,
        "nudge-width": 200,
        "content-class": "auth-header",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "", large: "" } }, on),
                [
                  _c("v-avatar", { attrs: { size: "32px" } }, [
                    _c("img", {
                      attrs: {
                        src: _vm.userIconUrl,
                        alt: _vm.userName,
                        color: "primary",
                      },
                    }),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        { staticClass: "login-menu" },
        [
          _c("v-list", { staticClass: "clearfix" }, [
            _c("div", { staticClass: "profile-outer-block" }, [
              _c("div", { staticClass: "profile-detail-block" }, [
                _c("div", { staticClass: "profile-img-block" }, [
                  _c(
                    "a",
                    { attrs: { href: "Javascript:void(0)", title: "Edit" } },
                    [
                      _c(
                        "v-badge",
                        { attrs: { color: "primary", overlap: "" } },
                        [
                          _vm.isOnline
                            ? _c(
                                "v-icon",
                                {
                                  attrs: { slot: "badge", dark: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.pickFile()
                                    },
                                  },
                                  slot: "badge",
                                },
                                [_vm._v("edit")]
                              )
                            : _vm._e(),
                          _c("v-avatar", { attrs: { size: "85px" } }, [
                            _c("img", {
                              attrs: {
                                src: _vm.userIconUrl,
                                alt: _vm.userName,
                                color: "primary",
                              },
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: {
                                    rules: {
                                      required: false,
                                      image: true,
                                      size: _vm.LogoSize,
                                      mimes: [
                                        "image/jpeg",
                                        "image/jpg",
                                        "image/png",
                                      ],
                                      ext: ["jpg", "jpeg", "png"],
                                    },
                                  },
                                  expression:
                                    "{\n                      rules: {\n                        required: false,\n                        image: true,\n                        size: LogoSize,\n                        mimes: ['image/jpeg', 'image/jpg', 'image/png'],\n                        ext: ['jpg', 'jpeg', 'png'],\n                      },\n                    }",
                                },
                              ],
                              ref: "image",
                              staticStyle: { display: "none" },
                              attrs: {
                                type: "file",
                                accept: "image/*",
                                name: "image",
                              },
                              on: { change: _vm.onFilePicked },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.imageValidationMessage(_vm.errors)) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "profile-right" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.userName))]),
                  _c("h5", { staticClass: "secondary--text" }, [
                    _vm._v(_vm._s(_vm.userRoleDescription)),
                  ]),
                  _c(
                    "a",
                    {
                      style: _vm.userEmailColourStyle,
                      attrs: { href: _vm.mailToLink },
                      on: {
                        mouseenter: function ($event) {
                          return _vm.updateHoverState(true)
                        },
                        mouseleave: function ($event) {
                          return _vm.updateHoverState(false)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.userEmail) + " ")]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("v-divider", { attrs: { light: "" } }),
          _vm.isOnline
            ? _c(
                "v-card-actions",
                { staticClass: "bottom-panel" },
                [
                  _vm.isUserRoleContractor
                    ? _c(
                        "router-link",
                        { attrs: { to: "contractorProfile" } },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("settings"),
                          ]),
                          _vm._v("  Settings "),
                        ]
                      )
                    : _c("v-btn", { attrs: { flat: "" } }, [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("settings"),
                        ]),
                        _vm._v("  Settings "),
                      ]),
                  _vm.isLoggedIn
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            disabled: _vm.loggingOut,
                            loading: _vm.loggingOut,
                          },
                          on: { click: _vm.logout },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("power_settings_new"),
                          ]),
                          _vm._v("  Logout "),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showErrorModal
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "400",
                "content-class": "info-dialog",
                persistent: "",
              },
              model: {
                value: _vm.showErrorModal,
                callback: function ($$v) {
                  _vm.showErrorModal = $$v
                },
                expression: "showErrorModal",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", {}, [
                    _c("h3", { staticClass: "grey--text text--darken-3" }, [
                      _vm._v(_vm._s(_vm.showErrorModalText)),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "text-center pa-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-auto",
                          attrs: { color: "primary" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("Logout")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }