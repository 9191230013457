var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pb-2 component-container" },
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoaded,
            expression: "!isLoaded",
          },
        ],
        style: _vm.getLoaderStyle(70),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 up-card" },
        [
          _c(
            "v-layout",
            { staticClass: "clt-avb-card", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h3", { staticClass: "pt-3 pb-1 ml-4" }, [
                    _vm._v("Confirm Booking Details"),
                  ]),
                  _c("v-divider", { staticClass: "mb-2 mt-2" }),
                  _c(
                    "v-container",
                    {
                      staticClass: "pb-0",
                      attrs: { fluid: "", "grid-list-md": "" },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "startPicker",
                                        staticClass: "start-datepicker",
                                        attrs: {
                                          "is-validation-required": true,
                                          "place-holder-text":
                                            "Start Date & Time",
                                          "date-time": _vm.confModel.etaFrom,
                                          "min-date": _vm.minDateFrom,
                                          "min-time": _vm.minTimeFrom,
                                          "allowed-minutes-step": true,
                                          disabled: _vm.isContractorAcepted,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.confModel,
                                              "etaFrom",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.confModel,
                                              "etaFrom",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "endPicker",
                                        staticClass: "end-datepicker",
                                        attrs: {
                                          "is-validation-required": true,
                                          "place-holder-text":
                                            "End Date & Time",
                                          "date-time": _vm.confModel.etaTo,
                                          "min-date": _vm.minDateTo,
                                          "min-time": _vm.minTimeTo,
                                          "allowed-minutes-step": true,
                                          disabled: _vm.isContractorAcepted,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.confModel,
                                              "etaTo",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.confModel,
                                              "etaTo",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "pt-0 pb-0",
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        ref: "engineerPicker",
                                        staticClass: "required engineer-picker",
                                        attrs: {
                                          "is-validation-required": true,
                                          items: _vm.engineerList,
                                          label: "Select Engineer",
                                          "item-text": "name",
                                          "item-value": "id",
                                          "menu-props": { maxHeight: "auto" },
                                          chips: "",
                                          required: "",
                                          "data-vv-scope":
                                            "confModel.engineerId",
                                          "data-vv-name": "Select Engineer",
                                          "error-messages":
                                            _vm.$validator.errors.collect(
                                              "Select Engineer"
                                            ),
                                          disabled: _vm.isContractorAcepted,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "selection",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    key: JSON.stringify(
                                                      data.item
                                                    ),
                                                    staticClass:
                                                      "chip--select-multi",
                                                    attrs: {
                                                      selected: data.selected,
                                                    },
                                                  },
                                                  [
                                                    _c("v-avatar", [
                                                      data.item
                                                        .profileImageThumbnailUrl
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: data.item
                                                                .profileImageThumbnailUrl,
                                                            },
                                                          })
                                                        : _c("img", {
                                                            attrs: {
                                                              src: "/img/unknownuser.png",
                                                            },
                                                          }),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(data.item.name) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                typeof data.item !== "object"
                                                  ? [
                                                      _c(
                                                        "v-list-tile-content",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              data.name
                                                            ),
                                                          },
                                                        }
                                                      ),
                                                    ]
                                                  : [
                                                      _c("v-list-tile-avatar", [
                                                        data.item
                                                          .profileImageThumbnailUrl
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: data.item
                                                                  .profileImageThumbnailUrl,
                                                              },
                                                            })
                                                          : _c("img", {
                                                              attrs: {
                                                                src: "/img/unknownuser.png",
                                                              },
                                                            }),
                                                      ]),
                                                      _c(
                                                        "v-list-tile-content",
                                                        [
                                                          _c(
                                                            "v-list-tile-title",
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .name
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.confModel.engineerId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.confModel,
                                              "engineerId",
                                              $$v
                                            )
                                          },
                                          expression: "confModel.engineerId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.getRepairEstimates &&
                      _vm.getRepairEstimates.length > 0
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "font-weight-bold ma-1" },
                                [_vm._v("Repair Estimation Details")]
                              ),
                              _vm.repairEstimateItems.length > 0
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mt-1": "" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass:
                                          "gridView elevation-1 estimate-detail-table",
                                        attrs: {
                                          headers: _vm.repairEstimateHeaders,
                                          items: _vm.repairEstimateItems,
                                          "item-key": "estimateId",
                                          "hide-actions": "",
                                          "select-all": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "items",
                                              fn: function (props) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      attrs: {
                                                        active: props.selected,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-checkbox", {
                                                            staticClass:
                                                              "select-visit-chkbox",
                                                            attrs: {
                                                              disabled:
                                                                _vm.isContractorAcepted,
                                                              color: "primary",
                                                              "hide-details":
                                                                "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                props.selected =
                                                                  !props.selected
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                props.selected,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    props,
                                                                    "selected",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "props.selected",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .estimateName
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getFormatedCurrency(
                                                                  props.item
                                                                    .price
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4231317165
                                        ),
                                        model: {
                                          value: _vm.selectedEstimates,
                                          callback: function ($$v) {
                                            _vm.selectedEstimates = $$v
                                          },
                                          expression: "selectedEstimates",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("DelayCodes", {
                    staticClass: "mr-2 ml-2 delay-component",
                    attrs: {
                      "has-delay": _vm.confModel.hasDelay,
                      "delay-code": _vm.confModel.delayCode,
                    },
                    on: { updateDelayCode: _vm.onDelayCodeChange },
                  }),
                ],
                1
              ),
              !_vm.isContractorAcepted
                ? _c(
                    "v-flex",
                    { staticClass: "text-xs-right", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "xs12 mt-3 elevation-1 submit-button",
                          attrs: {
                            disabled: _vm.updateBtnDisabled,
                            color: "primary",
                          },
                          on: { click: _vm.handleBooking },
                        },
                        [_vm._v(" Confirm Booking ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }