var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "question-title-desc" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { staticClass: "mb-2", attrs: { xs12: "" } }, [
            _vm.claimPictures && !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "avtar-container" },
                  [
                    _vm._l(_vm.claimPictures, function (item, i) {
                      return [
                        i < 2
                          ? _c(
                              "v-avatar",
                              { key: i, staticClass: "mr-2 card-images" },
                              [
                                _c("img", {
                                  attrs: { src: item.fileURL, alt: item.title },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                    _c(
                      "v-avatar",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.claimPictures.length > 2,
                            expression: "claimPictures.length > 2",
                          },
                        ],
                      },
                      [
                        _c("span", { staticClass: "total-images" }, [
                          _vm._v("+" + _vm._s(_vm.claimPictures.length - 2)),
                        ]),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.loading
              ? _c(
                  "div",
                  { staticClass: "pa-2" },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "grey" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }