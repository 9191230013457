var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "dashboard" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-container",
                    {
                      attrs: {
                        fluid: "",
                        "grid-list-lg": "",
                        "pt-0": "",
                        "pa-0": "",
                      },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "calls-info": "" } },
                        _vm._l(
                          Object.keys(_vm.dashboardComplaintTopTiles),
                          function (key) {
                            return _c(
                              "DashboardTopTile",
                              _vm._b(
                                {
                                  key: key,
                                  staticClass: "complaint-tracker-chart",
                                  attrs: {
                                    "dashboard-tile-view": "ComplaintTracker",
                                  },
                                },
                                "DashboardTopTile",
                                _vm.dashboardComplaintTopTiles[key],
                                false
                              )
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.getComplaint.length
                ? _c(
                    "v-flex",
                    { staticClass: "gridView mt-4" },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "pr-2": "",
                            "pl-0": "",
                            "pt-1": "",
                          },
                        },
                        [
                          _c("OutstandingComplaintsTable", {
                            attrs: {
                              "table-name": "outstandingComplaintsDashboard",
                              complaints: _vm.getComplaint,
                              "loading-complaint-list":
                                _vm.loadingComplaintList,
                              ready: _vm.ready,
                              "complaint-dashboard-selected-job-type":
                                _vm.complaintDashboardSelectedJobType,
                            },
                            on: {
                              "update:complaintDashboardSelectedJobType":
                                function ($event) {
                                  _vm.complaintDashboardSelectedJobType = $event
                                },
                              "update:complaint-dashboard-selected-job-type":
                                function ($event) {
                                  _vm.complaintDashboardSelectedJobType = $event
                                },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }