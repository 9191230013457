var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "heading" }, [
                      _c("span", { staticClass: "teal--text" }, [
                        _vm._v("Other Fees Detail"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { staticClass: "other-fee-detail", attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        staticClass: "prepend-icon-image",
                        attrs: {
                          label: "BER Amount",
                          "prepend-icon": "close",
                          name: "BERAmount",
                          maxlength: "10",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.otheFeesDetail.berAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.otheFeesDetail,
                              "berAmount",
                              _vm._n($$v)
                            )
                          },
                          expression: "otheFeesDetail.berAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        staticClass: "prepend-icon-image",
                        attrs: {
                          label: "Alternative Accommodation Amount",
                          "prepend-icon": "close",
                          name: "AlternativeAccommodationAmount",
                          maxlength: "10",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value:
                            _vm.otheFeesDetail.alternativeAccommodationAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.otheFeesDetail,
                              "alternativeAccommodationAmount",
                              _vm._n($$v)
                            )
                          },
                          expression:
                            "otheFeesDetail.alternativeAccommodationAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        staticClass: "prepend-icon-image",
                        attrs: {
                          label: "Temporary Heaters Amount",
                          "prepend-icon": "close",
                          name: "TemporaryHeatersAmount",
                          maxlength: "10",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.otheFeesDetail.temporaryHeatersAmount,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.otheFeesDetail,
                              "temporaryHeatersAmount",
                              _vm._n($$v)
                            )
                          },
                          expression: "otheFeesDetail.temporaryHeatersAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Notes",
                          rows: "5",
                          name: "Notes",
                          required: "",
                          "data-vv-scope": "otherFeesScope",
                          "data-vv-name": "Notes",
                          "error-messages": _vm.errors.collect("Notes"),
                        },
                        model: {
                          value: _vm.otheFeesDetail.notes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.otheFeesDetail,
                              "notes",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "otheFeesDetail.notes",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "text-xs-right" },
                      [
                        !_vm.isJobCompletedOrCancelled
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mr-0 save-otherFeeDetail",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.isLoading,
                                  loading: _vm.isLoading,
                                },
                                on: { click: _vm.saveOtherFeesDetail },
                              },
                              [_vm._v(" Submit ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }