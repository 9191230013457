var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("iframe", {
    style: `height: ${_vm.frameHeight}px`,
    attrs: {
      title: "Trial Pit Drawing Wrapper",
      src: _vm.targetUrl,
      scrolling: "no",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }