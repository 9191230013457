var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "div",
        [
          _vm._m(0),
          _vm._l(_vm.item.pitTests, function (pitTestItem, index) {
            return _c(
              "div",
              { key: index },
              [
                pitTestItem.name
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Test Name: ")]),
                      _c("span", [_vm._v(_vm._s(pitTestItem.name))]),
                    ])
                  : _vm._e(),
                pitTestItem.quantity
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Quantity: ")]),
                      _c("span", [_vm._v(_vm._s(pitTestItem.quantity))]),
                    ])
                  : _vm._e(),
                pitTestItem.notes
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Test Notes: ")]),
                      _c("span", [_vm._v(_vm._s(pitTestItem.notes))]),
                    ])
                  : _vm._e(),
                _c("v-divider", { staticClass: "mb-1" }),
              ],
              1
            )
          }),
          _vm.item.notes
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Pit Notes: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.notes))]),
              ])
            : _vm._e(),
          _vm.item.status
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Status: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getStatusName(_vm.item.status))),
                ]),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update Trial Pit Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }