var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-stepper",
    {
      staticClass: "elevation-0",
      attrs: { "non-linear": "" },
      model: {
        value: _vm.stepIndex,
        callback: function ($$v) {
          _vm.stepIndex = $$v
        },
        expression: "stepIndex",
      },
    },
    [
      _c(
        "v-stepper-header",
        [
          _c(
            "v-stepper-step",
            {
              staticClass: "enquiry-step1",
              attrs: {
                step: 1,
                editable: _vm.stepIndex > 1,
                complete: _vm.step1validated,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(1)
                },
              },
            },
            [_vm._v(" Policy Details ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              staticClass: "enquiry-step2",
              attrs: {
                step: 2,
                editable:
                  _vm.step1validated &&
                  _vm.stepIndex > 2 &&
                  !_vm.isPolicyEnquiryToggleOn &&
                  !_vm.isHelpLinePolicy,
                complete: _vm.step2validated,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(2)
                },
              },
            },
            [_vm._v(" Emergency Details ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              staticClass: "enquiry-step3",
              attrs: {
                step: 3,
                editable:
                  _vm.step1validated && _vm.step2validated && _vm.stepIndex > 3,
                complete: _vm.wizardSubmitStatus === "submitted",
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(3)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.getStep3Header) + " ")]
          ),
        ],
        1
      ),
      _c(
        "v-stepper-content",
        { attrs: { step: "1" } },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      !_vm.policySelected &&
                      !_vm.manualPick &&
                      _vm.isOnline &&
                      (!_vm.isCustomPolicySearchFeatureFlagEnabled ||
                        !_vm.useCustomSearch)
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mapping-alert": "" } },
                                [
                                  _c("v-autocomplete", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    ref: "cmbPolicySearch",
                                    staticClass: "required",
                                    attrs: {
                                      id: "cmbPolicySearch",
                                      tabindex: "0",
                                      label: _vm.policySearchLabel,
                                      loading: _vm.isOmnisearchRunning,
                                      items: _vm.searchResults,
                                      "browser-autocomplete": "off",
                                      "search-input": _vm.searchValue,
                                      "item-text": "displayValue",
                                      "item-value": "id",
                                      autofocus: "",
                                      filter: _vm.customFilter,
                                      disabled: _vm.policySearchDisabled,
                                      required: "",
                                      "data-vv-scope": _vm.step1formRef,
                                      "data-vv-name": "Search policy",
                                      "error-messages":
                                        _vm.errors.collect("Search policy"),
                                      "data-vv-validate-on": "input",
                                    },
                                    on: {
                                      "update:searchInput": function ($event) {
                                        _vm.searchValue = $event
                                      },
                                      "update:search-input": function ($event) {
                                        _vm.searchValue = $event
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c("v-list-tile-title", {
                                                    class:
                                                      data.item.id ===
                                                      "manualSelect"
                                                        ? "starred-outer secondary--text"
                                                        : "",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.sanitizeHTML(
                                                          data.item.id ===
                                                            "manualSelect"
                                                            ? "Select Policy Manually"
                                                            : _vm.highlightPhrase(
                                                                data.item
                                                                  .displayValue
                                                              )
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3274474224
                                    ),
                                    model: {
                                      value: _vm.selectedSearchId,
                                      callback: function ($$v) {
                                        _vm.selectedSearchId = $$v
                                      },
                                      expression: "selectedSearchId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("v-layout", { attrs: { wrap: "" } }, [
                            _vm.isCustomPolicySearchFeatureFlagEnabled &&
                            _vm.useCustomSearch
                              ? _c(
                                  "div",
                                  [
                                    _c("CustomPolicySearch", {
                                      ref: "customPolicySearchRef",
                                      on: {
                                        assumedCoverPolicySelected: function (
                                          $event
                                        ) {
                                          return _vm.setAssumedCoverPolicy(
                                            $event
                                          )
                                        },
                                        policySelected: function ($event) {
                                          return _vm.retrieveCustomPolicy(
                                            $event.clientId,
                                            $event.path
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _vm.getPreviouslySearchedValue
                                      ? _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs12: "",
                                              grey: "",
                                              "lighten-4": "",
                                              "grey--text": "",
                                              "pt-3": "",
                                              "ml-2": "",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getPreviouslySearchedValue
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      {
                                        staticStyle: {
                                          "padding-left": "0px",
                                          "padding-right": "0px",
                                        },
                                        attrs: {
                                          xs12: "",
                                          "py-0": "",
                                          "mb-2": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          [
                                            (_vm.isOnline &&
                                              _vm.manualPick &&
                                              _vm.policies) ||
                                            (!_vm.isOnline &&
                                              _vm.offlinePolicies)
                                              ? _c("PolicySelector", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  ref: "policySelectorRef",
                                                  staticClass: "mb-4",
                                                  staticStyle: {
                                                    "padding-top": "10px",
                                                    "padding-bottom": "0px",
                                                    "padding-left": "0px",
                                                  },
                                                  attrs: {
                                                    id: "policySelector",
                                                    type: "number",
                                                    required: "",
                                                    "policy-tree": _vm.isOnline
                                                      ? _vm.policies
                                                      : _vm.offlinePolicies,
                                                    "error-messages":
                                                      _vm.policySelected
                                                        ? []
                                                        : [
                                                            "A policy must be selected",
                                                          ],
                                                    "data-vv-scope":
                                                      _vm.step1formRef,
                                                    "data-vv-name":
                                                      "Selected Policy",
                                                    "selected-policy":
                                                      _vm.selectedPolicy,
                                                    "pre-selected-policy":
                                                      _vm.preSelectedPolicyScheduleId,
                                                    "bind-only-starred-policies":
                                                      _vm.isOnline,
                                                  },
                                                  on: {
                                                    "update:selectedPolicy":
                                                      _vm.updatePolicySelection,
                                                  },
                                                })
                                              : _c("v-select", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "selectedPolicyDropdown",
                                                    disabled: true,
                                                    type: "number",
                                                    placeholder:
                                                      _vm.claimModel
                                                        .policyScheme,
                                                    "data-vv-name":
                                                      "Selected Policy",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.claimModel
                                                        .policyScheduleId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.claimModel,
                                                        "policyScheduleId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "claimModel.policyScheduleId",
                                                  },
                                                }),
                                            _c(
                                              "v-alert",
                                              {
                                                staticClass: "warning-mapping",
                                                attrs: {
                                                  color: "warning",
                                                  icon: "priority_high",
                                                  dismissible: "",
                                                },
                                                model: {
                                                  value: _vm.showDisclaimer,
                                                  callback: function ($$v) {
                                                    _vm.showDisclaimer = $$v
                                                  },
                                                  expression: "showDisclaimer",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " DISCLAIMER: " +
                                                    _vm._s(
                                                      _vm.disclaimerMessage
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isCustomPolicySearchFeatureFlagEnabled &&
                                    _vm.policyValidationStatuses.length
                                      ? _c(
                                          "v-flex",
                                          [
                                            _c(
                                              "v-alert",
                                              {
                                                staticClass: "mb-5 mt-0 mx-2",
                                                attrs: {
                                                  color: "warning",
                                                  icon: "priority_high",
                                                  outline: "",
                                                },
                                                model: {
                                                  value: _vm.policySelected,
                                                  callback: function ($$v) {
                                                    _vm.policySelected = $$v
                                                  },
                                                  expression: "policySelected",
                                                },
                                              },
                                              _vm._l(
                                                _vm.policyValidationStatuses,
                                                function (status, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `validationStatus-${index}`,
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            name: "PolicyValidationErrorMessage",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                status.message
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "py-0": "" } },
                                      [
                                        _c("CustomerAddress", {
                                          ref: "addCustomerAddress",
                                          attrs: {
                                            "passed-client-title":
                                              _vm.getClientTitle,
                                            "passed-first-name":
                                              _vm.getFirstName,
                                            "passed-last-name": _vm.getLastName,
                                            "passed-policy-number":
                                              _vm.getPolicyNumber,
                                            "passed-secondary-client-title":
                                              _vm.getSecondaryClientTitle,
                                            "passed-secondary-first-name":
                                              _vm.getSecondaryFirstName,
                                            "passed-secondary-last-name":
                                              _vm.getSecondaryLastName,
                                            "passed-address-line1":
                                              _vm.getAddressLine1,
                                            "passed-address-line2":
                                              _vm.getAddressLine2,
                                            "passed-address-line3":
                                              _vm.getAddressLine3,
                                            "passed-address-line4":
                                              _vm.getAddressLine4,
                                            "passed-post-code": _vm.getPostCode,
                                            "is-display-policy-number": true,
                                            "customer-prefilled-details":
                                              _vm.customerPrefilledDetails,
                                            "passed-policy-number-not-present-reason":
                                              _vm.getPolicyNumberNotPresentReason,
                                            "is-h-e-job": true,
                                            "is-assumed-cover-policy":
                                              !_vm.policyValidation.isCovered,
                                          },
                                          on: {
                                            "update:passedClientTitle":
                                              function ($event) {
                                                _vm.getClientTitle = $event
                                              },
                                            "update:passed-client-title":
                                              function ($event) {
                                                _vm.getClientTitle = $event
                                              },
                                            "update:passedFirstName": function (
                                              $event
                                            ) {
                                              _vm.getFirstName = $event
                                            },
                                            "update:passed-first-name":
                                              function ($event) {
                                                _vm.getFirstName = $event
                                              },
                                            "update:passedLastName": function (
                                              $event
                                            ) {
                                              _vm.getLastName = $event
                                            },
                                            "update:passed-last-name":
                                              function ($event) {
                                                _vm.getLastName = $event
                                              },
                                            "update:passedPolicyNumber":
                                              function ($event) {
                                                _vm.getPolicyNumber = $event
                                              },
                                            "update:passed-policy-number":
                                              function ($event) {
                                                _vm.getPolicyNumber = $event
                                              },
                                            "update:passedSecondaryClientTitle":
                                              function ($event) {
                                                _vm.getSecondaryClientTitle =
                                                  $event
                                              },
                                            "update:passed-secondary-client-title":
                                              function ($event) {
                                                _vm.getSecondaryClientTitle =
                                                  $event
                                              },
                                            "update:passedSecondaryFirstName":
                                              function ($event) {
                                                _vm.getSecondaryFirstName =
                                                  $event
                                              },
                                            "update:passed-secondary-first-name":
                                              function ($event) {
                                                _vm.getSecondaryFirstName =
                                                  $event
                                              },
                                            "update:passedSecondaryLastName":
                                              function ($event) {
                                                _vm.getSecondaryLastName =
                                                  $event
                                              },
                                            "update:passed-secondary-last-name":
                                              function ($event) {
                                                _vm.getSecondaryLastName =
                                                  $event
                                              },
                                            "update:passedAddressLine1":
                                              function ($event) {
                                                _vm.getAddressLine1 = $event
                                              },
                                            "update:passed-address-line1":
                                              function ($event) {
                                                _vm.getAddressLine1 = $event
                                              },
                                            "update:passedAddressLine2":
                                              function ($event) {
                                                _vm.getAddressLine2 = $event
                                              },
                                            "update:passed-address-line2":
                                              function ($event) {
                                                _vm.getAddressLine2 = $event
                                              },
                                            "update:passedAddressLine3":
                                              function ($event) {
                                                _vm.getAddressLine3 = $event
                                              },
                                            "update:passed-address-line3":
                                              function ($event) {
                                                _vm.getAddressLine3 = $event
                                              },
                                            "update:passedAddressLine4":
                                              function ($event) {
                                                _vm.getAddressLine4 = $event
                                              },
                                            "update:passed-address-line4":
                                              function ($event) {
                                                _vm.getAddressLine4 = $event
                                              },
                                            "update:passedPostCode": function (
                                              $event
                                            ) {
                                              _vm.getPostCode = $event
                                            },
                                            "update:passed-post-code":
                                              function ($event) {
                                                _vm.getPostCode = $event
                                              },
                                            "update:passedPolicyNumberNotPresentReason":
                                              function ($event) {
                                                _vm.getPolicyNumberNotPresentReason =
                                                  $event
                                              },
                                            "update:passed-policy-number-not-present-reason":
                                              function ($event) {
                                                _vm.getPolicyNumberNotPresentReason =
                                                  $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper-content",
        { staticClass: "pb-0", attrs: { step: "2" } },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-container",
                { staticClass: "pb-0 pt-2" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", [
                        _c("div", { staticClass: "policy-logo" }, [
                          _vm.policyUrl &&
                          _vm.policyUrl !== null &&
                          _vm.policyUrl !== ""
                            ? _c("img", { attrs: { src: _vm.policyUrl } })
                            : _c("img", {
                                attrs: { src: "/img/dummy-logo.jpeg" },
                              }),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "emergency-info-table" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "shadow-outer emergency-table",
                              attrs: { wrap: "" },
                            },
                            [
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "PH Name",
                                  detail:
                                    (_vm.claimModel.clientTitle
                                      ? _vm.claimModel.clientTitle
                                      : "") +
                                    " " +
                                    _vm.claimModel.clientForename +
                                    " " +
                                    _vm.claimModel.clientLastName,
                                  "other-detail":
                                    (_vm.claimModel.secondaryClientTitle
                                      ? _vm.claimModel.secondaryClientTitle +
                                        " "
                                      : "") +
                                    (_vm.claimModel.secondaryClientForename
                                      ? _vm.claimModel.secondaryClientForename +
                                        " "
                                      : "") +
                                    (_vm.claimModel.secondaryClientLastName
                                      ? _vm.claimModel.secondaryClientLastName
                                      : ""),
                                },
                              }),
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "Policy Number",
                                  detail: _vm.claimModel.policyNumber
                                    ? "#" + _vm.claimModel.policyNumber
                                    : "",
                                },
                              }),
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "PostCode",
                                  detail: _vm.claimModel.postCode,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-container", { staticClass: "pb-0 px-0" }, [
                        _c(
                          "div",
                          { staticClass: "perilItems" },
                          [
                            _vm.wizardLoadState === "loaded"
                              ? _c("AddHEEmergency", {
                                  ref: "addEmergency",
                                  attrs: {
                                    disabled:
                                      _vm.isPolicyEnquiryToggleOn ||
                                      _vm.isHelpLinePolicy,
                                    "add-emergency-index": 0,
                                    "effective-date": _vm.effectiveDate,
                                  },
                                  model: {
                                    value: _vm.claimModel.emergencies[0],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.claimModel.emergencies,
                                        0,
                                        $$v
                                      )
                                    },
                                    expression: "claimModel.emergencies[0]",
                                  },
                                })
                              : _c("div", [
                                  _vm._v(
                                    "Loading dropdown values, please wait ..."
                                  ),
                                ]),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "link-switch mb-2" }, [
                          _c(
                            "span",
                            [
                              _vm.isPolicyEnquiryOrClaimAllowed
                                ? _c("v-switch", {
                                    attrs: {
                                      label: "Enquiry Only",
                                      color: "primary",
                                      small: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.isPolicyEnquiryToggleOn,
                                      callback: function ($$v) {
                                        _vm.isPolicyEnquiryToggleOn = $$v
                                      },
                                      expression: "isPolicyEnquiryToggleOn",
                                    },
                                  })
                                : _vm.isPolicyEnquiryToggleOn
                                ? _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      label: "Enquiry Only",
                                      small: "",
                                      "input-value":
                                        _vm.isPolicyEnquiryToggleOn,
                                      disabled: "",
                                      "hide-details": "",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm.filterPreviousJobsList.length > 0
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "px-2": "" } },
                            [
                              _c("v-textarea", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:2000",
                                    expression: "'required|max:2000'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Reason for creating duplicate job",
                                  maxlength: "2000",
                                  required: "",
                                  "data-vv-scope": _vm.step2formRef,
                                  "data-vv-name":
                                    "Reason for creating duplicate job",
                                  name: "ReasonForCreatingDuplicateJob",
                                  "error-messages": _vm.errors.collect(
                                    "Reason for creating duplicate job"
                                  ),
                                  disabled:
                                    _vm.isPolicyEnquiryToggleOn ||
                                    _vm.isHelpLinePolicy,
                                },
                                model: {
                                  value:
                                    _vm.claimModel
                                      .reasonForCreatingDuplicateJob,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.claimModel,
                                      "reasonForCreatingDuplicateJob",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "claimModel.reasonForCreatingDuplicateJob",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("h3", { staticClass: "mb-2 mt-2" }, [
                            _vm._v(
                              _vm._s(_vm.filterPreviousJobsList.length) +
                                " Job(s) found in last 30 days.."
                            ),
                          ]),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "elevation-1": "",
                                "px-2": "",
                                "pt-2": "",
                                "pb-0": "",
                                "mb-2": "",
                              },
                            },
                            _vm._l(_vm.filterPreviousJobsList, function (item) {
                              return _c(
                                "v-layout",
                                {
                                  key: item.jobId,
                                  attrs: {
                                    wrap: "",
                                    grey: "",
                                    "lighten-3": "",
                                    "mb-2": "",
                                    "py-2": "",
                                    "px-3": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "mb-2": "" } },
                                    [
                                      _c(
                                        "b",
                                        {
                                          staticClass:
                                            "grey--text text--darken-1",
                                        },
                                        [
                                          _vm._v(" Job ID: "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "secondary--text",
                                              attrs: {
                                                href: "Javascript:void(0)",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.redirectToJob(
                                                    item.jobId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.jobId) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  item.createdAt
                                    ? _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs6: "",
                                            "text-xs-right": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "b",
                                            {
                                              staticClass:
                                                "grey--text text--darken-1",
                                            },
                                            [
                                              _vm._v(
                                                "Logged on " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      item.createdAt
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    _vm._l(
                                      _vm.getPreviousJobsByJobId(item.jobId),
                                      function (prevJob, index) {
                                        return _c("b", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                prevJob.description +
                                                  (prevJob.secondaryDescription
                                                    ? " (" +
                                                      prevJob.secondaryDescription +
                                                      ")"
                                                    : "")
                                              ) +
                                              _vm._s(
                                                index ===
                                                  _vm.getPreviousJobsByJobId(
                                                    item.jobId
                                                  ).length -
                                                    1
                                                  ? ""
                                                  : ","
                                              ) +
                                              " "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-stepper-content",
        { attrs: { step: "3" } },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "shadow-outer",
                              attrs: { wrap: "" },
                            },
                            [
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "PH Name",
                                  detail:
                                    (_vm.claimModel.clientTitle
                                      ? _vm.claimModel.clientTitle
                                      : "") +
                                    " " +
                                    _vm.claimModel.clientForename +
                                    " " +
                                    _vm.claimModel.clientLastName,
                                  "other-detail":
                                    (_vm.claimModel.secondaryClientTitle
                                      ? _vm.claimModel.secondaryClientTitle +
                                        " "
                                      : "") +
                                    (_vm.claimModel.secondaryClientForename
                                      ? _vm.claimModel.secondaryClientForename +
                                        " "
                                      : "") +
                                    (_vm.claimModel.secondaryClientLastName
                                      ? _vm.claimModel.secondaryClientLastName
                                      : ""),
                                },
                              }),
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "Policy Number",
                                  detail: _vm.claimModel.policyNumber
                                    ? "#" + _vm.claimModel.policyNumber
                                    : "",
                                },
                              }),
                              _c("PropertyCard", {
                                attrs: {
                                  size: 4,
                                  title: "PostCode",
                                  detail: _vm.claimModel.postCode,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pt-4": "" } },
                        [
                          !_vm.isPolicyEnquiryToggleOn
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("ContactPreferenceDetails", {
                                        ref: "contactPreferenceDetails",
                                        attrs: {
                                          "customer-contact-preference-modal":
                                            _vm.customerContactPreferenceModal,
                                        },
                                        on: {
                                          updateContactPreference:
                                            _vm.updateContactPreference,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-layout",
                                { attrs: { wrap: "", "mt-3": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-autocomplete", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          items: _vm.isOnline
                                            ? _vm.enquiryCategories
                                            : _vm.getEnquiryCategoriesOffline,
                                          label: "Enquiry Category",
                                          "item-value": "description",
                                          "item-text": "description",
                                          required: "",
                                          loading: _vm.isLoadingEnquiryCategory,
                                          "data-vv-scope": _vm.step3formRef,
                                          "data-vv-name": "Enquiry Category",
                                          "error-messages":
                                            _vm.validationMessage(
                                              "Enquiry Category"
                                            ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onChangeCategory($event)
                                          },
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "item",
                                            fn: function (data) {
                                              return [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        class:
                                                          data.item
                                                            .description ===
                                                          "Configure"
                                                            ? "bold-select"
                                                            : "",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value:
                                            _vm.enquiryModel.enquiryCategory,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.enquiryModel,
                                              "enquiryCategory",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "enquiryModel.enquiryCategory",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Enquiry for",
                                          required: "",
                                          "data-vv-scope": _vm.step3formRef,
                                          "data-vv-name": "Enquiry",
                                          "error-messages":
                                            _vm.errors.collect("Enquiry"),
                                        },
                                        model: {
                                          value:
                                            _vm.enquiryModel.enquiryDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.enquiryModel,
                                              "enquiryDescription",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "enquiryModel.enquiryDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "820",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.configureDropdownDialog,
            callback: function ($$v) {
              _vm.configureDropdownDialog = $$v
            },
            expression: "configureDropdownDialog",
          },
        },
        [
          _c("ConfigureDropdown", {
            ref: "configureDropdown",
            attrs: { "record-type": _vm.configureDropdownType },
            on: { CloseConfigureDropdownDialog: _vm.onConfigureDropdownDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }