var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class:
        !_vm.emergency.isEmergencyAccepted &&
        (_vm.emergency.acceptRejectNote === null ||
          _vm.emergency.acceptRejectNote === "")
          ? "questions-list-content"
          : "",
    },
    [
      _vm._l(_vm.items, function (item) {
        return _c("EmergencyQAEditControl", {
          key: item.id,
          attrs: {
            "job-id": _vm.jobId,
            "emergency-q-a-id": item.id,
            "get-accept-reject-question-criteria":
              _vm.getAcceptRejectQuestionCriteria,
          },
          on: { setSyncing: _vm.setSyncing },
        })
      }),
      _c(
        "div",
        [
          _c("HealthAndSafetyQA", {
            attrs: {
              "health-and-safety-q-a": _vm.vulnerabilityQAItem,
              "get-accept-reject-question-criteria":
                _vm.getAcceptRejectQuestionCriteria,
              embedded: false,
              mandatory: true,
            },
            on: { setSyncing: _vm.setSyncing },
          }),
        ],
        1
      ),
      _c("v-expansion-panel", { staticClass: "elevation-0" }, [
        _c(
          "div",
          { staticClass: "collapse-list" },
          [
            _c(
              "v-expansion-panel-content",
              { attrs: { lazy: true } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("span", { staticClass: "additional-information" }, [
                    _vm._v("Additional Information"),
                  ]),
                ]),
                _c(
                  "v-card",
                  { staticClass: "elevation-0" },
                  [
                    _c("AccessNote", {
                      attrs: {
                        "access-note-question": _vm.getAccessNoteItem,
                        embedded: true,
                      },
                      on: { setSyncing: _vm.setSyncing },
                    }),
                    _c("HealthAndSafetyQA", {
                      attrs: {
                        "health-and-safety-q-a": _vm.healthAndSafetyQAItem,
                        "get-accept-reject-question-criteria":
                          _vm.getAcceptRejectQuestionCriteria,
                        embedded: true,
                        mandatory: false,
                      },
                      on: { setSyncing: _vm.setSyncing },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.showActionButtons()
        ? _c(
            "div",
            { staticClass: "claim-btn-content grey lighten-3" },
            [
              _c(
                "v-card",
                {
                  staticClass: "elevation-0 grey lighten-3",
                  attrs: { wrap: "" },
                },
                [
                  _c(
                    "v-card-actions",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "blue white--text btn-soft-fix",
                          attrs: {
                            disabled:
                              _vm.isEmergencyRejected ||
                              _vm.getAcceptButtonCriteria ||
                              _vm.isSyncing ||
                              _vm.isActionPerformed ||
                              _vm.questionMissing,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSoftFixEmergencyDialogOpen()
                            },
                          },
                        },
                        [_vm._v(" Soft Fix Emergency ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-reject",
                          class:
                            _vm.getRejectButtonCriteria || _vm.abilityToAccept
                              ? "red white--text"
                              : "grey",
                          attrs: {
                            disabled:
                              _vm.isEmergencyRejected ||
                              (!_vm.getRejectButtonCriteria &&
                                !_vm.abilityToAccept) ||
                              _vm.isSyncing ||
                              _vm.isActionPerformed ||
                              _vm.questionMissing,
                            "small-xs": "",
                          },
                          on: { click: _vm.onRejectEmergencyDialogOpen },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isEmergencyRejected
                                  ? "Emergency Rejected"
                                  : "Reject Emergency"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-accept",
                          class: !_vm.getAcceptButtonCriteria
                            ? "green white--text"
                            : _vm.isAcceptEmergencyLoading
                            ? "grey"
                            : "",
                          attrs: {
                            disabled:
                              _vm.getAcceptButtonCriteria ||
                              _vm.isAcceptEmergencyLoading ||
                              _vm.isSyncing ||
                              _vm.isActionPerformed ||
                              _vm.questionMissing,
                            loading: _vm.isAcceptEmergencyLoading,
                          },
                          on: { click: _vm.onAcceptEmergency },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isEmergencyRejected
                                  ? "Overwrite Rejection"
                                  : "Accept Emergency"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.isEmergencyRejected
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-pay",
                              class: !_vm.getAcceptButtonCriteria
                                ? "green white--text"
                                : "grey",
                              attrs: {
                                disabled:
                                  _vm.emergency
                                    .ctpCompletedForRejectedEmergency ||
                                  _vm.isSyncing ||
                                  _vm.rejectEmergencyAsyncLoading ||
                                  _vm.isActionPerformed,
                                loading: _vm.rejectEmergencyAsyncLoading,
                              },
                              on: { click: _vm.ctpForRejectedEmergency },
                            },
                            [_vm._v(" Pay for Emergency ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.rejectEmergencyDialog,
            callback: function ($$v) {
              _vm.rejectEmergencyDialog = $$v
            },
            expression: "rejectEmergencyDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Reject Emergency")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onRejectEmergencyDialogClose.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-autocomplete", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required repudiation-reason",
                                attrs: {
                                  label: "Repudiation reason",
                                  items: _vm.repudiations,
                                  loading: _vm.repudiationsLoading,
                                  "item-text": "description",
                                  "item-value": "description",
                                  required: "",
                                  "data-vv-scope": "frmRejectEmergency",
                                  "data-vv-name": "Repudiation reason",
                                  "error-messages":
                                    _vm.errors.collect("Repudiation reason"),
                                  "search-input":
                                    _vm.emergencyAcceptOrReject
                                      .repudiationDescription,
                                },
                                on: {
                                  "update:searchInput": function ($event) {
                                    return _vm.$set(
                                      _vm.emergencyAcceptOrReject,
                                      "repudiationDescription",
                                      $event
                                    )
                                  },
                                  "update:search-input": function ($event) {
                                    return _vm.$set(
                                      _vm.emergencyAcceptOrReject,
                                      "repudiationDescription",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value:
                                    _vm.emergencyAcceptOrReject
                                      .repudiationDescription,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.emergencyAcceptOrReject,
                                      "repudiationDescription",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "emergencyAcceptOrReject.repudiationDescription",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: { label: "Description", rows: "7" },
                                model: {
                                  value:
                                    _vm.emergencyAcceptOrReject
                                      .acceptRejectNote,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.emergencyAcceptOrReject,
                                      "acceptRejectNote",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "emergencyAcceptOrReject.acceptRejectNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-cancel",
                      attrs: { color: "primary", flat: "" },
                      on: { click: _vm.onRejectEmergencyDialogClose },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-reject-emergency",
                      attrs: { color: "primary" },
                      on: { click: _vm.onRejectEmergency },
                    },
                    [_vm._v("Reject")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.acceptEmergencyDialog,
            callback: function ($$v) {
              _vm.acceptEmergencyDialog = $$v
            },
            expression: "acceptEmergencyDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Accept Emergency")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onAcceptEmergencyDialogClose.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog px-3" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Description",
                                  required: "",
                                  name: "acceptRejectNote",
                                  "data-vv-scope": "frmAcceptEmergency",
                                  "data-vv-name": "Description",
                                  "error-messages":
                                    _vm.errors.collect("Description"),
                                },
                                model: {
                                  value:
                                    _vm.emergencyAcceptOrReject
                                      .acceptRejectNote,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.emergencyAcceptOrReject,
                                      "acceptRejectNote",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "emergencyAcceptOrReject.acceptRejectNote",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-cancel-accept",
                      attrs: { color: "primary", flat: "" },
                      on: { click: _vm.onAcceptEmergencyDialogClose },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-accept-emergency",
                      attrs: {
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.onAcceptEmergencyWithoutCriteria },
                    },
                    [_vm._v(" Accept ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.softFixEmergencyDialog,
            callback: function ($$v) {
              _vm.softFixEmergencyDialog = $$v
            },
            expression: "softFixEmergencyDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Soft Fix Emergency")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onSoftFixEmergencyDialogClose.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog px-3" },
                [
                  _c(
                    "v-form",
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  items: _vm.softFixTypeList,
                                  "item-text": "description",
                                  "item-value": "id",
                                  label: "What is the type of soft fix",
                                  required: "",
                                  name: "softFixType",
                                  "data-vv-scope": "frmSoftFixEmergency",
                                  "data-vv-name": "Soft Fix Type",
                                  "error-messages":
                                    _vm.errors.collect("Soft Fix Type"),
                                },
                                on: { change: _vm.onSoftFixTypeChange },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c(
                                                  "v-list-tile-title",
                                                  {
                                                    class:
                                                      data.item.description ===
                                                      "Configure"
                                                        ? "bold-select"
                                                        : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.softFixedEmergency.title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.softFixedEmergency,
                                      "title",
                                      $$v
                                    )
                                  },
                                  expression: "softFixedEmergency.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Description",
                                  "data-vv-name": "Description",
                                },
                                model: {
                                  value: _vm.softFixedEmergency.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.softFixedEmergency,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "softFixedEmergency.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-cancel-soft-fix",
                      attrs: { color: "primary", flat: "" },
                      on: { click: _vm.onSoftFixEmergencyDialogClose },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-soft-accept",
                      attrs: {
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                      on: { click: _vm.onSoftFixEmergencyAccept },
                    },
                    [_vm._v(" Accept ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "700",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.configureDropdownDialog,
            callback: function ($$v) {
              _vm.configureDropdownDialog = $$v
            },
            expression: "configureDropdownDialog",
          },
        },
        [
          _c("ConfigureDropdown", {
            ref: "configureDropdown",
            attrs: { "record-type": _vm.configureDropdownType },
            on: {
              CloseConfigureDropdownDialog: _vm.onConfigureDropdownDialogClose,
            },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: "", left: "" },
          model: {
            value: _vm.showEscalateCTPProcessSnackbar,
            callback: function ($$v) {
              _vm.showEscalateCTPProcessSnackbar = $$v
            },
            expression: "showEscalateCTPProcessSnackbar",
          },
        },
        [
          _vm._v(" CTP process escalated to team leader. "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              on: {
                click: function ($event) {
                  _vm.showEscalateCTPProcessSnackbar = false
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _vm.useExtraBalanceConfirmationDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.useExtraBalanceConfirmationDialog,
                callback: function ($$v) {
                  _vm.useExtraBalanceConfirmationDialog = $$v
                },
                expression: "useExtraBalanceConfirmationDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [
                      _vm._v(
                        " " +
                          _vm._s(
                            "You have " +
                              _vm.getFormatedCurrency(
                                _vm.emergencyResponseModel.extraBalanceLeft
                              ) +
                              " extra balance. Do you want to use this balance to proceed further?"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-no",
                          attrs: { small: "", flat: "" },
                          on: {
                            click: _vm.onCancelExtraBalanceConfirmationDialog,
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-yes",
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.useExtraBalanceLoading,
                            loading: _vm.useExtraBalanceLoading,
                          },
                          on: { click: _vm.onConfirmUseExtraBalance },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.doCTPForRejectedEmergency
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.doCTPForRejectedEmergency,
                callback: function ($$v) {
                  _vm.doCTPForRejectedEmergency = $$v
                },
                expression: "doCTPForRejectedEmergency",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          "This emergency is rejected, do you want to trigger CTP and proceed further?"
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-no",
                          attrs: {
                            small: "",
                            flat: "",
                            loading: _vm.rejectEmergencyAsyncLoading,
                            disabled: _vm.rejectEmergencyAsyncLoading,
                          },
                          on: { click: _vm.onCancelDoCTPForRejectedEmergency },
                        },
                        [_vm._v(" No ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-yes",
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.onConfirmDoCTPForRejectedEmergency },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }