var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false,
        "nudge-width": 380,
        "max-width": 380,
        "z-index": 999,
        fixed: "",
        left: "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "span",
                _vm._g({ staticClass: "notification-bell ma-0" }, on),
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-badge",
                                  {
                                    attrs: {
                                      overlap: "",
                                      color: "grey small-size-badge darken-2",
                                    },
                                  },
                                  [
                                    _vm.filterPreviousJobsList.length
                                      ? _c(
                                          "span",
                                          {
                                            attrs: { slot: "badge", small: "" },
                                            slot: "badge",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.filterPreviousJobsList
                                                    .length
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-avatar",
                                      _vm._g(
                                        {
                                          staticClass: "elevation-5",
                                          attrs: { color: "white", size: 34 },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "secondary--text",
                                            attrs: { dark: "", small: "" },
                                          },
                                          [_vm._v("work")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [_c("span", [_vm._v("Previous Jobs")])]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "bar",
              rawName: "v-bar",
              value: { useScrollbarPseudo: true },
              expression: "{ useScrollbarPseudo: true }",
            },
          ],
          staticClass: "notification-menu pa-3",
        },
        [
          _c(
            "div",
            { staticClass: "inner-content view-panel" },
            [
              _c(
                "v-list",
                {
                  style:
                    _vm.filterPreviousJobsList &&
                    _vm.filterPreviousJobsList.length > 2
                      ? "max-height: 225px !important"
                      : "",
                },
                [
                  _vm.filterPreviousJobsList &&
                  _vm.filterPreviousJobsList.length === 0
                    ? _c(
                        "v-list-tile",
                        { staticClass: "unread-notification" },
                        [
                          _c(
                            "v-list-tile-content",
                            [
                              _c(
                                "v-list-tile-sub-title",
                                { staticClass: "caption norecord-found-label" },
                                [_vm._v(" No previous jobs found. ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._l(_vm.filterPreviousJobsList, function (item, ind) {
                        return _c(
                          "v-list-tile",
                          { key: ind, staticClass: "unread-notification" },
                          [
                            _c("v-list-tile-content", [
                              _c(
                                "a",
                                {
                                  staticClass: "notification-anchor",
                                  attrs: {
                                    href: _vm.previousJobUrl(item.jobId),
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c(
                                    "v-list-tile-sub-title",
                                    { staticClass: "caption" },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c("v-flex", { attrs: { xs6: "" } }, [
                                            _c("b", [
                                              _vm._v(" Job Id: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "secondary--text",
                                                },
                                                [_vm._v(_vm._s(item.jobId))]
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs6: "",
                                                "text-xs-right": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "b",
                                                { staticClass: "grey--text" },
                                                [
                                                  _vm._v(" Logged on "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "grey--text text--darken-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getCreatedDateForDisplay(
                                                              item.createdAt
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "mt-2 grey--text text--darken-3 body-2",
                                    },
                                    _vm._l(
                                      _vm.getPreviousJobsByJobId(item.jobId),
                                      function (prevJob, index) {
                                        return _c("b", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(prevJob.description) +
                                              _vm._s(
                                                index ===
                                                  _vm.getPreviousJobsByJobId(
                                                    item.jobId
                                                  ).length -
                                                    1
                                                  ? ""
                                                  : ","
                                              ) +
                                              " "
                                          ),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }