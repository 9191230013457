var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.customerToPayModel
    ? _c(
        "div",
        { staticClass: "question-title-desc" },
        [
          _c(
            "v-layout",
            { staticClass: "mt-2", attrs: { wrap: "" } },
            [
              _vm.customerToPayModel.isTransactionCompleted
                ? _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v(
                        "Payment taken on " +
                          _vm._s(_vm.customerToPayModel.cardBrand) +
                          " card"
                      ),
                    ]),
                    _c(
                      "p",
                      { staticClass: "mb-2" },
                      [
                        _c("v-icon", [_vm._v("credit_card")]),
                        _c("b", [
                          _vm._v(
                            "**** **** **** " +
                              _vm._s(_vm.customerToPayModel.cardLastFourDigit)
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "mb-1" }, [
                      _vm._v(" Payment date: "),
                      _c("b", { staticClass: "secondary--text" }, [
                        _vm._v(_vm._s(_vm.getPaymentDate)),
                      ]),
                    ]),
                  ])
                : _c("v-flex", [
                    _c("p", { staticClass: "mb-2" }, [
                      _vm._v("Payment not done"),
                    ]),
                  ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }