var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "contractorrate" },
    [
      _c(
        "v-card",
        { staticClass: "elevation-1" },
        [
          !_vm.fromJobCompletionScreen
            ? _c(
                "v-card-title",
                { attrs: { xs12: "", "px-3": "", "pt-3": "", "pb-0": "" } },
                [_c("h3", [_vm._v("Contractor Rate")])]
              )
            : _vm._e(),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-lg": "", "py-0": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs4: "" } }, [
                        _c(
                          "div",
                          { staticClass: "newindicator-chip" },
                          [
                            !_vm.isAccessToCreateRatePackage
                              ? _c("v-autocomplete", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "rate-package",
                                  attrs: {
                                    label: "Search package",
                                    "return-object": "",
                                    loading: _vm.getRatePackageRunning,
                                    items: _vm.getContractorRatePackageList,
                                    "item-text": "packageName",
                                    "item-value": "id",
                                    "data-vv-scope": "frmContractorRate",
                                    "data-vv-name": "Search package",
                                    "error-messages":
                                      _vm.errors.collect("Search package"),
                                    disabled: _vm.fromJobCompletionScreen,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onContractorRatePackageChange(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedRatePackage,
                                    callback: function ($$v) {
                                      _vm.selectedRatePackage = $$v
                                    },
                                    expression: "selectedRatePackage",
                                  },
                                })
                              : _vm._e(),
                            _vm.isAccessToCreateRatePackage
                              ? _c("v-combobox", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  attrs: {
                                    label: "Search package",
                                    "return-object": "",
                                    loading: _vm.getRatePackageRunning,
                                    items: _vm.getContractorRatePackageList,
                                    "item-text": "packageName",
                                    "item-value": "id",
                                    "data-vv-scope": "frmContractorRate",
                                    "data-vv-name": "Search package",
                                    "error-messages":
                                      _vm.errors.collect("Search package"),
                                    "data-vv-delay": _vm.validationDelay,
                                    disabled: _vm.fromJobCompletionScreen,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onContractorRatePackageChange(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedRatePackage,
                                    callback: function ($$v) {
                                      _vm.selectedRatePackage = $$v
                                    },
                                    expression: "selectedRatePackage",
                                  },
                                })
                              : _vm._e(),
                            _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: "secondary",
                                  small: "",
                                  disabled: "",
                                  value: _vm.isNewRatePackage,
                                },
                              },
                              [_vm._v("New")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "v-flex",
                        { staticClass: "mt-1", attrs: { xs2: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              name: "fixedRate",
                              color: "primary",
                              "hide-details": "",
                              label: "Fixed Rate",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onContractorFixedRatedChange($event)
                              },
                            },
                            model: {
                              value: _vm.isHasFixedContractorRate,
                              callback: function ($$v) {
                                _vm.isHasFixedContractorRate = $$v
                              },
                              expression: "isHasFixedContractorRate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Materials uplift(%)",
                              maxlength: "5",
                              readonly: _vm.isUserRoleContractor,
                            },
                            on: {
                              keypress: function ($event) {
                                return _vm.numberKeyValidation($event)
                              },
                            },
                            model: {
                              value:
                                _vm.contractorRatePackageModel.materialUplift,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contractorRatePackageModel,
                                  "materialUplift",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "contractorRatePackageModel.materialUplift",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs3: "" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Aborted Visit", maxlength: "5" },
                            on: {
                              keypress: function ($event) {
                                return _vm.numberKeyValidation($event)
                              },
                            },
                            model: {
                              value:
                                _vm.contractorRatePackageModel.abortedVisit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.contractorRatePackageModel,
                                  "abortedVisit",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "contractorRatePackageModel.abortedVisit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mt-0": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1 contractor-rate-table",
                            attrs: {
                              headers: _vm.headers,
                              items:
                                _vm.contractorRatePackageModel.contractorRates,
                              "item-key": "tradeId",
                              "hide-actions": "",
                              expand: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "headers",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "tr",
                                      _vm._l(props.headers, function (header) {
                                        return _c(
                                          "th",
                                          {
                                            key: header.text,
                                            class: header.class,
                                            attrs: { align: header.align },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  header.text.replace(
                                                    "-Callout",
                                                    ""
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "items",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        [
                                          _c("v-select", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "tradeList",
                                            attrs: {
                                              items: _vm.getTrades,
                                              "item-value": "tradeId",
                                              "item-text": "description",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              loading: _vm.getTradesRunning,
                                              "data-vv-scope":
                                                "frmContractorRate",
                                              "data-vv-name":
                                                "Trade" + props.index,
                                              "error-messages":
                                                _vm.validateTradeList(
                                                  "Trade" + props.index,
                                                  props.item
                                                ),
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "v-list-tile",
                                                        [
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                {
                                                                  class:
                                                                    _vm.getClass(
                                                                      data.item
                                                                        .tradeId
                                                                    ),
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .description
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: props.item.tradeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "tradeId",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.tradeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "minimum-width" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "fixed-price" },
                                            [
                                              _c("v-text-field", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: {
                                                      rules: {
                                                        required:
                                                          props.item
                                                            .hasFixedTradeRate,
                                                      },
                                                    },
                                                    expression:
                                                      "{\n                          rules: { required: props.item.hasFixedTradeRate },\n                        }",
                                                  },
                                                ],
                                                staticClass:
                                                  "prepend-icon-image required fixedRateAmount",
                                                attrs: {
                                                  maxlength: "8",
                                                  required: "",
                                                  "prepend-icon": "close",
                                                  disabled:
                                                    !props.item
                                                      .hasFixedTradeRate,
                                                  "data-vv-scope":
                                                    "frmContractorRate",
                                                  "data-vv-name":
                                                    "fixedRateAmount" +
                                                    props.index,
                                                  "error-messages":
                                                    _vm.validationMessage(
                                                      "fixedRateAmount" +
                                                        props.index
                                                    ),
                                                  name: "fixedRateAmount",
                                                },
                                                on: {
                                                  keypress: function ($event) {
                                                    return _vm.numberKeyValidation(
                                                      $event
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    props.item.fixedRateAmount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "fixedRateAmount",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.fixedRateAmount",
                                                },
                                              }),
                                              _c("v-switch", {
                                                attrs: {
                                                  name: "tableFixedRate",
                                                  color: "primary",
                                                },
                                                model: {
                                                  value:
                                                    props.item
                                                      .hasFixedTradeRate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "hasFixedTradeRate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.hasFixedTradeRate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c("v-checkbox", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              disabled:
                                                _vm.isUserRoleContractor ||
                                                props.item.hasFixedTradeRate,
                                              color: "primary",
                                            },
                                            model: {
                                              value: props.item.insideM25,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "insideM25",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.insideM25",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "call-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: props.item.calloutDayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "calloutDayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.calloutDayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "call-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.calloutNightPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "calloutNightPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.calloutNightPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "call-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.calloutWeekendPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "calloutWeekendPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.calloutWeekendPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "call-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.calloutHolidayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "calloutHolidayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.calloutHolidayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "call-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item
                                                  .calloutChristmasHolidayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "calloutChristmasHolidayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.calloutChristmasHolidayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "follow-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.followOnDayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "followOnDayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.followOnDayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "follow-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.followOnNightPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "followOnNightPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.followOnNightPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "follow-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.followOnWeekendPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "followOnWeekendPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.followOnWeekendPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "follow-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item.followOnHolidayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "followOnHolidayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.followOnHolidayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "follow-row" },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "isFixedCostApplied",
                                            attrs: {
                                              maxlength: "8",
                                              readonly:
                                                _vm.isUserRoleContractor,
                                              disabled:
                                                props.item.hasFixedTradeRate,
                                            },
                                            on: {
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                props.item
                                                  .followOnChristmasHolidayPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "followOnChristmasHolidayPrice",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "props.item.followOnChristmasHolidayPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !_vm.isUserRoleContractor
                                        ? _c(
                                            "td",
                                            { staticClass: "follow-row" },
                                            [
                                              !_vm.fromJobCompletionScreen
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _vm
                                                                  .contractorRatePackageModel
                                                                  .contractorRates
                                                                  .length > 1
                                                                  ? _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "mr-0 btn-remove",
                                                                          attrs:
                                                                            {
                                                                              flat: "",
                                                                              icon: "",
                                                                              color:
                                                                                "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.preventDefault()
                                                                                return _vm.removeAdditionalRate(
                                                                                  props.item
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "close"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Remove Row"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          !_vm.isUserRoleContractor &&
                          !_vm.fromJobCompletionScreen
                            ? _c(
                                "div",
                                { staticClass: "text-xs-right" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "mr-0 mt-2 btn-add",
                                                      attrs: {
                                                        icon: "",
                                                        color: "primary",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.addAdditionalRate,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "white--text",
                                                        attrs: {
                                                          dark: "",
                                                          small: "",
                                                        },
                                                      },
                                                      [_vm._v("add")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1617414025
                                      ),
                                    },
                                    [_c("span", [_vm._v("Add Row")])]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-3" },
            [
              _c("v-spacer"),
              !_vm.fromJobCompletionScreen
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-saveOrAssignRatePackage",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        color: "primary",
                      },
                      on: { click: _vm.SaveOrAssignRatePackage },
                    },
                    [_vm._v(" Save ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }