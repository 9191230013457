var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        [
          _vm.videoSource
            ? _c(
                "v-container",
                { attrs: { "pa-3": "" } },
                [
                  _vm.videoSource.webEncoded
                    ? _c(
                        "video",
                        {
                          staticClass: "video-player",
                          attrs: { controls: "", muted: "", playsinline: "" },
                          domProps: { muted: true },
                        },
                        [
                          _c("source", {
                            attrs: {
                              src: _vm.videoSource.sasUrl,
                              type: "video/mp4",
                            },
                          }),
                        ]
                      )
                    : _c(
                        "v-card",
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "align-center",
                              attrs: { wrap: "" },
                            },
                            [
                              _c("v-flex", { attrs: { "pa-3": "", xs6: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    "Browser playback not available for this run."
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right",
                                  attrs: { "pa-3": "", xs6: "" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: { click: _vm.downloadVideoFile },
                                    },
                                    [_vm._v("Download Video")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.isAddUpdateMode && _vm.cctvControlRunLogLocal
        ? _c(
            "v-flex",
            { attrs: { "pa-3": "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "sitedata-accordion",
                  attrs: { wrap: "", "mb-2": "" },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("From:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.from)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("To:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.to)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Pipe Material:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.pipeMaterial)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Pipe Diameter:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.pipeDiameter)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Direction:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.direction)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Function:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlRunLogLocal.function)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Invert Level:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          _vm._s(_vm.cctvControlRunLogLocal.invertLevelOne)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Invert Level:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          _vm._s(_vm.cctvControlRunLogLocal.invertLevelTwo)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Shared Run:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.cctvControlRunLogLocal.sharedRun
                                ? _vm.cctvControlRunLogLocal.sharedRun
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Gully Condition:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.cctvControlRunLogLocal.gullyCondition
                                ? _vm.cctvControlRunLogLocal.gullyCondition
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c("v-flex", { attrs: { xs12: "", "mb-1": "" } }, [
                    _c("label", [_c("b", [_vm._v("Comments:")])]),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(_vm.cctvControlRunLogLocal.comments)),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "elevation-0 pt-3 pb-2" },
                _vm._l(
                  _vm.cctvControlRunLogLocal.distances,
                  function (cctvControlDistance, index) {
                    return _c(
                      "v-card-text",
                      { key: index, staticClass: "pb-2" },
                      [
                        _c("CCTVControlRunDistance", {
                          attrs: {
                            "cctv-control-distance": cctvControlDistance,
                            "job-id": _vm.jobId,
                          },
                        }),
                        _vm.cctvControlRunLogLocal.distances.length - 1 !==
                        index
                          ? _c("v-divider", { attrs: { "mt-1": "" } })
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isAddUpdateMode
        ? _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-combobox", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required from-location",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.fromToLocations
                              ? _vm.cctvLogConfiguration.fromToLocations
                              : [],
                          label: "From",
                          name: "fromLocation",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "From",
                          "error-messages": _vm.errors.collect("From"),
                          "search-input": _vm.cctvControlRunLogLocal.from,
                          maxlength: "20",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            return _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "from",
                              $event
                            )
                          },
                          "update:search-input": function ($event) {
                            return _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "from",
                              $event
                            )
                          },
                          keypress: function ($event) {
                            return _vm.alphanumericValidation($event)
                          },
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.from,
                          callback: function ($$v) {
                            _vm.$set(_vm.cctvControlRunLogLocal, "from", $$v)
                          },
                          expression: "cctvControlRunLogLocal.from",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-combobox", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required to-location",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.fromToLocations
                              ? _vm.cctvLogConfiguration.fromToLocations
                              : [],
                          label: "To",
                          name: "toLocation",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "To",
                          "error-messages": _vm.errors.collect("To"),
                          "search-input": _vm.cctvControlRunLogLocal.to,
                          maxlength: "20",
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            return _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "to",
                              $event
                            )
                          },
                          "update:search-input": function ($event) {
                            return _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "to",
                              $event
                            )
                          },
                          keypress: function ($event) {
                            return _vm.alphanumericValidation($event)
                          },
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.to,
                          callback: function ($$v) {
                            _vm.$set(_vm.cctvControlRunLogLocal, "to", $$v)
                          },
                          expression: "cctvControlRunLogLocal.to",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.pipeMaterials
                              ? _vm.cctvLogConfiguration.pipeMaterials
                              : [],
                          label: "Pipe Material",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Pipe Material",
                          "error-messages": _vm.errors.collect("Pipe Material"),
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.pipeMaterial,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "pipeMaterial",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.pipeMaterial",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-1": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.pipeDiameters
                              ? _vm.cctvLogConfiguration.pipeDiameters
                              : [],
                          label: "Pipe Diameter",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Pipe Diameter",
                          "error-messages": _vm.errors.collect("Pipe Diameter"),
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.pipeDiameter,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "pipeDiameter",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.pipeDiameter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.directions
                              ? _vm.cctvLogConfiguration.directions
                              : [],
                          label: "Direction",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Direction",
                          "error-messages": _vm.errors.collect("Direction"),
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.direction,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "direction",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.direction",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-1": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.functions
                              ? _vm.cctvLogConfiguration.functions
                              : [],
                          label: "Function",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Function",
                          "error-messages": _vm.errors.collect("Function"),
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.function,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "function",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.function",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Invert Level",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Invert Level One",
                          "error-messages":
                            _vm.validationMessage("Invert Level One"),
                          maxlength: "10",
                          name: "invertLevelOne",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.invertLevelOne,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "invertLevelOne",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.invertLevelOne",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-1": "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Invert Level",
                          required: "",
                          "data-vv-scope": "formCCTVControlRun",
                          "data-vv-name": "Invert Level Two",
                          "error-messages":
                            _vm.validationMessage("Invert Level Two"),
                          maxlength: "10",
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.cctvControlRunLogLocal.invertLevelTwo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "invertLevelTwo",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.invertLevelTwo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Shared Run" },
                        model: {
                          value: _vm.cctvControlRunLogLocal.sharedRun,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "sharedRun",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.sharedRun",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-1": "" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Gully Condition" },
                        model: {
                          value: _vm.cctvControlRunLogLocal.gullyCondition,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "gullyCondition",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.gullyCondition",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pl-1": "" } },
                    [
                      _c("v-textarea", {
                        attrs: { rows: "3", label: "Comments" },
                        model: {
                          value: _vm.cctvControlRunLogLocal.comments,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlRunLogLocal,
                              "comments",
                              $$v
                            )
                          },
                          expression: "cctvControlRunLogLocal.comments",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(
                    _vm.getCCTVControlDistance,
                    function (cctvControlDistance, distanceIndex) {
                      return _c(
                        "v-flex",
                        { key: distanceIndex },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "mt-2 grey lighten-4 elevation-0 layer-card",
                            },
                            [
                              _vm.getCCTVControlDistance.length > 1
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "ma-0 close-icon btn-deleteDistance",
                                      attrs: {
                                        flat: "",
                                        icon: "",
                                        color: "secondary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onDeleteCCTVDistance(
                                            distanceIndex
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("delete")])],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-card-text",
                                { staticClass: "pt-4" },
                                [
                                  _c("CCTVControlRunDistance", {
                                    ref: "refCCTVControlRunDistance",
                                    refInFor: true,
                                    attrs: {
                                      "is-add-update-mode": true,
                                      "cctv-log-configuration":
                                        _vm.cctvLogConfiguration,
                                      "cctv-control-distance":
                                        cctvControlDistance,
                                      "all-c-c-t-v-run-log-distance":
                                        JSON.parse(
                                          JSON.stringify(_vm.distances)
                                        ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 my-3 btn-addNewDistance",
                          attrs: { color: "primary" },
                          on: { click: _vm.onAddNewDistance },
                        },
                        [_vm._v("Add Distance")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }