var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.templateListDialog,
            callback: function ($$v) {
              _vm.templateListDialog = $$v
            },
            expression: "templateListDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("WIP Customer Notification")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeWIPTemplateDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mb-1": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            staticClass: "templateList",
                            attrs: {
                              items: _vm.templateList,
                              "item-text": "templateName",
                              "item-value": "templateId",
                              label: "Select Template",
                              loading: _vm.getTemplatesLoading,
                            },
                            model: {
                              value:
                                _vm.addWIPCustomerNotification
                                  .documentTemplateId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addWIPCustomerNotification,
                                  "documentTemplateId",
                                  $$v
                                )
                              },
                              expression:
                                "addWIPCustomerNotification.documentTemplateId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-close",
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeWIPTemplateDialog")
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-submit",
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.isLoading ||
                          _vm.getTemplatesLoading ||
                          !_vm.addWIPCustomerNotification.documentTemplateId,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.sendWIPCustomerNotification },
                    },
                    [_vm._v(" Send ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }