var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "450",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.jobCompletionWarningDialog,
            callback: function ($$v) {
              _vm.jobCompletionWarningDialog = $$v
            },
            expression: "jobCompletionWarningDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Job Completion Warning")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeJobCompletionWarningDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            "px-1": "",
                            "mb-2": "",
                            "job-warning": "",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "grey--text text--darken-1 custom-text",
                            },
                            [
                              _vm._v(
                                " This job cannot be closed due to open Engineer Request. "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "text-xs-center" },
                            [
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("error_outline"),
                              ]),
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "span",
                            { staticClass: "grey--text text--darken-1" },
                            [
                              _vm._v(
                                " Please review all visits and ensure you have made decisions about all open requests. "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }