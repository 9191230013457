var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "dashboard" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "m-supvr-lcol-outer", attrs: { lg7: "" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "px-0": "", "pt-1": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "mb-3": "" } },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: {
                                        fluid: "",
                                        "grid-list-md": "",
                                        "pt-0": "",
                                        "pa-0": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            wrap: "",
                                            "calls-info": "",
                                            "request-info": "",
                                          },
                                        },
                                        _vm._l(
                                          Object.keys(_vm.topLeftTiles),
                                          function (key) {
                                            return _c(
                                              "DashboardTopTile",
                                              _vm._b(
                                                {
                                                  key: key,
                                                  attrs: {
                                                    "dashboard-tile-view":
                                                      "MangerSupervisorLeft",
                                                  },
                                                },
                                                "DashboardTopTile",
                                                _vm.topLeftTiles[key],
                                                false
                                              )
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("span", { staticClass: "surge" }, [
                                    _c(
                                      "span",
                                      { staticClass: "surge-inner" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "surge-text grey--text",
                                          },
                                          [_c("b", [_vm._v("Surge")])]
                                        ),
                                        _c("v-switch", {
                                          staticClass: "pt-0 mt-0",
                                          attrs: {
                                            "hide-details": "",
                                            color: "secondary",
                                          },
                                          on: { change: _vm.updateSurge },
                                          model: {
                                            value: _vm.inSurgeLocal,
                                            callback: function ($$v) {
                                              _vm.inSurgeLocal = $$v
                                            },
                                            expression: "inSurgeLocal",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _vm.ready
                                    ? _c("ManagerSupervisorTable", {
                                        attrs: {
                                          "table-name":
                                            "manageSupervisorDashboard",
                                          "is-from-main-dash-board": true,
                                          workers: _vm.workers,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "m-supvr-rcol-outer", attrs: { lg5: "" } },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "m-supvr-rcol",
                          attrs: {
                            fluid: "",
                            "grid-list-md": "",
                            "pt-0": "",
                            "pr-0": "",
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "calls-info": "" } },
                            _vm._l(Object.keys(_vm.topTiles), function (key) {
                              return _c(
                                "DashboardTopTile",
                                _vm._b(
                                  {
                                    key: key,
                                    attrs: {
                                      "dashboard-tile-view":
                                        "MangerSupervisorRight",
                                    },
                                  },
                                  "DashboardTopTile",
                                  _vm.topTiles[key],
                                  false
                                )
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "system-info syetsm-dashboard",
                              attrs: { wrap: "" },
                            },
                            _vm._l(Object.keys(_vm.panels), function (key) {
                              return _c(
                                "DashboardPanel",
                                _vm._b(
                                  {
                                    key: key,
                                    attrs: { "flex-class": "xs12 md6" },
                                  },
                                  "DashboardPanel",
                                  _vm.panels[key],
                                  false
                                )
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }