var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.contractorCompanyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Contractor: ")]),
          _c("span", [_vm._v(_vm._s(_vm.contractorCompanyName))]),
        ])
      : _vm._e(),
    _vm.item.overrideCost && _vm.item.invoiceCostType
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Cost Updated For: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.invoiceCostType))]),
        ])
      : _vm._e(),
    _vm.contractorInvoiceDetail && _vm.contractorInvoiceDetail.invoiceDetail
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Original Cost: ")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormatedCurrency(
                    _vm.contractorInvoiceDetail.invoiceDetail.materialCost +
                      _vm.contractorInvoiceDetail.invoiceDetail.labourCost
                  )
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.overrideCost
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Overwrite Cost: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormatedCurrency(_vm.item.overrideCost))),
          ]),
        ])
      : _vm._e(),
    _vm.item.overrideReason
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Overwrite Reason: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.overrideReason))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Overwrite Contractor Invoice Cost")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }