var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "dashboard" },
        [
          _c("v-container", { attrs: { fluid: "", "pa-0": "" } }, [
            _c(
              "div",
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-container",
                          {
                            attrs: {
                              fluid: "",
                              "grid-list-md": "",
                              "pt-0": "",
                              "pa-0": "",
                            },
                          },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: {
                                  wrap: "",
                                  "calls-info": "",
                                  "request-info": "",
                                },
                              },
                              _vm._l(Object.keys(_vm.topTiles), function (key) {
                                return _c(
                                  "DashboardTopTile",
                                  _vm._b(
                                    {
                                      key: key,
                                      attrs: {
                                        "dashboard-tile-view":
                                          "IncidentManager",
                                      },
                                    },
                                    "DashboardTopTile",
                                    _vm.topTiles[key],
                                    false
                                  )
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        staticClass: "pr-0 mt-3",
                        attrs: { xs12: "", sm8: "", md9: "" },
                      },
                      [
                        true
                          ? _c("IncidentManagerTable", {
                              attrs: {
                                "table-name": "incidentManagerDashboard",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      {
                        attrs: {
                          xs12: "",
                          sm4: "",
                          md3: "",
                          "pl-3": "",
                          "mt-5": "",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "elevation-1 mt-4 white white--text pa-2 client-logos-section",
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-1" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "grey mb-3 lighten-4 pa-2",
                                        attrs: { xs12: "" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "subheading mb-2 secondary--text",
                                          },
                                          [
                                            _c("b", [_vm._v("The CET Way")]),
                                            _vm.isUserRoleAdministrator
                                              ? _c(
                                                  "span",
                                                  { staticClass: "edit-btn" },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ma-0",
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onEditCETNews()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [_vm._v("edit")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c("v-divider"),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "bar",
                                                rawName: "v-bar",
                                                value: {
                                                  useScrollbarPseudo: true,
                                                },
                                                expression:
                                                  "{ useScrollbarPseudo: true }",
                                              },
                                            ],
                                            staticClass: "pre-block",
                                          },
                                          [
                                            _c("v-layout", [
                                              _c(
                                                "pre",
                                                {
                                                  staticClass:
                                                    "body2 mb-0 mt-2 grey--text text--darken-1",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.sanitizeHTML(
                                                        _vm.cetNotePreview
                                                      )
                                                    ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.cetNotePreview)
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm6: "",
                                          "mb-2": "",
                                        },
                                      },
                                      [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _c("img", {
                                            staticClass: "img-responsive",
                                            attrs: { src: "/img/IRISLogo.png" },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm6: "",
                                          "mb-2": "",
                                        },
                                      },
                                      [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _c("img", {
                                            staticClass: "img-responsive",
                                            attrs: {
                                              src: "/img/perkboxLogo.png",
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _c("img", {
                                            staticClass: "img-responsive",
                                            attrs: {
                                              src: "/img/scorebuddyLogo.png",
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "" } },
                                      [
                                        _c("a", { attrs: { href: "#" } }, [
                                          _c("img", {
                                            staticClass: "img-responsive",
                                            attrs: { src: "/img/CETLogo.png" },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "v-dialog--scrollable",
                "max-width": "650",
                persistent: "",
              },
              model: {
                value: _vm.editCETNews,
                callback: function ($$v) {
                  _vm.editCETNews = $$v
                },
                expression: "editCETNews",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("The CET Way")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.editCETNews = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog px-3" },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("froala", {
                                attrs: {
                                  id: "froala",
                                  "data-vv-scope": "templateformRef",
                                  "data-vv-name": "Email",
                                  config: _vm.config,
                                  tag: "textarea",
                                },
                                model: {
                                  value: _vm.cetWayNoteModel.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.cetWayNoteModel, "note", $$v)
                                  },
                                  expression: "cetWayNoteModel.note",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.editCETNews = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.cetNoteSubmitLoading,
                            loading: _vm.cetNoteSubmitLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSaveCETWayNote()
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }