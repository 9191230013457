var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.item.requestType === "Material"
      ? _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v("Engineer Material Request")]),
        ])
      : _vm._e(),
    _vm.item.requestType === "Time"
      ? _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v("Engineer Extra Time Request")]),
        ])
      : _vm._e(),
    _vm.emergencyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency: ")]),
          _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
        ])
      : _vm._e(),
    _vm.item.requestType === "Material"
      ? _c("div", [
          _vm.item.materialName
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Material Name: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.materialName))]),
              ])
            : _vm._e(),
          _vm.item.materialType
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Material Type: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.materialType))]),
              ])
            : _vm._e(),
          _vm.item.quantity
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Quantity: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.quantity))]),
              ])
            : _vm._e(),
          _vm.item.materialCost
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Material Cost: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.materialCost))]),
              ])
            : _vm._e(),
          _vm.item.isPartAvailable
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Part Available: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.item.isPartAvailable ? "Yes" : "No")),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.item.requestType === "Time"
      ? _c("div", [
          _vm.item.extraHours
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Extra Hours: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.extraHours))]),
              ])
            : _vm._e(),
          _vm.item.timeRequestReason
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Request Reason: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.timeRequestReason))]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.item.description
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Description: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.description))]),
        ])
      : _vm._e(),
    _vm.item.requestStatus
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Status: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.requestStatus))]),
        ])
      : _vm._e(),
    _vm.item.companyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Contractor: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.companyName))]),
        ])
      : _vm._e(),
    _vm.item.address
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.address))]),
        ])
      : _vm._e(),
    _vm.item.fileURL
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("URL: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.fileURL))]),
        ])
      : _vm._e(),
    _vm.getFormattedDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Request process at: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.requestProcessedAt))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }