var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            "content-class": "v-dialog--scrollable",
            persistent: "",
          },
          model: {
            value: _vm.showMaterialDialog,
            callback: function ($$v) {
              _vm.showMaterialDialog = $$v
            },
            expression: "showMaterialDialog",
          },
        },
        [
          _c("Material", {
            ref: "materialRef",
            attrs: {
              "show-material-dialog": _vm.showMaterialDialog,
              "material-item": _vm.material,
            },
            on: {
              OnMaterialSave: _vm.saveMaterial,
              OnMaterialDialogClose: _vm.closeDialog,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 mb-2", attrs: { "primary-title": "" } },
            [
              _c("h3", { staticClass: "mb-0" }, [_vm._v("Material List")]),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0",
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "search",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", "nudge-left": "30px" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "mr-0 add-material-action",
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.showMaterialDialog = true
                                  },
                                },
                              },
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { color: "grey" } }, [
                                _vm._v("add"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Add Material")])]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "gridView material-list",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.materialList,
                  loading: _vm.isLoading,
                  search: _vm.search,
                  pagination: _vm.pagination,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.name))]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.isAdditionalInfoRequired
                                  ? "Yes"
                                  : "No"
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.isExcludedFromClientInvoicing
                                  ? "Yes"
                                  : "No"
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.isCostExcludedFromContractorFixedFee
                                  ? "Yes"
                                  : "No"
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.excludeFromCustomerApprovalUpdates
                                  ? "Yes"
                                  : "No"
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "px-4 text-xs-right" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mx-0 btn-edit",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEditClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("edit")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Edit")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "mx-0 ml-2 btn-delete",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onDeleteClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("delete")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Delete")])]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }