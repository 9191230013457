var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm.getEngineerVisitDetail.siReturnVisitReason
          ? _c(
              "div",
              { staticClass: "revisit-header" },
              [
                _c(
                  "v-tooltip",
                  {
                    staticClass:
                      "v-alert custom-warning-success pa-2 pink lighten-5 pink--text",
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "span",
                                _vm._g({ staticClass: "return-visit" }, on),
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/return-visit.svg",
                                      alt: "",
                                    },
                                  }),
                                  _c("span", [_vm._v("Revisit Requested")]),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1242558159
                    ),
                  },
                  [
                    _c("div", [
                      _c("div", [
                        _c("b", [_vm._v("Reason:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getEngineerVisitDetail.siReturnVisitReason
                            ) +
                            " "
                        ),
                      ]),
                      _vm.getEngineerVisitDetail.siReturnVisitNote
                        ? _c("div", [
                            _c("b", [_vm._v("Note:")]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getEngineerVisitDetail.siReturnVisitNote
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      _c("div", [
                        _c("b", [_vm._v("Total Visits:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.getEngineerVisitDetail.visitNumber) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "display-flex flex-wrap items-center justify-between gap-2 small-text mb-2",
            },
            [
              _c("b", [_vm._v(_vm._s(_vm.getStatusDetails()))]),
              _vm.getEngineerVisitDetail.visitOutcomeDescription
                ? _c(
                    "v-chip",
                    {
                      staticClass: "ma-0 white--text",
                      attrs: {
                        color:
                          _vm.getEngineerVisitDetail.visitOutcomeDescription ===
                          "Job Completed"
                            ? "green"
                            : "orange",
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getEngineerVisitDetail.visitOutcomeDescription
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "small-text mb-2" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormattedDate(_vm.getEngineerVisitDetail.processAt)
                ) +
                " "
            ),
          ]),
        ]),
        _vm.getEngineerVisitDetail.status ===
        _vm.engineerJobVisitStatus[_vm.engineerJobVisitStatus.RunningLate]
          ? _c("div", [
              _c("div", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("ETA From:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormattedDate(_vm.getEngineerVisitDetail.etaFrom)
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "mb-1" }, [
                _c("b", [_vm._v("ETA To:")]),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormattedDate(_vm.getEngineerVisitDetail.etaTo)
                    ) +
                    " "
                ),
              ]),
            ])
          : _vm._e(),
        _c("div", [_vm._v(_vm._s(_vm.getEngineerVisitDetail.description))]),
        _vm.claimPictures && !_vm.loading
          ? _c(
              "div",
              { staticClass: "avtar-container" },
              [
                _vm._l(_vm.claimPictures, function (item, i) {
                  return [
                    i < 2
                      ? _c(
                          "v-avatar",
                          { key: i, staticClass: "mr-2 card-images" },
                          [
                            _c("img", {
                              attrs: { src: item.fileURL, alt: item.title },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _c(
                  "v-avatar",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.claimPictures.length > 2,
                        expression: "claimPictures.length > 2",
                      },
                    ],
                  },
                  [
                    _c("span", { staticClass: "total-images" }, [
                      _vm._v("+" + _vm._s(_vm.claimPictures.length - 2)),
                    ]),
                  ]
                ),
              ],
              2
            )
          : _vm._e(),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "pa-2" },
              [
                _c("v-progress-circular", {
                  attrs: { indeterminate: "", color: "grey" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }