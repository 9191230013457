var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _vm.item && _vm.item.emergenciesQAs.length > 0
        ? _c(
            "div",
            _vm._l(
              _vm.item.emergenciesQAs,
              function (emergencyQAItem, questionIndex) {
                return _c(
                  "div",
                  { key: questionIndex },
                  [
                    emergencyQAItem.answer
                      ? _c("AddEmergencyAnswerLog", {
                          attrs: {
                            "request-body": emergencyQAItem,
                            index: questionIndex,
                            "has-list": true,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
      _vm.item && _vm.item.healthAndSafetyQAs.length > 0
        ? _c("v-divider", { staticClass: "mb-2" })
        : _vm._e(),
      _vm.item && _vm.item.healthAndSafetyQAs.length > 0
        ? _c(
            "div",
            _vm._l(
              _vm.item.healthAndSafetyQAs,
              function (healthAndSafetyItem, healthAndSafetyItemIndex) {
                return _c(
                  "div",
                  { key: healthAndSafetyItemIndex },
                  [
                    healthAndSafetyItem.isHealthAffected !== null
                      ? _c("AddVulnerabilityAndHealthSafetyAnswerLog", {
                          attrs: {
                            "request-body": healthAndSafetyItem,
                            "request-type": "AddHealthAndSafetyAnswer",
                            index: healthAndSafetyItemIndex,
                            "has-list": true,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
      _vm.item.vulnerabilityQA
        ? _c("v-divider", { staticClass: "mb-2" })
        : _vm._e(),
      _vm.item.vulnerabilityQA
        ? _c("AddVulnerabilityAndHealthSafetyAnswerLog", {
            attrs: {
              "request-body": _vm.item.vulnerabilityQA,
              "request-type": "AddVulnerabilityAnswer",
              "has-list": true,
            },
          })
        : _vm._e(),
      _vm.item.accessNoteQA
        ? _c("v-divider", { staticClass: "mb-2" })
        : _vm._e(),
      _vm.item.accessNoteQA
        ? _c("AddAccessNoteAnswerLog", {
            attrs: { "request-body": _vm.item.accessNoteQA },
          })
        : _vm._e(),
      _vm.item.customerAvailability.timeSlot.length > 0
        ? _c("v-divider", { staticClass: "mb-2" })
        : _vm._e(),
      _vm.item.customerAvailability.timeSlot.length > 0
        ? _c("AddCustomerAvailabilityTimeSlotLog", {
            attrs: { "request-body": _vm.item.customerAvailability },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update QuestionMode Job Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }