var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.siRevisitDialog,
            callback: function ($$v) {
              _vm.siRevisitDialog = $$v
            },
            expression: "siRevisitDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Revisit")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeReturnVisitDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mb-1": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "pr-2": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "ETAFromDateTimePicker",
                            attrs: {
                              "date-time":
                                _vm.addSIRevisitRequest.returnVisitETAFrom,
                              "is-static-location": false,
                              "place-holder-text": "Revisit ETA From",
                              "min-date": _vm.minETADate,
                              "is-validation-required": true,
                              "allowed-minutes-step": true,
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.addSIRevisitRequest,
                                  "returnVisitETAFrom",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.addSIRevisitRequest,
                                  "returnVisitETAFrom",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "pl-2": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "ETATodateTimePicker",
                            attrs: {
                              "date-time":
                                _vm.addSIRevisitRequest.returnVisitETATo,
                              "is-static-location": false,
                              "place-holder-text": "Revisit ETA To",
                              "min-date": _vm.minETAToDate,
                              "is-validation-required": true,
                              "allowed-minutes-step": true,
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.addSIRevisitRequest,
                                  "returnVisitETATo",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.addSIRevisitRequest,
                                  "returnVisitETATo",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isWrongDateRange
                        ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                            _c("span", { staticClass: "error--text" }, [
                              _vm._v("To date must be greater than from date."),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "slt-engineerList",
                          attrs: { xs12: "" },
                        },
                        [
                          _c("v-autocomplete", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "engineerPicker",
                            staticClass: "required engineer-list",
                            attrs: {
                              items: _vm.engineerList,
                              label: "Select Engineer",
                              "item-text": "name",
                              "item-value": "id",
                              "menu-props": { maxHeight: "auto" },
                              chips: "",
                              required: "",
                              "data-vv-scope": "siRevisitformReference",
                              "data-vv-name": "Select Engineer",
                              "error-messages":
                                _vm.$validator.errors.collect(
                                  "Select Engineer"
                                ),
                              disabled:
                                !_vm.addSIRevisitRequest.returnVisitETAFrom ||
                                !_vm.addSIRevisitRequest.returnVisitETATo,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-chip",
                                      {
                                        key: JSON.stringify(data.item),
                                        staticClass: "chip--select-multi",
                                        attrs: { selected: data.selected },
                                      },
                                      [
                                        _c("v-avatar", [
                                          data.item.profileImageThumbnailUrl
                                            ? _c("img", {
                                                attrs: {
                                                  src: data.item
                                                    .profileImageThumbnailUrl,
                                                },
                                              })
                                            : _c("img", {
                                                attrs: {
                                                  src: "/img/unknownuser.png",
                                                },
                                              }),
                                        ]),
                                        _vm._v(
                                          " " + _vm._s(data.item.name) + " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    typeof data.item !== "object"
                                      ? [
                                          _c("v-list-tile-content", {
                                            domProps: {
                                              textContent: _vm._s(data.name),
                                            },
                                          }),
                                        ]
                                      : [
                                          _c("v-list-tile-avatar", [
                                            data.item.profileImageThumbnailUrl
                                              ? _c("img", {
                                                  attrs: {
                                                    src: data.item
                                                      .profileImageThumbnailUrl,
                                                  },
                                                })
                                              : _c("img", {
                                                  attrs: {
                                                    src: "/img/unknownuser.png",
                                                  },
                                                }),
                                          ]),
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c("v-list-tile-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedEngineerId,
                              callback: function ($$v) {
                                _vm.selectedEngineerId = $$v
                              },
                              expression: "selectedEngineerId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mb-1": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "slt-returnTypeVisit" },
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  items: _vm.returnVisitTypeList,
                                  "item-text": "description",
                                  "item-value": "description",
                                  label: "What is the type for return visit",
                                  required: "",
                                  "data-vv-scope": "siRevisitformReference",
                                  "data-vv-name": "Return Visit Type",
                                  "error-messages":
                                    _vm.errors.collect("Return Visit Type"),
                                  name: "reVisitReason",
                                },
                                on: { change: _vm.onReturnVisitTypeChange },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c(
                                                  "v-list-tile-title",
                                                  {
                                                    class:
                                                      data.item.description ===
                                                      "Configure"
                                                        ? "bold-select"
                                                        : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value:
                                    _vm.addSIRevisitRequest.returnVisitReason,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addSIRevisitRequest,
                                      "returnVisitReason",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addSIRevisitRequest.returnVisitReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "mb-3": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Note",
                              rows: "5",
                              required: "",
                              "data-vv-scope": "siRevisitformReference",
                              "data-vv-name": "Note",
                              "error-messages": _vm.errors.collect("Note"),
                            },
                            model: {
                              value: _vm.addSIRevisitRequest.returnVisitNote,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addSIRevisitRequest,
                                  "returnVisitNote",
                                  $$v
                                )
                              },
                              expression: "addSIRevisitRequest.returnVisitNote",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeReturnVisitDialog")
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-submit",
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.onSubmitLoading || _vm.getEngineersLoading,
                        loading: _vm.onSubmitLoading,
                      },
                      on: { click: _vm.submitSIRevisitRequest },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }