var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      class: [
        _vm.isDisabled ? "menu-disabled" : "",
        _vm.isPackageSelection ? "menu-product" : "",
      ],
      attrs: {
        bottom: "",
        "close-on-content-click": false,
        "nudge-width": 150,
        "offset-x": "",
        disabled: _vm.isDisabled,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "a",
                _vm._g(
                  {
                    staticClass: "subheading priceLabel",
                    class: _vm.isPriceUpdated ? "red--text" : "",
                    attrs: {
                      href: "javascript:void(0)",
                      disabled: _vm.isDisabled,
                    },
                    on: { click: _vm.editProductPrice },
                  },
                  on
                ),
                [_c("b", [_vm._v(_vm._s(_vm.priceLabel))])]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-text-field", {
            staticClass: "pa-2",
            attrs: {
              label: "Price",
              "single-line": "",
              maxlength: "9",
              name: "productPrice",
            },
            on: {
              keypress: function ($event) {
                return _vm.numberKeyValidation($event)
              },
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = _vm._n($$v)
              },
              expression: "value",
            },
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", flat: "flat" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.menu = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "save-btn",
                  attrs: {
                    color: "primary",
                    flat: "",
                    disabled: _vm.value < 0 || _vm.value === _vm.productPrice,
                  },
                  on: { click: _vm.saveProductPrice },
                },
                [_vm._v(" Save ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }