var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "" },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "wrapper" },
        [
          _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Job Offer")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeJobOfferDialog")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              !_vm.jobOfferDecision.isAccepted &&
              !_vm.jobOfferDecision.isRejected
                ? _c("JobOffer")
                : _vm._e(),
              _vm.jobOfferDecision.isAccepted
                ? _c("AssignEngineer", {
                    on: {
                      engineerAssigned: function ($event) {
                        return _vm.$emit("closeJobOfferDialog")
                      },
                    },
                  })
                : _vm.jobOfferDecision.isRejected
                ? _c("JobRejectionReason", {
                    on: {
                      jobRejected: function ($event) {
                        return _vm.$emit("closeJobOfferDialog")
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }