var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "clientrate" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "1200",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.repairEstimationDialog,
            callback: function ($$v) {
              _vm.repairEstimationDialog = $$v
            },
            expression: "repairEstimationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Repair Estimate")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeRepairEstimationDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "px-3 scroll-content-dialog repair-estimation-content",
                },
                [
                  _c("v-progress-circular", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.ready,
                        expression: "!ready",
                      },
                    ],
                    style: _vm.getLoaderStyle(50),
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  }),
                  _c(
                    "v-layout",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.ready,
                          expression: "ready",
                        },
                      ],
                      attrs: { wrap: "", "mt-0": "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass:
                                "elevation-1 repair-estimation-table",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.repairEstimateProducts,
                                "item-key": "subCode",
                                "hide-actions": "",
                                expand: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "headers",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        _vm._l(
                                          props.headers,
                                          function (header) {
                                            return _c(
                                              "th",
                                              {
                                                key: header.text,
                                                class: header.class,
                                                attrs: { align: header.align },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(header.text) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c("tr", [
                                        _c(
                                          "td",
                                          [
                                            _c("v-autocomplete", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass: "subCategory",
                                              attrs: {
                                                maxlength: "500",
                                                items: _vm.subCategories,
                                                name:
                                                  "SubCategory" + props.index,
                                                "data-vv-scope":
                                                  "frmRepairEstimation",
                                                "data-vv-name":
                                                  "Sub Category" + props.index,
                                                "error-messages":
                                                  _vm.validationMessage(
                                                    "Sub Category" + props.index
                                                  ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onSubCategoryChange(
                                                    $event,
                                                    props.index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.subCategory,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "subCategory",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "props.item.subCategory",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-autocomplete", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass: "description",
                                              attrs: {
                                                maxlength: "500",
                                                name: "description",
                                                items: _vm.getDescriptionList(
                                                  props.item.subCategory
                                                ),
                                                "data-vv-scope":
                                                  "frmRepairEstimation",
                                                "data-vv-name":
                                                  "Description" + props.index,
                                                "error-messages":
                                                  _vm.validationMessage(
                                                    "Description" + props.index
                                                  ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onDescriptionChange(
                                                    $event,
                                                    props.item.subCategory,
                                                    props.index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.description,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "description",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.description",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                maxlength: "30",
                                                name: "unit",
                                                readonly:
                                                  !props.item.subCategory &&
                                                  !props.item.description,
                                              },
                                              model: {
                                                value: props.item.unit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "unit",
                                                    $$v
                                                  )
                                                },
                                                expression: "props.item.unit",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              staticClass: "prepend-icon-image",
                                              attrs: {
                                                maxlength: "8",
                                                "prepend-icon": "close",
                                                name: "unitPrice" + props.index,
                                                "data-vv-scope":
                                                  "frmRepairEstimation",
                                                "data-vv-name":
                                                  "Unit Price" + props.index,
                                                "error-messages":
                                                  _vm.validationMessage(
                                                    "Unit Price" + props.index
                                                  ),
                                                readonly:
                                                  !props.item.subCategory &&
                                                  !props.item.description,
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.changeClientPrice(
                                                    props.item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.unitPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "unitPrice",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.unitPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    rules: { max_value: 100 },
                                                  },
                                                  expression:
                                                    "{ rules: { max_value: 100 } }",
                                                },
                                              ],
                                              staticClass:
                                                "prepend-icon-image percent",
                                              attrs: {
                                                maxlength: "5",
                                                "append-icon": "close",
                                                name: "VAT" + props.index,
                                                "data-vv-scope":
                                                  "frmRepairEstimation",
                                                "data-vv-name":
                                                  "VAT" + props.index,
                                                "error-messages":
                                                  _vm.validationMessage(
                                                    "VAT" + props.index,
                                                    true
                                                  ),
                                                readonly:
                                                  !props.item.subCategory &&
                                                  !props.item.description,
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.changeClientPrice(
                                                    props.item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.vatRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "vatRate",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.vatRate",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              ref: "quantity" + props.index,
                                              attrs: {
                                                maxlength: "8",
                                                name: "quantity" + props.index,
                                                "data-vv-scope":
                                                  "frmRepairEstimation",
                                                "data-vv-name":
                                                  "Quantity" + props.index,
                                                "error-messages":
                                                  _vm.validationMessage(
                                                    "Quantity" + props.index
                                                  ),
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.quantityNumberValidation(
                                                    $event
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.changeClientPrice(
                                                    props.item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.quantity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "quantity",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.quantity",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              staticClass: "prepend-icon-image",
                                              attrs: {
                                                maxlength: "8",
                                                "prepend-icon": "close",
                                                name: "clientPrice",
                                                readonly: true,
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.clientPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "clientPrice",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.clientPrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              staticClass: "prepend-icon-image",
                                              attrs: {
                                                maxlength: "8",
                                                "prepend-icon": "close",
                                                name: "repairCost",
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: props.item.repairCost,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "repairCost",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.repairCost",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c("v-text-field", {
                                              staticClass: "prepend-icon-image",
                                              attrs: {
                                                maxlength: "8",
                                                "prepend-icon": "close",
                                                name: "customerAppointedTradePrice",
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  props.item
                                                    .customerAppointedTradePrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "customerAppointedTradePrice",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "props.item.customerAppointedTradePrice",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _vm
                                                            .repairEstimateProducts
                                                            .length > 1
                                                            ? _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "mr-0 btn-remove",
                                                                    attrs: {
                                                                      flat: "",
                                                                      icon: "",
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.removeAdditionalEstimate(
                                                                            props.item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        dark: "",
                                                                        color:
                                                                          "primary",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "close"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Remove Row"),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("template", { slot: "footer" }, [
                                _c("td", { attrs: { colspan: "3" } }),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("strong", [_vm._v("Total")]),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getFormatedCurrency(
                                            _vm.repairEstimateProducts.reduce(
                                              (a, b) =>
                                                a +
                                                (b.vatRate && b.clientPrice
                                                  ? (b.clientPrice *
                                                      b.vatRate) /
                                                    100
                                                  : 0),
                                              0
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.repairEstimateProducts.reduce(
                                            (a, b) =>
                                              a + (b.quantity ? b.quantity : 0),
                                            0
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("b", { staticClass: "secondary--text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getFormatedCurrency(
                                            _vm.repairEstimateProducts.reduce(
                                              (a, b) =>
                                                a +
                                                (b.clientPrice
                                                  ? b.clientPrice +
                                                    (b.clientPrice *
                                                      (b.vatRate
                                                        ? b.vatRate
                                                        : 1)) /
                                                      100
                                                  : 0),
                                              0
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("td", { attrs: { colspan: "3" } }),
                              ]),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "text-xs-right mt-2" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mr-0 btn-add",
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                  small: "",
                                                },
                                                on: {
                                                  click:
                                                    _vm.addAdditionalEstimation,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "white--text",
                                                  attrs: {
                                                    dark: "",
                                                    small: "",
                                                  },
                                                },
                                                [_vm._v("add")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Add Row")])]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeRepairEstimationDialog")
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-submit",
                      attrs: {
                        color: "primary",
                        disabled:
                          _vm.isLoading ||
                          _vm.repairEstimateProducts.length === 0,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.UpsertRepairEstimateRequest },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }