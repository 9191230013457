var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: {
                    "content-class": "v-dialog--scrollable",
                    "max-width": _vm.jobOfferedActionTimeExpired ? 480 : 800,
                    persistent: "",
                  },
                  model: {
                    value: _vm.showOfferedJobDetails,
                    callback: function ($$v) {
                      _vm.showOfferedJobDetails = $$v
                    },
                    expression: "showOfferedJobDetails",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Offered Job Details"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "close-btn",
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showOfferedJobDetails = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "add-dialog-content scroll-content-dialog pa-0",
                        },
                        [
                          !_vm.jobOfferedActionTimeExpired
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "", "pa-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "px-2": "",
                                        "py-1": "",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Job:")]),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .contractorOfferedJobAppointedModelTemp
                                                  .jobId
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Post Code:")]),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .contractorOfferedJobAppointedModelTemp
                                                  .postcode
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Client Name:")]),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .contractorOfferedJobAppointedModelTemp
                                                  .customerName
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Emergency:")]),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.contractorOfferedJobModel
                                                  .emergencyTypeDescription
                                              )
                                          ),
                                        ]),
                                        _vm.contractorOfferedJobModel
                                          .emergencyDetailDescription
                                          ? _c("span", [
                                              _vm._v(
                                                "  (" +
                                                  _vm._s(
                                                    _vm
                                                      .contractorOfferedJobModel
                                                      .emergencyDetailDescription
                                                  ) +
                                                  ") "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "mb-2" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Address:")]),
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm
                                                  .contractorOfferedJobAppointedModelTemp
                                                  .address
                                              )
                                          ),
                                        ]),
                                      ]),
                                      _vm.contractorOfferedJobModel
                                        .engineerSiteSLA
                                        ? _c("div", [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("SLA cut off time :"),
                                              ]),
                                            ]),
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm
                                                        .contractorOfferedJobModel
                                                        .engineerSiteSLA
                                                    )
                                                  )
                                              ),
                                            ]),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        "pl-2": "",
                                        xs6: "",
                                        "pr-2": "",
                                      },
                                    },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "ETAfromdateTimePicker",
                                        attrs: {
                                          "date-time":
                                            _vm.acceptAutoDeploymentJobModel
                                              .etaFrom,
                                          "is-static-location": false,
                                          "place-holder-text": "ETA From",
                                          "is-validation-required": true,
                                          "allowed-minutes-step": true,
                                          "allowed-dates":
                                            _vm.getDatesFromCustomerAvailability,
                                          "min-date": _vm.minETADate,
                                          "min-time": _vm.minETATime,
                                          "max-date": _vm.maxETAFromDate,
                                          "max-time": _vm.maxETAFromTime,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.acceptAutoDeploymentJobModel,
                                              "etaFrom",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.acceptAutoDeploymentJobModel,
                                              "etaFrom",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs6: "",
                                        "pl-2": "",
                                        "pr-2": "",
                                      },
                                    },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "ETATodateTimePicker",
                                        attrs: {
                                          "date-time":
                                            _vm.acceptAutoDeploymentJobModel
                                              .etaTo,
                                          "is-static-location": false,
                                          "place-holder-text": "ETA To",
                                          "is-validation-required": true,
                                          "allowed-minutes-step": true,
                                          "min-date": _vm.minETAToDate,
                                          "min-time": _vm.minETAToTime,
                                          "max-date": _vm.maxETAToDate,
                                          "max-time": _vm.maxETAToTime,
                                          "allowed-dates":
                                            _vm.getDatesFromCustomerAvailability,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.acceptAutoDeploymentJobModel,
                                              "etaTo",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.acceptAutoDeploymentJobModel,
                                              "etaTo",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.isWrongDateRange || _vm.isWrongTimeRange
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-2": "" } },
                                        [
                                          _vm.isWrongDateRange
                                            ? _c(
                                                "span",
                                                { staticClass: "error--text" },
                                                [
                                                  _vm._v(
                                                    "To date must be greater than From date."
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isWrongTimeRange
                                            ? _c(
                                                "span",
                                                { staticClass: "error--text" },
                                                [
                                                  _vm._v(
                                                    " Customer is not available within contractor ETA. "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.showDeploymentMessage
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-2": "" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "warning--text" },
                                            [
                                              _vm._v(
                                                "You are not allowed to deploy outside the SLA cut off time."
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "ma-2",
                                      attrs: { xs12: "" },
                                    },
                                    [
                                      _c("label", [
                                        _c("b", [
                                          _vm._v("Customer's Availability:"),
                                        ]),
                                      ]),
                                      _c("Availability", {
                                        attrs: {
                                          "customer-availability":
                                            _vm.customerAvailability,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "pa-2 ma-2 grey lighten-3" },
                                    [
                                      _c("b", [
                                        _vm._v(" Time left to accept job "),
                                        _c(
                                          "b",
                                          { staticClass: "secondary--text" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  color: "secondary",
                                                },
                                              },
                                              [_vm._v("access_time")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.contractorJobOfferTimerCounter
                                                ) +
                                                " "
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-layout",
                                { staticClass: "job-action-time-expired" },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "px-2": "",
                                        "py-1": "",
                                      },
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "Time duration to accept job has been expired!"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      !_vm.jobOfferedActionTimeExpired
                        ? _c(
                            "v-card-actions",
                            { staticClass: "text-xs-right pa-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "red white--text",
                                  attrs: {
                                    disabled:
                                      _vm.isAcceptJobLoading ||
                                      _vm.isRejectJobLoading,
                                  },
                                  on: {
                                    click: _vm.rejectOfferedJobConfirmation,
                                  },
                                },
                                [_vm._v(" Reject Job ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "green white--text acceptJob-btn",
                                  attrs: {
                                    loading: _vm.isAcceptJobLoading,
                                    disabled:
                                      _vm.isAcceptJobLoading ||
                                      _vm.isRejectJobLoading ||
                                      _vm.isWrongDateRange ||
                                      _vm.isWrongTimeRange,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.acceptOfferedJob.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Accept Job ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showEngineerSelectionDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "content-class": "v-dialog--scrollable",
                        "max-width": "600",
                        persistent: "",
                      },
                      model: {
                        value: _vm.showEngineerSelectionDialog,
                        callback: function ($$v) {
                          _vm.showEngineerSelectionDialog = $$v
                        },
                        expression: "showEngineerSelectionDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Assign Engineer"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "close-btn",
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showEngineerSelectionDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "add-dialog-content scroll-content-dialog pa-0",
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "pa-2": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "px-2": "",
                                        "py-1": "",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "hide-option",
                                        attrs: {
                                          loading:
                                            _vm.getContractorEngineersLoading,
                                          items: _vm.getTradeWiseEngineers(
                                            _vm
                                              .contractorOfferedJobAppointedModelTemp
                                              .tradeId
                                          ),
                                          label: "Select Engineer",
                                          "item-text": "name",
                                          "item-value": "id",
                                          "persistent-hint": "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.assignEngineerToJob(
                                              _vm.contractorOfferedJobAppointedModelTemp,
                                              $event
                                            )
                                          },
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onClickAssignEngineer(
                                              _vm.contractorOfferedJobAppointedModelTemp
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function (data) {
                                                return [
                                                  _c("v-avatar", [
                                                    data.item
                                                      .profileImageThumbnailUrl
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: data.item
                                                              .profileImageThumbnailUrl,
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: "/img/unknownuser.png",
                                                          },
                                                        }),
                                                  ]),
                                                  _vm._v(
                                                    "   " +
                                                      _vm._s(
                                                        data.item.firstName +
                                                          " " +
                                                          data.item.lastName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "item",
                                              fn: function (data) {
                                                return [
                                                  typeof data.item !== "object"
                                                    ? [
                                                        _c(
                                                          "v-list-tile-content",
                                                          {
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  data.item
                                                                ),
                                                            },
                                                          }
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-list-tile-avatar",
                                                          [
                                                            data.item
                                                              .profileImageThumbnailUrl
                                                              ? _c("img", {
                                                                  attrs: {
                                                                    src: data
                                                                      .item
                                                                      .profileImageThumbnailUrl,
                                                                  },
                                                                })
                                                              : _c("img", {
                                                                  attrs: {
                                                                    src: "/img/unknownuser.png",
                                                                  },
                                                                }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-tile-content",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .firstName +
                                                                        " " +
                                                                        data
                                                                          .item
                                                                          .lastName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-sub-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .email
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3550579976
                                        ),
                                        model: {
                                          value: _vm.selectedEngineerId,
                                          callback: function ($$v) {
                                            _vm.selectedEngineerId = $$v
                                          },
                                          expression: "selectedEngineerId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "text-xs-right pa-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary text--white",
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showEngineerSelectionDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showRejectReasonSelectionDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "content-class": "v-dialog--scrollable",
                        "max-width": "600",
                        persistent: "",
                      },
                      model: {
                        value: _vm.showRejectReasonSelectionDialog,
                        callback: function ($$v) {
                          _vm.showRejectReasonSelectionDialog = $$v
                        },
                        expression: "showRejectReasonSelectionDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [_vm._v("Reject Job")]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "close-btn",
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showRejectReasonSelectionDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "add-dialog-content scroll-content-dialog pa-0",
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "", "pa-3": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          items: _vm.jobRejectReasonList,
                                          "item-text": "description",
                                          "item-value": "id",
                                          label:
                                            "Select cancellation reason for job",
                                          required: "",
                                          loading:
                                            _vm.jobRejectReasonListLoading,
                                          "data-vv-scope":
                                            "frmCancellationReason",
                                          "data-vv-name": "Cancel Job Reason",
                                          "error-messages":
                                            _vm.errors.collect(
                                              "Cancel Job Reason"
                                            ),
                                        },
                                        model: {
                                          value: _vm.jobRejectReason,
                                          callback: function ($$v) {
                                            _vm.jobRejectReason = $$v
                                          },
                                          expression: "jobRejectReason",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: { label: "Description" },
                                        model: {
                                          value: _vm.jobRejectDescription,
                                          callback: function ($$v) {
                                            _vm.jobRejectDescription = $$v
                                          },
                                          expression: "jobRejectDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "text-xs-right pa-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showRejectReasonSelectionDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary text--white",
                                  attrs: {
                                    loading: _vm.isRejectJobLoading,
                                    disabled: _vm.isRejectJobLoading,
                                  },
                                  on: { click: _vm.rejectedJob },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }