var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobPackage
    ? _c(
        "v-flex",
        [
          _vm.jobPackage.packageInfo
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("b", [_vm._v(_vm._s(_vm.jobPackage.packageInfo.name))]),
                  ]),
                  _c(
                    "v-container",
                    { staticClass: "pa-0 pb-2 pb-3" },
                    [_c("v-flex", [_vm._v(_vm._s(_vm.packageInfoDisplay))])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.jobPackage.packageInfo
            ? _c("v-divider", { staticClass: "mb-3" })
            : _vm._e(),
          (_vm.jobPackage.extraProducts &&
            _vm.jobPackage.extraProducts.length > 0) ||
          (_vm.jobPackage.bulkPitProducts &&
            _vm.jobPackage.bulkPitProducts.length > 0)
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("b", [_vm._v("Additional Products")]),
                  ]),
                  _c(
                    "v-container",
                    { staticClass: "pa-0 pb-2 pb-3" },
                    [
                      _vm._l(
                        _vm.jobPackage.extraProducts,
                        function (product, ind) {
                          return _c("v-flex", { key: ind }, [
                            _vm._v(
                              " " +
                                _vm._s(product.quantity) +
                                " x " +
                                _vm._s(product.name) +
                                " "
                            ),
                          ])
                        }
                      ),
                      _vm._l(
                        _vm.jobPackage.bulkPitProducts,
                        function (product, ind) {
                          return _c("v-flex", { key: ind }, [
                            _vm._v(
                              " " +
                                _vm._s(product.quantity) +
                                " x " +
                                _vm._s(product.name) +
                                " "
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.jobPackage.monitoringProducts &&
          _vm.jobPackage.monitoringProducts.length > 0
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("b", [_vm._v("Monitoring Products")]),
                  ]),
                  _c(
                    "v-container",
                    { staticClass: "pa-0 pb-2 pb-3" },
                    _vm._l(
                      _vm.jobPackage.monitoringProducts,
                      function (product, ind) {
                        return _c(
                          "v-flex",
                          { key: ind },
                          [
                            _c("div", { staticClass: "xs12" }, [
                              _vm._v(
                                _vm._s(product.quantity) +
                                  " x " +
                                  _vm._s(product.name)
                              ),
                            ]),
                            product.selectedChildProducts &&
                            product.selectedChildProducts.length
                              ? _vm._l(
                                  product.selectedChildProducts,
                                  function (child, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "xs12" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(child.quantity) +
                                            " x " +
                                            _vm._s(child.name) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }