var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v(_vm._s(_vm.dialogTitle))]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "close-configure-dropdown",
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeConfigureDropdownDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "scroll-content-dialog" },
        [
          _c("v-progress-circular", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoaded,
                expression: "isLoaded",
              },
            ],
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoaded,
                  expression: "!isLoaded",
                },
              ],
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "gridView tbody-scroll-table",
                  attrs: { xs12: "" },
                },
                [
                  _c(
                    "v-data-table",
                    {
                      attrs: {
                        headers: _vm.headers,
                        items: _vm.items,
                        "item-key": "id",
                        loading: _vm.isLoading,
                        "hide-actions": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "items",
                          fn: function (props) {
                            return [
                              _c("tr", { ref: "datatable" }, [
                                _c(
                                  "td",
                                  { staticClass: "text-xs-left" },
                                  [
                                    _vm.isUpdateRecord &&
                                    _vm.index === props.item.id
                                      ? _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "pt-2 required",
                                          attrs: {
                                            "data-vv-scope":
                                              "updateRecordFormRef",
                                            "data-vv-name": "editDescription",
                                            name: "editDescription",
                                            "error-messages":
                                              _vm.validationMessage(
                                                "editDescription"
                                              ),
                                          },
                                          model: {
                                            value: props.item.description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                props.item,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "props.item.description",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(props.item.description)
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-right" },
                                  [
                                    _vm.isUpdateRecord &&
                                    _vm.index === props.item.id
                                      ? _c(
                                          "v-flex",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  name: "updateRecordButton",
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "primary",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onAddUpdateRecord(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "update"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Update")])]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  name: "cancelRecordButton",
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "primary",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onCancelEditRecord(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v("close"),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Cancel")])]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-flex",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  name: "editRecordButton",
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "primary",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEditRecord(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v("edit"),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Edit")])]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  name: "deleteRecordButton",
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "primary",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onDeleteRecordClick(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "delete"
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Delete")])]
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "footer" }, [
                        _c("tr", [
                          _c(
                            "td",
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Description",
                                  required: "",
                                  "data-vv-scope": "addRecordFormRef",
                                  "data-vv-name": "Description",
                                  name: "Description",
                                  "error-messages":
                                    _vm.errors.collect("Description"),
                                },
                                on: { focus: _vm.onAddDescriptionFocus },
                                model: {
                                  value: _vm.record.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.record, "description", $$v)
                                  },
                                  expression: "record.description",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "text-xs-right" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  name: "addRecordButton",
                                                  icon: "",
                                                  flat: "",
                                                  color: "primary",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onAddUpdateRecord(
                                                      null
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_c("v-icon", [_vm._v("add")])],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [_c("span", [_vm._v("Add")])]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }