var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm.item.companyName
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Contractor: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.companyName))]),
            ])
          : _vm._e(),
        _vm.item.status
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Status: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.status))]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }