var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "clientrate elevation-0" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-1" },
                [
                  !_vm.showRateTemplateInDialog
                    ? _c("v-card-title", [_c("h3", [_vm._v("Client Rate")])])
                    : _vm._e(),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "align-center",
                              attrs: { wrap: "" },
                            },
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "newindicator-chip search-field",
                                  },
                                  [
                                    !_vm.isAccessToCreateRatePackage
                                      ? _c("v-autocomplete", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "selectedRatePackage",
                                          attrs: {
                                            label: "Search package",
                                            "return-object": "",
                                            items: _vm.getClientRatePackageList,
                                            "item-text": "packageName",
                                            "item-value": "id",
                                            "data-vv-scope": "frmClientRate",
                                            "data-vv-name": "Search package",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Search package"
                                              ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onClientRatePackageChange(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedRatePackage,
                                            callback: function ($$v) {
                                              _vm.selectedRatePackage = $$v
                                            },
                                            expression: "selectedRatePackage",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isAccessToCreateRatePackage
                                      ? _c("v-combobox", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "selectedRatePackage",
                                          attrs: {
                                            label: "Search package",
                                            "return-object": "",
                                            items: _vm.getClientRatePackageList,
                                            "item-text": "packageName",
                                            "item-value": "id",
                                            "data-vv-scope": "frmClientRate",
                                            "data-vv-name": "Search package",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Search package"
                                              ),
                                            "data-vv-delay":
                                              _vm.validationDelay,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onClientRatePackageChange(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedRatePackage,
                                            callback: function ($$v) {
                                              _vm.selectedRatePackage = $$v
                                            },
                                            expression: "selectedRatePackage",
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          disabled: "",
                                          value: _vm.isNewRatePackage,
                                        },
                                      },
                                      [_vm._v("New")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "mt-0": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass:
                                      "elevation-1 client-rate-table",
                                    attrs: {
                                      headers: _vm.headers,
                                      items:
                                        _vm.clientRatePackageModel
                                          .clientRateUSList,
                                      "item-key": "subCode",
                                      "hide-actions": "",
                                      expand: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "headers",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "tr",
                                              _vm._l(
                                                props.headers,
                                                function (header) {
                                                  return _c(
                                                    "th",
                                                    {
                                                      key: header.text,
                                                      class: header.class,
                                                      attrs: {
                                                        align: header.align,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(header.text) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "required",
                                                    attrs: {
                                                      maxlength: "10",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      "data-vv-scope":
                                                        "frmClientRate",
                                                      "data-vv-name":
                                                        "Sub Code" +
                                                        props.index,
                                                      name:
                                                        "Sub Code" +
                                                        props.index,
                                                      "error-messages":
                                                        _vm.validationMessage(
                                                          "Sub Code" +
                                                            props.index
                                                        ),
                                                      required: "",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.alphanumericValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: props.item.subCode,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "subCode",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.subCode",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-combobox", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "required sub-category",
                                                    attrs: {
                                                      maxlength: "500",
                                                      items:
                                                        _vm.getSubCategories,
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      "data-vv-scope":
                                                        "frmClientRate",
                                                      "data-vv-name":
                                                        "Sub Category" +
                                                        props.index,
                                                      name:
                                                        "Sub Category" +
                                                        props.index,
                                                      "error-messages":
                                                        _vm.validationMessage(
                                                          "Sub Category" +
                                                            props.index
                                                        ),
                                                      required: "",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.checkIsDescriptionUnique(
                                                          $event,
                                                          props.item
                                                            .description,
                                                          props.index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        props.item.subCategory,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "subCategory",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.subCategory",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      maxlength: "500",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "description",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.checkIsDescriptionUnique(
                                                          props.item
                                                            .subCategory,
                                                          $event,
                                                          props.index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        props.item.description,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "description",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.description",
                                                    },
                                                  }),
                                                  _vm.validateDescription(
                                                    _vm.errorMessage,
                                                    props.index
                                                  )
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input-group__details descrition-message",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group__messages input-group__error input-group--error error--text",
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.validateDescription(
                                                                        _vm.errorMessage,
                                                                        props.index
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      maxlength: "30",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "unit",
                                                    },
                                                    model: {
                                                      value: props.item.unit,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "unit",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.unit",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "prepend-icon-image",
                                                    attrs: {
                                                      maxlength: "8",
                                                      "prepend-icon": "close",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "unitPrice",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        props.item.unitPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "unitPrice",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.unitPrice",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: {
                                                          rules: {
                                                            max_value: 100,
                                                          },
                                                        },
                                                        expression:
                                                          "{ rules: { max_value: 100 } }",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "prepend-icon-image percent pt-0 mt-0",
                                                    attrs: {
                                                      maxlength: "5",
                                                      "append-icon": "close",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "VAT" + props.index,
                                                      "data-vv-scope":
                                                        "frmClientRate",
                                                      "data-vv-name":
                                                        "VAT" + props.index,
                                                      "error-messages":
                                                        _vm.validationMessage(
                                                          "VAT" + props.index,
                                                          true
                                                        ),
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: props.item.vatRate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "vatRate",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.vatRate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      maxlength: "500",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "comment",
                                                    },
                                                    model: {
                                                      value: props.item.comment,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "comment",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.comment",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  !_vm.isUserRoleClientManager
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _vm
                                                                      .clientRatePackageModel
                                                                      .clientRateUSList
                                                                      .length >
                                                                    1
                                                                      ? _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mr-0 btn-remove",
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  icon: "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.removeAdditionalRate(
                                                                                      props.item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dark: "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "close"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Remove Row"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-xs-right mt-2" },
                                    [
                                      !_vm.isUserRoleClientManager
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-0 btn-add",
                                                              attrs: {
                                                                icon: "",
                                                                color:
                                                                  "primary",
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addAdditionalRate,
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "white--text",
                                                                attrs: {
                                                                  dark: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [_vm._v("add")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2456163247
                                              ),
                                            },
                                            [_c("span", [_vm._v("Add Row")])]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      !_vm.isUserRoleClientManager
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-saveOrAssignRatePackage",
                              attrs: {
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                                color: "primary",
                              },
                              on: { click: _vm.SaveOrAssignRatePackage },
                            },
                            [_vm._v(" Save ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    timeout: _vm.saveSnackbarTimeout,
                    bottom: true,
                    left: true,
                  },
                  model: {
                    value: _vm.saveSnackbar,
                    callback: function ($$v) {
                      _vm.saveSnackbar = $$v
                    },
                    expression: "saveSnackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.saveSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }