var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    {
      class:
        _vm.dashboardTileView === "FinancialSummary"
          ? "finance-cards"
          : _vm.tileClasses[0],
    },
    [
      _c(
        "v-card",
        {
          staticClass: "card-small-tile",
          class: _vm.tileClasses[1] + " " + _vm.backgroundColor,
        },
        [
          _c(
            "v-card-title",
            { staticClass: "card-title-pre", class: _vm.tileClasses[2] },
            [
              _vm._v(" " + _vm._s(_vm.title) + " "),
              _vm.hasFilter
                ? _c(
                    "div",
                    [
                      _c("v-spacer"),
                      _c(
                        "span",
                        { staticClass: "tooltip-filter" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "ma-0",
                                              class: _vm.isFilterActive
                                                ? "white"
                                                : "",
                                              attrs: {
                                                icon: "",
                                                small: "",
                                                flat: _vm.isFilterActive
                                                  ? false
                                                  : true,
                                              },
                                              on: {
                                                click: _vm.onFilterIconClick,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                class: _vm.isFilterActive
                                                  ? "secondary--text"
                                                  : "white--text",
                                              },
                                              [_vm._v("filter_list")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2751245795
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v("Filter by " + _vm._s(_vm.title)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "v-card-text",
            { class: _vm.tileClasses[3] },
            [
              _c(
                "h2",
                { class: _vm.tileClasses[4] },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.displayValue && _vm.showDisplayValueInTitle
                          ? _vm.displayValue(_vm.value)
                          : _vm.value
                      ) +
                      " "
                  ),
                  _vm.showArrow && _vm.arrowDirection
                    ? _c("v-icon", { attrs: { color: _vm.textColor } }, [
                        _vm._v(
                          " " + _vm._s("arrow_" + _vm.arrowDirection) + " "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.showChart
                ? _c("DashboardChart", {
                    attrs: {
                      "chart-data": _vm.chartData,
                      "chart-type": _vm.chartIsLine
                        ? _vm.chartTypeEnum[_vm.chartTypeEnum.Line]
                        : _vm.chartTypeEnum[_vm.chartTypeEnum.Bar],
                      "is-top": true,
                      "max-value": _vm.chartMaxValue,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.infoValue && _vm.infoValue.length > 0
        ? _c(
            "div",
            {
              staticClass: "lighten-2 day-counts-section",
              class: _vm.backgroundColor,
            },
            [
              _c(
                "div",
                { staticClass: "text-xs-right" },
                _vm._l(_vm.infoValue, function (item, index) {
                  return _c("span", { key: index }, [
                    item.type == "img"
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c("span", _vm._g({}, on), [
                                            _c("i", { staticClass: "v-icon" }, [
                                              item.path
                                                ? _c("img", {
                                                    attrs: {
                                                      src: item.path,
                                                      alt: item.alt,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "count" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.displayValue
                                                        ? _vm.displayValue(
                                                            item.value
                                                          )
                                                        : item.value
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                item.alt
                                  ? _c("span", [_vm._v(_vm._s(item.alt))])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : item.type == "icon"
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c("span", _vm._g({}, on), [
                                            _c(
                                              "i",
                                              { staticClass: "v-icon" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { color: "white" } },
                                                  [_vm._v(_vm._s(item.path))]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "count" },
                                              [_vm._v(_vm._s(item.value))]
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                item.alt
                                  ? _c("span", [_vm._v(_vm._s(item.alt))])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }