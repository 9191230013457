var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { class: "xs" + _vm.size },
    [
      _c(
        "v-card",
        { staticClass: "policy-information", attrs: { raised: "" } },
        [
          _c("v-card-title", [_vm._v(_vm._s(_vm.title))]),
          _c("v-card-text", [
            _c("pre", [_c("b", [_vm._v(_vm._s(_vm.detail))])]),
            _vm.otherDetail
              ? _c("pre", [_c("b", [_vm._v(_vm._s(_vm.otherDetail))])])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }