var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "bar",
          rawName: "v-bar",
          value: { useScrollbarPseudo: true },
          expression: "{ useScrollbarPseudo: true }",
        },
      ],
    },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "900",
            persistent: "",
            "content-class": "v-dialog--scrollable flex-col",
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _vm.showUserNotificationPreferences
            ? [
                _vm.showDialog
                  ? _c("EditEngineer", {
                      attrs: {
                        "existing-engineer": _vm.engineer,
                        "is-update": _vm.engineerUpdate,
                        "available-trades": _vm.engineerTradeAvailability,
                      },
                      on: {
                        close: function ($event) {
                          _vm.showDialog = false
                        },
                        save: _vm.onEngineerSaved,
                      },
                    })
                  : _vm._e(),
              ]
            : _c("Engineer", {
                attrs: {
                  "engineer-item": _vm.engineer,
                  "is-update-engineer": _vm.engineerUpdate,
                  "engineer-availability": _vm.engineerTradeAvailability,
                },
                on: { OnEngineerSave: _vm.onEngineerSaved },
              }),
        ],
        2
      ),
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0", attrs: { "primary-title": "" } },
            [
              _c("h3", { staticClass: "mb-0" }, [_vm._v("Engineer List")]),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "mt-0 pt-0",
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              !_vm.isIndependent && !_vm.isUserRoleContractor
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-0 ml-3 rounded-none",
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: { click: _vm.onCreateEngineer },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("add"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2998203164
                      ),
                    },
                    [_c("span", [_vm._v("Add Engineer")])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "gridView",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.getEngineers,
                  loading: _vm.isLoading,
                  search: _vm.search,
                  pagination: _vm.pagination,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.firstName))]),
                        _c("td", [_vm._v(_vm._s(props.item.lastName))]),
                        _c("td", [_vm._v(_vm._s(props.item.email))]),
                        _c("td", [_vm._v(_vm._s(props.item.contactNumber))]),
                        _c("td", [_vm._v(_vm._s(props.item.address))]),
                        _c(
                          "td",
                          { staticClass: "justify-center layout px-0" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "mx-0",
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onEditClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "primary" } },
                                                [_vm._v("edit")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Edit")])]
                            ),
                            !_vm.isIndependent
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "mx-0 mt-2 ml-2",
                                                    attrs: { icon: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onDeleteClick(
                                                          props.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("delete")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Delete")])]
                                )
                              : _vm._e(),
                            _c(
                              "v-snackbar",
                              {
                                attrs: {
                                  timeout: _vm.snackbarTimeout,
                                  left: "",
                                  bottom: "",
                                },
                                model: {
                                  value: _vm.snackbar,
                                  callback: function ($$v) {
                                    _vm.snackbar = $$v
                                  },
                                  expression: "snackbar",
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      dark: "",
                                      flat: "",
                                      color: "secondary",
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.snackbar = false
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }