var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-flex", { attrs: { "px-2": "", "pt-2": "" } }, [
    _c(
      "div",
      {
        staticClass: "example-full",
        class: ["dropzone-area ", _vm.dragging ? "dropzone-over" : ""],
        on: {
          dragstart: function ($event) {
            _vm.dragging = true
          },
          dragover: function ($event) {
            _vm.dragging = true
          },
          dragleave: function ($event) {
            _vm.dragging = false
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "text-center p-5 dropped-div-area select-file",
            on: { click: _vm.openFileSelectionWindow },
          },
          [
            _c(
              "div",
              [
                _c("h4", [
                  _vm._v(" Drop files here to upload "),
                  _c("br"),
                  _vm._v(" or "),
                ]),
                _c(
                  "label",
                  {
                    ref: "selectFileToUpload",
                    staticStyle: { display: "none" },
                    attrs: { color: "primary", for: _vm.name },
                  },
                  [_vm._v("Select Files")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-0 upload-btn",
                    attrs: { color: "primary" },
                  },
                  [_vm._v("Select Files")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("file-upload", {
          ref: "fileUpload",
          staticClass: "btn btn-primary dropdown-toggle",
          attrs: {
            extensions: _vm.extensions,
            multiple: _vm.multiple,
            size: _vm.getAttachmentSize,
            thread: _vm.thread < 1 ? 1 : _vm.thread > 5 ? 5 : _vm.thread,
            drop: _vm.drop,
            name: "file",
            "data-vv-scope": "formReference",
          },
          on: { "input-filter": _vm.onFileUpload },
          model: {
            value: _vm.files,
            callback: function ($$v) {
              _vm.files = $$v
            },
            expression: "files",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "image-upload-list" }, [
      _c(
        "div",
        { staticClass: "gridView mt-2 v-data-table--fixed-header" },
        [
          _c("div", { staticClass: "input-group__details" }, [
            _c(
              "div",
              {
                staticClass:
                  "input-group__messages input-group__error input-group--error error--text",
              },
              [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.fileValidationMessage(
                        _vm.errors,
                        _vm.fileErrorMessage
                      )
                    )
                  ),
                ]),
              ]
            ),
          ]),
          _c("v-data-table", {
            staticClass: "elevation-1 upload-image-table",
            attrs: {
              headers: _vm.headers,
              items: _vm.uploadedDocuments,
              "hide-actions": "",
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function (props) {
                  return [
                    _c(
                      "td",
                      { staticClass: "img text-xs-center px-0" },
                      [
                        _c(
                          "v-avatar",
                          {
                            staticClass: "elevation-2",
                            attrs: { slot: "activator", size: "40px" },
                            slot: "activator",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.documentExtentionType(
                                  props.item.documentName
                                ),
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(props.item.documentName))]),
                    _c(
                      "td",
                      { staticClass: "upload-select-document" },
                      [
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            items: _vm.documentTypes,
                            "item-text": "description",
                            "item-value": "id",
                            label: "Select Document Type",
                            "data-vv-scope": "formReference",
                            "data-vv-name": "DocumentType" + props.index,
                            "error-messages": _vm.validationMessage(
                              "DocumentType" + props.index
                            ),
                            "single-line": "",
                            disabled: _vm.loading,
                          },
                          model: {
                            value: props.item.documentTypeId,
                            callback: function ($$v) {
                              _vm.$set(props.item, "documentTypeId", $$v)
                            },
                            expression: "props.item.documentTypeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "text-xs-center" },
                      [
                        _c("v-checkbox", {
                          staticClass: "d-inline-block",
                          attrs: {
                            primary: "",
                            "hide-details": "",
                            disabled: _vm.loading,
                            color: "primary",
                          },
                          model: {
                            value: props.item.availableForEngineer,
                            callback: function ($$v) {
                              _vm.$set(props.item, "availableForEngineer", $$v)
                            },
                            expression: "props.item.availableForEngineer",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "upload-select-document text-xs-center" },
                      [
                        _c(
                          "v-menu",
                          {
                            attrs: {
                              bottom: "",
                              left: "",
                              "close-on-content-click": false,
                              "nudge-width": 350,
                              "offset-x": "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "add-note",
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              color: "primary",
                                              flat: !props.item.note
                                                ? true
                                                : false,
                                              small: "",
                                              disabled: _vm.loading,
                                            },
                                            slot: "activator",
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "md-icon",
                                              attrs: {
                                                color: !props.item.note
                                                  ? "primary"
                                                  : "",
                                                dark: "",
                                              },
                                            },
                                            [_vm._v("notes")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "px-2 pb-2" },
                              [
                                _c("v-textarea", {
                                  staticClass: "document-note",
                                  attrs: { label: "Note", "hide-details": "" },
                                  model: {
                                    value: props.item.note,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        props.item,
                                        "note",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "props.item.note",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "text-xs-right action" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "elevation-0 ma-0 delete-btn",
                            attrs: {
                              flat: "",
                              icon: "",
                              color: "primary",
                              disabled: _vm.loading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteDocument(props.item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { color: "primary" } }, [
                              _vm._v("delete"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }