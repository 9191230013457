var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { md8: "", lg9: "", xs8: "" } }, [
        _c("div", [
          _c("div", { staticClass: "mb-1" }, [
            _c("b", [
              !_vm.isContractorAcepted
                ? _c("span", [_vm._v("Provisional")])
                : _vm._e(),
              _vm._v(" From: "),
            ]),
            _c("span", { staticClass: "eta-date-from" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getFormattedDate(_vm.getAssignedContractor.etaFrom)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _c("b", [
              !_vm.isContractorAcepted
                ? _c("span", [_vm._v("Provisional")])
                : _vm._e(),
              _vm._v(" To: "),
            ]),
            _c("span", { staticClass: "eta-date-to" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getFormattedDate(_vm.getAssignedContractor.etaTo)
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _c("b", [
              !_vm.isContractorAcepted
                ? _c("span", [_vm._v("Provisional")])
                : _vm._e(),
              _vm._v(" Engineer: "),
            ]),
            _c("span", { staticClass: "eta-engineer-name" }, [
              _vm._v(
                " " + _vm._s(_vm.getAssignedContractor.engineerName) + " "
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }