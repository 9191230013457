var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._l(_vm.testingProgress.requiredTestingDocuments, function (docType) {
        return _c(
          "v-card",
          { key: docType, staticClass: "mb-4" },
          [
            _c(
              "v-card-title",
              [
                _c("h3", [_vm._v(_vm._s(_vm.titleForType(docType)))]),
                _c("v-spacer"),
                _vm.missingDocument(docType)
                  ? _c(
                      "v-icon",
                      { attrs: { medium: "", dark: "", color: "orange" } },
                      [_vm._v("info")]
                    )
                  : _c(
                      "v-icon",
                      { attrs: { medium: "", dark: "", color: "success" } },
                      [_vm._v("check_circle")]
                    ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c("DocumentList", {
        attrs: {
          "job-id": _vm.jobId,
          "job-type": _vm.job.jobType,
          "excluded-document-types": _vm.excludeNonTestingDocumentTypes,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }