var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.getRepairEstimates && _vm.getRepairEstimates.length > 0
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            _vm._l(_vm.getRepairEstimates, function (repairEstimate, index) {
              return _c(
                "v-layout",
                { key: index, attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("div", { staticClass: "font-weight-bold pb-1" }, [
                      _vm._v(" " + _vm._s(repairEstimate.estimateName) + " "),
                    ]),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(repairEstimate.products.length) +
                          " * items = " +
                          _vm._s(
                            _vm.getFormatedCurrency(
                              _vm.getEstimateWiseTotal(repairEstimate)
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  index !== _vm.getRepairEstimates.length - 1
                    ? _c("v-divider", { staticClass: "ml-1 mt-2 mb-2" })
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("v-flex", { attrs: { xs12: "", "mt-4": "" } }, [
        _c("span", { staticClass: "font-weight-bold grey--text" }, [
          _vm._v(
            "Total Price: " + _vm._s(_vm.getFormatedCurrency(_vm.getTotalPrice))
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }