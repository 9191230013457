var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-flex",
        { attrs: { md6: "" } },
        [
          _c("v-select", {
            attrs: {
              items: _vm.cookieRecords,
              "item-text": "name",
              "return-object": "",
              label: "Cookie Selection",
            },
            on: {
              change: function ($event) {
                return _vm.updateTemplateCookieRecord($event)
              },
            },
            model: {
              value: _vm.selectedCookieRecord,
              callback: function ($$v) {
                _vm.selectedCookieRecord = $$v
              },
              expression: "selectedCookieRecord",
            },
          }),
        ],
        1
      ),
      _vm.showCookieSettings
        ? _c(
            "v-flex",
            { attrs: { md6: "" } },
            _vm._l(
              _vm.templateCookieRecord.settings,
              function (setting, index) {
                return _c(
                  "div",
                  { key: `${setting.id}${index}` },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      attrs: {
                        label: _vm.retrieveCookieSettingLabel(setting),
                        "data-vv-name": `CookieSetting${index}`,
                        "error-messages": _vm.generateErrorMessage(
                          `CookieSetting${index}`
                        ),
                        "data-vv-scope": _vm.validationScope,
                      },
                      model: {
                        value: _vm.templateCookieRecord.settings[index].value,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.templateCookieRecord.settings[index],
                            "value",
                            $$v
                          )
                        },
                        expression:
                          "templateCookieRecord.settings[index].value",
                      },
                    }),
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }