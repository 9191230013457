var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pa-3 mt-1" },
    [
      _c(
        "v-form",
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "mb-2": "" } },
                [
                  _c("v-switch", {
                    staticClass: "delay-switch",
                    attrs: {
                      color: "primary",
                      "hide-details": "",
                      label: "Log Delay",
                      readonly: _vm.isReadOnly,
                    },
                    on: { change: _vm.hasDelayChanged },
                    model: {
                      value: _vm.hasDelayLocal,
                      callback: function ($$v) {
                        _vm.hasDelayLocal = $$v
                      },
                      expression: "hasDelayLocal",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "delaycode-list",
                    class: _vm.hasDelayLocal,
                    attrs: {
                      items: _vm.delayCodeList,
                      "item-text": "description",
                      "item-value": "id",
                      label: "Delay Code",
                      disabled: !_vm.hasDelayLocal,
                      required: _vm.hasDelayLocal,
                      "data-vv-scope": "frmDelayCode",
                      "data-vv-name": "Delay Code",
                      "error-messages": _vm.errors.collect("Delay Code"),
                      readonly: _vm.isReadOnly,
                    },
                    on: { change: _vm.onDelayCodeChange },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-list-tile",
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c(
                                      "v-list-tile-title",
                                      {
                                        class:
                                          data.item.description === "Configure"
                                            ? "bold-select"
                                            : "delay-code",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.description) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.delayCodeLocal,
                      callback: function ($$v) {
                        _vm.delayCodeLocal = $$v
                      },
                      expression: "delayCodeLocal",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("DelayCodeConfiguration", {
                ref: "configureDropdown",
                attrs: { "record-type": "DelayCode" },
                on: { closeConfigModal: _vm.onConfigureDropdownDialogClose },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }