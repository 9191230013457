var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "grid-list-lg fluid" },
            [
              _vm.clientTemplates
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm._l(_vm.clientTemplates, function (item, index) {
                        return _c(
                          "v-flex",
                          {
                            key: index,
                            attrs: { xl2: "", lg3: "", md4: "", sm6: "" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "inner-content edit-template-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.onEditTemplate(item)
                                  },
                                },
                              },
                              [
                                item.logoURL
                                  ? _c("img", { attrs: { src: item.logoURL } })
                                  : _c("img", {
                                      staticClass: "default-img",
                                      attrs: { src: "img/default-logo.png" },
                                    }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pa-2 text-center grey--text text--darken-1",
                              },
                              [
                                _c("span", { staticClass: "portal-name" }, [
                                  _vm._v(_vm._s(item.displayName)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      !_vm.isUserRoleClientManager
                        ? _c(
                            "v-flex",
                            { attrs: { xl2: "", lg3: "", md4: "", sm6: "" } },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "inner-content",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addNewTemplate()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "secondary--text" },
                                    [_vm._v("add")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "make-template px-4 py-3",
          class: _vm.editTemplate ? "add-newtempalte" : "",
        },
        [
          _c(
            "v-container",
            { staticClass: "grid-list-lg fluid pa-0" },
            [
              _c("v-layout", [
                _c("div", { staticClass: "pt-3 pb-3 preview-content" }, [
                  _c("div", { staticClass: "mobile-section-content" }, [
                    _c("span", { staticClass: "back-mobile" }),
                    _c(
                      "div",
                      {
                        staticClass: "url-bar",
                        on: {
                          click: function ($event) {
                            return _vm.openStagingInNewTab()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "custom-text-filed" }, [
                          _vm._v(_vm._s(_vm.previewUrl)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "bar",
                            rawName: "v-bar",
                            value: { useScrollbarPseudo: true },
                            expression: "{ useScrollbarPseudo: true }",
                          },
                        ],
                        staticStyle: { height: "100%" },
                      },
                      [
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "scroll-content",
                                staticStyle: {
                                  height: "100%",
                                  overflow: "hidden",
                                },
                                attrs: { xs12: "", "px-3": "", "py-0": "" },
                              },
                              [
                                _vm.previewUrl
                                  ? _c("iframe", {
                                      staticClass: "preview-template",
                                      attrs: {
                                        id: "iframeClientTemplate",
                                        src: _vm.previewUrl,
                                      },
                                      on: {
                                        load: function ($event) {
                                          _vm.frame = $event.target
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "portal-form" },
                  [
                    _c(
                      "v-expansion-panel",
                      {
                        model: {
                          value: _vm.templateDetailsPanel,
                          callback: function ($$v) {
                            _vm.templateDetailsPanel = $$v
                          },
                          expression: "templateDetailsPanel",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [_c("h3", [_vm._v("Template Detail")])]
                            ),
                            _c("v-divider"),
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "template-form px-4" },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "header-section",
                                        attrs: { wrap: "" },
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md12: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: { label: "Display Name" },
                                              model: {
                                                value:
                                                  _vm.clientTemplateModel
                                                    .displayName,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.clientTemplateModel,
                                                    "displayName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "clientTemplateModel.displayName",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "sub-domain" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "SubDomain",
                                                    disabled: "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.clientTemplateModel
                                                        .subDomain,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.clientTemplateModel,
                                                        "subDomain",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "clientTemplateModel.subDomain",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c("v-flex", {
                                          attrs: { lg4: "", md6: "" },
                                        }),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xl2: "",
                                              lg3: "",
                                              md6: "",
                                            },
                                          },
                                          [
                                            _c("v-checkbox", {
                                              staticClass: "mt-3",
                                              attrs: {
                                                label: `Add Policy Logo`,
                                                "hide-details": "",
                                                color: "primary",
                                              },
                                              on: {
                                                change:
                                                  _vm.onChangeAllowToSelectLogo,
                                              },
                                              model: {
                                                value: _vm.allowToSelectLogo,
                                                callback: function ($$v) {
                                                  _vm.allowToSelectLogo = $$v
                                                },
                                                expression: "allowToSelectLogo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "text-xs-center icon-validation",
                                            class: !_vm.allowToSelectLogo
                                              ? "show"
                                              : "hide",
                                            attrs: {
                                              xl5: "",
                                              lg5: "",
                                              md6: "",
                                            },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Logo",
                                                required: "",
                                                placeholder: "Select logo",
                                                "append-icon": _vm.imageName
                                                  ? "close"
                                                  : "",
                                                "prepend-icon": "attach_file",
                                                "hide-details": true,
                                              },
                                              on: {
                                                "click:append": _vm.removeImage,
                                                click: _vm.pickFile,
                                              },
                                              model: {
                                                value: _vm.imageName,
                                                callback: function ($$v) {
                                                  _vm.imageName = $$v
                                                },
                                                expression: "imageName",
                                              },
                                            }),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    rules: {
                                                      required:
                                                        _vm.isLogoRequired,
                                                      image: true,
                                                      size: _vm.LogoSize,
                                                      mimes: [
                                                        "image/jpeg",
                                                        "image/jpg",
                                                        "image/png",
                                                        "image/gif",
                                                      ],
                                                      ext: [
                                                        "jpg",
                                                        "jpeg",
                                                        "png",
                                                        "gif",
                                                      ],
                                                    },
                                                  },
                                                  expression:
                                                    "{\n                          rules: {\n                            required: isLogoRequired,\n                            image: true,\n                            size: LogoSize,\n                            mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],\n                            ext: ['jpg', 'jpeg', 'png', 'gif'],\n                          },\n                        }",
                                                },
                                              ],
                                              ref: "image",
                                              staticStyle: { display: "none" },
                                              attrs: {
                                                type: "file",
                                                accept: "image/*",
                                                name: "logo",
                                                "data-vv-scope":
                                                  _vm.validationScope,
                                              },
                                              on: { change: _vm.onFilePicked },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group__details",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group__messages input-group__error input-group--error error--text",
                                                  },
                                                  [
                                                    _vm.imageValidationMessage(
                                                      _vm.errors
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.logoErrorMsg
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "text-xs-center icon-validation policy-logo-client",
                                            class: _vm.allowToSelectLogo
                                              ? "show"
                                              : "hide",
                                            attrs: {
                                              xl5: "",
                                              lg5: "",
                                              md6: "",
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    rules: {
                                                      required:
                                                        _vm.isPolicyLogoUrlRequired,
                                                    },
                                                  },
                                                  expression:
                                                    "{ rules: { required: isPolicyLogoUrlRequired } }",
                                                },
                                              ],
                                              staticClass:
                                                "policy-logo-clienttemplate",
                                              attrs: {
                                                items:
                                                  _vm.existingPolicyLogoUrls,
                                                label: "Policy Logo",
                                                "return-object": "",
                                                "menu-props": {
                                                  maxHeight: "auto",
                                                },
                                                hide: !_vm.allowToSelectLogo,
                                                required: "",
                                                "data-vv-scope":
                                                  _vm.validationScope,
                                                "data-vv-name": "Policy Logo",
                                                "error-messages":
                                                  _vm.errors.collect(
                                                    "Policy Logo"
                                                  ),
                                              },
                                              on: {
                                                change:
                                                  _vm.onSelectLogoFromExistingUrls,
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "selection",
                                                  fn: function (data) {
                                                    return [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item.policyName
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "policy-logo-select",
                                                          },
                                                          [
                                                            data.item.fileURL
                                                              ? _c("img", {
                                                                  attrs: {
                                                                    src: data
                                                                      .item
                                                                      .fileURL,
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-tile-content",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    data.item
                                                                      .policyName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.selectedPolicyLogo,
                                                callback: function ($$v) {
                                                  _vm.selectedPolicyLogo = $$v
                                                },
                                                expression:
                                                  "selectedPolicyLogo",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            staticClass:
                                              "text-xs-center icon-validation",
                                            attrs: {
                                              xl5: "",
                                              lg4: "",
                                              md6: "",
                                            },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Favicon",
                                                placeholder: "Select favicon",
                                                "append-icon":
                                                  _vm.favIconImageName ||
                                                  _vm.favIconImageUrl ||
                                                  _vm.clientTemplateModel
                                                    .favIconURL
                                                    ? "close"
                                                    : "",
                                                "prepend-icon": "attach_file",
                                                "hide-details": true,
                                              },
                                              on: {
                                                "click:append":
                                                  _vm.removeFavIconImage,
                                                click: _vm.pickFavIconFile,
                                              },
                                              model: {
                                                value: _vm.favIconImageName,
                                                callback: function ($$v) {
                                                  _vm.favIconImageName = $$v
                                                },
                                                expression: "favIconImageName",
                                              },
                                            }),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    rules: {
                                                      required: false,
                                                      image: true,
                                                      size: 10,
                                                      mimes: [
                                                        "image/jpeg",
                                                        "image/jpg",
                                                        "image/png",
                                                        "image/gif",
                                                      ],
                                                      ext: [
                                                        "jpg",
                                                        "jpeg",
                                                        "png",
                                                        "gif",
                                                      ],
                                                    },
                                                  },
                                                  expression:
                                                    "{\n                          rules: {\n                            required: false,\n                            image: true,\n                            size: 10,\n                            mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],\n                            ext: ['jpg', 'jpeg', 'png', 'gif'],\n                          },\n                        }",
                                                },
                                              ],
                                              ref: "favIconImage",
                                              staticStyle: { display: "none" },
                                              attrs: {
                                                type: "file",
                                                accept: "image/*",
                                                name: "favicon",
                                                "data-vv-scope":
                                                  _vm.validationScope,
                                              },
                                              on: {
                                                change: _vm.onFavIconFilePicked,
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "input-group__details",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group__messages input-group__error input-group--error error--text",
                                                  },
                                                  [
                                                    _vm.favIconImageValidationMessage(
                                                      _vm.errors
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.favIconErrorMsg
                                                            )
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "color-input" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Primary",
                                                    value: _vm.primaryColor.hex,
                                                  },
                                                }),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "offset-y": "",
                                                      "content-class":
                                                        "color-picker-menu",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "show-color",
                                                                  style:
                                                                    "background:" +
                                                                    _vm
                                                                      .primaryColor
                                                                      .hex,
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "chrome-picker",
                                                                  {
                                                                    attrs: {
                                                                      "data-testid":
                                                                        "primaryColorPicker",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.primaryColor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.primaryColor =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "primaryColor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "color-input" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Secondary",
                                                    value:
                                                      _vm.secondaryColor.hex,
                                                  },
                                                }),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "offset-y": "",
                                                      "content-class":
                                                        "color-picker-menu",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "show-color",
                                                                  style:
                                                                    "background:" +
                                                                    _vm
                                                                      .secondaryColor
                                                                      .hex,
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "chrome-picker",
                                                                  {
                                                                    attrs: {
                                                                      "data-testid":
                                                                        "secondaryColorPicker",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.secondaryColor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.secondaryColor =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "secondaryColor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "color-input" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Complementary",
                                                    value:
                                                      _vm.complementaryColor
                                                        .hex,
                                                  },
                                                }),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "offset-y": "",
                                                      "content-class":
                                                        "color-picker-menu",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "show-color",
                                                                  style:
                                                                    "background:" +
                                                                    _vm
                                                                      .complementaryColor
                                                                      .hex,
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "chrome-picker",
                                                                  {
                                                                    attrs: {
                                                                      "data-testid":
                                                                        "complementaryColorPicker",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.complementaryColor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.complementaryColor =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "complementaryColor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "color-input" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Error Color",
                                                    value: _vm.errorColor.hex,
                                                  },
                                                }),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "offset-y": "",
                                                      "content-class":
                                                        "color-picker-menu",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "show-color error-color",
                                                                  style:
                                                                    "background:" +
                                                                    _vm
                                                                      .errorColor
                                                                      .hex,
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "chrome-picker",
                                                                  {
                                                                    attrs: {
                                                                      "data-testid":
                                                                        "errorColorPicker",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.errorColor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.errorColor =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "errorColor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { lg4: "", md6: "" } },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "color-input" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Input Text Color",
                                                    value:
                                                      _vm.inputTextColor.hex,
                                                  },
                                                }),
                                                _c(
                                                  "v-menu",
                                                  {
                                                    attrs: {
                                                      left: "",
                                                      "offset-y": "",
                                                      "content-class":
                                                        "color-picker-menu",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "span",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "show-color inputText-color",
                                                                  style:
                                                                    "background:" +
                                                                    _vm
                                                                      .inputTextColor
                                                                      .hex,
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c(
                                                      "v-list",
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "chrome-picker",
                                                                  {
                                                                    attrs: {
                                                                      "data-testid":
                                                                        "inputTextColorPicker",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.inputTextColor,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.inputTextColor =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "inputTextColor",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-expansion-panel",
                                              [
                                                _c(
                                                  "v-expansion-panel-content",
                                                  {
                                                    staticClass:
                                                      "expansion-header",
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "h4",
                                                              {
                                                                staticClass:
                                                                  "text-uppercase",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Step Headers"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ]),
                                                  },
                                                  [
                                                    _c("TemplateHeaders", {
                                                      attrs: {
                                                        ct: _vm.clientTemplateModel,
                                                        "validation-scope":
                                                          _vm.validationScope,
                                                      },
                                                      on: {
                                                        updateHeader: function (
                                                          $event
                                                        ) {
                                                          _vm.clientTemplateModel[
                                                            `${$event.field}Step${$event.step}`
                                                          ] = $event.value
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.emergencyTypes.length > 0
                                          ? _c(
                                              "v-flex",
                                              { attrs: { xs12: "" } },
                                              [
                                                _c("v-autocomplete", {
                                                  staticClass: "hide-option",
                                                  attrs: {
                                                    items:
                                                      _vm.allEmergencyTypes,
                                                    multiple: "",
                                                    chips: "",
                                                    "return-object": "",
                                                    "item-text": "description",
                                                    "item-value": "emergencyId",
                                                    "persistent-hint": "",
                                                    label:
                                                      "Select Top Emergency",
                                                    "hide-details": "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.onChangeEmergencyType(
                                                        _vm.topEmergencies,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            _c(
                                                              "v-list-tile",
                                                              [
                                                                _c(
                                                                  "v-list-tile-action",
                                                                  [
                                                                    _c(
                                                                      "v-checkbox",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .emergencyCheckboxes[
                                                                              _vm.allEmergencyTypes.indexOf(
                                                                                data.item
                                                                              )
                                                                            ]
                                                                              .checked,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .emergencyCheckboxes[
                                                                                  _vm.allEmergencyTypes.indexOf(
                                                                                    data.item
                                                                                  )
                                                                                ],
                                                                                "checked",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "emergencyCheckboxes[allEmergencyTypes.indexOf(data.item)].checked",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-list-tile-content",
                                                                  [
                                                                    _c(
                                                                      "v-list-tile-title",
                                                                      {
                                                                        class:
                                                                          data
                                                                            .item
                                                                            .isDeleted
                                                                            ? "deleted-item"
                                                                            : "",
                                                                        domProps:
                                                                          {
                                                                            innerHTML:
                                                                              _vm._s(
                                                                                data
                                                                                  .item
                                                                                  .description
                                                                              ),
                                                                          },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1643851373
                                                  ),
                                                  model: {
                                                    value: _vm.topEmergencies,
                                                    callback: function ($$v) {
                                                      _vm.topEmergencies = $$v
                                                    },
                                                    expression:
                                                      "topEmergencies",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c("v-autocomplete", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              attrs: {
                                                label:
                                                  "Fallback Starred Policy",
                                                required: "",
                                                items: _vm.getStarredPolicy,
                                                "item-text":
                                                  "policyBasicDetail.name",
                                                "item-value":
                                                  "policyBasicDetail.policyId",
                                                "data-vv-scope":
                                                  _vm.validationScope,
                                                "data-vv-name":
                                                  "Fallback Starred Policy",
                                                "error-messages":
                                                  _vm.errors.collect(
                                                    "Fallback Starred Policy"
                                                  ),
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "v-list-tile",
                                                        [
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                {
                                                                  class: data
                                                                    .item
                                                                    .policyBasicDetail
                                                                    .isDeleted
                                                                    ? "deleted-item"
                                                                    : "",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .policyBasicDetail
                                                                          .name
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.selectedFallbackStarredPolicy,
                                                callback: function ($$v) {
                                                  _vm.selectedFallbackStarredPolicy =
                                                    $$v
                                                },
                                                expression:
                                                  "selectedFallbackStarredPolicy",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c("v-autocomplete", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: "required",
                                                  expression: "'required'",
                                                },
                                              ],
                                              attrs: {
                                                items: _vm.getPolicyList,
                                                loading:
                                                  _vm.policySearchRunning,
                                                label: "Select Policy Schedule",
                                                multiple: "",
                                                chips: "",
                                                "return-object": "",
                                                "item-text":
                                                  "policyBasicDetail.name",
                                                "item-value":
                                                  "policyBasicDetail.policyId",
                                                required: "",
                                                "data-vv-scope":
                                                  _vm.validationScope,
                                                "data-vv-name":
                                                  "Select Policy Schedule",
                                                "error-messages":
                                                  _vm.errors.collect(
                                                    "Select Policy Schedule"
                                                  ),
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "item",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "v-list-tile",
                                                        [
                                                          _c(
                                                            "v-list-tile-action",
                                                            [
                                                              _c("v-checkbox", {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .policyCheckboxes[
                                                                      _vm.policyList.indexOf(
                                                                        data.item
                                                                      )
                                                                    ].checked,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .policyCheckboxes[
                                                                          _vm.policyList.indexOf(
                                                                            data.item
                                                                          )
                                                                        ],
                                                                        "checked",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "policyCheckboxes[policyList.indexOf(data.item)].checked",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-list-tile-content",
                                                            [
                                                              _c(
                                                                "v-list-tile-title",
                                                                {
                                                                  class: data
                                                                    .item
                                                                    .policyBasicDetail
                                                                    .isDeleted
                                                                    ? "deleted-item"
                                                                    : "",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .policyBasicDetail
                                                                          .name
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ]),
                                              model: {
                                                value: _vm.policySchedules,
                                                callback: function ($$v) {
                                                  _vm.policySchedules = $$v
                                                },
                                                expression: "policySchedules",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _vm._l(
                                                  Object.keys(_vm.noticeConfig),
                                                  function (notice) {
                                                    return _c(
                                                      "v-flex",
                                                      { key: notice },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.displayNoticeDialog =
                                                                  notice
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .noticeConfig[
                                                                    notice
                                                                  ].buttonText
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c("CookieSelection", {
                                                      ref: "cookieSelection",
                                                      attrs: {
                                                        "validation-scope":
                                                          _vm.validationScope,
                                                        "client-template":
                                                          _vm.clientTemplateModel,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs12: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { md6: "" } },
                                                      [
                                                        _c("v-select", {
                                                          directives: [
                                                            {
                                                              name: "validate",
                                                              rawName:
                                                                "v-validate",
                                                              value: "required",
                                                              expression:
                                                                "'required'",
                                                            },
                                                          ],
                                                          attrs: {
                                                            label:
                                                              "Template Status",
                                                            items:
                                                              _vm.templateStatuses,
                                                            required: "",
                                                            "data-vv-scope":
                                                              _vm.validationScope,
                                                            "data-vv-name":
                                                              "TemplateStatus",
                                                            "error-messages":
                                                              _vm.errors.collect(
                                                                "TemplateStatus"
                                                              ),
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.resetHoldingMessage,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .clientTemplateModel
                                                                .status,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.clientTemplateModel,
                                                                "status",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "clientTemplateModel.status",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.clientTemplateModel
                                                      .status ===
                                                    _vm.templateStatusEnum
                                                      .TemporaryOffline
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md6: "" },
                                                          },
                                                          [
                                                            _c("v-textarea", {
                                                              directives: [
                                                                {
                                                                  name: "validate",
                                                                  rawName:
                                                                    "v-validate",
                                                                  value: {
                                                                    rules: {
                                                                      required:
                                                                        _vm
                                                                          .clientTemplateModel
                                                                          .status ===
                                                                        _vm
                                                                          .templateStatusEnum
                                                                          .TemporaryOffline,
                                                                    },
                                                                  },
                                                                  expression:
                                                                    "{\n                                rules: {\n                                  required: clientTemplateModel.status === templateStatusEnum.TemporaryOffline,\n                                },\n                              }",
                                                                },
                                                              ],
                                                              attrs: {
                                                                label:
                                                                  "Holding Page Message",
                                                                "data-vv-scope":
                                                                  _vm.validationScope,
                                                                "data-vv-name":
                                                                  "HoldingPageMessage",
                                                                "error-messages":
                                                                  _vm.errors.collect(
                                                                    "HoldingPageMessage"
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .clientTemplateModel
                                                                    .holdingPageMessage,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.clientTemplateModel,
                                                                      "holdingPageMessage",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "clientTemplateModel.holdingPageMessage",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      md12: "",
                                                      lg4: "",
                                                      xl3: "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-checkbox", {
                                                      staticClass: "mt-0 pt-0",
                                                      attrs: {
                                                        label: `Brand Specific Widget`,
                                                        "hide-details": "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          !_vm
                                                            .clientTemplateModel
                                                            .dashboardWidgetEnabled
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .clientTemplateModel
                                                            .dashboardWidgetEnabled,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.clientTemplateModel,
                                                            "dashboardWidgetEnabled",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "clientTemplateModel.dashboardWidgetEnabled",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      "tile-preview": "",
                                                      md6: "",
                                                      lg4: "",
                                                      xl3: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        class: _vm
                                                          .clientTemplateModel
                                                          .dashboardWidgetEnabled
                                                          ? "show"
                                                          : "hide",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "white pa-2 elevation-2 insurer-preview-window",
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "elevation-0 white--text pa-0",
                                                                style:
                                                                  "background:" +
                                                                  (_vm
                                                                    .dashboardWidgetColor
                                                                    .hex
                                                                    ? _vm
                                                                        .dashboardWidgetColor
                                                                        .hex
                                                                    : _vm
                                                                        .primaryColor
                                                                        .hex),
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "h2",
                                                                      {
                                                                        staticClass:
                                                                          "pa-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Insurer Name"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "day-counts-section",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-center lighten-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    bottom:
                                                                                      "",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "activator",
                                                                                        fn: function ({
                                                                                          on,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "i",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "white",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "call"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ]
                                                                                  ),
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Total number of calls"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "count",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-center lighten-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    bottom:
                                                                                      "",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "activator",
                                                                                        fn: function ({
                                                                                          on,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "i",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "white",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "work"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ]
                                                                                  ),
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Total number of jobs logged"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "count",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-center lighten-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-tooltip",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    bottom:
                                                                                      "",
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "activator",
                                                                                        fn: function ({
                                                                                          on,
                                                                                        }) {
                                                                                          return [
                                                                                            _c(
                                                                                              "i",
                                                                                              _vm._g(
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "icon",
                                                                                                },
                                                                                                on
                                                                                              ),
                                                                                              [
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        color:
                                                                                                          "white",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "build"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ]
                                                                                  ),
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Total number of jobs deployed"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "count",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "num-call-announcing sys-call-info graph-image pt-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                src: "img/insurer-graph.png",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "num-call-announcing sys-call-info pa-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Calls in announcing"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right sys-call-content",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "num",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "bar-chart",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "img/bar-chart.png",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "new-call sys-call-info light-background lighten-3 pa-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "New"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right sys-call-content",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "num",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "time",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0:00"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "bar-chart",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "img/bar-chart.png",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "existing-call sys-call-info pa-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                for: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Existing"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right sys-call-content",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "num",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "time",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "0:00"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "bar-chart",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "img/bar-chart.png",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      md6: "",
                                                      lg4: "",
                                                      xl3: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "color-input",
                                                        class: _vm
                                                          .clientTemplateModel
                                                          .dashboardWidgetEnabled
                                                          ? "show"
                                                          : "hide",
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label:
                                                              "Select Tile Color",
                                                            value: _vm
                                                              .dashboardWidgetColor
                                                              .hex
                                                              ? _vm
                                                                  .dashboardWidgetColor
                                                                  .hex
                                                              : _vm.primaryColor
                                                                  .hex,
                                                          },
                                                        }),
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              left: "",
                                                              "offset-y": "",
                                                              "content-class":
                                                                "color-picker-menu",
                                                              "close-on-content-click": false,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "show-color",
                                                                            style:
                                                                              "background:" +
                                                                              (_vm
                                                                                .dashboardWidgetColor
                                                                                .hex
                                                                                ? _vm
                                                                                    .dashboardWidgetColor
                                                                                    .hex
                                                                                : _vm
                                                                                    .primaryColor
                                                                                    .hex),
                                                                          },
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c(
                                                              "v-list",
                                                              [
                                                                _c(
                                                                  "v-list-tile",
                                                                  [
                                                                    _c(
                                                                      "v-list-tile-title",
                                                                      [
                                                                        _c(
                                                                          "chrome-picker",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                "data-testid":
                                                                                  "dashboardWidgetColorPicker",
                                                                              },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.dashboardWidgetColor,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.dashboardWidgetColor =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "dashboardWidgetColor",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      lg12: "",
                                                      "text-xs-right": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: _vm.cancel,
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "mr-0",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isSavingChanges,
                                                          loading:
                                                            _vm.isSavingChanges,
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: _vm.submit,
                                                        },
                                                      },
                                                      [_vm._v(" Update ")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              2
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-expansion-panel-content",
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [_c("h3", [_vm._v("Template Versions")])]
                            ),
                            _c("v-divider"),
                            _c("v-data-table", {
                              staticClass: "gridView release-history-items",
                              attrs: {
                                items: _vm.historyItems,
                                headers: _vm.historyHeaders,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c("td", [
                                        props.item.status == "Pending"
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "blue",
                                                      "text-color": "white",
                                                    },
                                                  },
                                                  [_vm._v("Pending")]
                                                ),
                                              ],
                                              1
                                            )
                                          : props.item.status == "Error"
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      color: "red",
                                                      "text-color": "white",
                                                    },
                                                  },
                                                  [_vm._v("Failed")]
                                                ),
                                              ],
                                              1
                                            )
                                          : props.item.status == "Success"
                                          ? _c(
                                              "div",
                                              [
                                                props.item.id ==
                                                _vm.clientTemplateModel
                                                  .stagingPortalRequestId
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "orange",
                                                          "text-color": "white",
                                                        },
                                                      },
                                                      [_vm._v(" Staging ")]
                                                    )
                                                  : _vm._e(),
                                                props.item.id ==
                                                _vm.clientTemplateModel
                                                  .productionPortalRequestId
                                                  ? _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "green",
                                                          "text-color": "white",
                                                        },
                                                      },
                                                      [_vm._v(" Live ")]
                                                    )
                                                  : _vm._e(),
                                                !_vm.isUserRoleClientManager &&
                                                props.item.id !=
                                                  _vm.clientTemplateModel
                                                    .stagingPortalRequestId
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "deploy-staging",
                                                        attrs: {
                                                          small: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deployTemplateVersion(
                                                              props.item,
                                                              "Staging"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Deploy Staging "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                !_vm.isUserRoleClientManager &&
                                                props.item.id ==
                                                  _vm.clientTemplateModel
                                                    .stagingPortalRequestId &&
                                                props.item.id !=
                                                  _vm.clientTemplateModel
                                                    .productionPortalRequestId
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          color: "secondary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deployTemplateVersion(
                                                              props.item,
                                                              "Production"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v(" Deploy Live ")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                      props.item.createdAt
                                        ? _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.createdAt.format(
                                                    "Do MMM YYYY"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.createdAt.format(
                                                    "HH:mm:ss"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.createdByName)
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.createdByNote)
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c(
                              "div",
                              { staticClass: "text-xs-right pb-2" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showDeploymentDialog = true
                                      },
                                    },
                                  },
                                  [_vm._v("Deployment History")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "v-dialog--scrollable",
            "max-width": "1000px",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.showDeploymentDialog = false
            },
          },
          model: {
            value: _vm.showDeploymentDialog,
            callback: function ($$v) {
              _vm.showDeploymentDialog = $$v
            },
            expression: "showDeploymentDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Deployment History")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showDeploymentDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c("v-data-table", {
                    staticClass: "gridView",
                    attrs: {
                      items: _vm.deploymentItems,
                      headers: _vm.deploymentHeaders,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function (props) {
                          return [
                            _c("td", [
                              props.item.status == "Pending"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "blue",
                                            "text-color": "white",
                                          },
                                        },
                                        [_vm._v("Pending")]
                                      ),
                                    ],
                                    1
                                  )
                                : props.item.status == "Error"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "red",
                                            "text-color": "white",
                                          },
                                        },
                                        [_vm._v("Failed")]
                                      ),
                                    ],
                                    1
                                  )
                                : props.item.status == "Success"
                                ? _c(
                                    "div",
                                    [
                                      props.item.request.PublishToStagingPortal
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "orange",
                                                "text-color": "white",
                                              },
                                            },
                                            [_vm._v(" Staging ")]
                                          )
                                        : _vm._e(),
                                      props.item.request
                                        .PublishToProductionPortal
                                        ? _c(
                                            "v-chip",
                                            {
                                              attrs: {
                                                small: "",
                                                color: "green",
                                                "text-color": "white",
                                                width: "92px",
                                              },
                                            },
                                            [_vm._v(" Live ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _c("td", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.item.createdAt.format("Do MMM YYYY")
                                  ) +
                                  " (" +
                                  _vm._s(
                                    props.item.createdAt.format("HH:mm:ss")
                                  ) +
                                  ") "
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.createdByName)),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.status == "Error"
                                    ? props.item.errorDetail
                                    : props.item.createdByNote
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.showAddDialog = false
            },
          },
          model: {
            value: _vm.showAddDialog,
            callback: function ($$v) {
              _vm.showAddDialog = $$v
            },
            expression: "showAddDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Create New Template")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showAddDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { lg12: "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|max:100",
                                    expression: "'required|max:100'",
                                  },
                                ],
                                attrs: {
                                  label: "Display Name",
                                  required: "",
                                  disabled: _vm.newOrUpdateSubmitted,
                                  maxlength: "100",
                                  "data-vv-scope": "frmAddNew",
                                  "data-vv-name": "DisplayName",
                                  "error-messages":
                                    _vm.errors.collect("DisplayName"),
                                },
                                model: {
                                  value: _vm.clientTemplateModel.displayName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientTemplateModel,
                                      "displayName",
                                      $$v
                                    )
                                  },
                                  expression: "clientTemplateModel.displayName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value:
                                      "required|max:50|regex:^[a-z0-9]{1}[a-z0-9-]*[a-z0-9]{1}$",
                                    expression:
                                      "'required|max:50|regex:^[a-z0-9]{1}[a-z0-9-]*[a-z0-9]{1}$'",
                                  },
                                ],
                                attrs: {
                                  label: "Portal Link",
                                  required: "",
                                  disabled: _vm.newOrUpdateSubmitted,
                                  maxlength: "50",
                                  prefix: _vm.portalDomainPrefix,
                                  "data-vv-scope": "frmAddNew",
                                  "data-vv-name": "SubDomain",
                                  "error-messages":
                                    _vm.errors.collect("SubDomain"),
                                },
                                model: {
                                  value: _vm.clientTemplateModel.subDomain,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientTemplateModel,
                                      "subDomain",
                                      $$v
                                    )
                                  },
                                  expression: "clientTemplateModel.subDomain",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-autocomplete", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                attrs: {
                                  label: "Fallback Starred Policy",
                                  required: "",
                                  disabled: _vm.newOrUpdateSubmitted,
                                  items: _vm.getStarredPolicy,
                                  "item-text": "policyBasicDetail.name",
                                  "item-value": "policyBasicDetail.policyId",
                                  "data-vv-scope": "frmAddNew",
                                  "data-vv-name": "Fallback Starred Policy",
                                  "error-messages": _vm.errors.collect(
                                    "Fallback Starred Policy"
                                  ),
                                },
                                model: {
                                  value:
                                    _vm.clientTemplateModel.fallBackStarPolicy,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.clientTemplateModel,
                                      "fallBackStarPolicy",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "clientTemplateModel.fallBackStarPolicy",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.newOrUpdateSubmitted
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-progress-linear", {
                                    attrs: { indeterminate: true },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.newOrUpdateStatusMsg
                            ? _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      nsmError: _vm.newOrUpdateStatusMsgIsError,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.newOrUpdateStatusMsg))]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.newOrUpdateSubmitted,
                        color: "primary",
                        flat: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.newOrUpdateCancelled()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.newOrUpdateSubmitted,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitNewTemplate()
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "600px",
            "content-class": "v-dialog--scrollable",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.showUpdateDialog = false
            },
          },
          model: {
            value: _vm.showUpdateDialog,
            callback: function ($$v) {
              _vm.showUpdateDialog = $$v
            },
            expression: "showUpdateDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Update Template")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showUpdateDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _vm.newOrUpdateShowNote
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|max:10000",
                                        expression: "'required|max:10000'",
                                      },
                                    ],
                                    attrs: {
                                      label: "Update Note",
                                      placeholder:
                                        "Please enter a description of your update",
                                      required: "",
                                      disabled: _vm.newOrUpdateSubmitted,
                                      "data-vv-scope": "frmUpdateModel",
                                      "data-vv-name": "UpdateNote",
                                      "error-messages":
                                        _vm.errors.collect("UpdateNote"),
                                    },
                                    model: {
                                      value: _vm.updateNote,
                                      callback: function ($$v) {
                                        _vm.updateNote = $$v
                                      },
                                      expression: "updateNote",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.newOrUpdateSubmitted
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-progress-linear", {
                                    attrs: { indeterminate: true },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.newOrUpdateStatusMsg
                            ? _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      nsmError: _vm.newOrUpdateStatusMsgIsError,
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.newOrUpdateStatusMsg))]
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.newOrUpdateSubmitted,
                        color: "primary",
                        flat: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.newOrUpdateCancelled()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.newOrUpdateSubmitted,
                        color: "primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitUpdateTemplate()
                        },
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.displayNoticeDialog,
            persistent: "",
            "max-width": "700px",
            "content-class": "v-dialog--scrollable",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.displayNoticeDialog = null
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.displayNoticeConfig.header)),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.displayNoticeDialog = null
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0", attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("froala", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  id: "froala",
                                  froala: "",
                                  "data-vv-scope": "templateformRef",
                                  required: true,
                                  "error-messages": _vm.ValidateEditor(
                                    _vm.displayNoticeConfig.validate
                                  ),
                                  "data-vv-name":
                                    _vm.displayNoticeConfig.validate,
                                  config: _vm.froalaConfig,
                                  tag: "textarea",
                                },
                                model: {
                                  value: _vm.displayNoticeText,
                                  callback: function ($$v) {
                                    _vm.displayNoticeText = $$v
                                  },
                                  expression: "displayNoticeText",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "input-group__details" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "input-group__messages input-group__error input-group--error error--text",
                                    },
                                    [
                                      _vm.ValidateEditor(
                                        _vm.displayNoticeConfig.validate
                                      )
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.ValidateEditor(
                                                    _vm.displayNoticeConfig
                                                      .validate
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.displayNoticeDialog = null
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0",
                      attrs: { color: "primary" },
                      on: { click: _vm.updateNotice },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }