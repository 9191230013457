var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pb-2" },
    [
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 up-card" },
        [
          _c(
            "v-layout",
            { staticClass: "clt-avb-card", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h3", { staticClass: "pt-3 pb-1 pl-2" }, [
                    _vm._v("Customer Availability"),
                  ]),
                  _c("v-divider", { staticClass: "mb-2 mt-2" }),
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "mb-2", attrs: { xs12: "" } },
                            [
                              _c("TimeSlotPicker", {
                                attrs: {
                                  "header-text": _vm.getFirstPickedDate,
                                  "date-selection": true,
                                  "filter-by-date": true,
                                  "filter-slot-by-time":
                                    _vm.getIsFirstFilterSlotByTime,
                                  "slot-type": "Today",
                                  model: _vm.customerAvailability,
                                  "slot-items": _vm.firstDateTimeSlots,
                                },
                                on: {
                                  "update:slotItems": function ($event) {
                                    _vm.firstDateTimeSlots = $event
                                  },
                                  "update:slot-items": function ($event) {
                                    _vm.firstDateTimeSlots = $event
                                  },
                                  updateSlotItems: _vm.onSlotSelectionChange,
                                  updateDayTwoTimeSlot:
                                    _vm.updateDayTwoTimeSlot,
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.hasSecondSlotAvailabilities &&
                          _vm.secondDateTimeSlots.length === 0 &&
                          _vm.thirdDateTimeSlots.length === 0 &&
                          _vm.firstDateTimeSlots.length > 0
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right py-0",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "primary--text grey lighten-3 ma-0 elevation-0 add-second-slot",
                                                      attrs: {
                                                        icon: "",
                                                        flat: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.showSecondSlotAvailabilities,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1233390829
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("More Availability Slots"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasSecondSlotAvailabilities ||
                          _vm.secondDateTimeSlots.length > 0 ||
                          _vm.thirdDateTimeSlots.length > 0
                            ? _c(
                                "v-flex",
                                { staticClass: "mb-2", attrs: { xs12: "" } },
                                [
                                  _c("TimeSlotPicker", {
                                    attrs: {
                                      "header-text": _vm.getSecondPickedDate,
                                      "date-selection": true,
                                      "filter-by-date": true,
                                      "filter-slot-by-time":
                                        _vm.getIsSecondFilterSlotByTime,
                                      "slot-type": "Tomorrow",
                                      model: _vm.customerAvailability,
                                      "slot-items": _vm.secondDateTimeSlots,
                                    },
                                    on: {
                                      "update:slotItems": function ($event) {
                                        _vm.secondDateTimeSlots = $event
                                      },
                                      "update:slot-items": function ($event) {
                                        _vm.secondDateTimeSlots = $event
                                      },
                                      updateSlotItems:
                                        _vm.onSlotSelectionChange,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.hasThirdSlotAvailabilities &&
                          _vm.thirdDateTimeSlots.length === 0 &&
                          _vm.secondDateTimeSlots.length > 0
                            ? _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right py-0",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "primary--text grey lighten-3 ma-0 elevation-0 add-third-slot",
                                                      attrs: {
                                                        icon: "",
                                                        flat: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.showThirdSlotAvailabilities,
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("add"),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        605212941
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v("More Availability Slots"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.hasThirdSlotAvailabilities ||
                          _vm.thirdDateTimeSlots.length > 0
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("TimeSlotPicker", {
                                    attrs: {
                                      "header-text": _vm.getThirdPickedDate,
                                      "date-selection": true,
                                      "filter-by-date": true,
                                      "filter-slot-by-time":
                                        _vm.getIsThirdFilterSlotByTime,
                                      "slot-type": "DayAfterTomorrow",
                                      model: _vm.customerAvailability,
                                      "slot-items": _vm.thirdDateTimeSlots,
                                    },
                                    on: {
                                      "update:slotItems": function ($event) {
                                        _vm.thirdDateTimeSlots = $event
                                      },
                                      "update:slot-items": function ($event) {
                                        _vm.thirdDateTimeSlots = $event
                                      },
                                      updateSlotItems:
                                        _vm.onSlotSelectionChange,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "mt-3 elevation-1" },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", white: "" } },
            [
              _c("DelayCodes", {
                attrs: {
                  "has-delay": _vm.customerAvailability.hasDelay,
                  "delay-code": _vm.customerAvailability.delayCode,
                },
                on: { updateDelayCode: _vm.onDelayCodeChange },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }