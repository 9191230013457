var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false,
        "nudge-width": 380,
        "max-width": 380,
        "z-index": 99,
        left: "",
        "position-y": 56,
        "content-class": "notification-box",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "notification-bell",
                    attrs: { icon: "", large: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.openNotificationWindow.apply(null, arguments)
                      },
                    },
                  },
                  on
                ),
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c(
                        "v-badge",
                        { attrs: { overlap: "", color: "secondary" } },
                        [
                          _vm.getUnreadNotificationCount > 0
                            ? _c(
                                "span",
                                {
                                  attrs: { slot: "badge", small: "" },
                                  slot: "badge",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.getUnreadNotificationCount) +
                                      " "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-avatar",
                            { attrs: { size: 34 } },
                            [
                              _c("v-icon", { attrs: { dark: "" } }, [
                                _vm._v("notifications"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "n-arrow" }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showNotificationWindow,
        callback: function ($$v) {
          _vm.showNotificationWindow = $$v
        },
        expression: "showNotificationWindow",
      },
    },
    [
      _vm.showNotificationWindow
        ? _c(
            "div",
            { staticClass: "notification-content" },
            [
              _vm.isLoading
                ? _c("v-progress-circular", {
                    style: _vm.getLoaderStyle(30),
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _c(
                    "div",
                    [
                      _vm.notifications.length > 0
                        ? _c(
                            "div",
                            { staticClass: "clearall-btn" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "clear-all-notification",
                                  attrs: {
                                    flat: "",
                                    small: "",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClearAllNotificationClick()
                                    },
                                  },
                                },
                                [_vm._v(" Clear all ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        {
                          directives: [
                            {
                              name: "bar",
                              rawName: "v-bar",
                              value: { useScrollbarPseudo: true },
                              expression: "{ useScrollbarPseudo: true }",
                            },
                          ],
                          staticClass: "notification-menu",
                          class:
                            _vm.notifications.length > 0
                              ? "mt-4 show-notification"
                              : "",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "inner-content view-panel px-3 py-3",
                            },
                            [
                              _vm.notifications.length === 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "notification-msg grey lighten-3",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "grey--text" },
                                        [_vm._v("No notification(s)")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-list",
                                _vm._l(_vm.notifications, function (item) {
                                  return _c(
                                    "v-list-tile",
                                    {
                                      key: item.id,
                                      class: !item.isRead
                                        ? "unread-notification"
                                        : "grey lighten-3",
                                    },
                                    [
                                      _c(
                                        "v-list-tile-avatar",
                                        {
                                          attrs: {
                                            color:
                                              _vm.isNewReturnVisitAwaitingETANotification(
                                                item.notificationType
                                              ) ||
                                              _vm.isMaterialRequestWithoutPriceNotification(
                                                item.notificationType
                                              )
                                                ? "white"
                                                : "red",
                                            size: 32,
                                          },
                                        },
                                        [
                                          _vm.isNewReturnVisitAwaitingETANotification(
                                            item.notificationType
                                          )
                                            ? _c(
                                                "span",
                                                { staticClass: "return-visit" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "/img/return-visit.svg",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm.isMaterialRequestWithoutPriceNotification(
                                                item.notificationType
                                              )
                                            ? _c(
                                                "span",
                                                { staticClass: "return-visit" },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "/img/pound-symbol-pink.svg",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    dark: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getIconName(
                                                          item.notificationType
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                left: "",
                                                "max-width": "200",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "a",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "notification-anchor",
                                                              attrs: {
                                                                href: "Javascript:void(0)",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.markNotificationAsReadForUser(
                                                                      item.id,
                                                                      item.isRead
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            item.title
                                                              ? _c(
                                                                  "v-list-tile-title",
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.title
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "v-list-tile-sub-title",
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.message
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-tile-sub-title",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "caption",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "date_range"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "timeago",
                                                                      {
                                                                        attrs: {
                                                                          datetime:
                                                                            item.createdAt.format(
                                                                              _vm.dateFormat
                                                                            ),
                                                                          "auto-update": 60,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm.isBordereauNotification(
                                                item.notificationType
                                              )
                                                ? _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        " Client Id: " +
                                                          _vm._s(
                                                            item.notificationRecord
                                                              ? item
                                                                  .notificationRecord
                                                                  .partitionKey
                                                              : "--"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                    _c("span", [
                                                      _vm._v(
                                                        " Bordereau Id: " +
                                                          _vm._s(
                                                            item.notificationRecord
                                                              ? item
                                                                  .notificationRecord
                                                                  .id
                                                              : "--"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                  ])
                                                : _c("span", [
                                                    _c("span", [
                                                      _vm._v(
                                                        " Job Id: " +
                                                          _vm._s(
                                                            item.notificationRecord
                                                              ? item
                                                                  .notificationRecord
                                                                  .partitionKey
                                                              : "--"
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c("br"),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Address: " +
                                                          _vm._s(item.address)
                                                      ),
                                                    ]),
                                                  ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-action",
                                        [
                                          _c(
                                            "v-list-tile-action-text",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 redirect-job",
                                                  attrs: {
                                                    icon: "",
                                                    flat: "",
                                                    small: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.redirectToJob(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "grey",
                                                        small: "",
                                                      },
                                                    },
                                                    [_vm._v("launch")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 clear-notification",
                                                  attrs: {
                                                    small: "",
                                                    flat: "",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.clearNotification(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "md-icon",
                                                      attrs: { color: "grey" },
                                                    },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }