var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { md8: "", lg9: "", xs8: "" } }, [
        _c("div", { staticClass: "small-text" }, [
          _vm._v(_vm._s(_vm.job.clientFullName)),
        ]),
        _c("div", { staticClass: "small-text" }, [
          _vm._v(_vm._s(_vm.job.secondaryClientFullName)),
        ]),
        _vm.job
          ? _c("div", { staticClass: "small-text" }, [
              _c("pre", [_vm._v(_vm._s(_vm.job.getAddress("\n")))]),
            ])
          : _vm._e(),
      ]),
      _c("v-flex", { attrs: { md4: "", lg3: "", xs4: "" } }, [
        _c("div", { staticClass: "text-xs-right" }, [
          _vm.job.policyScheduleImageUrl
            ? _c("img", {
                staticClass: "policy-img",
                attrs: {
                  src: _vm.job.policyScheduleImageUrl,
                  alt: "",
                  width: "100%",
                },
              })
            : _vm._e(),
        ]),
      ]),
      _vm.job.jobType !== "SI"
        ? _c(
            "v-flex",
            { attrs: { xs12: "", "mt-2": "" } },
            _vm._l(_vm.getEmergencyList, function (emergency) {
              return _c(
                "div",
                {
                  key: emergency.id,
                  staticClass:
                    "fnol-emergency-details grey lighten-3 mb-1 pa-1",
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c("span", _vm._g({}, on), [
                                      _c("i", { staticClass: "img-content" }, [
                                        emergency.fileURL !== null &&
                                        emergency.fileURL !== ""
                                          ? _c("img", {
                                              attrs: { src: emergency.fileURL },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: "/img/emergency.svg",
                                                alt: "",
                                              },
                                            }),
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "em-title-content" },
                                        [
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                emergency.detailDescription +
                                                  ": "
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  emergency.typeDescription
                                    ? emergency.typeDescription
                                    : emergency.detailDescription
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getFormattedDateTime(emergency.firstNoticedAt)
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
                  emergency.thirdPartyContractor
                    ? _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            "mt-1": "",
                            "mb-2": "",
                            "pl-0": "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "third-party-contractor" },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                {
                                                  staticClass: "md-icon mr-1",
                                                  attrs: { color: "secondary" },
                                                },
                                                on
                                              ),
                                              [_vm._v("group")]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v("Third Party Contractor")])]
                              ),
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getThirdPartyContractor(
                                        emergency.thirdPartyContractor
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.job.additionalCostsAuthorised
        ? _c("v-flex", { staticClass: "my-1" }, [
            _c("b", { staticClass: "green--text" }, [
              _vm._v(
                "Additional payment authorized by " +
                  _vm._s(_vm.job.policyScheme)
              ),
            ]),
          ])
        : _vm._e(),
      _vm.job.nomineeClientForename && _vm.job.nomineeClientLastName
        ? _c("v-flex", { staticClass: "my-1" }, [
            _c("b", [
              _vm._v(
                "Nominee : " +
                  _vm._s(_vm.job.nomineeClientFullName) +
                  " (" +
                  _vm._s(_vm.getRelationWithNominee(_vm.job.relation)) +
                  ")"
              ),
            ]),
          ])
        : _vm._e(),
      _vm.job.jobType == "SI"
        ? _c(
            "v-flex",
            { staticClass: "my-1" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    disabled: !(_vm.siJobDelay && _vm.siJobDelay.notes),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "span",
                              _vm._g(
                                {
                                  class: _vm.siJobDelay
                                    ? "red--text"
                                    : "green--text",
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.siJobDelay
                                        ? "Delay: " + _vm.siJobDelay.reason
                                        : "No delay logged"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    229004307
                  ),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.siJobDelay ? _vm.siJobDelay.notes : "")),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.job.jobType == "US"
        ? _c(
            "v-flex",
            { staticClass: "my-1" },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    bottom: "",
                    disabled: !(_vm.usJobDelay && _vm.usJobDelay.notes),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "span",
                              _vm._g(
                                {
                                  class: _vm.usJobDelay
                                    ? "red--text"
                                    : "green--text",
                                },
                                on
                              ),
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.usJobDelay
                                        ? "Delay: " + _vm.usJobDelay.reason
                                        : "No delay logged"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1996946383
                  ),
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.usJobDelay ? _vm.usJobDelay.notes : "")),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.job.commodityWorkType && _vm.job.jobType === "US"
        ? _c("v-flex", { staticClass: "my-1" }, [
            _vm._v(" Commodity Work Type: "),
            _c("b", [_vm._v(_vm._s(_vm.job.commodityWorkType))]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }