var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: _vm.tableName } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "grid-view": "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "request-info": "" } }, [
            _c("h3", [_vm._v("Job History")]),
          ]),
          _c("v-flex", { attrs: { xs6: "", "job-dashboard-seach": "" } }, [
            _c(
              "div",
              { staticClass: "search-content" },
              [
                _c("v-text-field", {
                  staticClass: "mt-0 pt-0",
                  attrs: {
                    "append-icon": "search",
                    label: _vm.setSearchLabel(),
                    "single-line": "",
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.searchValue,
                    callback: function ($$v) {
                      _vm.searchValue = $$v
                    },
                    expression: "searchValue",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-options" },
              [
                _c("v-select", {
                  attrs: {
                    items: _vm.searchByFieldItems,
                    "item-value": "value",
                    "item-text": "text",
                    "single-line": "",
                    "hide-details": "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeSearchByFieldValue($event)
                    },
                  },
                  model: {
                    value: _vm.searchByFieldName,
                    callback: function ($$v) {
                      _vm.searchByFieldName = $$v
                    },
                    expression: "searchByFieldName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "v-flex",
            { staticClass: "elevation-1", attrs: { xs12: "", "mt-2": "" } },
            [
              _c("v-data-table", {
                staticClass: "complaint-table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.jobItems,
                  "total-items": _vm.totalJobs,
                  loading: _vm.isLoading,
                  pagination: _vm.pagination,
                  "rows-per-page-items": _vm.rowsPerPageItems,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("tr", {
                          attrs: { active: props.selected },
                          on: {
                            click: function ($event) {
                              props.selected = !props.selected
                            },
                          },
                        }),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "primary--text",
                                on: {
                                  click: function ($event) {
                                    return _vm.redirectToJobUrl(props.item.id)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(props.item.id) + " ")]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(props.item.policyNumber))]),
                          _c("td", [_vm._v(_vm._s(props.item.name))]),
                          _c("td", [_vm._v(_vm._s(props.item.postCode))]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.getFormattedDateTime(props.item.createdAt)
                              )
                            ),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }