var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.getVisibleItems(_vm.menuItems), function (menuItem) {
      return _c(
        "v-list-group",
        { key: menuItem.path, attrs: { "append-icon": "" } },
        [
          _c(
            "v-list-tile",
            {
              attrs: { slot: "activator" },
              on: {
                click: function ($event) {
                  return _vm.menuItemClick(menuItem)
                },
              },
              slot: "activator",
            },
            [
              _c(
                "v-list-tile-action",
                [
                  menuItem.icon
                    ? _c("v-icon", [_vm._v(_vm._s(menuItem.icon))])
                    : _c("img", {
                        staticClass: "menu-img",
                        attrs: { src: menuItem.image },
                      }),
                ],
                1
              ),
              _c(
                "v-list-tile-content",
                [
                  _c(
                    "v-list-tile-title",
                    [
                      menuItem.tooltip
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("span", _vm._g({}, on), [
                                          _vm._v(_vm._s(menuItem.text)),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(menuItem.tooltip))])]
                          )
                        : _c("span", [_vm._v(_vm._s(menuItem.text))]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.getVisibleSubItems(menuItem), function (item) {
            return _c(
              "v-list-tile",
              {
                key: item.path,
                staticClass: "sub-menu-list",
                on: {
                  click: function ($event) {
                    return _vm.subMenuItemClick(item)
                  },
                },
              },
              [
                item.icon || item.image
                  ? _c(
                      "v-list-tile-action",
                      [
                        item.icon
                          ? _c("v-icon", [_vm._v(_vm._s(item.icon))])
                          : _c("img", {
                              staticClass: "menu-img",
                              attrs: { src: item.image },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-list-tile-content",
                  [_c("v-list-tile-title", [_vm._v(_vm._s(item.text))])],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }