var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.flagEnabled
    ? _c(
        "div",
        [
          _c("AgentReservationsDialog"),
          _c(
            "div",
            [
              _c(
                "v-card-title",
                { staticClass: "pa-0 mb-2", attrs: { "primary-title": "" } },
                [
                  _c("h3", { staticClass: "mb-0" }, [
                    _vm._v("Agent Dashboard"),
                  ]),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    staticClass: "mt-0 pt-0",
                    attrs: {
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-data-table", {
                    staticClass: "gridView",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.agents,
                      loading: _vm.isLoading,
                      search: _vm.search,
                      pagination: _vm.pagination,
                    },
                    on: {
                      "update:pagination": function ($event) {
                        _vm.pagination = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function ({ item }) {
                            return [
                              _c("td", [_vm._v(_vm._s(item.name))]),
                              _c("td", [_vm._v(_vm._s(item.status))]),
                              _c(
                                "td",
                                { staticClass: "text-xs-right px-0" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "mx-0",
                                                      attrs: {
                                                        small: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onFixClick(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [_vm._v(" Fix ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Fix")])]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1640399394
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }