var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "center-sec-layout" },
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "500",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.tradeDialog,
            callback: function ($$v) {
              _vm.tradeDialog = $$v
            },
            expression: "tradeDialog",
          },
        },
        [
          _c("Trade", {
            attrs: {
              "trade-item": _vm.tradeModel,
              "emergency-types": _vm.emergencyTypes,
              "emergency-details": _vm.emergencyDetails,
              "selected-emergency-details": _vm.selectedEmergencyDetails,
            },
            on: { OnTradeSave: _vm.OnTradeSave },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "transparent", attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "py-0 px-0",
                          attrs: { "primary-title": "" },
                        },
                        [
                          _c("h3", [_vm._v("Trade List")]),
                          _c("v-spacer"),
                          _c("v-select", {
                            staticClass: "mt-0 pt-0 mr-3 job-type-select",
                            attrs: {
                              items: _vm.getJobTypes,
                              label: "Job Type",
                              "item-text": "jobType",
                              "item-value": "jobType",
                              "single-line": "",
                              "hide-details": "",
                              multiple: "",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onJobTypeChange(
                                  _vm.selectedJobType,
                                  $event
                                )
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-list-tile-action",
                                      [
                                        _c("v-checkbox", {
                                          attrs: { color: "primary" },
                                          model: {
                                            value:
                                              _vm.jobTypeCheckboxes[
                                                _vm.getJobTypes.indexOf(
                                                  data.item
                                                )
                                              ].checked,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.jobTypeCheckboxes[
                                                  _vm.getJobTypes.indexOf(
                                                    data.item
                                                  )
                                                ],
                                                "checked",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "jobTypeCheckboxes[getJobTypes.indexOf(data.item)].checked",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.item.jobTypeDescription
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedJobType,
                              callback: function ($$v) {
                                _vm.selectedJobType = $$v
                              },
                              expression: "selectedJobType",
                            },
                          }),
                          _c("v-text-field", {
                            staticClass: "mt-0 pt-0",
                            attrs: {
                              "append-icon": "search",
                              label: "Search",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              icon: "",
                                              color: "primary",
                                              flat: "",
                                            },
                                            on: { click: _vm.openTradeDialog },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Add Trade")])]
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "trade-table", attrs: { xs12: "" } },
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.tradelist,
                                  "prev-icon": "mdi-menu-left",
                                  "next-icon": "mdi-menu-right",
                                  "sort-icon": "mdi-menu-down",
                                  search: _vm.search,
                                  pagination: _vm.pagination,
                                },
                                on: {
                                  "update:pagination": function ($event) {
                                    _vm.pagination = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.description)
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-xs-center trade-code",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.shortCode
                                                    ? props.item.shortCode
                                                    : "-"
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          _vm._l(
                                            props.item.emergency,
                                            function (emergency) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: emergency.emergencyId,
                                                  staticClass:
                                                    "primary--text emergency",
                                                  class: emergency.isDeleted
                                                    ? "deleted-item"
                                                    : "",
                                                  attrs: {
                                                    flat: "",
                                                    outline: "",
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        emergency.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "td",
                                          _vm._l(
                                            props.item.emergencydetail,
                                            function (emergencyDetails) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: emergencyDetails.emergencyDetailId,
                                                  staticClass:
                                                    "primary--text emergency-detail",
                                                  class:
                                                    emergencyDetails.isDeleted
                                                      ? "deleted-item"
                                                      : "",
                                                  attrs: {
                                                    flat: "",
                                                    small: "",
                                                    outline: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        emergencyDetails.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c(
                                          "td",
                                          _vm._l(
                                            props.item.delayCodes,
                                            function (delayId, index) {
                                              return _c(
                                                "v-chip",
                                                {
                                                  key: `${delayId}-${index}`,
                                                  staticClass:
                                                    "primary--text emergency-detail",
                                                  attrs: {
                                                    flat: "",
                                                    small: "",
                                                    outline: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.retrieveDelayCode(
                                                          delayId
                                                        ).description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.jobType)),
                                        ]),
                                        _c(
                                          "td",
                                          { staticClass: "text-xs-right" },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEditClick(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [_vm._v("edit")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Edit")])]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mx-0",
                                                                attrs: {
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onDeleteClick(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Delete")])]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-snackbar",
                        {
                          attrs: {
                            timeout: _vm.snackbarTimeout,
                            left: "",
                            bottom: "",
                          },
                          model: {
                            value: _vm.snackbar,
                            callback: function ($$v) {
                              _vm.snackbar = $$v
                            },
                            expression: "snackbar",
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", flat: "", color: "secondary" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.snackbar = false
                                },
                              },
                            },
                            [_vm._v("close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }