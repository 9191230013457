var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.contractor && _vm.isContractorReviewed,
              expression: "!contractor && isContractorReviewed",
            },
          ],
          staticClass: "contractor-management-cp",
        },
        [
          _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _vm.contractor
        ? _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-layout",
                {
                  class: !_vm.showContractorProfileInDialog ? "mb-2" : "",
                  attrs: { row: "", wrap: "" },
                },
                [
                  _c(
                    "v-flex",
                    {
                      class: _vm.showContractorProfileInDialog
                        ? "profile-sec"
                        : "md8 lg6 offset-lg3 offset-md3 mt-3 profile-sec",
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-2 pa-0" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "cntp-upper-sec primary pt-2",
                              attrs: { wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-center cntp-logo-sec",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "cntp-logo-sec-content" },
                                    [
                                      _vm.imageUrl
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "rm-logo elevation-1 pa-0",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 btn-remove",
                                                  attrs: {
                                                    flat: "",
                                                    icon: "",
                                                    color: "primary",
                                                    small: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.removeImage.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("delete")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "cntp-logo-img mb-3",
                                          on: { click: _vm.pickFile },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "elevation-1",
                                            attrs: {
                                              src: _vm.imageUrl
                                                ? _vm.imageUrl
                                                : "/img/unknownuser.png",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "cntp-logo-img-edit",
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: "secondary",
                                                    large: "",
                                                  },
                                                },
                                                [_vm._v("edit")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              rules: {
                                                required: false,
                                                image: true,
                                                size: _vm.profileImageSize,
                                                mimes: [
                                                  "image/jpeg",
                                                  "image/jpg",
                                                  "image/png",
                                                  "image/gif",
                                                ],
                                                ext: [
                                                  "jpg",
                                                  "jpeg",
                                                  "png",
                                                  "gif",
                                                ],
                                              },
                                            },
                                            expression:
                                              "{\n                    rules: {\n                      required: false,\n                      image: true,\n                      size: profileImageSize,\n                      mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],\n                      ext: ['jpg', 'jpeg', 'png', 'gif'],\n                    },\n                  }",
                                          },
                                        ],
                                        ref: "image",
                                        staticStyle: { display: "none" },
                                        attrs: {
                                          type: "file",
                                          accept: "image/*",
                                          name: "logo",
                                          "data-vv-scope": "frmProfile",
                                        },
                                        on: { change: _vm.onFilePicked },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-group__details imageError-wrapper",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-group__messages input-group__error input-group--error error--text",
                                            },
                                            [
                                              _vm.imageValidationMessage(
                                                _vm.errors
                                              )
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.logoErrorMsg
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "pa-3 mt-5",
                              attrs: { row: "", wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Company Name",
                                      maxlength: "50",
                                      required: "",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Company Name",
                                      "error-messages":
                                        _vm.errors.collect("Company Name"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.companyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "companyName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.companyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Trading Name (If different)",
                                      maxlength: "50",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.tradingName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "tradingName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.tradingName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Company Registration No (If LTD)",
                                      maxlength: "100",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.registrationNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "registrationNumber",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "contractor.registrationNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "VAT Registration Number",
                                      maxlength: "100",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value:
                                        _vm.contractor.vatRegistrationNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "vatRegistrationNumber",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "contractor.vatRegistrationNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Proprietor Name",
                                      maxlength: "100",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.proprietorName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "proprietorName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.proprietorName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Director Name",
                                      maxlength: "100",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.directorName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "directorName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.directorName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      items: _vm.getCompanyTypeList,
                                      "item-text": "alterText",
                                      "item-value": "description",
                                      label: "Type Of Company",
                                      required: "",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Company Type",
                                      "error-messages":
                                        _vm.errors.collect("Company Type"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.companyType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "companyType",
                                          $$v
                                        )
                                      },
                                      expression: "contractor.companyType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "url",
                                        expression: "'url'",
                                      },
                                    ],
                                    attrs: {
                                      label: "Website",
                                      maxlength: "50",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Website",
                                      "error-messages":
                                        _vm.errors.collect("Website"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.website,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "website",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.website",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Address",
                                      maxlength: "500",
                                      rows: "3",
                                      required: "",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Address",
                                      "error-messages":
                                        _vm.errors.collect("Address"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "address",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Trading Address",
                                      maxlength: "500",
                                      rows: "3",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.tradingAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "tradingAddress",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.tradingAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Purchase Ledger",
                                      maxlength: "50",
                                      readonly: !_vm.canEditContractors,
                                      name: "PurchaseLedger",
                                    },
                                    model: {
                                      value: _vm.contractor.purchaseLedger,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "purchaseLedger",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.purchaseLedger",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Analysis Code",
                                      maxlength: "50",
                                      readonly: !_vm.canEditContractors,
                                      name: "AnalysisCode",
                                    },
                                    model: {
                                      value: _vm.contractor.analysisCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "analysisCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.analysisCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Trade Type",
                                      maxlength: "500",
                                      rows: "3",
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "description",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pr-2": "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Email",
                                      maxlength: "50",
                                      required: "",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Email",
                                      "error-messages":
                                        _vm.errors.collect("Email"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "email",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "pl-2": "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Country",
                                      "item-text": "description",
                                      "item-value": "id",
                                      items: _vm.getCountryNames,
                                      required: "",
                                      "data-vv-scope": "frmProfile",
                                      "data-vv-name": "Country",
                                      "error-messages":
                                        _vm.errors.collect("Country"),
                                      readonly: _vm.isUserRoleContractor,
                                    },
                                    model: {
                                      value: _vm.contractor.country,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractor,
                                          "country",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "contractor.country",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.showUserNotificationPreferences
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          loading: _vm.loadingCommsPrefs,
                                          disabled: _vm.loadingCommsPrefs,
                                          label: "Copy Emails to Engineers",
                                          color: "primary",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value:
                                            _vm.contractorComms
                                              .blindCopyEmailToEngineers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.contractorComms,
                                              "blindCopyEmailToEngineers",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "contractorComms.blindCopyEmailToEngineers",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grey py-2 px-3 mt-4 lighten-4 multiple-contact-field",
                                    class:
                                      _vm.contractor.contactNumbers &&
                                      _vm.contractor.contactNumbers.length > 1
                                        ? "add-remove-btn"
                                        : "",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "bar",
                                            rawName: "v-bar",
                                            value: { useScrollbarPseudo: true },
                                            expression:
                                              "{ useScrollbarPseudo: true }",
                                          },
                                        ],
                                      },
                                      [
                                        _vm.contractor.contactNumbers
                                          ? _vm._l(
                                              _vm.contractor.contactNumbers,
                                              function (contact, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs6: "",
                                                              "pr-2": "",
                                                            },
                                                          },
                                                          [
                                                            _c("v-combobox", {
                                                              attrs: {
                                                                label: "Label",
                                                                maxlength: "20",
                                                                "return-object":
                                                                  "",
                                                                items:
                                                                  _vm.phoneNumberLabelOptions,
                                                                readonly:
                                                                  _vm.isUserRoleContractor ||
                                                                  _vm.disableIfCompulsory(
                                                                    contact.label
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  contact.label,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      contact,
                                                                      "label",
                                                                      typeof $$v ===
                                                                        "string"
                                                                        ? $$v.trim()
                                                                        : $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "contact.label",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs6: "",
                                                              "pl-2": "",
                                                            },
                                                          },
                                                          [
                                                            !_vm.isUserRoleContractor
                                                              ? _c(
                                                                  "PhoneNumber",
                                                                  {
                                                                    ref: "contractorContactNumber",
                                                                    refInFor: true,
                                                                    staticClass:
                                                                      "contractor-phone-number",
                                                                    attrs: {
                                                                      "passed-phone-number":
                                                                        contact.phoneNumber,
                                                                      "is-validation-required": true,
                                                                      index:
                                                                        index,
                                                                    },
                                                                    on: {
                                                                      phoneNumberUpdated:
                                                                        _vm.phoneNumberUpdated,
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "v-text-field",
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        _vm.isUserRoleContractor,
                                                                      value:
                                                                        contact.phoneNumber,
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.contractor
                                                          .contactNumbers &&
                                                        _vm.contractor
                                                          .contactNumbers
                                                          .length > 1 &&
                                                        !_vm.isUserRoleContractor &&
                                                        !_vm.disableIfCompulsory(
                                                          contact.label
                                                        )
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "contact-remove ma-0",
                                                                attrs: {
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "secondary",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteContact(
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "md-icon",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "delete"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                    !_vm.isUserRoleContractor
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass: "ma-0 btn-add",
                                            class:
                                              _vm.contractor.contactNumbers &&
                                              _vm.contractor.contactNumbers
                                                .length > 1
                                                ? "add-remove-btn-icon"
                                                : "contact-add",
                                            attrs: {
                                              icon: "",
                                              flat: "",
                                              color: "primary",
                                            },
                                            on: { click: _vm.addNewContact },
                                          },
                                          [_c("v-icon", [_vm._v("add")])],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.isContractorReviewed
                                ? [
                                    _vm.canEditContractors
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mt-1": "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items:
                                                  _vm.preferredContractorOptions,
                                                label: "Contractor Status",
                                                "item-value": "id",
                                                "item-text": "description",
                                                disabled:
                                                  _vm.isFromCallContractor,
                                                readonly:
                                                  _vm.isUserRoleContractor,
                                              },
                                              model: {
                                                value:
                                                  _vm.contractor
                                                    .preferredContractor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractor,
                                                    "preferredContractor",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "contractor.preferredContractor",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.isUserRoleContractor
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mt-1": "" } },
                                          [
                                            _c("v-switch", {
                                              attrs: {
                                                label: "Delegated Authority",
                                                color: "primary",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.contractor
                                                    .isDelegatedAuthority,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractor,
                                                    "isDelegatedAuthority",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contractor.isDelegatedAuthority",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.isUserRoleContractor
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mt-1": "" } },
                                          [
                                            _c("v-text-field", {
                                              directives: [
                                                {
                                                  name: "validate",
                                                  rawName: "v-validate",
                                                  value: {
                                                    rules: {
                                                      max_value: 9999.99,
                                                      decimal: 2,
                                                      max: 7,
                                                    },
                                                  },
                                                  expression:
                                                    "{\n                    rules: { max_value: 9999.99, decimal: 2, max: 7 },\n                  }",
                                                },
                                              ],
                                              attrs: {
                                                label:
                                                  "Delegated Authority Amount",
                                                type: "number",
                                                disabled:
                                                  !_vm.contractor
                                                    .isDelegatedAuthority,
                                                placeholder: "Enter Amount",
                                                "data-vv-scope": "frmProfile",
                                                "data-vv-name":
                                                  "Delegated Authority Amount",
                                                "error-messages":
                                                  _vm.errors.collect(
                                                    "Delegated Authority Amount"
                                                  ),
                                              },
                                              on: {
                                                keypress: function ($event) {
                                                  return _vm.numberKeyValidation(
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.contractor
                                                    .delegatedAuthorityLimit,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractor,
                                                    "delegatedAuthorityLimit",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "contractor.delegatedAuthorityLimit",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mt-1": "" } },
                                      [
                                        _c("v-autocomplete", {
                                          attrs: {
                                            items: _vm.contractorList,
                                            label:
                                              "Select Members of the Group",
                                            "item-text": "companyName",
                                            "item-value": "id",
                                            multiple: "",
                                            chips: "",
                                            readonly: _vm.isUserRoleContractor,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        key: JSON.stringify(
                                                          data.item
                                                        ),
                                                        staticClass:
                                                          "chip--select-multi",
                                                        attrs: {
                                                          selected:
                                                            data.selected,
                                                          close:
                                                            !_vm.isUserRoleContractor,
                                                          readonly:
                                                            _vm.isUserRoleContractor,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            return data.parent.selectItem(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .companyName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1128298485
                                          ),
                                          model: {
                                            value:
                                              _vm.contractor.subContractors,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contractor,
                                                "subContractors",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contractor.subContractors",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.canEditContractors
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mt-1": "" } },
                                          [
                                            _c("v-switch", {
                                              attrs: {
                                                "hide-details": "",
                                                label: "Independent Contractor",
                                                color: "primary",
                                                readonly:
                                                  _vm.isUserRoleContractor,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.onIsIndependentChange()
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.contractor.isIndependent,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractor,
                                                    "isIndependent",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contractor.isIndependent",
                                              },
                                            }),
                                            _vm.isIndependentContractorErrorMsg
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "input-group__details",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group__messages input-group__error input-group--error error--text",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "You cannot be an Independent Contractor. You have multiple engineers configured."
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm6: "",
                                          "pr-2": "",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            disabled: !(
                                              _vm.contractor.isIndependent &&
                                              (_vm.canEditContractors ||
                                                _vm.isUserRoleContractor)
                                            ),
                                            label: "First Name",
                                            maxlength: "50",
                                            required: "",
                                            "data-vv-scope": "frmProfile",
                                            "data-vv-name": "First Name",
                                            "error-messages":
                                              _vm.errors.collect("First Name"),
                                          },
                                          model: {
                                            value: _vm.contractor.firstName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contractor,
                                                "firstName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "contractor.firstName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs12: "",
                                          sm6: "",
                                          "pl-2": "",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            disabled: !(
                                              _vm.contractor.isIndependent &&
                                              (_vm.canEditContractors ||
                                                _vm.isUserRoleContractor)
                                            ),
                                            label: "Last Name",
                                            maxlength: "50",
                                          },
                                          model: {
                                            value: _vm.contractor.lastName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contractor,
                                                "lastName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "contractor.lastName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mt-1": "" } },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            items: _vm.getAutoDeploymentTypes,
                                            "item-text": "description",
                                            "item-value": "name",
                                            label: "AutoDeployment Type",
                                            disabled: _vm.isFromCallContractor,
                                            readonly: _vm.isUserRoleContractor,
                                          },
                                          model: {
                                            value:
                                              _vm.contractor.autoDeploymentType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.contractor,
                                                "autoDeploymentType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "contractor.autoDeploymentType",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.contractor.autoDeploymentType ==
                                      _vm.autoDeploymentOption[
                                        _vm.autoDeploymentOption
                                          .AutodeployToEngineer
                                      ] ||
                                    _vm.contractor.autoDeploymentType ==
                                      _vm.autoDeploymentOption[
                                        _vm.autoDeploymentOption
                                          .AutodeployToContractor
                                      ]
                                      ? [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "mt-1": "",
                                                "pb-2": "",
                                              },
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: "24 hours",
                                                  color: "primary",
                                                  "hide-details": "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.onAutoDeployChange,
                                                },
                                                model: {
                                                  value:
                                                    _vm.contractor
                                                      .autoDeployAlwaysActive,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.contractor,
                                                      "autoDeployAlwaysActive",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "contractor.autoDeployAlwaysActive",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pr-2": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "autoDeployStartTimePicker",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.contractor
                                                        .autoDeployStartTime,
                                                    lazy: "",
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "full-width": "",
                                                    "nudge-right": 40,
                                                    "nudge-top": 400,
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.contractor,
                                                          "autoDeployStartTime",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.contractor,
                                                          "autoDeployStartTime",
                                                          $event
                                                        )
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "required",
                                                                  attrs: {
                                                                    label:
                                                                      "From time",
                                                                    "append-icon":
                                                                      "access_time",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "data-vv-scope":
                                                                      "frmProfile",
                                                                    "data-vv-name":
                                                                      "From time",
                                                                    "error-messages":
                                                                      _vm.errors.collect(
                                                                        "From time"
                                                                      ),
                                                                    disabled:
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployAlwaysActive,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployStartTime,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.contractor,
                                                                          "autoDeployStartTime",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "contractor.autoDeployStartTime",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3620512081
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.showautoDeployStartTimePicker,
                                                    callback: function ($$v) {
                                                      _vm.showautoDeployStartTimePicker =
                                                        $$v
                                                    },
                                                    expression:
                                                      "showautoDeployStartTimePicker",
                                                  },
                                                },
                                                [
                                                  _vm.showautoDeployStartTimePicker
                                                    ? _c(
                                                        "v-time-picker",
                                                        {
                                                          attrs: {
                                                            format: "24hr",
                                                            "allowed-minutes":
                                                              _vm.allowedMinutes,
                                                            "full-width": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contractor
                                                                .autoDeployStartTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contractor,
                                                                "autoDeployStartTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contractor.autoDeployStartTime",
                                                          },
                                                        },
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showautoDeployStartTimePicker = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$refs.autoDeployStartTimePicker.save(
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployStartTime
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" OK ")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                sm6: "",
                                                "pr-2": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  ref: "autoDeployEndTimePicker",
                                                  attrs: {
                                                    "close-on-content-click": false,
                                                    "return-value":
                                                      _vm.contractor
                                                        .autoDeployEndTime,
                                                    lazy: "",
                                                    transition:
                                                      "scale-transition",
                                                    "offset-y": "",
                                                    "full-width": "",
                                                    "nudge-right": 40,
                                                    "nudge-top": 400,
                                                  },
                                                  on: {
                                                    "update:returnValue":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.contractor,
                                                          "autoDeployEndTime",
                                                          $event
                                                        )
                                                      },
                                                    "update:return-value":
                                                      function ($event) {
                                                        return _vm.$set(
                                                          _vm.contractor,
                                                          "autoDeployEndTime",
                                                          $event
                                                        )
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-text-field",
                                                              _vm._g(
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "required",
                                                                  attrs: {
                                                                    label:
                                                                      "To time",
                                                                    "append-icon":
                                                                      "access_time",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "data-vv-name":
                                                                      "To time",
                                                                    "error-messages":
                                                                      _vm.errors.collect(
                                                                        "To time"
                                                                      ),
                                                                    "data-vv-scope":
                                                                      "frmProfile",
                                                                    disabled:
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployAlwaysActive,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployEndTime,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.contractor,
                                                                          "autoDeployEndTime",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "contractor.autoDeployEndTime",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1226770963
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.showautoDeployEndTimePicker,
                                                    callback: function ($$v) {
                                                      _vm.showautoDeployEndTimePicker =
                                                        $$v
                                                    },
                                                    expression:
                                                      "showautoDeployEndTimePicker",
                                                  },
                                                },
                                                [
                                                  _vm.showautoDeployEndTimePicker
                                                    ? _c(
                                                        "v-time-picker",
                                                        {
                                                          attrs: {
                                                            format: "24hr",
                                                            "allowed-minutes":
                                                              _vm.allowedMinutes,
                                                            "full-width": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.contractor
                                                                .autoDeployEndTime,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.contractor,
                                                                "autoDeployEndTime",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "contractor.autoDeployEndTime",
                                                          },
                                                        },
                                                        [
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.showautoDeployEndTimePicker = false
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("Cancel")]
                                                          ),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$refs.autoDeployEndTimePicker.save(
                                                                      _vm
                                                                        .contractor
                                                                        .autoDeployEndTime
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v(" OK ")]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                    _vm.canEditContractors
                                      ? _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "mt-1": "" } },
                                          [
                                            _c("v-switch", {
                                              attrs: {
                                                label: "On hold",
                                                color: "primary",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.contractor
                                                    .hasHoldDuration,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.contractor,
                                                    "hasHoldDuration",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "contractor.hasHoldDuration",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              _vm.contractor.hasHoldDuration &&
                              _vm.canEditContractors
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pr-2": "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "fromDatePickerMenu",
                                          attrs: {
                                            top: "",
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            lazy: "",
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "full-width": "",
                                            "min-width": "290px",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "Select from date",
                                                            "append-icon":
                                                              "date_range",
                                                            readonly: "",
                                                            "hide-details": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.chosenHoldOnFromFormattedDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.chosenHoldOnFromFormattedDate =
                                                                $$v
                                                            },
                                                            expression:
                                                              "chosenHoldOnFromFormattedDate",
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3359623507
                                          ),
                                          model: {
                                            value:
                                              _vm.showHoldOnFromDateSelection,
                                            callback: function ($$v) {
                                              _vm.showHoldOnFromDateSelection =
                                                $$v
                                            },
                                            expression:
                                              "showHoldOnFromDateSelection",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                "no-title": "",
                                                scrollable: "",
                                                min: _vm.minOnHoldFromDate,
                                              },
                                              model: {
                                                value: _vm.chosenHoldOnFromDate,
                                                callback: function ($$v) {
                                                  _vm.chosenHoldOnFromDate = $$v
                                                },
                                                expression:
                                                  "chosenHoldOnFromDate",
                                              },
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showHoldOnFromDateSelection = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btn-ok",
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onSelectHoldOnFromDatePicker(
                                                        _vm.chosenHoldOnFromDate
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" OK ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.contractor.hasHoldDuration
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pl-2": "" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          ref: "toDatePickerMenu",
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            lazy: "",
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "full-width": "",
                                            "min-width": "290px",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-text-field",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              "Select to date",
                                                            "append-icon":
                                                              "date_range",
                                                            readonly: "",
                                                            "hide-details": "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.chosenHoldOnToFormattedDate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.chosenHoldOnToFormattedDate =
                                                                $$v
                                                            },
                                                            expression:
                                                              "chosenHoldOnToFormattedDate",
                                                          },
                                                        },
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            2391147091
                                          ),
                                          model: {
                                            value:
                                              _vm.showHoldOnToDateSelection,
                                            callback: function ($$v) {
                                              _vm.showHoldOnToDateSelection =
                                                $$v
                                            },
                                            expression:
                                              "showHoldOnToDateSelection",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-date-picker",
                                            {
                                              attrs: {
                                                "no-title": "",
                                                scrollable: "",
                                                min: _vm.minOnHoldToDate,
                                              },
                                              model: {
                                                value: _vm.chosenHoldOnToDate,
                                                callback: function ($$v) {
                                                  _vm.chosenHoldOnToDate = $$v
                                                },
                                                expression:
                                                  "chosenHoldOnToDate",
                                              },
                                            },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.showHoldOnToDateSelection = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "btn-ok",
                                                  attrs: {
                                                    flat: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onSelectHoldOnToDatePicker(
                                                        _vm.chosenHoldOnToDate
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" OK ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.commonErrorMsg
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pl-2": "" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "pt-3 common-error-message",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "red pa-2 d-block lighten-4",
                                            },
                                            [
                                              _c("span", [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(_vm.commonErrorMsg)
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-right mt-3",
                                  attrs: { xs12: "" },
                                },
                                [
                                  !_vm.isUserRoleContractor &&
                                  _vm.showContractorProfileInDialog
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-0 mr-2 btn-cancel",
                                          attrs: { flat: "", color: "primary" },
                                          on: {
                                            click: _vm.onCancelButtonClick,
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isUserRoleContractor
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-0 btn-save",
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.isLoading,
                                            disabled: _vm.isLoading,
                                          },
                                          on: {
                                            click: _vm.saveContractorProfile,
                                          },
                                        },
                                        [_vm._v(" Save ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    timeout: _vm.saveSnackbarTimeout,
                                    bottom: true,
                                    left: true,
                                  },
                                  model: {
                                    value: _vm.saveSnackbar,
                                    callback: function ($$v) {
                                      _vm.saveSnackbar = $$v
                                    },
                                    expression: "saveSnackbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.saveSnackbarText) + " "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "", color: "secondary" },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.saveSnackbar = false
                                        },
                                      },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }