var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "all-jobs dashboard",
      class: _vm.isActiveBlock ? "open-block" : "",
    },
    [
      _c(
        "v-container",
        {
          attrs: {
            fluid: "",
            "grid-list-xl": "",
            "pt-0": "",
            "pa-0": "",
            "px-0": "",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ready,
                  expression: "ready",
                },
              ],
            },
            [
              !_vm.isBtnPrimary
                ? _c(
                    "div",
                    { staticClass: "filter elevation-2 pa-3 mb-3" },
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", [
                            _c(
                              "span",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.getJobEscalationTypes,
                                    "item-text": "description",
                                    "item-value": "id",
                                    label: "Escalation Type",
                                    loading:
                                      _vm.gettingEscalationRecordsRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsBySelectedJobEscalationType(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.jobEscalationType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobsFilter,
                                        "jobEscalationType",
                                        $$v
                                      )
                                    },
                                    expression: "jobsFilter.jobEscalationType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", [
                            _c(
                              "span",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.userTeam,
                                    "item-text": "teamName",
                                    "item-value": "id",
                                    label: "Search team",
                                    loading: _vm.getteamRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsBySelectedTeam(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.teamName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.jobsFilter, "teamName", $$v)
                                    },
                                    expression: "jobsFilter.teamName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", [
                            _c(
                              "span",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.contractors,
                                    "item-text": "companyName",
                                    "item-value": "id",
                                    label: "Search contractor",
                                    loading: _vm.getContractorRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onContractorChange($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.contractorId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobsFilter,
                                        "contractorId",
                                        $$v
                                      )
                                    },
                                    expression: "jobsFilter.contractorId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", [
                            _c(
                              "span",
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.getVulnerabilityLevel,
                                    "item-text": "description",
                                    "item-value": "id",
                                    label: "Vulnerability Level",
                                    loading: _vm.vulnerabilityFilterRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsBySelectedJobVulnerabilityLevel(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.vulnerabilityLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobsFilter,
                                        "vulnerabilityLevel",
                                        $$v
                                      )
                                    },
                                    expression: "jobsFilter.vulnerabilityLevel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", [
                            _c(
                              "span",
                              [
                                _c("v-autocomplete", {
                                  attrs: {
                                    items: _vm.getVisitTypes,
                                    "item-text": "alterText",
                                    "item-value": "description",
                                    label: "Visit Type",
                                    loading: _vm.getVisitTypeRecordsRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsBySelectedVisitType(
                                        $event
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.visitType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.jobsFilter, "visitType", $$v)
                                    },
                                    expression: "jobsFilter.visitType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs2: "" } }, [
                            _c(
                              "span",
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.getSortingType,
                                    "item-text": "description",
                                    "item-value": "id",
                                    label: "Sort By",
                                    loading: _vm.jobSortingRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsBySortingType($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.sortingType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobsFilter,
                                        "sortingType",
                                        $$v
                                      )
                                    },
                                    expression: "jobsFilter.sortingType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", { attrs: { xs2: "" } }, [
                            _c(
                              "span",
                              [
                                _c("v-select", {
                                  staticClass: "jobDateFilterTypes",
                                  attrs: {
                                    items: _vm.getJobDateFilterTypes,
                                    "item-text": "alterText",
                                    "item-value": "id",
                                    label: "Date Type",
                                    loading: _vm.getJobsByDateFilterRunning,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.filterJobsByDateType($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.jobsFilter.jobDateFilterType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jobsFilter,
                                        "jobDateFilterType",
                                        $$v
                                      )
                                    },
                                    expression: "jobsFilter.jobDateFilterType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-flex",
                            { attrs: { "text-xs-right": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3",
                                  class: _vm.isBtnPrimary
                                    ? "v-btn--flat"
                                    : "primary",
                                  on: { click: _vm.getJobList },
                                },
                                [
                                  _vm._v(" job list "),
                                  _c("v-icon", { staticClass: "ml-1" }, [
                                    _vm._v("view_list"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3 mr-0",
                                  class: _vm.isBtnPrimary
                                    ? "primary"
                                    : "v-btn--flat",
                                  on: { click: _vm.getJobLocations },
                                },
                                [
                                  _vm._v(" Job location "),
                                  _c("v-icon", { staticClass: "ml-1" }, [
                                    _vm._v("place"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { "text-xs-right": "" } },
                        [
                          _c(
                            "span",
                            { staticClass: "contractor-filter" },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.contractors,
                                  "item-text": "companyName",
                                  "item-value": "id",
                                  label: "Search contractor",
                                  loading: _vm.getContractorRunning,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.onContractorChange($event)
                                  },
                                },
                                model: {
                                  value: _vm.jobsFilter.contractorId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.jobsFilter,
                                      "contractorId",
                                      $$v
                                    )
                                  },
                                  expression: "jobsFilter.contractorId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2",
                              class: _vm.isBtnPrimary
                                ? "v-btn--flat"
                                : "primary",
                              on: { click: _vm.getJobList },
                            },
                            [
                              _vm._v(" job list "),
                              _c("v-icon", { staticClass: "ml-1" }, [
                                _vm._v("view_list"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 mr-0",
                              class: _vm.isBtnPrimary
                                ? "primary"
                                : "v-btn--flat",
                              on: { click: _vm.getJobLocations },
                            },
                            [
                              _vm._v(" Job location "),
                              _c("v-icon", { staticClass: "ml-1" }, [
                                _vm._v("place"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "div",
                [
                  !_vm.isBtnPrimary
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "calls-info": "",
                            "undeployed-jobcount": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-0 grey lighten-3 white--text",
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "px-2 pb-0 pt-2 grey--text grey--text text--darken-2",
                                    },
                                    [
                                      _c("b", [
                                        _vm._v("Outstanding Trade Jobs"),
                                      ]),
                                    ]
                                  ),
                                  _vm.jobsTradeList.length > 0
                                    ? _c(
                                        "v-card-text",
                                        { staticClass: "pa-1" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "grey grey--text text--darken-4",
                                                  class:
                                                    _vm.currentIndex === -1
                                                      ? "lighten-1"
                                                      : "lighten-2",
                                                  attrs: { label: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getTradeWiseJobs(
                                                        "All",
                                                        0,
                                                        ""
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(" All   "),
                                                  _c("b", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.jobsTradeList.reduce(
                                                            (a, b) =>
                                                              a + b.jobCount,
                                                            0
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.jobsTradeList,
                                                function (jobTrade, i) {
                                                  return _c(
                                                    "span",
                                                    { key: i },
                                                    [
                                                      _c(
                                                        "v-chip",
                                                        {
                                                          staticClass:
                                                            "grey grey--text text--darken-2",
                                                          class:
                                                            _vm.currentIndex ===
                                                            i
                                                              ? "lighten-1"
                                                              : "lighten-2",
                                                          attrs: { label: "" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getTradeWiseJobs(
                                                                "",
                                                                jobTrade.tradeId,
                                                                i
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                jobTrade.trade
                                                              ) +
                                                              "   "
                                                          ),
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                jobTrade.jobCount
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "taskboard-wrapper",
                  class: _vm.openJobView ? "hide-dashboard" : "show-dashboard",
                },
                [
                  _c(
                    "div",
                    { staticClass: "jobs-taskboard" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "pending-job", attrs: { xs3: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "task-col grey lighten-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-header" },
                                    [
                                      _c("h3", { staticClass: "pb-0" }, [
                                        _vm._v(
                                          "Pending(" +
                                            _vm._s(
                                              _vm.getTotalPendingJobsCount
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                      _c("v-divider", { staticClass: "mt-3" }),
                                    ],
                                    1
                                  ),
                                  _vm.pendingJobList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-body px-3 pb-3 pt-1",
                                        },
                                        _vm._l(
                                          _vm.pendingJobList,
                                          function (pendingJob) {
                                            return _c(
                                              "JobSummaryCard",
                                              {
                                                key: _vm.jobKey(pendingJob),
                                                attrs: {
                                                  job: pendingJob,
                                                  "title-generator": (job) =>
                                                    job.jobId +
                                                    (_vm.multipleVisits(job)
                                                      ? "-" + job.visitNumber
                                                      : ""),
                                                },
                                                on: {
                                                  redirectToJob:
                                                    _vm.onRedirectToJob,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "primary-title",
                                                    attrs: {
                                                      slot: "JobCardDetails",
                                                    },
                                                    slot: "JobCardDetails",
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "date-title",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "card-emergency-icon",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _vm.getEmergencyIcon(
                                                                              pendingJob.forEmergencyTypeId
                                                                            )
                                                                              ? _c(
                                                                                  "img",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "category-icon-img",
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm.getEmergencyIcon(
                                                                                            pendingJob.forEmergencyTypeId
                                                                                          ),
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              : _c(
                                                                                  "img",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "category-icon-img",
                                                                                      attrs:
                                                                                        {
                                                                                          src: "/img/emergency.svg",
                                                                                          alt: "",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEmergencyDescription(
                                                                          pendingJob.forEmergencyTypeId
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.openContractorJobDetailModal(
                                                                        pendingJob.jobId,
                                                                        pendingJob.id,
                                                                        pendingJob.engineerName
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      pendingJob.companyName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(" ( "),
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      attach:
                                                                        "",
                                                                      "offset-y":
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "a",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          href: "javascript:void(0)",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "secondary",
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "call"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  _vm._l(
                                                                    pendingJob.contactNumbers,
                                                                    function (
                                                                      contactNumber,
                                                                      numberIndex
                                                                    ) {
                                                                      return _c(
                                                                        "v-list",
                                                                        {
                                                                          key: numberIndex,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-tile",
                                                                            [
                                                                              _c(
                                                                                "v-list-tile-title",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text cursor-pointer",
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.preventDefault()
                                                                                            return _vm.onCallContractorIconClick(
                                                                                              pendingJob,
                                                                                              numberIndex
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "call"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            contactNumber.phoneNumber
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            contactNumber.label
                                                                                              ? "(" +
                                                                                                  contactNumber.label +
                                                                                                  ")"
                                                                                              : ""
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          numberIndex !==
                                                                          pendingJob
                                                                            .contactNumbers
                                                                            .length -
                                                                            1
                                                                            ? _c(
                                                                                "v-divider"
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _vm._v(" ) "),
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-spacer"),
                                                            pendingJob.isContractorReAttend
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "return-visit",
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: "/img/return-visit.svg",
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Return Visit"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "unavilable-job-msg" },
                                        [
                                          _c("span", [
                                            _vm._v("Data not available"),
                                          ]),
                                        ]
                                      ),
                                  _vm.getTotalPendingJobsCount >=
                                    _vm.defaultLoadMoreRecordCount &&
                                  _vm.pendingJobList.length !==
                                    _vm.getTotalPendingJobsCount &&
                                  _vm.currentIndex === -1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-action text-xs-center grey lighten-2 px-2",
                                        },
                                        [
                                          _vm.pendingJobsLoadMoreStarted
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: true,
                                                  height: "4",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                flat: "",
                                                small: "",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadMore(
                                                    _vm.pendingJobList.length,
                                                    _vm.jobVisitStatus[
                                                      _vm.jobVisitStatus.Pending
                                                    ]
                                                  )
                                                },
                                              },
                                            },
                                            [_c("b", [_vm._v("Load More")])]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "accepted-job", attrs: { xs3: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "task-col grey lighten-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-header" },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "Accepted(" +
                                            _vm._s(
                                              _vm.getTotalAcceptedJobsCount
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                      _c("v-divider", { staticClass: "mt-3" }),
                                    ],
                                    1
                                  ),
                                  _vm.acceptedJobList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-body px-3 pb-3 pt-1",
                                        },
                                        _vm._l(
                                          _vm.acceptedJobList,
                                          function (acceptedJob) {
                                            return _c(
                                              "JobSummaryCard",
                                              {
                                                key: _vm.jobKey(acceptedJob),
                                                attrs: { job: acceptedJob },
                                                on: {
                                                  redirectToJob:
                                                    _vm.onRedirectToJob,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "primary-title",
                                                    attrs: {
                                                      slot: "JobCardDetails",
                                                    },
                                                    slot: "JobCardDetails",
                                                  },
                                                  [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "date-title",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "card-emergency-icon",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _vm.getEmergencyIcon(
                                                                              acceptedJob.forEmergencyTypeId
                                                                            )
                                                                              ? _c(
                                                                                  "img",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "category-icon-img",
                                                                                      attrs:
                                                                                        {
                                                                                          src: _vm.getEmergencyIcon(
                                                                                            acceptedJob.forEmergencyTypeId
                                                                                          ),
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                )
                                                                              : _c(
                                                                                  "img",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "category-icon-img",
                                                                                      attrs:
                                                                                        {
                                                                                          src: "/img/emergency.svg",
                                                                                          alt: "",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  )
                                                                                ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getEmergencyDescription(
                                                                          acceptedJob.forEmergencyTypeId
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.openContractorJobDetailModal(
                                                                        acceptedJob.jobId,
                                                                        acceptedJob.id,
                                                                        acceptedJob.engineerName
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      acceptedJob.companyName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(" ( "),
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      attach:
                                                                        "",
                                                                      "offset-y":
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "a",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          href: "javascript:void(0)",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "secondary",
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "call"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  _vm._l(
                                                                    acceptedJob.contactNumbers,
                                                                    function (
                                                                      contactNumber,
                                                                      numberIndex
                                                                    ) {
                                                                      return _c(
                                                                        "v-list",
                                                                        {
                                                                          key: numberIndex,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-list-tile",
                                                                            [
                                                                              _c(
                                                                                "v-list-tile-title",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text cursor-pointer",
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            $event.preventDefault()
                                                                                            return _vm.onCallContractorIconClick(
                                                                                              acceptedJob,
                                                                                              numberIndex
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "call"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            contactNumber.phoneNumber
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            contactNumber.label
                                                                                              ? "(" +
                                                                                                  contactNumber.label +
                                                                                                  ")"
                                                                                              : ""
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          numberIndex !==
                                                                          acceptedJob
                                                                            .contactNumbers
                                                                            .length -
                                                                            1
                                                                            ? _c(
                                                                                "v-divider"
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                ),
                                                                _vm._v(" ) "),
                                                              ],
                                                              1
                                                            ),
                                                            _c("v-spacer"),
                                                            acceptedJob.isContractorReAttend
                                                              ? _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      top: "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  _vm._g(
                                                                                    {
                                                                                      staticClass:
                                                                                        "return-visit",
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: "/img/return-visit.svg",
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Return Visit"
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                acceptedJob.engineerName ||
                                                acceptedJob.engineerContactNumber
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "bottom-row",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "img-text",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/img/employee.svg",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  acceptedJob.engineerName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.canSeeEngineerPhoneNumber(
                                                          acceptedJob.contractorId
                                                        )
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(" ( "),
                                                                _c(
                                                                  "v-menu",
                                                                  {
                                                                    attrs: {
                                                                      attach:
                                                                        "",
                                                                      "offset-y":
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  "a",
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          href: "javascript:void(0)",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "secondary",
                                                                                            small:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "call"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-list",
                                                                      [
                                                                        _c(
                                                                          "v-list-tile",
                                                                          [
                                                                            _c(
                                                                              "v-list-tile-title",
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "primary--text cursor-pointer",
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          return _vm.onCallIconClick(
                                                                                            acceptedJob
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "call"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          acceptedJob.engineerContactNumber
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" ) "),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "unavilable-job-msg" },
                                        [
                                          _c("span", [
                                            _vm._v("Data not available"),
                                          ]),
                                        ]
                                      ),
                                  _vm.getTotalAcceptedJobsCount >=
                                    _vm.defaultLoadMoreRecordCount &&
                                  _vm.acceptedJobList.length !==
                                    _vm.getTotalAcceptedJobsCount &&
                                  _vm.currentIndex === -1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-action text-xs-center grey lighten-2 px-2",
                                        },
                                        [
                                          _vm.acceptedJobsLoadMoreStarted
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: true,
                                                  height: "4",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                flat: "",
                                                small: "",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadMore(
                                                    _vm.acceptedJobList.length,
                                                    _vm.jobVisitStatus[
                                                      _vm.jobVisitStatus
                                                        .Accepted
                                                    ]
                                                  )
                                                },
                                              },
                                            },
                                            [_c("b", [_vm._v("Load More")])]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "progress-job", attrs: { xs3: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "task-col grey lighten-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-header" },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "In progress(" +
                                            _vm._s(
                                              _vm.getTotalInProgressJobsCount
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                      _c("v-divider", { staticClass: "mt-3" }),
                                    ],
                                    1
                                  ),
                                  _vm.inProgressJobList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-body px-3 pb-3 pt-1",
                                        },
                                        _vm._l(
                                          _vm.inProgressJobList,
                                          function (inProgressJob) {
                                            return _c(
                                              "JobSummaryCard",
                                              {
                                                key: _vm.jobKey(inProgressJob),
                                                attrs: { job: inProgressJob },
                                                on: {
                                                  redirectToJob:
                                                    _vm.onRedirectToJob,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      slot: "JobCardDetails",
                                                    },
                                                    slot: "JobCardDetails",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary-title",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "date-title",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-emergency-icon",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _vm.getEmergencyIcon(
                                                                                  inProgressJob.forEmergencyTypeId
                                                                                )
                                                                                  ? _c(
                                                                                      "img",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "category-icon-img",
                                                                                          attrs:
                                                                                            {
                                                                                              src: _vm.getEmergencyIcon(
                                                                                                inProgressJob.forEmergencyTypeId
                                                                                              ),
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  : _c(
                                                                                      "img",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "category-icon-img",
                                                                                          attrs:
                                                                                            {
                                                                                              src: "/img/emergency.svg",
                                                                                              alt: "",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getEmergencyDescription(
                                                                              inProgressJob.forEmergencyTypeId
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.openContractorJobDetailModal(
                                                                        inProgressJob.jobId,
                                                                        inProgressJob.id,
                                                                        inProgressJob.engineerName
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      inProgressJob.companyName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c("v-spacer"),
                                                        inProgressJob.isContractorReAttend
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "return-visit",
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/img/return-visit.svg",
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Return Visit"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    inProgressJob.engineerName ||
                                                    inProgressJob.engineerContactNumber
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bottom-row",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "img-text",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: "/img/employee.svg",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      inProgressJob.engineerName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.canSeeEngineerPhoneNumber(
                                                              inProgressJob.contractorId
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " ( "
                                                                    ),
                                                                    _c(
                                                                      "v-menu",
                                                                      {
                                                                        attrs: {
                                                                          attach:
                                                                            "",
                                                                          "offset-y":
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "a",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              href: "javascript:void(0)",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "secondary",
                                                                                                small:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "call"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list",
                                                                          [
                                                                            _c(
                                                                              "v-list-tile",
                                                                              [
                                                                                _c(
                                                                                  "v-list-tile-title",
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "primary--text cursor-pointer",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.preventDefault()
                                                                                              return _vm.onCallIconClick(
                                                                                                inProgressJob
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "call"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              inProgressJob.engineerContactNumber
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(
                                                                      " ) "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    inProgressJob.visitStartedAt
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bottom-date",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "date_range"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Job Start:"
                                                              ),
                                                            ]),
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getETADateFormat(
                                                                      inProgressJob.visitStartedAt
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "unavilable-job-msg" },
                                        [
                                          _c("span", [
                                            _vm._v("Data not available"),
                                          ]),
                                        ]
                                      ),
                                  _vm.getTotalInProgressJobsCount >=
                                    _vm.defaultLoadMoreRecordCount &&
                                  _vm.inProgressJobList.length !==
                                    _vm.getTotalInProgressJobsCount &&
                                  _vm.currentIndex === -1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-action text-xs-center grey lighten-2 px-2",
                                        },
                                        [
                                          _vm.inProgressJobsLoadMoreStarted
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  indeterminate: true,
                                                  height: "4",
                                                },
                                              })
                                            : _vm._e(),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                flat: "",
                                                small: "",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadMore(
                                                    _vm.inProgressJobList
                                                      .length,
                                                    _vm.jobVisitStatus[
                                                      _vm.jobVisitStatus
                                                        .InProgress
                                                    ]
                                                  )
                                                },
                                              },
                                            },
                                            [_c("b", [_vm._v("Load More")])]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "completed-job",
                              attrs: { xs3: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "task-col grey lighten-3" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-header" },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "Completed(" +
                                            _vm._s(
                                              _vm.getTotalCompletedJobsCount
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                      _c("v-divider", { staticClass: "mt-3" }),
                                    ],
                                    1
                                  ),
                                  _vm.completedJobList.length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "task-body px-3 pb-3 pt-1",
                                        },
                                        _vm._l(
                                          _vm.completedJobList,
                                          function (completedJobs) {
                                            return _c(
                                              "JobSummaryCard",
                                              {
                                                key: _vm.jobKey(completedJobs),
                                                attrs: { job: completedJobs },
                                                on: {
                                                  redirectToJob:
                                                    _vm.onRedirectToJob,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: {
                                                      slot: "JobCardDetails",
                                                    },
                                                    slot: "JobCardDetails",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "primary-title",
                                                      },
                                                      [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "date-title",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "card-emergency-icon",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _vm.getEmergencyIcon(
                                                                                  completedJobs.forEmergencyTypeId
                                                                                )
                                                                                  ? _c(
                                                                                      "img",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "category-icon-img",
                                                                                          attrs:
                                                                                            {
                                                                                              src: _vm.getEmergencyIcon(
                                                                                                completedJobs.forEmergencyTypeId
                                                                                              ),
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    )
                                                                                  : _c(
                                                                                      "img",
                                                                                      _vm._g(
                                                                                        {
                                                                                          staticClass:
                                                                                            "category-icon-img",
                                                                                          attrs:
                                                                                            {
                                                                                              src: "/img/emergency.svg",
                                                                                              alt: "",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      )
                                                                                    ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getEmergencyDescription(
                                                                              completedJobs.forEmergencyTypeId
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href: "javascript:void(0)",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                      return _vm.openContractorJobDetailModal(
                                                                        completedJobs.jobId,
                                                                        completedJobs.id,
                                                                        completedJobs.engineerName
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      completedJobs.companyName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c("v-spacer"),
                                                        completedJobs.isContractorReAttend
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  top: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "span",
                                                                              _vm._g(
                                                                                {
                                                                                  staticClass:
                                                                                    "return-visit",
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/img/return-visit.svg",
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Return Visit"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    completedJobs.engineerName ||
                                                    completedJobs.engineerContactNumber
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bottom-row",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "img-text",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: "/img/employee.svg",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      completedJobs.engineerName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm.canSeeEngineerPhoneNumber(
                                                              completedJobs.contractorId
                                                            )
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " ( "
                                                                    ),
                                                                    _c(
                                                                      "v-menu",
                                                                      {
                                                                        attrs: {
                                                                          attach:
                                                                            "",
                                                                          "offset-y":
                                                                            "",
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function ({
                                                                                  on,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "a",
                                                                                      _vm._g(
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              href: "javascript:void(0)",
                                                                                            },
                                                                                        },
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "secondary",
                                                                                                small:
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "call"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-list",
                                                                          [
                                                                            _c(
                                                                              "v-list-tile",
                                                                              [
                                                                                _c(
                                                                                  "v-list-tile-title",
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "primary--text cursor-pointer",
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              $event.preventDefault()
                                                                                              return _vm.onCallIconClick(
                                                                                                completedJobs
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "call"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              completedJobs.engineerContactNumber
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(
                                                                      " ) "
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    completedJobs.visitCompletedAt
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "bottom-date",
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "date_range"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Visit Completed:"
                                                              ),
                                                            ]),
                                                            _c("b", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getETADateFormat(
                                                                      completedJobs.visitCompletedAt
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      )
                                    : _c(
                                        "div",
                                        { staticClass: "unavilable-job-msg" },
                                        [
                                          _c("span", [
                                            _vm._v("Data not available"),
                                          ]),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "jobs-map mt-2" },
                    [
                      _c("Map", {
                        staticStyle: { height: "calc(100vh - 185px)" },
                        attrs: {
                          "map-id": new Date().getTime().toString(),
                          "source-latitude": 51.509865,
                          "source-longitude": -0.118092,
                          "zoom-level": 8,
                          "show-marker": false,
                          "outstanding-jobs-jobs-location":
                            _vm.outstandingJobsForLocation,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.contractorJobDetailDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "1000",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.contractorJobDetailDialog,
                        callback: function ($$v) {
                          _vm.contractorJobDetailDialog = $$v
                        },
                        expression: "contractorJobDetailDialog",
                      },
                    },
                    [
                      _c("ContractorJobDetail", {
                        attrs: {
                          "parent-component": "OutstandingJobDashboard",
                          "contractor-job-detail-item":
                            _vm.contractorJobDetailModel,
                        },
                        on: {
                          contractorJobDetailDialogClose:
                            _vm.contractorJobDetailDialogClose,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.ready,
                  expression: "!ready",
                },
              ],
            },
            [
              _c("v-progress-circular", {
                staticClass: "loading-spinner",
                attrs: {
                  width: 2,
                  size: 50,
                  indeterminate: "",
                  color: "primary",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _c("PartialJobView", {
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }