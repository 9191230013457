var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pictureUploadPreview inner-content" },
    [
      _vm.claimPictures.length > 0
        ? _c(
            "v-card",
            {
              staticClass:
                "pt-0 px-0 pb-2 up-card mb-3 elevation-0 grey lighten-5",
            },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _vm.uploadedBy === "Customer"
                        ? _c("h3", { staticClass: "pt-3 pb-1 pl-2 mb-2" }, [
                            _vm._v("Uploaded Images By Client"),
                          ])
                        : _vm.uploadedBy === "EngineerBeforeJob"
                        ? _c("h3", { staticClass: "pt-3 pb-1 pl-2 mb-2" }, [
                            _vm._v(" Uploaded Images By Engineer Before Job "),
                          ])
                        : _vm.uploadedBy === "EngineerAfterJob"
                        ? _c("h3", { staticClass: "pt-3 pb-1 pl-2 mb-2" }, [
                            _vm._v(" Uploaded Images By Engineer After Job "),
                          ])
                        : _vm.uploadedBy === "PolicyHolderMissing"
                        ? _c("h3", { staticClass: "pt-3 pb-1 pl-2 mb-2" }, [
                            _vm._v(
                              " Uploaded Images By Engineer For Customer Not Present "
                            ),
                          ])
                        : _vm.uploadedBy === "DuringJobPhotos"
                        ? _c("h3", { staticClass: "pt-3 pb-1 pl-2 mb-2" }, [
                            _vm._v("Uploaded Images By Engineer During Job "),
                          ])
                        : _vm._e(),
                      _c(
                        "v-container",
                        {
                          class: _vm.uploadedBy === "" ? "pa-0" : "",
                          attrs: { fluid: "", "grid-list-lg": "", "pa-2": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            _vm._l(_vm.claimPictures, function (item, i) {
                              return _c(
                                "v-flex",
                                {
                                  key: i,
                                  staticClass: "img-block pa1 mb-2",
                                  attrs: { lg4: "", md6: "" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "up-images pa-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleCarousel(
                                            i,
                                            item.uploadedBy,
                                            item.fileURL
                                          )
                                        },
                                      },
                                    },
                                    [
                                      item.note
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "hover-content-section uploadimage-notes",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "notes elevation-1",
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                    color: "white",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "primary--text md-icon",
                                                    },
                                                    [_vm._v("notes")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "yellow lighten-4 hover-item text-xs-left px-2",
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "my-2" },
                                                    [_vm._v("Notes")]
                                                  ),
                                                  _c("p", [
                                                    _vm._v(_vm._s(item.note)),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("img", {
                                        staticClass: "image",
                                        attrs: {
                                          src: item.fileURL,
                                          alt: item.fileName,
                                          width: "100%",
                                          height: "100%",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("VueEasyLightbox", {
        attrs: {
          visible: _vm.showPictureUploadDialog,
          imgs: _vm.blobOfImages,
          index: _vm.pictureIndex,
        },
        on: { hide: _vm.hidePictureUploadDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }