var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobs
    ? _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Phone Call Assign")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close(false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-layout",
            { staticClass: "job-search px-3", attrs: { wrap: "" } },
            [
              _vm.isViaTwillio
                ? _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        "mt-2": "",
                        grey: "",
                        "pa-2": "",
                        "lighten-3": "",
                      },
                    },
                    [
                      _c(
                        "h4",
                        [
                          _c("v-icon", [_vm._v("info")]),
                          _c("span", { staticClass: "popup-close-msg" }, [
                            _vm._v(
                              " This window will close automatically after "
                            ),
                            _c(
                              "b",
                              { staticClass: "secondary--text" },
                              [
                                _c(
                                  "v-icon",
                                  { staticClass: "md-icon secondary--text" },
                                  [_vm._v("access_time")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.popupCounter > 9
                                        ? _vm.popupCounter + " seconds"
                                        : _vm.popupCounter + " second"
                                    ) +
                                    ". "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("UniversalSearch", {
                    staticClass: "search-job-item",
                    attrs: {
                      "is-clear-search": _vm.isClearSearch,
                      "parent-component": "PhoneCallJobsAssign",
                    },
                    on: {
                      "update:isClearSearch": function ($event) {
                        _vm.isClearSearch = $event
                      },
                      "update:is-clear-search": function ($event) {
                        _vm.isClearSearch = $event
                      },
                      searchJobResult: _vm.bindJobResult,
                      closeSearchJobResult: _vm.clearJobResult,
                    },
                  }),
                ],
                1
              ),
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "h3",
                  { staticClass: "mb-2 grey--text text--darken-2 subheading" },
                  [_vm._v("Suggestion List")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "scroll-content-dialog px-3 pt-0 pb-4" },
            [
              _c("v-progress-circular", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.getIsDataLoaded,
                    expression: "!getIsDataLoaded",
                  },
                ],
                style: _vm.getLoaderStyle(50),
                attrs: {
                  width: 2,
                  size: 50,
                  indeterminate: "",
                  color: "primary",
                },
              }),
              _c(
                "v-layout",
                { attrs: { wrap: "", "fill-height": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "suggestion-list grey lighten-3",
                      attrs: { xs12: "" },
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.getIsDataLoaded &&
                                _vm.suggestedJobList.length < 1,
                              expression:
                                "getIsDataLoaded && suggestedJobList.length < 1",
                            },
                          ],
                          staticClass: "record-msg fill-height",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "subheading grey--text ma-auto" },
                            [_vm._v("Record is not Found")]
                          ),
                        ]
                      ),
                      _vm._l(_vm.suggestedJobList, function (job, index) {
                        return _c(
                          "v-list",
                          {
                            key: index,
                            staticClass: "grey lighten-3",
                            attrs: { "three-line": "" },
                          },
                          [
                            [
                              _c(
                                "v-list-tile",
                                { attrs: { avatar: "", ripple: "" } },
                                [
                                  _c(
                                    "v-list-tile-content",
                                    [
                                      _c(
                                        "v-list-tile-title",
                                        { staticClass: "body-1" },
                                        [
                                          _c("b", [
                                            _vm._v("Job ID: " + _vm._s(job.id)),
                                          ]),
                                        ]
                                      ),
                                      job.name
                                        ? _c(
                                            "v-list-tile-sub-title",
                                            { staticClass: "text--primary" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("person")]
                                              ),
                                              _vm._v(
                                                " " + _vm._s(job.name) + " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      job.phoneNumber
                                        ? _c(
                                            "v-list-tile-sub-title",
                                            { staticClass: "text--primary" },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("call")]
                                              ),
                                              _c("b", [
                                                _vm._v(_vm._s(job.phoneNumber)),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      job.address || job.postCode
                                        ? _c(
                                            "v-list-tile-sub-title",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("place")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(job.address) +
                                                  " " +
                                                  _vm._s(
                                                    job.address
                                                      ? "," + job.postCode
                                                      : job.postCode
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-tile-action",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            dark: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.assignJobClick(job)
                                            },
                                          },
                                        },
                                        [_vm._v("Assign")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider"),
                            ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isViaTwillio
            ? _c(
                "v-card-actions",
                { staticClass: "py-2 px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "text-xs-right",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.markCallAsNoise },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "md-icon", attrs: { color: "white" } },
                        [_vm._v("phonelink_erase")]
                      ),
                      _vm._v("   Mark as Noise "),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }