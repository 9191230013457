var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "consumerrate" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-0" },
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "py-0 mb-2",
                      attrs: { "primary-title": "" },
                    },
                    [
                      _c("h3", { staticClass: "mb-0" }, [
                        _vm._v("Phone Numbers"),
                      ]),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "search",
                          label: "Search",
                          "single-line": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "nudge-left": "30px" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "mx-0 add-action-btn",
                                        attrs: { icon: "" },
                                        on: {
                                          click:
                                            _vm.onCreateCustomerPhoneNumber,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "grey" } },
                                        [_vm._v("add")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_c("span", [_vm._v("Add Phone Number")])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pt-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "px-0": "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass: "gridView",
                                    attrs: {
                                      headers: _vm.headers,
                                      items: _vm.insurerPhoneNumberList,
                                      loading: _vm.isLoading,
                                      search: _vm.search,
                                      "item-key": "name",
                                      pagination: _vm.pagination,
                                    },
                                    on: {
                                      "update:pagination": function ($event) {
                                        _vm.pagination = $event
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.label)
                                                ),
                                              ]),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.item.phoneNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mx-0",
                                                                      attrs: {
                                                                        icon: "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.editPhoneNumber(
                                                                              props.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "edit"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Edit"),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "mx-0 mt-2 ml-2",
                                                                      attrs: {
                                                                        icon: "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.deletePhoneNumberConfirmation(
                                                                              props
                                                                                .item
                                                                                .phoneNumber
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Delete"),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showPhoneNumberDialog
                ? _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "650",
                        persistent: "",
                        "content-class": "v-dialog--scrollable",
                      },
                      model: {
                        value: _vm.showPhoneNumberDialog,
                        callback: function ($$v) {
                          _vm.showPhoneNumberDialog = $$v
                        },
                        expression: "showPhoneNumberDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [_vm._v("Phone Number")]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showPhoneNumberDialog = false
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-3 scroll-content-dialog" },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pr-2": "" } },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Label",
                                          required: "",
                                          "data-vv-scope": "refCustomerInput",
                                          "data-vv-name": "Label",
                                          "error-messages":
                                            _vm.errors.collect("Label"),
                                        },
                                        model: {
                                          value:
                                            _vm.insurerPhoneNumberToAdd.label,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.insurerPhoneNumberToAdd,
                                              "label",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "insurerPhoneNumberToAdd.label",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pl-2": "" } },
                                    [
                                      _c("PhoneNumber", {
                                        ref: "refCustomerPhoneNumber",
                                        staticClass:
                                          "internal-phone-number pl-0",
                                        attrs: {
                                          "passed-phone-number":
                                            _vm.insurerPhoneNumberToAdd
                                              .phoneNumber,
                                          "is-validation-required": true,
                                        },
                                        on: {
                                          phoneNumberUpdated:
                                            _vm.phoneNumberUpdated,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.showPhoneNumberDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.onSubmitLoading,
                                    loading: _vm.onSubmitLoading,
                                  },
                                  on: {
                                    click: _vm.submitCustomerPhoneNumberDetail,
                                  },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }