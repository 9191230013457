var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _vm.item.clientTitle
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Title: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.clientTitle))]),
          ])
        : _vm._e(),
      _vm.item.clientForename
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("First Name: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.clientForename))]),
          ])
        : _vm._e(),
      _vm.item.clientLastName
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Last Name: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.clientLastName))]),
          ])
        : _vm._e(),
      _vm.item.secondaryClientTitle
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Secondary Policy Holder Title: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientTitle))]),
          ])
        : _vm._e(),
      _vm.item.secondaryClientForename
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Secondary Policy Holder First Name: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientForename))]),
          ])
        : _vm._e(),
      _vm.item.secondaryClientLastName
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Secondary Policy Holder Last Name: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientLastName))]),
          ])
        : _vm._e(),
      _vm.item.addressLine1
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Address Line1: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.addressLine1))]),
          ])
        : _vm._e(),
      _vm.item.addressLine2
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Address Line2: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.addressLine2))]),
          ])
        : _vm._e(),
      _vm.item.addressLine3
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Address Line3: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.addressLine3))]),
          ])
        : _vm._e(),
      _vm.item.addressLine4
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Address Line4: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.addressLine4))]),
          ])
        : _vm._e(),
      _vm.item.postCode
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Postcode: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.postCode))]),
          ])
        : _vm._e(),
      _vm.item.mobilePhone
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Mobile Phone: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.mobilePhone))]),
          ])
        : _vm._e(),
      _vm.item.email
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Email: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.email))]),
          ])
        : _vm._e(),
      _vm.item.contactPreference
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Contact Preference: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.contactPreference))]),
          ])
        : _vm._e(),
      _vm.item.reasonForChoosingCallOption
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [
              _vm._v("Reason For selecting Call As a Preference: "),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.item.reasonForChoosingCallOption))]),
          ])
        : _vm._e(),
      _vm.item.policyNumber
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Policy Number: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.policyNumber))]),
          ])
        : _vm._e(),
      _vm.item.policyNumberNotPresentReason
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Policy Number Not Present Reason: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.policyNumberNotPresentReason))]),
          ])
        : _vm._e(),
      _vm.item.customerRef
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Customer Reference: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.customerRef))]),
          ])
        : _vm._e(),
      _vm.item.policyScheme
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Policy Scheme: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.policyScheme))]),
          ])
        : _vm._e(),
      _vm.item.insurer
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Insurer: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.insurer))]),
          ])
        : _vm._e(),
      _vm.item.insuranceValue
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Insurance Value: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.insuranceValue))]),
          ])
        : _vm._e(),
      _vm.item.policyScheduleId
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("PolicyScheduleId: ")]),
            _c("span", [_vm._v(_vm._s(_vm.item.policyScheduleId))]),
          ])
        : _vm._e(),
      _vm.item.emergencies && _vm.item.emergencies.length > 0
        ? _vm._l(_vm.item.emergencies, function (emergency, index) {
            return _c("div", { key: index }, [
              emergency.emergencyTypeDesc
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Emergency: ")]),
                    _c("span", [_vm._v(_vm._s(emergency.emergencyTypeDesc))]),
                  ])
                : _vm._e(),
              emergency.emergencyDetailDesc
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Emergency Detail: ")]),
                    _c("span", [_vm._v(_vm._s(emergency.emergencyDetailDesc))]),
                  ])
                : _vm._e(),
              emergency.tradeDesc
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Trade: ")]),
                    _c("span", [_vm._v(_vm._s(emergency.tradeDesc))]),
                  ])
                : _vm._e(),
              emergency.note
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Note: ")]),
                    _c("span", [_vm._v(_vm._s(emergency.note))]),
                  ])
                : _vm._e(),
              emergency.firstNoticedAtUtc
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("First Noticed: ")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getFormattedDate(emergency.firstNoticedAtUtc)
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          })
        : _vm._e(),
      _vm.item.reasonForCreatingDuplicateJob
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Reason For Creating Duplicate Job: ")]),
            _c("span", [
              _vm._v(_vm._s(_vm.item.reasonForCreatingDuplicateJob)),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Job Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }