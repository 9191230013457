var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "mt-2" },
            [
              _c(
                "v-container",
                {
                  staticClass: "text-right contractor-search",
                  attrs: {
                    fluid: "",
                    "pr-2": "",
                    "pl-0": "",
                    "pt-0": "",
                    "pb-2": "",
                  },
                },
                [
                  _c("v-autocomplete", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Search contractor",
                      required: "",
                      loading: _vm.getContractorEngineersLoading,
                      items: _vm.contractors,
                      "item-text": "companyName",
                      "item-value": "id",
                      "data-vv-scope": "frmContractor",
                      "data-vv-name": "Search contractor",
                      "error-messages": _vm.errors.collect("Search contractor"),
                      "data-vv-delay": _vm.validationDelay,
                      disabled: _vm.getContractorEngineersLoading,
                    },
                    model: {
                      value: _vm.selectedContractorId,
                      callback: function ($$v) {
                        _vm.selectedContractorId = $$v
                      },
                      expression: "selectedContractorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-container",
                {
                  attrs: {
                    fluid: "",
                    "pr-2": "",
                    "pl-0": "",
                    "pt-0": "",
                    "mt-5": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c("ContractorFinancialSummary", {
                            attrs: {
                              "contractor-id": _vm.selectedContractorId,
                              "is-visit-summary-data-loading":
                                _vm.getContractorEngineersLoading,
                              engineers: _vm.engineers,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }