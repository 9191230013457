var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.pitData
    ? _c(
        "v-card",
        { staticClass: "mb-4 pit-card elevation-2" },
        [
          _c(
            "v-card-title",
            [
              _vm.priceIncluded
                ? _c(
                    "h3",
                    { class: _vm.pitData.isDeleted ? "deleted-item" : "" },
                    [_vm._v(" " + _vm._s(_vm.pitTitle) + " ")]
                  )
                : _c("h3", [
                    _vm._v(" " + _vm._s(_vm.pitTitle) + " - "),
                    _c("span", { staticClass: "primary--text" }, [
                      _vm._v("£" + _vm._s(_vm.pitData.basePrice)),
                    ]),
                  ]),
              _c("v-spacer"),
              !_vm.disabled && _vm.allowedDelete
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-delete",
                      attrs: { disabled: !_vm.isPitIdEditable, icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-pit")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "px-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "px-3", attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: `required|unique:${_vm.pitIds}`,
                            expression: "`required|unique:${pitIds}`",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          id: "pitId",
                          label: "Trial Pit ID",
                          "error-messages":
                            _vm.$validator.errors.collect("Trial Pit ID"),
                          "data-vv-name": "Trial Pit ID",
                          disabled: _vm.disabled || !_vm.isPitIdEditable,
                          required: "",
                        },
                        model: {
                          value: _vm.pitData.pitId,
                          callback: function ($$v) {
                            _vm.$set(_vm.pitData, "pitId", $$v)
                          },
                          expression: "pitData.pitId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "px-3", attrs: { xs6: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: `required|between:0,${_vm.maxDepth}`,
                            expression: "`required|between:0,${maxDepth}`",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          id: "depth",
                          label: "Borehole Depth",
                          "error-messages":
                            _vm.$validator.errors.collect("Depth"),
                          "data-vv-name": "Depth",
                          type: "number",
                          hint: "meters",
                          disabled: _vm.disabled,
                          required: "",
                        },
                        model: {
                          value: _vm.pitData.boreDepth,
                          callback: function ($$v) {
                            _vm.$set(_vm.pitData, "boreDepth", _vm._n($$v))
                          },
                          expression: "pitData.boreDepth",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pa-3 test-block" },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h4", { staticClass: "pb-2" }, [_vm._v("Tests:")]),
                  _vm._l(_vm.availableTests, function (test) {
                    return _c(
                      "v-flex",
                      { key: test.name },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "pb-2": "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs4: "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", xs12: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "" } },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-switch",
                                                        _vm._g(
                                                          {
                                                            ref: "pitTest",
                                                            refInFor: true,
                                                            staticClass:
                                                              "pt-1 switch-toggle",
                                                            class:
                                                              test.isDeleted
                                                                ? "deleted-item"
                                                                : "",
                                                            attrs: {
                                                              "input-value":
                                                                _vm.findTestOnPit(
                                                                  test
                                                                ),
                                                              color: "primary",
                                                              label:
                                                                _vm.testIncluded(
                                                                  test
                                                                )
                                                                  ? test.name
                                                                  : `${test.name}`,
                                                              disabled:
                                                                _vm.disabled,
                                                            },
                                                            on: {
                                                              change: (t) =>
                                                                _vm.toggleTest(
                                                                  test,
                                                                  t
                                                                ),
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(test.description)),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        staticClass: "priceLabel",
                                        attrs: {
                                          xs3: "",
                                          "pl-4": "",
                                          "mr-1": "",
                                          "mt-2": "",
                                        },
                                      },
                                      [
                                        _c("UpdateProductPriceDialog", {
                                          ref: "updateProductPriceDialogRef",
                                          refInFor: true,
                                          attrs: {
                                            "price-label": `£${test.basePrice}`,
                                            "is-price-updated":
                                              test.isPriceUpdated,
                                            "product-price":
                                              _vm.productBasePrice,
                                            "is-disabled":
                                              _vm.disabled ||
                                              !_vm.findTestOnPit(test),
                                          },
                                          on: {
                                            "update:isPriceUpdated": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                test,
                                                "isPriceUpdated",
                                                $event
                                              )
                                            },
                                            "update:is-price-updated":
                                              function ($event) {
                                                return _vm.$set(
                                                  test,
                                                  "isPriceUpdated",
                                                  $event
                                                )
                                              },
                                            "update:productPrice": function (
                                              $event
                                            ) {
                                              _vm.productBasePrice = $event
                                            },
                                            "update:product-price": function (
                                              $event
                                            ) {
                                              _vm.productBasePrice = $event
                                            },
                                            editProductPrice: () => {
                                              _vm.openEditProductPriceDialog(
                                                test
                                              )
                                            },
                                            productPriceUpdated:
                                              _vm.onProductPriceUpdated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.findTestOnPit(test),
                                    expression: "findTestOnPit(test)",
                                  },
                                ],
                                ref: test.name + ":data",
                                refInFor: true,
                                attrs: { xs8: "" },
                              },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "", xs12: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs4: "", "px-3": "" } },
                                      [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: `required|min_value:0`,
                                              expression:
                                                "`required|min_value:0`",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            label: "Quantity",
                                            "error-messages":
                                              _vm.$validator.errors.collect(
                                                test.name
                                              ),
                                            "data-vv-name": test.name,
                                            type: "number",
                                            hint: test.unit,
                                            disabled: _vm.disabled,
                                            required: "",
                                          },
                                          model: {
                                            value: test.quantity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                test,
                                                "quantity",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression: "test.quantity",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-spacer"),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "", "mr-0": "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Test Notes",
                                            disabled: _vm.disabled,
                                          },
                                          model: {
                                            value: test.notes,
                                            callback: function ($$v) {
                                              _vm.$set(test, "notes", $$v)
                                            },
                                            expression: "test.notes",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }