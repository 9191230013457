var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.getTrialPitDetail.length > 0
        ? _c("v-flex", { staticClass: "mb-1", attrs: { xs12: "" } }, [
            _c("b", [
              _vm._v(" Trial Pit "),
              _vm.getVerificationData && _vm.getVerificationData("Pit")
                ? _c("span", { staticClass: "green--text test-verify" }, [
                    _vm._v("(Verified)"),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.getCCTVControlLogDetail
        ? _c("v-flex", { staticClass: "mb-1", attrs: { xs12: "" } }, [
            _c("b", [
              _vm._v(" CCTV Logs "),
              _vm.job &&
              _vm.job.jobType === "US" &&
              _vm.getCCTVControlLogDetail.isCCTVDrainageVerified
                ? _c("span", { staticClass: "green--text" }, [
                    _vm._v(" (Verified) "),
                  ])
                : _vm.getVerificationData && _vm.getVerificationData("CCTV")
                ? _c("span", { staticClass: "green--text" }, [
                    _vm._v("(Verified)"),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.getWaterMainsTestDetail
        ? _c("v-flex", { staticClass: "mb-1", attrs: { xs12: "" } }, [
            _c("b", [
              _vm._v(" Water mains test "),
              _vm.getVerificationData && _vm.getVerificationData("WaterMains")
                ? _c("span", { staticClass: "green--text" }, [
                    _vm._v("(Verified)"),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.getDatumControlLogDetail.length > 0
        ? _c("v-flex", { staticClass: "mb-1", attrs: { xs12: "" } }, [
            _c("b", [
              _vm._v(" Datums "),
              _vm.getVerificationData && _vm.getVerificationData("Datum")
                ? _c("span", { staticClass: "green--text" }, [
                    _vm._v("(Verified)"),
                  ])
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }