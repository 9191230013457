var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pictureUploadPreview inner-content" },
    [
      _c("PictureUpload", {
        attrs: {
          "job-id": _vm.jobId,
          "uploaded-by": "Customer",
          "picture-list": _vm.clientPictureList,
        },
      }),
      _c("PictureUpload", {
        attrs: {
          "job-id": _vm.jobId,
          "uploaded-by": "PolicyHolderMissing",
          "picture-list": _vm.policyHolderNotPresentPicture,
        },
      }),
      _c("PictureUpload", {
        attrs: {
          "job-id": _vm.jobId,
          "uploaded-by": "EngineerBeforeJob",
          "picture-list": _vm.engineerBeforeJobPictureList,
        },
      }),
      _c("PictureUpload", {
        attrs: {
          "job-id": _vm.jobId,
          "uploaded-by": "DuringJobPhotos",
          "picture-list": _vm.engineerDuringJobPictureList,
        },
      }),
      _c("PictureUpload", {
        attrs: {
          "job-id": _vm.jobId,
          "uploaded-by": "EngineerAfterJob",
          "picture-list": _vm.engineerAfterJobPictureList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }