var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "elevation-0 mb-2" },
    [
      _vm.fromContractorDetailsView
        ? _c(
            "v-card-title",
            [_c("h4", [_vm._v("Gas & Safety Answers")]), _c("v-spacer")],
            1
          )
        : _vm._e(),
      _vm.fromContractorDetailsView ? _c("v-divider") : _vm._e(),
      _vm.fromContractorDetailsView &&
      _vm.showGasSafetyReportDialog &&
      _vm.gasSafetyQuestionReportList.length > 0
        ? _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-md": "", "pa-0": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    _vm._l(
                      _vm.gasSafetyQuestionReportList,
                      function (list, index) {
                        return _c(
                          "v-expansion-panel",
                          { key: index },
                          [
                            list.values.length > 0
                              ? _c(
                                  "v-expansion-panel-content",
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "header" },
                                        slot: "header",
                                      },
                                      [_c("h4", [_vm._v(_vm._s(list.header))])]
                                    ),
                                    _c(
                                      "v-card",
                                      _vm._l(
                                        list.values,
                                        function (onSiteQA, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "pt-2 pb-2 pl-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "pt-2" },
                                                [
                                                  _c("b", [_vm._v("Q:")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        onSiteQA.question
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c("div", [
                                                _c("b", [_vm._v("A:")]),
                                                onSiteQA.answer
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(onSiteQA.answer)
                                                      ),
                                                    ])
                                                  : _c("span", [_vm._v("No")]),
                                              ]),
                                              onSiteQA.description
                                                ? _c("div", [
                                                    _c("b", [_vm._v("D:")]),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          onSiteQA.description
                                                        )
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                              onSiteQA.imageId &&
                                              onSiteQA.imageUrl
                                                ? _c("div", [
                                                    _c("img", {
                                                      staticClass:
                                                        "profile-img",
                                                      staticStyle: {
                                                        width: "auto",
                                                        height: "auto",
                                                        "max-width": "500px",
                                                        "max-height": "500px",
                                                      },
                                                      attrs: {
                                                        src: onSiteQA.imageUrl,
                                                      },
                                                    }),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "text-xs-right pt-3 pb-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-generatePdf",
                      attrs: {
                        color: "primary",
                        disabled: _vm.generatePDFInProcess,
                        loading: _vm.generatePDFInProcess,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.generateReport()
                        },
                      },
                    },
                    [_vm._v(" Generate PDF ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.fromContractorDetailsView &&
          !_vm.showGasSafetyReportDialog &&
          !(_vm.gasSafetyQuestionReportList.length > 0)
        ? _c("v-card-text", { staticClass: "text-xs-center" }, [
            _vm._v(" No Gas and Safety records found. "),
          ])
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "pa-0",
          class:
            _vm.fromContractorDetailsView || !_vm.showGasSafetyReportDialog
              ? "hide"
              : "",
        },
        [
          _c("div", { attrs: { id: "gasSafety" } }, [
            _c("h3", { staticClass: "mb-2 primary--text" }, [
              _vm._v("Job Summary"),
            ]),
            _c("div", { staticClass: "grey lighten-4 pa-2" }, [
              _vm.jobId
                ? _c("div", { staticClass: "pb-1 mb-2" }, [
                    _c("b", [_vm._v("Job ID :")]),
                    _vm._v(" " + _vm._s(_vm.jobId) + " "),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("Emergency Type:")]),
                _vm.emergency && _vm.emergency.typeDescription
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.emergency.typeDescription) + " "),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("Emergency Detail:")]),
                _vm.emergency && _vm.emergency.detailDescription
                  ? _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.emergency.detailDescription) + " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("Contractor Name:")]),
                _vm.getEngineerVisitDetail &&
                _vm.getEngineerVisitDetail.companyName
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getEngineerVisitDetail.companyName) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("Engineer Name:")]),
                _vm.engineerName
                  ? _c("span", [_vm._v(_vm._s(_vm.engineerName))])
                  : _c("span", [_vm._v("---")]),
              ]),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("H&S Completion Date:")]),
                _vm.getVisitHealthAndSafetyDetails &&
                _vm.getVisitHealthAndSafetyDetails.createdAt
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getFormattedDate(
                              _vm.getVisitHealthAndSafetyDetails.createdAt
                            )
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]),
              _c("div", { staticClass: "pb-1 mb-2" }, [
                _c("b", [_vm._v("Job Address:")]),
                _vm.getVisitHealthAndSafetyDetails &&
                _vm.getVisitHealthAndSafetyDetails.jobAddress
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getVisitHealthAndSafetyDetails.jobAddress
                          ) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("---")]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "grey lighten-4 pa-2" },
              [
                _c("h3", { staticClass: "mb-2 primary--text pt-2" }, [
                  _vm._v("Appliance Checks"),
                ]),
                _vm.getBoilerBreakdownQA
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.getBoilerBreakdownQA.modelQuestion) +
                                ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.modelAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.modelAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.getBoilerBreakdownQA.makeQuestion) +
                                ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.makeAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.makeAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.getBoilerBreakdownQA.gcNumberQuestion
                              ) + ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.gcNumberAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.gcNumberAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.getBoilerBreakdownQA.gasSafetyDetails
                                  .serialNumberQuestion
                              ) + ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.gasSafetyDetails
                            .serialNumberAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.gasSafetyDetails
                                        .serialNumberAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.getBoilerBreakdownQA
                                  .installationYearQuestion
                              ) + ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.installationYearAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA
                                        .installationYearAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _c("div", { staticClass: "mt-1" }, [
                          _c("b", [
                            _vm._v(
                              _vm._s(
                                _vm.getBoilerBreakdownQA
                                  .yearOfLastServiceQuestion
                              ) + ":"
                            ),
                          ]),
                          _vm.getBoilerBreakdownQA.yearOfLastServiceAnswer
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA
                                        .yearOfLastServiceAnswer
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v("-")]),
                        ]),
                        _vm.getBoilerBreakdownQA.gasSafetyDetails
                          ? [
                              _c("div", { staticClass: "mt-1" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.gasSafetyDetails
                                        .locationConditionQuestion
                                    ) + ":"
                                  ),
                                ]),
                                _vm.getBoilerBreakdownQA.gasSafetyDetails
                                  .locationOfApplianceAnswer
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBoilerBreakdownQA
                                              .gasSafetyDetails
                                              .locationOfApplianceAnswer
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]),
                              _c("div", { staticClass: "mt-1" }, [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getBoilerBreakdownQA.gasSafetyDetails
                                        .applianceTypeQuestion
                                    ) + ":"
                                  ),
                                ]),
                                _vm.getBoilerBreakdownQA.gasSafetyDetails
                                  .applianceTypeAnswer
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getBoilerBreakdownQA
                                              .gasSafetyDetails
                                              .applianceTypeAnswer
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("span", [_vm._v("-")]),
                              ]),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._l(_vm.gasSafetyQuestionReportList, function (list, index) {
                  return _c("div", { key: index }, [
                    list.values.length > 0
                      ? _c(
                          "div",
                          { staticClass: "pt-2" },
                          [
                            _c("v-divider"),
                            _c(
                              "h3",
                              { staticClass: "mb-1 primary--text pt-4" },
                              [_vm._v(_vm._s(list.header))]
                            ),
                            _vm._l(list.values, function (qaItem, index) {
                              return _c("div", { key: index }, [
                                qaItem.question
                                  ? _c("div", { staticClass: "pt-3" }, [
                                      _c("b", [_vm._v("Que:")]),
                                      _vm._v(
                                        " " + _vm._s(qaItem.question) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                qaItem.question
                                  ? _c("div", [
                                      _c("b", [_vm._v("Ans:")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            qaItem.answer ? qaItem.answer : "--"
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                qaItem.description
                                  ? _c("div", [
                                      _c("b", [_vm._v("Desc:")]),
                                      _c("span", [
                                        _vm._v(_vm._s(qaItem.description)),
                                      ]),
                                    ])
                                  : _vm._e(),
                                qaItem.imageId && qaItem.imageUrl
                                  ? _c("div", { staticClass: "pb-2" }, [
                                      _c("b", [_vm._v("Img:")]),
                                      _c("span", [_vm._v("-")]),
                                    ])
                                  : _vm._e(),
                                qaItem.imageId && qaItem.imageUrl
                                  ? _c("div", [
                                      _c("img", {
                                        staticClass: "profile-img",
                                        staticStyle: {
                                          width: "auto",
                                          height: "auto",
                                          "max-width": "500px",
                                          "max-height": "500px",
                                        },
                                        attrs: { src: qaItem.imageUrl },
                                      }),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                }),
              ],
              2
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }