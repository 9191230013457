var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 up-card" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "heading" }, [_vm._v("Delay Details")]),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-2" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items: _vm.delayReasonList,
                          "item-text": "description",
                          "item-value": "description",
                          label: "Reason",
                          required: "",
                          "data-vv-name": "Delay Reason",
                          "error-messages": _vm.errors.collect("Delay Reason"),
                          loading: _vm.loading.delayReasonList,
                        },
                        on: { change: _vm.onChangeDelayReason },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-tile",
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c(
                                          "v-list-tile-title",
                                          {
                                            class:
                                              data.item.description ===
                                              "Configure"
                                                ? "bold-select"
                                                : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.delay.reason,
                          callback: function ($$v) {
                            _vm.$set(_vm.delay, "reason", $$v)
                          },
                          expression: "delay.reason",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Note", rows: "3" },
                        model: {
                          value: _vm.delay.notes,
                          callback: function ($$v) {
                            _vm.$set(_vm.delay, "notes", $$v)
                          },
                          expression: "delay.notes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 btn-save",
                  attrs: { color: "primary", loading: _vm.loading.saveReason },
                  on: { click: _vm.saveDelayReason },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                ref: "configureDropdown",
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }