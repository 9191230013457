var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _vm.parentComponent !== "ContractorFinancialSummary"
        ? _c(
            "v-toolbar",
            { attrs: { dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [
                _vm.contractorJobDetail && _vm.contractorJobDetail.jobDetail
                  ? _c("span", [
                      _vm._v(
                        " Job Id: " +
                          _vm._s(_vm.contractorJobDetail.jobDetail.jobId) +
                          " - " +
                          _vm._s(_vm.contractorJobDetail.getAddress(",")) +
                          " "
                      ),
                    ])
                  : _c("span", [_vm._v("Contractor Job Detail")]),
              ]),
              _c("v-spacer"),
              _vm.showIconToRedirectToJob
                ? _c(
                    "v-btn",
                    {
                      staticClass: "btn-redirectToJob",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.redirectToJob(
                            _vm.contractorJobDetail.jobDetail.jobId
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("launch")])],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "btn-close-dialog",
                  attrs: { icon: "" },
                  on: { click: _vm.closeContractorJobDetaildialog },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.parentComponent !== "ContractorFinancialSummary"
        ? _c("v-divider")
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass:
            "grey lighten-3 job-detail-dlog-content scroll-content-dialog",
        },
        [
          _c(
            "v-layout",
            { staticClass: "scoll-vb-content", attrs: { "mt-0": "" } },
            [
              !_vm.contractorJobDetail
                ? _c("v-progress-circular", {
                    style: _vm.getLoaderStyle(70),
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _vm._e(),
              _vm.contractorJobDetail
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mb-2" },
                        [
                          _c(
                            "v-card-text",
                            [
                              _vm.contractorJobDetail.jobDetail
                                ? _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs5: "",
                                            "job-basicinfo": "",
                                            "pr-3": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [_vm._v("person")]),
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.contractorJobDetail
                                                        .jobDetail.clientTitle
                                                        ? _vm
                                                            .contractorJobDetail
                                                            .jobDetail
                                                            .clientTitle
                                                        : ""
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.contractorJobDetail
                                                        .jobDetail
                                                        .clientForename
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.contractorJobDetail
                                                        .jobDetail
                                                        .clientLastName
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            [
                                              _c("v-icon", [_vm._v("place")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.contractorJobDetail
                                                      .jobDetail.addressLine1
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.contractorJobDetail
                                                      .jobDetail.addressLine2
                                                  ) +
                                                  ", " +
                                                  _vm._s(
                                                    _vm.contractorJobDetail
                                                      .jobDetail.addressLine3
                                                  ) +
                                                  ", " +
                                                  _vm._s(
                                                    _vm.contractorJobDetail
                                                      .jobDetail.addressLine4
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.contractorJobDetail
                                            .emergencyDetail &&
                                          _vm.contractorJobDetail
                                            .emergencyDetail.firstNoticedAt
                                            ? _c(
                                                "div",
                                                { staticClass: "mt-3" },
                                                [
                                                  _c("h4", [
                                                    _vm._v("First Noticed At"),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "date-label",
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "pl-1",
                                                          attrs: {
                                                            small: "",
                                                            color: "secondary",
                                                          },
                                                        },
                                                        [_vm._v("date_range")]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getFormattedDate(
                                                              _vm
                                                                .contractorJobDetail
                                                                .emergencyDetail
                                                                .firstNoticedAt
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.contractorJobDetail.jobDetail &&
                                          _vm.contractorJobDetail.jobDetail
                                            .createdAt
                                            ? _c("div", [
                                                _c("h4", [_vm._v("Logged At")]),
                                                _c(
                                                  "span",
                                                  { staticClass: "date-label" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pl-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "secondary",
                                                        },
                                                      },
                                                      [_vm._v("date_range")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            _vm
                                                              .contractorJobDetail
                                                              .jobDetail
                                                              .createdAt
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.contractorJobDetail
                                            .contractorAppointedDetail &&
                                          _vm.contractorJobDetail
                                            .contractorAppointedDetail.createdAt
                                            ? _c("div", [
                                                _c("h4", [
                                                  _vm._v("Deployed At"),
                                                ]),
                                                _c(
                                                  "span",
                                                  { staticClass: "date-label" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "pl-1",
                                                        attrs: {
                                                          small: "",
                                                          color: "secondary",
                                                        },
                                                      },
                                                      [_vm._v("date_range")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            _vm
                                                              .contractorJobDetail
                                                              .contractorAppointedDetail
                                                              .createdAt
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm.contractorJobDetail.jobDetail
                                            .sIJobDetail
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("business"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .contractorJobDetail
                                                            .jobDetail
                                                            .sIJobDetail
                                                            .claimManagementCompany
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("event_note"),
                                                    ]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            _vm
                                                              .contractorJobDetail
                                                              .jobDetail
                                                              .sIJobDetail
                                                              .instructionDate
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.contractorJobDetail.siJobDelay
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("av_timer"),
                                                    ]),
                                                    _vm._v(
                                                      " Delay Reason : " +
                                                        _vm._s(
                                                          _vm
                                                            .contractorJobDetail
                                                            .siJobDelay.reason
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("notes"),
                                                    ]),
                                                    _vm._v(
                                                      " Delay Notes : " +
                                                        _vm._s(
                                                          _vm
                                                            .contractorJobDetail
                                                            .siJobDelay.notes
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          _vm.contractorJobDetail.usJobDelay
                                            ? [
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("av_timer"),
                                                    ]),
                                                    _vm._v(
                                                      " Delay Reason : " +
                                                        _vm._s(
                                                          _vm
                                                            .contractorJobDetail
                                                            .usJobDelay.reason
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("notes"),
                                                    ]),
                                                    _vm._v(
                                                      " Delay Notes : " +
                                                        _vm._s(
                                                          _vm
                                                            .contractorJobDetail
                                                            .usJobDelay.notes
                                                        ) +
                                                        " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c("v-flex", { attrs: { xs7: "" } }, [
                                        _c(
                                          "div",
                                          { staticClass: "prperty-detail" },
                                          [
                                            _c("Map", {
                                              staticStyle: { height: "250px" },
                                              attrs: {
                                                "map-id": new Date()
                                                  .getTime()
                                                  .toString(),
                                                "source-latitude":
                                                  _vm.contractorJobDetail
                                                    .jobDetail.latitude,
                                                "source-longitude":
                                                  _vm.contractorJobDetail
                                                    .jobDetail.longitude,
                                                "source-address":
                                                  _vm.contractorJobDetail.getAddress(
                                                    ","
                                                  ),
                                                "show-street-view": true,
                                                "show-fullscreen-control": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-expansion-panel",
                        { staticClass: "elevation-0 mb-2" },
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c("h4", [
                                          _vm._v("Policy Information"),
                                        ]),
                                        _c("v-spacer"),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                740043961
                              ),
                            },
                            [
                              _c("v-divider"),
                              _c("v-layout", { staticClass: "ma-5" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "bar",
                                        rawName: "v-bar",
                                        value: { useScrollbarPseudo: true },
                                        expression:
                                          "{ useScrollbarPseudo: true }",
                                      },
                                    ],
                                    staticClass: "pre-block",
                                  },
                                  [
                                    _c("v-layout", [
                                      _c("pre", {
                                        staticClass: "mb-0 mt-0",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitizeHTML(
                                              _vm.emergencyDefinition
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.getEngineerVisitDetail
                        ? _c(
                            "v-card",
                            { staticClass: "elevation-0 mb-2" },
                            [
                              _c(
                                "v-card-title",
                                {},
                                [
                                  _c("h4", [_vm._v("Engineer Visit Detail")]),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticClass: "engineer-name",
                                          attrs: { xs12: "" },
                                        },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              "Name: " +
                                                _vm._s(_vm.engineerName)
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm.getEngineerVisitDetail
                                        .jobAcceptOrRejectAt
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v("Accepted At :"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm.getEngineerVisitDetail
                                                        .jobAcceptOrRejectAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail
                                        .engineerOnSiteAt
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [_vm._v("On Site At :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm.getEngineerVisitDetail
                                                        .engineerOnSiteAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.visitStartedAt
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [_vm._v("Started At :")]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm.getEngineerVisitDetail
                                                        .visitStartedAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail
                                        .visitCompletedAt
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v("Completed At :"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm.getEngineerVisitDetail
                                                        .visitCompletedAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.getEngineerVisitDetail.status ===
                                        _vm.engineerJobVisitStatus[
                                          _vm.engineerJobVisitStatus.Cancelled
                                        ] ||
                                        _vm.getEngineerVisitDetail.status ===
                                          _vm.engineerJobVisitStatus[
                                            _vm.engineerJobVisitStatus.Rejected
                                          ] ||
                                        _vm.getEngineerVisitDetail.status ===
                                          _vm.engineerJobVisitStatus[
                                            _vm.engineerJobVisitStatus.Abandoned
                                          ]) &&
                                      _vm.getEngineerVisitDetail.processAt
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getEngineerVisitDetail
                                                      .status
                                                  ) + " At :"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormattedDate(
                                                      _vm.getEngineerVisitDetail
                                                        .processAt
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.status ===
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Cancelled
                                      ]
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _vm.getUserName(
                                                _vm.getEngineerVisitDetail
                                                  .processByUserId
                                              ) &&
                                              _vm.getUserName(
                                                _vm.getEngineerVisitDetail
                                                  .processByUserId
                                              ) !== "undefined" &&
                                              _vm.getUserName(
                                                _vm.getEngineerVisitDetail
                                                  .processByUserId
                                              ) !== "undefined undefined"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "cancelledby-engineer",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .getEngineerVisitDetail
                                                              .status
                                                          ) + " by :"
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getUserName(
                                                              _vm
                                                                .getEngineerVisitDetail
                                                                .processByUserId
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .getEngineerVisitDetail
                                                          .status
                                                      ) + " by :"
                                                    ),
                                                  ]),
                                              _vm._v(" Engineer "),
                                            ]
                                          )
                                        : _vm._e(),
                                      (_vm.getEngineerVisitDetail.status ===
                                        _vm.engineerJobVisitStatus[
                                          _vm.engineerJobVisitStatus.Rejected
                                        ] ||
                                        _vm.getEngineerVisitDetail.status ===
                                          _vm.engineerJobVisitStatus[
                                            _vm.engineerJobVisitStatus.Abandoned
                                          ]) &&
                                      _vm.getEngineerVisitDetail
                                        .processByUserName
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getEngineerVisitDetail
                                                      .status
                                                  ) + " by :"
                                                ),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getEngineerVisitDetail
                                                      .processByUserName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.status ===
                                        _vm.engineerJobVisitStatus[
                                          _vm.engineerJobVisitStatus.Cancelled
                                        ] ||
                                      _vm.getEngineerVisitDetail.status ===
                                        _vm.engineerJobVisitStatus[
                                          _vm.engineerJobVisitStatus.Rejected
                                        ] ||
                                      _vm.getEngineerVisitDetail.status ===
                                        _vm.engineerJobVisitStatus[
                                          _vm.engineerJobVisitStatus.Abandoned
                                        ]
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [_vm._v("Status:")]),
                                              _vm._v("   "),
                                              _c("b", [
                                                _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm
                                                            .getEngineerVisitDetail
                                                            .status
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.status ===
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Abandoned
                                      ]
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs6: "", "my-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v("Abandoned Reason:"),
                                              ]),
                                              _vm._v("   "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getEngineerVisitDetail
                                                      .description
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.status ===
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Abandoned
                                      ]
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass: "abandonNotes",
                                              attrs: { xs6: "", "my-1": "" },
                                            },
                                            [
                                              _c("b", [
                                                _vm._v("Abandoned Notes:"),
                                              ]),
                                              _vm._v("   "),
                                              _c("span", [
                                                _c("pre", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getEngineerVisitDetail
                                                        .abandonNotes
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.getEngineerVisitDetail.visitNote
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "", "mt-2": "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "notes body-2 black--text text--lighten-1 pa-2",
                                                },
                                                [
                                                  _c(
                                                    "h4",
                                                    { staticClass: "mb-1" },
                                                    [_vm._v("Site Notes")]
                                                  ),
                                                  _c(
                                                    "pre",
                                                    { staticClass: "notes" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .getEngineerVisitDetail
                                                            .visitNote
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mb-2" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("h4", [_vm._v("Questions and Answers")]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _vm.contractorJobDetail.productsQA.length ||
                          _vm.contractorJobDetail.emergenciesQA.length
                            ? _c(
                                "v-card-text",
                                [
                                  _vm.contractorJobDetail.emergenciesQA.length
                                    ? _c(
                                        "v-container",
                                        {
                                          attrs: {
                                            fluid: "",
                                            "grid-list-md": "",
                                            "pa-0": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            _vm._l(
                                              _vm.contractorJobDetail
                                                .emergenciesQA,
                                              function (emergencyQA) {
                                                return _c(
                                                  "v-flex",
                                                  {
                                                    key: emergencyQA.questionId,
                                                    attrs: { xs6: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-inner-content grey lighten-3 pa-2",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              emergencyQA.questionText
                                                            )
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "b",
                                                            {
                                                              staticClass:
                                                                "secondary--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    emergencyQA.answer
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    emergencyQA.answer !==
                                                                      ""
                                                                      ? emergencyQA.unit
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.contractorJobDetail.productsQA.length
                                    ? _c(
                                        "v-container",
                                        {
                                          attrs: {
                                            fluid: "",
                                            "grid-list-md": "",
                                            "pa-0": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            _vm._l(
                                              _vm.contractorJobDetail
                                                .productsQA,
                                              function (productQA) {
                                                return _c(
                                                  "v-flex",
                                                  {
                                                    key: productQA.questionId,
                                                    attrs: { xs6: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "question-inner-content grey lighten-3 pa-2",
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              productQA.questionText
                                                            )
                                                          ),
                                                        ]),
                                                        _c("div", [
                                                          _c(
                                                            "b",
                                                            {
                                                              staticClass:
                                                                "secondary--text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    productQA.answer
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    productQA.answer !==
                                                                      ""
                                                                      ? productQA.unit
                                                                      : ""
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c(
                                "v-card-text",
                                { staticClass: "text-xs-center" },
                                [_vm._v("No questions and answers found.")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mb-2" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("h4", [
                                _vm._v("Vulnerability Questions and Answers"),
                              ]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-layout",
                            {
                              staticClass: "mx-auto mt-3 mb-3",
                              attrs: { wrap: "" },
                            },
                            [
                              _vm.vulnerabilityAnswer.isHealthAffected === true
                                ? _c(
                                    "div",
                                    [
                                      _vm.vulnerabilityAnswer.level
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "", "mb-2": "" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "pl-10" },
                                                [
                                                  _vm._v(" Level : "),
                                                  _c(
                                                    "b",
                                                    {
                                                      staticClass:
                                                        "secondary--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .vulnerabilityAnswer
                                                            .level
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.vulnerabilityAnswer.answerDetail
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "affected-details",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Details :"),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "affected-description",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .vulnerabilityAnswer
                                                              .answerDetail
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm.vulnerabilityAnswer.isHealthAffected ===
                                  false
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-2": "" } },
                                        [
                                          _c(
                                            "span",
                                            { attrs: { "padding-2": "" } },
                                            [_vm._v("No Vulnerability")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mb-2": "" } },
                                        [
                                          _c("span", [
                                            _vm._v("Not answered yet."),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.contractorJobDetail
                        ? _c("GasSafetyQAReport", {
                            attrs: {
                              "boiler-breakdown-q-a": _vm.getBoilerBreakdownQA,
                              "health-safety-q-a": _vm.getHealthSafetyQA,
                              "engineer-visit-detail":
                                _vm.getEngineerVisitDetail,
                              "from-contractor-details-view": true,
                              "job-id": _vm.contractorJobDetail.jobDetail.jobId,
                              "visit-emergency-details":
                                _vm.contractorJobDetail.emergencyDetail,
                              "engineer-name": _vm.engineerName,
                              "pictures-upload":
                                _vm.contractorJobDetail.picturesUpload,
                            },
                          })
                        : _vm._e(),
                      _vm.getHealthSafetyQA
                        ? _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "div",
                                    {
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v(
                                          "Health & Safety Questions and Answers"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("HealthSafetyQAReport", {
                                    staticClass: "pa-3",
                                    attrs: {
                                      "get-health-safety-q-a":
                                        _vm.getHealthSafetyQA,
                                      "job-id":
                                        _vm.contractorJobDetail.jobDetail.jobId,
                                      emergency:
                                        _vm.contractorJobDetail.emergencyDetail,
                                      "engineer-visit-detail":
                                        _vm.getEngineerVisitDetail,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "v-expansion-panel",
                            { attrs: { disabled: "" } },
                            [
                              _c("v-expansion-panel-content", [
                                _c(
                                  "div",
                                  { attrs: { slot: "header" }, slot: "header" },
                                  [
                                    _c("h4", [
                                      _vm._v(
                                        "No Health & Safety records found"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mt-2" },
                        [
                          _c(
                            "v-card-title",
                            [_c("h4", [_vm._v("Pictures")]), _c("v-spacer")],
                            1
                          ),
                          _c("v-divider"),
                          _vm.contractorJobDetail.picturesUpload.length > 0
                            ? _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    _vm._l(
                                      _vm.contractorJobDetail.picturesUpload,
                                      function (pictureUpload, index) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: index,
                                            staticClass: "img-block pa1 xs3",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "up-images pa-1" },
                                              [
                                                _c("img", {
                                                  staticClass: "image",
                                                  attrs: {
                                                    src: _vm.getBlobUrl(
                                                      pictureUpload.fileURL
                                                    ),
                                                    width: "100%",
                                                    height: "100%",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-card-text",
                                { staticClass: "text-xs-center" },
                                [_vm._v("No pictures found.")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mt-2" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("h4", [_vm._v("Engineer Requests")]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _vm.getAdditionalRequestList.length > 0
                            ? _c(
                                "v-card-text",
                                [
                                  _c("EngineerResourceRequestComponent", {
                                    attrs: {
                                      "additional-request-list":
                                        _vm.getAdditionalRequestList,
                                      "job-id":
                                        _vm.contractorJobDetail.jobDetail.jobId,
                                      "parent-component": _vm.parentComponent,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "v-card-text",
                                { staticClass: "text-xs-center" },
                                [_vm._v("No resource requests found.")]
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mt-2" },
                        [
                          _c(
                            "v-card-title",
                            {},
                            [_c("h4", [_vm._v("Job Summary")]), _c("v-spacer")],
                            1
                          ),
                          _c("v-divider"),
                          _c("v-card-text", [
                            _c(
                              "div",
                              { staticClass: "px-3 pt-0" },
                              [
                                _c("v-divider", { staticClass: "mb-2" }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-xs-center message pt-2 pb-3 grey--text",
                                  },
                                  [
                                    _vm._v(
                                      " In Progress... Job Financial Summary data. "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card",
                        { staticClass: "elevation-0 mb-2" },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("h4", [_vm._v("Additional Information")]),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-layout",
                            {
                              staticClass: "mx-auto mt-1 mb-1",
                              attrs: { wrap: "" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "pa-12" },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: {
                                        fluid: "",
                                        "grid-list-md": "",
                                        "pa-0": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass: "gap-2 mx-auto p-2",
                                          attrs: { wrap: "" },
                                        },
                                        [
                                          _vm.getAccessNotes &&
                                          _vm.getAccessNotes.answer
                                            ? _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "mx-auto text-xs-center",
                                                  attrs: { xs12: "" },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v("Access Notes:"),
                                                  ]),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "secondary--text font-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getAccessNotes
                                                              .answer
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "mx-auto text-xs-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "No access notes added"
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass:
                                            "gap-3 p-2 text-xs-center",
                                          attrs: { wrap: "" },
                                        },
                                        [
                                          _vm.getAdditionalHealthQA &&
                                          _vm.getAdditionalHealthQA.answerDetail
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "affected-details text-xs-center",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .getAdditionalHealthQA
                                                                .questionText
                                                            )
                                                        ),
                                                      ]),
                                                      _c("br"),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text font-bold text-xs-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm
                                                                  .getAdditionalHealthQA
                                                                  .answerDetail
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "v-card-text",
                                                {
                                                  staticClass: "text-xs-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "No additional information entered"
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }