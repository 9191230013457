var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobId
    ? _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            [
              _vm.showHeader
                ? _c("h3", [_vm._v("Linked Phone Call")])
                : _vm._e(),
              _c("v-spacer"),
              _vm.recordingUrl && _vm.canPlayCall
                ? _c(
                    "v-btn",
                    {
                      staticClass: "play-btn",
                      attrs: { color: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getRecordingUrl.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("play_circle_outline")]),
                      _vm._v("  Play Call "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.callSid
                ? _c(
                    "v-btn",
                    {
                      staticClass: "transcript-btn",
                      attrs: {
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                        color: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.getTranscriptLog.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("perm_phone_msg")]),
                      _vm._v(
                        "  " +
                          _vm._s(_vm.showTranscriptLog ? "Hide" : "See") +
                          " Transcript "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.callSid && !_vm.recordingUrl
                ? _c("div", [_vm._v("No call log or transcript found")])
                : _vm._e(),
            ],
            1
          ),
          _vm.showTranscriptLog && _vm.transcriptLog.length > 0
            ? _c("v-card-text", { staticClass: "grey lighten-3" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "bar",
                        rawName: "v-bar",
                        value: { useScrollbarPseudo: true },
                        expression: "{ useScrollbarPseudo: true }",
                      },
                    ],
                    staticClass: "scroll-content",
                  },
                  [
                    _c("div", { staticClass: "mb-3" }),
                    _c(
                      "div",
                      { staticClass: "call-chat" },
                      [
                        _c(
                          "v-list",
                          { staticClass: "grey lighten-3" },
                          [
                            _vm._l(
                              _vm.transcriptLog,
                              function (transcript, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-tile",
                                      {
                                        staticClass: "mb-4",
                                        class:
                                          transcript.Speaker === 1
                                            ? "im-chat"
                                            : "client-chat",
                                        attrs: { avatar: "" },
                                      },
                                      [
                                        _c(
                                          "v-list-tile-content",
                                          {
                                            staticClass:
                                              "elevation-1 pa-2 lighten-4",
                                            class: _vm.getTranscriptTextColor(
                                              transcript.Speaker
                                            ),
                                          },
                                          [
                                            _c(
                                              "v-list-tile-sub-title",
                                              { staticClass: "black--text" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(transcript.Words) +
                                                    " "
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-1 text-xs-right mt-1",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFullTime(
                                                            transcript.StartTime
                                                          )
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.getFullTime(
                                                            transcript.EndTime
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.snackbar
            ? _c(
                "v-snackbar",
                {
                  attrs: { timeout: 3000, left: "", bottom: "" },
                  model: {
                    value: _vm.snackbar,
                    callback: function ($$v) {
                      _vm.snackbar = $$v
                    },
                    expression: "snackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.snackbar = false
                        },
                      },
                    },
                    [_vm._v("close")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }