var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.cookieRecord
    ? _c(
        "div",
        { staticClass: "cookie-record" },
        [
          _c(
            "v-tooltip",
            {
              staticClass: "cookie-record cookie-record--close",
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                icon: "",
                                flat: "",
                                color: "primary",
                                small: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeModal()
                                },
                              },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("close")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                916411077
              ),
            },
            [_c("span", [_vm._v("Close")])]
          ),
          _c(
            "v-layout",
            { staticClass: "pa-3", attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "", "pr-2": "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "mb-3",
                    attrs: {
                      label: "Cookie Name",
                      hint: "The name of the cookie",
                      required: "",
                      "data-vv-name": "cookieName",
                      "data-vv-scope": _vm.validationScope,
                      "error-messages": _vm.errors.collect("cookieName"),
                    },
                    model: {
                      value: _vm.cookieName,
                      callback: function ($$v) {
                        _vm.cookieName = $$v
                      },
                      expression: "cookieName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "", "pl-2": "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "mb-3",
                    attrs: {
                      label: "Cookie Description",
                      hint: "A brief description of the cookie",
                      required: "",
                      "data-vv-name": "cookieDescription",
                      "data-vv-scope": _vm.validationScope,
                      "error-messages": _vm.errors.collect("cookieDescription"),
                    },
                    model: {
                      value: _vm.cookieDescription,
                      callback: function ($$v) {
                        _vm.cookieDescription = $$v
                      },
                      expression: "cookieDescription",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { "mb-3": "", xs12: "" } },
                [
                  _c("div", { staticClass: "warning--text mb-2" }, [
                    _vm._v(
                      " When adding a script, any text inside curly brackets {{}} will be treated as a placeholder and added to the cookie settings. "
                    ),
                  ]),
                  _c("div", { staticClass: "warning--text mb-2" }, [
                    _vm._v(
                      " After adding your placeholders to the script, click update placeholders, and proceeed to update the relevant settings. "
                    ),
                  ]),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticClass: "expansion-header",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "h4",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v("Cookie Settings")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                479537736
                              ),
                            },
                            [
                              _c("CookieConfigurationSettings", {
                                ref: "cookieConfigSettings",
                                attrs: {
                                  "validation-scope": _vm.validationScope,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { "mb-3": "", xs12: "" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-content",
                            {
                              staticClass: "expansion-header",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function () {
                                      return [
                                        _c(
                                          "h4",
                                          { staticClass: "text-uppercase" },
                                          [_vm._v("Cookie Scripts")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3134469297
                              ),
                            },
                            [
                              _c("CookieConfigurationScripts", {
                                ref: "cookieConfigScripts",
                                attrs: {
                                  "validation-scope": _vm.validationScope,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "d-flex", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.updatePlaceholders()
                            },
                          },
                        },
                        [_vm._v("Update Placeholders")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.saveRecord()
                            },
                          },
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: {
                            click: function ($event) {
                              return _vm.resetChanges()
                            },
                          },
                        },
                        [_vm._v("Reset changes")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "error" },
                          on: {
                            click: function ($event) {
                              return _vm.closeModal()
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: 3000,
                bottom: "",
                center: "",
                "multi-line": "",
                color: "red",
              },
              model: {
                value: _vm.showCookieErrors,
                callback: function ($$v) {
                  _vm.showCookieErrors = $$v
                },
                expression: "showCookieErrors",
              },
            },
            [_c("div", [_vm._v(_vm._s(_vm.cookieRecordErrors[0]))])]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }