var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoaded,
            expression: "!isLoaded",
          },
        ],
        style: _vm.getLoaderStyle(70),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      ((_vm.getIsThirdPartyContractor &&
        _vm.getAssignedThirdPartyContractor !== null) ||
        _vm.getAssignedContractor !== undefined) &&
      _vm.isLoaded
        ? _c("CallContractorDetailView", {
            attrs: {
              "bind-from-prop": true,
              item: _vm.getContractorDetails,
              emergency: _vm.emergency,
              "job-id": _vm.jobId,
              "job-latitude": _vm.job.latitude,
              "job-longitude": _vm.job.longitude,
              "assigned-contractors": _vm.getAssignedContractors,
              "is-third-party-contractor": _vm.getIsThirdPartyContractor,
              "engineer-visit-detail": _vm.getEngineerVisitDetail,
              "job-address": _vm.job.getAddress(", "),
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }