var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.showProductSelection
        ? [
            _c(
              "v-card",
              { staticClass: "mb-3 enablement" },
              [
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-xs-center": "" } },
                          [
                            _c(
                              "div",
                              { staticClass: "question-title-desc" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-0 btn",
                                    attrs: {
                                      color: "green white--text",
                                      large: "",
                                      ripple: true,
                                      disabled: _vm.isJobCancelled,
                                    },
                                    on: { click: _vm.reInitialize },
                                  },
                                  [
                                    _c("v-icon", { attrs: { dark: "" } }, [
                                      _vm._v("refresh"),
                                    ]),
                                    _vm._v("   Re-initialize SI Process "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c("JobPackagePreview", {
              ref: "jobPackagePreviewRef",
              attrs: { "job-id": _vm.jobId },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }