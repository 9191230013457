var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "div",
        { staticClass: "bg-white pa-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("h3", [_vm._v("Drainage Report")]),
              ]),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mt-2 mb-3" }),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.getDrainageReport
                ? _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "gridView mt-2 drainage-report-gridview" },
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-1",
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.drainageReportData,
                            "item-key": "id",
                            "hide-actions": "",
                            loading: _vm.generatePDFInProcess || _vm.isLoading,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "items",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.name)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.item.createdAt
                                                ? _vm.getFormattedDate(
                                                    props.item.createdAt
                                                  )
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.item.updatedAt
                                                ? _vm.getFormattedDate(
                                                    props.item.updatedAt
                                                  )
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { attrs: { align: "right" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-0 edit-btn",
                                                              attrs: {
                                                                icon: "",
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "md-icon",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.editDrainageReport(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("edit")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Edit")])]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "download-btn",
                                                              attrs: {
                                                                icon: "",
                                                                flat: "",
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.generatePDFInProcess,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.downloadDrainageReport(
                                                                      props.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "md-icon",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "get_app"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Download")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4042669352
                          ),
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "text-xs-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "blue white--text generate-report",
                            attrs: {
                              disabled: _vm.isLoading,
                              loading: _vm.isLoading,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.generateDrainageReport()
                              },
                            },
                          },
                          [_vm._v(" Generate Report ")]
                        ),
                      ],
                      1
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showDrainageDocumentTemplateSelection
        ? _c("ReportDocumentTemplate", {
            ref: "refReportDocumentTemplate",
            attrs: {
              "document-template-list": _vm.drainageDocumentTemplateList,
              "job-id": _vm.jobId,
            },
            on: {
              getDocumentTemplateText: _vm.getDocumentTemplateText,
              closeDocumentTemplateDialog: _vm.closeDocumentTemplateDialog,
              saveDocument: _vm.saveDocument,
              showSnackBarWithMessage: _vm.showSnackBarWithMessage,
            },
          })
        : _vm._e(),
      _vm.showSnackbar
        ? _c(
            "v-snackbar",
            {
              attrs: { bottom: "", left: "", timeout: _vm.snackbarTimeout },
              model: {
                value: _vm.showSnackbar,
                callback: function ($$v) {
                  _vm.showSnackbar = $$v
                },
                expression: "showSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", flat: "" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.showSnackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }