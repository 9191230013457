var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    _vm._l(_vm.keyItems, function (item) {
      return _c("v-flex", { key: item.id, attrs: { xs12: "" } }, [
        _c("span", [
          _vm._v(" " + _vm._s(item.questionText) + ": "),
          _c("b", [
            _vm._v(
              _vm._s(item.answer) +
                " " +
                _vm._s(item.answer !== "" ? item.unit : "")
            ),
          ]),
        ]),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }