var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isLocal
    ? _c(
        "v-expansion-panel-content",
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("b", [
              _vm._v(
                _vm._s(_vm.indexPrefix ? _vm.indexPrefix + "." : "") +
                  " Select " +
                  _vm._s(_vm.displayText) +
                  " questions"
              ),
            ]),
          ]),
          _c(
            "v-card",
            { staticClass: "bg-gray" },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c(
                              "v-card",
                              { staticClass: "card-box-shadow-none" },
                              [
                                _c(
                                  "v-card-title",
                                  { staticClass: "pt-0 pb-0" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        "single-line": "",
                                        label: "Search available questions...",
                                        "prepend-icon": "search",
                                      },
                                      model: {
                                        value: _vm.filterValue,
                                        callback: function ($$v) {
                                          _vm.filterValue = $$v
                                        },
                                        expression: "filterValue",
                                      },
                                    }),
                                    !_vm.isUserRoleClientManager
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            style: {
                                                              cursor: "pointer",
                                                            },
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.addEmergencyQuestion()
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v(" add ")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              4191103634
                                            ),
                                          },
                                          [_c("span", [_vm._v("Add Question")])]
                                        )
                                      : _vm._e(),
                                    _vm.showEmergencyQuestionDialog
                                      ? _c(
                                          "v-dialog",
                                          {
                                            attrs: {
                                              "content-class":
                                                "v-dialog--scrollable",
                                              "max-width": "650",
                                              persistent: "",
                                            },
                                            model: {
                                              value:
                                                _vm.showEmergencyQuestionDialog,
                                              callback: function ($$v) {
                                                _vm.showEmergencyQuestionDialog =
                                                  $$v
                                              },
                                              expression:
                                                "showEmergencyQuestionDialog",
                                            },
                                          },
                                          [
                                            _c("EmergencyQuestion", {
                                              attrs: {
                                                item: _vm.getEmergencyQuestion,
                                                trades: _vm.trades,
                                                "submited-policy-question-list":
                                                  _vm.submitedPolicyQuestionList,
                                                "job-type": _vm.jobType,
                                                "third-party-detail":
                                                  _vm.thirdPartyDetail,
                                              },
                                              on: {
                                                closeEmergencyQuestion:
                                                  function ($event) {
                                                    _vm.showEmergencyQuestionDialog =
                                                      !_vm.showEmergencyQuestionDialog
                                                  },
                                                saveEmergencyQuestion:
                                                  _vm.onSaveEmergencyQuestion,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-container",
                                  {
                                    staticClass: "drag-container",
                                    attrs: { fluid: "", "pt-0": "" },
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "questions-list-p",
                                        attrs: { wrap: "" },
                                      },
                                      _vm._l(
                                        _vm.emergencyQuestionsList,
                                        function (question) {
                                          return _c(
                                            "v-flex",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    String(question.text)
                                                      .toLowerCase()
                                                      .indexOf(
                                                        _vm.filterValue.toLowerCase()
                                                      ) >= 0,
                                                  expression:
                                                    "String(question.text).toLowerCase().indexOf(filterValue.toLowerCase()) >= 0",
                                                },
                                              ],
                                              key: question.id,
                                              staticClass: "mb-2",
                                              class: question.isSelected
                                                ? "selected"
                                                : "",
                                              attrs: {
                                                xs6: "",
                                                md6: "",
                                                lg4: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "bg-gray ml-2 card-box-shadow-none",
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.onQuestionClick(
                                                        question
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            question.isSelected,
                                                          expression:
                                                            "question.isSelected",
                                                        },
                                                      ],
                                                      style: {
                                                        cursor: "pointer",
                                                      },
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v(" check_circle ")]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            !question.isSelected,
                                                          expression:
                                                            "!question.isSelected",
                                                        },
                                                      ],
                                                      style: {
                                                        cursor: "pointer",
                                                      },
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " panorama_fish_eye "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-card-title", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(question.text) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              !_vm.isUserRoleClientManager
                                                ? _c(
                                                    "v-card-actions",
                                                    {
                                                      staticClass:
                                                        "action-que-icon py-0",
                                                    },
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.editEmergencyQuestion(
                                                                question
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [_vm._v("edit")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onDeleteEmergencyQuestionClick(
                                                                question.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [_vm._v("delete")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "sub-module" },
                  [
                    _c(
                      "v-expansion-panel",
                      { staticClass: "select-options elevation-0" },
                      _vm._l(_vm.emergencyDetails, function (item, index) {
                        return _c("EmergencyQuestionGenerator", {
                          key: item.emergencyDetailId,
                          staticClass: "mb-3 elevation-1",
                          attrs: {
                            "policy-id": _vm.policyId,
                            "entity-type": item.entityType,
                            "insurer-id": item.insurerId,
                            "emergency-id": item.emergencyId,
                            "emergency-detail-id": item.emergencyDetailId,
                            "display-text": item.displayText,
                            "index-prefix": _vm.getPrefix(index),
                            "submited-policy-question-list":
                              _vm.submitedPolicyQuestionList,
                            "is-local": item.isLocal,
                            trades: _vm.trades,
                            "job-type": _vm.jobType,
                            "third-party-detail": _vm.thirdPartyDetail,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }