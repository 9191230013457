var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Cost Authorised: ")]),
      _c("span", [
        _vm._v(_vm._s(_vm.item.additionalCostsAuthorised ? "Yes" : "No")),
      ]),
    ]),
    _vm.authorizationReason
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Authorisation Reason: ")]),
          _c("span", [_vm._v(_vm._s(_vm.authorizationReason))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Additional Cost Authorization")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }