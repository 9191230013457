var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getCustomerAppointedTradespeople
    ? _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "heading" }, [
                      _c("span", { staticClass: "teal--text" }, [
                        _vm._v("Customer Appointed Tradespeople"),
                      ]),
                      _vm.getCustomerAppointedTradespeople.invoiceImageUrl
                        ? _c("span", { staticClass: "teal--text" }, [
                            _vm._v("(Invoice)"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  !_vm.getCustomerAppointedTradespeople.invoiceImageUrl
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _vm._v(" " + _vm._s(_vm.getDisplayText) + " "),
                      ])
                    : _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("PictureUpload", {
                            ref: "pictureUploadRef",
                            attrs: {
                              "job-id": _vm.jobId,
                              "picture-list": _vm.getCATInvoiceUrls,
                              "show-claim-thumb": false,
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }