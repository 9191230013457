var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Insurer")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeInsurerDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-card-text", { staticClass: "scroll-content-dialog" }, [
        _c(
          "div",
          { staticClass: "configure-dropdown-details" },
          [
            _c("v-progress-circular", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoaded,
                  expression: "!isLoaded",
                },
              ],
              style: _vm.getLoaderStyle(70),
              attrs: {
                width: 2,
                size: 50,
                indeterminate: "",
                color: "primary",
              },
            }),
            _c(
              "v-layout",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLoaded && _vm.items,
                    expression: "isLoaded && items",
                  },
                ],
              },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "gridView tbody-scroll-table",
                    attrs: { xs12: "" },
                  },
                  [
                    _c(
                      "v-data-table",
                      {
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.items,
                          "item-key": "id",
                          loading: _vm.isLoading,
                          "hide-actions": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function (props) {
                              return [
                                _c("tr", { ref: "datatable" }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-left name-col" },
                                    [
                                      _vm.isUpdateRecord &&
                                      _vm.index === props.item.id
                                        ? _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "pt-2",
                                            attrs: {
                                              "data-vv-scope":
                                                "updateRecordFormRef",
                                              "data-vv-name": "editName",
                                              "error-messages":
                                                _vm.validationMessage(
                                                  "editName"
                                                ),
                                            },
                                            model: {
                                              value: props.item.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "props.item.name",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(_vm._s(props.item.name)),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-left" },
                                    [
                                      _vm.isUpdateRecord &&
                                      _vm.index === props.item.id
                                        ? _c("v-text-field", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required",
                                                expression: "'required'",
                                              },
                                            ],
                                            staticClass: "pt-2",
                                            attrs: {
                                              "data-vv-scope":
                                                "updateRecordFormRef",
                                              "data-vv-name":
                                                "editAlternativeName",
                                              "error-messages":
                                                _vm.validationMessage(
                                                  "editAlternativeName"
                                                ),
                                            },
                                            model: {
                                              value: props.item.alternativeName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  props.item,
                                                  "alternativeName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "props.item.alternativeName",
                                            },
                                          })
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(props.item.alternativeName)
                                            ),
                                          ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-xs-center aling-center-chekbox",
                                    },
                                    [
                                      _vm.isUpdateRecord &&
                                      _vm.index === props.item.id
                                        ? _c(
                                            "span",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { color: "primary" },
                                                model: {
                                                  value:
                                                    props.item.passToThirdParty,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "passToThirdParty",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.passToThirdParty",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : props.item.passToThirdParty
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "secondary",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("group_add")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Pass to third party"),
                                              ]),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { attrs: { color: "secondary" } },
                                            [_vm._v("-")]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "text-xs-right action-col" },
                                    [
                                      _vm.isUpdateRecord &&
                                      _vm.index === props.item.id
                                        ? _c(
                                            "v-flex",
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    flat: "",
                                                                    color:
                                                                      "primary",
                                                                    small: "",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onAddUpdateRecord(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "update"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Update")])]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    flat: "",
                                                                    color:
                                                                      "primary",
                                                                    small: "",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onCancelEditRecord(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "close"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Cancel")])]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-flex",
                                            {},
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    flat: "",
                                                                    color:
                                                                      "primary",
                                                                    small: "",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onEditRecord(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "edit"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Edit")])]
                                              ),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    icon: "",
                                                                    flat: "",
                                                                    color:
                                                                      "primary",
                                                                    small: "",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.onDeleteRecordClick(
                                                                          props.item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "delete"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Delete")])]
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "footer" }, [
                          _c("tr", [
                            _c(
                              "td",
                              { staticClass: "name-col" },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "required",
                                  attrs: {
                                    label: "Name",
                                    required: "",
                                    "data-vv-scope": "addRecordFormRef",
                                    "data-vv-name": "Name",
                                    "error-messages":
                                      _vm.errors.collect("Name"),
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.onAddRecordFocus($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.record.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.record, "name", $$v)
                                    },
                                    expression: "record.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "pl-0" },
                              [
                                _c("v-text-field", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required",
                                      expression: "'required'",
                                    },
                                  ],
                                  staticClass: "required",
                                  attrs: {
                                    label: "Alternative Name",
                                    required: "",
                                    "data-vv-scope": "addRecordFormRef",
                                    "data-vv-name": "AlternativeName",
                                    "error-messages":
                                      _vm.errors.collect("AlternativeName"),
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.onAddRecordFocus($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.record.alternativeName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.record,
                                        "alternativeName",
                                        $$v
                                      )
                                    },
                                    expression: "record.alternativeName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "pr-0 text-xs-center aling-center-chekbox",
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-checkbox",
                                              _vm._g(
                                                {
                                                  attrs: { color: "primary" },
                                                  model: {
                                                    value:
                                                      _vm.record
                                                        .passToThirdParty,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.record,
                                                        "passToThirdParty",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "record.passToThirdParty",
                                                  },
                                                },
                                                on
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [_c("span", [_vm._v("Pass to third party")])]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "text-xs-right action-col" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    flat: "",
                                                    color: "primary",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onAddUpdateRecord(
                                                        null
                                                      )
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [_c("v-icon", [_vm._v("add")])],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [_c("span", [_vm._v("Add")])]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }