var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        [
          !_vm.isUserRoleClientManager
            ? _c(
                "div",
                { staticClass: "text-xs-right policy-import" },
                [
                  _c(
                    "span",
                    { staticClass: "importpolicy" },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Select policy to Import",
                          required: "",
                          items: _vm.policyList,
                          "item-text": "policyBasicDetail.name",
                          "item-value": "policyBasicDetail.policyId",
                          "data-vv-name": "Select policy to Import",
                          "data-vv-scope": _vm.step7formRefPolicyImport,
                          "error-messages": _vm.errors.collect(
                            "Select policy to Import"
                          ),
                        },
                        model: {
                          value: _vm.currentSelectedPolicyIdToCopy,
                          callback: function ($$v) {
                            _vm.currentSelectedPolicyIdToCopy = $$v
                          },
                          expression: "currentSelectedPolicyIdToCopy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-0",
                      attrs: { color: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.openCopyPolicyQuestionConfirmationBox()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("save_alt")]), _vm._v("   Import ")],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "500",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.confirmImportPolicyQuestions,
                callback: function ($$v) {
                  _vm.confirmImportPolicyQuestions = $$v
                },
                expression: "confirmImportPolicyQuestions",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [
                      _vm._v(
                        "Do you want to copy the policy questions from selected policy?"
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3 mt-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.confirmImportPolicyQuestions = false
                            },
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.copyPolicyQuestions(
                                _vm.currentSelectedPolicyIdToCopy
                              )
                            },
                          },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showQuestionOverridePopup,
                callback: function ($$v) {
                  _vm.showQuestionOverridePopup = $$v
                },
                expression: "showQuestionOverridePopup",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Select Question(s) to Override"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showQuestionOverridePopup = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-data-table", {
                        staticClass: "gridView questionListTable",
                        attrs: {
                          headers: _vm.copyQuestion,
                          items: _vm.questionListToOverride,
                          "item-key": "policyQuestionId",
                          "select-all": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function (props) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    attrs: { active: props.selected },
                                    on: {
                                      click: function ($event) {
                                        props.selected = !props.selected
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "td",
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            "input-value": props.selected,
                                            color: "primary",
                                            "hide-details": "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(props.item.overWrittenText)
                                      ),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.entityName)),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedQuestionsToOverride,
                          callback: function ($$v) {
                            _vm.selectedQuestionsToOverride = $$v
                          },
                          expression: "selectedQuestionsToOverride",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3 mt-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closePolicyQuestionCopyPopups.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.overrideAndCloneQuestionList.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Override")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { staticClass: "px-2 lighten-5 grey pa-4", attrs: { xs12: "" } },
        [
          _c(
            "v-expansion-panel",
            { staticClass: "select-options elevation-0" },
            _vm._l(_vm.selectedEntities, function (emergency, index) {
              return _c("EmergencyQuestionGenerator", {
                key: emergency.emergencyId,
                staticClass: "elevation-1 mb-3",
                attrs: {
                  "policy-id": _vm.policyId,
                  "entity-type": emergency.entityType,
                  "insurer-id": emergency.insurerId,
                  "emergency-id": emergency.emergencyId,
                  "display-text": emergency.displayText,
                  "emergency-details": emergency.emergencyDetails,
                  "index-prefix": String(index + 1),
                  "submited-policy-question-list": _vm.localPolicyQuestionList,
                  "is-local": emergency.isLocal,
                  trades: _vm.trades,
                  "job-type": _vm.jobType,
                  "third-party-detail": _vm.thirdPartyDetail,
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "policyQuestionList" },
            [
              _c("EmergencyQuestionList", {
                ref: "questionList",
                attrs: {
                  "policy-id": _vm.policyId,
                  "policy-questions": _vm.localPolicyQuestionList,
                  "is-helpline-policy": _vm.isHelplinePolicy,
                },
                on: { updateEmergencyQuestion: _vm.updateEmergencyQuestion },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }