var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm.datumControlLogData.length > 0
          ? _c(
              "div",
              _vm._l(_vm.datumControlLogData, function (item) {
                return _c(
                  "v-expansion-panel",
                  {
                    key: item.datumId,
                    staticClass: "trialpit-accordion",
                    class: _vm.getClassName(item.status),
                  },
                  [
                    _c(
                      "v-expansion-panel-content",
                      { staticClass: "grey lighten-4 mb-2" },
                      [
                        _c(
                          "h3",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v(_vm._s(item.name))]
                        ),
                        _c(
                          "v-card",
                          { staticClass: "grey lighten-5" },
                          [
                            _c(
                              "v-card-text",
                              [
                                item.datumControlRunLogs
                                  ? _c("v-data-table", {
                                      staticClass:
                                        "gridView gridView-full-width",
                                      attrs: {
                                        headers: _vm.headers,
                                        items: item.datumControlRunLogs,
                                        "hide-actions": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "items",
                                            fn: function (props) {
                                              return [
                                                _c(
                                                  "td",
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: _vm.getPitIds,
                                                      },
                                                      model: {
                                                        value: props.item.pitId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "pitId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.pitId",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-xs-right",
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: "required",
                                                          expression:
                                                            "'required'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        "data-vv-scope":
                                                          "datumControlScope" +
                                                          item.id,
                                                        "data-vv-name":
                                                          "note" +
                                                          props.index +
                                                          item.id,
                                                        "error-messages":
                                                          _vm.validationMessage(
                                                            "note" +
                                                              props.index +
                                                              item.id
                                                          ),
                                                      },
                                                      model: {
                                                        value: props.item.note,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "note",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.note",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "td",
                                                  [
                                                    !_vm.isDatumAbandoned(item)
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-btn",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "mr-0 btn-remove",
                                                                            attrs:
                                                                              {
                                                                                flat: "",
                                                                                icon: "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  return _vm.removeDatumControlRunLogs(
                                                                                    item,
                                                                                    props.item
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  dark: "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "close"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                "Remove Row"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _c("span", { staticClass: "pa-2" }, [
                                      _vm._v("No Data Found"),
                                    ]),
                                _vm.isAllowed(item)
                                  ? _c(
                                      "div",
                                      { staticClass: "text-xs-right mt-2" },
                                      [
                                        !_vm.isDatumAbandoned(item)
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "mr-0 btn-add",
                                                attrs: {
                                                  icon: "",
                                                  color: "primary",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addDatumControlRunLogs(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "white--text",
                                                    attrs: {
                                                      dark: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [_vm._v("add")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        item.datumControlRunLogs.length >= 1
                          ? _c("v-flex", { staticClass: "px-3" }, [
                              item && _vm.isDatumAbandoned(item)
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grey lighten-4 mt-2 mb-3 abandon-detail",
                                    },
                                    [
                                      _c("div", { staticClass: "pb-1" }, [
                                        _c("label", [
                                          _c("b", [_vm._v("Abandon Reason:")]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(item.abandonedReason)),
                                        ]),
                                      ]),
                                      item.abandonedReasonNotes
                                        ? _c("div", [
                                            _c("label", [
                                              _c("b", [
                                                _vm._v("Abandon Note:"),
                                              ]),
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "ml-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item.abandonedReasonNotes
                                                  )
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "text-xs-right" },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "btn-save",
                                          attrs: {
                                            color: "primary",
                                            loading:
                                              _vm.isLoading &&
                                              _vm.updateDatumControlLog.id ==
                                                item.id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onSave(item)
                                            },
                                          },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                      item.status !==
                                      _vm.siJobProductProgress[
                                        _vm.siJobProductProgress.Finished
                                      ]
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "btn-save white--text",
                                              attrs: {
                                                color: "green",
                                                loading:
                                                  _vm.datumCompleteLoading &&
                                                  _vm.updateDatumControlLog
                                                    .id == item.id,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSave(item, true)
                                                },
                                              },
                                            },
                                            [_vm._v(" Complete ")]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "white--text btn-abandonDatums",
                                          attrs: { color: "red" },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.openAbandonReasonDialog(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Abandon ")]
                                      ),
                                    ],
                                    1
                                  ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            )
          : _c("span", [_vm._v("No Data Found")]),
      ]),
      _vm.showAbandonReasonDialog
        ? _c("AbandonSiteDataDialog", {
            ref: "abandonSiteDataDialog",
            attrs: {
              "record-type": _vm.abandonRecordType,
              "abandon-reason-details": _vm.abandonReasonDetails,
            },
            on: {
              abandonReasonSave: _vm.onAbandonReasonSave,
              closeAbandonReasonDialog: function ($event) {
                _vm.showAbandonReasonDialog = false
              },
            },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }