var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    attrs: { label: "Trade", items: _vm.trades },
    on: { change: _vm.onTradeChange },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (data) {
          return [
            _c(
              "v-list-tile-content",
              [
                _c(
                  "v-list-tile-title",
                  { class: { "deleted-item": data.item.deleted } },
                  [_vm._v(" " + _vm._s(data.item.text) + " ")]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedTradeId,
      callback: function ($$v) {
        _vm.selectedTradeId = $$v
      },
      expression: "selectedTradeId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }