var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.deployment
    ? _c(
        "div",
        [
          _c(
            "v-flex",
            { staticClass: "mb-2" },
            [
              _c("AutoDeploymentDetail", {
                attrs: { deployment: _vm.deployment },
              }),
            ],
            1
          ),
          _vm.deployment.contractors.length
            ? _c(
                "v-flex",
                [
                  _c(
                    "v-expansion-panel",
                    { attrs: { value: _vm.accordionIndexToOpen } },
                    _vm._l(
                      _vm.deployment.contractors,
                      function (contractor, index) {
                        return _c(
                          "v-expansion-panel-content",
                          {
                            key: `deployment-${index}`,
                            attrs: {
                              "hide-actions": _vm.isJobStatusNotOffered(
                                contractor.appointmentRequest
                              ),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-flex",
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                "justify-space-between": "",
                                                "align-center": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { column: "" } },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "text-uppercase",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          contractor.contractorName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  !_vm.isJobStatusNotOffered(
                                                    contractor.appointmentRequest
                                                  )
                                                    ? _c("h5", [
                                                        _vm._v(
                                                          _vm._s(index + 1) +
                                                            ". " +
                                                            _vm._s(
                                                              contractor.strategy
                                                            )
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c(
                                                "v-chip",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color:
                                                      contractor.appointmentRequest
                                                        ? _vm
                                                            .deploymentStatusMap[
                                                            contractor
                                                              .appointmentRequest
                                                              .status
                                                          ].color
                                                        : "grey",
                                                    "text-color": "white",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        contractor.appointmentRequest
                                                          ? _vm
                                                              .deploymentStatusMap[
                                                              contractor
                                                                .appointmentRequest
                                                                .status
                                                            ].text
                                                          : "Not Offered"
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            contractor.appointmentRequest &&
                            contractor.appointmentRequest.status !==
                              _vm.deploymentStatusEnum.NotOffered
                              ? _c("AutoDeploymentContractorDetail", {
                                  attrs: {
                                    "contractor-appointed-id":
                                      contractor.contractorId,
                                    "deployment-id": _vm.deploymentId,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    1
                  ),
                ],
                1
              )
            : _c("v-card", { staticClass: "pa-5 text-center" }, [
                _c("h3", [
                  _vm._v(
                    "No contractors found for this area, advising manual deployment"
                  ),
                ]),
              ]),
        ],
        1
      )
    : _c("div", [_vm._v("Deployment Currently Not Running")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }