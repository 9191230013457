var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showDocumentTemplateDialog
    ? _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "900",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showDocumentTemplateDialog,
            callback: function ($$v) {
              _vm.showDocumentTemplateDialog = $$v
            },
            expression: "showDocumentTemplateDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Document Template")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "close-btn",
                      attrs: { icon: "" },
                      on: { click: _vm.closeDocumentTemplateDialog },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog px-4 pt-4" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "pr-2": "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Select template name",
                              maxlength: "100",
                              items: _vm.documentTemplateList,
                              "item-text": "name",
                              "item-value": "templateId",
                              loading: _vm.loadTemplatesInProgress,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onTemplateChange($event)
                              },
                            },
                            model: {
                              value: _vm.selectedDocumentTemplate,
                              callback: function ($$v) {
                                _vm.selectedDocumentTemplate = $$v
                              },
                              expression: "selectedDocumentTemplate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("froala", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              id: "froala",
                              froala: "",
                              "data-vv-scope": "templateformRef",
                              required: true,
                              "error-messages":
                                _vm.ValidateEditor("Document Template"),
                              "data-vv-name": "Document Template",
                              config: _vm.config,
                              tag: "textarea",
                            },
                            model: {
                              value: _vm.templateText,
                              callback: function ($$v) {
                                _vm.templateText = $$v
                              },
                              expression: "templateText",
                            },
                          }),
                          _c("div", { staticClass: "input-group__details" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-group__messages input-group__error input-group--error error--text",
                              },
                              [
                                _vm.ValidateEditor("Document Template")
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ValidateEditor(
                                              "Document Template"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-4" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closeDocumentTemplateDialog.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 saveDocument-btn",
                      attrs: {
                        color: "primary",
                        loading: _vm.generatePDFInProcess,
                        disabled:
                          _vm.generatePDFInProcess ||
                          _vm.selectedDocumentTemplate === -1,
                      },
                      on: { click: _vm.saveDocument },
                    },
                    [_vm._v(" Save As Document ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }