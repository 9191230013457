var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "text-xs-right": "", "mb-2": "", "mr-0": "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-0 upload-btn",
              attrs: { color: "primary" },
              on: { click: _vm.openAddDocumentDialog },
            },
            [_vm._v("Upload Document")]
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "", "grid-view": "", "upload-document-section": "" } },
        [
          _c("v-data-table", {
            staticClass: "elevation-1 uploadedDocument",
            attrs: {
              headers: _vm.headerColumns,
              items: _vm.filteredDocumentList,
              "hide-actions": "",
              "item-key": "id",
              loading: _vm.loading,
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function (props) {
                  return [
                    _c(
                      "tr",
                      {
                        key: _vm.itemKey(props.item),
                        staticClass: "sortableRow",
                      },
                      [
                        _c("td", [_vm._v(_vm._s(props.item.documentName))]),
                        _c(
                          "td",
                          [
                            _vm.index === props.item.documentUrl
                              ? _c("v-select", {
                                  staticClass: "document-list",
                                  attrs: {
                                    items: _vm.getDocumentTypeItems,
                                    label: "Document Type",
                                    "item-text": "description",
                                    "item-value": "id",
                                    "data-vv-scope": "formReference",
                                    "single-line": "",
                                    "hide-details": "",
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.errorMessage = ""
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c("v-list-tile-title", {
                                                      class: data.item.isDeleted
                                                        ? "deleted-item"
                                                        : "",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          data.item.description
                                                        ),
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: props.item.documentTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        props.item,
                                        "documentTypeId",
                                        $$v
                                      )
                                    },
                                    expression: "props.item.documentTypeId",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(props.item.documentType)),
                                ]),
                            _vm.index === props.item.documentUrl
                              ? _c(
                                  "div",
                                  { staticClass: "input-group__details" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group__messages input-group__error input-group--error error--text",
                                      },
                                      [
                                        _vm.typeValidationMessage(
                                          _vm.errorMessage
                                        )
                                          ? _c("span", [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.errorMessage)
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("td", [
                          _vm._v(_vm._s(props.item.uploadedByUserName)),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.getFormattedDate(props.item.uploadedAt))
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-right action-td-col" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.index === props.item.documentUrl
                                            ? _c(
                                                "v-checkbox",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "d-inline-block engineer-checkbox",
                                                    attrs: {
                                                      primary: "",
                                                      "hide-details": "",
                                                      color: "primary",
                                                    },
                                                    model: {
                                                      value:
                                                        props.item
                                                          .availableForEngineer,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "availableForEngineer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.availableForEngineer",
                                                    },
                                                  },
                                                  on
                                                )
                                              )
                                            : _c(
                                                "span",
                                                _vm._g(
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          props.item
                                                            .availableForEngineer,
                                                        expression:
                                                          "props.item.availableForEngineer",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "engineer-icon primary--text",
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticStyle: {
                                                        "enable-background":
                                                          "new 0 0 535.806 535.807",
                                                      },
                                                      attrs: {
                                                        id: "Capa_1",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                        "xmlns:xlink":
                                                          "http://www.w3.org/1999/xlink",
                                                        version: "1.1",
                                                        x: "0px",
                                                        y: "0px",
                                                        viewBox:
                                                          "0 0 535.806 535.807",
                                                        "xml:space": "preserve",
                                                        fill: _vm.getEngineerIconColor,
                                                      },
                                                    },
                                                    [
                                                      _c("g", [
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z",
                                                          },
                                                        }),
                                                        _c("path", {
                                                          attrs: {
                                                            d: "M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z",
                                                          },
                                                        }),
                                                      ]),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                      _c("g"),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Available For Engineer")])]
                            ),
                            _vm.index === props.item.documentUrl
                              ? _c(
                                  "span",
                                  { staticClass: "mr-1" },
                                  [
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: {
                                          left: "",
                                          "close-on-content-click": false,
                                          "nudge-width": 350,
                                          "offset-x": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ma-0 add-note",
                                                        attrs: {
                                                          small: "",
                                                          icon: "",
                                                          flat: props.item.note
                                                            ? false
                                                            : true,
                                                          color: "primary",
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: !props.item
                                                              .note
                                                              ? "primary"
                                                              : "",
                                                          },
                                                        },
                                                        [_vm._v("notes")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "px-2 pb-2" },
                                          [
                                            _c("v-textarea", {
                                              staticClass: "document-note",
                                              attrs: {
                                                label: "Note",
                                                "hide-details": "",
                                              },
                                              model: {
                                                value: props.item.note,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.item,
                                                    "note",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression: "props.item.note",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-tooltip",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: props.item.note,
                                        expression: "props.item.note",
                                      },
                                    ],
                                    staticClass: "hover-menu-content",
                                    attrs: {
                                      left: "",
                                      "content-class": "notes-tooltip-content",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ma-0 show-note-btn",
                                                    attrs: {
                                                      small: "",
                                                      icon: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.show = true
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [_vm._v("notes")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "yellow lighten-4 hover-menu-item text-xs-left",
                                      },
                                      [
                                        _c("h4", { staticClass: "m1-2" }, [
                                          _vm._v("Notes"),
                                        ]),
                                        _c("p", [
                                          _vm._v(_vm._s(props.item.note)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                            _vm.index === props.item.documentUrl
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ma-0 save-btn",
                                                        attrs: {
                                                          icon: "",
                                                          flat: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onUpdateDocumentMetadata(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "md-icon",
                                                        },
                                                        [_vm._v("save")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Save")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "ma-0 cancel-btn",
                                                        attrs: {
                                                          icon: "",
                                                          flat: "",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onCancelEditDocumentDetail(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "md-icon",
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Cancel")])]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-menu",
                                  {
                                    staticClass: "menu-edit",
                                    attrs: { "offset-y": "", left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ma-0 action-btn",
                                                    attrs: {
                                                      icon: "",
                                                      flat: "",
                                                      color: "primary",
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color:
                                                          props.item
                                                            .documentTypeId ===
                                                          _vm.typeOfSIReport
                                                            ? "secondary"
                                                            : "primary",
                                                      },
                                                    },
                                                    [_vm._v(" more_vert ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pa-2 white" },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "ma-0 download-btn",
                                                            attrs: {
                                                              icon: "",
                                                              flat: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.downloadDocument(
                                                                  props.item
                                                                    .documentUrl
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "md-icon",
                                                              attrs: {
                                                                color:
                                                                  props.item
                                                                    .documentTypeId ===
                                                                  _vm.typeOfSIReport
                                                                    ? "secondary"
                                                                    : "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " get_app "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Download")])]
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "ma-0 delete-btn",
                                                            attrs: {
                                                              icon: "",
                                                              flat: "",
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.deleteDocument(
                                                                  props.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "md-icon",
                                                              attrs: {
                                                                color:
                                                                  props.item
                                                                    .documentTypeId ===
                                                                  _vm.typeOfSIReport
                                                                    ? "secondary"
                                                                    : "primary",
                                                              },
                                                            },
                                                            [_vm._v(" delete ")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Delete")])]
                                        ),
                                        props.item.documentTypeId !==
                                        _vm.typeOfSIReport
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                attrs: { top: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ma-0 edit-btn",
                                                                attrs: {
                                                                  icon: "",
                                                                  flat: "",
                                                                  color:
                                                                    "primary",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.onEditDocumentDetail(
                                                                        props.item
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "md-icon",
                                                                },
                                                                [_vm._v("edit")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Edit")])]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.showAddDocumentDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "wizard-dialog v-dialog--scrollable",
                "max-width": "800",
                persistent: "",
              },
              model: {
                value: _vm.showAddDocumentDialog,
                callback: function ($$v) {
                  _vm.showAddDocumentDialog = $$v
                },
                expression: "showAddDocumentDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Upload Documents")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "close-btn",
                          attrs: { icon: "" },
                          on: { click: _vm.closeAddDocumentDialog },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "add-dialog-content scroll-content-dialog pa-0",
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "px-2": "", "py-1": "" } },
                            [
                              _c("UploadDocument", {
                                ref: "uploadDocument",
                                attrs: {
                                  "job-id": _vm.jobId,
                                  "document-types": _vm.getDocumentTypes,
                                  "is-any-documents-uploaded":
                                    _vm.isAnyDocumentsUploaded,
                                  "is-loading": _vm.isDocumentUploadInProgress,
                                },
                                on: {
                                  "update:isAnyDocumentsUploaded": function (
                                    $event
                                  ) {
                                    _vm.isAnyDocumentsUploaded = $event
                                  },
                                  "update:is-any-documents-uploaded": function (
                                    $event
                                  ) {
                                    _vm.isAnyDocumentsUploaded = $event
                                  },
                                  "update:isLoading": function ($event) {
                                    _vm.isDocumentUploadInProgress = $event
                                  },
                                  "update:is-loading": function ($event) {
                                    _vm.isDocumentUploadInProgress = $event
                                  },
                                  documentUploadComplete:
                                    _vm.onDocumentUploadComplete,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "text-xs-right pa-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 upload-document-btn",
                          attrs: {
                            disabled:
                              _vm.isDocumentUploadInProgress ||
                              !_vm.isAnyDocumentsUploaded,
                            loading: _vm.isDocumentUploadInProgress,
                            color: "primary",
                          },
                          on: { click: _vm.uploadDocumentClicked },
                        },
                        [_vm._v(" Upload ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }