var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.complaint.loggedByUserName
    ? _c("span", [
        _vm._v(" Logged by "),
        _c("b", { staticClass: "secondary--text" }, [
          _vm._v(_vm._s(_vm.complaint.loggedByUserName)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }