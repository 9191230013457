var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-card",
                { staticClass: "elevation-1 pa-0" },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { staticClass: "covrage-sidebar" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pl-3": "", "pr-4": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Search by postcode",
                                      "prepend-icon": "search",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.searchInput,
                                      callback: function ($$v) {
                                        _vm.searchInput = $$v
                                      },
                                      expression: "searchInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.outcodesByArea.length === 0
                                ? _c(
                                    "v-flex",
                                    { attrs: { "pr-3": "", "mt-2": "" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "record-not-found" },
                                        [_vm._v("Record not found.")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  directives: [
                                    { name: "bar", rawName: "v-bar" },
                                  ],
                                  attrs: { xs12: "", "px-3": "", "mt-2": "" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "area-list-content pb-3 pr-0",
                                    },
                                    [
                                      _vm.outcodesByArea.length > 0
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              _vm.outcodesByArea,
                                              function (postcode) {
                                                return _c(
                                                  "v-layout",
                                                  {
                                                    key: postcode.id,
                                                    staticClass:
                                                      "checkoutput-row",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "check-outcode",
                                                      },
                                                      [
                                                        _c("v-checkbox", {
                                                          staticClass:
                                                            "que-checkbox",
                                                          attrs: {
                                                            name: "areaCode",
                                                            "hide-details": true,
                                                            value:
                                                              postcode.areaCode,
                                                            label:
                                                              postcode.areaCode +
                                                              " - " +
                                                              postcode.description,
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.onPostalCodeChange()
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedPostcode,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedPostcode =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selectedPostcode",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      [
                                                        _c(
                                                          "v-expansion-panel",
                                                          {
                                                            staticClass:
                                                              "elevation-0",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "collapse-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-expansion-panel-content",
                                                                  {
                                                                    attrs: {
                                                                      lazy: true,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        attrs: {
                                                                          slot: "header",
                                                                        },
                                                                        slot: "header",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "area-checkbox",
                                                                          },
                                                                          [
                                                                            _vm.selectedPostcode.indexOf(
                                                                              postcode.areaCode
                                                                            ) !==
                                                                            -1
                                                                              ? _c(
                                                                                  "a",
                                                                                  {
                                                                                    staticClass:
                                                                                      "tradecount",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " (Trade: " +
                                                                                        _vm._s(
                                                                                          _vm
                                                                                            .getTradeList
                                                                                            .length
                                                                                        ) +
                                                                                        ") "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._l(
                                                                      postcode.outcodes,
                                                                      function (
                                                                        district
                                                                      ) {
                                                                        return _c(
                                                                          "v-card",
                                                                          {
                                                                            key: district,
                                                                            staticClass:
                                                                              "elevation-0",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-card-text",
                                                                              {
                                                                                staticClass:
                                                                                  "pl-4 district-list",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "v-checkbox",
                                                                                      {
                                                                                        staticClass:
                                                                                          "que-checkbox",
                                                                                        attrs:
                                                                                          {
                                                                                            "hide-details": true,
                                                                                            label:
                                                                                              district,
                                                                                            value:
                                                                                              district,
                                                                                            color:
                                                                                              "primary",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onDistrictChange(
                                                                                                _vm.selectedDistrict,
                                                                                                district,
                                                                                                postcode.areaCode
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                        model:
                                                                                          {
                                                                                            value:
                                                                                              _vm.selectedDistrict,
                                                                                            callback:
                                                                                              function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.selectedDistrict =
                                                                                                  $$v
                                                                                              },
                                                                                            expression:
                                                                                              "selectedDistrict",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                    _vm.selectedDistrict.indexOf(
                                                                                      district
                                                                                    ) !==
                                                                                    -1
                                                                                      ? _c(
                                                                                          "a",
                                                                                          {
                                                                                            staticClass:
                                                                                              "tradecount",
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.onTradeCountClick(
                                                                                                    district,
                                                                                                    postcode.areaCode
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " (Trade: " +
                                                                                                _vm._s(
                                                                                                  _vm.getTradeCount(
                                                                                                    district
                                                                                                  )
                                                                                                ) +
                                                                                                ") "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "covrage-mapsection" },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "show-map-cn inblock",
                              attrs: { wrap: "", row: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "map-content",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "map-card elevation-0",
                                      class: _vm.showTradelist
                                        ? "show-availability-job"
                                        : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "map-section-content" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.showFullScreenMap,
                                                  expression:
                                                    "!showFullScreenMap",
                                                },
                                              ],
                                              ref: "fullScreenIcon",
                                              staticClass: "fullScreenIcon",
                                              class: _vm.showFullScreenMap
                                                ? "fullScreenIconFixedStyle"
                                                : "",
                                              on: {
                                                click: _vm.toggleFullScreenView,
                                              },
                                            },
                                            [_vm._v(" fullscreen ")]
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showFullScreenMap,
                                                  expression:
                                                    "showFullScreenMap",
                                                },
                                              ],
                                              ref: "fullScreenIcon",
                                              staticClass: "fullScreenIcon",
                                              class: _vm.showFullScreenMap
                                                ? "fullScreenIconFixedStyle"
                                                : "",
                                              on: {
                                                click: _vm.toggleFullScreenView,
                                              },
                                            },
                                            [_vm._v(" fullscreen_exit ")]
                                          ),
                                          _c("div", { staticClass: "map" }, [
                                            _c(
                                              "div",
                                              { staticClass: "map-area" },
                                              [
                                                _c(
                                                  "v-layout",
                                                  {
                                                    staticClass: "map-toolbar",
                                                    attrs: {
                                                      row: "",
                                                      wrap: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        staticClass:
                                                          "search-lc pt-2 pr-3",
                                                        attrs: {
                                                          xs12: "",
                                                          "pl-2": "",
                                                          "pr-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            id: "searchTextField",
                                                            "hide-details": "",
                                                            "prepend-icon":
                                                              "place",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.autocompleteValue,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.autocompleteValue =
                                                                $$v
                                                            },
                                                            expression:
                                                              "autocompleteValue",
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "fl-polygon-btn",
                                                          },
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                class:
                                                                                  _vm.drawPolygon
                                                                                    ? "btn polygon-btn Activeoption"
                                                                                    : "btn polygon-btn",
                                                                                attrs:
                                                                                  {
                                                                                    flat: "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.drawPolygonOnMap,
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src: "/img/polygon.svg",
                                                                                      alt: "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Draw Polygon"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u([
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                class:
                                                                                  _vm.drawCircle
                                                                                    ? "btn circle-btn Activeoption"
                                                                                    : "btn circle-btn",
                                                                                attrs:
                                                                                  {
                                                                                    flat: "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.drawCircleOnMap,
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "img",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      src: "/img/circle.svg",
                                                                                      alt: "",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ]),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Draw Circle"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      row: "",
                                                      wrap: "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c("div", {
                                                          ref: "mapView",
                                                          attrs: {
                                                            id: "customMap",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "availability-job" },
                                        [
                                          _c(
                                            "v-card-title",
                                            { staticClass: "py-3 px-2" },
                                            [
                                              _c(
                                                "h3",
                                                {
                                                  staticClass:
                                                    "grey--text text--darken-1 d-inline-block",
                                                },
                                                [
                                                  _vm._v(" Trade "),
                                                  _vm.selectedDistrictName
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "(" +
                                                            _vm._s(
                                                              _vm.selectedDistrictName
                                                            ) +
                                                            ")"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "back-btn",
                                                  attrs: {
                                                    icon: "",
                                                    flat: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.hideTradeList()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("arrow_forward"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _vm.getTradeList.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "bar",
                                                      rawName: "v-bar",
                                                      value: {
                                                        useScrollbarPseudo: true,
                                                      },
                                                      expression:
                                                        "{ useScrollbarPseudo: true }",
                                                    },
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "job-availability-content pl-2",
                                                    },
                                                    [
                                                      _c("v-checkbox", {
                                                        staticClass:
                                                          "que-checkbox",
                                                        attrs: {
                                                          "hide-details": true,
                                                          label: "Select All",
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.onSelectAllTradeList,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.selectAllTradeList,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.selectAllTradeList =
                                                              $$v
                                                          },
                                                          expression:
                                                            "selectAllTradeList",
                                                        },
                                                      }),
                                                      _vm._l(
                                                        _vm.getTradeList,
                                                        function (trade) {
                                                          return _c(
                                                            "v-checkbox",
                                                            {
                                                              key: trade.tradeId,
                                                              staticClass:
                                                                "que-checkbox",
                                                              class:
                                                                trade.isDeleted
                                                                  ? "deleted-item"
                                                                  : "",
                                                              attrs: {
                                                                "hide-details": true,
                                                                label:
                                                                  trade.description,
                                                                value: trade,
                                                                color:
                                                                  "primary",
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.onTradeSelectionChange(
                                                                      $event,
                                                                      trade
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectedTradeList,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.selectedTradeList =
                                                                      $$v
                                                                  },
                                                                expression:
                                                                  "selectedTradeList",
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "text-xs-right": "",
                                    "mb-1": "",
                                    "mt-1": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3",
                                      attrs: {
                                        color: "primary white--text",
                                        disabled: _vm.isLoading,
                                        loading: _vm.isLoading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.save.apply(null, arguments)
                                        },
                                      },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-snackbar",
                                {
                                  attrs: {
                                    timeout: _vm.saveSnackbarTimeout,
                                    bottom: true,
                                    left: true,
                                  },
                                  model: {
                                    value: _vm.saveSnackbar,
                                    callback: function ($$v) {
                                      _vm.saveSnackbar = $$v
                                    },
                                    expression: "saveSnackbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.saveSnackbarText) + " "
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "", color: "secondary" },
                                      nativeOn: {
                                        click: function ($event) {
                                          _vm.saveSnackbar = false
                                        },
                                      },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.mapCircleDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.mapCircleDialog,
                callback: function ($$v) {
                  _vm.mapCircleDialog = $$v
                },
                expression: "mapCircleDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pl-3 pr-1 pb-0" }, [
                    _c("h4", [_vm._v("Enter Radius in KM:")]),
                  ]),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c("v-text-field", {
                        attrs: { "hide-details": true },
                        model: {
                          value: _vm.circleRadius,
                          callback: function ($$v) {
                            _vm.circleRadius = $$v
                          },
                          expression: "circleRadius",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-2" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", small: "", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.mapCircleDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.circleRadius <= 0,
                          },
                          on: { click: _vm.drawCircleWithRadius },
                        },
                        [_vm._v("Draw Circle")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }