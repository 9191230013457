var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.productData
    ? _c(
        "v-card",
        { staticClass: "mb-3 monitoring-product-card elevation-2" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "h3",
                [
                  _vm._v(" " + _vm._s(_vm.productTitle) + " - "),
                  _c("UpdateProductPriceDialog", {
                    ref: "updateProductPriceDialogRef",
                    attrs: {
                      "price-label": `£${_vm.productData.basePrice}`,
                      "is-price-updated": _vm.productData.isPriceUpdated,
                      "product-price": _vm.productBasePrice,
                      "is-disabled": _vm.disabled,
                    },
                    on: {
                      "update:isPriceUpdated": function ($event) {
                        return _vm.$set(
                          _vm.productData,
                          "isPriceUpdated",
                          $event
                        )
                      },
                      "update:is-price-updated": function ($event) {
                        return _vm.$set(
                          _vm.productData,
                          "isPriceUpdated",
                          $event
                        )
                      },
                      "update:productPrice": function ($event) {
                        _vm.productBasePrice = $event
                      },
                      "update:product-price": function ($event) {
                        _vm.productBasePrice = $event
                      },
                      editProductPrice: () => {
                        _vm.openEditProductPriceDialog(_vm.productData)
                      },
                      productPriceUpdated: _vm.onProductPriceUpdated,
                    },
                  }),
                ],
                1
              ),
              _c("v-spacer"),
              !_vm.disabled
                ? _c(
                    "v-btn",
                    { attrs: { icon: "" }, on: { click: _vm.deleteProduct } },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "px-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                _vm._l(
                  _vm.productAttributes,
                  function (productAttribute, index) {
                    return _c(
                      "v-flex",
                      { key: index, staticClass: "px-3", attrs: { xs6: "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            id: productAttribute.attributeName,
                            label: productAttribute.displayName,
                            type: "number",
                            "error-messages": _vm.$validator.errors.collect(
                              productAttribute.displayName
                            ),
                            "data-vv-name": productAttribute.displayName,
                            disabled:
                              _vm.disabled || productAttribute.isDisabled,
                            required: "",
                          },
                          model: {
                            value:
                              _vm.productData.productAttributes[index]
                                .attributeValue,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.productData.productAttributes[index],
                                "attributeValue",
                                $$v
                              )
                            },
                            expression:
                              "productData.productAttributes[index].attributeValue",
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
          _vm.productChildLinks.length
            ? _c(
                "v-container",
                { staticClass: "pa-3 test-block" },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("h4", { staticClass: "pb-2" }, [_vm._v("Extras:")]),
                      _vm._l(_vm.productChildLinks, function (product) {
                        return _c(
                          "v-flex",
                          { key: product.name },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "", "pb-2": "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "", xs12: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs8: "" } },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-switch",
                                                            _vm._g(
                                                              {
                                                                ref: "productChild",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "pt-1 switch-toggle",
                                                                attrs: {
                                                                  "input-value":
                                                                    _vm.isChildSelected(
                                                                      product
                                                                    ),
                                                                  color:
                                                                    "primary",
                                                                  label:
                                                                    product.name,
                                                                  disabled:
                                                                    _vm.disabled,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.toggleChildProduct(
                                                                        product
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(product.description)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          {
                                            attrs: {
                                              xs4: "",
                                              "px-4": "",
                                              "mt-1": "",
                                            },
                                          },
                                          [
                                            _c("UpdateProductPriceDialog", {
                                              attrs: {
                                                "price-label": `£${product.basePrice}`,
                                                "is-price-updated":
                                                  product.isPriceUpdated,
                                                "product-price":
                                                  _vm.productBasePrice,
                                                "is-disabled":
                                                  _vm.disabled ||
                                                  !_vm.isChildSelected(product),
                                              },
                                              on: {
                                                "update:isPriceUpdated":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      product,
                                                      "isPriceUpdated",
                                                      $event
                                                    )
                                                  },
                                                "update:is-price-updated":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      product,
                                                      "isPriceUpdated",
                                                      $event
                                                    )
                                                  },
                                                "update:productPrice":
                                                  function ($event) {
                                                    _vm.productBasePrice =
                                                      $event
                                                  },
                                                "update:product-price":
                                                  function ($event) {
                                                    _vm.productBasePrice =
                                                      $event
                                                  },
                                                editProductPrice: () => {
                                                  _vm.openEditProductPriceDialog(
                                                    product,
                                                    true
                                                  )
                                                },
                                                productPriceUpdated:
                                                  _vm.onProductPriceUpdated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.isChildSelected(product)
                                  ? _c(
                                      "v-flex",
                                      {
                                        ref: product.id + ":data",
                                        refInFor: true,
                                        attrs: { xs6: "" },
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "", xs12: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs6: "", "px-3": "" },
                                              },
                                              [
                                                _c("v-text-field", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: `required|min_value:1`,
                                                      expression:
                                                        "`required|min_value:1`",
                                                    },
                                                  ],
                                                  staticClass: "required",
                                                  attrs: {
                                                    label: "Quantity",
                                                    "error-messages":
                                                      _vm.$validator.errors.collect(
                                                        product.name
                                                      ),
                                                    "data-vv-name":
                                                      product.name,
                                                    type: "number",
                                                    hint: product.unit,
                                                    disabled: _vm.disabled,
                                                    required: "",
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      return _vm.updateChild(
                                                        product
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: product.quantity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        product,
                                                        "quantity",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "product.quantity",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }