var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-2" }, [
        _c("h4", [
          _vm._v(
            _vm._s(_vm.isDocumentMetadataUpdated ? "Update" : "Uploaded") +
              " Job Document"
          ),
        ]),
      ]),
      _vm.isDocumentMetadataUpdated
        ? _c("div", [
            _vm.items.documentName
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Document Name: ")]),
                  _c("span", [_vm._v(_vm._s(_vm.items.documentName))]),
                ])
              : _vm._e(),
            _vm.items.documentType
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Document Type: ")]),
                  _c("span", [_vm._v(_vm._s(_vm.items.documentType))]),
                ])
              : _vm._e(),
            _vm.items.note
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Note: ")]),
                  _c("span", [_vm._v(_vm._s(_vm.items.note))]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Available For Engineer: ")]),
              _c("span", [
                _vm._v(_vm._s(_vm.items.availableForEngineer ? "Yes" : "No")),
              ]),
            ]),
          ])
        : _vm._l(_vm.items.documents, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                item.documentName
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Document Name: ")]),
                      _c("span", [_vm._v(_vm._s(item.documentName))]),
                    ])
                  : _vm._e(),
                item.documentType
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Document Type: ")]),
                      _c("span", [_vm._v(_vm._s(item.documentType))]),
                    ])
                  : _vm._e(),
                item.note
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Note: ")]),
                      _c("span", [_vm._v(_vm._s(item.note))]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Available For Engineer: ")]),
                  _c("span", [
                    _vm._v(_vm._s(item.availableForEngineer ? "Yes" : "No")),
                  ]),
                ]),
                item.uploadedByUserName
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Uploaded By: ")]),
                      _c("span", [_vm._v(_vm._s(item.uploadedByUserName))]),
                    ])
                  : _vm._e(),
                _vm.getFormattedDate(item.uploadedAt)
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Uploaded At: ")]),
                      _c("span", [
                        _vm._v(_vm._s(_vm.getFormattedDate(item.uploadedAt))),
                      ]),
                    ])
                  : _vm._e(),
                _c("v-divider", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index !== _vm.items.documents.length - 1,
                      expression: "index !== items.documents.length - 1",
                    },
                  ],
                  staticClass: "mb-1",
                }),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }