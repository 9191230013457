var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    {
      staticClass: "wrapper",
      attrs: { row: "", "fill-height": "", "pa-2": "" },
    },
    [
      _c(
        "v-flex",
        { attrs: { xs6: "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "font-weight-bold", attrs: { xs3: "" } },
                [_vm._v(" Postcode:")]
              ),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _vm._v(" " + _vm._s(_vm.deployment.postcode) + " "),
              ]),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "font-weight-bold", attrs: { xs3: "" } },
                [_vm._v("Trade:")]
              ),
              _c("v-flex", { attrs: { xs6: "" } }, [
                _vm._v(" " + _vm._s(_vm.deployment.trade || "Not Set") + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-flex", { attrs: { xs6: "" } }, [
        _c(
          "div",
          { staticClass: "pa-2" },
          [
            _vm.googleMapOptions
              ? _c("InteractiveMap", {
                  attrs: { "map-options": _vm.googleMapOptions },
                })
              : _c("h2", [_vm._v("Unable to load map")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }