var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "pr-2": "", xs6: "" } },
            [
              _c("v-autocomplete", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required materialList",
                attrs: {
                  items: _vm.materialList,
                  multiple: "",
                  label: "Selected Material",
                  "item-text": "name",
                  "item-value": "name",
                  required: "",
                  "data-vv-name": "Material Name",
                  "error-messages": _vm.errors.collect("Material Name"),
                  "return-object": "",
                },
                model: {
                  value: _vm.value.additionalMaterialRequest,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "additionalMaterialRequest", $$v)
                  },
                  expression: "value.additionalMaterialRequest",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "pl-2": "", xs6: "" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  type: "Number",
                  name: "price",
                  label: "Price",
                  min: "1",
                  required: "",
                  "data-vv-name": "Material Price",
                  "error-messages": _vm.errors.collect("Material Price"),
                },
                model: {
                  value: _vm.value.materialCost,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "materialCost", $$v)
                  },
                  expression: "value.materialCost",
                },
              }),
            ],
            1
          ),
          _vm.showAdditionalInfoField()
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Additional Information",
                      required: "",
                      "data-vv-name": "Additional Information",
                      "error-messages": _vm.validationMessage(
                        "Additional Information"
                      ),
                      name: "AdditionalInfoField",
                    },
                    model: {
                      value: _vm.value.additionalInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "additionalInfo", $$v)
                      },
                      expression: "value.additionalInfo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  name: "description",
                  label: "Further Information",
                  required: "",
                  "data-vv-name": "Further Information",
                  "error-messages": _vm.validationMessage(
                    "Further Information"
                  ),
                },
                model: {
                  value: _vm.value.furtherInfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "furtherInfo", $$v)
                  },
                  expression: "value.furtherInfo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }