var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.worker.activityName !== "DisplayOfflineUsers"
      ? _c(
          "div",
          { staticClass: "dashboard-worker", class: _vm.dynamicClass },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "span",
                            _vm._g(
                              {
                                class:
                                  _vm.worker.activityName === "Busy"
                                    ? "oncall-person"
                                    : "",
                              },
                              on
                            ),
                            [
                              _c("img", {
                                attrs: {
                                  src: _vm.imagePath,
                                  alt: _vm.worker.friendlyName,
                                },
                              }),
                              _vm.worker.activityName === "Busy"
                                ? _c(
                                    "span",
                                    { staticClass: "call-icon" },
                                    [_c("v-icon", [_vm._v("call")])],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2988325831
                ),
              },
              [_c("span", [_vm._v(_vm._s(_vm.worker.friendlyName))])]
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.showAllWorker && _vm.worker.activityName === "DisplayOfflineUsers"
      ? _c(
          "div",
          {
            staticClass: "teaminfo b-secondory",
            on: { click: _vm.showOfflineWorkers },
          },
          [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "span",
                            _vm._g({ staticClass: "offline-user" }, on),
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "showoffline-user text-xs-center",
                                },
                                [_c("v-icon", [_vm._v("person_add")])],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1282326892
                ),
              },
              [_c("span", [_vm._v("Show Offline Users")])]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }