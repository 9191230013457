var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.getCustomerContactPreferenceModal &&
      _vm.getCustomerContactPreferenceModal != undefined
        ? _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("ContactDetails", {
                    ref: "contactDetails",
                    attrs: {
                      "contact-preference-id":
                        _vm.getCustomerContactPreferenceModal
                          .contactPreferenceId,
                      "mobile-phone":
                        _vm.getCustomerContactPreferenceModal.mobilePhone,
                      email: _vm.getCustomerContactPreferenceModal.email,
                      "is-nominee": false,
                      "preferred-mode-title":
                        "Choose preferred mode of contact",
                      "reason-for-call-option":
                        _vm.getCustomerContactPreferenceModal
                          .reasonForChoosingCallOption,
                    },
                    on: {
                      updateContactPreference: _vm.updateContactPreference,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-layout", { attrs: { wrap: "" } }, [
                    _c(
                      "div",
                      { staticClass: "nominee-content" },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "text-xs-right": "" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-0 ml-0",
                                class:
                                  _vm.getCustomerContactPreferenceModal
                                    .contactPreferenceId ===
                                  _vm.contactPreference.Call
                                    ? "call-nominee-btn"
                                    : "nominee-btn",
                                attrs: {
                                  flat: "",
                                  color: "primary",
                                  small: "",
                                },
                                on: { click: _vm.addNominee },
                              },
                              [
                                _c(
                                  "span",
                                  { class: _vm.hasNominee ? "hide" : "show" },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("add"),
                                    ]),
                                    _vm._v(" Add nominee "),
                                  ],
                                  1
                                ),
                                _c(
                                  "span",
                                  { class: _vm.hasNominee ? "show" : "hide" },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("close"),
                                    ]),
                                    _vm._v(" Remove nominee "),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.hasNominee
                          ? _c(
                              "v-flex",
                              {
                                class:
                                  _vm.getCustomerContactPreferenceModal
                                    .contactPreferenceId ===
                                  _vm.contactPreference.Call
                                    ? "mt-4"
                                    : "",
                                attrs: { xs12: "" },
                              },
                              [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass:
                                      "grey pa-3 lighten-4 elevation-1 nomineeDiv",
                                    attrs: { wrap: "" },
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs3: "",
                                          "pr-2": "",
                                          "py-0": "",
                                        },
                                      },
                                      [
                                        _c("v-combobox", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            label: "Title",
                                            maxlength: "20",
                                            "return-object": "",
                                            items: _vm.clientTitleList,
                                            required: "",
                                            name: "Title",
                                            "data-vv-scope":
                                              _vm.contactPreferenceScope,
                                            "data-vv-name": "Title",
                                            "error-messages":
                                              _vm.errors.collect("Title"),
                                          },
                                          model: {
                                            value:
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .nomineeClientTitle,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.getCustomerContactPreferenceModal,
                                                "nomineeClientTitle",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "getCustomerContactPreferenceModal.nomineeClientTitle",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs5: "",
                                          "px-2": "",
                                          "py-0": "",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            label: "First Name",
                                            name: "FirstName",
                                            maxlength: "50",
                                            "data-vv-scope":
                                              _vm.contactPreferenceScope,
                                            required: "",
                                            "data-vv-name": "First Name",
                                            "error-messages":
                                              _vm.errors.collect("First Name"),
                                          },
                                          on: {
                                            blur: _vm.updateNomineeDetails,
                                          },
                                          model: {
                                            value: _vm.nomineeClientForename,
                                            callback: function ($$v) {
                                              _vm.nomineeClientForename =
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                            },
                                            expression: "nomineeClientForename",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: {
                                          xs4: "",
                                          "py-0": "",
                                          "pl-2": "",
                                        },
                                      },
                                      [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            label: "Last Name",
                                            name: "LastName",
                                            maxlength: "50",
                                            "data-vv-scope":
                                              _vm.contactPreferenceScope,
                                            required: "",
                                            "data-vv-name": "Last Name",
                                            "error-messages":
                                              _vm.errors.collect("Last Name"),
                                          },
                                          on: {
                                            blur: _vm.updateNomineeDetails,
                                          },
                                          model: {
                                            value: _vm.nomineeClientLastName,
                                            callback: function ($$v) {
                                              _vm.nomineeClientLastName =
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                            },
                                            expression: "nomineeClientLastName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "py-0": "" } },
                                      [
                                        _c("v-autocomplete", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            label: "Relation",
                                            maxlength: "20",
                                            items: _vm.isOnline
                                              ? _vm.nomineeRelationList
                                              : _vm.getNomineeRelationOffline,
                                            "item-value": "id",
                                            "item-text": "description",
                                            "data-vv-scope":
                                              _vm.contactPreferenceScope,
                                            "data-vv-name": "Relation",
                                            "error-messages":
                                              _vm.errors.collect("Relation"),
                                            loading: _vm.isLoadingRelations,
                                          },
                                          on: {
                                            change: _vm.onChangeNomineeRelation,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "v-list-tile-content",
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          {
                                                            staticClass:
                                                              "nomineeRelation",
                                                            class:
                                                              data.item
                                                                .description ===
                                                              "Configure"
                                                                ? "bold-select"
                                                                : "",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.item
                                                                    .description
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1354215086
                                          ),
                                          model: {
                                            value:
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .relation,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.getCustomerContactPreferenceModal,
                                                "relation",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "getCustomerContactPreferenceModal.relation",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("ContactDetails", {
                                          ref: "nomineeContactDetails",
                                          attrs: {
                                            "contact-preference-id":
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .nomineeContactPreferenceId,
                                            "mobile-phone":
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .nomineeMobilePhone,
                                            email:
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .nomineeEmail,
                                            "is-nominee": true,
                                            "preferred-mode-title":
                                              "Choose preferred mode of nominee contact",
                                            "reason-for-call-option":
                                              _vm
                                                .getCustomerContactPreferenceModal
                                                .nomineeReasonForChoosingCallOption,
                                          },
                                          on: {
                                            "update:contactPreferenceId":
                                              function ($event) {
                                                return _vm.$set(
                                                  _vm.getCustomerContactPreferenceModal,
                                                  "nomineeContactPreferenceId",
                                                  $event
                                                )
                                              },
                                            "update:contact-preference-id":
                                              function ($event) {
                                                return _vm.$set(
                                                  _vm.getCustomerContactPreferenceModal,
                                                  "nomineeContactPreferenceId",
                                                  $event
                                                )
                                              },
                                            "update:mobilePhone": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.getCustomerContactPreferenceModal,
                                                "nomineeMobilePhone",
                                                $event
                                              )
                                            },
                                            "update:mobile-phone": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                _vm.getCustomerContactPreferenceModal,
                                                "nomineeMobilePhone",
                                                $event
                                              )
                                            },
                                            "update:email": function ($event) {
                                              return _vm.$set(
                                                _vm.getCustomerContactPreferenceModal,
                                                "nomineeEmail",
                                                $event
                                              )
                                            },
                                            updateContactPreference:
                                              _vm.updateContactPreference,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                ref: "configureDropdown",
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }