var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: _vm.tableName } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "grid-view": "" } },
        [
          _c("v-flex", { attrs: { xs7: "", "request-info": "" } }, [
            _c("h3", [_vm._v("Incident Manager")]),
          ]),
          _vm.isUserRoleAdministrator
            ? _c("v-flex", { attrs: { xs5: "", "pl-3": "" } }, [
                _c(
                  "div",
                  { staticClass: "search-supervisor" },
                  [
                    _c("v-autocomplete", {
                      attrs: {
                        items: _vm.incidentManagerList,
                        "item-text": "displayValue",
                        "item-value": "id",
                        label: "Incident Manager",
                        "return-object": "",
                        loading: _vm.isLoading,
                        "hide-details": "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onIncidentManagerChange($event)
                        },
                      },
                      model: {
                        value: _vm.selectedIncidentManager,
                        callback: function ($$v) {
                          _vm.selectedIncidentManager = $$v
                        },
                        expression: "selectedIncidentManager",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("v-flex", { attrs: { "mt-2": "", "elevation-1": "" } }, [
            _c(
              "div",
              { staticClass: "incident-manager-list" },
              [
                _c("v-data-table", {
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.incidentManagerData,
                    "item-key": "name",
                    "hide-actions": "",
                    search: _vm.search,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function (props) {
                        return [
                          _c(
                            "tr",
                            {
                              class: _vm.setClassForSelectedIM(props.item.name),
                              attrs: { active: props.selected },
                              on: {
                                click: function ($event) {
                                  props.selected = !props.selected
                                },
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "text-center font-weight-bold secondary--text",
                                },
                                [_vm._v(" " + _vm._s(props.item.rank) + " ")]
                              ),
                              _c(
                                "td",
                                { class: _vm.setBorderClass(props.item.id) },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: _vm.getImageUrl(props.item.id),
                                      alt: "",
                                    },
                                  }),
                                ]
                              ),
                              _c("td", [_vm._v(_vm._s(props.item.name))]),
                              _c("td", [_vm._v(_vm._s(props.item.teamName))]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(props.item.taskAnswered)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(props.item.taskMissed)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(_vm._s(props.item.jobLogged)),
                              ]),
                              _c("td", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(props.item.complimentsReceived) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }