var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.getIsThirdPartyContractor
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("h4", [_vm._v("Warm Transfer Completed.")]),
          ])
        : _vm.getAssignedContractor &&
          _vm.getAssignedContractor.status ===
            _vm.jobVisitStatus[_vm.jobVisitStatus.AutoDeploying]
        ? _c("v-flex", [
            _c("b", [_vm._v("Auto deployment of engineers is running")]),
          ])
        : _c("v-flex", { attrs: { xs12: "" } }, [
            _c("div", { staticClass: "mb-1" }, [
              _c("b", [_vm._v("ETA From:")]),
              _vm._v(" " + _vm._s(_vm.getContractorEtaFrom) + " "),
            ]),
            _c("div", { staticClass: "mb-1" }, [
              _c("b", [_vm._v("ETA To:")]),
              _vm._v(" " + _vm._s(_vm.getContractorEtaTo) + " "),
            ]),
            _vm.getContractorAdditionalTravelTime
              ? _c("div", { staticClass: "mb-1" }, [
                  _vm._v(" Additional Travel Time: "),
                  _c("b", { staticClass: "secondary--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getContractorAdditionalTravelTime) +
                        " (£ " +
                        _vm._s(_vm.getContractorAdditionalPay) +
                        ") "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.getContractorReason
              ? _c("div", [
                  _c("b", [_vm._v("Reason")]),
                  _vm._v(" : " + _vm._s(_vm.getContractorReason) + " "),
                ])
              : _vm._e(),
            _vm.getDelayCode
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("b", [_vm._v("DelayCode:")]),
                  _vm._v(" " + _vm._s(_vm.getDelayCode) + " "),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }