var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.smsId
    ? _c("div", [
        _vm._m(0),
        _vm.jobSmsText
          ? _c("div", [
              _vm.jobSmsText.to
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Phone Number: ")]),
                    _c("span", [_vm._v(_vm._s(_vm.jobSmsText.to))]),
                  ])
                : _vm._e(),
              _vm.jobSmsText.body
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Text: ")]),
                    _c("span", [_vm._v(_vm._s(_vm.jobSmsText.body))]),
                  ])
                : _vm._e(),
              _vm.jobSmsText.status
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Status: ")]),
                    _c("span", [_vm._v(_vm._s(_vm.jobSmsText.status))]),
                  ])
                : _vm._e(),
              _vm.jobSmsText.dateSent
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Received at: ")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.getFormattedDate(_vm.jobSmsText.dateSent))
                      ),
                    ]),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Incoming Job Sms")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }