var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { bottom: "", color: _vm.snackbarColor, timeout: 60000 },
      model: {
        value: _vm.showSnackbar,
        callback: function ($$v) {
          _vm.showSnackbar = $$v
        },
        expression: "showSnackbar",
      },
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _c(
        "v-btn",
        {
          attrs: { flat: "", color: "white" },
          nativeOn: {
            click: function ($event) {
              return _vm.snackbarClicked.apply(null, arguments)
            },
          },
        },
        [_vm._v("Refresh")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }