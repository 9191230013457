var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-1" },
                [
                  _c("v-card-title", [_c("h3", [_vm._v("Monitoring Detail")])]),
                  _c(
                    "v-card-text",
                    { staticClass: "px-0 py-2" },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "px-2", attrs: { wrap: "", xs12: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "px-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: `required|min_value:0`,
                                    expression: "`required|min_value:0`",
                                  },
                                ],
                                staticClass: "required plannedVisitCount",
                                attrs: {
                                  label: "Planned Visit Count",
                                  name: "plannedVisitCount",
                                  "data-vv-scope": _vm.changeFormref,
                                  required: "",
                                  "data-vv-name": "plannedVisitCount",
                                  type: "number",
                                  "error-messages":
                                    _vm.validationMessage("plannedVisitCount"),
                                  disabled:
                                    _vm.isMonitoringDetail || _vm.loading,
                                },
                                model: {
                                  value: _vm.monitoringDetail.plannedVisitCount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.monitoringDetail,
                                      "plannedVisitCount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "monitoringDetail.plannedVisitCount",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "px-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: `required|min_value:0`,
                                    expression: "`required|min_value:0`",
                                  },
                                ],
                                staticClass: "required visitFrequency",
                                attrs: {
                                  label: "Visit Frequency",
                                  name: "visitFrequency",
                                  "data-vv-scope": _vm.changeFormref,
                                  required: "",
                                  "data-vv-name": "visitFrequency",
                                  type: "number",
                                  "error-messages":
                                    _vm.validationMessage("visitFrequency"),
                                  disabled:
                                    _vm.isMonitoringDetail || _vm.loading,
                                },
                                model: {
                                  value: _vm.monitoringDetail.visitFrequency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.monitoringDetail,
                                      "visitFrequency",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "monitoringDetail.visitFrequency",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isMonitoringDetail,
                              expression: "!isMonitoringDetail",
                            },
                          ],
                          staticClass: "btnCancel",
                          attrs: { color: "", disabled: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.cancelMonitoringDetail()
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isMonitoringDetail,
                              expression: "!isMonitoringDetail",
                            },
                          ],
                          staticClass: "btnSave",
                          attrs: {
                            solid: "",
                            color: "primary",
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addMonitoringDetail()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isMonitoringDetail,
                              expression: "isMonitoringDetail",
                            },
                          ],
                          staticClass: "btnEdit",
                          attrs: {
                            solid: "",
                            color: "primary",
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.editMonitoringDetail()
                            },
                          },
                        },
                        [_vm._v(" Edit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", "mt-3": "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-1" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "py-0" },
                    [
                      _c("h3", [_vm._v("Monitoring Visit Logs")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-xs-right add-record pr-0",
                          attrs: {
                            flat: "",
                            solid: "",
                            color: "primary",
                            disabled: _vm.isActionButtonDisabled(),
                          },
                          on: { click: _vm.addVisitLog },
                        },
                        [_vm._v(" Add Visit "), _c("v-icon", [_vm._v("add")])],
                        1
                      ),
                      _vm.monitoringVisitLogs.length > 0
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "text-xs-right visit-invoice pr-0",
                              attrs: {
                                flat: "",
                                solid: "",
                                color: "primary",
                                disabled: _vm.isActionButtonDisabled(),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showVisitInvoiceDialog = true
                                },
                              },
                            },
                            [
                              _vm._v(" Visit Invoice "),
                              _c("v-icon", { staticClass: "pl-1" }, [
                                _vm._v("receipt"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "pt-1": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "grid-view": "",
                                "monitor-product-section": "",
                              },
                            },
                            [
                              _c("MonitoringVisitLogs", {
                                ref: "monitoringVisitsLogRef",
                                attrs: {
                                  "job-id": _vm.jobId,
                                  "monitoring-visit-logs":
                                    _vm.monitoringVisitLogs,
                                  loading: _vm.loading,
                                },
                                on: {
                                  saveMonitoringVisitLog:
                                    _vm.saveMonitoringVisitLog,
                                  deleteMonitoringVisitLog:
                                    _vm.deleteMonitoringVisitLog,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-snackbar",
                            {
                              attrs: {
                                timeout: _vm.snackbarTimeout,
                                bottom: true,
                                left: true,
                              },
                              model: {
                                value: _vm.snackbar,
                                callback: function ($$v) {
                                  _vm.snackbar = $$v
                                },
                                expression: "snackbar",
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                              _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "secondary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      _vm.saveSnackbar = false
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showVisitInvoiceDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showVisitInvoiceDialog,
                callback: function ($$v) {
                  _vm.showVisitInvoiceDialog = $$v
                },
                expression: "showVisitInvoiceDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Create Visit Invoice")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.closeVisitLogInvoiceDialog },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "mb-1": "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "grid-view": "" } },
                            [
                              _c("MonitoringVisitLogs", {
                                ref: "monitoringVisitInvoiceRef",
                                attrs: {
                                  "job-id": _vm.jobId,
                                  "monitoring-visit-logs":
                                    _vm.getFilteredVisitLogs,
                                  loading: _vm.loading,
                                  "is-visit-log-invoice": true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeVisitLogInvoiceDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 submit-invoice-btn",
                          attrs: {
                            color: "primary",
                            loading: _vm.loading,
                            disabled:
                              _vm.loading ||
                              _vm.getFilteredVisitLogs.length === 0,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createVisitLogInvoice.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }