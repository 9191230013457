var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mt-2" },
    [
      _c(
        "v-tabs",
        {
          attrs: { "background-color": "primary", color: "primary" },
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "v-tab",
            { attrs: { href: "#tab-1" } },
            [
              _c("v-icon", { attrs: { color: "secondary" } }, [
                _vm._v("cloud"),
              ]),
            ],
            1
          ),
          _c(
            "v-tab",
            { attrs: { href: "#tab-2" } },
            [
              _c("v-icon", { attrs: { color: "secondary" } }, [
                _vm._v("notes"),
              ]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            [
              _c(
                "v-tab-item",
                { attrs: { value: "tab-1" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [_c("WeatherWidget", { attrs: { "job-id": _vm.jobId } })],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                { attrs: { value: "tab-2" } },
                [
                  _c(
                    "v-card",
                    { attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("AddJobNote", {
                            ref: "addNewJobNote",
                            model: {
                              value: _vm.addNewJobNote,
                              callback: function ($$v) {
                                _vm.addNewJobNote = $$v
                              },
                              expression: "addNewJobNote",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-clear",
                              on: { click: _vm.openAddNote },
                            },
                            [_vm._v("Clear")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-0 btn-submit",
                              attrs: {
                                color: "primary",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.onSaveJobNote },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }