var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.item
        ? _c(
            "v-card",
            { staticClass: "pt-0 px-2 pb-2" },
            [
              _c(
                "v-card-title",
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", { staticClass: "heading" }, [
                          _vm._v(" Escalation Details "),
                          _c(
                            "span",
                            {
                              staticClass: "escalation-Details",
                              class: _vm.getJobEscalationStatusColor,
                            },
                            [
                              _vm._v(
                                " (" + _vm._s(_vm.getJobEscalationStatus) + ") "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _vm.item.escalationReason
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("label", [_c("b", [_vm._v("Escalation Reason:")])]),
                        _c("span", [_vm._v(_vm._s(_vm.item.escalationReason))]),
                      ])
                    : _vm._e(),
                  _vm.item.escalationDescription
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("label", [
                          _c("b", [_vm._v("Escalation Description:")]),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.item.escalationDescription)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.item.deEscalationDescription
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "pt-2 deEscalationDescription",
                          attrs: { xs12: "" },
                        },
                        [
                          _c("v-divider", { staticClass: "pb-2" }),
                          _c("label", [
                            _c("b", [_vm._v("De-Escalation Description:")]),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.item.deEscalationDescription)),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.item.deEscalationDescription && _vm.item.deEscalatedAt
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("label", [_c("b", [_vm._v("De-Escalated At:")])]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getFormatedDate(_vm.item.deEscalatedAt))
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.item.deEscalationDescription && _vm.canEscalateJob
                    ? _c("v-flex", { attrs: { xs12: "" } }, [
                        _c(
                          "div",
                          { staticClass: "text-xs-right" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btn-deEscalate",
                                attrs: { color: "primary" },
                                on: { click: _vm.showDeEscalateJobDialog },
                              },
                              [_vm._v("De-Escalate")]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "450",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.openDeEscalateJobDialog,
            callback: function ($$v) {
              _vm.openDeEscalateJobDialog = $$v
            },
            expression: "openDeEscalateJobDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("De-Escalate Job")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.openDeEscalateJobDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c("v-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Description",
                      required: "",
                      "data-vv-scope": "frmDeEscalateReason",
                      "data-vv-name": "Job De-Escalate Description",
                      name: "JobDeEscalateDescription",
                      "error-messages": _vm.errors.collect(
                        "Job De-Escalate Description"
                      ),
                    },
                    model: {
                      value: _vm.deEscalationDescription,
                      callback: function ($$v) {
                        _vm.deEscalationDescription = $$v
                      },
                      expression: "deEscalationDescription",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-cancelDeEscalateJob",
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.openDeEscalateJobDialog = false
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-saveDeEscalateJob",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deEscalateJob()
                        },
                      },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }