var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.clientRateTemplateDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1200",
                persistent: "",
                "content-class": "v-dialog--scrollable full-card-width",
              },
              model: {
                value: _vm.clientRateTemplateDialog,
                callback: function ($$v) {
                  _vm.clientRateTemplateDialog = $$v
                },
                expression: "clientRateTemplateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Client Rate")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "close-rate-template-dialog",
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeClientRateTemplateDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "py-0" },
                        [
                          _vm.jobType === "US"
                            ? _c("ClientRateUS", {
                                ref: "clientRateUSRef",
                                attrs: {
                                  "selected-rate-package-in-policy":
                                    _vm.selectedRatePackage,
                                  "all-client-rate-package-list":
                                    _vm.allClientRatePackageList,
                                  "policy-id": _vm.policyModel.policyId,
                                  "policy-name": _vm.policyModel.name,
                                  "rate-package-id":
                                    _vm.policyModel.ratePackageId,
                                  "show-rate-template-in-dialog": true,
                                },
                                on: {
                                  "update:allClientRatePackageList": function (
                                    $event
                                  ) {
                                    _vm.allClientRatePackageList = $event
                                  },
                                  "update:all-client-rate-package-list":
                                    function ($event) {
                                      _vm.allClientRatePackageList = $event
                                    },
                                  "update:ratePackageId": function ($event) {
                                    return _vm.$set(
                                      _vm.policyModel,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                  "update:rate-package-id": function ($event) {
                                    return _vm.$set(
                                      _vm.policyModel,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                  saveRatePackage: _vm.onSaveRatePackage,
                                },
                              })
                            : _c("ClientRate", {
                                ref: "clientRateRef",
                                attrs: {
                                  "selected-rate-package-in-policy":
                                    _vm.selectedRatePackage,
                                  "all-client-rate-package-list":
                                    _vm.allClientRatePackageList,
                                  "policy-id": _vm.policyModel.policyId,
                                  "policy-name": _vm.policyModel.name,
                                  "rate-package-id":
                                    _vm.policyModel.ratePackageId,
                                  "show-rate-template-in-dialog": true,
                                },
                                on: {
                                  "update:allClientRatePackageList": function (
                                    $event
                                  ) {
                                    _vm.allClientRatePackageList = $event
                                  },
                                  "update:all-client-rate-package-list":
                                    function ($event) {
                                      _vm.allClientRatePackageList = $event
                                    },
                                  "update:ratePackageId": function ($event) {
                                    return _vm.$set(
                                      _vm.policyModel,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                  "update:rate-package-id": function ($event) {
                                    return _vm.$set(
                                      _vm.policyModel,
                                      "ratePackageId",
                                      $event
                                    )
                                  },
                                  saveRatePackage: _vm.onSaveRatePackage,
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "policy-name-add", attrs: { xs4: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:50",
                            expression: "'required|max:50'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Policy Name",
                          required: "",
                          maxlength: "50",
                          "data-vv-name": "Policy Name",
                          "error-messages": _vm.errors.collect("Policy Name"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "PolicyName",
                        },
                        model: {
                          value: _vm.policyModel.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyModel.name",
                        },
                      }),
                      _c(
                        "v-chip",
                        {
                          staticClass: "new-policy-chip",
                          attrs: {
                            color: "secondary",
                            disabled: "",
                            value: _vm.selectedPolicyIsNew,
                          },
                        },
                        [_vm._v("New")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      attrs: {
                        xs2: "",
                        "pl-3": "",
                        "assumed-cover-switch": "",
                      },
                    },
                    [
                      _c("v-switch", {
                        attrs: {
                          color: "primary",
                          small: "",
                          name: "AssumedCover",
                          readonly: _vm.isUserRoleClientManager,
                          label: "Assumed Cover",
                        },
                        model: {
                          value: _vm.policyModel.isStarred,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "isStarred", $$v)
                          },
                          expression: "policyModel.isStarred",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:50",
                            expression: "'max:50'",
                          },
                        ],
                        attrs: {
                          label: "Insurer Reference",
                          maxlength: "50",
                          "data-vv-name": "Insurer Reference",
                          "error-messages":
                            _vm.errors.collect("Insurer Reference"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "InsurerReference",
                        },
                        model: {
                          value: _vm.policyModel.insurerReference,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "insurerReference",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyModel.insurerReference",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pl-2 pr-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.getComplexity,
                          label: "Complexity",
                          "item-text": "complexity",
                          "item-value": "id",
                          readonly: _vm.isUserRoleClientManager,
                          name: "Complexity",
                        },
                        model: {
                          value: _vm.policyModel.complexity,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "complexity", $$v)
                          },
                          expression: "policyModel.complexity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.getPriority,
                          label: "Priority",
                          "item-text": "priority",
                          "item-value": "id",
                          readonly: _vm.isUserRoleClientManager,
                          name: "Priority",
                        },
                        model: {
                          value: _vm.policyModel.priority,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "priority", $$v)
                          },
                          expression: "policyModel.priority",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pl-2 pr-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:500",
                            expression: "'max:500'",
                          },
                        ],
                        staticClass: "intention",
                        attrs: {
                          label: "Intention",
                          "data-vv-name": "Intention",
                          "error-messages": _vm.errors.collect("Intention"),
                          readonly: _vm.isUserRoleClientManager,
                        },
                        model: {
                          value: _vm.policyModel.intention,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "intention",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyModel.intention",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3 showEditor",
                      attrs: { xs6: "" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("label", { staticClass: "pre-block-label" }, [
                            _vm._v("Emergency Definition"),
                          ]),
                          !_vm.isUserRoleClientManager
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0 emergency-definition",
                                  attrs: { icon: "", small: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openEditor()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "bar",
                                  rawName: "v-bar",
                                  value: { useScrollbarPseudo: true },
                                  expression: "{ useScrollbarPseudo: true }",
                                },
                              ],
                              staticClass: "pre-block",
                            },
                            [
                              _c("v-layout", [
                                _c(
                                  "pre",
                                  {
                                    staticClass: "mb-0 mt-2",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitizeHTML(
                                          _vm.policyModel.emergencyDefinition
                                        )
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.policyModel.emergencyDefinition
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pl-2 pr-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:20",
                            expression: "'max:20'",
                          },
                        ],
                        attrs: {
                          label: "Delivery Phone",
                          placeholder: "Phone Number",
                          maxlength: "20",
                          "data-vv-name": "Delivery Phone",
                          "error-messages":
                            _vm.errors.collect("Delivery Phone"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "DeliveryPhone",
                        },
                        model: {
                          value: _vm.policyModel.deliveryPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "deliveryPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyModel.deliveryPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "max:500",
                            expression: "'max:500'",
                          },
                        ],
                        attrs: {
                          label: "Phone Answer",
                          placeholder:
                            "Welcome to the claims department, how may I help you?",
                          maxlength: "500",
                          "data-vv-name": "Phone Answer",
                          "error-messages": _vm.errors.collect("Phone Answer"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "PhoneAnswer",
                        },
                        model: {
                          value: _vm.policyModel.answerPhone,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "answerPhone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyModel.answerPhone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      class: _vm.jobType === "HE" ? "pl-2 pr-3" : "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.jobType === "HE" || _vm.jobType === "SI",
                            expression: "jobType === 'HE' || jobType === 'SI'",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric|max:8",
                            expression: "'required|numeric|max:8'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Policy Limit",
                          type: "number",
                          maxlength: "8",
                          placeholder: "0",
                          required: "",
                          "data-vv-name": "Policy Limit",
                          "error-messages": _vm.errors.collect("Policy Limit"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "PolicyLimit",
                        },
                        model: {
                          value: _vm.policyModel.policyLimit,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "policyLimit",
                              _vm._n($$v)
                            )
                          },
                          expression: "policyModel.policyLimit",
                        },
                      }),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.jobType === "US",
                            expression: "jobType === 'US'",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|numeric|max:8",
                            expression: "'required|numeric|max:8'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Delegated Authority",
                          type: "number",
                          maxlength: "8",
                          placeholder: "0",
                          required: "",
                          "data-vv-name": "Delegated Authority",
                          "error-messages": _vm.errors.collect(
                            "Delegated Authority"
                          ),
                          readonly: _vm.isUserRoleClientManager,
                          name: "DelegatedAuthority",
                        },
                        model: {
                          value: _vm.policyModel.policyLimit,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "policyLimit",
                              _vm._n($$v)
                            )
                          },
                          expression: "policyModel.policyLimit",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.jobType === "HE"
                    ? _c(
                        "v-flex",
                        { staticClass: "pr-2 pl-3 mt-1", attrs: { xs6: "" } },
                        [
                          _c("v-switch", {
                            attrs: {
                              label: "Policy Limit Per Emergency",
                              color: "primary",
                              "hide-details": "",
                              readonly: _vm.isUserRoleClientManager,
                              name: "PolicyLimitPerEmergency",
                            },
                            model: {
                              value: _vm.policyModel.hasPolicyLimitPerEmergency,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.policyModel,
                                  "hasPolicyLimitPerEmergency",
                                  $$v
                                )
                              },
                              expression:
                                "policyModel.hasPolicyLimitPerEmergency",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      class: _vm.jobType === "HE" ? "pl-2 pr-3" : "",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric|max:3",
                            expression: "'numeric|max:3'",
                          },
                        ],
                        attrs: {
                          label: "Cut off Hours",
                          placeholder: "Cut off hours",
                          maxlength: "3",
                          "data-vv-name": "Cut off Hours",
                          "error-messages": _vm.errors.collect("Cut off Hours"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "CutoffHours",
                        },
                        model: {
                          value: _vm.policyModel.cutOffHours,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "cutOffHours", $$v)
                          },
                          expression: "policyModel.cutOffHours",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          disabled: _vm.policyModel.isHelplinePolicy,
                          items: _vm.getEnquiryType,
                          label: "Enquiry Type",
                          "item-text": "enquiryType",
                          "item-value": "id",
                          readonly: _vm.isUserRoleClientManager,
                          name: "EnquiryType",
                        },
                        model: {
                          value: _vm.policyModel.enquiryType,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "enquiryType", $$v)
                          },
                          expression: "policyModel.enquiryType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pl-2 pr-3 mt-1",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "Is Helpline Policy?",
                          color: "primary",
                          "hide-details": "",
                          readonly: _vm.isUserRoleClientManager,
                          name: "helplinePolicy",
                        },
                        on: { change: _vm.onHelplinePolicyChange },
                        model: {
                          value: _vm.policyModel.isHelplinePolicy,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "isHelplinePolicy", $$v)
                          },
                          expression: "policyModel.isHelplinePolicy",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pr-2 pl-3",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: _vm.policyModel.isHelplinePolicy
                                  ? true
                                  : false,
                                emails: true,
                              },
                            },
                            expression:
                              "{\n              rules: {\n                required: policyModel.isHelplinePolicy ? true : false,\n                emails: true,\n              },\n            }",
                          },
                        ],
                        class: _vm.policyModel.isHelplinePolicy
                          ? "required"
                          : "",
                        attrs: {
                          label: "Helpline Emails",
                          required: "",
                          "data-vv-name": "Helpline Email",
                          "error-messages":
                            _vm.errors.collect("Helpline Email"),
                          readonly: _vm.isUserRoleClientManager,
                          name: "HelplineEmail",
                        },
                        model: {
                          value: _vm.policyModel.helplineEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.policyModel, "helplineEmail", $$v)
                          },
                          expression: "policyModel.helplineEmail",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType !== "SI",
                          expression: "jobType !== 'SI'",
                        },
                      ],
                      staticClass: "pl-2 pr-3 package-selction",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-autocomplete", {
                        staticClass: "select-package",
                        attrs: {
                          label: "Search package",
                          "return-object": "",
                          items: _vm.getClientRatePackageList,
                          "item-text": "packageName",
                          "item-value": "id",
                          readonly: _vm.isUserRoleClientManager,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onClientRatePackageChange($event)
                          },
                        },
                        model: {
                          value: _vm.selectedRatePackage,
                          callback: function ($$v) {
                            _vm.selectedRatePackage = $$v
                          },
                          expression: "selectedRatePackage",
                        },
                      }),
                      _vm.policyModel.policyId !== 0 &&
                      !_vm.isUserRoleClientManager
                        ? _c(
                            "span",
                            {
                              staticClass: "packge-btn pr-2",
                              class:
                                _vm.editClientRatePackage ||
                                _vm.selectedRatePackage !== null
                                  ? "show"
                                  : "hide",
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0 edit-rate-template",
                                  attrs: {
                                    icon: "",
                                    color: "primary",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.clientRateTemplateDialog = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v("edit"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      staticClass:
                        "text-xs-center icon-validation img-with-input",
                      class: { "pl-3 pr-2": _vm.jobType !== "US" },
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Logo",
                          placeholder: "Select logo",
                          "prepend-icon": "attach_file",
                          "hide-details": true,
                          readonly: _vm.isUserRoleClientManager,
                          name: "imageName",
                        },
                        on: { click: _vm.pickFile },
                        model: {
                          value: _vm.imageName,
                          callback: function ($$v) {
                            _vm.imageName = $$v
                          },
                          expression: "imageName",
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: false,
                                image: true,
                                size: _vm.LogoSize,
                                mimes: [
                                  "image/jpeg",
                                  "image/jpg",
                                  "image/png",
                                  "image/gif",
                                ],
                                ext: ["jpg", "jpeg", "png", "gif"],
                              },
                            },
                            expression:
                              "{\n              rules: {\n                required: false,\n                image: true,\n                size: LogoSize,\n                mimes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],\n                ext: ['jpg', 'jpeg', 'png', 'gif'],\n              },\n            }",
                          },
                        ],
                        ref: "image",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          name: "logo",
                          disabled: _vm.isUserRoleClientManager,
                        },
                        on: { change: _vm.onFilePicked },
                      }),
                      _c("div", { staticClass: "input-group__details" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-group__messages input-group__error input-group--error error--text",
                          },
                          [
                            _vm.imageValidationMessage(_vm.errors)
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.logoErrorMsg) + " "),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm.imageUrl
                        ? _c(
                            "div",
                            { staticClass: "show-up-logo" },
                            [
                              _c("img", { attrs: { src: _vm.imageUrl } }),
                              !_vm.isUserRoleClientManager
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "remove-img",
                                      attrs: {
                                        flat: "",
                                        icon: "",
                                        color: "primary",
                                        small: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.removeImage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { dark: "", color: "primary" },
                                        },
                                        [_vm._v("delete")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.jobType === "HE",
                          expression: "jobType === 'HE'",
                        },
                      ],
                      staticClass: "pl-2 pr-3 mt-1",
                      attrs: { xs6: "" },
                    },
                    [
                      _c("v-switch", {
                        attrs: {
                          label: "Is Commercial Property Policy?",
                          color: "primary",
                          "hide-details": "",
                          readonly: _vm.isUserRoleClientManager,
                          name: "isCommercialPropertyPolicy",
                        },
                        on: { change: _vm.onHelplinePolicyChange },
                        model: {
                          value: _vm.policyModel.isCommercialPropertyPolicy,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyModel,
                              "isCommercialPropertyPolicy",
                              $$v
                            )
                          },
                          expression: "policyModel.isCommercialPropertyPolicy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    timeout: _vm.saveSnackbarTimeout,
                    bottom: true,
                    left: true,
                  },
                  model: {
                    value: _vm.saveSnackbar,
                    callback: function ($$v) {
                      _vm.saveSnackbar = $$v
                    },
                    expression: "saveSnackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.saveSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "v-dialog--scrollable",
            "max-width": "650",
            persistent: "",
          },
          model: {
            value: _vm.editEmergencyDefinition,
            callback: function ($$v) {
              _vm.editEmergencyDefinition = $$v
            },
            expression: "editEmergencyDefinition",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Emergency Definition")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.editEmergencyDefinition = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog px-3" },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("froala", {
                            attrs: {
                              id: "froala",
                              "data-vv-scope": "templateformRef",
                              "data-vv-name": "Email",
                              config: _vm.config,
                              tag: "textarea",
                            },
                            model: {
                              value: _vm.emergencyDefinitionTemp,
                              callback: function ($$v) {
                                _vm.emergencyDefinitionTemp = $$v
                              },
                              expression: "emergencyDefinitionTemp",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.editEmergencyDefinition = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 save-emergency-definition",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.onSaveEmergencyDefinition()
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }