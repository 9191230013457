var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getRepairEstimates
    ? _c(
        "div",
        { staticClass: "bg-white pa-3", attrs: { xs12: "" } },
        [
          _vm._l(
            _vm.getRepairEstimates,
            function (repairEstimateDetail, index) {
              return _c(
                "v-layout",
                { key: index, attrs: { row: "", wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs7: "" } }, [
                    _c(
                      "span",
                      { staticClass: "font-weight-bold estimate-name" },
                      [
                        _vm._v(
                          " " + _vm._s(repairEstimateDetail.estimateName) + " "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs5: "", "text-xs-right": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 btn-editRepairEstimate",
                          attrs: { flat: "", icon: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editRepairEstimate(
                                repairEstimateDetail
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 mr-1 btn-deleteRepairEstimate",
                          attrs: { flat: "", icon: "", color: "secondary" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteRepairEstimate(
                                repairEstimateDetail.id,
                                index
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("delete")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "gridView mt-2 mb-2", attrs: { xs12: "" } },
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: repairEstimateDetail.products,
                          "item-key": "id",
                          "hide-actions": "",
                          loading: _vm.isLoading && _vm.currentIndex === index,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function (props) {
                                return [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.description)),
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getFormatedCurrency(
                                                props.item.clientPrice
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  index !== _vm.getRepairEstimates.length - 1
                    ? _c("v-divider", { staticClass: "ml-1 mt-2 mb-2" })
                    : _vm._e(),
                ],
                1
              )
            }
          ),
          _vm.showRepairEstimationDialog
            ? _c("RepairEstimationDialog", {
                ref: "repairEstimationDialog",
                attrs: {
                  "policy-client-rate-package-id":
                    _vm.job && _vm.job.policyClientRatePackageId
                      ? _vm.job.policyClientRatePackageId
                      : "",
                  "job-id": _vm.jobId,
                  products: _vm.selectedRepairEstimateDetail.products,
                  "repair-estimate-id": _vm.selectedRepairEstimateDetail.id,
                },
                on: {
                  closeRepairEstimationDialog:
                    _vm.onCloseRepairEstimationDialog,
                },
              })
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.snackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }