var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.getCancellationReason
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", { staticClass: "red--text" }, [
              _c("b", [_vm._v("Cancellation Reason:")]),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.getCancellationReason))]),
          ])
        : _vm._e(),
      _vm.job.reasonDescription
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("label", [_c("b", [_vm._v("Reason Description:")])]),
            _c("span", [_vm._v(_vm._s(_vm.job.reasonDescription))]),
          ])
        : _vm._e(),
      _vm.job.jobCompletedAtUtc
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _vm.job.cancellationReason
              ? _c("div", { staticClass: "small-text mb-2" }, [
                  _c("b", [_vm._v("Cancelled at:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.getFormattedDate(_vm.job.jobCompletedAtUtc)) +
                      " "
                  ),
                ])
              : _c("div", { staticClass: "small-text mb-2" }, [
                  _c("b", [_vm._v("Completed at:")]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.getFormattedDate(_vm.job.jobCompletedAtUtc)) +
                      " "
                  ),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }