var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-data-table",
    {
      attrs: {
        headers: _vm.monitoringVisitLogHeaders,
        items: _vm.monitoringVisitLogs,
        "item-key": "visitId",
        "hide-actions": "",
        loading: _vm.loading,
        "select-all":
          _vm.isVisitLogInvoice && _vm.monitoringVisitLogs.length > 0,
      },
      scopedSlots: _vm._u([
        {
          key: "items",
          fn: function (props) {
            return [
              _c("tr", { attrs: { active: props.selected } }, [
                _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isVisitLogInvoice,
                        expression: "isVisitLogInvoice",
                      },
                    ],
                  },
                  [
                    _c("v-checkbox", {
                      staticClass: "select-visit-chkbox",
                      attrs: {
                        disabled: _vm.loading,
                        color: "primary",
                        "hide-details": "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          props.selected = !props.selected
                        },
                      },
                      model: {
                        value: props.selected,
                        callback: function ($$v) {
                          _vm.$set(props, "selected", $$v)
                        },
                        expression: "props.selected",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _vm.updatingLog && _vm.index === props.item.visitId
                      ? _c("DateTimePicker", {
                          attrs: {
                            "data-vv-scope": _vm.editFormref,
                            "date-time": props.item.visitDate,
                            "is-static-location": false,
                            "is-current-time": true,
                            "is-validation-required": true,
                            "show-present-time-icon": false,
                            "hide-time-picker": true,
                            "display-format": _vm.dateFormat,
                            disabled: _vm.loading,
                            "place-holder-text": "Visit Date",
                          },
                          on: {
                            "update:dateTime": function ($event) {
                              return _vm.$set(props.item, "visitDate", $event)
                            },
                            "update:date-time": function ($event) {
                              return _vm.$set(props.item, "visitDate", $event)
                            },
                          },
                        })
                      : _c("span", [
                          _vm._v(_vm._s(_vm.formatDate(props.item.visitDate))),
                        ]),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _vm.updatingLog && _vm.index === props.item.visitId
                      ? _c("DateTimePicker", {
                          attrs: {
                            "data-vv-scope": _vm.editFormref,
                            "date-time": props.item.reportSentDate,
                            "min-date": _vm.formatDate(
                              props.item.visitDate,
                              true
                            ),
                            "is-static-location": false,
                            "is-current-time": false,
                            "is-validation-required": true,
                            "show-present-time-icon": false,
                            "hide-time-picker": true,
                            "display-format": _vm.dateFormat,
                            disabled: _vm.loading,
                            "place-holder-text": "Report Sent Date",
                          },
                          on: {
                            "update:dateTime": function ($event) {
                              return _vm.$set(
                                props.item,
                                "reportSentDate",
                                $event
                              )
                            },
                            "update:date-time": function ($event) {
                              return _vm.$set(
                                props.item,
                                "reportSentDate",
                                $event
                              )
                            },
                          },
                        })
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatDate(props.item.reportSentDate))
                          ),
                        ]),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _vm.updatingLog && _vm.index === props.item.visitId
                      ? _c("DateTimePicker", {
                          attrs: {
                            "data-vv-scope": _vm.editFormref,
                            "date-time": props.item.invoicedDate,
                            "min-date": _vm.formatDate(
                              props.item.reportSentDate,
                              true
                            ),
                            "is-static-location": false,
                            "is-current-time": false,
                            "is-validation-required": false,
                            "show-present-time-icon": false,
                            "hide-time-picker": true,
                            "display-format": _vm.dateFormat,
                            disabled: true,
                            "place-holder-text": "Invoiced Date",
                          },
                          on: {
                            "update:dateTime": function ($event) {
                              return _vm.$set(
                                props.item,
                                "invoicedDate",
                                $event
                              )
                            },
                            "update:date-time": function ($event) {
                              return _vm.$set(
                                props.item,
                                "invoicedDate",
                                $event
                              )
                            },
                          },
                        })
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                props.item.invoicedDate
                                  ? _vm.formatDate(props.item.invoicedDate)
                                  : "-"
                              ) +
                              " "
                          ),
                        ]),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _vm.updatingLog && _vm.index === props.item.visitId
                      ? _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: `required|min_value:0`,
                              expression: "`required|min_value:0`",
                            },
                          ],
                          attrs: {
                            label: "cost",
                            "hide-details": "",
                            "data-vv-scope": _vm.editFormref,
                            "data-vv-name": "cost",
                            type: "number",
                            "error-messages": _vm.validationMessage("cost"),
                            disabled: _vm.loading,
                          },
                          model: {
                            value: props.item.cost,
                            callback: function ($$v) {
                              _vm.$set(props.item, "cost", _vm._n($$v))
                            },
                            expression: "props.item.cost",
                          },
                        })
                      : _c("span", [_vm._v(_vm._s(props.item.cost))]),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _vm.updatingLog && _vm.index === props.item.visitId
                      ? _c(
                          "span",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "ma-0 save-btn",
                                                attrs: {
                                                  icon: "",
                                                  flat: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.saveMonitoringVisitLog(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "md-icon" },
                                                [_vm._v("save")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Save")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass: "ma-0 cancel-btn",
                                                attrs: {
                                                  icon: "",
                                                  flat: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.cancelMonitoringVisitLog(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "md-icon" },
                                                [_vm._v("close")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Cancel")])]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "v-menu",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isVisitLogInvoice
                                  ? false
                                  : !props.item.invoicedDate,
                                expression:
                                  "isVisitLogInvoice ? false : !props.item.invoicedDate",
                              },
                            ],
                            staticClass: "menu-edit",
                            attrs: {
                              "offset-y": "",
                              left: "",
                              disabled: _vm.loading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            staticClass: "ma-0 action-btn",
                                            attrs: {
                                              icon: "",
                                              flat: "",
                                              color: "primary",
                                              disabled:
                                                _vm.loading ||
                                                _vm.isNewVisitLog,
                                            },
                                          },
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("more_vert")])],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "pa-2 white" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ma-0 delete-btn",
                                                    attrs: {
                                                      icon: "",
                                                      flat: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteMonitoringVisitLog(
                                                          props.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "md-icon" },
                                                    [_vm._v("delete")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Delete")])]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "ma-0 edit-btn",
                                                    attrs: {
                                                      icon: "",
                                                      flat: "",
                                                      color: "primary",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        return _vm.editMonitoringVisitLog(
                                                          props.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { staticClass: "md-icon" },
                                                    [_vm._v("edit")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Edit")])]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selectedVisitLogs,
        callback: function ($$v) {
          _vm.selectedVisitLogs = $$v
        },
        expression: "selectedVisitLogs",
      },
    },
    [
      _vm.isNewVisitLog
        ? _c(
            "template",
            { slot: _vm.monitoringVisitLogs.length ? "footer" : "no-data" },
            [
              _c("tr", [
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _c("DateTimePicker", {
                      attrs: {
                        "data-vv-scope": _vm.addFormref,
                        "date-time": _vm.newVisit.visitDate,
                        "is-static-location": false,
                        "is-current-time": true,
                        "is-validation-required": true,
                        "show-present-time-icon": false,
                        "hide-time-picker": true,
                        "display-format": _vm.dateFormat,
                        disabled: _vm.loading,
                        "place-holder-text": "Visit Date",
                      },
                      on: {
                        "update:dateTime": function ($event) {
                          return _vm.$set(_vm.newVisit, "visitDate", $event)
                        },
                        "update:date-time": function ($event) {
                          return _vm.$set(_vm.newVisit, "visitDate", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _c("DateTimePicker", {
                      attrs: {
                        "data-vv-scope": _vm.addFormref,
                        "date-time": _vm.newVisit.reportSentDate,
                        "min-date": _vm.formatDate(
                          _vm.newVisit.visitDate,
                          true
                        ),
                        "is-static-location": false,
                        "is-current-time": false,
                        "is-validation-required": true,
                        "show-present-time-icon": false,
                        "hide-time-picker": true,
                        "display-format": _vm.dateFormat,
                        disabled: _vm.loading,
                        "place-holder-text": "Report Sent Date",
                      },
                      on: {
                        "update:dateTime": function ($event) {
                          return _vm.$set(
                            _vm.newVisit,
                            "reportSentDate",
                            $event
                          )
                        },
                        "update:date-time": function ($event) {
                          return _vm.$set(
                            _vm.newVisit,
                            "reportSentDate",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _c("DateTimePicker", {
                      attrs: {
                        "data-vv-scope": _vm.addFormref,
                        "date-time": _vm.newVisit.invoicedDate,
                        "min-date": _vm.formatDate(
                          _vm.newVisit.reportSentDate,
                          true
                        ),
                        "is-static-location": false,
                        "is-current-time": false,
                        "is-validation-required": false,
                        "show-present-time-icon": false,
                        "hide-time-picker": true,
                        "display-format": _vm.dateFormat,
                        disabled: true,
                        "place-holder-text": "Invoiced Date",
                      },
                      on: {
                        "update:dateTime": function ($event) {
                          return _vm.$set(_vm.newVisit, "invoicedDate", $event)
                        },
                        "update:date-time": function ($event) {
                          return _vm.$set(_vm.newVisit, "invoicedDate", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _c("v-text-field", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: `required|min_value:0`,
                          expression: "`required|min_value:0`",
                        },
                      ],
                      attrs: {
                        "data-vv-scope": _vm.addFormref,
                        "data-vv-name": "Read cost",
                        label: "cost",
                        "hide-details": "",
                        type: "number",
                        "error-messages": _vm.validationMessage("Read cost"),
                        disabled: _vm.loading,
                      },
                      model: {
                        value: _vm.newVisit.cost,
                        callback: function ($$v) {
                          _vm.$set(_vm.newVisit, "cost", _vm._n($$v))
                        },
                        expression: "newVisit.cost",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "text-xs-center" },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ma-0 save-btn",
                                        attrs: {
                                          icon: "",
                                          flat: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveMonitoringVisitLog(
                                              _vm.newVisit
                                            )
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { staticClass: "md-icon" }, [
                                        _vm._v("save"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2822368095
                        ),
                      },
                      [_c("span", [_vm._v("Save")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass: "ma-0 cancel-btn",
                                        attrs: {
                                          icon: "",
                                          flat: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.close()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c("v-icon", { staticClass: "md-icon" }, [
                                        _vm._v("close"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2990040364
                        ),
                      },
                      [_c("span", [_vm._v("Cancel")])]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }