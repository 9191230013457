<template>
  <v-layout v-if="!contractorAppointment">No appointed contractor details found</v-layout>
  <v-layout v-else row fill-height class="wrapper">
    <v-layout column xs6>
      <v-layout>
        <v-flex class="ml-4 mb-2">
          <v-layout row class="pa-1">
            <v-flex xs6>
              <span class="font-weight-bold">Notifications</span>
            </v-flex>
          </v-layout>

          <v-layout
            v-for="(notification, index) in contractorAppointment.notifications"
            :key="`${notification.type}-${index}`"
            row
            class="px-1"
          >
            <v-flex xs6>
              <span> {{ notification.type }} Sent At: </span>
            </v-flex>
            <v-flex xs6>
              <span>{{ formatDateTime(notification.date) }}</span>
            </v-flex>
          </v-layout>

          <v-layout row class="pa-1">
            <v-flex xs6>
              <span class="font-weight-bold">Status:</span>
            </v-flex>
            <v-flex xs6>
              <v-chip small :color="statusColor" text-color="white"> {{ formattedStatusText }}</v-chip>
            </v-flex>
          </v-layout>

          <div v-if="isAcceptedOrRejected(contractorAppointment)">
            <v-layout row class="pa-1">
              <v-flex xs6>
                <span class="font-weight-bold">{{ formattedStatusText }} At:</span>
              </v-flex>
              <v-flex xs6>
                <span>{{ formatDateTime(contractorAppointment.updatedAt) }}</span>
              </v-flex>
            </v-layout>

            <v-layout row class="pa-1">
              <v-flex xs6>
                <span class="font-weight-bold">{{ formattedStatusText }} By:</span>
              </v-flex>
              <v-flex xs6>
                <span>{{ contractorAppointment.updatedByUserName }}</span>
              </v-flex>
            </v-layout>
          </div>

          <v-layout v-if="Boolean(contractorAppointment.updateReasonDescription)" row class="pa-1">
            <v-flex xs6>
              <span class="font-weight-bold">{{ formattedStatusText }} Reason:</span>
            </v-flex>
            <v-flex xs6>
              <span>{{ contractorAppointment.updateReasonDescription }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <!-- <v-layout v-if="showCountDownTimer" align-end pa-2 row text-right>
        <v-flex
          >AD Time Remaining <h4>{{ timerCountdown.minutes }} : {{ timerCountdown.seconds }}</h4></v-flex
        >
      </v-layout> -->
    </v-layout>
  </v-layout>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import {
  AppointmentRequestStatus,
  DeploymentStatusMap,
  ContractorAppointment,
} from '@/models/autoDeployment/auto-deployment'
import { formatDateTime } from '@/common/datetime'
import InteractiveMap from '@/components/InteractiveMap.vue'
import { useDeploymentStore } from '@/pinia/deployment'

@Component({
  components: {
    InteractiveMap,
  },
})
export default class AutoDeploymentContractorDetail extends Vue {
  @Prop()
  public contractorAppointedId: string

  @Prop()
  public deploymentId: string

  public formatDateTime = formatDateTime
  public deploymentStatusMap = DeploymentStatusMap

  public deploymentStatusEnum = AppointmentRequestStatus
  public timerCountdown: { minutes: string; seconds: string } = {
    minutes: '',
    seconds: '',
  }

  public get contractorAppointment(): ContractorAppointment | null {
    const contractor = useDeploymentStore().autoDeployment?.contractors.find(
      (x) => x.contractorId === this.contractorAppointedId
    )
    return contractor?.appointmentRequest || null
  }

  public isAcceptedOrRejected(appointment: ContractorAppointment): boolean {
    const { status } = appointment
    return status === this.deploymentStatusEnum.Accepted || status === this.deploymentStatusEnum.Rejected
  }

  public get statusColor(): string {
    return this.contractorAppointment ? DeploymentStatusMap[this.contractorAppointment.status].color : ''
  }

  public get formattedStatusText(): string {
    return this.contractorAppointment ? DeploymentStatusMap[this.contractorAppointment.status].text : ''
  }

  public get showCountDownTimer(): boolean {
    return this.contractorAppointment ? this.contractorAppointment.status === this.deploymentStatusEnum.Offered : false
  }
}
</script>

<style scoped>
.v-chip--small {
  height: 12px !important;
  font-size: 12px;
  padding: 10px 0px;
  margin: 0;
}
</style>
