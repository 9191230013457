var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { "flex-col": "", "align-center": "", "gap-2": "" } },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _c("img", {
            staticClass: "image rounded-full elevation-1",
            attrs: { src: _vm.image ? _vm.image : "/img/unknownuser.png" },
          }),
          _c(
            "v-layout",
            {
              staticClass: "buttons",
              attrs: { "flex-col": "", "justify-space-between": "" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0",
                  attrs: { label: "", icon: "", color: "secondary" },
                },
                [
                  _c(
                    "label",
                    { staticClass: "pa-2 cursor-pointer" },
                    [
                      _c("v-icon", [_vm._v("edit")]),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: false,
                                image: true,
                                size: _vm.profileImageSize,
                                mimes: ["image/jpeg", "image/jpg", "image/png"],
                                ext: _vm.validExtensions,
                              },
                            },
                            expression:
                              "{\n              rules: {\n                required: false,\n                image: true,\n                size: profileImageSize,\n                mimes: ['image/jpeg', 'image/jpg', 'image/png'],\n                ext: validExtensions,\n              },\n            }",
                          },
                        ],
                        ref: "image",
                        staticClass: "hide",
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          name: "logo",
                          "data-vv-scope": "profileImage",
                        },
                        on: { change: _vm.onImageChanged },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm.image
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ma-0 pa-2",
                      attrs: { icon: "", color: "error" },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.clear.apply(null, arguments)
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "error--text" }, [
        _vm._v(" " + _vm._s(_vm.validationMessage(_vm.errors)) + " "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }