var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "850",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "elevation-0" },
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      "Agent Reservations (" + _vm._s(_vm.agentName) + ")"
                    ),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("v-data-table", {
                    staticClass: "gridView",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.reservations,
                      loading: _vm.isLoading,
                      search: _vm.search,
                      pagination: _vm.pagination,
                    },
                    on: {
                      "update:pagination": function ($event) {
                        _vm.pagination = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function ({ item }) {
                          return [
                            _c("td", [_vm._v(_vm._s(item.sid))]),
                            _c("td", [_vm._v(_vm._s(item.status))]),
                            _c("td", [_vm._v(_vm._s(item.dateCreated))]),
                            _c("td", [_vm._v(_vm._s(item.dateUpdated))]),
                            _c(
                              "td",
                              { staticClass: "text-xs-right px-0" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass: "mx-0",
                                                    attrs: {
                                                      small: "",
                                                      color: "error",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onEndClick(
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" End Reservation ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("End Reservation")])]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }