var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "taskboard-wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading.jobs,
            expression: "!loading.jobs",
          },
        ],
        staticClass: "jobs-taskboard",
      },
      [
        _c(
          "v-layout",
          { staticClass: "scrollmenu pa-0", attrs: { wrap: "" } },
          _vm._l(_vm.getJobs, function (item, index) {
            return _c(
              "v-flex",
              { key: index, attrs: { xs3: "" } },
              [
                _c("USJobStatusBar", {
                  key: index,
                  ref: "jobStatusBar",
                  refInFor: true,
                  attrs: { record: item },
                  on: { filterDelay: _vm.filterDelay },
                }),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading.jobs,
            expression: "loading.jobs",
          },
        ],
      },
      [
        _c("v-progress-circular", {
          staticStyle: { position: "absolute", top: "400px", left: "50%" },
          attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }