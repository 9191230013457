var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _c("div", { staticClass: "mb-2" }, [
          _c("h4", [
            _vm._v(
              _vm._s(_vm.item.id ? " Update" : "Add") +
                " Repair Estimate Detail"
            ),
          ]),
        ]),
        _vm.item.products.length > 0
          ? _c(
              "div",
              _vm._l(_vm.item.products, function (product, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    product.subCategory
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Sub Category: ")]),
                          _c("span", [_vm._v(_vm._s(product.subCategory))]),
                        ])
                      : _vm._e(),
                    product.description
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Description: ")]),
                          _c("span", [_vm._v(_vm._s(product.description))]),
                        ])
                      : _vm._e(),
                    product.unit
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Unit: ")]),
                          _c("span", [_vm._v(_vm._s(product.unit))]),
                        ])
                      : _vm._e(),
                    product.unitPrice
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Unit Price: ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getFormatedCurrency(product.unitPrice))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    product.vatRate
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Vat Rate(%): ")]),
                          _c("span", [_vm._v(_vm._s(product.vatRate))]),
                        ])
                      : _vm._e(),
                    product.quantity
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Quantity: ")]),
                          _c("span", [_vm._v(_vm._s(product.quantity))]),
                        ])
                      : _vm._e(),
                    product.clientPrice
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Client Price: ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getFormatedCurrency(product.clientPrice)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    product.repairCost
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [_vm._v("Repair Cost: ")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.getFormatedCurrency(product.repairCost)
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    product.customerAppointedTradePrice
                      ? _c("div", { staticClass: "mb-1" }, [
                          _c("label", [
                            _vm._v("Customer Appointed Trade Price: "),
                          ]),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getFormatedCurrency(
                                    product.customerAppointedTradePrice
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    index !== _vm.item.products.length - 1
                      ? _c("v-divider", { staticClass: "mb-1" })
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }