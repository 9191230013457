var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    {
      staticClass: "elevation-1 center-sec-layout",
      class: _vm.isEdit ? "pb-0" : "pb-2",
    },
    [
      _vm.isEdit
        ? _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Document Template")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.Close } },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-text",
        {
          staticClass: "templateContent",
          class: _vm.isEdit ? "pt-0 user-temp scroll-content-dialog" : "pt-0",
        },
        [
          _c(
            "v-form",
            { staticClass: "template-form", attrs: { autocomplete: "off" } },
            [
              _c(
                "v-layout",
                { staticClass: "mt-2", attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Search insurer",
                          required: "",
                          items: _vm.insurers,
                          loading: _vm.insurerSearchRunning,
                          "item-text": "name",
                          "item-value": "id",
                          disabled: _vm.isEdit,
                          "data-vv-scope": "templateformRef",
                          "data-vv-name": "Search insurer",
                          "error-messages":
                            _vm.errors.collect("Search insurer"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onInsurerChange($event)
                          },
                        },
                        model: {
                          value: _vm.getDocumentTemplate.insurerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.getDocumentTemplate, "insurerId", $$v)
                          },
                          expression: "getDocumentTemplate.insurerId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Search template type",
                          required: "",
                          disabled: _vm.isEdit,
                          items: _vm.templatetypes,
                          loading: _vm.templatetypeSearchRunning,
                          "item-text": "description",
                          "item-value": "templateTypeId",
                          "data-vv-scope": "templateformRef",
                          "data-vv-name": "Search template type",
                          "error-messages": _vm.errors.collect(
                            "Search template type"
                          ),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onTemplateTypeChange($event)
                          },
                        },
                        model: {
                          value: _vm.getDocumentTemplate.templateTypeId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getDocumentTemplate,
                              "templateTypeId",
                              $$v
                            )
                          },
                          expression: "getDocumentTemplate.templateTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "newindicator-chip" },
                      [
                        _c("v-combobox", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:100",
                              expression: "'required|max:100'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Search name",
                            maxlength: "100",
                            required: "",
                            "return-object": "",
                            disabled: _vm.isEdit,
                            items: _vm.docTemplatesbyTemplateType,
                            loading: _vm.documentTemplateSearchRunning,
                            "item-text": "name",
                            "item-value": "templateId",
                            "data-vv-scope": "templateformRef",
                            "data-vv-name": "Search name",
                            "error-messages": _vm.errors.collect("Search name"),
                            "data-vv-delay": _vm.validationDelay,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onTemplateChange($event)
                            },
                          },
                          model: {
                            value: _vm.selectedDocumentTemplate,
                            callback: function ($$v) {
                              _vm.selectedDocumentTemplate = $$v
                            },
                            expression: "selectedDocumentTemplate",
                          },
                        }),
                        _c(
                          "v-chip",
                          {
                            staticClass: "white--text",
                            attrs: {
                              color: "secondary",
                              small: "",
                              disabled: "",
                              value: _vm.IsNewTemplate,
                            },
                          },
                          [_vm._v("New")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-1": "" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          items: _vm.delayTimeHourList,
                          label: "Delay Time (Hours)",
                          "item-text": "text",
                          "item-value": "value",
                          "search-input": _vm.delayTimeHour,
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.delayTimeHour = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.delayTimeHour = $event
                          },
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.delayTimeHour,
                          callback: function ($$v) {
                            _vm.delayTimeHour = $$v
                          },
                          expression: "delayTimeHour",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-combobox", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "between:0,59",
                            expression: "'between:0,59'",
                          },
                        ],
                        attrs: {
                          items: _vm.delayTimeMinuteList,
                          label: "Delay Time (Minutes)",
                          "item-text": "text",
                          "item-value": "value",
                          "data-vv-scope": "templateformRef",
                          "data-vv-name": "Delay Time",
                          "error-messages": _vm.errors.collect("Delay Time"),
                          "search-input": _vm.delayTimeMinute,
                        },
                        on: {
                          "update:searchInput": function ($event) {
                            _vm.delayTimeMinute = $event
                          },
                          "update:search-input": function ($event) {
                            _vm.delayTimeMinute = $event
                          },
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.delayTimeMinute,
                          callback: function ($$v) {
                            _vm.delayTimeMinute = $$v
                          },
                          expression: "delayTimeMinute",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value:
                            _vm.getDocumentTemplate
                              .isCommunicationWindowApplied,
                          label: "Notification window (8am-10pm) applies",
                          color: "primary",
                        },
                        model: {
                          value:
                            _vm.getDocumentTemplate
                              .isCommunicationWindowApplied,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getDocumentTemplate,
                              "isCommunicationWindowApplied",
                              $$v
                            )
                          },
                          expression:
                            "getDocumentTemplate.isCommunicationWindowApplied",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm8: "" } },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: { row: "", mandatory: false },
                          model: {
                            value:
                              _vm.getDocumentTemplate.notificationPreferenceId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.getDocumentTemplate,
                                "notificationPreferenceId",
                                $$v
                              )
                            },
                            expression:
                              "getDocumentTemplate.notificationPreferenceId",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: {
                              label: "SMS",
                              color: "primary",
                              value: _vm.DocumentPreference.SMS,
                            },
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "Email",
                              color: "primary",
                              value: _vm.DocumentPreference.Email,
                            },
                          }),
                          _c("v-radio", {
                            attrs: {
                              label: "Both",
                              color: "primary",
                              value: _vm.DocumentPreference.Both,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showSMS,
                          expression: "showSMS",
                        },
                      ],
                      staticClass: "pa-3 bg-gray elevation-1",
                      attrs: { xs12: "" },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "h3",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "secondary" } },
                                  [_vm._v("sms")]
                                ),
                                _vm._v(" SMS "),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.showSMS
                                      ? "required|max:500"
                                      : "max:500",
                                    expression:
                                      "showSMS ? 'required|max:500' : 'max:500'",
                                  },
                                ],
                                class: _vm.showSMS ? "required" : "",
                                attrs: {
                                  label: "Description",
                                  maxlength: "500",
                                  required: _vm.showSMS,
                                  counter: 500,
                                  "data-vv-scope": "templateformRef",
                                  "data-vv-name": "Description",
                                  "error-messages":
                                    _vm.errors.collect("Description"),
                                },
                                model: {
                                  value: _vm.getDocumentTemplate.smsText,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.getDocumentTemplate,
                                      "smsText",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "getDocumentTemplate.smsText",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showEmail,
                          expression: "showEmail",
                        },
                      ],
                      staticClass: "pa-3 mt-3 bg-gray elevation-1",
                      attrs: { xs12: "" },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "h3",
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { small: "", color: "secondary" } },
                                  [_vm._v("email")]
                                ),
                                _vm._v(" Email "),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "v-flex",
                            { staticClass: "mb-3", attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.showEmail
                                      ? "required|max:200"
                                      : "max:200",
                                    expression:
                                      "showEmail ? 'required|max:200' : 'max:200'",
                                  },
                                ],
                                class: _vm.showEmail ? "required" : "",
                                attrs: {
                                  label: "Subject",
                                  maxlength: "200",
                                  required: _vm.showEmail,
                                  "data-vv-scope": "templateformRef",
                                  "data-vv-name": "Subject",
                                  "error-messages":
                                    _vm.errors.collect("Subject"),
                                },
                                model: {
                                  value: _vm.getDocumentTemplate.subject,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.getDocumentTemplate,
                                      "subject",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "getDocumentTemplate.subject",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              [
                                _c("froala", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: _vm.showEmail ? "required" : "",
                                      expression: "showEmail ? 'required' : ''",
                                    },
                                  ],
                                  class: _vm.showEmail ? "required" : "",
                                  attrs: {
                                    id: "froala",
                                    required: _vm.showEmail,
                                    "data-vv-scope": "templateformRef",
                                    "data-vv-name": "Email",
                                    "error-messages":
                                      _vm.ValidateEditor("Email"),
                                    config: _vm.config,
                                    tag: "textarea",
                                  },
                                  model: {
                                    value: _vm.getDocumentTemplate.emailText,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.getDocumentTemplate,
                                        "emailText",
                                        $$v
                                      )
                                    },
                                    expression: "getDocumentTemplate.emailText",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group__details" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group__messages input-group__error input-group--error error--text",
                                      },
                                      [
                                        _vm.ValidateEditor("Email")
                                          ? _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.ValidateEditor("Email")
                                                  ) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showClientEmail
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "pa-3 mt-3 bg-gray elevation-1",
                          attrs: { xs12: "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "h3",
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          small: "",
                                          color: "secondary",
                                        },
                                      },
                                      [_vm._v("email")]
                                    ),
                                    _vm._v(" Client Email "),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-flex",
                                { staticClass: "mb-3", attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "max:200",
                                        expression: "'max:200'",
                                      },
                                    ],
                                    attrs: {
                                      label: "Subject",
                                      maxlength: "200",
                                      "data-vv-scope": "templateformRef",
                                      "data-vv-name": "Client Subject",
                                    },
                                    model: {
                                      value:
                                        _vm.getDocumentTemplate.clientSubject,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.getDocumentTemplate,
                                          "clientSubject",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "getDocumentTemplate.clientSubject",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "div",
                                  [
                                    _c("froala", {
                                      attrs: {
                                        id: "clientFroala",
                                        required: _vm.showEmail,
                                        "data-vv-scope": "templateformRef",
                                        "data-vv-name": "Client Email",
                                        config: _vm.config,
                                        tag: "textarea",
                                      },
                                      model: {
                                        value:
                                          _vm.getDocumentTemplate
                                            .clientEmailText,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.getDocumentTemplate,
                                            "clientEmailText",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "getDocumentTemplate.clientEmailText",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _vm.isEdit
            ? _c("v-btn", { on: { click: _vm.Close } }, [_vm._v("Close")])
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary",
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
              },
              on: { click: _vm.saveDocumentTemplate },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }