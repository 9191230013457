var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mb-0 failedToContactCallCard" }, [
    _c(
      "div",
      { staticClass: "card-inner-detail" },
      [
        _vm.getUnavailableContractors &&
        _vm.getUnavailableContractors.length > 0
          ? _vm._l(_vm.getUnavailableContractors, function (item, j) {
              return _c(
                "v-tooltip",
                {
                  key: j,
                  attrs: { "content-class": "newcustom-tooltip", right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-avatar",
                              _vm._g(
                                { attrs: { color: "indigo", size: "42" } },
                                on
                              ),
                              [
                                _c("img", {
                                  staticClass: "icon-img",
                                  attrs: { src: "/img/miss_call.svg" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onMissedCallClick(item)
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "span",
                    [
                      _c("span", [
                        _c("label", [_vm._v("Reason:")]),
                        _c("span", [_vm._v(_vm._s(item.reasonType))]),
                      ]),
                      _c("br"),
                      item.reasonDescription
                        ? [
                            _c("span", [
                              _c("label", [_vm._v("Description:")]),
                              _c("span", [
                                _vm._v(_vm._s(item.reasonDescription)),
                              ]),
                            ]),
                            _c("br"),
                          ]
                        : _vm._e(),
                      _c("span", [
                        _c("label", [_vm._v("Contractor:")]),
                        _c("span", [_vm._v(_vm._s(item.companyName))]),
                      ]),
                      _c("br"),
                      _c("span", [
                        _c("label", [_vm._v("Call At:")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.getFormattedDate(item.createdAt))),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]
              )
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }