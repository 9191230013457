var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.createdJobId
    ? _c(
        "div",
        [
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0",
              attrs: { "non-linear": "" },
              model: {
                value: _vm.stepIndex,
                callback: function ($$v) {
                  _vm.stepIndex = $$v
                },
                expression: "stepIndex",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "si-job-step-1",
                      attrs: {
                        step: 1,
                        editable: _vm.stepIndex > 1,
                        complete: _vm.step1validated,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.switchToStep(1)
                        },
                      },
                    },
                    [_vm._v(" Policy Details ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "si-job-step-2",
                      attrs: {
                        step: 2,
                        editable: _vm.step1validated && _vm.stepIndex > 1,
                        complete: _vm.step2validated,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.switchToStep(2)
                        },
                      },
                    },
                    [_vm._v(" Select package ")]
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    {
                      staticClass: "si-job-step-3",
                      attrs: {
                        step: 3,
                        editable:
                          _vm.step1validated &&
                          _vm.step2validated &&
                          _vm.stepIndex > 1,
                        complete: _vm.wizardSubmitStatus === "submitted",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.switchToStep(3)
                        },
                      },
                    },
                    [_vm._v(" Contact Preferences ")]
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-form",
                    { attrs: { autocomplete: "off" } },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pa-0": "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          _vm.policies
                                            ? _c("PolicySelector", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                ref: "policySelectorRef",
                                                staticClass: "mb-4 pb-0 pl-0",
                                                staticStyle: {
                                                  "padding-top": "10px",
                                                },
                                                attrs: {
                                                  id: "policySelector",
                                                  type: "number",
                                                  required: "",
                                                  "policy-tree": _vm.policies,
                                                  "error-messages":
                                                    _vm.validationMessage(
                                                      "Selected Policy"
                                                    ),
                                                  "data-vv-scope":
                                                    _vm.step1formRef,
                                                  "data-vv-name":
                                                    "Selected Policy",
                                                  "selected-policy":
                                                    _vm.selectedPolicy,
                                                },
                                                on: {
                                                  "update:selectedPolicy":
                                                    _vm.updatePolicySelection,
                                                },
                                                model: {
                                                  value: _vm.selectedPolicy,
                                                  callback: function ($$v) {
                                                    _vm.selectedPolicy = $$v
                                                  },
                                                  expression: "selectedPolicy",
                                                },
                                              })
                                            : _c("v-select", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: "required",
                                                    expression: "'required'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled: true,
                                                  type: "number",
                                                  placeholder:
                                                    _vm.claimModel.policyScheme,
                                                  "data-vv-name":
                                                    "Selected Policy",
                                                },
                                                model: {
                                                  value:
                                                    _vm.claimModel
                                                      .policyScheduleId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.claimModel,
                                                      "policyScheduleId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "claimModel.policyScheduleId",
                                                },
                                              }),
                                          _vm.showDisclaimer
                                            ? _c(
                                                "v-alert",
                                                {
                                                  staticClass:
                                                    "pa-0 waring-maping",
                                                  attrs: {
                                                    color: "warning",
                                                    icon: "priority_high",
                                                    dismissible: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " DISCLAIMER: " +
                                                      _vm._s(
                                                        _vm.disclaimerMessage
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-alert",
                                            {
                                              staticClass: "pa-0",
                                              staticStyle: {
                                                border: "0px !important",
                                                top: "-15px !important",
                                              },
                                              attrs: {
                                                color: "warning",
                                                icon: "priority_high",
                                                outline: "",
                                              },
                                              model: {
                                                value: _vm.isAssumedCoverPolicy,
                                                callback: function ($$v) {
                                                  _vm.isAssumedCoverPolicy = $$v
                                                },
                                                expression:
                                                  "isAssumedCoverPolicy",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " This is an assumed cover policy, you should create a job even if you can't find the customer in the validation database. "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "py-0": "" } },
                                    [
                                      _c("CustomerAddress", {
                                        ref: "addCustomerAddress",
                                        attrs: {
                                          "passed-client-title":
                                            _vm.getClientTitle,
                                          "passed-first-name": _vm.getFirstName,
                                          "passed-last-name": _vm.getLastName,
                                          "passed-policy-number":
                                            _vm.getPolicyNumber,
                                          "passed-address-line1":
                                            _vm.getAddressLine1,
                                          "passed-address-line2":
                                            _vm.getAddressLine2,
                                          "passed-address-line3":
                                            _vm.getAddressLine3,
                                          "passed-address-line4":
                                            _vm.getAddressLine4,
                                          "passed-post-code": _vm.getPostCode,
                                          "is-display-policy-number": true,
                                          "is-s-i-job": true,
                                        },
                                        on: {
                                          "update:passedClientTitle": function (
                                            $event
                                          ) {
                                            _vm.getClientTitle = $event
                                          },
                                          "update:passed-client-title":
                                            function ($event) {
                                              _vm.getClientTitle = $event
                                            },
                                          "update:passedFirstName": function (
                                            $event
                                          ) {
                                            _vm.getFirstName = $event
                                          },
                                          "update:passed-first-name": function (
                                            $event
                                          ) {
                                            _vm.getFirstName = $event
                                          },
                                          "update:passedLastName": function (
                                            $event
                                          ) {
                                            _vm.getLastName = $event
                                          },
                                          "update:passed-last-name": function (
                                            $event
                                          ) {
                                            _vm.getLastName = $event
                                          },
                                          "update:passedPolicyNumber":
                                            function ($event) {
                                              _vm.getPolicyNumber = $event
                                            },
                                          "update:passed-policy-number":
                                            function ($event) {
                                              _vm.getPolicyNumber = $event
                                            },
                                          "update:passedAddressLine1":
                                            function ($event) {
                                              _vm.getAddressLine1 = $event
                                            },
                                          "update:passed-address-line1":
                                            function ($event) {
                                              _vm.getAddressLine1 = $event
                                            },
                                          "update:passedAddressLine2":
                                            function ($event) {
                                              _vm.getAddressLine2 = $event
                                            },
                                          "update:passed-address-line2":
                                            function ($event) {
                                              _vm.getAddressLine2 = $event
                                            },
                                          "update:passedAddressLine3":
                                            function ($event) {
                                              _vm.getAddressLine3 = $event
                                            },
                                          "update:passed-address-line3":
                                            function ($event) {
                                              _vm.getAddressLine3 = $event
                                            },
                                          "update:passedAddressLine4":
                                            function ($event) {
                                              _vm.getAddressLine4 = $event
                                            },
                                          "update:passed-address-line4":
                                            function ($event) {
                                              _vm.getAddressLine4 = $event
                                            },
                                          "update:passedPostCode": function (
                                            $event
                                          ) {
                                            _vm.getPostCode = $event
                                          },
                                          "update:passed-post-code": function (
                                            $event
                                          ) {
                                            _vm.getPostCode = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs6: "",
                                        "py-0": "",
                                        "pt-2": "",
                                      },
                                    },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "dateTimePicker",
                                        attrs: {
                                          "date-time": _vm.instructionDate,
                                          "is-static-location": false,
                                          "place-holder-text":
                                            "Instruction Received",
                                          "is-current-time": true,
                                          "is-validation-required": true,
                                          "show-present-time-icon": true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.instructionDate = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.instructionDate = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs6: "",
                                        "py-0": "",
                                        "pt-2": "",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Client Reference",
                                          required: "",
                                          name: "ClientReference",
                                          "data-vv-name": "Client Reference",
                                          "data-vv-scope": _vm.step1formRef,
                                          "error-messages":
                                            _vm.validationMessage(
                                              "Client Reference"
                                            ),
                                        },
                                        model: {
                                          value: _vm.claimModel.customerRef,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.claimModel,
                                              "customerRef",
                                              $$v
                                            )
                                          },
                                          expression: "claimModel.customerRef",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pr-2": "" } },
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Claim Management Company",
                                          items: _vm.claimManagementCompanies,
                                          "item-text": "description",
                                          "return-object": "",
                                          required: "",
                                          name: "ClaimManagementCompany",
                                          "data-vv-name":
                                            "Claim Management Company",
                                          "data-vv-scope": _vm.step1formRef,
                                          "error-messages":
                                            _vm.validationMessage(
                                              "Claim Management Company"
                                            ),
                                        },
                                        on: {
                                          change:
                                            _vm.updateClaimManagementCompany,
                                        },
                                        model: {
                                          value:
                                            _vm.selectedClaimManagementCompany,
                                          callback: function ($$v) {
                                            _vm.selectedClaimManagementCompany =
                                              $$v
                                          },
                                          expression:
                                            "selectedClaimManagementCompany",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs6: "", "pl-2": "" } },
                                    [
                                      _c("v-autocomplete", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        staticClass: "required",
                                        attrs: {
                                          label: "Subsidence Consultant",
                                          items: _vm.subsidenceConsultants,
                                          "item-text": "description",
                                          "return-object": "",
                                          required: "",
                                          "data-vv-name":
                                            "Subsidence Consultant",
                                          "data-vv-scope": _vm.step1formRef,
                                          "error-messages":
                                            _vm.validationMessage(
                                              "Subsidence Consultant"
                                            ),
                                        },
                                        on: {
                                          change:
                                            _vm.updateSubsidenceConsultant,
                                        },
                                        model: {
                                          value:
                                            _vm.selectedSubsidenceConsultant,
                                          callback: function ($$v) {
                                            _vm.selectedSubsidenceConsultant =
                                              $$v
                                          },
                                          expression:
                                            "selectedSubsidenceConsultant",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _c(
                    "v-form",
                    { attrs: { autocomplete: "off" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "isIVSJob",
                            attrs: {
                              label: `is an IVS job?`,
                              color: "primary",
                            },
                            on: { change: _vm.clearJobPackageSelection },
                            model: {
                              value: _vm.claimModel.isIVSJob,
                              callback: function ($$v) {
                                _vm.$set(_vm.claimModel, "isIVSJob", $$v)
                              },
                              expression: "claimModel.isIVSJob",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.selectedClaimManagementCompany &&
                      _vm.selectedClaimManagementCompany.id &&
                      !_vm.claimModel.isIVSJob
                        ? _c("PackageSelection", {
                            ref: "packageSelection",
                            staticClass: "package-selection",
                            attrs: {
                              "claim-management-company-id":
                                _vm.selectedClaimManagementCompany.id,
                            },
                            model: {
                              value: _vm.selectedJobPackage,
                              callback: function ($$v) {
                                _vm.selectedJobPackage = $$v
                              },
                              expression: "selectedJobPackage",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.claimModel.isIVSJob
                    ? _c(
                        "v-snackbar",
                        {
                          attrs: {
                            top: "",
                            "multi-line": "",
                            color: "error",
                            timeout: _vm.snackbarTimeout,
                          },
                          model: {
                            value: _vm.showSnackbar,
                            callback: function ($$v) {
                              _vm.showSnackbar = $$v
                            },
                            expression: "showSnackbar",
                          },
                        },
                        [
                          _c("h4", [_vm._v(_vm._s(_vm.snackbarText))]),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.showSnackbar = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _c(
                    "v-form",
                    { attrs: { autocomplete: "off" } },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("ContactPreferenceDetails", {
                                    ref: "contactPreferenceDetails",
                                    attrs: {
                                      "customer-contact-preference-modal":
                                        _vm.customerContactPreferenceModal,
                                    },
                                    on: {
                                      updateContactPreference:
                                        _vm.updateContactPreference,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        [
          _c(
            "v-card",
            { staticClass: "elevation-0 pa-0 upload-document" },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "px-1": "", "mb-2": "" } },
                        [
                          _c("UploadDocument", {
                            ref: "uploadDocument",
                            attrs: {
                              "job-id": _vm.createdJobId,
                              "document-types": _vm.uploadedDocumentTypes,
                              "is-in-wizard-step": true,
                            },
                            on: {
                              documentUploadComplete:
                                _vm.onDocumentUploadComplete,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }