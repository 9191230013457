var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 up-card customer-payment" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "heading" }, [_vm._v("Complaint Details")]),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-2" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label:
                            "Summary of Complaint in the words of the customer",
                          rows: "5",
                          readonly: _vm.isComplaintResolved,
                        },
                        model: {
                          value: _vm.complaintModel.complaintSummary,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.complaintModel,
                              "complaintSummary",
                              $$v
                            )
                          },
                          expression: "complaintModel.complaintSummary",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:50|email",
                              expression: "'required|max:50|email'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Contact Email",
                            placeholder: "Email",
                            maxlength: "50",
                            required: "",
                            "data-vv-scope": "formReference",
                            "data-vv-name": "Contact Email",
                            "error-messages":
                              _vm.errors.collect("Contact Email"),
                            readonly: _vm.isComplaintResolved,
                          },
                          model: {
                            value: _vm.complaintModel.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.complaintModel,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "complaintModel.email",
                          },
                        },
                        [_vm._v(" > ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "radio-group-custom py-0",
                          attrs: { xs12: "" },
                        },
                        [
                          _c("h3", { staticClass: "pb-3" }, [
                            _vm._v("Preferred mode of contact"),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "radio-group-input pt-1",
                              attrs: {
                                mandatory: false,
                                readonly: _vm.isComplaintResolved,
                              },
                              model: {
                                value: _vm.contactPreferenceId,
                                callback: function ($$v) {
                                  _vm.contactPreferenceId = $$v
                                },
                                expression: "contactPreferenceId",
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    grey: "",
                                    "lighten-4": "",
                                    "pt-1": "",
                                    "pb-4": "",
                                    "px-3": "",
                                  },
                                },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pb-0": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          staticClass:
                                            "radio-inline-custom pr-1",
                                          class:
                                            _vm.contactPreferenceId !==
                                            _vm.contactPreference.Call
                                              ? ""
                                              : " show-number",
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              color: "primary",
                                              value: _vm.contactPreference.Call,
                                              label: "Call",
                                            },
                                          }),
                                          _c("PhoneNumber", {
                                            ref: "contactPreferenceCall",
                                            staticClass: "complaint",
                                            attrs: {
                                              "passed-phone-number":
                                                _vm.getCallMobilePhone,
                                              "is-disabled":
                                                _vm.contactPreferenceId !==
                                                  _vm.contactPreference.Call ||
                                                _vm.isComplaintResolved,
                                              "is-validation-required": true,
                                            },
                                            on: {
                                              phoneNumberUpdated:
                                                _vm.phoneNumberUpdated,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mb-3": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { staticClass: "radio-inline-custom" },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              color: "primary",
                                              value: _vm.contactPreference.Post,
                                              label: "Post",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        "pt-3": "",
                                        "mt-3": "",
                                        "mb-2": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { staticClass: "radio-inline-custom" },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              color: "primary",
                                              value:
                                                _vm.contactPreference.Client,
                                              label: "Client",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required severity-dropdown",
                                    attrs: {
                                      items: _vm.complaintSeverityList,
                                      label:
                                        "What is the Severity of the complaint",
                                      "item-value": "id",
                                      required: "",
                                      "data-vv-scope": "formReference",
                                      "data-vv-name": "Complaint Severity",
                                      "error-messages":
                                        _vm.errors.collect(
                                          "Complaint Severity"
                                        ),
                                      readonly: _vm.isComplaintResolved,
                                      disabled:
                                        !_vm.isNewComplaint &&
                                        !_vm.canChangeToFormal,
                                    },
                                    on: { change: _vm.escalationDateRequired },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "selection",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "complaint-severity-type",
                                              },
                                              [_vm._v(_vm._s(item.description))]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  "severity-icon " +
                                                  _vm.severityItemColor(item),
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.severityPriority(item)
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "item",
                                        fn: function ({ item }) {
                                          return [
                                            _c(
                                              "v-list-tile",
                                              { staticClass: "severity-list" },
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        class:
                                                          _vm.severityItemColor(
                                                            item
                                                          ),
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.description
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.complaintModel.severity,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "severity",
                                          $$v
                                        )
                                      },
                                      expression: "complaintModel.severity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.complaintModel.id
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", lg6: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.userList,
                                          "item-text": "displayValue",
                                          "item-value": "id",
                                          label: "Who is responsible",
                                          readonly: _vm.isComplaintResolved,
                                          disabled:
                                            !_vm.canEditResponsiblePerson,
                                        },
                                        model: {
                                          value:
                                            _vm.complaintModel
                                              .responsiblePerson,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.complaintModel,
                                              "responsiblePerson",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "complaintModel.responsiblePerson",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  class: _vm.complaintModel.id ? "lg6" : "lg12",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      items: _vm.complaintTypeList,
                                      "item-text": "description",
                                      "item-value": "id",
                                      label: "What is the type of Complaint",
                                      required: "",
                                      "data-vv-scope": "formReference",
                                      "data-vv-name": "Complaint Type",
                                      "error-messages":
                                        _vm.errors.collect("Complaint Type"),
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    on: { change: _vm.onComplaintTypeChange },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        class:
                                                          data.item
                                                            .description ===
                                                          "Configure"
                                                            ? "bold-select"
                                                            : "",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.complaintModel.complaintType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "complaintType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.complaintType",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Delay Remarks",
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value: _vm.complaintModel.reasonForDelay,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "reasonForDelay",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.reasonForDelay",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("ComplaintDocumentUpload", {
                                ref: "refComplaintDocumentUpload",
                                attrs: {
                                  id:
                                    _vm.complaintModel && _vm.complaintModel.id
                                      ? _vm.complaintModel.id
                                      : _vm.tempComplaintId,
                                  "job-id": _vm.jobId,
                                  "is-complaint-logged":
                                    _vm.complaintModel && _vm.complaintModel.id
                                      ? true
                                      : false,
                                  "document-list": _vm.complaintModel.files,
                                },
                                on: {
                                  deleteDocument: _vm.onDeleteDocument,
                                  uploadDocument: _vm.onUploadDocument,
                                },
                              }),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.yesNoOptions,
                                      "item-text": "description",
                                      "item-value": "description",
                                      label: "Justified",
                                    },
                                    model: {
                                      value: _vm.complaintModel.justified,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "justified",
                                          $$v
                                        )
                                      },
                                      expression: "complaintModel.justified",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg6: "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      multiple: "",
                                      items: _vm.concernsRaisedList,
                                      label: "Concerns Raised",
                                      "item-text": "description",
                                      "item-value": "id",
                                      required: "",
                                      "data-vv-scope": "formReference",
                                      "data-vv-name": "Concerns Raised",
                                      "error-messages":
                                        _vm.errors.collect("Concerns Raised"),
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onConcernsRaisedChange(
                                          _vm.getConcernsRaisedList,
                                          $event
                                        )
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c(
                                                  "v-list-tile-action",
                                                  [
                                                    _vm
                                                      .concernsRaisedIdCheckboxes
                                                      .length > 0 &&
                                                    data.item.description !==
                                                      "Configure"
                                                      ? _c("v-checkbox", {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .concernsRaisedIdCheckboxes[
                                                                _vm.concernsRaisedList.indexOf(
                                                                  data.item
                                                                )
                                                              ].checked,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .concernsRaisedIdCheckboxes[
                                                                  _vm.concernsRaisedList.indexOf(
                                                                    data.item
                                                                  )
                                                                ],
                                                                "checked",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "concernsRaisedIdCheckboxes[concernsRaisedList.indexOf(data.item)].checked",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-tile-content",
                                                  [
                                                    _c(
                                                      "v-list-tile-title",
                                                      {
                                                        class:
                                                          data.item
                                                            .description ===
                                                          "Configure"
                                                            ? "bold-select"
                                                            : "",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              data.item
                                                                .description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.getConcernsRaisedList,
                                      callback: function ($$v) {
                                        _vm.getConcernsRaisedList = $$v
                                      },
                                      expression: "getConcernsRaisedList",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          required: _vm.isOtherConcernRaised,
                                        },
                                        expression:
                                          "{ required: isOtherConcernRaised }",
                                      },
                                    ],
                                    class: _vm.isOtherConcernRaised
                                      ? "required"
                                      : "",
                                    attrs: {
                                      label: "Concerns Raised Description",
                                      rows: "5",
                                      required: "",
                                      "data-vv-scope": "formReference",
                                      "data-vv-name":
                                        "Concerns Raised Description",
                                      "error-messages": _vm.errors.collect(
                                        "Concerns Raised Description"
                                      ),
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel
                                          .concernsRaisedComment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "concernsRaisedComment",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.concernsRaisedComment",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  class:
                                    _vm.complaintModel.isNoticeSent === "Yes"
                                      ? "lg6"
                                      : "",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.yesNoOptions,
                                      "item-text": "description",
                                      "item-value": "description",
                                      label: "Has an 'On Notice' been sent",
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value: _vm.complaintModel.isNoticeSent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "isNoticeSent",
                                          $$v
                                        )
                                      },
                                      expression: "complaintModel.isNoticeSent",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.complaintModel.isNoticeSent === "Yes"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", lg6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "noticeSentDate",
                                        attrs: {
                                          "date-time": _vm.getNoticeSentDate,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "place-holder-text":
                                            "Select date for notice been sent",
                                          disabled: _vm.isComplaintResolved,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.getNoticeSentDate = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.getNoticeSentDate = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                {
                                  class:
                                    _vm.complaintModel.escalationRequired ===
                                    "Yes"
                                      ? "lg6"
                                      : "",
                                  attrs: { xs12: "" },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.escalationOptions,
                                      "item-text": "description",
                                      "item-value": "description",
                                      label:
                                        "Is an escalation to client required?",
                                      disabled: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel.escalationRequired,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "escalationRequired",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.escalationRequired",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.complaintModel.escalationRequired === "Yes"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", lg6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "escalationDate",
                                        attrs: {
                                          "date-time": _vm.getEsacalationDate,
                                          "is-static-location": false,
                                          "show-present-time-icon": true,
                                          "is-current-time": true,
                                          "place-holder-text":
                                            "Select Escalation date",
                                          disabled: _vm.isComplaintResolved,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.getEsacalationDate = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.getEsacalationDate = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg6: "" } },
                                [
                                  _c("DateTimePicker", {
                                    ref: "refReceivedDate",
                                    staticClass: "received-date",
                                    attrs: {
                                      "date-time": _vm.getComplaintReceivedDate,
                                      "is-static-location": false,
                                      "show-present-time-icon": false,
                                      "place-holder-text":
                                        "Complaint Received Date",
                                      disabled:
                                        _vm.isComplaintResolved ||
                                        !_vm.canEditReceivedDate,
                                    },
                                    on: {
                                      "update:dateTime": function ($event) {
                                        _vm.getComplaintReceivedDate = $event
                                      },
                                      "update:date-time": function ($event) {
                                        _vm.getComplaintReceivedDate = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "grey lighten-4 px-3",
                              attrs: { wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-flex", [
                                    _c("h3", [_vm._v("Regulatory Letters")]),
                                  ]),
                                  _c(
                                    "v-flex",
                                    { staticClass: "letters-padding" },
                                    [_vm._v("Initial Response Letter")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "letters-padding" },
                                    [_vm._v("Four Week Letter")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "letters-padding" },
                                    [_vm._v("Eight Week Letter")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "letters-padding" },
                                    [_vm._v("Summary Resolution Communication")]
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "letters-padding" },
                                    [_vm._v("Final Response Letter")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-flex", [
                                    _c("h3", [_vm._v("Required By")]),
                                  ]),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "IRLDueDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.irl.date,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.irl,
                                              "date",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.irl,
                                              "date",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "FWLDueDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.fwl.date,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.fwl,
                                              "date",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.fwl,
                                              "date",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "EWLDueDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.ewl.date,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.ewl,
                                              "date",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.ewl,
                                              "date",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "SRCDueDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.src.date,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.src,
                                              "date",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.src,
                                              "date",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "FRLDueDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.frl.date,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.frl,
                                              "date",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.frl,
                                              "date",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-flex", [_c("h3", [_vm._v("Sent On")])]),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "IRLSentDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.irl.sent,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.irl,
                                              "sent",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.irl,
                                              "sent",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "FWLSentDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.fwl.sent,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.fwl,
                                              "sent",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.fwl,
                                              "sent",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "EWLSentDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.ewl.sent,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.ewl,
                                              "sent",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.ewl,
                                              "sent",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "SRCSentDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.src.sent,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.src,
                                              "sent",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.src,
                                              "sent",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "py-0": "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "FRLSentDate",
                                        attrs: {
                                          "date-time":
                                            _vm.regulatoryLetters.frl.sent,
                                          "is-static-location": false,
                                          "show-present-time-icon": false,
                                          "hide-time-picker": true,
                                          "min-date": _vm.formatDate(
                                            _vm.complaintModel.createdAt
                                          ),
                                          "display-format": "DD/MM/YYYY",
                                          clearable: true,
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.frl,
                                              "sent",
                                              $event
                                            )
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.regulatoryLetters.frl,
                                              "sent",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              staticClass: "grey lighten-4 px-3",
                              attrs: { wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Agreed actions",
                                      rows: "3",
                                      readonly: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value: _vm.complaintModel.agreedAction,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "agreedAction",
                                          $$v
                                        )
                                      },
                                      expression: "complaintModel.agreedAction",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          rules: {
                                            required:
                                              !_vm.isComplaintResolved &&
                                              _vm.complaintModel &&
                                              _vm.complaintModel.isResolved
                                                ? true
                                                : false,
                                          },
                                        },
                                        expression:
                                          "{\n                    rules: {\n                      required: !isComplaintResolved && complaintModel && complaintModel.isResolved ? true : false,\n                    },\n                  }",
                                      },
                                    ],
                                    attrs: {
                                      label: "Summary of resolution",
                                      rows: "3",
                                      readonly: _vm.isComplaintResolved,
                                      required: "",
                                      "data-vv-scope": "formSubmitReference",
                                      "data-vv-name": "Resolution summary",
                                      "error-messages":
                                        _vm.errors.collect(
                                          "Resolution summary"
                                        ),
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel.resolutionSummary,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "resolutionSummary",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.resolutionSummary",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-select",
                                    {
                                      attrs: {
                                        multiple: "",
                                        items: _vm.resolutionCodeList,
                                        label: "Select resolution code",
                                        "item-text": "description",
                                        "item-value": "id",
                                        readonly: _vm.isComplaintResolved,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onResolutionCodeChange(
                                            _vm.getResolutionCodeList,
                                            $event
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "item",
                                          fn: function (data) {
                                            return [
                                              _c(
                                                "v-list-tile",
                                                [
                                                  _c(
                                                    "v-list-tile-action",
                                                    [
                                                      _vm
                                                        .resolutionCodeCheckboxes
                                                        .length > 0 &&
                                                      data.item.description !==
                                                        "Configure"
                                                        ? _c("v-checkbox", {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .resolutionCodeCheckboxes[
                                                                  _vm.resolutionCodeList.indexOf(
                                                                    data.item
                                                                  )
                                                                ].checked,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm
                                                                      .resolutionCodeCheckboxes[
                                                                      _vm.resolutionCodeList.indexOf(
                                                                        data.item
                                                                      )
                                                                    ],
                                                                    "checked",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "resolutionCodeCheckboxes[resolutionCodeList.indexOf(data.item)].checked",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-tile-content",
                                                    [
                                                      _c(
                                                        "v-list-tile-title",
                                                        {
                                                          class:
                                                            data.item
                                                              .description ===
                                                            "Configure"
                                                              ? "bold-select"
                                                              : "",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.item
                                                                  .description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.getResolutionCodeList,
                                        callback: function ($$v) {
                                          _vm.getResolutionCodeList = $$v
                                        },
                                        expression: "getResolutionCodeList",
                                      },
                                    },
                                    [_vm._v(" > ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", lg6: "" } },
                                [
                                  _c("DateTimePicker", {
                                    ref: "complaintResolution",
                                    attrs: {
                                      "date-time": _vm.getDateOfResolution,
                                      "is-static-location": false,
                                      min: _vm.setMinDate,
                                      "show-present-time-icon": false,
                                      "place-holder-text":
                                        "Expected date of resolution",
                                      disabled:
                                        _vm.isComplaintResolved ||
                                        !(
                                          _vm.getResolutionCodeList &&
                                          _vm.getResolutionCodeList.length > 0
                                        ),
                                    },
                                    on: {
                                      "update:dateTime": function ($event) {
                                        _vm.getDateOfResolution = $event
                                      },
                                      "update:date-time": function ($event) {
                                        _vm.getDateOfResolution = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.complaint.rootCause
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pb-2": "" } },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          attrs: {
                                            items: _vm.rootCauseList,
                                            "item-text": "description",
                                            "item-value": "id",
                                            label: "Complaint root cause",
                                            readonly: "",
                                          },
                                          on: {
                                            change:
                                              _vm.onComplaintRootCauseChange,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "v-list-tile",
                                                      [
                                                        _c(
                                                          "v-list-tile-content",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              {
                                                                class:
                                                                  data.item
                                                                    .description ===
                                                                  "Configure"
                                                                    ? "bold-select"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .description
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3659597833
                                          ),
                                          model: {
                                            value: _vm.complaintModel.rootCause,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.complaintModel,
                                                "rootCause",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "complaintModel.rootCause",
                                          },
                                        },
                                        [_vm._v(" > ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.complaint.rootCauseComment
                                    ? _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "pb-2": "" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              label: "Root Cause Description",
                                              rows: "3",
                                              readonly: "",
                                            },
                                            model: {
                                              value:
                                                _vm.complaintModel
                                                  .rootCauseComment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.complaintModel,
                                                  "rootCauseComment",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "complaintModel.rootCauseComment",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.complaintModel.escalationReasonId
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-select", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: "required",
                                            expression: "'required'",
                                          },
                                        ],
                                        attrs: {
                                          label: "Escalation Reason",
                                          items:
                                            _vm.complaintEscalationResonList,
                                          "item-text": "description",
                                          "item-value": "id",
                                          readonly: "",
                                        },
                                        model: {
                                          value:
                                            _vm.complaintModel
                                              .escalationReasonId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.complaintModel,
                                              "escalationReasonId",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "complaintModel.escalationReasonId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          rows: "3",
                                          label: "Escalation Description",
                                          readonly: "",
                                        },
                                        model: {
                                          value:
                                            _vm.complaintModel
                                              .escalationDescription,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.complaintModel,
                                              "escalationDescription",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression:
                                            "complaintModel.escalationDescription",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      label: "Compensation payment by cheque",
                                      color: "primary",
                                      "hide-details": "",
                                      readonly: _vm.isComplaintResolved,
                                      disabled: _vm.isComplaintResolved,
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel
                                          .isCompensationByCheque,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "isCompensationByCheque",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.isCompensationByCheque",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            {
                              class: _vm.complaintPaymentShow
                                ? "complaint-payment grey lighten-4 px-3 elevation-1"
                                : "complaint-payment grey lighten-4 px-3 elevation-1 hide",
                              attrs: { wrap: "" },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    staticClass: "required",
                                    attrs: {
                                      label: "Select Reason for payment",
                                      items: _vm.reasonForPaymentList,
                                      "item-text": "description",
                                      "item-value": "id",
                                      "return-object": "",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "Payment Reason",
                                      "error-messages":
                                        _vm.errors.collect("Payment Reason"),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.onPaymentReasonChange($event)
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function (data) {
                                          return [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c(
                                                  "v-list-tile-title",
                                                  {
                                                    class:
                                                      data.item.description ===
                                                      "Configure"
                                                        ? "bold-select"
                                                        : "",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          data.item.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                    model: {
                                      value: _vm.paymentReason,
                                      callback: function ($$v) {
                                        _vm.paymentReason = $$v
                                      },
                                      expression: "paymentReason",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "First Name",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "First Name",
                                      "error-messages":
                                        _vm.errors.collect("First Name"),
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel
                                          .accountHolderFirstName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "accountHolderFirstName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.accountHolderFirstName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Last Name",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "Last Name",
                                      "error-messages":
                                        _vm.errors.collect("Last Name"),
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel
                                          .accountHolderLastName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "accountHolderLastName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.accountHolderLastName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Address",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "Address",
                                      "error-messages":
                                        _vm.errors.collect("Address"),
                                      rows: "3",
                                    },
                                    model: {
                                      value:
                                        _vm.complaintModel.accountHolderAddress,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "accountHolderAddress",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.accountHolderAddress",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Account No",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "Account No",
                                      "error-messages":
                                        _vm.errors.collect("Account No"),
                                    },
                                    model: {
                                      value: _vm.complaintModel.accountNumber,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "accountNumber",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.accountNumber",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "IFSC Code",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "IFSC Code",
                                      "error-messages":
                                        _vm.errors.collect("IFSC Code"),
                                    },
                                    model: {
                                      value: _vm.complaintModel.IFSCCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "IFSCCode",
                                          $$v
                                        )
                                      },
                                      expression: "complaintModel.IFSCCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-flex", { attrs: { xs4: "" } }, [
                                _c(
                                  "div",
                                  { staticClass: "amount-icon" },
                                  [
                                    _c("span", [_vm._v("£")]),
                                    _c("v-text-field", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: "required",
                                          expression: "'required'",
                                        },
                                      ],
                                      staticClass: "required",
                                      attrs: {
                                        label: "Amount",
                                        required: "",
                                        "data-vv-scope": "paymentDetails",
                                        "data-vv-name": "Amount",
                                        "error-messages":
                                          _vm.errors.collect("Amount"),
                                      },
                                      on: {
                                        keypress: function ($event) {
                                          return _vm.numberKeyValidation($event)
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.complaintModel.compensationPaid,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.complaintModel,
                                            "compensationPaid",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "complaintModel.compensationPaid",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Lessons learned",
                                      required: "",
                                      "data-vv-scope": "paymentDetails",
                                      "data-vv-name": "Lessons learned",
                                      "error-messages":
                                        _vm.errors.collect("Lessons learned"),
                                      rows: "3",
                                    },
                                    model: {
                                      value: _vm.complaintModel.lessonsLearned,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.complaintModel,
                                          "lessonsLearned",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "complaintModel.lessonsLearned",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "text-xs-right": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-0",
                                      attrs: {
                                        color: "primary",
                                        loading: _vm.savePaymentLoading,
                                        disabled: _vm.savePaymentLoading,
                                      },
                                      on: { click: _vm.savePaymentDetails },
                                    },
                                    [_vm._v(" Pay ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.getJobComplaintNotes &&
                          _vm.getJobComplaintNotes.length > 0
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "pt-0": "" } },
                                    [_c("v-divider", { staticClass: "mt-3" })],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "", "mb-2": "" } },
                                    [
                                      _c("h3", [
                                        _vm._v(
                                          "Complaint Notes (" +
                                            _vm._s(
                                              _vm.getJobComplaintNotes.length
                                            ) +
                                            ")"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    _vm._l(
                                      _vm.getJobComplaintNotes,
                                      function (complaint, index) {
                                        return _c(
                                          "v-layout",
                                          {
                                            key: index,
                                            attrs: {
                                              wrap: "",
                                              grey: "",
                                              "lighten-4": "",
                                              "py-1": "",
                                              "mb-3": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "py-1": "" },
                                              },
                                              [
                                                _c("span", [
                                                  _c("b", [_vm._v("Title :")]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(complaint.title) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "py-1": "" },
                                              },
                                              [
                                                _c("span", [
                                                  _c("b", [
                                                    _vm._v("Emergency Type :"),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        complaint.forEmergencyDetailDescription
                                                          ? complaint.forEmergencyDetailDescription
                                                          : "All"
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: { xs12: "", "py-1": "" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "yellow lighten-4 pa-2",
                                                  },
                                                  [
                                                    _c("b", [_vm._v("Note :")]),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          complaint.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "claim-btn-content grey lighten-3" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-0 grey lighten-3",
                      attrs: { wrap: "" },
                    },
                    [
                      _c(
                        "v-card-actions",
                        { staticClass: "complaint-btns", attrs: { xs12: "" } },
                        [
                          !_vm.isComplaintResolved
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "sticky-btn-content" },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-0 save-btn",
                                        attrs: {
                                          color: "primary",
                                          loading: _vm.isLoading,
                                          disabled:
                                            _vm.isLoading ||
                                            _vm.isComplaintResolved,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.onComplaintSave()
                                          },
                                        },
                                      },
                                      [_vm._v(" Save ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        class:
                                          _vm.showPaymentButton ||
                                          _vm.isComplaintResolved
                                            ? "mr-0"
                                            : "hide mr-0",
                                        attrs: { color: "primary", dark: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.makePayment()
                                          },
                                        },
                                      },
                                      [_vm._v(" Make a Payment ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.complaintModel.id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "sticky-btn-content mt-3",
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "blue white--text",
                                            attrs: {
                                              loading:
                                                _vm.loadListOfTemplatesInProgress,
                                              disabled:
                                                _vm.loadListOfTemplatesInProgress,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.createComplaintDocument()
                                              },
                                            },
                                          },
                                          [_vm._v(" Create Document ")]
                                        ),
                                        _c("v-spacer"),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              top: "",
                                              disabled: _vm.complaintModel
                                                .resolutionSummary
                                                ? true
                                                : false,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        _vm._g({}, on),
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "green white--text mr-2",
                                                                attrs: {
                                                                  disabled:
                                                                    !_vm
                                                                      .complaintModel
                                                                      .resolutionSummary ||
                                                                    _vm.loading,
                                                                  loading:
                                                                    _vm.loading,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.openResolveComplaintDialog,
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _vm._v(
                                                                " Resolve "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3185626318
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "summary of resolution required to mark complaint as resolved"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "primary white--text",
                                            attrs: {
                                              disabled:
                                                _vm.isLoadingEscalateDocuments,
                                              loading:
                                                _vm.isLoadingEscalateDocuments,
                                            },
                                            on: {
                                              click:
                                                _vm.openComplaintEscalateDialog,
                                            },
                                          },
                                          [_vm._v(" Escalate ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm.isComplaintResolved &&
                          _vm.hasComplaintResolutionSkills
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "blue white--text",
                                    attrs: {
                                      loading:
                                        _vm.loadListOfTemplatesInProgress,
                                      disabled:
                                        _vm.loadListOfTemplatesInProgress,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.createComplaintDocument()
                                      },
                                    },
                                  },
                                  [_vm._v(" Create Document ")]
                                ),
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "primary white--text",
                                    attrs: {
                                      loading: _vm.isLoading,
                                      disabled:
                                        _vm.isLoading ||
                                        !_vm.canReOpenComplaint,
                                    },
                                    on: { click: _vm.reOpenConfirmationDialog },
                                  },
                                  [_vm._v(" Re-open Complaint ")]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showComplaintDocumentTemplateSelection
        ? _c("ReportDocumentTemplate", {
            ref: "refReportDocumentTemplate",
            attrs: {
              "document-template-list": _vm.complaintDocumentTemplateList,
            },
            on: {
              getDocumentTemplateText: _vm.getDocumentTemplateText,
              closeDocumentTemplateDialog: _vm.closeDocumentTemplateDialog,
              saveDocument: _vm.saveDocument,
              showSnackBarWithMessage: _vm.showSnackBarWithMessage,
            },
          })
        : _vm._e(),
      _vm.showResolveComplaintDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showResolveComplaintDialog,
                callback: function ($$v) {
                  _vm.showResolveComplaintDialog = $$v
                },
                expression: "showResolveComplaintDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Resolve Complaint")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showResolveComplaintDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c(
                        "span",
                        { staticClass: "warning--text mb-1" },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "md-icon pr-2",
                              attrs: { color: "orange" },
                            },
                            [_vm._v("info")]
                          ),
                          _vm._v(
                            " Once complaint is resolved, you won't be able to edit it. "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              items: _vm.rootCauseList,
                              "item-text": "description",
                              "item-value": "id",
                              label: "Select Complaint Root Cause",
                              required: "",
                              "data-vv-scope": "formResolveComplaint",
                              "data-vv-name": "Complaint Root Cause",
                              "error-messages": _vm.errors.collect(
                                "Complaint Root Cause"
                              ),
                              readonly: _vm.isComplaintResolved,
                            },
                            on: { change: _vm.onComplaintRootCauseChange },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  class:
                                                    data.item.description ===
                                                    "Configure"
                                                      ? "bold-select"
                                                      : "",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3659597833
                            ),
                            model: {
                              value: _vm.rootCause,
                              callback: function ($$v) {
                                _vm.rootCause = $$v
                              },
                              expression: "rootCause",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-textarea", {
                        attrs: {
                          label: "Root Cause Description",
                          rows: "5",
                          "data-vv-name": "Root Cause Comment",
                          readonly: _vm.isComplaintResolved,
                        },
                        model: {
                          value: _vm.rootCauseComment,
                          callback: function ($$v) {
                            _vm.rootCauseComment = $$v
                          },
                          expression: "rootCauseComment",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showResolveComplaintDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.loading,
                            loading: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resolveComplaint()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.complaintEscalateDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "700" },
              model: {
                value: _vm.complaintEscalateDialog,
                callback: function ($$v) {
                  _vm.complaintEscalateDialog = $$v
                },
                expression: "complaintEscalateDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Complaint Escalation")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.complaintEscalateDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Select Reason",
                              items: _vm.complaintEscalationResonList,
                              "item-text": "description",
                              "item-value": "id",
                              "data-vv-scope": "formEscalationReference",
                              "data-vv-name": "Escalation Reason",
                              required: "",
                              "error-messages":
                                _vm.errors.collect("Escalation Reason"),
                            },
                            on: {
                              change: _vm.onComplaintEscalationResonChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  class:
                                                    data.item.description ===
                                                    "Configure"
                                                      ? "bold-select"
                                                      : "",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3659597833
                            ),
                            model: {
                              value: _vm.complaintEscalation.escalationReasonId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.complaintEscalation,
                                  "escalationReasonId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "complaintEscalation.escalationReasonId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-textarea",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                rows: "5",
                                label: "Description",
                                required: "",
                                "data-vv-scope": "formEscalationReference",
                                "data-vv-name": "Escalation Description",
                                "error-messages": _vm.errors.collect(
                                  "Escalation Description"
                                ),
                              },
                              model: {
                                value:
                                  _vm.complaintEscalation.escalationDescription,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.complaintEscalation,
                                    "escalationDescription",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "complaintEscalation.escalationDescription",
                              },
                            },
                            [_vm._v(" > ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.complaintEscalateDialog = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoadingEscalateDocuments,
                            loading: _vm.isLoadingEscalateDocuments,
                          },
                          on: { click: _vm.onComplaintEscalation },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSnackbar
        ? _c(
            "v-snackbar",
            {
              attrs: {
                top: "",
                "multi-line": "",
                color: "error",
                timeout: _vm.snackbarTimeout,
              },
              model: {
                value: _vm.showSnackbar,
                callback: function ($$v) {
                  _vm.showSnackbar = $$v
                },
                expression: "showSnackbar",
              },
            },
            [
              _c("h4", [_vm._v(_vm._s(_vm.snackbarText))]),
              _c(
                "v-btn",
                {
                  attrs: { flat: "" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.showSnackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }