var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ReportBuilderSelector", { attrs: { "job-id": _vm.jobId } }),
      _vm.reportDetail &&
      _vm.reportDetail.reportStatus === _vm.reportStatus.ReportBuilt &&
      (_vm.job && !_vm.job.isTestingOnlyJob
        ? _vm.isEngineerVisitCompleted
        : true)
        ? _c(
            "v-flex",
            { staticClass: "text-xs-right" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 upload-btn sentReport-btn",
                  attrs: { color: "primary", disabled: _vm.loading },
                  on: { click: _vm.sentReport },
                },
                [_vm._v(" Send Email ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }