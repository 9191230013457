var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.financialSummary
    ? _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 up-card mb-3 financial-info" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("h3", { staticClass: "py-3 pl-2" }, [
                    _vm._v("Financial Summary"),
                  ]),
                  _c("v-divider"),
                  _c(
                    "div",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "px-2 py-3",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", { staticClass: "sub-title" }, [
                              _vm._v("Invoice to RSA"),
                            ]),
                            _c("table", [
                              _c("tr", [
                                _c("td", [_vm._v("One call Out")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.invoice
                                            ? _vm.item.invoice.oneCallOutCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Aditional Time Authorized")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.invoice
                                            ? _vm.item.invoice
                                                .additionalTimeCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("New Parts")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.invoice
                                            ? _vm.item.invoice.newPartsCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("v-flex", { attrs: { "xs-12": "" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-xs-right mt-3 total-financial pa-2",
                              },
                              [
                                _c("span", [_c("b", [_vm._v("Total      ")])]),
                                _c("span", [
                                  _c("span", [
                                    _c("b", [
                                      _vm._v(
                                        " £ " +
                                          _vm._s(
                                            _vm.financialSummary.invoice
                                              ? _vm.item.invoice.totalCost
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "px-2 py-2",
                          attrs: { row: "", wrap: "" },
                        },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", { staticClass: "sub-title" }, [
                              _vm._v("Purchase Order to Stuart Barr"),
                            ]),
                            _c("table", [
                              _c("tr", [
                                _c("td", [_vm._v("One call Out")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.purchase
                                            ? _vm.item.purchase.oneCallOutCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("Aditional Time Authorized")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.purchase
                                            ? _vm.item.purchase
                                                .additionalTimeCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                              _c("tr", [
                                _c("td", [_vm._v("New Parts")]),
                                _c("td"),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      " £ " +
                                        _vm._s(
                                          _vm.financialSummary.purchase
                                            ? _vm.item.purchase.newPartsCost
                                            : 0
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("v-flex", { attrs: { "xs-12": "" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-xs-right mt-3 total-financial pa-2",
                              },
                              [
                                _c("span", [_c("b", [_vm._v("Total      ")])]),
                                _c("span", [
                                  _c("span", [
                                    _c("b", [
                                      _vm._v(
                                        " £ " +
                                          _vm._s(
                                            _vm.financialSummary.purchase
                                              ? _vm.item.purchase.totalCost
                                              : 0
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }