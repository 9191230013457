var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [
        _vm._v(
          "Engineer " +
            _vm._s(_vm.item.visitStartedAt ? "OnSite" : "Offsite") +
            " Time Update"
        ),
      ]),
    ]),
    _vm.emergencyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency Type and Detail: ")]),
          _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
        ])
      : _vm._e(),
    _vm.item.visitStartedAt
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Visit Started At: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.visitStartedAt))),
          ]),
        ])
      : _vm._e(),
    _vm.item.visitCompletedAt
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Visit Completed At: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.visitCompletedAt))),
          ]),
        ])
      : _vm._e(),
    _vm.item.visitNote
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Site Notes: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.visitNote))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }