var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.waterDetails.length > 0
      ? _c(
          "div",
          _vm._l(_vm.waterDetails, function (waterTest, index) {
            return _c(
              "div",
              { key: index },
              [
                waterTest.testNumber
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Test Number: ")]),
                      _c("span", [_vm._v(_vm._s(waterTest.testNumber))]),
                    ])
                  : _vm._e(),
                waterTest.pressureAtZeroMinutes
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Pressure At Zero Minutes: ")]),
                      _c("span", [
                        _vm._v(_vm._s(waterTest.pressureAtZeroMinutes)),
                      ]),
                    ])
                  : _vm._e(),
                waterTest.pressureAtFiveMinutes
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Pressure At Five Minutes: ")]),
                      _c("span", [
                        _vm._v(_vm._s(waterTest.pressureAtFiveMinutes)),
                      ]),
                    ])
                  : _vm._e(),
                waterTest.pressureAtTenMinutes
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Pressure At Ten Minutes: ")]),
                      _c("span", [
                        _vm._v(_vm._s(waterTest.pressureAtTenMinutes)),
                      ]),
                    ])
                  : _vm._e(),
                waterTest.startTime
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Start Time: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getFormattedDate(waterTest.startTime))
                        ),
                      ]),
                    ])
                  : _vm._e(),
                waterTest.testNote
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Test Note: ")]),
                      _c("span", [_vm._v(_vm._s(waterTest.testNote))]),
                    ])
                  : _vm._e(),
                _vm.item && _vm.item.status
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Status: ")]),
                      _c("span", [_vm._v(_vm._s(_vm.item.status))]),
                    ])
                  : _vm._e(),
                index !== _vm.waterDetails.length - 1
                  ? _c("v-divider", { staticClass: "mb-1" })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Water Mains Test Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }