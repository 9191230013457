var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.localQA
        ? _c(
            "v-card",
            {
              staticClass: "mb-3 access-note",
              attrs: { elevation: _vm.embedded ? "0" : undefined },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs11: "" } }, [
                        _c("h3", [_vm._v(_vm._s(_vm.localQA.questionText))]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "", "text-xs-right": "" } },
                        [
                          _vm.syncing
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "processing-icon",
                                  attrs: { medium: "" },
                                },
                                [_vm._v("sync")]
                              )
                            : _vm.localQA.answer !== null
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "success",
                                  },
                                },
                                [_vm._v("check_circle")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c("v-textarea", {
                    attrs: { label: "Details", name: "accessNote" },
                    on: {
                      focus: _vm.editStarted,
                      blur: function ($event) {
                        return _vm.editComplete()
                      },
                    },
                    model: {
                      value: _vm.localQA.answer,
                      callback: function ($$v) {
                        _vm.$set(_vm.localQA, "answer", $$v)
                      },
                      expression: "localQA.answer",
                    },
                  }),
                ],
                1
              ),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                attrs: {
                  color: "primary",
                  height: "3",
                  value: "15",
                  indeterminate: true,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }