var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "elevation-0" },
    [
      _vm.isLoading
        ? _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          })
        : _c(
            "v-layout",
            { attrs: { wrap: "", "bg-white": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", "pa-3": "" } },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "data-accordion elevation-0" },
                    [
                      _vm.job && _vm.job.jobType === "HE"
                        ? _c(
                            "v-expansion-panel-content",
                            { staticClass: "grey lighten-5 mb-3 elevation-1" },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "align-center",
                                  attrs: { slot: "header", wrap: "" },
                                  slot: "header",
                                },
                                [
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", { staticClass: "pb-2" }, [
                                      _vm._v("Job Fees"),
                                    ]),
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right pr-2",
                                      attrs: { xs4: "" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          " Total: " +
                                            _vm._s(
                                              _vm.getFormatedCurrency(
                                                _vm.jobFeeItems.reduce(
                                                  (a, b) => a + b.amount,
                                                  0
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticClass: "grey lighten-5" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-data-table",
                                        {
                                          staticClass: "gridView elevation-1",
                                          attrs: {
                                            headers: _vm.jobFeeHeaders,
                                            items: _vm.jobFeeItems,
                                            "hide-headers": "",
                                            "hide-actions": "",
                                            width: "500",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "items",
                                                fn: function (props) {
                                                  return [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .description
                                                          )
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getFormatedCurrency(
                                                                  props.item
                                                                    .amount
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3571780580
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "footer" }, [
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [_c("strong", [_vm._v("Total")])]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getFormatedCurrency(
                                                          _vm.jobFeeItems.reduce(
                                                            (a, b) =>
                                                              a + b.amount,
                                                            0
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                      _vm.getOtherFeesDetail.notes
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "otherFeesDetailNotes",
                                              attrs: { xs12: "", "pa-3": "" },
                                            },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "pb-2" },
                                                [_vm._v("Other Fee Notes")]
                                              ),
                                              _c("v-divider", {
                                                staticClass: "mb-2",
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getOtherFeesDetail.notes
                                                  )
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.getJobSummaryCardModel &&
                      _vm.getJobSummaryCardModel.tradeFeeDetails.length > 0
                        ? _c(
                            "v-expansion-panel-content",
                            { staticClass: "grey lighten-5 mb-3 elevation-1" },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "align-center",
                                  attrs: { slot: "header", wrap: "" },
                                  slot: "header",
                                },
                                [
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", { staticClass: "pb-2" }, [
                                      _vm._v("Trade Fees"),
                                    ]),
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right pr-2",
                                      attrs: { xs4: "" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          " Total: " +
                                            _vm._s(
                                              _vm.getFormatedCurrency(
                                                _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                  (a, b) => a + b.labourCost,
                                                  0
                                                ) +
                                                  _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                    (a, b) =>
                                                      a + b.materialCost,
                                                    0
                                                  ) +
                                                  _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                    (a, b) => a + b.travelCost,
                                                    0
                                                  )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticClass: "grey lighten-5" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-data-table",
                                        {
                                          staticClass: "gridView elevation-1",
                                          attrs: {
                                            headers: _vm.tradeFeesheaders,
                                            items:
                                              _vm.getJobSummaryCardModel
                                                .tradeFeeDetails,
                                            "hide-actions": "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "items",
                                                fn: function (props) {
                                                  return [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.index + 1
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .emergencyTypeDescription
                                                          ) +
                                                            " ( " +
                                                            _vm._s(
                                                              props.item
                                                                .emergencyDetailDescription
                                                            ) +
                                                            " )"
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .tradeDescription
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getFormattedDate(
                                                              props.item
                                                                .visitStartedAt
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getFormattedDate(
                                                                props.item
                                                                  .engineerOnSiteAt
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getFormatedCurrency(
                                                                  props.item
                                                                    .labourCost +
                                                                    props.item
                                                                      .travelCost
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getFormatedCurrency(
                                                                  props.item
                                                                    .materialCost
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "revisit-status",
                                                        class: props.item
                                                          .isAnotherVisitRequested
                                                          ? "orange lighten-2"
                                                          : "green lighten-4",
                                                        attrs: { rowspan: "2" },
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              colspan: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Visit Notes"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        props.item
                                                          .isAnotherVisitRequested
                                                          ? _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "orange--text text-xs-right",
                                                                attrs: {
                                                                  colspan: "4",
                                                                },
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Another Visit Needed"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "green--text text-xs-right",
                                                                attrs: {
                                                                  colspan: "4",
                                                                },
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Additional Visit Not Requested"
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "tr",
                                                      {
                                                        staticClass:
                                                          "revisit-status btn-isAnotherVisitRequested",
                                                        class: props.item
                                                          .isAnotherVisitRequested
                                                          ? "orange lighten-2"
                                                          : "green lighten-4",
                                                      },
                                                      [
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              colspan:
                                                                props.item
                                                                  .isAnotherVisitRequested ||
                                                                _vm.job
                                                                  .cancellationReason
                                                                  ? 7
                                                                  : 5,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  props.item
                                                                    .visitNote
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        !props.item
                                                          .isAnotherVisitRequested &&
                                                        !_vm.job
                                                          .cancellationReason
                                                          ? _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  colspan: "2",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "red white--text btn-not-ready-to-close",
                                                                    attrs: {
                                                                      disabled:
                                                                        _vm.payLoading ||
                                                                        _vm.notReadyToCloseJobLoading,
                                                                      loading:
                                                                        _vm.notReadyToCloseJobLoading,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openAdditionalRequestDialog(
                                                                            props
                                                                              .item
                                                                              .visitId
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Not Ready to Close "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            454176782
                                          ),
                                        },
                                        [
                                          _c("template", { slot: "footer" }, [
                                            _c("td", {
                                              attrs: { colspan: "3" },
                                            }),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [_c("strong", [_vm._v("Total")])]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getFormatedCurrency(
                                                          _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                            (a, b) =>
                                                              a + b.labourCost,
                                                            0
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getFormatedCurrency(
                                                          _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                            (a, b) =>
                                                              a +
                                                              b.materialCost,
                                                            0
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getFormatedCurrency(
                                                          _vm.getJobSummaryCardModel.tradeFeeDetails.reduce(
                                                            (a, b) =>
                                                              a + b.travelCost,
                                                            0
                                                          )
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.getJobSummaryCardModel &&
                      _vm.getJobSummaryCardModel.ctpJobCardDetails.length > 0
                        ? _c(
                            "v-expansion-panel-content",
                            { staticClass: "grey lighten-5 mb-3 elevation-1" },
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "align-center",
                                  attrs: { slot: "header", wrap: "" },
                                  slot: "header",
                                },
                                [
                                  _c("v-flex", { attrs: { xs8: "" } }, [
                                    _c("h3", { staticClass: "pb-2" }, [
                                      _vm._v("Customer To Pay"),
                                    ]),
                                  ]),
                                  _c(
                                    "v-flex",
                                    {
                                      staticClass: "text-xs-right pr-2",
                                      attrs: { xs4: "" },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          " Total: " +
                                            _vm._s(
                                              _vm.getFormatedCurrency(
                                                _vm.getJobSummaryCardModel.ctpJobCardDetails.reduce(
                                                  (a, b) => a + b.amount,
                                                  0
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card",
                                { staticClass: "grey lighten-5" },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mt-2": "" } },
                                        [
                                          _c(
                                            "v-data-table",
                                            {
                                              staticClass:
                                                "gridView elevation-1 ctptable",
                                              attrs: {
                                                headers: _vm.ctpHeaders,
                                                items:
                                                  _vm.getJobSummaryCardModel
                                                    .ctpJobCardDetails,
                                                "hide-actions": "",
                                                width: "500",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "items",
                                                    fn: function (props) {
                                                      return [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.getFormattedDate(
                                                                    props.item
                                                                      .recordCreatedAt
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-xs-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getFormatedCurrency(
                                                                      props.item
                                                                        .amount
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-xs-right",
                                                              style:
                                                                "color:" +
                                                                (props.item
                                                                  .isTransactionCompleted
                                                                  ? "green"
                                                                  : "red"),
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    props.item
                                                                      .isTransactionCompleted
                                                                      ? "Yes"
                                                                      : "No"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2445061466
                                              ),
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "footer" },
                                                [
                                                  _c("td", [
                                                    _c("strong", [
                                                      _vm._v("Total"),
                                                    ]),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-xs-right",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getFormatedCurrency(
                                                                _vm.getJobSummaryCardModel.ctpJobCardDetails.reduce(
                                                                  (a, b) =>
                                                                    a +
                                                                    b.amount,
                                                                  0
                                                                )
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-xs-right",
                                                      attrs: { colspan: "1" },
                                                    },
                                                    [_vm._v("------")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isCTPTriggered()
                ? _c(
                    "v-flex",
                    {
                      staticClass: "ctp-block",
                      attrs: { xs12: "", "pa-3": "" },
                    },
                    [
                      _c("h3", { staticClass: "pb-2" }, [
                        _vm._v("Remaining Payment Summary"),
                      ]),
                      _c("v-divider"),
                      _c("div", { staticClass: "bg-white pa-2" }, [
                        _c(
                          "div",
                          { staticClass: "grey px-2 pt-2 pb-3 lighten-3" },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  {
                                    attrs: { xs12: "", "py-1": "", "pt-3": "" },
                                  },
                                  [
                                    _c("b", [_vm._v("Paid Amount")]),
                                    _vm._v(" : "),
                                    _c(
                                      "b",
                                      { staticClass: "secondary--text" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getFormatedCurrency(
                                                _vm.getJobSummaryCardModel
                                                  .completeJobData.prePaidAmount
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "py-1": "" } },
                                  [
                                    _c("b", [_vm._v("Due Amount")]),
                                    _vm._v(" : "),
                                    _c(
                                      "span",
                                      { staticClass: "amount-pay" },
                                      [
                                        _c(
                                          "v-text-field",
                                          {
                                            staticClass:
                                              "mt-0 pt-0 prepend-icon-image",
                                            attrs: {
                                              "hide-details": "",
                                              "prepend-icon": "money",
                                              name: "dueAmount",
                                            },
                                            model: {
                                              value: _vm.getTotalOverdueAmount,
                                              callback: function ($$v) {
                                                _vm.getTotalOverdueAmount = $$v
                                              },
                                              expression:
                                                "getTotalOverdueAmount",
                                            },
                                          },
                                          [_vm._v(" > ")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "pound-img" },
                                          [
                                            _c(
                                              "b",
                                              {
                                                staticClass: "secondary--text",
                                                attrs: { value: "300" },
                                              },
                                              [_vm._v("£")]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.getJobSummaryCardModel.completeJobData
                                      .totalConsumerCharged
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "ml-2 md-icon",
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("info")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2330153053
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Total Chargable Amount"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.getJobSummaryCardModel.completeJobData
                                      .totalConsumerCharged
                                      ? _c(
                                          "span",
                                          { staticClass: "grey--text" },
                                          [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.getFormatedCurrency(
                                                    _vm.getJobSummaryCardModel
                                                      .completeJobData
                                                      .totalConsumerCharged
                                                  )
                                                ) +
                                                ") "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm.errorMessage
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "mt-2": "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "error-info error--text",
                                          },
                                          [_vm._v(_vm._s(_vm.errorMessage))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.job &&
              _vm.job.jobType === "US" &&
              _vm.job.cancellationReason &&
              _vm.getJobSummaryCardModel &&
              !_vm.getJobSummaryCardModel.tradeFeeDetails.length > 0 &&
              !_vm.getJobSummaryCardModel.ctpJobCardDetails.length > 0 &&
              !_vm.isCTPTriggered() &&
              !_vm.unableToCompleteReason
                ? _c("v-flex", { attrs: { xs12: "", "pa-3": "" } }, [
                    _c("div", { staticClass: "bg-white pa-3" }, [
                      _c("div", { staticClass: "text-xs-center py-2" }, [
                        _c("h3", [_vm._v("Do you want to complete the job?")]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "", "pa-3": "", "text-xs-right": "" } },
                [
                  _c("v-divider"),
                  _vm.job.cancellationReason && _vm.isCTPTriggered()
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "red white--text btn-pay-and-cancel",
                          attrs: {
                            disabled:
                              _vm.payLoading ||
                              _vm.notReadyToCloseJobLoading ||
                              _vm.getTotalOverdueAmount < 0 ||
                              _vm.unableToCompleteReason,
                            loading: _vm.payLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.completeCTPProcess("Cancelled")
                            },
                          },
                        },
                        [_vm._v(" Pay & Cancel ")]
                      )
                    : _vm.job.cancellationReason && !_vm.isCTPTriggered()
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "red white--text btn-cancel",
                          attrs: {
                            disabled:
                              _vm.payLoading ||
                              _vm.notReadyToCloseJobLoading ||
                              _vm.unableToCompleteReason,
                            loading: _vm.payLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onCompleteJobConfirm("Cancelled")
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      )
                    : !_vm.job.cancellationReason && _vm.isCTPTriggered()
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "green white--text btn-pay-and-complete",
                          attrs: {
                            disabled:
                              _vm.payLoading ||
                              _vm.notReadyToCloseJobLoading ||
                              _vm.getTotalOverdueAmount < 0 ||
                              _vm.unableToCompleteReason,
                            loading: _vm.payLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.completeCTPProcess("Completed")
                            },
                          },
                        },
                        [_vm._v(" Pay & Complete ")]
                      )
                    : !_vm.job.cancellationReason && !_vm.isCTPTriggered()
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "green white--text btn-complete",
                          attrs: {
                            disabled:
                              _vm.payLoading ||
                              _vm.notReadyToCloseJobLoading ||
                              _vm.unableToCompleteReason,
                            loading: _vm.payLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onCompleteJobConfirm("Completed")
                            },
                          },
                        },
                        [_vm._v(" Complete ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.showJobCompletionWarningDialog
                ? _c("JobCompletionWarning", {
                    on: {
                      closeJobCompletionWarningDialog: function ($event) {
                        _vm.showJobCompletionWarningDialog = false
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    timeout: _vm.saveSnackbarTimeout,
                    bottom: true,
                    left: true,
                  },
                  model: {
                    value: _vm.saveSnackbar,
                    callback: function ($$v) {
                      _vm.saveSnackbar = $$v
                    },
                    expression: "saveSnackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.saveSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm.additionalRequestDialog && _vm.itemId && _vm.jobId
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.additionalRequestDialog,
                callback: function ($$v) {
                  _vm.additionalRequestDialog = $$v
                },
                expression: "additionalRequestDialog",
              },
            },
            [
              _c("AdditionalRequest", {
                ref: "refAdditionalRequest",
                attrs: {
                  "job-id": _vm.jobId,
                  "item-id": _vm.itemId,
                  "is-from-job-completion-card": true,
                },
                on: {
                  closeAdditionalRequestDialog:
                    _vm.closeAdditionalRequestDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }