var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    !_vm.createdJobId
      ? _c(
          "div",
          [
            _c(
              "v-stepper",
              {
                staticClass: "elevation-0",
                attrs: { "non-linear": "" },
                model: {
                  value: _vm.stepIndex,
                  callback: function ($$v) {
                    _vm.stepIndex = $$v
                  },
                  expression: "stepIndex",
                },
              },
              [
                _c(
                  "v-stepper-header",
                  [
                    _c(
                      "v-stepper-step",
                      {
                        staticClass: "enquiry-step1",
                        attrs: {
                          step: 1,
                          editable: _vm.stepIndex > 1,
                          complete: _vm.step1validated,
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.switchToStep(1)
                          },
                        },
                      },
                      [_vm._v(" Policy Details ")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      {
                        staticClass: "enquiry-step2",
                        attrs: {
                          step: 2,
                          editable: _vm.step1validated && _vm.stepIndex > 2,
                          complete: _vm.step2validated,
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.switchToStep(2)
                          },
                        },
                      },
                      [_vm._v(" Commodity Details ")]
                    ),
                    _c("v-divider"),
                    _c(
                      "v-stepper-step",
                      {
                        staticClass: "enquiry-step3",
                        attrs: {
                          step: 3,
                          editable:
                            _vm.step1validated &&
                            _vm.step2validated &&
                            _vm.stepIndex > 3,
                          complete: _vm.wizardSubmitStatus === "submitted",
                        },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.switchToStep(3)
                          },
                        },
                      },
                      [_vm._v(" CONTACT PREFERENCES ")]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "1" } },
                  [
                    _c(
                      "v-form",
                      { attrs: { autocomplete: "off" } },
                      [
                        _c(
                          "v-container",
                          {
                            attrs: {
                              fluid: "",
                              "pa-0": "",
                              "grid-list-lg": "",
                            },
                          },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "pa-0": "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          [
                                            _vm.policies
                                              ? _c("PolicySelector", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  ref: "policySelectorRef",
                                                  staticClass: "mb-4 pb-0 pl-0",
                                                  staticStyle: {
                                                    "padding-top": "10px",
                                                  },
                                                  attrs: {
                                                    id: "policySelector",
                                                    type: "number",
                                                    required: "",
                                                    "policy-tree": _vm.policies,
                                                    "error-messages":
                                                      _vm.policySelected
                                                        ? []
                                                        : [
                                                            "A policy must be selected",
                                                          ],
                                                    "data-vv-scope":
                                                      _vm.step1formRef,
                                                    "data-vv-name":
                                                      "Selected Policy",
                                                    "selected-policy":
                                                      _vm.selectedPolicy,
                                                    "pre-selected-policy":
                                                      _vm.preSelectedPolicyScheduleId,
                                                    "job-type": "US",
                                                  },
                                                  on: {
                                                    "update:selectedPolicy":
                                                      _vm.updatePolicySelection,
                                                  },
                                                })
                                              : _c("v-select", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: "required",
                                                      expression: "'required'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    disabled: true,
                                                    type: "number",
                                                    placeholder:
                                                      _vm.claimModel
                                                        .policyScheme,
                                                    "data-vv-name":
                                                      "Selected Policy",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.claimModel
                                                        .policyScheduleId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.claimModel,
                                                        "policyScheduleId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "claimModel.policyScheduleId",
                                                  },
                                                }),
                                            _c(
                                              "v-alert",
                                              {
                                                attrs: {
                                                  color: "warning",
                                                  icon: "priority_high",
                                                  dismissible: "",
                                                },
                                                model: {
                                                  value: _vm.showDisclaimer,
                                                  callback: function ($$v) {
                                                    _vm.showDisclaimer = $$v
                                                  },
                                                  expression: "showDisclaimer",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " DISCLAIMER: " +
                                                    _vm._s(
                                                      _vm.disclaimerMessage
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-alert",
                                              {
                                                staticClass: "pa-0",
                                                staticStyle: {
                                                  border: "0px !important",
                                                  top: "-15px !important",
                                                },
                                                attrs: {
                                                  color: "warning",
                                                  icon: "priority_high",
                                                  outline: "",
                                                },
                                                model: {
                                                  value:
                                                    _vm.isAssumedCoverPolicy,
                                                  callback: function ($$v) {
                                                    _vm.isAssumedCoverPolicy =
                                                      $$v
                                                  },
                                                  expression:
                                                    "isAssumedCoverPolicy",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " This is an assumed cover policy, you should create a job even if you can't find the customer in the validation database. "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "", "py-0": "" } },
                                      [
                                        _c("v-checkbox", {
                                          ref: "siFollowUpTick",
                                          staticClass:
                                            "que-checkbox full-width pb-2",
                                          attrs: {
                                            disabled: false,
                                            name: "siFollowUpTick",
                                            "hide-details": true,
                                            label:
                                              "Is this following up from an SI job?",
                                            color: "primary",
                                          },
                                          on: { change: _vm.siFollowUp },
                                          model: {
                                            value: _vm.siFollowUp,
                                            callback: function ($$v) {
                                              _vm.siFollowUp = $$v
                                            },
                                            expression: "siFollowUp",
                                          },
                                        }),
                                        _vm.siFollowUp
                                          ? _c(
                                              "v-flex",
                                              {
                                                ref: "siFollowUpSearch",
                                                staticStyle: {
                                                  "min-height": "100px",
                                                },
                                                attrs: {
                                                  name: "siFollowUpSearch",
                                                  "xs-12": "",
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    "Search for SI job, or enter information below"
                                                  ),
                                                ]),
                                                _c("UniversalSearch", {
                                                  staticClass:
                                                    "search-job-item",
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    "s-i-search": true,
                                                    "is-clear-search": false,
                                                    "parent-component":
                                                      "AddDrainageClaimWizard",
                                                  },
                                                }),
                                                _c("v-divider", {
                                                  staticClass: "pb-2 pt-2",
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c("CustomerAddress", {
                                          ref: "addCustomerAddress",
                                          attrs: {
                                            "passed-client-title":
                                              _vm.getClientTitle,
                                            "passed-first-name":
                                              _vm.getFirstName,
                                            "passed-last-name": _vm.getLastName,
                                            "passed-policy-number":
                                              _vm.getPolicyNumber,
                                            "passed-address-line1":
                                              _vm.getAddressLine1,
                                            "passed-address-line2":
                                              _vm.getAddressLine2,
                                            "passed-address-line3":
                                              _vm.getAddressLine3,
                                            "passed-address-line4":
                                              _vm.getAddressLine4,
                                            "passed-post-code": _vm.getPostCode,
                                            "is-display-policy-number": true,
                                            disabled: _vm.disableAddressForm,
                                          },
                                          on: {
                                            "update:passedClientTitle":
                                              function ($event) {
                                                _vm.getClientTitle = $event
                                              },
                                            "update:passed-client-title":
                                              function ($event) {
                                                _vm.getClientTitle = $event
                                              },
                                            "update:passedFirstName": function (
                                              $event
                                            ) {
                                              _vm.getFirstName = $event
                                            },
                                            "update:passed-first-name":
                                              function ($event) {
                                                _vm.getFirstName = $event
                                              },
                                            "update:passedLastName": function (
                                              $event
                                            ) {
                                              _vm.getLastName = $event
                                            },
                                            "update:passed-last-name":
                                              function ($event) {
                                                _vm.getLastName = $event
                                              },
                                            "update:passedPolicyNumber":
                                              function ($event) {
                                                _vm.getPolicyNumber = $event
                                              },
                                            "update:passed-policy-number":
                                              function ($event) {
                                                _vm.getPolicyNumber = $event
                                              },
                                            "update:passedAddressLine1":
                                              function ($event) {
                                                _vm.getAddressLine1 = $event
                                              },
                                            "update:passed-address-line1":
                                              function ($event) {
                                                _vm.getAddressLine1 = $event
                                              },
                                            "update:passedAddressLine2":
                                              function ($event) {
                                                _vm.getAddressLine2 = $event
                                              },
                                            "update:passed-address-line2":
                                              function ($event) {
                                                _vm.getAddressLine2 = $event
                                              },
                                            "update:passedAddressLine3":
                                              function ($event) {
                                                _vm.getAddressLine3 = $event
                                              },
                                            "update:passed-address-line3":
                                              function ($event) {
                                                _vm.getAddressLine3 = $event
                                              },
                                            "update:passedAddressLine4":
                                              function ($event) {
                                                _vm.getAddressLine4 = $event
                                              },
                                            "update:passed-address-line4":
                                              function ($event) {
                                                _vm.getAddressLine4 = $event
                                              },
                                            "update:passedPostCode": function (
                                              $event
                                            ) {
                                              _vm.getPostCode = $event
                                            },
                                            "update:passed-post-code":
                                              function ($event) {
                                                _vm.getPostCode = $event
                                              },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { staticClass: "pb-0", attrs: { step: "2" } },
                  [
                    _c(
                      "v-form",
                      { attrs: { autocomplete: "off" } },
                      [
                        _c(
                          "v-container",
                          { staticClass: "pb-0 pt-2" },
                          [
                            _c(
                              "v-layout",
                              [
                                _c("v-flex", [
                                  _c("div", { staticClass: "policy-logo" }, [
                                    _vm.policyUrl &&
                                    _vm.policyUrl !== null &&
                                    _vm.policyUrl !== ""
                                      ? _c("img", {
                                          attrs: { src: _vm.policyUrl },
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src: "/img/dummy-logo.jpeg",
                                          },
                                        }),
                                  ]),
                                ]),
                                _c(
                                  "v-flex",
                                  { staticClass: "emergency-info-table" },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass:
                                          "shadow-outer emergency-table",
                                        attrs: { wrap: "" },
                                      },
                                      [
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "PH Name",
                                            detail:
                                              (_vm.claimModel.clientTitle
                                                ? _vm.claimModel.clientTitle
                                                : "") +
                                              " " +
                                              _vm.claimModel.clientForename +
                                              " " +
                                              _vm.claimModel.clientLastName,
                                            "other-detail":
                                              (_vm.claimModel
                                                .secondaryClientTitle
                                                ? _vm.claimModel
                                                    .secondaryClientTitle + " "
                                                : "") +
                                              (_vm.claimModel
                                                .secondaryClientForename
                                                ? _vm.claimModel
                                                    .secondaryClientForename +
                                                  " "
                                                : "") +
                                              (_vm.claimModel
                                                .secondaryClientLastName
                                                ? _vm.claimModel
                                                    .secondaryClientLastName
                                                : ""),
                                          },
                                        }),
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "Policy Number",
                                            detail: _vm.claimModel.policyNumber
                                              ? "#" +
                                                _vm.claimModel.policyNumber
                                              : "",
                                          },
                                        }),
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "PostCode",
                                            detail: _vm.claimModel.postcode,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-container",
                                  { staticClass: "pb-0 px-0" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "perilItems" },
                                      [
                                        _vm.wizardLoadState === "loaded" || true
                                          ? _c("AddDrainageCommodity", {
                                              ref: "addCommodity",
                                              model: {
                                                value: _vm.claimModel.commodity,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.claimModel,
                                                    "commodity",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "claimModel.commodity",
                                              },
                                            })
                                          : _c("div", [
                                              _vm._v(
                                                "Loading dropdown values, please wait ..."
                                              ),
                                            ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-stepper-content",
                  { attrs: { step: "3" } },
                  [
                    _c(
                      "v-form",
                      { attrs: { autocomplete: "off" } },
                      [
                        _c(
                          "v-container",
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "shadow-outer",
                                        attrs: { wrap: "" },
                                      },
                                      [
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "PH Name",
                                            detail:
                                              (_vm.claimModel.clientTitle
                                                ? _vm.claimModel.clientTitle
                                                : "") +
                                              " " +
                                              _vm.claimModel.clientForename +
                                              " " +
                                              _vm.claimModel.clientLastName,
                                            "other-detail":
                                              (_vm.claimModel
                                                .secondaryClientTitle
                                                ? _vm.claimModel
                                                    .secondaryClientTitle + " "
                                                : "") +
                                              (_vm.claimModel
                                                .secondaryClientForename
                                                ? _vm.claimModel
                                                    .secondaryClientForename +
                                                  " "
                                                : "") +
                                              (_vm.claimModel
                                                .secondaryClientLastName
                                                ? _vm.claimModel
                                                    .secondaryClientLastName
                                                : ""),
                                          },
                                        }),
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "Policy Number",
                                            detail: _vm.claimModel.policyNumber
                                              ? "#" +
                                                _vm.claimModel.policyNumber
                                              : "",
                                          },
                                        }),
                                        _c("PropertyCard", {
                                          attrs: {
                                            size: 4,
                                            title: "PostCode",
                                            detail: _vm.claimModel.postcode,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", "pt-4": "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c("ContactPreferenceDetails", {
                                              ref: "contactPreferenceDetails",
                                              attrs: {
                                                "customer-contact-preference-modal":
                                                  _vm.customerContactPreferenceModal,
                                              },
                                              on: {
                                                updateContactPreference:
                                                  _vm.updateContactPreference,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "v-card",
              { staticClass: "elevation-0 pa-0" },
              [
                _c(
                  "v-card-text",
                  { staticClass: "pa-0" },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "px-1": "", "mb-2": "" } },
                          [
                            _c("UploadDocument", {
                              ref: "uploadDocument",
                              attrs: {
                                "job-id": _vm.createdJobId,
                                "document-types": _vm.uploadedDocumentTypes,
                                "is-in-wizard-step": true,
                              },
                              on: {
                                documentUploadComplete:
                                  _vm.onDocumentUploadComplete,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }