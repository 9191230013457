var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.returnVisitETAFrom
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("ETA From: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.returnVisitETAFrom))),
          ]),
        ])
      : _vm._e(),
    _vm.item.returnVisitETATo
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("ETA To: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.returnVisitETATo))),
          ]),
        ])
      : _vm._e(),
    _vm.engineerName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Engineer: ")]),
          _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
        ])
      : _vm._e(),
    _vm.item.returnVisitReason
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Reason: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.returnVisitReason))]),
        ])
      : _vm._e(),
    _vm.item.returnVisitNote
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Note: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.returnVisitNote))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Return Visit Request")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }