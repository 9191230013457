var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { position: "relative", display: "block" } },
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoaded,
            expression: "!isLoaded",
          },
        ],
        style: _vm.getLoaderStyle(70),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      _vm.isLoaded &&
      _vm.customerNotAvailableMessage &&
      _vm.job &&
      _vm.job.jobType === "HE"
        ? _c(
            "div",
            [
              _c(
                "v-card",
                { staticClass: "not-found-msg", attrs: { "elevation-0": "" } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "subheading text-xs-center" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.customerNotAvailableMessage) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : [
            (_vm.contractorList.length > 0 || _vm.getThirdPartyContractor) &&
            !_vm.loaderOnAutoDeploymentRunning
              ? _c(
                  "div",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("CallContractorDetailView", {
                          ref: "callContractorDetailView",
                          attrs: {
                            "bind-from-prop": true,
                            item: _vm.isThirdPartyContractor
                              ? _vm.getThirdPartyContractor
                              : _vm.selectedItem,
                            emergency: _vm.emergency,
                            "job-id": _vm.jobId,
                            "job-latitude": _vm.job.latitude,
                            "job-longitude": _vm.job.longitude,
                            "assigned-contractors": _vm.getAssignedContractors,
                            "is-third-party-contractor":
                              _vm.isThirdPartyContractor,
                            "is-contractor-selected-via-search-result":
                              _vm.isContractorSelectedViaSearchResult,
                            "is-un-assigned-contractor": true,
                          },
                          on: {
                            runManualAutoDeployment:
                              _vm.runManualAutoDeployment,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-lg": "", "px-0": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "", row: "" } },
                              [
                                _vm.contractorList.length > 0 &&
                                !_vm.getThirdPartyContractor
                                  ? _vm._l(
                                      _vm.contractorList,
                                      function (item, index) {
                                        return _c(
                                          "v-flex",
                                          {
                                            key: index,
                                            staticClass:
                                              "callContractorTileView",
                                            attrs: {
                                              xs12: "",
                                              sm6: "",
                                              xl4: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickContractorTileView(
                                                  item,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("CallContractorTileView", {
                                              attrs: {
                                                item: item,
                                                selected:
                                                  _vm.activeTileIndex === index,
                                                "assigned-contractors":
                                                  _vm.getAssignedContractors,
                                                "trade-id":
                                                  _vm.emergency.tradeId,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                (!_vm.isNearByContractorLoaded &&
                                  !_vm.getThirdPartyContractor) ||
                                (_vm.isNearByContractorLoaded &&
                                  !_vm.getThirdPartyContractor &&
                                  (_vm.isNearBySecondaryContractorLoaded ||
                                    _vm.isNearByTertiaryContractorLoaded))
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "", xl4: "" } },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "nearby-contractor" },
                                          [
                                            !_vm.isNearByContractorLoaded &&
                                            !_vm.getThirdPartyContractor
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "white--text btn-initial",
                                                    attrs: {
                                                      color: "primary",
                                                      small: "",
                                                      disabled: _vm.isLoading,
                                                      loading: _vm.isLoading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNearByContractors(
                                                          _vm.initialNearByDistance
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("place")]
                                                    ),
                                                    _vm._v(
                                                      "  Near By Contractors "
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.initialNearByDistance
                                                          ) +
                                                          _vm._s(
                                                            _vm.distanceMeasurement
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isNearByContractorLoaded &&
                                            !_vm.getThirdPartyContractor &&
                                            _vm.isNearBySecondaryContractorLoaded
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "white--text btn-secondary",
                                                    attrs: {
                                                      color: "primary",
                                                      small: "",
                                                      disabled: _vm.isLoading,
                                                      loading: _vm.isLoading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNearByContractors(
                                                          _vm.secondaryNearByDistance
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("place")]
                                                    ),
                                                    _vm._v(
                                                      "  Near By Contractors "
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.secondaryNearByDistance
                                                          ) +
                                                          _vm._s(
                                                            _vm.distanceMeasurement
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.isNearByContractorLoaded &&
                                            !_vm.getThirdPartyContractor &&
                                            !_vm.isNearBySecondaryContractorLoaded &&
                                            _vm.isNearByTertiaryContractorLoaded
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "white--text btn-tertiary",
                                                    attrs: {
                                                      color: "primary",
                                                      small: "",
                                                      disabled: _vm.isLoading,
                                                      loading: _vm.isLoading,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.getNearByContractors(
                                                          _vm.tertiaryNearByDistance
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("place")]
                                                    ),
                                                    _vm._v(
                                                      "  Near By Contractors "
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        " (" +
                                                          _vm._s(
                                                            _vm.tertiaryNearByDistance
                                                          ) +
                                                          _vm._s(
                                                            _vm.distanceMeasurement
                                                          ) +
                                                          ")"
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.isNearByContractorLoaded &&
                                (!_vm.isNearByTertiaryContractorLoaded ||
                                  !_vm.getIsSkillLevelAboveRequired) &&
                                !_vm.isNearBySecondaryContractorLoaded
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "", xl4: "" } },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "nearby-contractor" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "extra-contractor-buttons",
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "white--text btn-add",
                                                    attrs: {
                                                      color: "primary",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addContractorClick()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("person_add")]
                                                    ),
                                                    _vm._v("  Contractor "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "extra-contractor-buttons",
                                              },
                                              [
                                                _vm.getIsSkillLevelAboveRequired
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "white--text btn-search",
                                                        attrs: {
                                                          color: "primary",
                                                          small: "",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.searchButtonClicked,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("search")]
                                                        ),
                                                        _vm._v("  Contractor "),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.showCustomerAppointedTradespeopleBtn
                                  ? _c(
                                      "v-flex",
                                      { attrs: { xs12: "", sm6: "", xl4: "" } },
                                      [
                                        _c(
                                          "v-card",
                                          { staticClass: "nearby-contractor" },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "white--text customer-appointed-tradespeople",
                                                attrs: {
                                                  color: "primary",
                                                  small: "",
                                                  disabled:
                                                    _vm.isAssignCATLoading,
                                                  loading:
                                                    _vm.isAssignCATLoading,
                                                },
                                                on: {
                                                  click:
                                                    _vm.addCustomerAppointedTradesPeople,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Customer Appointed Tradespeople "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-flex",
                                  [
                                    _c("CallContractorSearchView", {
                                      ref: "callContractorSearchView",
                                      attrs: {
                                        "job-id": _vm.jobId,
                                        "emergency-id": _vm.emergencyId,
                                        "contractor-search-list":
                                          _vm.contractorSearchList,
                                        "enable-search-contractor":
                                          _vm.enableSearchContractor,
                                        "active-search-tile-index":
                                          _vm.activeSearchTileIndex,
                                        "third-party-contractor":
                                          _vm.thirdPartyContractor,
                                        "is-third-party-contractor":
                                          _vm.isThirdPartyContractor,
                                        emergency: _vm.emergency,
                                      },
                                      on: {
                                        onSearchInputChanged:
                                          _vm.onSearchInputChanged,
                                        clickContractorTileView:
                                          _vm.clickContractorTileView,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.getContractorAvailability &&
                      _vm.isLoaded &&
                      !_vm.getThirdPartyContractor &&
                      !_vm.loaderOnAutoDeploymentRunning,
                    expression:
                      "!getContractorAvailability && isLoaded && !getThirdPartyContractor && !loaderOnAutoDeploymentRunning",
                  },
                ],
              },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "not-found-msg",
                        attrs: { "elevation-0": "" },
                      },
                      [
                        !_vm.isContractorSelected
                          ? _c(
                              "v-card-text",
                              { staticClass: "subheading text-xs-center" },
                              [
                                _vm._v(
                                  " No contractor found for this emergency... "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isContractorSelected
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "", "mb-4": "" } },
                              [
                                _c("CallContractorDetailView", {
                                  ref: "callContractorDetailView",
                                  attrs: {
                                    "bind-from-prop": true,
                                    item: _vm.isThirdPartyContractor
                                      ? _vm.getThirdPartyContractor
                                      : _vm.selectedItem,
                                    emergency: _vm.emergency,
                                    "job-id": _vm.jobId,
                                    "job-latitude": _vm.job.latitude,
                                    "job-longitude": _vm.job.longitude,
                                    "assigned-contractors":
                                      _vm.getAssignedContractors,
                                    "is-third-party-contractor":
                                      _vm.isThirdPartyContractor,
                                    "is-contractor-selected-via-search-result":
                                      _vm.isContractorSelectedViaSearchResult,
                                    "is-un-assigned-contractor": true,
                                  },
                                  on: {
                                    runManualAutoDeployment:
                                      _vm.runManualAutoDeployment,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isNearByContractorLoaded ||
                        (_vm.isNearByContractorLoaded &&
                          (_vm.isNearBySecondaryContractorLoaded ||
                            _vm.isNearByTertiaryContractorLoaded))
                          ? _c("v-card-actions", { staticClass: "pb-4" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-xs-center full-width near-by-contractor",
                                },
                                [
                                  !_vm.isNearByContractorLoaded
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-0 white--text",
                                          attrs: {
                                            color: "teal",
                                            small: "",
                                            disabled: _vm.isLoading,
                                            loading: _vm.isLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getNearByContractors(
                                                _vm.initialNearByDistance
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("place")]
                                          ),
                                          _vm._v("  Near By Contractors "),
                                          _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.initialNearByDistance
                                                ) +
                                                _vm._s(
                                                  _vm.distanceMeasurement
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isNearByContractorLoaded &&
                                  _vm.isNearBySecondaryContractorLoaded
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-0 white--text",
                                          attrs: {
                                            color: "teal",
                                            small: "",
                                            disabled: _vm.isLoading,
                                            loading: _vm.isLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getNearByContractors(
                                                _vm.secondaryNearByDistance
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("place")]
                                          ),
                                          _vm._v("  Near By Contractors "),
                                          _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.secondaryNearByDistance
                                                ) +
                                                _vm._s(
                                                  _vm.distanceMeasurement
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.isNearByContractorLoaded &&
                                  !_vm.isNearBySecondaryContractorLoaded &&
                                  _vm.isNearByTertiaryContractorLoaded
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-0 white--text",
                                          attrs: {
                                            color: "teal",
                                            small: "",
                                            disabled: _vm.isLoading,
                                            loading: _vm.isLoading,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.getNearByContractors(
                                                _vm.tertiaryNearByDistance
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("place")]
                                          ),
                                          _vm._v("  Near By Contractors "),
                                          _c("span", [
                                            _vm._v(
                                              " (" +
                                                _vm._s(
                                                  _vm.tertiaryNearByDistance
                                                ) +
                                                _vm._s(
                                                  _vm.distanceMeasurement
                                                ) +
                                                ")"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showCustomerAppointedTradespeopleBtn
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "mr-0 white--text",
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            disabled: _vm.isAssignCATLoading,
                                            loading: _vm.isAssignCATLoading,
                                          },
                                          on: {
                                            click:
                                              _vm.addCustomerAppointedTradesPeople,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " Customer Appointed Tradespeople "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.isNearByContractorLoaded &&
                        (!_vm.isNearByTertiaryContractorLoaded ||
                          !_vm.getIsSkillLevelAboveRequired) &&
                        !_vm.isNearBySecondaryContractorLoaded
                          ? _c(
                              "div",
                              { staticClass: "pb-3" },
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      { staticClass: "text-xs-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "white--text",
                                            attrs: {
                                              color: "primary",
                                              small: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addContractorClick()
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { small: "" } },
                                              [_vm._v("person_add")]
                                            ),
                                            _vm._v("  Contractor "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      [
                                        _vm.getIsSkillLevelAboveRequired
                                          ? _c(
                                              "v-btn",
                                              {
                                                staticClass: "white--text",
                                                attrs: {
                                                  color: "primary",
                                                  small: "",
                                                },
                                                on: {
                                                  click:
                                                    _vm.searchButtonClicked,
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("search")]
                                                ),
                                                _vm._v("  Contractor "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { "px-3": "" } },
                                      [
                                        _c("CallContractorSearchView", {
                                          attrs: {
                                            "job-id": _vm.jobId,
                                            "emergency-id": _vm.emergencyId,
                                            "contractor-search-list":
                                              _vm.contractorSearchList,
                                            "enable-search-contractor":
                                              _vm.enableSearchContractor,
                                            "active-search-tile-index":
                                              _vm.activeSearchTileIndex,
                                            "is-third-party-contractor":
                                              _vm.isThirdPartyContractor,
                                            "third-party-contractor":
                                              _vm.thirdPartyContractor,
                                            emergency: _vm.emergency,
                                          },
                                          on: {
                                            onSearchInputChanged:
                                              _vm.onSearchInputChanged,
                                            clickContractorTileView:
                                              _vm.clickContractorTileView,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.loaderOnAutoDeploymentRunning
              ? _c(
                  "div",
                  { staticClass: "engineer-deploy-loader white elevation-1" },
                  [
                    _c("v-progress-circular", {
                      style: _vm.getLoaderStyle(70),
                      attrs: {
                        width: 2,
                        size: 50,
                        indeterminate: "",
                        color: "primary",
                      },
                    }),
                    _vm._m(0),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm.showContractorProfileDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showContractorProfileDialog,
                callback: function ($$v) {
                  _vm.showContractorProfileDialog = $$v
                },
                expression: "showContractorProfileDialog",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    [
                      _c("ContractorProfile", {
                        ref: "contractorProfile",
                        attrs: {
                          "contractor-detail": _vm.contractorProfileData,
                          "is-from-call-contractor": true,
                          "show-contractor-profile-in-dialog": true,
                        },
                        on: {
                          saveContractor: _vm.addContractor,
                          onCancelChanges: function ($event) {
                            _vm.showContractorProfileDialog = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "message-loader" }, [
      _c("b", [_vm._v("Auto deployment is running, please wait..!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }