var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showAbandonReasonDialog,
            callback: function ($$v) {
              _vm.showAbandonReasonDialog = $$v
            },
            expression: "showAbandonReasonDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.dialogTitle))]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-closeAbandonReasonDialog",
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeAbandonReasonDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-autocomplete", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "reasonList",
                            attrs: {
                              label: "Abandon Reason",
                              maxlength: "20",
                              items: _vm.abandonReasonList,
                              "item-value": "id",
                              "item-text": "description",
                              "data-vv-scope": "abandonformReference",
                              "data-vv-name": "Abandon Reason",
                              "error-messages":
                                _vm.errors.collect("Abandon Reason"),
                            },
                            on: { change: _vm.onChangeAbandonType },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c(
                                          "v-list-tile-title",
                                          {
                                            class:
                                              data.item.description ===
                                              "Configure"
                                                ? "bold-select"
                                                : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedAbandonDetails.type,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedAbandonDetails,
                                  "type",
                                  $$v
                                )
                              },
                              expression: "selectedAbandonDetails.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: { label: "Reason", maxlength: "2000" },
                            model: {
                              value: _vm.selectedAbandonDetails.reason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.selectedAbandonDetails,
                                  "reason",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "selectedAbandonDetails.reason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-4" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-closeAbandonReasonDialog",
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$emit("closeAbandonReasonDialog")
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 btn-saveAbandonReason",
                      attrs: {
                        color: "primary",
                        loading: _vm.isLoading,
                        disabled: _vm.isLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.abandonReasonSave()
                        },
                      },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                ref: "configureDropdown",
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }