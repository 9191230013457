var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "questions-list-content" },
    [
      _vm._l(_vm.localPolicyWideQAs, function (item) {
        return _c("EmergencyQAEditControl", {
          key: item.id,
          attrs: {
            "job-id": _vm.jobId,
            "emergency-q-a-id": item.id,
            type: item.type,
            "get-accept-reject-question-criteria":
              _vm.getAcceptRejectQuestionCriteria,
          },
          on: { setSyncing: _vm.setSyncing },
        })
      }),
      _vm._l(_vm.localProductQAs, function (item) {
        return _c("EmergencyQAEditControl", {
          key: item.id,
          attrs: {
            "job-id": _vm.jobId,
            type: item.type,
            "emergency-q-a-id": item.id,
            "get-accept-reject-question-criteria":
              _vm.getAcceptRejectQuestionCriteria,
          },
          on: { setSyncing: _vm.setSyncing },
        })
      }),
      _c("AccessNote", {
        attrs: {
          "access-note-question": _vm.getAccessNoteItem,
          "get-accept-reject-question-criteria":
            _vm.getAcceptRejectQuestionCriteria,
        },
        on: { setSyncing: _vm.setSyncing },
      }),
      _vm._l(_vm.healthAndSafetyQAItems, function (healthAndSafetyQAItem) {
        return _c("HealthAndSafetyQA", {
          key: healthAndSafetyQAItem.id,
          attrs: {
            "health-and-safety-q-a": healthAndSafetyQAItem,
            "get-accept-reject-question-criteria":
              _vm.getAcceptRejectQuestionCriteria,
          },
          on: { setSyncing: _vm.setSyncing },
        })
      }),
      _c("HealthAndSafetyQA", {
        key: _vm.vulnerabilityQAItem.id,
        attrs: {
          "health-and-safety-q-a": _vm.vulnerabilityQAItem,
          "get-accept-reject-question-criteria":
            _vm.getAcceptRejectQuestionCriteria,
        },
        on: { setSyncing: _vm.setSyncing },
      }),
      _vm.isSIJob
        ? _c("EnablementTriggerCard", {
            ref: "enablementTriggerCard",
            attrs: { "job-id": _vm.jobId, "emergency-id": _vm.emergencyId },
            on: { setSyncing: _vm.setSyncing },
          })
        : _vm._e(),
      !_vm.isEmergencyAccepted
        ? _c(
            "div",
            { staticClass: "claim-btn-content grey lighten-3" },
            [
              _c(
                "v-card",
                {
                  staticClass: "elevation-0 grey lighten-3",
                  attrs: { wrap: "" },
                },
                [
                  _c(
                    "v-card-actions",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "confirm-button",
                          class: !_vm.isConfirmationLoading
                            ? "green white--text"
                            : "grey",
                          attrs: {
                            disabled:
                              !_vm.criteriaToConfirmQuestions ||
                              _vm.isSyncing ||
                              _vm.isActionPerformed,
                            loading: _vm.isConfirmationLoading,
                          },
                          on: { click: _vm.onConfirmQuestions },
                        },
                        [_vm._v(" Confirm ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }