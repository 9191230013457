var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c(
            "v-card",
            { staticClass: "mb-3 enablement" },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs11: "" } }, [
                        _c("h3", [_vm._v("Enablement")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "", "text-xs-right": "" } },
                        [
                          _vm.syncing
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "processing-icon",
                                  attrs: { medium: "" },
                                },
                                [_vm._v("sync")]
                              )
                            : _vm.isEnablementCompleted
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "success",
                                  },
                                },
                                [_vm._v("check_circle")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "orange",
                                  },
                                },
                                [_vm._v("info")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "pt-0" },
                [
                  _c("v-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      label: "Please enter the enablement notes",
                      "data-vv-name": "Enablement Notes",
                      "error-messages": _vm.errors.collect("Enablement Notes"),
                      disabled: _vm.disableControls,
                    },
                    on: { focus: _vm.editStarted },
                    model: {
                      value: _vm.enablementNote,
                      callback: function ($$v) {
                        _vm.enablementNote = $$v
                      },
                      expression: "enablementNote",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { attrs: { xs12: "" } },
                [
                  _c("v-spacer"),
                  _vm.isUserRoleSupervisor ||
                  !_vm.isEnablementCompleted ||
                  _vm.isUserRoleClientManager
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "update-button",
                          class: !_vm.saving ? "green white--text" : "grey",
                          attrs: {
                            type: "submit",
                            loading: _vm.saving,
                            disabled: _vm.saving || _vm.syncing,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.saveNotes.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" update ")]
                      )
                    : _vm._e(),
                  !_vm.isEnablementCompleted
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "confirm-button",
                          class: !_vm.saving ? "green white--text" : "grey",
                          attrs: {
                            type: "submit",
                            loading: _vm.saving,
                            disabled: _vm.saving || _vm.syncing,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.editComplete.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Complete ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                attrs: {
                  color: "primary",
                  height: "3",
                  value: "15",
                  indeterminate: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }