var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "taskboard-wrapper" }, [
    _c(
      "div",
      { staticClass: "filter" },
      [
        _c(
          "v-layout",
          { attrs: { wrap: "" } },
          [
            _c("v-spacer"),
            _c(
              "v-flex",
              { staticClass: "text-xs-right", attrs: { xs12: "", "py-0": "" } },
              [
                _c(
                  "span",
                  { staticClass: "search-contractor-jobs" },
                  [
                    _c("v-autocomplete", {
                      staticClass: "job-type",
                      attrs: {
                        items: _vm.getJobtypes,
                        "item-text": "name",
                        "item-value": "id",
                        "hide-details": "",
                        label: "Job Type",
                        loading: _vm.loading.jobType,
                        name: "search-contractor-jobs",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.filterJobsBySelectedJobType($event)
                        },
                      },
                      model: {
                        value: _vm.selectedJobType,
                        callback: function ($$v) {
                          _vm.selectedJobType = $$v
                        },
                        expression: "selectedJobType",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "search-contractor-jobs pl-2" },
                  [
                    _c("v-autocomplete", {
                      staticClass: "search-contractorJobs",
                      attrs: {
                        items: _vm.contractors,
                        "item-text": "companyName",
                        "item-value": "id",
                        "hide-details": "",
                        label: "Search contractor",
                        loading: _vm.loading.contractors,
                        name: "search-contractor-jobs",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.filterJobsBySelectedContractor($event)
                        },
                      },
                      model: {
                        value: _vm.selectedContractor,
                        callback: function ($$v) {
                          _vm.selectedContractor = $$v
                        },
                        expression: "selectedContractor",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "search-contractor-jobs pl-2" },
                  [
                    _c("v-autocomplete", {
                      staticClass: "search-claimManagementCompany",
                      attrs: {
                        items: _vm.claimManagementCompanies,
                        "item-text": "description",
                        "item-value": "description",
                        "hide-details": "",
                        label: "Claim Management Company",
                        loading: _vm.loading.claimManagementCompanies,
                        name: "search-contractor-jobs",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.filterJobsBySelectedClaimManagementCompany(
                            $event
                          )
                        },
                      },
                      model: {
                        value: _vm.selectedClaimManagementCompany,
                        callback: function ($$v) {
                          _vm.selectedClaimManagementCompany = $$v
                        },
                        expression: "selectedClaimManagementCompany",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.loading.jobs,
            expression: "!loading.jobs",
          },
        ],
        staticClass: "jobs-taskboard",
      },
      [
        _c(
          "v-layout",
          { staticClass: "scrollmenu pa-0", attrs: { wrap: "" } },
          _vm._l(_vm.getJobs, function (item, index) {
            return _c(
              "v-flex",
              { key: index, attrs: { xs2: "" } },
              [
                _c("JobStatusBar", {
                  key: index,
                  ref: "jobStatusBar",
                  refInFor: true,
                  attrs: { record: item },
                  on: {
                    filterDelay: _vm.filterDelay,
                    filterVisitCompleted: _vm.filterVisitCompleted,
                  },
                }),
                _vm.showLoadMoreButton(item)
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "task-action text-xs-center grey lighten-2 px-2",
                      },
                      [
                        _vm.activeIndex === index
                          ? _c("v-progress-linear", {
                              attrs: { indeterminate: true, height: "4" },
                            })
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-loadMore",
                            attrs: {
                              color: "primary",
                              flat: "",
                              small: "",
                              block: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.loadMoreJobs(item, index)
                              },
                            },
                          },
                          [_c("b", [_vm._v("Load More")])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading.jobs,
            expression: "loading.jobs",
          },
        ],
      },
      [
        _c("v-progress-circular", {
          staticStyle: { position: "absolute", top: "400px", left: "50%" },
          attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }