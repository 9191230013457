var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "package-selection-content" },
    [
      _vm.packageState
        ? _c(
            "v-card",
            { staticClass: "elevation-0 pa-3" },
            [
              _c(
                "v-form",
                { staticClass: "mb-4", attrs: { autocomplete: "off" } },
                [
                  _c("PackageSelection", {
                    ref: "packageSelection",
                    attrs: {
                      "claim-management-company-id":
                        _vm.job.sIJobDetail.claimManagementCompanyId,
                      disabled:
                        !_vm.editMode ||
                        !_vm.userCanUpdate ||
                        _vm.isJobCompleted,
                      "job-id": _vm.jobId,
                      "disabled-monitoring-product":
                        !_vm.editMode || !_vm.userCanUpdate,
                      "is-testing-only-job": _vm.isTestingOnlyJob,
                    },
                    on: { loadedAPIData: () => (_vm.disableEditMode = false) },
                    model: {
                      value: _vm.packageState,
                      callback: function ($$v) {
                        _vm.packageState = $$v
                      },
                      expression: "packageState",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "action-btn-content grey lighten-4" },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "elevation-0 grey lighten-4",
                      attrs: { wrap: "" },
                    },
                    [
                      _c(
                        "v-card-actions",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-spacer"),
                          _vm.userCanUpdate && !_vm.editMode
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ma-0",
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      _vm.disableEditMode || _vm.isJobCancelled,
                                  },
                                  on: { click: () => (_vm.editMode = true) },
                                },
                                [_vm._v(" Edit ")]
                              )
                            : _vm._e(),
                          _vm.editMode && _vm.userCanUpdate
                            ? [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-0 mr-3",
                                    attrs: { color: "" },
                                    on: { click: _vm.cancelEdit },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ma-0",
                                    attrs: {
                                      color: "primary",
                                      loading: _vm.isSaving,
                                    },
                                    on: { click: _vm.validateAndSave },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    top: "",
                    "multi-line": "",
                    color: "error",
                    timeout: _vm.snackbarTimeout,
                  },
                  model: {
                    value: _vm.showSnackbar,
                    callback: function ($$v) {
                      _vm.showSnackbar = $$v
                    },
                    expression: "showSnackbar",
                  },
                },
                [
                  _c("h4", [_vm._v(_vm._s(_vm.snackbarText))]),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }