var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "heading" }, [_vm._v("Linked Phone Call")]),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("span", [
                _c("b", [_vm._v("Reason:")]),
                _c("span", [_vm._v(_vm._s(_vm.item.reasonType))]),
              ]),
              _c("br"),
              _vm.item.reasonDescription
                ? [
                    _c("span", [
                      _c("b", [_vm._v("Description:")]),
                      _c("span", [_vm._v(_vm._s(_vm.item.reasonDescription))]),
                    ]),
                    _c("br"),
                  ]
                : _vm._e(),
              _c("span", [
                _c("b", [_vm._v("Contractor:")]),
                _c("span", [_vm._v(_vm._s(_vm.item.companyName))]),
              ]),
              _c("br"),
              _c("span", [
                _c("b", [_vm._v("Call At:")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.createdAt))),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-divider"),
                  _c("LinkedPhoneCallDetails", {
                    attrs: {
                      "recording-url": _vm.item.callRecordingUrl,
                      "call-sid": _vm.item.callSid,
                      "job-id": _vm.item.jobId,
                      "show-header": false,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }