var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getAdditionalRequestList.length > 0
    ? _c(
        "v-layout",
        { staticClass: "step-colum mt-3", attrs: { wrap: "" } },
        [
          _c(
            "v-layout",
            { staticClass: "mb-3" },
            [
              _c("v-flex", [_c("h3", [_vm._v("Additional Request")])]),
              _c("v-spacer"),
              !_vm.isClientUserLoggedIn
                ? _c(
                    "v-flex",
                    {
                      staticClass: "total-request-cost",
                      attrs: { "text-xs-right": "" },
                    },
                    [
                      _c("b", [
                        _c("span", [
                          _vm._v(" Total: "),
                          _c("span", { staticClass: "secondary--text" }, [
                            _vm._v(_vm._s(_vm.totalRequestCost)),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                [
                  _c("v-data-table", {
                    staticClass: "gridView gridView-full-width",
                    attrs: {
                      headers: _vm.additionalRequestHeader,
                      items: _vm.getAdditionalRequestList,
                      "hide-actions": "",
                      "item-key": "id",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "items",
                          fn: function (props) {
                            return [
                              _c(
                                "tr",
                                {
                                  staticClass: "expand-row",
                                  on: {
                                    click: function ($event) {
                                      props.expanded = !props.expanded
                                    },
                                  },
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedDate(
                                          props.item.createdAt
                                        )
                                      )
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    _vm._l(
                                      props.item.engineerRequests,
                                      function (material, index) {
                                        return _c("span", { key: index }, [
                                          material.requestType ===
                                          _vm.engineerRequestType[
                                            _vm.engineerRequestType.Material
                                          ]
                                            ? _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      material.materialName.split(
                                                        ","
                                                      ).length
                                                    ) +
                                                    " x parts "
                                                ),
                                              ])
                                            : _vm._e(),
                                          material.requestType ===
                                          _vm.engineerRequestType[
                                            _vm.engineerRequestType.Time
                                          ]
                                            ? _c("span", [
                                                _vm.checkIfMaterialTypeExist(
                                                  props.item.engineerRequests
                                                )
                                                  ? _c("span", [_vm._v(",")])
                                                  : _vm._e(),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.calculateMinutes(
                                                        material.extraMinutes
                                                      )
                                                    ) +
                                                    " hours extra time "
                                                ),
                                              ])
                                            : _vm._e(),
                                          material.requestType ===
                                          _vm.engineerRequestType[
                                            _vm.engineerRequestType.ReturnVisit
                                          ]
                                            ? _c("span", [
                                                _vm.checkIfMaterialOrTimeTypeExist(
                                                  props.item.engineerRequests
                                                )
                                                  ? _c("span", [_vm._v(",")])
                                                  : _vm._e(),
                                                _vm._v(" additional visit "),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _c("td", { staticClass: "text-xs-right" }, [
                                    _vm.parentComponent ===
                                      "EngineerVisitPreview" &&
                                    props.item.requestStatus === "Pending" &&
                                    _vm.engineerVisitDetail.status !==
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Cancelled
                                      ] &&
                                    _vm.engineerVisitDetail.status !==
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Rejected
                                      ] &&
                                    _vm.engineerVisitDetail.status !==
                                      _vm.engineerJobVisitStatus[
                                        _vm.engineerJobVisitStatus.Abandoned
                                      ] &&
                                    _vm.job &&
                                    _vm.job.status !== _vm.completedJobStatus
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "mr-2 ma-0 request-action-btn",
                                                                class:
                                                                  _vm.abilityToEditMaterialRequest(
                                                                    props.item
                                                                      .engineerRequests
                                                                  )
                                                                    ? "disabled-btn"
                                                                    : "",
                                                                attrs: {
                                                                  color:
                                                                    "error",
                                                                  icon: "",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      _vm.openAdditionalRequestActionDialog(
                                                                        props
                                                                          .item
                                                                          .id,
                                                                        props
                                                                          .item
                                                                          .jobId,
                                                                        false,
                                                                        _vm.abilityToEditMaterialRequest(
                                                                          props
                                                                            .item
                                                                            .engineerRequests
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "close"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Reject")])]
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "ma-0 request-action-btn",
                                                                class:
                                                                  _vm.abilityToEditMaterialRequest(
                                                                    props.item
                                                                      .engineerRequests
                                                                  )
                                                                    ? "disabled-btn"
                                                                    : "",
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                  icon: "",
                                                                  small: "",
                                                                },
                                                                nativeOn: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      $event.stopPropagation()
                                                                      _vm.openAdditionalRequestActionDialog(
                                                                        props
                                                                          .item
                                                                          .id,
                                                                        props
                                                                          .item
                                                                          .jobId,
                                                                        true,
                                                                        _vm.abilityToEditMaterialRequest(
                                                                          props
                                                                            .item
                                                                            .engineerRequests
                                                                        )
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "check"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [_c("span", [_vm._v("Accept")])]
                                            ),
                                            _vm.abilityToEditMaterialRequest(
                                              props.item.engineerRequests
                                            )
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 ma-0 request-action-btn edit-material-cost",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      icon: "",
                                                                      small: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.openEditMaterialRequestDialog(
                                                                            props
                                                                              .item
                                                                              .id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "edit"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Edit Material Cost"
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            _vm.isUserRoleContractor &&
                                            props.item.requestStatus ===
                                              "Pending" &&
                                            _vm.abilityToEditMaterialRequest(
                                              props.item.engineerRequests
                                            )
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 ma-0 request-action-btn edit-material-cost",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      icon: "",
                                                                      small: "",
                                                                    },
                                                                    nativeOn: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.stopPropagation()
                                                                          return _vm.openEditMaterialRequestDialog(
                                                                            props
                                                                              .item
                                                                              .id
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "edit"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        "Edit Material Cost"
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "engineer-requests-statu",
                                                    class:
                                                      props.item
                                                        .requestStatus ===
                                                      "Pending"
                                                        ? "orange--text"
                                                        : props.item
                                                            .requestStatus ===
                                                          "Approved"
                                                        ? "green--text"
                                                        : props.item
                                                            .requestStatus ===
                                                          "Rejected"
                                                        ? "red--text"
                                                        : "",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item
                                                            .requestStatus
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                  ]),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "expand",
                          fn: function (props) {
                            return [
                              _c(
                                "v-card",
                                { attrs: { flat: "", "elevation-0": "" } },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "lighten-4 pa-2" },
                                    _vm._l(
                                      props.item.engineerRequests,
                                      function (material, index) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "mb-2" },
                                          [
                                            material.requestType ===
                                            _vm.engineerRequestType[
                                              _vm.engineerRequestType.Material
                                            ]
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      white: "",
                                                      "elevation-1": "",
                                                      "border-table": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs3"
                                                            : material.additionalInfo
                                                            ? "xs4"
                                                            : "xs6",
                                                        attrs: {
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Material"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          material.additionalInfo
                                                            ? "xs3"
                                                            : "xs6",
                                                        attrs: {
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Description"),
                                                        ]),
                                                      ]
                                                    ),
                                                    material.additionalInfo
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            class:
                                                              !_vm.isClientUserLoggedIn
                                                                ? "xs3"
                                                                : "xs5",
                                                            attrs: {
                                                              grey: "",
                                                              "lighten-3": "",
                                                              "pa-2": "",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                "Additional Description"
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs3: "",
                                                              grey: "",
                                                              "lighten-3": "",
                                                              "pa-2": "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v("Price"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs3"
                                                            : material.additionalInfo
                                                            ? "xs4"
                                                            : "xs6",
                                                        attrs: {
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              material.materialName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          material.additionalInfo
                                                            ? "xs3"
                                                            : "xs6",
                                                        attrs: {
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              material.furtherInfo
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    material.additionalInfo
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            class:
                                                              !_vm.isClientUserLoggedIn
                                                                ? "xs3"
                                                                : "xs5",
                                                            attrs: {
                                                              "pa-2": "",
                                                              description: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  material.additionalInfo
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "material-price",
                                                            attrs: {
                                                              xs3: "",
                                                              "pa-2": "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "secondary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getFormatedCurrency(
                                                                        material.materialCost
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            material.requestType ===
                                            _vm.engineerRequestType[
                                              _vm.engineerRequestType.Time
                                            ]
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      "elevation-1": "",
                                                      white: "",
                                                      wrap: "",
                                                      "mb-3": "",
                                                      "border-table": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs3"
                                                            : "xs6",
                                                        attrs: {
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Extra Hours"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs6: "",
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Request Reason"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs3: "",
                                                              grey: "",
                                                              "lighten-3": "",
                                                              "pa-2": "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v("Price"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs3"
                                                            : "xs6",
                                                        attrs: { "pa-2": "" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.calculateMinutes(
                                                                material.extraMinutes
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs6: "",
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              material.timeRequestReason
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "time-request-price",
                                                            attrs: {
                                                              xs3: "",
                                                              "pa-2": "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "secondary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getFormatedCurrency(
                                                                        props
                                                                          .item
                                                                          .contractorTimeValue
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            material.requestType ===
                                            _vm.engineerRequestType[
                                              _vm.engineerRequestType
                                                .ReturnVisit
                                            ]
                                              ? _c(
                                                  "v-layout",
                                                  {
                                                    attrs: {
                                                      wrap: "",
                                                      white: "",
                                                      "elevation-1": "",
                                                      "border-table": "",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs3: "",
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Visit At"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs2: "",
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Return Visit Type"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs3: "",
                                                          grey: "",
                                                          "lighten-3": "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Reason"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs2"
                                                            : "xs4",
                                                        attrs: {
                                                          "pa-0": "",
                                                          grey: "",
                                                          "signature-text": "",
                                                          "lighten-3": "",
                                                          "py-2": "",
                                                          "text-xs-center": "",
                                                        },
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Signature"),
                                                        ]),
                                                      ]
                                                    ),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: {
                                                              xs2: "",
                                                              grey: "",
                                                              "lighten-3": "",
                                                              "pa-2": "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v("Price"),
                                                            ]),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs3: "",
                                                          "pa-2": "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getFormattedDate(
                                                                material.returnVisitAt
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("br"),
                                                        material.returnVisitDuration
                                                          ? _c(
                                                              "v-tooltip",
                                                              {
                                                                staticClass:
                                                                  "returnVisitDuration",
                                                                attrs: {
                                                                  right: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              "b",
                                                                              _vm._g(
                                                                                {},
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "(" +
                                                                                    _vm._s(
                                                                                      _vm.getFormattedVisitDuration(
                                                                                        material.returnVisitDuration
                                                                                      )
                                                                                    ) +
                                                                                    ")"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Estimated visit duration time"
                                                                  ),
                                                                ]),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs2: "",
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              material.returnVisitType
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs3: "",
                                                          "pa-2": "",
                                                          description: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              material.reasonForReturnVisit
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        class:
                                                          !_vm.isClientUserLoggedIn
                                                            ? "xs2"
                                                            : "xs4",
                                                        attrs: {
                                                          "pa-2": "",
                                                          description: "",
                                                          "text-xs-center": "",
                                                        },
                                                      },
                                                      [
                                                        material.customerSignatureUrl
                                                          ? _c("img", {
                                                              staticClass:
                                                                "signature-return-visit",
                                                              attrs: {
                                                                src: _vm.getBlobUrl(
                                                                  material.customerSignatureUrl
                                                                ),
                                                                alt: "signature",
                                                              },
                                                            })
                                                          : _c(
                                                              "span",
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "remove"
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    ),
                                                    !_vm.isClientUserLoggedIn
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            staticClass:
                                                              "return-visit-price",
                                                            attrs: {
                                                              xs2: "",
                                                              "pa-2": "",
                                                              description: "",
                                                              "text-xs-right":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b",
                                                              {
                                                                staticClass:
                                                                  "secondary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getFormatedCurrency(
                                                                        props
                                                                          .item
                                                                          .contractorExtraVisitValue
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1616608164
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showRejectDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "400" },
                  model: {
                    value: _vm.showRejectDialog,
                    callback: function ($$v) {
                      _vm.showRejectDialog = $$v
                    },
                    expression: "showRejectDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "title" }, [
                        _vm._v("Are you sure to Reject?"),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "py-0" },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              label: "Why you want to Reject?",
                              required: "",
                              "data-vv-scope": "materialRejectReason",
                              "data-vv-name": "Reason",
                              "error-messages": _vm.errors.collect("Reason"),
                            },
                            model: {
                              value: _vm.materialRejectReason,
                              callback: function ($$v) {
                                _vm.materialRejectReason = $$v
                              },
                              expression: "materialRejectReason",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "pa-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "flat" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.showRejectDialog = false
                                },
                              },
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text mr-0",
                              attrs: {
                                color: "primary",
                                disabled: _vm.onSubmitLoading,
                                loading: _vm.onSubmitLoading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.updateEmergencyAdditionalRequest(
                                    "Rejected"
                                  )
                                },
                              },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "400" },
              model: {
                value: _vm.showapproveDialog,
                callback: function ($$v) {
                  _vm.showapproveDialog = $$v
                },
                expression: "showapproveDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "title" }, [
                    _vm._v("Are you sure to Approve?"),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pa-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary darken-1", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showapproveDialog = false
                            },
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.onSubmitLoading,
                            loading: _vm.onSubmitLoading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.updateEmergencyAdditionalRequest(
                                "Approved"
                              )
                            },
                          },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.snackbarTimeout, bottom: "", left: "" },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.snackbar = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _vm.useExtraBalanceConfirmationDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { persistent: "", "max-width": "400" },
                  model: {
                    value: _vm.useExtraBalanceConfirmationDialog,
                    callback: function ($$v) {
                      _vm.useExtraBalanceConfirmationDialog = $$v
                    },
                    expression: "useExtraBalanceConfirmationDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", { staticClass: "pa-3" }, [
                        _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                "You have " +
                                  _vm.getFormatedCurrency(
                                    _vm.updateEngineerRequestResponseModel
                                      .extraBalanceLeft
                                  ) +
                                  " extra balance. Do you want to use this balance to proceed further?"
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c(
                        "v-card-actions",
                        { staticClass: "pt-2 px-3 pb-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { small: "", flat: "" },
                              on: {
                                click:
                                  _vm.onCancelExtraBalanceConfirmationDialog,
                              },
                            },
                            [_vm._v("No")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                small: "",
                                disabled: _vm.useExtraBalanceLoading,
                                loading: _vm.useExtraBalanceLoading,
                              },
                              on: { click: _vm.onConfirmUseExtraBalance },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.editMaterialRequestDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600",
                    persistent: "",
                    "content-class": "v-dialog--scrollable",
                  },
                  model: {
                    value: _vm.editMaterialRequestDialog,
                    callback: function ($$v) {
                      _vm.editMaterialRequestDialog = $$v
                    },
                    expression: "editMaterialRequestDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Update Material Cost"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeEditMaterialRequestDialog.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "px-3 scroll-content-dialog" },
                        [
                          _c("table", { attrs: { width: "100%" } }, [
                            _c("thead", { staticClass: "text-left" }, [
                              _c("tr", [
                                _c("th", [_vm._v("Material")]),
                                _c("th", { staticClass: "pl-2" }, [
                                  _vm._v("Price"),
                                ]),
                                _c("th", { staticStyle: { width: "0" } }),
                              ]),
                            ]),
                            _c(
                              "tbody",
                              [
                                _vm.loadingMaterialRequests
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "text-center pa-4",
                                          attrs: { colspan: "3" },
                                        },
                                        [
                                          _c("v-progress-circular", {
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary",
                                              size: "64",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._l(
                                  _vm.materialRequests,
                                  function (item, i) {
                                    return _c("tr", { key: i }, [
                                      _c(
                                        "td",
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.materials,
                                              "hide-selected": "",
                                              "item-text": "name",
                                              "hide-details": "",
                                              placeholder: "Select material",
                                            },
                                            model: {
                                              value: item.name,
                                              callback: function ($$v) {
                                                _vm.$set(item, "name", $$v)
                                              },
                                              expression: "item.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "pl-2" },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              "hide-details": "",
                                              filled: "",
                                              name: "price",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "prepend-inner",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        { staticClass: "pa-1" },
                                                        [_vm._v("£")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: item.cost,
                                              callback: function ($$v) {
                                                _vm.$set(item, "cost", $$v)
                                              },
                                              expression: "item.cost",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "action-button",
                                              attrs: {
                                                color: "error",
                                                flat: "",
                                              },
                                              on: {
                                                click: () =>
                                                  _vm.onRemoveMaterial(i),
                                              },
                                            },
                                            [_c("v-icon", [_vm._v("close")])],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                ),
                                _c("tr", [
                                  _c("td"),
                                  _c("td"),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "action-button",
                                          attrs: { color: "primary", flat: "" },
                                          on: { click: _vm.onAddMaterial },
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ]),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.closeEditMaterialRequestDialog.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                color: "primary submit-material-cost",
                                disabled:
                                  _vm.isLoading ||
                                  !_vm.materialRequests.length ||
                                  _vm.materialRequests.some((mr) => !mr.name) ||
                                  _vm.materialRequests.some(
                                    (mr) => !mr.cost || mr.cost <= 0
                                  ),
                                loading: _vm.isLoading,
                              },
                              on: { click: _vm.updateMaterialRequest },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }