var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "pr-2": "", "pl-0": "", "pt-1": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                [_c("CTPTable", { attrs: { "table-name": "ctpDashboard" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }