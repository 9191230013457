var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.ready
    ? _c(
        "div",
        {
          staticClass: "dashboard outstanding-complaint-table",
          attrs: { id: _vm.tableName },
        },
        [
          _vm.complaints && _vm.complaints.length > 0
            ? _c(
                "v-layout",
                { attrs: { wrap: "", "grid-view": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "request-info": "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-0" },
                        [
                          _c("v-text-field", {
                            staticClass: "outstanding-search",
                            attrs: {
                              "append-icon": "search",
                              label: "Search",
                              "single-line": "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.searchComplaint,
                              callback: function ($$v) {
                                _vm.searchComplaint = $$v
                              },
                              expression: "searchComplaint",
                            },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _vm.showExportButton
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass: "mr-0 mb-0",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.openExportToExcelDialog,
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  { staticClass: "md-icon" },
                                                  [_vm._v("get_app")]
                                                ),
                                                _vm._v("   Export "),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                968520094
                              ),
                            },
                            [_c("span", [_vm._v("Export To Excel")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "bar",
                          rawName: "v-bar",
                          value: { useScrollbarPseudo: true },
                          expression: "{ useScrollbarPseudo: true }",
                        },
                      ],
                      staticClass: "elevation-1",
                      attrs: { xs12: "", "mt-2": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "complaint-list" },
                        [
                          _c("v-data-table", {
                            staticClass: "complaint-table",
                            attrs: {
                              headers: _vm.complaintListHeaders,
                              items: _vm.complaints,
                              search: _vm.searchComplaint,
                              loading: _vm.loadingComplaintList,
                              pagination: _vm.pagination,
                              "custom-sort": _vm.customSort,
                            },
                            on: {
                              "update:pagination": function ($event) {
                                _vm.pagination = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "complaintListHeaders",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        _vm._l(
                                          props.complaintListHeaders,
                                          function (header) {
                                            return _c(
                                              "th",
                                              {
                                                key: header.text,
                                                class: [
                                                  "column sortable",
                                                  _vm.pagination.descending
                                                    ? "desc"
                                                    : "asc",
                                                  header.value ===
                                                  _vm.pagination.sortBy
                                                    ? "active"
                                                    : "",
                                                ],
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.changeSort(
                                                      header.value
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(header.text) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          class: _vm.isComplaintOverdue(
                                            props.item
                                          )
                                            ? "red lighten-5"
                                            : "",
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.selected = !props.selected
                                            },
                                          },
                                        },
                                        [
                                          _c("td", [
                                            _c(
                                              "a",
                                              {
                                                staticClass: "secondary--text",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.redirectToJob(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(props.item.jobId) +
                                                    " " +
                                                    _vm._s(
                                                      props.item.indexCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.policyName)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item
                                                  .complaintTypeDescription
                                                  ? props.item
                                                      .complaintTypeDescription
                                                  : "-"
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.toCommaSeparatedList(
                                                  props.item
                                                    .concernsRaisedDescription
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(props.item.severity) +
                                                "   "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.severityTypeTextStyle(
                                                    props.item.severity
                                                  ),
                                              },
                                              [
                                                _vm._v(
                                                  " (" +
                                                    _vm._s(
                                                      _vm.severityTypeText(
                                                        props.item.severity
                                                      )
                                                    ) +
                                                    ") "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getVulnerabilityStatus(
                                                  props.item.vulnerabilityStatus
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: _vm.userList,
                                                  "item-text": "displayValue",
                                                  "item-value": "id",
                                                  label: "Who is responsible",
                                                  "return-object": "",
                                                  disabled:
                                                    !_vm.canUpdateComplaintResponsiblePerson,
                                                  "hide-details": "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateComplaintResponsiblePerson(
                                                      $event,
                                                      props.item.jobId,
                                                      props.item.id
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    props.item
                                                      .responsiblePerson,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      props.item,
                                                      "responsiblePerson",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "props.item.responsiblePerson",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._l(
                                            _vm.letterTypes,
                                            function (type) {
                                              return _c(
                                                "complaint-table-letter-icon",
                                                {
                                                  key: type,
                                                  attrs: {
                                                    complaint: props.item,
                                                    "letter-type": type,
                                                  },
                                                }
                                              )
                                            }
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getFormattedDateTime(
                                                  props.item.dateOfResolution
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.receivedDate
                                                    ? _vm.getFormattedDateTime(
                                                        props.item.receivedDate
                                                      )
                                                    : _vm.getFormattedDateTime(
                                                        props.item.createdAt
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.compensationPaid > 0
                                                  ? `£${props.item.compensationPaid}`
                                                  : ``
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getFormattedDateTime(
                                                  props.item.reopenedDate
                                                )
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.calculateAge(
                                                  props.item.createdAt
                                                )
                                              )
                                            ),
                                          ]),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              545308394
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("PartialJobView", {
                    attrs: {
                      "job-id": _vm.selectedJobIdToExpand,
                      "record-id": _vm.complainId,
                      "record-type": "complaint",
                    },
                    on: { closeJobView: _vm.closeJobView },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.exportToExcelDialog
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600",
                    persistent: "",
                    "content-class": "v-dialog--scrollable",
                  },
                  model: {
                    value: _vm.exportToExcelDialog,
                    callback: function ($$v) {
                      _vm.exportToExcelDialog = $$v
                    },
                    expression: "exportToExcelDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Export To Excel")]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.exportToExcelDialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "px-3 pt-0 scroll-content-dialog" },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0",
                              attrs: { "grid-list-xl": "" },
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { "date-content": "", xs6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "exportComplaintsFromDate",
                                        attrs: {
                                          "date-time": _vm.exportComplaintsFrom,
                                          "is-static-location": false,
                                          "place-holder-text": "From",
                                          "is-current-time": false,
                                          "is-validation-required": true,
                                          "show-present-time-icon": false,
                                          "max-date": _vm.selectableDateTime(
                                            _vm.exportComplaintsTo
                                          ),
                                          "hide-time-picker": true,
                                          "display-format": _vm.dateFormat(),
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.exportComplaintsFrom = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.exportComplaintsFrom = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { "date-content": "", xs6: "" } },
                                    [
                                      _c("DateTimePicker", {
                                        ref: "exportComplaintsToDate",
                                        attrs: {
                                          "date-time": _vm.exportComplaintsTo,
                                          "is-static-location": false,
                                          "place-holder-text": "To",
                                          "is-current-time": false,
                                          "is-validation-required": true,
                                          "show-present-time-icon": false,
                                          "min-date": _vm.selectableDateTime(
                                            _vm.exportComplaintsFrom
                                          ),
                                          "max-date":
                                            _vm.maxComplaintExportDate,
                                          "hide-time-picker": true,
                                          "display-format": _vm.dateFormat(),
                                        },
                                        on: {
                                          "update:dateTime": function ($event) {
                                            _vm.exportComplaintsTo = $event
                                          },
                                          "update:date-time": function (
                                            $event
                                          ) {
                                            _vm.exportComplaintsTo = $event
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: "Complaint Status",
                                          items: _vm.exportComplaintsStatuses,
                                        },
                                        model: {
                                          value: _vm.selectedStatus,
                                          callback: function ($$v) {
                                            _vm.selectedStatus = $$v
                                          },
                                          expression: "selectedStatus",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.exportToExcelDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "export-excel",
                            {
                              staticClass: "btn ml-2",
                              attrs: {
                                "export-fields": _vm.exportToExcelHeaderFields,
                                fetch: _vm.exportComplaints,
                                worksheet: "Complaint Details",
                                "before-generate": _vm.startExportGeneration,
                                "before-finish": _vm.stopExportGeneration,
                                type: "xls",
                                name: "complaintList.xls",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  ref: "ExportToExcel",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.exportToExcelLoader,
                                    disabled: _vm.exportToExcelLoader,
                                  },
                                },
                                [
                                  _c("v-icon", { staticClass: "md-icon" }, [
                                    _vm._v("get_app"),
                                  ]),
                                  _vm._v("   Export "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { dark: "", flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.snackbar = false
                    },
                  },
                },
                [_vm._v("close")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }