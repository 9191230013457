var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return (_vm.isComponentLoaded || _vm.disabled) && _vm.state
    ? _c(
        "div",
        [
          _c(
            "v-container",
            { staticClass: "pa-0" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _vm.isComponentLoaded
                    ? _c("v-select", {
                        ref: "packageSelector",
                        staticClass: "select-package",
                        attrs: {
                          label: _vm.disabled ? "" : "Select Package",
                          items: _vm.packageSelectItems,
                          disabled:
                            !_vm.isExtrasLoaded ||
                            _vm.disabled ||
                            _vm.isTestingOnlyJob,
                        },
                        on: { input: _vm.changePackage },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "obsolete-item": item.isObsolete,
                                        "deleted-item": item.isDeleted,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.text) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "selection",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "obsolete-item": item.isObsolete,
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.text) + " ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3079675701
                        ),
                        model: {
                          value: _vm.state.selectedPackage,
                          callback: function ($$v) {
                            _vm.$set(_vm.state, "selectedPackage", $$v)
                          },
                          expression: "state.selectedPackage",
                        },
                      })
                    : _c("v-select", {
                        ref: "offlinePackageDisplay",
                        attrs: {
                          disabled: "",
                          placeholder: _vm.state.selectedPackage
                            ? `${_vm.state.selectedPackage.name} - £${_vm.state.selectedPackage.price}`
                            : "--- No Package ---",
                        },
                      }),
                  _vm.state.selectedPackage
                    ? _c(
                        "v-tooltip",
                        {
                          staticClass: "mt-4 ml-2",
                          attrs: { bottom: "", "max-width": "500" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass:
                                            "grey--text text--darken-1",
                                        },
                                        on
                                      ),
                                      [_vm._v("info")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            862549454
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.state.selectedPackage.description)
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.state && _vm.state.selectedPackage
                    ? _c("UpdateProductPriceDialog", {
                        ref: "updateProductPriceDialogRef",
                        attrs: {
                          "price-label": `£${_vm.state.selectedPackage.price}`,
                          "is-price-updated":
                            _vm.state.selectedPackage.isPriceUpdated,
                          "product-price": _vm.productBasePrice,
                          "is-disabled": _vm.disabled,
                          "is-package-selection": true,
                        },
                        on: {
                          "update:isPriceUpdated": function ($event) {
                            return _vm.$set(
                              _vm.state.selectedPackage,
                              "isPriceUpdated",
                              $event
                            )
                          },
                          "update:is-price-updated": function ($event) {
                            return _vm.$set(
                              _vm.state.selectedPackage,
                              "isPriceUpdated",
                              $event
                            )
                          },
                          "update:productPrice": function ($event) {
                            _vm.productBasePrice = $event
                          },
                          "update:product-price": function ($event) {
                            _vm.productBasePrice = $event
                          },
                          editProductPrice: () => {
                            _vm.openEditProductPriceDialog(
                              _vm.state.selectedPackage.price
                            )
                          },
                          productPriceUpdated: _vm.onProductPriceUpdated,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.isExtrasLoaded || _vm.disabled
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.state.selectedPits, function (pit, ind) {
                        return [
                          _c("SIPitCard", {
                            key: ind,
                            ref: "Pit",
                            refInFor: true,
                            attrs: {
                              "pit-title": `Pit ${ind + 1}`,
                              "included-tests": _vm.state.packageIncludedTests,
                              "price-included": true,
                              disabled: _vm.disabled,
                              pits: _vm.pits,
                              "is-engineer-visit-created":
                                _vm.isEngineerVisitCreated,
                              "added-pit-ids": _vm.pitIds,
                              "is-new-job": _vm.jobId ? false : true,
                            },
                            on: {
                              "delete-pit": () =>
                                _vm.deletePit(pit, _vm.state.selectedPits),
                            },
                            model: {
                              value: pit.value,
                              callback: function ($$v) {
                                _vm.$set(pit, "value", $$v)
                              },
                              expression: "pit.value",
                            },
                          }),
                        ]
                      }),
                      _vm.pitProduct && !_vm.disabled
                        ? _c(
                            "v-layout",
                            { staticClass: "mb-4" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  ref: "addPit",
                                  staticClass: "ma-0",
                                  attrs: {
                                    flat: "",
                                    solid: "",
                                    disabled: _vm.reachedPitLimit(),
                                  },
                                  on: {
                                    click: () =>
                                      _vm.addPit(
                                        _vm.pitProduct,
                                        _vm.state.selectedPits
                                      ),
                                  },
                                },
                                [
                                  _vm._v(" Add Pit "),
                                  _c("v-icon", [_vm._v("add")]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isExtrasLoaded || _vm.disabled,
                      expression: "isExtrasLoaded || disabled",
                    },
                  ],
                  staticClass: "pa-3 grey lighten-3 mb-2",
                  attrs: { wrap: "" },
                },
                [
                  _c("v-flex", { attrs: { "pb-2": "", xs12: "" } }, [
                    _c("h3", [_vm._v("Additional Products")]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { "pb-2": "", xs12: "" } },
                    [
                      !_vm.disabled
                        ? _c("v-autocomplete", {
                            key: _vm.bulkPitModel,
                            ref: "extraPitSelector",
                            staticClass: "additional-product",
                            attrs: {
                              "search-input": _vm.bulkPitModel,
                              placeholder: "Add Pit Products...",
                              items: _vm.extraPitSelectItems,
                              disabled: _vm.isTestingOnlyJob,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.bulkPitModel = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.bulkPitModel = $event
                              },
                              input: (prod) => {
                                _vm.bulkPitModel = prod.text
                                _vm.addBulkPit(prod)
                                _vm.bulkPitModel = ""
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "deleted-item": item.isDeleted,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.text) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              426262108
                            ),
                          })
                        : _vm._e(),
                      _vm._l(
                        _vm.state.selectedBulkPits,
                        function (bulkPit, ind) {
                          return [
                            _c(
                              "div",
                              { key: ind },
                              [
                                _c(
                                  "v-card",
                                  { staticClass: "mb-4 pit-card elevation-2" },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _c(
                                          "h3",
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(bulkPit.description) +
                                                " - "
                                            ),
                                            _c("UpdateProductPriceDialog", {
                                              attrs: {
                                                "price-label": `£${bulkPit.basePrice}`,
                                                "is-price-updated":
                                                  bulkPit.isPriceUpdated,
                                                "product-price":
                                                  _vm.productBasePrice,
                                                "is-disabled": _vm.disabled,
                                              },
                                              on: {
                                                "update:isPriceUpdated":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      bulkPit,
                                                      "isPriceUpdated",
                                                      $event
                                                    )
                                                  },
                                                "update:is-price-updated":
                                                  function ($event) {
                                                    return _vm.$set(
                                                      bulkPit,
                                                      "isPriceUpdated",
                                                      $event
                                                    )
                                                  },
                                                "update:productPrice":
                                                  function ($event) {
                                                    _vm.productBasePrice =
                                                      $event
                                                  },
                                                "update:product-price":
                                                  function ($event) {
                                                    _vm.productBasePrice =
                                                      $event
                                                  },
                                                editProductPrice: () => {
                                                  _vm.openEditProductPriceDialog(
                                                    bulkPit
                                                  )
                                                },
                                                productPriceUpdated:
                                                  _vm.onProductPriceUpdated,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("v-spacer"),
                                        !_vm.disabled
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteBulkPit(
                                                      bulkPit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("delete"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c("v-flex", [
                                      bulkPit.productAttributes.find(
                                        (e) => e.attributeName === "maxPits"
                                      )
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              bulkPit.pits,
                                              function (pit, index) {
                                                return _c(
                                                  "div",
                                                  { key: index + "x" },
                                                  [
                                                    _c("SIPitCard", {
                                                      key: index,
                                                      ref: "ExtraPit",
                                                      refInFor: true,
                                                      attrs: {
                                                        "pit-title": `Extra Pit ${
                                                          index + 1
                                                        }`,
                                                        "price-included": true,
                                                        "included-tests":
                                                          _vm.state
                                                            .packageIncludedTests,
                                                        disabled: _vm.disabled,
                                                        pits: _vm.pits,
                                                        "is-engineer-visit-created":
                                                          _vm.isEngineerVisitCreated,
                                                        "allowed-delete": false,
                                                        "added-pit-ids":
                                                          _vm.pitIds,
                                                        "is-new-job": _vm.jobId
                                                          ? false
                                                          : true,
                                                      },
                                                      model: {
                                                        value: pit.value,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pit,
                                                            "value",
                                                            $$v
                                                          )
                                                        },
                                                        expression: "pit.value",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      !_vm.disabledMonitoringProduct
                        ? _c("v-autocomplete", {
                            key: _vm.monitoringProductModel,
                            ref: "extraMonitoringSelector",
                            staticClass: "monitoring-product-card",
                            attrs: {
                              disabled: _vm.isTestingOnlyJob,
                              "search-input": _vm.monitoringProductModel,
                              "return-object": "",
                              placeholder: "Add Monitoring Products...",
                              items: _vm.filteredMonitoringProductItems,
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.monitoringProductModel = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.monitoringProductModel = $event
                              },
                              input: _vm.addMonitoringProduct,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          class: {
                                            "deleted-item": item.isDeleted,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.text) + " ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              426262108
                            ),
                          })
                        : _vm._e(),
                      _vm._l(
                        _vm.state.selectedMonitoringProducts,
                        function (product, index) {
                          return [
                            _c("MonitoringProductCard", {
                              key: index + "x",
                              ref: "MonitoringProductCard",
                              refInFor: true,
                              attrs: {
                                "product-title": product.name,
                                disabled: _vm.disabledMonitoringProduct,
                              },
                              on: { "delete-product": _vm.deleteProduct },
                              model: {
                                value:
                                  _vm.state.selectedMonitoringProducts[index],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.state.selectedMonitoringProducts,
                                    index,
                                    $$v
                                  )
                                },
                                expression:
                                  "state.selectedMonitoringProducts[index]",
                              },
                            }),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "v-flex",
                    { attrs: { "pb-2": "", xs12: "" } },
                    [
                      !_vm.disabled
                        ? _c("v-autocomplete", {
                            ref: "siteExtraSelector",
                            staticClass: "site-extra-product",
                            attrs: {
                              clearable: "",
                              placeholder: "Select Site Extras",
                              items: _vm.productSelectItems,
                              multiple: "",
                              disabled: _vm.disabled,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "mr-2" },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color:
                                                  _vm.state.selectedOther.find(
                                                    (i) =>
                                                      i.id === item.value.id
                                                  )
                                                    ? "primary"
                                                    : "none",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.state.selectedOther.find(
                                                      (i) =>
                                                        i.id === item.value.id
                                                    )
                                                      ? "check_box"
                                                      : "check_box_outline_blank"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "obsolete-item":
                                                    item.isObsolete,
                                                  "deleted-item":
                                                    item.isDeleted,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(item.text) + " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item, index }) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "v-select__selection v-select__selection--comma",
                                        },
                                        [
                                          index + 1 <
                                          _vm.state.selectedOther.length
                                            ? _c(
                                                "span",
                                                {
                                                  class: {
                                                    "obsolete-item":
                                                      item.isObsolete,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text + ",") +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  class: {
                                                    "obsolete-item":
                                                      item.isObsolete,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              434571577
                            ),
                            model: {
                              value: _vm.state.selectedOther,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "selectedOther", $$v)
                              },
                              expression: "state.selectedOther",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.state.selectedOther.length > 0,
                          expression: "state.selectedOther.length > 0",
                        },
                      ],
                      attrs: { xs12: "" },
                    },
                    [
                      _c(
                        "table",
                        { staticClass: "white pa-3 quantity-table" },
                        [
                          _c("thead", { staticClass: "pb-2" }, [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-left",
                                  attrs: { width: "250" },
                                },
                                [_vm._v("Name")]
                              ),
                              _c("th", { staticClass: "text-left" }, [
                                _vm._v("Quantity"),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.state.selectedOther,
                              function (extra, ind) {
                                return _c(
                                  "tr",
                                  {
                                    key: ind,
                                    ref: "SiteExtra",
                                    refInFor: true,
                                  },
                                  [
                                    _c(
                                      "td",
                                      [
                                        _vm.isExtrasLoaded
                                          ? _c("v-label", [
                                              _c(
                                                "span",
                                                {
                                                  class: {
                                                    "obsolete-item":
                                                      _vm.extraIsObsolete(
                                                        extra
                                                      ),
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(extra.name) + " -"
                                                  ),
                                                ]
                                              ),
                                            ])
                                          : _c("v-label", [
                                              _vm._v(_vm._s(extra.name) + " -"),
                                            ]),
                                        _c("UpdateProductPriceDialog", {
                                          ref: "updateProductPriceDialogRef",
                                          refInFor: true,
                                          attrs: {
                                            "price-label": `£${extra.basePrice}`,
                                            "is-price-updated":
                                              extra.isPriceUpdated,
                                            "product-price":
                                              _vm.productBasePrice,
                                            "is-disabled": _vm.disabled,
                                          },
                                          on: {
                                            "update:isPriceUpdated": function (
                                              $event
                                            ) {
                                              return _vm.$set(
                                                extra,
                                                "isPriceUpdated",
                                                $event
                                              )
                                            },
                                            "update:is-price-updated":
                                              function ($event) {
                                                return _vm.$set(
                                                  extra,
                                                  "isPriceUpdated",
                                                  $event
                                                )
                                              },
                                            "update:productPrice": function (
                                              $event
                                            ) {
                                              _vm.productBasePrice = $event
                                            },
                                            "update:product-price": function (
                                              $event
                                            ) {
                                              _vm.productBasePrice = $event
                                            },
                                            editProductPrice: () => {
                                              _vm.openEditProductPriceDialog(
                                                extra
                                              )
                                            },
                                            productPriceUpdated:
                                              _vm.onProductPriceUpdated,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              [
                                                _c("v-text-field", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value: `required|min_value:0`,
                                                      expression:
                                                        "`required|min_value:0`",
                                                    },
                                                  ],
                                                  attrs: {
                                                    "data-vv-name": extra.name,
                                                    "error-messages":
                                                      _vm.validationMessage(
                                                        extra.name
                                                      ),
                                                    type: "number",
                                                    hint: extra.unit,
                                                    disabled: _vm.disabled,
                                                    required: "",
                                                  },
                                                  on: {
                                                    input: () =>
                                                      (_vm.totalPriceKey =
                                                        !_vm.totalPriceKey),
                                                  },
                                                  model: {
                                                    value: extra.quantity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        extra,
                                                        "quantity",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "extra.quantity",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-tooltip",
                                              {
                                                staticClass: "ml-2 mt-2",
                                                attrs: {
                                                  bottom: "",
                                                  "max-width": "300",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "grey--text text--darken-1",
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("info")]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(extra.description)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _vm.isExtrasLoaded || _vm.disabled
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Additional Product Notes",
                              disabled: _vm.disabled,
                              rows: "3",
                            },
                            model: {
                              value: _vm.state.extraProductNotes,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "extraProductNotes", $$v)
                              },
                              expression: "state.extraProductNotes",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-layout",
                [
                  _vm.isExtrasLoaded || _vm.disabled
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Job Notes",
                              disabled: _vm.disabled,
                              rows: "3",
                            },
                            model: {
                              value: _vm.state.jobNotes,
                              callback: function ($$v) {
                                _vm.$set(_vm.state, "jobNotes", $$v)
                              },
                              expression: "state.jobNotes",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.isExtrasLoaded || _vm.disabled
                ? _c(
                    "v-layout",
                    { key: _vm.totalPriceKey, staticClass: "price-label pa-0" },
                    [
                      _c("h3", { staticClass: "ml-auto" }, [
                        _vm._v(" Total Price: "),
                        _c("span", { staticClass: "primary--text" }, [
                          _vm._v("£" + _vm._s(_vm.state.totalPrice)),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isComponentLoaded && !_vm.isExtrasLoaded && !_vm.disabled
                ? _c(
                    "div",
                    { staticStyle: { "min-height": "400px" } },
                    [
                      _c("v-progress-circular", {
                        style: _vm.getLoaderStyle(70),
                        attrs: {
                          width: 2,
                          size: 50,
                          indeterminate: "",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { ref: "componentLoader", staticStyle: { "min-height": "400px" } },
        [
          _c("v-progress-circular", {
            style: _vm.getLoaderStyle(100),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }