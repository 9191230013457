var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    (_vm.hasList ? _vm.index === 0 : true)
      ? _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v("Emergency Answer")]),
        ])
      : _vm._e(),
    _vm.questionText
      ? _c("div", { class: _vm.hasList ? "" : "mb-1" }, [
          _c("label", [_vm._v("Question: ")]),
          _c("span", [_vm._v(_vm._s(_vm.questionText))]),
        ])
      : _vm._e(),
    _vm.item.answer
      ? _c("div", { class: _vm.hasList ? "mb-2" : "mb-1" }, [
          _c("label", [_vm._v("Answer: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.answer))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }