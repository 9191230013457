var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-expansion-panel",
        { staticClass: "data-accordion elevation-0" },
        [
          _vm.getTrialPitDetail.length > 0
            ? _c(
                "v-expansion-panel-content",
                { staticClass: "mb-3 elevation-1" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "align-center",
                      attrs: { slot: "header", wrap: "" },
                      slot: "header",
                    },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c(
                          "h3",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v("Trial Pit Data")]
                        ),
                      ]),
                      _c(
                        "v-flex",
                        {
                          staticClass: "text-xs-right test-verify",
                          attrs: { xs4: "" },
                        },
                        [
                          _c("b", { staticClass: "green--text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isTrialPitVerified ? "Verified" : "Verify"
                                ) +
                                " "
                            ),
                          ]),
                          !_vm.isTrialPitVerified
                            ? _c("v-checkbox", {
                                attrs: { color: "green" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.verifySiteData(
                                      _vm.siProductType[_vm.siProductType.Pit]
                                    )
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _vm.isTrialPitVerified,
                                  callback: function ($$v) {
                                    _vm.isTrialPitVerified = $$v
                                  },
                                  expression: "isTrialPitVerified",
                                },
                              })
                            : _c("v-checkbox", {
                                attrs: { color: "green" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.unverifySiteData(
                                      _vm.siProductType[_vm.siProductType.Pit]
                                    )
                                  },
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                model: {
                                  value: _vm.isTrialPitVerified,
                                  callback: function ($$v) {
                                    _vm.isTrialPitVerified = $$v
                                  },
                                  expression: "isTrialPitVerified",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("SitePitDetailLog", {
                            attrs: {
                              "job-id": _vm.jobId,
                              "engineer-visit-detail-id": _vm.itemId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.getCCTVControlLogDetail
            ? _c(
                "v-expansion-panel-content",
                { staticClass: "mb-3 elevation-1" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "align-center",
                      attrs: { slot: "header", wrap: "" },
                      slot: "header",
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          class:
                            _vm.getCCTVControlLogDetail.cctvControlRunLogs &&
                            _vm.getCCTVControlLogDetail.cctvControlRunLogs
                              .length > 0
                              ? "xs8"
                              : "xs12",
                        },
                        [
                          _c(
                            "h3",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v("CCTV Logs")]
                          ),
                        ]
                      ),
                      _vm.getCCTVControlLogDetail.cctvControlRunLogs &&
                      _vm.getCCTVControlLogDetail.cctvControlRunLogs.length > 0
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { xs4: "" },
                            },
                            [
                              _c("b", { staticClass: "green--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isCCTVVerified ? "Verified" : "Verify"
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.isCCTVVerified
                                ? _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.verifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.CCTV
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isCCTVVerified,
                                      callback: function ($$v) {
                                        _vm.isCCTVVerified = $$v
                                      },
                                      expression: "isCCTVVerified",
                                    },
                                  })
                                : _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.unverifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.CCTV
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isCCTVVerified,
                                      callback: function ($$v) {
                                        _vm.isCCTVVerified = $$v
                                      },
                                      expression: "isCCTVVerified",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("CCTVControlLog", {
                            attrs: {
                              "job-id": _vm.jobId,
                              "engineer-visit-detail-id": _vm.itemId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.getWaterMainsTestDetail
            ? _c(
                "v-expansion-panel-content",
                { staticClass: "mb-3 elevation-1" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "align-center",
                      attrs: { slot: "header", wrap: "" },
                      slot: "header",
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          class:
                            _vm.getWaterMainsTestDetail.waterTests.length > 0
                              ? "xs8"
                              : "xs12",
                        },
                        [
                          _c(
                            "h3",
                            { attrs: { slot: "header" }, slot: "header" },
                            [_vm._v("Water mains test")]
                          ),
                        ]
                      ),
                      _vm.getWaterMainsTestDetail.waterTests.length > 0
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { xs4: "" },
                            },
                            [
                              _c("b", { staticClass: "green--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isWaterMainsVerified
                                        ? "Verified"
                                        : "Verify"
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.isWaterMainsVerified
                                ? _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.verifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.WaterMains
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isWaterMainsVerified,
                                      callback: function ($$v) {
                                        _vm.isWaterMainsVerified = $$v
                                      },
                                      expression: "isWaterMainsVerified",
                                    },
                                  })
                                : _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.unverifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.WaterMains
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isWaterMainsVerified,
                                      callback: function ($$v) {
                                        _vm.isWaterMainsVerified = $$v
                                      },
                                      expression: "isWaterMainsVerified",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c("WaterTest", {
                        attrs: {
                          "job-id": _vm.jobId,
                          "engineer-visit-detail-id": _vm.itemId,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.getDatumControlLogDetail.length > 0
            ? _c(
                "v-expansion-panel-content",
                { staticClass: "mb-3 elevation-1" },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "align-center",
                      attrs: { slot: "header", wrap: "" },
                      slot: "header",
                    },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c(
                          "h3",
                          { attrs: { slot: "header" }, slot: "header" },
                          [_vm._v("Datums")]
                        ),
                      ]),
                      _vm.isAnyDatumRunLogAdded
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { xs4: "" },
                            },
                            [
                              _c("b", { staticClass: "green--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isDatumVerified
                                        ? "Verified"
                                        : "Verify"
                                    ) +
                                    " "
                                ),
                              ]),
                              !_vm.isDatumVerified
                                ? _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.verifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.Datum
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isDatumVerified,
                                      callback: function ($$v) {
                                        _vm.isDatumVerified = $$v
                                      },
                                      expression: "isDatumVerified",
                                    },
                                  })
                                : _c("v-checkbox", {
                                    attrs: { color: "green" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.unverifySiteData(
                                          _vm.siProductType[
                                            _vm.siProductType.Datum
                                          ]
                                        )
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                      },
                                    },
                                    model: {
                                      value: _vm.isDatumVerified,
                                      callback: function ($$v) {
                                        _vm.isDatumVerified = $$v
                                      },
                                      expression: "isDatumVerified",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("Datums", {
                            attrs: {
                              "job-id": _vm.jobId,
                              "engineer-visit-detail-id": _vm.itemId,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }