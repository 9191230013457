var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "mb-3" },
    [
      _c(
        "v-card-title",
        { staticClass: "pb-0" },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs11: "", "mb-2": "" } }, [
                _c("h3", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.storedRecord.questionText) +
                      " " +
                      _vm._s(
                        _vm.storedRecord.unit
                          ? "(" + _vm.storedRecord.unit + ")"
                          : ""
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "v-flex",
                { attrs: { xs1: "", "text-xs-right": "" } },
                [
                  _vm.syncing
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "processing-icon",
                          attrs: { medium: "" },
                        },
                        [_vm._v("sync")]
                      )
                    : _vm.storedRecord.validationStatus === "invalid"
                    ? _c(
                        "v-icon",
                        { attrs: { medium: "", dark: "", color: "error" } },
                        [_vm._v("warning")]
                      )
                    : _vm.storedRecord.validationStatus === "missing"
                    ? _c(
                        "v-icon",
                        { attrs: { medium: "", dark: "", color: "orange" } },
                        [_vm._v("info")]
                      )
                    : _vm.storedRecord.validationStatus === "manual"
                    ? _c("v-icon", { attrs: { medium: "" } }, [_vm._v("help")])
                    : _vm.storedRecord.isValid
                    ? _c(
                        "v-icon",
                        { attrs: { medium: "", dark: "", color: "success" } },
                        [_vm._v("check_circle")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.storedRecord.note
                ? _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "pre",
                      {
                        staticClass:
                          "agent-note body-2 black--text text--lighten-1 pa-2 mt-1",
                      },
                      [_vm._v(_vm._s(_vm.storedRecord.note))]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pt-0" },
        [
          _vm.storedRecord.answerType === _vm.answerTypeText
            ? _c("v-textarea", {
                ref: "answer" + _vm.storedRecord.orderBy,
                staticClass: "answer",
                attrs: {
                  placeholder: "enter a value",
                  loading: _vm.saving,
                  rows: "4",
                  "full-width": true,
                  disabled:
                    _vm.isJobCompleted || _vm.getAcceptRejectQuestionCriteria,
                },
                on: {
                  focus: _vm.editStarted,
                  blur: function ($event) {
                    return _vm.editComplete(false)
                  },
                },
                model: {
                  value: _vm.localAnswer,
                  callback: function ($$v) {
                    _vm.localAnswer = $$v
                  },
                  expression: "localAnswer",
                },
              })
            : _vm.storedRecord.answerType === _vm.answerTypeDropdown
            ? _c("v-select", {
                ref: "answer" + _vm.storedRecord.orderBy,
                staticClass: "answer",
                attrs: {
                  items: _vm.storedRecord.dropdownOptions,
                  placeholder: "select a value",
                  loading: _vm.saving,
                  disabled:
                    _vm.isJobCompleted || _vm.getAcceptRejectQuestionCriteria,
                },
                on: {
                  focus: _vm.editStarted,
                  input: function ($event) {
                    return _vm.editComplete(false)
                  },
                },
                model: {
                  value: _vm.localAnswer,
                  callback: function ($$v) {
                    _vm.localAnswer = $$v
                  },
                  expression: "localAnswer",
                },
              })
            : _vm.storedRecord.answerType === _vm.answerTypeNumber
            ? _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "decimal",
                    expression: "'decimal'",
                  },
                ],
                ref: "answer" + _vm.storedRecord.orderBy,
                staticClass: "answer",
                attrs: {
                  "error-messages": _vm.validationMessage(_vm.storedRecord.id),
                  "data-vv-name": _vm.storedRecord.id,
                  placeholder: "enter a value",
                  loading: _vm.saving,
                  disabled:
                    _vm.isJobCompleted || _vm.getAcceptRejectQuestionCriteria,
                },
                on: {
                  focus: _vm.editStarted,
                  blur: function ($event) {
                    return _vm.editComplete(false)
                  },
                },
                model: {
                  value: _vm.localAnswer,
                  callback: function ($$v) {
                    _vm.localAnswer = $$v
                  },
                  expression: "localAnswer",
                },
              })
            : _vm.storedRecord.answerType === _vm.answerTypeCheckbox
            ? _c(
                "v-radio-group",
                {
                  ref: "answer" + _vm.storedRecord.orderBy,
                  staticClass: "answer",
                  attrs: { loading: _vm.saving },
                  on: {
                    focus: _vm.editStarted,
                    change: function ($event) {
                      return _vm.editComplete(false)
                    },
                  },
                  model: {
                    value: _vm.localAnswer,
                    callback: function ($$v) {
                      _vm.localAnswer = $$v
                    },
                    expression: "localAnswer",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Yes",
                      color: "primary",
                      value: "Yes",
                      disabled:
                        _vm.isJobCompleted ||
                        _vm.getAcceptRejectQuestionCriteria,
                    },
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: "No",
                      color: "primary",
                      value: "No",
                      disabled:
                        _vm.isJobCompleted ||
                        _vm.getAcceptRejectQuestionCriteria,
                    },
                  }),
                ],
                1
              )
            : _c("div", [
                _vm._v(
                  "Unsupported answer type, please contact support for assistance."
                ),
              ]),
          !_vm.storedRecord.isValid
            ? _c(
                "div",
                {
                  class:
                    _vm.storedRecord.validationStatus === "invalid"
                      ? "answer-invalid"
                      : "answer-missing",
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.storedRecord.validationErrorMessage) + " "
                  ),
                ]
              )
            : _vm._e(),
          _vm.storedRecord.forCommentOperatorId
            ? _c(
                "div",
                [
                  _vm.storedRecord.validationForAdditionalInfoStatus === "valid"
                    ? [
                        _c("v-textarea", {
                          ref: "comment" + _vm.storedRecord.orderBy,
                          staticClass: "answer",
                          attrs: {
                            loading: _vm.savingComment,
                            label: !_vm.storedRecord.commentLabel
                              ? "Additional Information"
                              : _vm.storedRecord.commentLabel,
                            name: "AdditionalInfo",
                            disabled:
                              _vm.isJobCompleted ||
                              _vm.getAcceptRejectQuestionCriteria,
                          },
                          on: {
                            focus: _vm.editStarted,
                            blur: function ($event) {
                              return _vm.editComplete(true)
                            },
                          },
                          model: {
                            value: _vm.localComment,
                            callback: function ($$v) {
                              _vm.localComment = $$v
                            },
                            expression: "localComment",
                          },
                        }),
                        _vm.storedRecord.mandatory &&
                        (_vm.localComment === "" || _vm.localComment === null)
                          ? _c("div", { staticClass: "answer-missing" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    "comment to this question is mandatory"
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }