var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "log-details" },
    [
      _c(
        "v-card-title",
        { staticClass: "py-0" },
        [
          _c("h3", [
            _vm._v(
              _vm._s(_vm.isRequestStream ? "Request Stream" : "Job Audit Log") +
                " - " +
                _vm._s(_vm.jobId)
            ),
          ]),
          _c("v-spacer"),
          !_vm.isRequestStream
            ? _c("div", [
                _c("span", { staticClass: "grey--text swich-label mr-2" }, [
                  _c("b", [_vm._v("Success")]),
                ]),
                _c(
                  "span",
                  [
                    _c("v-switch", {
                      attrs: {
                        color: "primary",
                        small: "",
                        "hide-details": "",
                      },
                      model: {
                        value: _vm.isSuccess,
                        callback: function ($$v) {
                          _vm.isSuccess = $$v
                        },
                        expression: "isSuccess",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: { icon: "" },
              on: { click: _vm.closeModal },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "px-0 pt-2 scroll-content-dialog" },
        [
          _c(
            "v-layout",
            { staticClass: "scrollvb-content", attrs: { "mt-0": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-progress-circular", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.jobRequests.length === 0 &&
                              _vm.requestedJob == null,
                            expression:
                              "jobRequests.length === 0 && requestedJob == null",
                          },
                        ],
                        style: _vm.getLoaderStyle(70),
                        attrs: {
                          width: 2,
                          size: 50,
                          indeterminate: "",
                          color: "primary",
                        },
                      }),
                      _c("textarea", {
                        staticClass: "clipboard",
                        attrs: {
                          id: "clipboardText",
                          name: "cbTxt",
                          rows: "4",
                          cols: "4",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        {
                          staticClass: "container d-flex px-3 py-1 fluid",
                          attrs: { fluid: "" },
                        },
                        [
                          _vm.isRequestStream
                            ? _c("v-checkbox", {
                                staticClass: "align-center justify-end",
                                attrs: { "hide-details": "" },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleSelectAll()
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c("v-label", [_vm._v("Select All")]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1172569318
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.jobRequests.length > 0
                        ? _vm._l(_vm.jobRequests, function (request, index) {
                            return _c(
                              "v-flex",
                              {
                                key: index,
                                class:
                                  _vm.detailshow && _vm.activeLogIndex === index
                                    ? " show-details grey lighten-3 log-details"
                                    : "log-details",
                                attrs: { xs12: "", "px-3": "", "py-2": "" },
                              },
                              [
                                _vm.isRequestStream
                                  ? _c("v-checkbox", {
                                      staticClass: "log-status",
                                      attrs: {
                                        value: `${request.requestType}-${request.id}`,
                                      },
                                      model: {
                                        value: _vm.requestsToCopy,
                                        callback: function ($$v) {
                                          _vm.requestsToCopy = $$v
                                        },
                                        expression: "requestsToCopy",
                                      },
                                    })
                                  : _c("span", { staticClass: "log-status" }, [
                                      _c(
                                        "b",
                                        {
                                          class: _vm.requestProcessStatus(
                                            request.processStatus
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(request.processStatus) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                _c("div", { staticClass: "show-log-details" }, [
                                  _c(
                                    "div",
                                    { staticClass: "show-inner-content" },
                                    [
                                      _vm.isExisitingComponent(
                                        request.requestType
                                      )
                                        ? _c(
                                            _vm.isExisitingComponent(
                                              request.requestType
                                            ),
                                            _vm._b(
                                              {
                                                tag: "component",
                                                attrs: {
                                                  id: `${request.requestType}-${request.id}`,
                                                },
                                              },
                                              "component",
                                              {
                                                "request-body":
                                                  request.requestBody,
                                                "request-type":
                                                  request.requestType,
                                              },
                                              false
                                            )
                                          )
                                        : _c("DefaultRequestLog", {
                                            attrs: {
                                              id: `${request.requestType}-${request.id}`,
                                              request: request,
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "v-layout",
                                  { attrs: { "mt-3": "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs8: "", "mb-1": "" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-xs-right mt-2 person-entry grey--text text--darken-1",
                                            attrs: {
                                              id: `processedByData-${request.requestType}-${request.id}`,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Process " +
                                                _vm._s(
                                                  _vm.getUserName(request)
                                                ) +
                                                " at " +
                                                _vm._s(
                                                  _vm.getProcessedAt(request)
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      {
                                        attrs: { xs4: "", "text-xs-right": "" },
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "pa-0 ma-0",
                                            attrs: {
                                              small: "",
                                              flat: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showdetails(index)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.detailshow &&
                                                  _vm.activeLogIndex === index
                                                    ? "hide"
                                                    : "show",
                                              },
                                              [
                                                _vm._v(" More Details "),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "keyboard_arrow_down"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  _vm.detailshow &&
                                                  _vm.activeLogIndex === index
                                                    ? "show"
                                                    : "hide",
                                              },
                                              [
                                                _vm._v(" Less Details "),
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("keyboard_arrow_up")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                request.length != index
                                  ? _c("v-divider", { staticClass: "0" })
                                  : _vm._e(),
                              ],
                              1
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isRequestStream && _vm.jobRequests.length > 0
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.requestsToCopy.length,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.copyToClipboard()
                    },
                  },
                },
                [_vm._v("Copy To Clipboard")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { color: "green", timeout: 1500, top: "", center: "" },
          model: {
            value: _vm.isCopiedToClipboard,
            callback: function ($$v) {
              _vm.isCopiedToClipboard = $$v
            },
            expression: "isCopiedToClipboard",
          },
        },
        [_c("span", [_vm._v("Copied To Clipboard")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }