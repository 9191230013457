var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _vm._v(_vm._s(_vm.JobTypeLabel("emergency detail"))),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeEmergencyDetailDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-3 scroll-content-dialog" },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items: _vm.emergencies,
                          label: "Select " + _vm.JobTypeLabel("emergency"),
                          disabled: _vm.localEmergencyId ? true : false,
                          "item-text": "description",
                          "item-value": "emergencyId",
                          required: "",
                          "data-vv-scope": "frmEmergencyDetail",
                          "data-vv-name": _vm.JobTypeLabel("emergency"),
                          "error-messages": _vm.errors.collect(
                            _vm.JobTypeLabel("emergency")
                          ),
                        },
                        model: {
                          value: _vm.localEmergencyId,
                          callback: function ($$v) {
                            _vm.localEmergencyId = $$v
                          },
                          expression: "localEmergencyId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required trade-items",
                        attrs: {
                          items: _vm.localTradeList,
                          "item-value": "tradeId",
                          "item-text": "description",
                          label: "Trade",
                          required: "",
                          "data-vv-scope": "frmEmergencyDetail",
                          "data-vv-name": "Trade",
                          "error-messages": _vm.errors.collect("Trade"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-tile",
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", {
                                          class: _vm.getClassName(
                                            data.item.tradeId
                                          ),
                                          domProps: {
                                            innerHTML: _vm._s(
                                              data.item.description
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.tradeId,
                          callback: function ($$v) {
                            _vm.tradeId = $$v
                          },
                          expression: "tradeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:50",
                            expression: "'required|max:50'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: _vm.JobTypeLabel("emergency detail"),
                          maxlength: "50",
                          "data-vv-scope": "frmEmergencyDetail",
                          "data-vv-name": _vm.JobTypeLabel("emergency detail"),
                          "error-messages": _vm.errors.collect(
                            _vm.JobTypeLabel("emergency detail")
                          ),
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "trade-items",
                        attrs: {
                          items: _vm.productCategories,
                          "item-value": "id",
                          "item-text": "description",
                          label: "Integration Reference",
                          "data-vv-scope": "frmEmergencyDetail",
                          "data-vv-name": "Integration",
                          "error-messages": _vm.errors.collect("Integration"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-tile",
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c("v-list-tile-title", {
                                          class: _vm.getClassName(
                                            data.item.tradeId
                                          ),
                                          domProps: {
                                            innerHTML: _vm._s(
                                              data.item.description
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.integrationReference,
                          callback: function ($$v) {
                            _vm.integrationReference = $$v
                          },
                          expression: "integrationReference",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3 pb-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "flat" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeEmergencyDetailDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary",
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.saveEmergencyDetail.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }