var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "peril-repeat", attrs: { wrap: "" } },
    [
      _vm.value
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-2": "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required commodity",
                        attrs: {
                          items: _vm.commodityDetailsFiltered,
                          "item-value": "emergencyDetailId",
                          "item-text": "description",
                          label: "Commodity",
                          required: "",
                          "data-vv-name": "Commodity",
                          "error-messages": _vm.errors.collect("Commodity"),
                        },
                        model: {
                          value: _vm.value.commodityDetailId,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "commodityDetailId", $$v)
                          },
                          expression: "value.commodityDetailId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-2": "", "pl-2": "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required commodityDetail",
                        attrs: {
                          items: _vm.getCommodityWorkTypes,
                          "item-value": "description",
                          "item-text": "description",
                          label: "Commodity Detail",
                          required: "",
                          "data-vv-name": "Commodity Detail",
                          "error-messages":
                            _vm.errors.collect("Commodity Detail"),
                        },
                        model: {
                          value: _vm.value.commodityWorkType,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "commodityWorkType", $$v)
                          },
                          expression: "value.commodityWorkType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "numeric",
                            expression: "'numeric'",
                          },
                        ],
                        attrs: {
                          label: "Excess Value",
                          required: "",
                          name: "ExcessValue",
                          "data-vv-name": "Excess Value",
                          "error-messages": _vm.errors.collect("Excess Value"),
                        },
                        model: {
                          value: _vm.value.excessValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "excessValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "value.excessValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Notes" },
                        model: {
                          value: _vm.value.note,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "note",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "value.note",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }