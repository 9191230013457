var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard", attrs: { id: _vm.tableName } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ready,
              expression: "ready",
            },
          ],
          class: _vm.openJobView ? "hide-dashboard" : "show-dashboard",
          attrs: { wrap: "", "grid-view": "", "ctp-dashboard": "" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "px-0 pb-0 pt-0" },
            [
              _c("h3", [_vm._v("Customer To Pay")]),
              _c("v-spacer"),
              _c(
                "div",
                {
                  staticClass:
                    "grey filter-content pa-2 pl-3 mt-2 lighten-3 mb-2",
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "grid-list-lg pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "filter-col-content",
                          attrs: { wrap: "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { col1: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Search customer name",
                                  "prepend-inner-icon": "search",
                                },
                                model: {
                                  value: _vm.paymentFilter.customerName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "customerName",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.customerName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { col1: "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "insurerlist",
                                attrs: {
                                  items: _vm.insurers,
                                  "item-text": "name",
                                  "item-value": "id",
                                  label: "Select insurer",
                                  "single-line": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.getPolicyList($event)
                                  },
                                },
                                model: {
                                  value: _vm.paymentFilter.selectedInsurerId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "selectedInsurerId",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.selectedInsurerId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { col1: "" } },
                            [
                              _c("v-select", {
                                staticClass: "policiesList",
                                attrs: {
                                  items: _vm.polices,
                                  "item-text": "policyBasicDetail.name",
                                  "item-value": "policyBasicDetail.policyId",
                                  label: "Search policy",
                                  "single-line": "",
                                  loading: _vm.policySearchRunning,
                                },
                                model: {
                                  value: _vm.paymentFilter.paymentPolicy,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentPolicy",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.paymentPolicy",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { col2: "" } },
                            [
                              _c("v-select", {
                                staticClass: "paymentStatus",
                                attrs: {
                                  items: _vm.paymentStatusList,
                                  "item-value": "id",
                                  "item-text": "paymentStatus",
                                  label: "Payment Status",
                                },
                                model: {
                                  value: _vm.paymentFilter.paymentStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentStatus",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.paymentStatus",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { "date-content": "", col2: "" } },
                            [
                              _c("DateTimePicker", {
                                ref: "dateTimePickerFrom",
                                attrs: {
                                  "date-time": _vm.paymentFilter.paymentFrom,
                                  "is-static-location": false,
                                  "place-holder-text": "From",
                                  "is-current-time": false,
                                  "is-validation-required": false,
                                  "show-present-time-icon": false,
                                  "max-date": _vm.selectableDateTime(
                                    _vm.paymentFilter.paymentTo
                                  ),
                                  "hide-time-picker": true,
                                  "display-format": _vm.dateFormat(),
                                },
                                on: {
                                  "update:dateTime": function ($event) {
                                    return _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentFrom",
                                      $event
                                    )
                                  },
                                  "update:date-time": function ($event) {
                                    return _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentFrom",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.paymentFilter.paymentFrom,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentFrom",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.paymentFrom",
                                },
                              }),
                              _vm.paymentFilter.paymentFrom
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cleardate",
                                      on: {
                                        click: function ($event) {
                                          _vm.paymentFilter.paymentFrom = null
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("close")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { "date-content": "", col2: "" } },
                            [
                              _c("DateTimePicker", {
                                ref: "dateTimePickerTo",
                                attrs: {
                                  "date-time": _vm.paymentFilter.paymentTo,
                                  "is-static-location": false,
                                  "place-holder-text": "To",
                                  "is-current-time": false,
                                  "is-validation-required": false,
                                  "show-present-time-icon": false,
                                  "max-date": _vm.maxPaymentToDateTime,
                                  "min-date": _vm.selectableDateTime(
                                    _vm.paymentFilter.paymentFrom
                                  ),
                                  "hide-time-picker": true,
                                  "display-format": _vm.dateFormat(),
                                },
                                on: {
                                  "update:dateTime": function ($event) {
                                    return _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentTo",
                                      $event
                                    )
                                  },
                                  "update:date-time": function ($event) {
                                    return _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentTo",
                                      $event
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.paymentFilter.paymentTo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentTo",
                                      $$v
                                    )
                                  },
                                  expression: "paymentFilter.paymentTo",
                                },
                              }),
                              _vm.paymentFilter.paymentTo
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "cleardate",
                                      on: {
                                        click: function ($event) {
                                          _vm.paymentFilter.paymentTo = null
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("close")])],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { col2: "" } },
                            [
                              _c("v-switch", {
                                staticClass: "paymentByPayoutDate",
                                attrs: {
                                  name: "paymentByPayoutDate",
                                  color: "primary",
                                  label: _vm.paymentByPayoutDateText,
                                },
                                model: {
                                  value: _vm.paymentFilter.paymentByPayoutDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.paymentFilter,
                                      "paymentByPayoutDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "paymentFilter.paymentByPayoutDate",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                "text-xs-right": "",
                                "pt-3": "",
                                "mt-1": "",
                                "filter-action": "",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2 search-btn",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    loading: _vm.isLoading,
                                    disabled: _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.GetPaymentList(
                                        _vm.paymentFilter,
                                        false
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("filter_list")]),
                                  _vm._v("   Search "),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-2 clearFilters-btn",
                                  attrs: { color: "primary--text", small: "" },
                                  on: { click: _vm.clearFilters },
                                },
                                [
                                  _c("v-icon", [_vm._v("clear")]),
                                  _vm._v("   Clear "),
                                ],
                                1
                              ),
                              _vm.canViewFinance
                                ? _c(
                                    "v-btn",
                                    {
                                      ref: "export-button",
                                      staticClass: "mt-2",
                                      attrs: {
                                        slot: "activator",
                                        color: "primary",
                                        disabled: _vm.isLoading,
                                        small: "",
                                      },
                                      on: {
                                        click: _vm.openExportToExcelDialog,
                                      },
                                      slot: "activator",
                                    },
                                    [
                                      _c("v-icon", { staticClass: "md-icon" }, [
                                        _vm._v("get_app"),
                                      ]),
                                      _vm._v("   Export "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "elevation-1", attrs: { xs12: "", "mt-2": "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.paymentList,
                  pagination: _vm.pagination,
                  loading: _vm.isLoading,
                  "total-items": _vm.totalPaymentCount,
                  "rows-per-page-items": _vm.rowsPerPageItems,
                  "item-key": "chargeId",
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            staticClass: "lighten-5",
                            class: props.item.colourCode,
                            attrs: { active: props.selected },
                            on: {
                              click: function ($event) {
                                props.expanded = !props.expanded
                              },
                            },
                          },
                          [
                            _c("td", [
                              _c(
                                "a",
                                {
                                  staticClass: "primary--text link-jobId",
                                  attrs: {
                                    href: "Javascript:void(0)",
                                    name: "openJobDetailButton",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.redirectToJob(props.item.jobId)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(props.item.jobId) + " ")]
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.cardHolderName)),
                            ]),
                            _c("td", [_vm._v(_vm._s(props.item.postcode))]),
                            _c("td", [_vm._v(_vm._s(props.item.cardBrand))]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.statementDescription)),
                            ]),
                            _c("td", [_vm._v(_vm._s(props.item.policyName))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.getFormattedDateTime(props.item.createdAt)
                                )
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(props.item.paymentStatus)),
                            ]),
                            _c(
                              "td",
                              {
                                class:
                                  props.item.net > 0
                                    ? "text-xs-right"
                                    : "text-xs-center",
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.item.net > 0
                                          ? _vm.getFormatedCurrency(
                                              props.item.net
                                            )
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "td",
                              {
                                class:
                                  props.item.fee > 0
                                    ? "text-xs-right"
                                    : "text-xs-center",
                              },
                              [
                                _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.item.fee > 0
                                          ? _vm.getFormatedCurrency(
                                              props.item.fee
                                            )
                                          : "-"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFormatedCurrency(props.item.amount)
                                  )
                                ),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.item.amountRefunded > 0
                                        ? _vm.getFormatedCurrency(
                                            props.item.amountRefunded * -1
                                          )
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _c("b", { staticClass: "secondary--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getFormatedCurrency(
                                        props.item.amount -
                                          (props.item.amountRefunded
                                            ? props.item.amountRefunded
                                            : 0)
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c(
                              "td",
                              {
                                class: !props.item.payoutCreatedAt
                                  ? "text-xs-center"
                                  : "payoutat-col",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.item.payoutCreatedAt
                                        ? _vm.getFormattedDateTime(
                                            props.item.payoutCreatedAt
                                          )
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("td", { staticClass: "text-xs-center" }, [
                              props.item.chargeId
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "payment-receipt",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.redirectToPaymentReceipt(
                                            props.item.chargeId
                                          )
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("email")])],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "expand",
                    fn: function (props) {
                      return [
                        _c(
                          "v-card",
                          { attrs: { flat: "" } },
                          [
                            props.item.ctpRefundHistory &&
                            props.item.ctpRefundHistory.length > 0
                              ? _c(
                                  "v-card-text",
                                  { staticClass: "refund-detail-table" },
                                  [
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1",
                                        attrs: {
                                          headers: _vm.refundTableHeaders,
                                          items: props.item.ctpRefundHistory,
                                          "hide-actions": "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "items",
                                              fn: function (props) {
                                                return [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getFormattedDateTime(
                                                          props.item.createdAt
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormatedCurrency(
                                                            props.item
                                                              .amountRefunded *
                                                              -1
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(props.item.reason)
                                                    ),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.description
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                  ],
                                  2
                                )
                              : _c(
                                  "v-card-text",
                                  { staticClass: "refund-detail-table" },
                                  [_vm._v("No Refund(s) Found")]
                                ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.ready,
              expression: "!ready",
            },
          ],
        },
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _vm.exportToExcelDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.exportToExcelDialog,
                callback: function ($$v) {
                  _vm.exportToExcelDialog = $$v
                },
                expression: "exportToExcelDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Export To Excel")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 pt-0 scroll-content-dialog" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0", attrs: { "grid-list-xl": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Export Name" },
                                    model: {
                                      value: _vm.exportName,
                                      callback: function ($$v) {
                                        _vm.exportName =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "exportName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "export-excel",
                        {
                          staticClass: "btn ml-2",
                          attrs: {
                            "export-fields": _vm.exportToExcelHeaderFields,
                            fetch: _vm.exportCTP,
                            worksheet: "CTP Details",
                            "before-generate": _vm.startExportGeneration,
                            "before-finish": _vm.stopExportGeneration,
                            type: "xls",
                            name: _vm.exportName
                              ? _vm.exportName + ".xls"
                              : "ctpList.xls",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: "ExportToExcel",
                              attrs: {
                                color: "primary",
                                loading: _vm.exportToExcelLoader,
                                disabled: _vm.exportToExcelLoader,
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "md-icon" }, [
                                _vm._v("get_app"),
                              ]),
                              _vm._v("   Export "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
      _c("PartialJobView", {
        ref: "refPartialJobView",
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }