var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "reopen-job",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.ShowCardPreview()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "question-title-desc mt-3" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-0",
              attrs: { color: "green white--text", large: "", ripple: true },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("work")]),
              _vm._v("    Reopen Job "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }