var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobOffer
    ? _c(
        "div",
        [
          _vm.jobOfferExpired
            ? _c("v-layout", [
                _c("h3", [_vm._v("Job Offer is no longer available")]),
              ])
            : _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "px-2": "", "py-1": "" } },
                    [
                      _c("div", { staticClass: "mb-2" }, [
                        _c("label", { staticClass: "mr-1 font-weight-bold" }, [
                          _vm._v("Job:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.jobOffer.contractorAppointedModel.jobId)
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "mb-2" }, [
                        _c("label", { staticClass: "mr-1 font-weight-bold" }, [
                          _vm._v("Post Code:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.jobOffer.contractorAppointedModel.postcode
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "mb-2" }, [
                        _c("label", { staticClass: "mr-1 font-weight-bold" }, [
                          _vm._v("Client Name:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.jobOffer.contractorAppointedModel.customerName
                            )
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "mb-2" }, [
                        _c("label", { staticClass: "mr-1 font-weight-bold" }, [
                          _vm._v("Emergency"),
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.jobOffer.emergencyTypeDescription)),
                        ]),
                        _vm.jobOffer.emergencyDetailDescription
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.jobOffer.emergencyDetailDescription
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "mb-2" }, [
                        _c("label", { staticClass: "mr-1 font-weight-bold" }, [
                          _vm._v("Address:"),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.jobOffer.contractorAppointedModel.address
                            )
                          ),
                        ]),
                      ]),
                      _vm.jobOffer.engineerSiteSla
                        ? _c("div", [
                            _c(
                              "label",
                              { staticClass: "mr-1 font-weight-bold" },
                              [_vm._v("SLA cut off time :")]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatDate(_vm.jobOffer.engineerSiteSla)
                                )
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
          _c(
            "v-layout",
            [
              _c(
                "v-flex",
                { staticClass: "mr-3" },
                [
                  _c("DateTimePicker", {
                    ref: "etaFrom",
                    attrs: {
                      "date-time": _vm.etaFrom,
                      "is-static-location": false,
                      "place-holder-text": "ETA From",
                      "is-validation-required": true,
                      "allowed-minutes-step": true,
                    },
                    on: {
                      "update:dateTime": function ($event) {
                        _vm.etaFrom = $event
                      },
                      "update:date-time": function ($event) {
                        _vm.etaFrom = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c("DateTimePicker", {
                    ref: "etaTo",
                    attrs: {
                      "date-time": _vm.etaTo,
                      "is-static-location": false,
                      "place-holder-text": "ETA From",
                      "is-validation-required": true,
                      "allowed-minutes-step": true,
                    },
                    on: {
                      "update:dateTime": function ($event) {
                        _vm.etaTo = $event
                      },
                      "update:date-time": function ($event) {
                        _vm.etaTo = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-layout", [
            _vm.etaErrorMessage
              ? _c("h4", { staticClass: "warning--text" }, [
                  _vm._v(_vm._s(_vm.etaErrorMessage)),
                ])
              : _vm._e(),
          ]),
          _c(
            "v-flex",
            [
              _c("CustomerAvailabilityDisplay", {
                attrs: { availability: _vm.jobOffer.customerAvailability },
              }),
            ],
            1
          ),
          _c("v-divider", { staticClass: "mt-2" }),
          _c(
            "v-layout",
            { staticClass: "justify-end" },
            [
              _c(
                "v-flex",
                { staticClass: "d-flex justify-end", attrs: { xs4: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "red white--text",
                      on: {
                        click: function ($event) {
                          _vm.showRejectJobConfirmationDialog = true
                        },
                      },
                    },
                    [_vm._v(" Reject Job ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "green white--text",
                      on: { click: _vm.acceptJob },
                    },
                    [_vm._v(" Accept Job ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "400", persistent: "" },
              model: {
                value: _vm.showRejectJobConfirmationDialog,
                callback: function ($$v) {
                  _vm.showRejectJobConfirmationDialog = $$v
                },
                expression: "showRejectJobConfirmationDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [_c("v-toolbar-title", [_vm._v("Reject Job")])],
                    1
                  ),
                  _c("v-card-text", [
                    _vm._v("Are you sure you want to reject this job?"),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "blue" },
                          on: {
                            click: function ($event) {
                              _vm.showRejectJobConfirmationDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "white--text",
                          attrs: { color: "red" },
                          on: { click: _vm.setContractorDecisionToRejectJob },
                        },
                        [_vm._v("Reject")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm.isJobOfferDetailsLoading && !_vm.jobOffer
    ? _c("v-progress-circular", {
        staticClass: "loading-spinner",
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      })
    : _c("div", [_c("h1", [_vm._v("Failed to load job details")])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }