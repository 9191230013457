var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [
        _vm._v(
          _vm._s(_vm.item.id === null ? "Added Complaint" : "Updated Complaint")
        ),
      ]),
    ]),
    _vm.item.severity !== ""
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Severity:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.severity))]),
        ])
      : _vm._e(),
    _vm.item.responsiblePerson
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", { staticClass: "MEDIUM" }, [
            _vm._v("Responsible Person:"),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.username))]),
        ])
      : _vm._e(),
    _vm.item.complaintTypeDescription
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Complaint Type:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.complaintTypeDescription))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Received At:")]),
      _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.item.receivedDate
                ? _vm.getFormattedDateTime(_vm.item.receivedDate)
                : _vm.item.createdAt
                ? _vm.getFormattedDateTime(_vm.item.createdAt)
                : "-"
            ) +
            " "
        ),
      ]),
    ]),
    _vm.item.reasonForDelay
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Reason For Delay:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.reasonForDelay))]),
        ])
      : _vm._e(),
    _vm.item.dateOfResolution
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Date Of Resolution:")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.item.dateOfResolution
                    ? _vm.getFormattedDateTime(_vm.item.dateOfResolution)
                    : ""
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.complaintSummary
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Complaint Summary:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.complaintSummary))]),
        ])
      : _vm._e(),
    _vm.item.agreedAction
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Agreed Action:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.agreedAction))]),
        ])
      : _vm._e(),
    _vm.item.initialResponseLetterDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Initial Response Letter Due:")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.getFormattedDateTime(_vm.item.initialResponseLetterDate)
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.initialResponseLetterSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Initial Response Letter Sent:")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.getFormattedDateTime(_vm.item.initialResponseLetterSent)
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.fourWeekLetterDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Four Week Letter Due:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.fourWeekLetterDate))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.fourWeekLetterSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Four Week Letter Sent:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.fourWeekLetterSent))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.eightWeekLetterDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Eight Week Letter Due:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.eightWeekLetterDate))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.eightWeekLetterSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Eight Week Letter Sent:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.eightWeekLetterSent))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.summaryResolutionCommunicationDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Summary Resolution Communication Due:")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormattedDateTime(
                    _vm.item.summaryResolutionCommunicationDate
                  )
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.summaryResolutionCommunicationSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Summary Resolution Communication Sent:")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.getFormattedDateTime(
                    _vm.item.summaryResolutionCommunicationSent
                  )
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.finalResponseLetterDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Final Response Letter Due:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.finalResponseLetterDate))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.finalResponseLetterSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Final Response Letter Sent:")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.getFormattedDateTime(_vm.item.finalResponseLetterSent))
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.resolutionSummary
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Resolution Summary:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.resolutionSummary))]),
        ])
      : _vm._e(),
    _vm.selectedResolutionCodes.length > 0
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Resolution Code:")]),
          _c("span", [_vm._v(_vm._s(_vm.selectedResolutionCodes.join(", ")))]),
        ])
      : _vm._e(),
    _vm.item.rootCause
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Root Cause:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.rootCauseDescription))]),
        ])
      : _vm._e(),
    _vm.item.loggedByUserName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Logged By UserName:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.loggedByUserName))]),
        ])
      : _vm._e(),
    _vm.item.escalationRequired
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Is an escalation to client required?:")]),
          _c("span", [_vm._v(_vm._s(_vm.item.escalationRequired))]),
        ])
      : _vm._e(),
    _vm.item.concernsRaisedDescription
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Concerns Raised:")]),
          _c("span", [
            _vm._v(_vm._s(_vm.item.concernsRaisedDescription.join(", "))),
          ]),
        ])
      : _vm._e(),
    _vm.item.isNoticeSent
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Has an ‘On Notice’ been sent:")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.item.isNoticeSent) +
                " " +
                _vm._s(
                  _vm.item.noticeSentAt
                    ? " (" +
                        _vm.getFormattedDateTime(_vm.item.noticeSentAt) +
                        ")"
                    : ""
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Compensation By Cheque:")]),
      _c("span", [
        _vm._v(_vm._s(_vm.item.isCompensationByCheque ? "Yes" : "No")),
      ]),
    ]),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Is Complaint Resolved:")]),
      _c("span", [_vm._v(_vm._s(_vm.item.isResolved ? "Yes" : "No"))]),
    ]),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Is Complaint Justified:")]),
      _c("span", [_vm._v(_vm._s(_vm.item.justified))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }