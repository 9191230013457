<template>
  <v-dialog v-model="showModal" width="800" persistent>
    <v-card class="wrapper">
      <v-toolbar card dark color="primary">
        <v-toolbar-title>Job Offer</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeJobOfferDialog')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <JobOffer v-if="!jobOfferDecision.isAccepted && !jobOfferDecision.isRejected" />
        <AssignEngineer v-if="jobOfferDecision.isAccepted" @engineerAssigned="$emit('closeJobOfferDialog')" />
        <JobRejectionReason v-else-if="jobOfferDecision.isRejected" @jobRejected="$emit('closeJobOfferDialog')" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import AssignEngineer from './AssignEngineer.vue'
import JobRejectionReason from './JobRejectionReason.vue'
import Vue from 'vue'
import JobOffer from './JobOffer.vue'
import { useDeploymentStore } from '@/pinia/deployment'
import { storeToRefs } from 'pinia'

@Component({
  components: {
    AssignEngineer,
    JobOffer,
    JobRejectionReason,
  },
})
export default class ContractorJobOfferModal extends Vue {
  public showModal = true

  public get jobOfferDecision(): { isAccepted: boolean; isRejected: boolean } {
    const { isJobOfferAccepted, isJobOfferRejected } = storeToRefs(useDeploymentStore())
    return {
      isAccepted: isJobOfferAccepted.value,
      isRejected: isJobOfferRejected.value,
    }
  }

  private destroyed() {
    // Clean up data in pinia store
    useDeploymentStore().$reset()
  }
}
</script>

<style scoped>
.wrapper {
  min-height: 400px;
}
</style>
