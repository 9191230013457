var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: {
        color: _vm.snackbar.color,
        timeout: _vm.snackbar.timeout,
        "auto-height": "",
      },
      model: {
        value: _vm.showSnackbar,
        callback: function ($$v) {
          _vm.showSnackbar = $$v
        },
        expression: "showSnackbar",
      },
    },
    [
      _vm.snackbar.icon
        ? _c("v-icon", { attrs: { left: "" } }, [
            _vm._v(_vm._s(_vm.snackbar.icon)),
          ])
        : _vm._e(),
      _c("div", [_vm._v(" " + _vm._s(_vm.snackbar.message) + " ")]),
      _vm.snackbar.showClose
        ? _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.closeSnackbar()
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }