var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.jobItem
        ? _c("v-card", { staticClass: "mapsidebar-content" }, [
            _c("ul", { staticClass: "contact-details" }, [
              _c(
                "li",
                [
                  _c("v-icon", { attrs: { color: "black" } }, [
                    _vm._v("my_location"),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.jobItem.clientForename +
                          " " +
                          _vm.jobItem.clientLastName
                      ) +
                      " "
                  ),
                ],
                1
              ),
              _c(
                "li",
                { staticClass: "prolicy-name-customer" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "secondary--text",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          return _vm.openPolicySummaryDialog(
                            _vm.jobItem.policyScheduleId
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          class: _vm.jobItem.additionalCostsAuthorised
                            ? "green--text"
                            : "secondary--text",
                        },
                        [_vm._v("assignment")]
                      ),
                      _c(
                        "u",
                        {
                          class: _vm.jobItem.additionalCostsAuthorised
                            ? "green--text"
                            : "secondary--text",
                        },
                        [_vm._v(" " + _vm._s(_vm.jobItem.policyScheme) + " ")]
                      ),
                    ],
                    1
                  ),
                  _vm.showUpdatePolicyScheduleButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass:
                            "ma-0 name-edit lighten-2 underline edit-policy-btn",
                          attrs: { icon: "" },
                          on: { click: _vm.openPolicyReAssignDialogue },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v("edit"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("li", [
                _c("i", { staticClass: "svg-wrap" }, [
                  _c("img", { attrs: { src: "/img/pound.svg" } }),
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.jobItem.insuranceValue) +
                    " " +
                    _vm._s(
                      _vm.jobItem.jobType === "US"
                        ? "Delegated Authority"
                        : "Limit"
                    ) +
                    " "
                ),
              ]),
              _vm.jobItem.jobType === "HE" && _vm.jobItem.excessAmount
                ? _c("li", [
                    _c("i", { staticClass: "svg-wrap excess-amount" }, [
                      _c("img", { attrs: { src: "/img/pound.svg" } }),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.jobItem.excessAmount) +
                        " " +
                        _vm._s("Excess") +
                        " "
                    ),
                  ])
                : _vm._e(),
              _c("li", { staticClass: "chat-box-wrap" }, [
                _vm.jobItem.mobilePhone ||
                _vm.jobItem.nomineeMobilePhone ||
                _vm.jobItem.email ||
                _vm.jobItem.nomineeEmail
                  ? _c(
                      "span",
                      [_c("CustomerCall", { attrs: { "job-id": _vm.jobId } })],
                      1
                    )
                  : _vm._e(),
                _vm.getAbilityToAccessCustomerToPay && !_vm.isHEJobCompleted
                  ? _c(
                      "span",
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            overlap: "",
                                            color:
                                              "grey small-size-badge darken-2",
                                          },
                                        },
                                        [
                                          _vm.jobItem.customerToPay.length > 0
                                            ? _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    slot: "badge",
                                                    small: "",
                                                  },
                                                  slot: "badge",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.jobItem
                                                          .customerToPay.length
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-avatar",
                                            _vm._g(
                                              {
                                                staticClass: "btn-payment",
                                                attrs: {
                                                  color: "white",
                                                  size: 35,
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: { color: "secondary" },
                                                  on: {
                                                    click: _vm.CustomerToPay,
                                                  },
                                                },
                                                [_vm._v("payment")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3904548517
                            ),
                          },
                          [_c("span", [_vm._v("Payment")])]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "span",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-avatar",
                                    _vm._g(
                                      { attrs: { color: "white", size: 35 } },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { color: "secondary" },
                                          on: { click: _vm.openAddNote },
                                        },
                                        [_vm._v("notes")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1321452815
                        ),
                      },
                      [_c("span", [_vm._v("Notes")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        overlap: "",
                                        color: "grey small-size-badge darken-2",
                                      },
                                    },
                                    [
                                      _vm.jobItem.complaint.length > 0
                                        ? _c(
                                            "span",
                                            {
                                              attrs: {
                                                slot: "badge",
                                                small: "",
                                              },
                                              slot: "badge",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.jobItem.complaint.length
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-avatar",
                                        _vm._g(
                                          {
                                            attrs: { color: "white", size: 35 },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: { color: "secondary" },
                                              on: {
                                                click: _vm.complaintRaised,
                                              },
                                            },
                                            [_vm._v("announcement")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          932703905
                        ),
                      },
                      [_c("span", [_vm._v("Complaint")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  [_c("PreviousJobs", { attrs: { "job-id": _vm.jobId } })],
                  1
                ),
                _c(
                  "span",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-avatar",
                                    _vm._g(
                                      {
                                        attrs: { color: "white", size: 35 },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openAuditLogModal()
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "secondary" } },
                                        [_vm._v("description")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3636506719
                        ),
                      },
                      [_c("span", [_vm._v("Audit Log")])]
                    ),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "subMenuContent" },
                  [
                    _c(
                      "v-speed-dial",
                      {
                        staticClass: "more-btn",
                        attrs: { absolute: true, "open-on-hover": true },
                        model: {
                          value: _vm.subMenu,
                          callback: function ($$v) {
                            _vm.subMenu = $$v
                          },
                          expression: "subMenu",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "activator",
                              small: "",
                              color: "white",
                              dark: "",
                              fab: "",
                            },
                            slot: "activator",
                            model: {
                              value: _vm.subMenu,
                              callback: function ($$v) {
                                _vm.subMenu = $$v
                              },
                              expression: "subMenu",
                            },
                          },
                          [
                            _c("v-icon", { staticClass: "secondary--text" }, [
                              _vm._v("more_horiz"),
                            ]),
                          ],
                          1
                        ),
                        !_vm.isHEJobCompleted
                          ? _c(
                              "span",
                              { staticClass: "mb-2 card-img-content" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-avatar",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "cost-authorised-btn",
                                                    class: _vm.jobItem
                                                      .additionalCostsAuthorised
                                                      ? "green"
                                                      : "white",
                                                    attrs: {
                                                      color: "",
                                                      size: 35,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.openCostAuthorizationDialog,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "i",
                                                    { staticClass: "svg-wrap" },
                                                    [
                                                      _c("img", {
                                                        class: _vm.jobItem
                                                          .additionalCostsAuthorised
                                                          ? "hide-img"
                                                          : "show-img",
                                                        attrs: {
                                                          src: "/img/get-money.svg",
                                                        },
                                                      }),
                                                      _c("img", {
                                                        class: _vm.jobItem
                                                          .additionalCostsAuthorised
                                                          ? "show-img"
                                                          : "hide-img",
                                                        attrs: {
                                                          src: "/img/get-money-white.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1660557521
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("Additional Payment Authorised"),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showOtherFeesDetailIcon
                          ? _c(
                              "span",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-avatar",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "btn-addOtherFeesDetail",
                                                    attrs: {
                                                      color: "white",
                                                      size: 35,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.addOtherFeesDetail,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                      },
                                                    },
                                                    [_vm._v("post_add")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3221854906
                                    ),
                                  },
                                  [_c("span", [_vm._v("Other Fees")])]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.jobItem.status !== "Completed"
                          ? _c(
                              "span",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _vm.jobItem.status ===
                                                "Cancelled" ||
                                              _vm.jobItem.cancellationReason
                                                ? _c(
                                                    "v-avatar",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          size: 35,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "red",
                                                          attrs: {
                                                            color: "white",
                                                          },
                                                        },
                                                        [_vm._v("work_off")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm.getAbilityToCancelJob
                                                ? _c(
                                                    "v-avatar",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          color: "white",
                                                          size: 35,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.cancelJob(
                                                              _vm.jobItem
                                                                .cancellationReason
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                          },
                                                        },
                                                        [_vm._v("work_off")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3892029976
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.jobItem.status ===
                                              "Cancelled" ||
                                              _vm.jobItem.cancellationReason
                                              ? "Job Cancelled "
                                              : "Cancel Job"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.canEscalateJob ||
                        (_vm.getJobCurrentEscalationDetail &&
                          _vm.getJobCurrentEscalationDetail.escalationReason)
                          ? _c(
                              "span",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-avatar",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "btn-escalateJobDetail",
                                                    class:
                                                      _vm.getJobCurrentEscalationDetail &&
                                                      _vm
                                                        .getJobCurrentEscalationDetail
                                                        .escalationReason
                                                        ? "secondary"
                                                        : "white",
                                                    attrs: {
                                                      color: "",
                                                      size: 35,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.showEscalateJobDialog,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class:
                                                        _vm.getJobCurrentEscalationDetail &&
                                                        _vm
                                                          .getJobCurrentEscalationDetail
                                                          .escalationReason
                                                          ? "white--text"
                                                          : "secondary--text",
                                                    },
                                                    [_vm._v(" forward ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      615944311
                                    ),
                                  },
                                  [
                                    _vm.getJobCurrentEscalationDetail &&
                                    _vm.getJobCurrentEscalationDetail
                                      .escalationReason
                                      ? _c("span", [
                                          _vm._v(
                                            " Job is Escalated due to " +
                                              _vm._s(
                                                _vm
                                                  .getJobCurrentEscalationDetail
                                                  .escalationReason
                                              ) +
                                              ". "
                                          ),
                                          _c("p", [
                                            _vm._v(
                                              " Description: " +
                                                _vm._s(
                                                  _vm
                                                    .getJobCurrentEscalationDetail
                                                    .escalationDescription
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      : _c("span", [_vm._v("Escalate Job")]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showRepairEstimateIcon
                          ? _c(
                              "span",
                              { staticClass: "mb-2" },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-avatar",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "btn-repairEstimate",
                                                    attrs: {
                                                      color: "white",
                                                      size: 35,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.showRepairEstimationDialog = true
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "secondary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "home_repair_service"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2142763763
                                    ),
                                  },
                                  [_c("span", [_vm._v("Repair Estimate")])]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "800",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showPolicySummaryDialog,
            callback: function ($$v) {
              _vm.showPolicySummaryDialog = $$v
            },
            expression: "showPolicySummaryDialog",
          },
        },
        [
          _c("PolicySummary", {
            attrs: {
              "policy-summary-item": _vm.policySummaryModel,
              "job-id": _vm.jobId,
            },
            on: { OnPolicySummaryClose: _vm.closePolicySummaryDialog },
          }),
        ],
        1
      ),
      _vm.showAuditLogDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showAuditLogDialog,
                callback: function ($$v) {
                  _vm.showAuditLogDialog = $$v
                },
                expression: "showAuditLogDialog",
              },
            },
            [
              _c("JobAuditLog", {
                attrs: { "job-id": _vm.jobId },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeAuditLog()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "450",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.costAuthorizationDialog,
            callback: function ($$v) {
              _vm.costAuthorizationDialog = $$v
            },
            expression: "costAuthorizationDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Cost Authorization")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onCostAuthorizationDialogClose.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c("v-switch", {
                    attrs: {
                      label: "Authorize Additional Cost",
                      "hide-details": "",
                      color: "primary",
                    },
                    on: { change: _vm.additionalCostsAuthorisedChange },
                    model: {
                      value: _vm.additionalCostsAuthorised,
                      callback: function ($$v) {
                        _vm.additionalCostsAuthorised = $$v
                      },
                      expression: "additionalCostsAuthorised",
                    },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-flex",
                    [
                      _vm.additionalCostsAuthorised
                        ? _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              items:
                                _vm.additionalPaymentAuthorizationReasonList,
                              "item-text": "description",
                              "item-value": "id",
                              label:
                                "Select additional payment authorization reason",
                              required: "",
                              loading:
                                _vm.getadditionalPaymentAuthorizationReasonLoading,
                              disabled: _vm.disableCostReason,
                              "data-vv-scope": "frmAuthorizationReason",
                              "data-vv-name": "Authorization Job Reason",
                              "error-messages": _vm.errors.collect(
                                "Authorization Job Reason"
                              ),
                            },
                            on: {
                              change:
                                _vm.additionalPaymentAuthorizationReasonChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "v-list-tile",
                                        [
                                          _c(
                                            "v-list-tile-content",
                                            [
                                              _c(
                                                "v-list-tile-title",
                                                {
                                                  class:
                                                    data.item.description ===
                                                    "Configure"
                                                      ? "bold-select"
                                                      : "",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.item.description
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3659597833
                            ),
                            model: {
                              value: _vm.additionalPaymentAuthorizationReason,
                              callback: function ($$v) {
                                _vm.additionalPaymentAuthorizationReason = $$v
                              },
                              expression:
                                "additionalPaymentAuthorizationReason",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.onCostAuthorizationDialogClose.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.onAdditionalCostAuthorized },
                    },
                    [_vm._v(" Save ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.cancelJobDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                "content-class": "v-dialog--scrollable",
                persistent: "",
              },
              model: {
                value: _vm.cancelJobDialog,
                callback: function ($$v) {
                  _vm.cancelJobDialog = $$v
                },
                expression: "cancelJobDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Cancel Job")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.cancelJobDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog px-3" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("v-select", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  items: _vm.cancelJobReasonList,
                                  "item-text": "description",
                                  "item-value": "id",
                                  label: "Select cancellation reason for job",
                                  required: "",
                                  loading: _vm.getCancelJobReasonLoading,
                                  "data-vv-scope": "frmCancellationReason",
                                  "data-vv-name": "Cancel Job Reason",
                                  "error-messages":
                                    _vm.errors.collect("Cancel Job Reason"),
                                },
                                on: { change: _vm.cancelJobReasonChange },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "item",
                                      fn: function (data) {
                                        return [
                                          _c(
                                            "v-list-tile",
                                            [
                                              _c(
                                                "v-list-tile-content",
                                                [
                                                  _c(
                                                    "v-list-tile-title",
                                                    {
                                                      class:
                                                        data.item
                                                          .description ===
                                                        "Configure"
                                                          ? "bold-select"
                                                          : "",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            data.item
                                                              .description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3659597833
                                ),
                                model: {
                                  value: _vm.cancelJobReason,
                                  callback: function ($$v) {
                                    _vm.cancelJobReason = $$v
                                  },
                                  expression: "cancelJobReason",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: { label: "Description" },
                                model: {
                                  value: _vm.reasonDescription,
                                  callback: function ($$v) {
                                    _vm.reasonDescription = $$v
                                  },
                                  expression: "reasonDescription",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.cancelJobDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.addCancelJobRequest },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.openEscalateJobDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "450",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.openEscalateJobDialog,
                callback: function ($$v) {
                  _vm.openEscalateJobDialog = $$v
                },
                expression: "openEscalateJobDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Escalate Job")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.openEscalateJobDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required escalationType-list",
                        attrs: {
                          items: _vm.escalationTypeList,
                          "item-text": "description",
                          "item-value": "id",
                          label: "Reason for escalation",
                          required: "",
                          "data-vv-scope": "frmEscalateReason",
                          "data-vv-name": "Job Escalate Reason",
                          "error-messages": _vm.errors.collect(
                            "Job Escalate Reason"
                          ),
                        },
                        on: { change: _vm.onEscalationTypeChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _c(
                                    "v-list-tile",
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c(
                                            "v-list-tile-title",
                                            {
                                              class:
                                                data.item.description ===
                                                "Configure"
                                                  ? "bold-select"
                                                  : "",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.item.description
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3659597833
                        ),
                        model: {
                          value: _vm.escalationTypeId,
                          callback: function ($$v) {
                            _vm.escalationTypeId = $$v
                          },
                          expression: "escalationTypeId",
                        },
                      }),
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Description",
                          required: "",
                          "data-vv-scope": "frmEscalateReason",
                          "data-vv-name": "Job Escalate Description",
                          "error-messages": _vm.errors.collect(
                            "Job Escalate Description"
                          ),
                        },
                        model: {
                          value: _vm.escalationDescription,
                          callback: function ($$v) {
                            _vm.escalationDescription = $$v
                          },
                          expression: "escalationDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.resetEscalationFields()
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.escalateJob()
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.policyReAssignDialogue
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "overflow-visible",
              },
              model: {
                value: _vm.policyReAssignDialogue,
                callback: function ($$v) {
                  _vm.policyReAssignDialogue = $$v
                },
                expression: "policyReAssignDialogue",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Select Policy")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.policyReAssignDialogue = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 policy-selector" },
                    [
                      _c("v-progress-circular", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.policies,
                            expression: "!policies",
                          },
                        ],
                        style: _vm.getLoaderStyle(40),
                        attrs: {
                          width: 2,
                          size: 50,
                          indeterminate: "",
                          color: "primary",
                        },
                      }),
                      _c(
                        "v-flex",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.policies,
                              expression: "policies",
                            },
                          ],
                          attrs: { "mb-2": "" },
                        },
                        [
                          _vm.policies && _vm.preSelectedPolicyScheduleId
                            ? _c("PolicySelector", {
                                ref: "policySelector",
                                attrs: {
                                  id: "policySelector",
                                  type: "number",
                                  "policy-tree": _vm.policies,
                                  "error-messages": _vm.policySelected
                                    ? []
                                    : ["A policy must be selected"],
                                  "selected-policy": _vm.selectedPolicy,
                                  "pre-selected-policy":
                                    _vm.preSelectedPolicyScheduleId,
                                  "parent-component": "CustomerCard",
                                },
                                on: {
                                  "update:selectedPolicy":
                                    _vm.updatePolicySelection,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.showInfoMessage
                        ? _c("span", { staticClass: "warning--text" }, [
                            _vm._v(" " + _vm._s(_vm.showInfoMessage) + " "),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closePolicyReAssignDialogue.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled:
                              _vm.policySearchRunning ||
                              _vm.policyReAssignInProgress,
                            loading: _vm.policyReAssignInProgress,
                          },
                          on: { click: _vm.onUpdateJobPolicySchedule },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.triggerCTPForPolicyChangeDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.triggerCTPForPolicyChangeDialog,
                callback: function ($$v) {
                  _vm.triggerCTPForPolicyChangeDialog = $$v
                },
                expression: "triggerCTPForPolicyChangeDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [
                      _vm._v(
                        " " +
                          _vm._s(
                            "Policy Limit Exceeded. Do you want to pay " +
                              _vm.getFormatedCurrency(
                                _vm.triggerCTPResponse.extraAmountNeedToPay
                              ) +
                              " to proceed further?"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", flat: "" },
                          on: {
                            click: _vm.closeTriggerCTPForPolicyChangeDialog,
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", small: "" },
                          on: { click: _vm.onConfirmTriggerCTPForPolicyChange },
                        },
                        [_vm._v("Yes")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.useTriggerCTPLoading,
                            loading: _vm.useTriggerCTPLoading,
                          },
                          on: { click: _vm.changePolicyWithoutCTP },
                        },
                        [_vm._v(" Skip CTP ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.useExtraBalanceConfirmationDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.useExtraBalanceConfirmationDialog,
                callback: function ($$v) {
                  _vm.useExtraBalanceConfirmationDialog = $$v
                },
                expression: "useExtraBalanceConfirmationDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [
                      _vm._v(
                        " " +
                          _vm._s(
                            "You have " +
                              _vm.getFormatedCurrency(
                                _vm.triggerCTPResponse.extraBalanceLeft
                              ) +
                              " extra balance. Do you want to use this balance to proceed further?"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", flat: "" },
                          on: {
                            click: _vm.onCancelExtraBalanceConfirmationDialog,
                          },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.useTriggerCTPLoading,
                            loading: _vm.useTriggerCTPLoading,
                          },
                          on: { click: _vm.onConfirmUseExtraBalance },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showRepairEstimationDialog
        ? _c("RepairEstimationDialog", {
            ref: "repairEstimationDialog",
            attrs: {
              "policy-client-rate-package-id":
                _vm.jobItem && _vm.jobItem.policyClientRatePackageId
                  ? _vm.jobItem.policyClientRatePackageId
                  : "",
              "job-id": _vm.jobId,
            },
            on: {
              closeRepairEstimationDialog: _vm.onCloseRepairEstimationDialog,
            },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.configureEscalationReasonDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "860",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureEscalationReasonDialog,
                callback: function ($$v) {
                  _vm.configureEscalationReasonDialog = $$v
                },
                expression: "configureEscalationReasonDialog",
              },
            },
            [
              _c("ConfigureEscalationReason", {
                ref: "refConfigureEscalationReason",
                on: {
                  CloseConfigureEscalationReasonDialog:
                    _vm.onConfigureEscalationReasonDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }