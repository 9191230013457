var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "holder-list-form", attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "ml-1 grey lighten-4 pt-3 pb-3 pl-3",
              class:
                _vm.localPolicyRoutetoMarket.length >= 1 ? "pr-3 pb-5" : "",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                  staticClass: "route-to-market",
                },
                [
                  _c(
                    "div",
                    [
                      _c("v-flex", { attrs: { "pb-1": "" } }, [
                        _vm.localPolicyRoutetoMarket.length === 0
                          ? _c(
                              "h3",
                              { staticClass: "text-xs-center grey--text" },
                              [_vm._v("No Data Available")]
                            )
                          : _vm._e(),
                      ]),
                      _vm._l(
                        _vm.localPolicyRoutetoMarket,
                        function (item, ItemIndex) {
                          return _c(
                            "v-flex",
                            {
                              key: item.dummyId,
                              class:
                                _vm.localPolicyRoutetoMarket.length === 0
                                  ? "pr-0"
                                  : !_vm.isUserRoleClientManager
                                  ? "route-to-market-repeat"
                                  : "pr-3",
                              attrs: { xs12: "" },
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { staticClass: "pr-2", attrs: { xs6: "" } },
                                    [
                                      _c("v-select", {
                                        staticClass: "routeToMarketTemp",
                                        attrs: {
                                          items: _vm.policyRoutetoMarketItems,
                                          label: "Route to market",
                                          "item-value": "routeToMarket",
                                          "item-text": "routeToMarket",
                                          readonly: _vm.isUserRoleClientManager,
                                          loading: _vm.routeToMarketRunning,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.policyRoutetoMarkertChanged(
                                              $event,
                                              ItemIndex,
                                              item
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.routeToMarket,
                                          callback: function ($$v) {
                                            _vm.$set(item, "routeToMarket", $$v)
                                          },
                                          expression: "item.routeToMarket",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-flex",
                                    { staticClass: "pl-2", attrs: { xs6: "" } },
                                    [
                                      _c("v-select", {
                                        staticClass: "productCode",
                                        attrs: {
                                          items: item.productItems,
                                          label: "Product code",
                                          "item-value": "policyRouteToMarketId",
                                          "item-text": "productCode",
                                          readonly: _vm.isUserRoleClientManager,
                                          loading: item.isLoading,
                                        },
                                        model: {
                                          value: item.policyRouteToMarketId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item,
                                              "policyRouteToMarketId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.policyRouteToMarketId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.isUserRoleClientManager
                                    ? _c(
                                        "v-flex",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "mt-3 emergency-delete-icon close-icon",
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "secondary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.removeProduct(
                                                    ItemIndex
                                                  )
                                                },
                                              },
                                            },
                                            [_c("v-icon", [_vm._v("delete")])],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              ),
              !_vm.isUserRoleClientManager
                ? _c(
                    "div",
                    { staticClass: "add-btn-form" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mt-2 add-icon",
                                          attrs: {
                                            flat: "",
                                            icon: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: _vm.addAdditionalProduct,
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("add")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3881374130
                          ),
                        },
                        [_c("span", [_vm._v("Add route to market")])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }