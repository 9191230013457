var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.contractorJobs
    ? _c(
        "div",
        { staticClass: "contjob-status-board elevation-1" },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-xl": "", fluid: "" } },
            [
              _c(
                "v-layout",
                { staticClass: "contjob-status-head", attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("h3", [_vm._v("Pending")]),
                      _c("v-divider", { staticClass: "mt-2 mb-2" }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("h3", [_vm._v("Accepted")]),
                      _c("v-divider", { staticClass: "mt-2 mb-2" }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("h3", [_vm._v("In progress")]),
                      _c("v-divider", { staticClass: "mt-2 mb-2" }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("h3", [_vm._v("Completed")]),
                      _c("v-divider", { staticClass: "mt-2 mb-2" }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                },
                [
                  _c(
                    "v-layout",
                    { staticClass: "contjob-status-body d-flex" },
                    [
                      _c("ContractorJobCard", {
                        attrs: {
                          id: _vm.id,
                          "status-text": "Pending",
                          "contractor-jobs-by-visit-status":
                            _vm.getContractorJobList.filter(
                              (c) =>
                                c.status ===
                                _vm.jobVisitStatus[_vm.jobVisitStatus.Pending]
                            ),
                          engineers: _vm.engineers,
                          "selected-job-to-open": _vm.selectedJobToOpen,
                          "show-engineer-details": _vm.showEngineerDetails,
                          emergencies: _vm.emergencies,
                          "total-job-count": _vm.totalJobCount
                            ? _vm.totalJobCount.totalPendingJobCount
                            : 0,
                          "show-load-more": _vm.showLoadMore,
                          "visit-type": _vm.visitType,
                        },
                      }),
                      _c("ContractorJobCard", {
                        attrs: {
                          id: _vm.id,
                          "status-text": "Accepted",
                          "contractor-jobs-by-visit-status":
                            _vm.getContractorJobList.filter(
                              (c) =>
                                c.status ===
                                _vm.jobVisitStatus[_vm.jobVisitStatus.Accepted]
                            ),
                          engineers: _vm.engineers,
                          "selected-job-to-open": _vm.selectedJobToOpen,
                          "show-engineer-details": _vm.showEngineerDetails,
                          emergencies: _vm.emergencies,
                          "total-job-count": _vm.totalJobCount
                            ? _vm.totalJobCount.totalAcceptedJobCount
                            : 0,
                          "show-load-more": _vm.showLoadMore,
                          "visit-type": _vm.visitType,
                        },
                      }),
                      _c("ContractorJobCard", {
                        attrs: {
                          id: _vm.id,
                          "status-text": "In progress",
                          "contractor-jobs-by-visit-status":
                            _vm.getContractorJobList.filter(
                              (c) =>
                                c.status ===
                                _vm.jobVisitStatus[
                                  _vm.jobVisitStatus.InProgress
                                ]
                            ),
                          engineers: _vm.engineers,
                          "selected-job-to-open": _vm.selectedJobToOpen,
                          "show-engineer-details": _vm.showEngineerDetails,
                          emergencies: _vm.emergencies,
                          "total-job-count": _vm.totalJobCount
                            ? _vm.totalJobCount.totalInProgressJobCount
                            : 0,
                          "show-load-more": _vm.showLoadMore,
                          "visit-type": _vm.visitType,
                        },
                      }),
                      _c("ContractorJobCard", {
                        attrs: {
                          id: _vm.id,
                          "status-text": "Completed",
                          "contractor-jobs-by-visit-status":
                            _vm.getContractorJobList.filter(
                              (c) =>
                                c.status ===
                                _vm.jobVisitStatus[_vm.jobVisitStatus.Completed]
                            ),
                          engineers: _vm.engineers,
                          "selected-job-to-open": _vm.selectedJobToOpen,
                          "show-engineer-details": _vm.showEngineerDetails,
                          emergencies: _vm.emergencies,
                          "total-job-count": _vm.totalJobCount
                            ? _vm.totalJobCount.totalCompletedJobCount
                            : 0,
                          "show-load-more": _vm.showLoadMore,
                          "visit-type": _vm.visitType,
                        },
                      }),
                      _vm.getContractorJobs &&
                      _vm.getContractorJobList.length === 0
                        ? _c("div", { staticClass: "data_nf_msg" }, [
                            _c("span", [_vm._v("Data Not Available")]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
              model: {
                value: _vm.snackbar,
                callback: function ($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.snackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }