var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [
        _vm._v(
          "Send Job SMS " + _vm._s(_vm.item.sendToNominee ? "(Nominee)" : "")
        ),
      ]),
    ]),
    _vm.documentTemplateName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Document template type: ")]),
          _c("span", [_vm._v(_vm._s(_vm.documentTemplateName))]),
        ])
      : _vm._e(),
    _vm.item.emergencyName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
        ])
      : _vm._e(),
    _vm.contractorAppointedDetail
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Company name: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.contractorAppointedDetail.companyName)),
          ]),
        ])
      : _vm._e(),
    _vm.item.documentTemplateType === _vm.documentTemplateType.WorkInProgress
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Is WIP Customer Notification?: ")]),
          _c("span", [_vm._v("Yes")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }