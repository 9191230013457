var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "pa-3": "" } },
        [
          _c(
            "v-flex",
            [
              _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  items: _vm.jobRejectReasonList,
                  "item-text": "description",
                  "item-value": "id",
                  label: "Select cancellation reason for job",
                  required: "",
                  loading: _vm.jobRejectReasonListLoading,
                  "data-vv-scope": "rejectJobValidator",
                  "data-vv-name": "Cancel Job Reason",
                  "error-messages": _vm.errors.collect("Cancel Job Reason"),
                },
                model: {
                  value: _vm.jobRejectReason,
                  callback: function ($$v) {
                    _vm.jobRejectReason = $$v
                  },
                  expression: "jobRejectReason",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: { label: "Description" },
                model: {
                  value: _vm.jobRejectDescription,
                  callback: function ($$v) {
                    _vm.jobRejectDescription = $$v
                  },
                  expression: "jobRejectDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-layout",
        { staticClass: "justify-end" },
        [
          _c(
            "v-flex",
            { staticClass: "d-flex justify-end", attrs: { xs4: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: {
                    loading: _vm.isRejectJobLoading,
                    disabled: _vm.isRejectJobLoading,
                  },
                  on: { click: _vm.rejectJob },
                },
                [_vm._v(" Submit ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }