var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.clientTitle
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Title: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.clientTitle))]),
        ])
      : _vm._e(),
    _vm.item.clientForename
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("First Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.clientForename))]),
        ])
      : _vm._e(),
    _vm.item.clientLastName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Last Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.clientLastName))]),
        ])
      : _vm._e(),
    _vm.item.secondaryClientTitle
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Secondary Policy Holder Title: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientTitle))]),
        ])
      : _vm._e(),
    _vm.item.secondaryClientForename
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Secondary Policy Holder First Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientForename))]),
        ])
      : _vm._e(),
    _vm.item.secondaryClientLastName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Secondary Policy Holder Last Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.secondaryClientLastName))]),
        ])
      : _vm._e(),
    _vm.item.addressLine1
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line1: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine1))]),
        ])
      : _vm._e(),
    _vm.item.addressLine2
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line2: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine2))]),
        ])
      : _vm._e(),
    _vm.item.addressLine3
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line3: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine3))]),
        ])
      : _vm._e(),
    _vm.item.addressLine4
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line4: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine4))]),
        ])
      : _vm._e(),
    _vm.item.postCode
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Postcode: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.postCode))]),
        ])
      : _vm._e(),
    _vm.item.policyNumber
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Policy Number: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.policyNumber))]),
        ])
      : _vm._e(),
    _vm.item.policyScheduleId
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("PolicyScheduleId: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.policyScheduleId))]),
        ])
      : _vm._e(),
    _vm.item.enquiryCategory
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Enquiry Category: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.enquiryCategory))]),
        ])
      : _vm._e(),
    _vm.item.enquiryDescription
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Enquiry Description: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.enquiryDescription))]),
        ])
      : _vm._e(),
    _vm.item.FNOLCallSid
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("FNOL Call Sid: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.FNOLCallSid))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Policy Enquiry Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }