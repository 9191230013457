var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-container",
        {
          staticClass: "pa-0",
          class: _vm.openJobView ? "hide-dashboard" : "show-dashboard",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-spacer"),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.getETAFilterTypes,
                          "item-text": "description",
                          "item-value": "id",
                          label: "ETA Type",
                          disabled: _vm.isFilterDisabled,
                        },
                        model: {
                          value: _vm.selectedETAFilterType,
                          callback: function ($$v) {
                            _vm.selectedETAFilterType = $$v
                          },
                          expression: "selectedETAFilterType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-autocomplete", {
                        staticClass: "escalation-type",
                        attrs: {
                          items: _vm.getJobEscalationTypes,
                          "item-text": "description",
                          "item-value": "id",
                          label: "Escalation Type",
                          name: "searchByEscalationType",
                          disabled: _vm.isFilterDisabled,
                        },
                        model: {
                          value: _vm.selectedJobEscalationType,
                          callback: function ($$v) {
                            _vm.selectedJobEscalationType = $$v
                          },
                          expression: "selectedJobEscalationType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-select", {
                        staticClass: "escalation-reason",
                        attrs: {
                          items: _vm.escalationReasonList,
                          "item-text": "description",
                          "item-value": "description",
                          label: "Escalation Reason",
                          name: "searchByEscalationReason",
                          loading: _vm.getEscalationReasonLoading,
                          disabled:
                            _vm.isFilterDisabled ||
                            _vm.selectedJobEscalationType !==
                              _vm.escalationTypeEnum.Escalated,
                        },
                        model: {
                          value: _vm.selectedEscalationReason,
                          callback: function ($$v) {
                            _vm.selectedEscalationReason = $$v
                          },
                          expression: "selectedEscalationReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.userTeam,
                          "item-text": "teamName",
                          "item-value": "id",
                          label: "Search team",
                          name: "searchByTeam",
                          loading: _vm.getTeamRunning,
                          disabled: _vm.isFilterDisabled,
                        },
                        model: {
                          value: _vm.selectedTeamName,
                          callback: function ($$v) {
                            _vm.selectedTeamName = $$v
                          },
                          expression: "selectedTeamName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.contractors,
                          "item-text": "companyName",
                          "item-value": "id",
                          label: "Search contractor",
                          name: "searchByContractor",
                          loading: _vm.getContractorRunning,
                          disabled: _vm.isFilterDisabled,
                        },
                        model: {
                          value: _vm.selectedContractor,
                          callback: function ($$v) {
                            _vm.selectedContractor = $$v
                          },
                          expression: "selectedContractor",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right px-2" },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "search",
                          label: "Search by jobid",
                          "single-line": "",
                          "hide-details": "",
                          name: "searchByJobId",
                          disabled: _vm.isFilterDisabled,
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.jobIdValidation($event)
                          },
                        },
                        model: {
                          value: _vm.searchValue,
                          callback: function ($$v) {
                            _vm.searchValue = $$v
                          },
                          expression: "searchValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  attrs: {
                    wrap: "",
                    "calls-info": "",
                    "undeployed-jobcount": "",
                    "mb-3": "",
                  },
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass:
                            "elevation-0 pa-2 grey lighten-3 white--text",
                        },
                        [
                          _c(
                            "v-card-title",
                            {
                              staticClass:
                                "pa-0 grey--text grey--text text--darken-2",
                            },
                            [_c("b", [_vm._v("Outstanding Trade Jobs")])]
                          ),
                          _vm.jobsTradeList.length > 0
                            ? _c("v-card-text", { staticClass: "pa-1" }, [
                                _c(
                                  "div",
                                  _vm._l(
                                    _vm.jobsTradeList,
                                    function (jobTrade, i) {
                                      return _c(
                                        "span",
                                        { key: i },
                                        [
                                          jobTrade.tradeId === 0
                                            ? _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "grey grey--text text--darken-4",
                                                  class:
                                                    _vm.currentIndex === -1
                                                      ? "lighten-1"
                                                      : "lighten-2",
                                                  attrs: { label: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getTradeWiseJobs(
                                                        "All",
                                                        0,
                                                        ""
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(" All   "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        jobTrade.tradeCount
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _c(
                                                "v-chip",
                                                {
                                                  staticClass:
                                                    "grey grey--text text--darken-2",
                                                  class:
                                                    _vm.currentIndex === i
                                                      ? "lighten-1"
                                                      : "lighten-2",
                                                  attrs: { label: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getTradeWiseJobs(
                                                        "",
                                                        jobTrade.tradeId,
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getTradeName(
                                                          jobTrade.tradeId
                                                        )
                                                      ) +
                                                      "   "
                                                  ),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        jobTrade.tradeCount
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "elevation-1", attrs: { xs12: "", "mt-2": "" } },
            [
              _c("v-data-table", {
                staticClass: "gridView",
                class: _vm.isFilterDisabled
                  ? "disable-missedjob-table-actions"
                  : "",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.missedJobItems,
                  "total-items": _vm.missedJobItemsCount,
                  loading: _vm.isLoading,
                  pagination: _vm.pagination,
                  "rows-per-page-items": _vm.rowsPerPageItems,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("tr", {
                          attrs: { active: props.selected },
                          on: {
                            click: function ($event) {
                              props.selected = !props.selected
                            },
                          },
                        }),
                        _c("tr", [
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "primary--text",
                                attrs: {
                                  href: "javascript:void(0)",
                                  name: "openJobDetailButton",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.redirectToJob(props.item.jobId)
                                  },
                                },
                              },
                              [_c("b", [_vm._v(_vm._s(props.item.jobId))])]
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(props.item.address))]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  props.item.companyName
                                    ? props.item.companyName
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  props.item.engineerName
                                    ? props.item.engineerName
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getFormattedDateTime(props.item.etaFrom) +
                                    " To " +
                                    _vm.getFormattedDateTime(props.item.etaTo)
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", [_vm._v(_vm._s(props.item.status))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _c("PartialJobView", {
        ref: "refPartialJobView",
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }