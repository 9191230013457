var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-btn",
    {
      staticClass: "action-button",
      attrs: { color: "secondary", small: false, disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._v(_vm._s(_vm.isSet ? "Update" : "Capture"))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }