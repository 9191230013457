var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("p", [_vm._v(_vm._s(_vm.errorMessage))]),
      _c("v-btn", { staticClass: "primary", on: { click: _vm.logout } }, [
        _vm._v("Log Out"),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }