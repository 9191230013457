var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.todaySlots.length > 0 ||
      (_vm.tomorrowSlots.length === 0 && _vm.dayAfterTomorrowSlots.length === 0)
        ? _c(
            "v-flex",
            { staticClass: "mb-2 today-slots", attrs: { xs12: "" } },
            [
              _c(
                "div",
                { staticClass: "show-clta-time" },
                [
                  _c(
                    "span",
                    { staticClass: "show-clta-label" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "secondary" } },
                        [_vm._v("date_range")]
                      ),
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.getFirstPickedDate) + ":"),
                      ]),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _vm._l(_vm.todaySlots, function (item) {
                    return [
                      item.slotId > 0
                        ? _c(
                            "v-chip",
                            {
                              key: item.slotId,
                              staticClass: "white--text",
                              attrs: {
                                flat: "",
                                outline: "",
                                color: "primary",
                                dark: "",
                                small: "",
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      new Date(item.startTime).getHours()
                                    ) +
                                    " to " +
                                    _vm._s(
                                      new Date(item.endTime).getHours() === 0
                                        ? 24
                                        : new Date(item.endTime).getHours()
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm.tomorrowSlots.length > 0
        ? _c(
            "v-flex",
            { staticClass: "mb-2 tomorrow-slots", attrs: { xs12: "" } },
            [
              _c(
                "div",
                { staticClass: "show-clta-time" },
                [
                  _c(
                    "span",
                    { staticClass: "show-clta-label" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "secondary" } },
                        [_vm._v("date_range")]
                      ),
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.getSecondPickedDate) + ":"),
                      ]),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _vm._l(_vm.tomorrowSlots, function (item) {
                    return [
                      item.slotId > 0
                        ? _c(
                            "v-chip",
                            {
                              key: item.slotId,
                              staticClass: "white--text",
                              attrs: {
                                flat: "",
                                outline: "",
                                color: "primary",
                                dark: "",
                                small: "",
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      new Date(item.startTime).getHours()
                                    ) +
                                    " to " +
                                    _vm._s(
                                      new Date(item.endTime).getHours() === 0
                                        ? 24
                                        : new Date(item.endTime).getHours()
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm.dayAfterTomorrowSlots.length > 0
        ? _c(
            "v-flex",
            { staticClass: "mb-2 dayaftertomorrow-slots", attrs: { xs12: "" } },
            [
              _c(
                "div",
                { staticClass: "show-clta-time" },
                [
                  _c(
                    "span",
                    { staticClass: "show-clta-label" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { color: "secondary" } },
                        [_vm._v("date_range")]
                      ),
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v(_vm._s(_vm.getThirdPickedDate) + ":"),
                      ]),
                      _vm._v("   "),
                    ],
                    1
                  ),
                  _vm._l(_vm.dayAfterTomorrowSlots, function (item) {
                    return [
                      item.slotId > 0
                        ? _c(
                            "v-chip",
                            {
                              key: item.slotId,
                              staticClass: "white--text",
                              attrs: {
                                flat: "",
                                outline: "",
                                color: "primary",
                                dark: "",
                                small: "",
                              },
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      new Date(item.startTime).getHours()
                                    ) +
                                    " to " +
                                    _vm._s(
                                      new Date(item.endTime).getHours() === 0
                                        ? 24
                                        : new Date(item.endTime).getHours()
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }