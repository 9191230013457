var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-autocomplete", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required timeRequestList",
                attrs: {
                  items: _vm.timeRequestList,
                  label: "Add Hours",
                  "item-text": "text",
                  "item-value": "value",
                  required: "",
                  "data-vv-name": "Additional Hours",
                  "error-messages": _vm.errors.collect("Additional Hours"),
                },
                on: {
                  change: function ($event) {
                    return _vm.OnTimeRequestChange($event)
                  },
                },
                model: {
                  value: _vm.selectedTimeRequestSlot,
                  callback: function ($$v) {
                    _vm.selectedTimeRequestSlot = $$v
                  },
                  expression: "selectedTimeRequestSlot",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  id: "Reason",
                  name: "timeRequestReason",
                  label: "Reason",
                  required: "",
                  "data-vv-name": "Reason",
                  "error-messages": _vm.errors.collect("Reason"),
                },
                model: {
                  value: _vm.value.timeRequestReason,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "timeRequestReason", $$v)
                  },
                  expression: "value.timeRequestReason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }