var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Abandoned " + _vm._s(_vm.productType))]),
    ]),
    _vm.item.abandonedReason
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Reason: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.abandonedReason))]),
        ])
      : _vm._e(),
    _vm.item.abandonedReasonNotes
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Notes: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.abandonedReasonNotes))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }