var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.card
    ? _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "grey--text text--darken-3" }, [
              _vm._v(_vm._s(_vm.card.title)),
            ]),
          ]),
          _c("v-spacer"),
          _c("v-card-text", [_c("h4", [_vm._v(_vm._s(_vm.card.payload))])]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }