var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.documentTemplate && _vm.showDocumentTemplateDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800",
                "content-class": "document-temp v-dialog--scrollable",
              },
              model: {
                value: _vm.showDocumentTemplateDialog,
                callback: function ($$v) {
                  _vm.showDocumentTemplateDialog = $$v
                },
                expression: "showDocumentTemplateDialog",
              },
            },
            [
              _c("DocumentTemplate", {
                ref: "refDocumentTemplate",
                attrs: {
                  "document-item": _vm.documentTemplate,
                  "is-edit": true,
                },
                on: { OnDocumentSave: _vm.saveDocumentTemplate },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "pl-2 pr-2", attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "gridView document-template-list",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.getDocumentList,
                  loading: _vm.isTemplateLoading,
                  pagination: _vm.pagination,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            class: props.item.isDeleted
                              ? "deleted-template"
                              : "",
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("v-checkbox", {
                                  staticClass: "documentRecord",
                                  attrs: {
                                    color: "primary",
                                    "hide-details": "",
                                    "input-value": props.item.selectedTemplate,
                                    disabled: _vm.isUserRoleClientManager,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.onDocumentSelect(props.item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.templateTypeDescription)
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.notificationPreferenceName)
                              ),
                            ]),
                            _c(
                              "td",
                              { staticClass: "justify-center layout px-0" },
                              [
                                !_vm.isUserRoleClientManager
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mx-0 btn-editDocumenTemplate",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onEditClick(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "grey",
                                                          },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Edit")])]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }