var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Emergency Accepted: ")]),
      _c("span", [_vm._v(_vm._s(_vm.item.isEmergencyAccepted ? "Yes" : "No"))]),
    ]),
    _vm.item.repudiationDescription
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Repudiation Description: ")]),
          _c("span", [
            _c("pre", [_vm._v(_vm._s(_vm.item.repudiationDescription))]),
          ]),
        ])
      : _vm._e(),
    _vm.item.acceptRejectNote
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.item.isEmergencyAccepted ? "Accept Note:" : "Reject Note:"
                ) +
                " "
            ),
          ]),
          _c("span", [_c("pre", [_vm._v(_vm._s(_vm.item.acceptRejectNote))])]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Agreed for CTP?: ")]),
      _c("span", [
        _c("pre", [
          _vm._v(
            _vm._s(_vm.item.ctpCompletedForRejectedEmergency ? "Yes" : "No")
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Emergency Accepted Or Rejected")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }