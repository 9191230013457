var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { class: _vm.flexClass },
    [
      _c(
        "v-card",
        {
          class: "elevation-0 white--text pa-0 " + _vm.colorClass,
          style: { backgroundColor: _vm.color },
        },
        [
          _c("v-card-text", { staticClass: "pa-0" }, [
            _c("div", { staticClass: "card-top-content" }, [
              _c("h2", { staticClass: "pa-2" }, [_vm._v(_vm._s(_vm.title))]),
              _c("div", { staticClass: "day-counts-section" }, [
                _c(
                  "span",
                  {
                    staticClass: "text-xs-center lighten-1",
                    class: _vm.colorClass ? _vm.colorClass : "customColor",
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "i",
                                  _vm._g({ staticClass: "icon" }, on),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("call")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_c("span", [_vm._v("Total number of calls")])]
                    ),
                    _c("span", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.insurerCountStore.totalCalls)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "text-xs-center lighten-1",
                    class: _vm.colorClass ? _vm.colorClass : "customColor",
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "i",
                                  _vm._g({ staticClass: "icon" }, on),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("work")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_c("span", [_vm._v("Total number of jobs logged")])]
                    ),
                    _c("span", { staticClass: "count" }, [
                      _vm._v(_vm._s(_vm.insurerCountStore.totalLoggedJobs)),
                    ]),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "text-xs-center lighten-1",
                    class: _vm.colorClass ? _vm.colorClass : "customColor",
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "i",
                                  _vm._g({ staticClass: "icon" }, on),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("build")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_c("span", [_vm._v("Total number of jobs deployed")])]
                    ),
                    _c("span", { staticClass: "count" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.insurerCountStore.totalDeployedJobs) +
                          " "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "syetsm-dashboard-colum" },
              [
                _c("DashboardChart", {
                  attrs: {
                    "chart-data": _vm.chartData,
                    "chart-type": _vm.chartTypeEnum[_vm.chartTypeEnum.Line],
                  },
                }),
                _vm._l(_vm.panelLines, function (panelLine) {
                  return _c(
                    "DashboardPanelLine",
                    _vm._b(
                      {
                        key: panelLine.title,
                        attrs: {
                          "custom-color": panelLine.hasLightColor
                            ? "customColors"
                            : "",
                        },
                      },
                      "DashboardPanelLine",
                      panelLine,
                      false
                    )
                  )
                }),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }