var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullscreenControl && !_vm.showFullScreenMap,
              expression: "showFullscreenControl && !showFullScreenMap",
            },
          ],
          ref: "fullScreenIcon",
          staticClass: "fullScreenIcon",
          class: _vm.showFullScreenMap ? "fullScreenIconFixedStyle" : "",
          on: { click: _vm.toogleFullScreenMap },
        },
        [_vm._v(" fullscreen ")]
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullscreenControl && _vm.showFullScreenMap,
              expression: "showFullscreenControl && showFullScreenMap",
            },
          ],
          ref: "fullScreenIcon",
          staticClass: "fullScreenIcon",
          class: _vm.showFullScreenMap ? "fullScreenIconFixedStyle" : "",
          on: { click: _vm.toogleFullScreenMap },
        },
        [_vm._v(" fullscreen_exit ")]
      ),
      _c("div", {
        ref: "mapView",
        staticClass: "google-map",
        attrs: { id: _vm.mapId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }