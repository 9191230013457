var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getCustomerAppointedTradespeople
    ? _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          !_vm.getCustomerAppointedTradespeople.invoiceImageUrl
            ? _c("v-flex", { attrs: { xs12: "" } }, [
                _c("b", { staticClass: "red--text" }, [
                  _vm._v("Awaiting Customer's Invoice"),
                ]),
              ])
            : _c("v-flex", { attrs: { xs12: "" } }, [
                _c("b", { staticClass: "green--text" }, [
                  _vm._v("Customer's Invoice Uploaded"),
                ]),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }