var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "div",
        [
          _vm._m(0),
          _vm.datumName
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Name: ")]),
                _c("span", [_vm._v(_vm._s(_vm.datumName))]),
              ])
            : _vm._e(),
          _vm.item.status
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Status: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.status))]),
              ])
            : _vm._e(),
          _vm.item.datumControlRunLogs.length > 0
            ? [
                _c("v-divider", { staticClass: "mb-1" }),
                _vm._l(
                  _vm.item.datumControlRunLogs,
                  function (datumControlRunItem, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        datumControlRunItem.pitId
                          ? _c("div", { staticClass: "mb-1" }, [
                              _c("label", [_vm._v("Pit Id: ")]),
                              _c("span", [
                                _vm._v(_vm._s(datumControlRunItem.pitId)),
                              ]),
                            ])
                          : _vm._e(),
                        datumControlRunItem.note
                          ? _c("div", { staticClass: "mb-1" }, [
                              _c("label", [_vm._v("Notes: ")]),
                              _c("span", [
                                _vm._v(_vm._s(datumControlRunItem.note)),
                              ]),
                            ])
                          : _vm._e(),
                        index !== _vm.item.datumControlRunLogs.length - 1
                          ? _c("v-divider", { staticClass: "mb-1" })
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update Datum Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }