import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { AxiosResponse } from 'axios'

export default class UndeployedEmergenciesController {
  public static async ClearUndeployedEmergencies(jobId: string) {
    const res: AxiosResponse = await CallCentreApi.post(`undeployed-emergencies/${jobId}/clear-all`)

    return res.status === 204
  }
}
