import { render, staticRenderFns } from "./CTPTable.vue?vue&type=template&id=4dbbcf19&scoped=true"
import script from "./CTPTable.vue?vue&type=script&lang=ts"
export * from "./CTPTable.vue?vue&type=script&lang=ts"
import style0 from "./CTPTable.vue?vue&type=style&index=0&id=4dbbcf19&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dbbcf19",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dbbcf19')) {
      api.createRecord('4dbbcf19', component.options)
    } else {
      api.reload('4dbbcf19', component.options)
    }
    module.hot.accept("./CTPTable.vue?vue&type=template&id=4dbbcf19&scoped=true", function () {
      api.rerender('4dbbcf19', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tables/CTPTable.vue"
export default component.exports