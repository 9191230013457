var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullscreenControl && !_vm.showFullScreenMap,
              expression: "showFullscreenControl && !showFullScreenMap",
            },
          ],
          ref: "fullScreenIcon",
          staticClass: "fullScreenIcon",
          class: _vm.showFullScreenMap ? "fullScreenIconFixedStyle" : "",
          on: { click: _vm.toggleFullScreenMap },
        },
        [_vm._v(" fullscreen ")]
      ),
      _c(
        "v-icon",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFullscreenControl && _vm.showFullScreenMap,
              expression: "showFullscreenControl && showFullScreenMap",
            },
          ],
          ref: "fullScreenIcon",
          staticClass: "fullScreenIcon",
          class: _vm.showFullScreenMap ? "fullScreenIconFixedStyle" : "",
          on: { click: _vm.toggleFullScreenMap },
        },
        [_vm._v(" fullscreen_exit ")]
      ),
      _vm.showStreetView
        ? _c("div", {
            ref: "mapView",
            staticClass: "google-map-street-view",
            attrs: { id: _vm.mapId },
          })
        : _c("div", { staticClass: "map-view" }, [
            _vm.mapLoaded && _vm.showTravelMode && !_vm.showFullScreenMap
              ? _c(
                  "div",
                  { staticClass: "map-card-direction" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass:
                          "container__map-card container__map-card-direction",
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              {
                                staticClass: "map-icons pa-2",
                                attrs: { xs12: "" },
                                model: {
                                  value: _vm.travelMode,
                                  callback: function ($$v) {
                                    _vm.travelMode = $$v
                                  },
                                  expression: "travelMode",
                                },
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "", fixed: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    class:
                                                      _vm.travelMode ===
                                                      "DRIVING"
                                                        ? "active"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onTravelModeChanged(
                                                          "DRIVING"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" directions_car ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3503919287
                                    ),
                                  },
                                  [_c("span", [_vm._v("Driving")])]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    class:
                                                      _vm.travelMode ===
                                                      "TRANSIT"
                                                        ? "active"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onTravelModeChanged(
                                                          "TRANSIT"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" train ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2545732366
                                    ),
                                  },
                                  [_c("span", [_vm._v("Transit")])]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    class:
                                                      _vm.travelMode ===
                                                      "WALKING"
                                                        ? "active"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onTravelModeChanged(
                                                          "WALKING"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" directions_walk ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1067251382
                                    ),
                                  },
                                  [_c("span", [_vm._v("Walking")])]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    class:
                                                      _vm.travelMode ===
                                                      "BICYCLING"
                                                        ? "active"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onTravelModeChanged(
                                                          "BICYCLING"
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" directions_bike ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      824512674
                                    ),
                                  },
                                  [_c("span", [_vm._v("Bicycling")])]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "pa-3" },
                              [
                                _vm.directionNotFound
                                  ? _c(
                                      "v-flex",
                                      [
                                        _c("v-flex", { attrs: { xs12: "" } }, [
                                          _c("div", { staticClass: "left" }, [
                                            _vm._v(_vm._s(_vm.errorMessage)),
                                          ]),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "v-flex",
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { wrap: "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left" },
                                                  [_vm._v("Distance:")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs9: "" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "map-card-direction-value",
                                                  },
                                                  [_vm._v(_vm._s(_vm.distance))]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs3: "" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left" },
                                                  [_vm._v("Duration:")]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-flex",
                                              { attrs: { xs9: "" } },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "left" },
                                                  [_vm._v(_vm._s(_vm.duration))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showPlaceCard
              ? _c("div", { staticClass: "map-container" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.showFullScreenMap,
                          expression: "!showFullScreenMap",
                        },
                      ],
                      staticClass: "placeDiv",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "container__map-card placecard__container",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "placecard__left" },
                            [
                              _c(
                                "p",
                                { staticClass: "placecard__business-name" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.sourceAddress !== "" &&
                                          _vm.sourceAddress !== null &&
                                          _vm.sourceAddress !== undefined
                                          ? _vm.sourceAddress
                                          : _vm.formattedAddress
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("p", { staticClass: "placecard__info" }, [
                                _vm._v(_vm._s(_vm.formattedAddress)),
                              ]),
                              _vm.totalRatings > 0
                                ? _c("star-rating", {
                                    attrs: {
                                      "star-size": 13,
                                      rating: _vm.totalRatings,
                                      "read-only": true,
                                      increment: 0.01,
                                      "active-color": "#e7711b",
                                    },
                                  })
                                : _vm._e(),
                              _c(
                                "a",
                                {
                                  staticClass: "placecard__view-large",
                                  attrs: {
                                    target: "_blank",
                                    href: _vm.viewLargerMapUrl,
                                  },
                                },
                                [_vm._v("View larger map")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "placecard__right" }, [
                            _c(
                              "a",
                              {
                                staticClass: "placecard__direction-link",
                                attrs: {
                                  target: "_blank",
                                  href: _vm.viewDirectionLinkUrl,
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("directions")]),
                                _vm._v(" Directions "),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", {
              ref: "mapView",
              staticClass: "google-map",
              attrs: { id: _vm.mapId },
            }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }