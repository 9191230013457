var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-autocomplete", {
    directives: [
      {
        name: "validate",
        rawName: "v-validate",
        value: "required",
        expression: "'required'",
      },
    ],
    staticClass: "required client-list",
    attrs: {
      label: "Search client",
      required: "",
      items: _vm.clients,
      loading: _vm.loading,
      "item-text": "name",
      "item-value": "id",
      disabled: _vm.loading,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (data) {
          return [
            _c(
              "v-list-tile-content",
              [
                _c(
                  "v-list-tile-title",
                  { class: { "deleted-item": data.item.isDelete } },
                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedClientId,
      callback: function ($$v) {
        _vm.selectedClientId = $$v
      },
      expression: "selectedClientId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }