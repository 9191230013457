var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getOtherFeesDetail
    ? _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { "mt-1": "", xs12: "" } }, [
            _c("span", [
              _vm._v(" BER Amount: "),
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.getFormatedCurrency(_vm.getOtherFeesDetail.berAmount)
                  )
                ),
              ]),
            ]),
          ]),
          _c("v-flex", { attrs: { "mt-1": "", xs12: "" } }, [
            _c("span", [
              _vm._v(" Alternative Accommodation Amount: "),
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormatedCurrency(
                        _vm.getOtherFeesDetail.alternativeAccommodationAmount
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("v-flex", { attrs: { "mt-1": "", xs12: "" } }, [
            _c("span", [
              _vm._v(" Temporary Heaters Amount: "),
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormatedCurrency(
                        _vm.getOtherFeesDetail.temporaryHeatersAmount
                      )
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }