var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-card",
        { staticClass: "white elevation-0" },
        [
          _vm.getWaterMainTest && _vm.getWaterMainTest.waterTests.length > 0
            ? _c(
                "div",
                [
                  _vm._l(_vm.getWaterMainTest.waterTests, function (waterTest) {
                    return _c(
                      "v-card-text",
                      {
                        key: waterTest.testNumber,
                        staticClass: "v-card__text",
                      },
                      [
                        _c(
                          "v-card",
                          { staticClass: "elevation-0 mb-2 lighten-4 grey" },
                          [
                            _c(
                              "v-card-title",
                              { staticClass: "justify-end py-2" },
                              [
                                _c("v-spacer"),
                                !_vm.isWaterTestAbandoned
                                  ? _c(
                                      "div",
                                      { staticClass: "action-btn py-2" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "ma-0 close-icon btn-editWaterTest",
                                            attrs: {
                                              flat: "",
                                              icon: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onEditWaterTest(
                                                  waterTest
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "ma-0 close-icon btn-deleteWaterTest",
                                            attrs: {
                                              flat: "",
                                              icon: "",
                                              color: "secondary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteWaterTestRecord(
                                                  waterTest.testNumber
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "layer-card" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [_vm._v("Test Number:")]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(waterTest.testNumber)),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [_vm._v("Start Time:")]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getFormattedDate(
                                                  waterTest.startTime
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [
                                            _vm._v("Pressure At 0 Minutes:"),
                                          ]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                waterTest.pressureAtZeroMinutes
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [
                                            _vm._v("Pressure At 5 Minutes:"),
                                          ]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                waterTest.pressureAtFiveMinutes
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [
                                            _vm._v("Pressure At 10 Minutes:"),
                                          ]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            _vm._s(
                                              waterTest.pressureAtTenMinutes
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "", "mb-1": "" } },
                                      [
                                        _c("label", [
                                          _c("b", [_vm._v("Test Notes:")]),
                                        ]),
                                        _c("span", { staticClass: "ml-1" }, [
                                          _vm._v(_vm._s(waterTest.testNote)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-flex",
                    { attrs: { wrap: "", "pa-3": "" } },
                    [
                      _c("h4", { staticClass: "pb-1" }, [
                        _vm._v("Listening Stick Water Mains Test"),
                      ]),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            label:
                                              "Period listening test carried out",
                                            type: "number",
                                            name: "periodListeningTest",
                                          },
                                          on: {
                                            keypress: function ($event) {
                                              return _vm.numberKeyValidation(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.getWaterMainTest
                                                .periodListeningTest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.getWaterMainTest,
                                                "periodListeningTest",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "getWaterMainTest.periodListeningTest",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            214910853
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " Period listening test carried out with all available water services within property switched off "
                            ),
                          ]),
                        ]
                      ),
                      _c("v-checkbox", {
                        staticClass: "isLeakDetected",
                        attrs: {
                          label:
                            "Any indication of leak detected by way of sound with stick placed on stop cock",
                          color: "primary",
                        },
                        model: {
                          value: _vm.getWaterMainTest.isLeakDetected,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getWaterMainTest,
                              "isLeakDetected",
                              $$v
                            )
                          },
                          expression: "getWaterMainTest.isLeakDetected",
                        },
                      }),
                      _c("v-checkbox", {
                        staticClass: "isNoiseHeardFromListeningStick",
                        attrs: {
                          label:
                            "When taps are used, toilets flushed or any other water demand appliance used,is there a noticeable flushing noise heard from the listening stick",
                          color: "primary",
                        },
                        model: {
                          value:
                            _vm.getWaterMainTest.isNoiseHeardFromListeningStick,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getWaterMainTest,
                              "isNoiseHeardFromListeningStick",
                              $$v
                            )
                          },
                          expression:
                            "getWaterMainTest.isNoiseHeardFromListeningStick",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _c("span", { staticClass: "pa-3" }, [_vm._v("No Data Found")]),
          _vm.getWaterMainTest
            ? _c("v-flex", { attrs: { xs12: "", "pa-3": "" } }, [
                _vm.getWaterMainTest.status ===
                _vm.siJobProductProgress[_vm.siJobProductProgress.Finished]
                  ? _c("div", { staticClass: "grey lighten-4 pa-2" }, [
                      _c("b", { staticClass: "green--text" }, [
                        _vm._v("Water Test Completed"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isWaterTestAbandoned
                  ? _c(
                      "div",
                      { staticClass: "pa-3 abandon-detail grey lighten-4" },
                      [
                        _c("div", { staticClass: "pb-3 red--text" }, [
                          _c("label", [
                            _c("b", [_vm._v("Water Test Abandoned")]),
                          ]),
                        ]),
                        _c("div", { staticClass: "pb-1" }, [
                          _c("label", [_c("b", [_vm._v("Abandon Reason:")])]),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(
                              _vm._s(_vm.getWaterMainTest.abandonedReason)
                            ),
                          ]),
                        ]),
                        _vm.getWaterMainTest.abandonedReasonNotes
                          ? _c("div", [
                              _c("label", [_c("b", [_vm._v("Abandon Note:")])]),
                              _c("span", { staticClass: "ml-1" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getWaterMainTest.abandonedReasonNotes
                                    ) +
                                    " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "text-xs-right" },
                      [
                        _vm.getWaterMainTest.waterTests &&
                        _vm.getWaterMainTest.waterTests.length > 0
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mb-3 mr-0 btn-saveWaterTest",
                                attrs: {
                                  color: "primary",
                                  loading: _vm.loading.saveWaterMainTest,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveWaterMainTest(false)
                                  },
                                },
                              },
                              [_vm._v(" Save ")]
                            )
                          : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mb-3 mr-0 btn-addWaterTest",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.openWaterTestDialog()
                              },
                            },
                          },
                          [_vm._v("Add Test")]
                        ),
                        _vm.getWaterMainTest.status !==
                          _vm.siJobProductProgress[
                            _vm.siJobProductProgress.Finished
                          ] &&
                        _vm.getWaterMainTest.waterTests &&
                        _vm.getWaterMainTest.waterTests.length > 0
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "mb-3 mr-0 btn-completeWaterTest",
                                attrs: {
                                  color: "success",
                                  loading: _vm.loading.completeWaterMainTest,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveWaterMainTest(true)
                                  },
                                },
                              },
                              [_vm._v(" Complete ")]
                            )
                          : _vm._e(),
                        !_vm.isWaterTestAbandoned
                          ? _c(
                              "v-btn",
                              {
                                staticClass:
                                  "mb-3 mr-0 white--text btn-abandonWaterMainsTest",
                                attrs: { color: "red" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.openAbandonReasonDialog()
                                  },
                                },
                              },
                              [_vm._v(" Abandon ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.waterTestDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "850",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.waterTestDialog,
                callback: function ($$v) {
                  _vm.waterTestDialog = $$v
                },
                expression: "waterTestDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.isAddWaterTest ? "Add" : "Edit") +
                            " Water Test " +
                            _vm._s(
                              _vm.isAddWaterTest
                                ? ""
                                : " - " + [_vm.waterTestModel.testNumber]
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.discardWaterTestConfirmation },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog pa-3" },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Pressure At 0 Minutes",
                                      type: "number",
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.numberKeyValidation($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.waterTestModel
                                          .pressureAtZeroMinutes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.waterTestModel,
                                          "pressureAtZeroMinutes",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "waterTestModel.pressureAtZeroMinutes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Pressure At 5 Minutes",
                                      type: "number",
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.numberKeyValidation($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.waterTestModel
                                          .pressureAtFiveMinutes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.waterTestModel,
                                          "pressureAtFiveMinutes",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "waterTestModel.pressureAtFiveMinutes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Pressure At 10 Minutes",
                                      type: "number",
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.numberKeyValidation($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.waterTestModel.pressureAtTenMinutes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.waterTestModel,
                                          "pressureAtTenMinutes",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "waterTestModel.pressureAtTenMinutes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("DateTimePicker", {
                                    ref: "dateTimePicker",
                                    attrs: {
                                      "date-time": _vm.getStartTime,
                                      "is-static-location": false,
                                      "place-holder-text": "Start Time",
                                      "is-current-time": true,
                                      "is-validation-required": true,
                                      "show-present-time-icon": true,
                                      "data-vv-scope": "waterTestScope",
                                    },
                                    on: {
                                      "update:dateTime": function ($event) {
                                        _vm.getStartTime = $event
                                      },
                                      "update:date-time": function ($event) {
                                        _vm.getStartTime = $event
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "py-0": "" } },
                                [
                                  _c("v-textarea", {
                                    attrs: { label: "Test Notes" },
                                    model: {
                                      value: _vm.waterTestModel.testNote,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.waterTestModel,
                                          "testNote",
                                          $$v
                                        )
                                      },
                                      expression: "waterTestModel.testNote",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-closeDialog",
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.discardWaterTestConfirmation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 btn-waterTestSave",
                          attrs: {
                            color: "primary",
                            loading: _vm.loading.waterMainsLogs,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSaveWaterTestRecord()
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAbandonReasonDialog
        ? _c("AbandonSiteDataDialog", {
            ref: "abandonSiteDataDialog",
            attrs: {
              "record-type": _vm.abandonRecordType,
              "abandon-reason-details": _vm.abandonReasonDetails,
            },
            on: {
              abandonReasonSave: _vm.onAbandonReasonSave,
              closeAbandonReasonDialog: function ($event) {
                _vm.showAbandonReasonDialog = false
              },
            },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }