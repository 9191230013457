var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "650",
        persistent: "",
        "content-class": "v-dialog--scrollable",
      },
      model: {
        value: _vm.clearEtaDialogVisible,
        callback: function ($$v) {
          _vm.clearEtaDialogVisible = $$v
        },
        expression: "clearEtaDialogVisible",
      },
    },
    [
      _vm.job
        ? _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _vm.isNoCallButtonClicked
                    ? _c("v-toolbar-title", [_vm._v("Clear ETA Note")])
                    : _c("v-toolbar-title", [_vm._v("Call Customer")]),
                  _c("v-spacer"),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "scroll-content-dialog px-4 pt-4" },
                [
                  _vm.isNoCallButtonClicked
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            ref: "noCallReasonRef",
                            staticClass: "required",
                            attrs: { label: "Reason", rows: "5" },
                            model: {
                              value: _vm.noCallReason,
                              callback: function ($$v) {
                                _vm.noCallReason = $$v
                              },
                              expression: "noCallReason",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", [
                            _c("span", { staticClass: "primary--text" }, [
                              _vm._v(_vm._s(_vm.job.clientFullName)),
                            ]),
                            _vm.job.mobilePhone &&
                            _vm.job.mobilePhone.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "my-2" },
                                  [
                                    _c(
                                      "v-icon",
                                      { staticClass: "pink--text" },
                                      [_vm._v("local_phone")]
                                    ),
                                    _c("span", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "grey--text text--darken-2",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "number",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onCallCustomer(
                                                    _vm.job.mobilePhone
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.job.mobilePhone) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "my-2" }, [
                                  _c("span", [
                                    _vm._v("No number available for customer."),
                                  ]),
                                ]),
                            _c("span", { staticClass: "red--text" }, [
                              _vm._v(
                                " If no call to the customer is required, you will be asked to leave a note on the next page. "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-4" },
                [
                  _c("v-spacer"),
                  _vm.job.mobilePhone &&
                  _vm.job.mobilePhone.length > 0 &&
                  !_vm.isNoCallButtonClicked
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeClearEtaDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0 assignedContactor-btn",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.isNoCallButtonClicked
                            ? _vm.saveNoCallReason()
                            : _vm.noCallSelected()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isNoCallButtonClicked ? "Submit" : "No Call"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }