var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "grey lighten-3 pt-2 px-3 pb-2", attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { xs12: "", "my-1": "" } }, [
        _c("h4", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _c(
        "v-flex",
        { staticClass: "trade-content", attrs: { xs12: "" } },
        [
          _c(
            "div",
            { staticClass: "py-2 multiule-trade-filed add-remove-btn" },
            [
              _vm._l(_vm.getQestions, function (item, index) {
                return _c(
                  "v-layout",
                  {
                    key: index,
                    staticClass: "section-repeat",
                    attrs: {
                      wrap: "",
                      grey: "",
                      "lighten-4": "",
                      "px-3": "",
                      "mb-3": "",
                    },
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs6: "", "pr-2": "" } },
                      [
                        _c("v-select", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            items: _vm.getOperatorsByAnswerType,
                            label: "Operator",
                            "item-text": "name",
                            "item-value": "id",
                            required: "",
                            "data-vv-name": "OperatorId" + index,
                            "error-messages": _vm.validationMessage(
                              "OperatorId" + index
                            ),
                          },
                          on: { blur: _vm.onQuestionChange },
                          model: {
                            value: item.operatorId,
                            callback: function ($$v) {
                              _vm.$set(item, "operatorId", $$v)
                            },
                            expression: "item.operatorId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs6: "", "pl-2": "" } },
                      [
                        _vm.getEmergencyQuestionModel.answerTypeId ===
                          _vm.answerType.Text ||
                        _vm.getEmergencyQuestionModel.answerTypeId ===
                          _vm.answerType.Number
                          ? _c("v-text-field", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:200",
                                  expression: "'required|max:200'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                label: "Comparison Value",
                                maxlength: "200",
                                "data-vv-name": "ComparisonValue" + index,
                                "error-messages": _vm.validationMessage(
                                  "ComparisonValue" + index
                                ),
                                required: "",
                              },
                              on: { blur: _vm.onQuestionChange },
                              model: {
                                value: item.comparisonValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "comparisonValue",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.comparisonValue",
                              },
                            })
                          : _vm._e(),
                        _vm.getEmergencyQuestionModel.answerTypeId ===
                        _vm.answerType.Dropdown
                          ? _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:200",
                                  expression: "'required|max:200'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                items: _vm.getdropdownOptions(
                                  _vm.getEmergencyQuestionModel.additionalValues
                                ),
                                label: "Comparison Value",
                                maxlength: "200",
                                "data-vv-name": "ComparisonValue" + index,
                                "error-messages": _vm.validationMessage(
                                  "ComparisonValue" + index
                                ),
                                required: "",
                              },
                              on: { blur: _vm.onQuestionChange },
                              model: {
                                value: item.comparisonValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "comparisonValue",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.comparisonValue",
                              },
                            })
                          : _vm._e(),
                        _vm.getEmergencyQuestionModel.answerTypeId ===
                        _vm.answerType.Checkbox
                          ? _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required|max:200",
                                  expression: "'required|max:200'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                items: _vm.getcheckboxOptions,
                                label: "Comparison Value",
                                maxlength: "200",
                                "data-vv-name": "ComparisonValue" + index,
                                "error-messages": _vm.validationMessage(
                                  "ComparisonValue" + index
                                ),
                                required: "",
                              },
                              on: { blur: _vm.onQuestionChange },
                              model: {
                                value: item.comparisonValue,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item,
                                    "comparisonValue",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.comparisonValue",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _vm.emergencyAnswerComparisonFor == "Trade"
                          ? _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                items: _vm.getTrades,
                                label: "Trade",
                                "item-value": "tradeId",
                                "item-text": "description",
                                required: "",
                                "data-vv-name": "Trade" + index,
                                "error-messages": _vm.validateTradeList(
                                  "Trade" + index,
                                  item
                                ),
                              },
                              on: { blur: _vm.onQuestionChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", {
                                                  class: _vm.getClass(
                                                    data.item.tradeId
                                                  ),
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.description
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: item.tradeId,
                                callback: function ($$v) {
                                  _vm.$set(item, "tradeId", $$v)
                                },
                                expression: "item.tradeId",
                              },
                            })
                          : _vm.emergencyAnswerComparisonFor == "Asbestos"
                          ? _c("v-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "required",
                              attrs: {
                                items: _vm.getAsbestosEffects,
                                label: "Asbestos",
                                "item-value": "id",
                                "item-text": "effect",
                                required: "",
                                "data-vv-name": "Asbestos" + index,
                                "error-messages": _vm.validationMessage(
                                  "Asbestos" + index
                                ),
                              },
                              on: { blur: _vm.onQuestionChange },
                              model: {
                                value: item.effectLevel,
                                callback: function ($$v) {
                                  _vm.$set(item, "effectLevel", $$v)
                                },
                                expression: "item.effectLevel",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "trade-remove ma-0 elevation-3",
                        attrs: { icon: "", small: "", color: "white" },
                        on: {
                          click: function ($event) {
                            return _vm.removeQuestion(item)
                          },
                        },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "secondary", small: "" } },
                          [_vm._v("delete")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "v-tooltip",
                {
                  staticClass: "trade-add",
                  attrs: { top: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ma-0",
                                attrs: {
                                  icon: "",
                                  flat: "",
                                  color: "secondary",
                                },
                                on: { click: _vm.addQuestion },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("add")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v(_vm._s(_vm.addButtonTooltipText))])]
              ),
            ],
            2
          ),
          _vm.hasDuplicateQuestionAsbestos || _vm.hasDuplicateQuestionTrade
            ? [
                _c("span", { staticClass: "red--text erorr-msg" }, [
                  _vm._v(" " + _vm._s(_vm.duplicateQuestionErrorMessage) + " "),
                ]),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }