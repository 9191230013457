var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 grid-list-md", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      color: "primary",
                      label: "Are you Gas Safe Registered?",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeGasSafeRegistered($event)
                      },
                    },
                    model: {
                      value: _vm.isGasSafeRegistered,
                      callback: function ($$v) {
                        _vm.isGasSafeRegistered = $$v
                      },
                      expression: "isGasSafeRegistered",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs4: "", "pt-2": "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: _vm.getValidationRule,
                                expression: "getValidationRule",
                              },
                            ],
                            class: _vm.isGasSafeRegistered ? "required" : "",
                            attrs: {
                              disabled: !_vm.isGasSafeRegistered,
                              label: "Gas Safe Registration Number",
                              "data-vv-scope": _vm.formRef,
                              "data-vv-name": "Registration Number",
                              "error-messages": _vm.errors.collect(
                                "Registration Number"
                              ),
                            },
                            model: {
                              value: _vm.gasSafeRegistrationNumber,
                              callback: function ($$v) {
                                _vm.gasSafeRegistrationNumber = $$v
                              },
                              expression: "gasSafeRegistrationNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs8: "" } },
                        [
                          _c("FileUpload", {
                            ref: "refGasSafetyCertificate",
                            class: _vm.isGasSafeRegistered ? "required" : "",
                            attrs: {
                              id: _vm.contractorId,
                              "is-required-validation": _vm.isGasSafeRegistered,
                              "uploaded-files": _vm.getUploadedFilesFor(
                                "Gas Safety Certificate"
                              ),
                              label: "Upload Gas Safety Certificate",
                              "upload-document-for": "Gas Safety Certificate",
                              "is-disabled": !_vm.isGasSafeRegistered,
                              "show-date-time-picker": true,
                            },
                            on: {
                              removeSelectedAssociation:
                                _vm.removeSelectedAssociation,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "", "pr-2": "", "mb-2": "" } },
                [
                  _c("FileUpload", {
                    ref: "refPublicLiabilityInsurance",
                    attrs: {
                      id: _vm.contractorId,
                      "is-required-validation": true,
                      "uploaded-files": _vm.getUploadedFilesFor(
                        "Public Liability Insurance"
                      ),
                      label: "Upload Public Liability Insurance Documents",
                      "upload-document-for": "Public Liability Insurance",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "", "pl-2": "", "mb-2": "" } },
                [
                  _c("FileUpload", {
                    ref: "refEmployersLiabilityInsurance",
                    attrs: {
                      id: _vm.contractorId,
                      "is-required-validation":
                        _vm.contractor &&
                        _vm.contractor.companyType ===
                          _vm.CompanyType[_vm.CompanyType.SoleTrader]
                          ? false
                          : true,
                      "upload-document-for": "Employers Liability Insurance",
                      "uploaded-files": _vm.getUploadedFilesFor(
                        "Employers Liability Insurance"
                      ),
                      label: "Upload Employers Liability Insurance Documents",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.membershipAssociationItems,
                      label: "Select Upload Documents",
                      "item-text": "name",
                      "item-value": "name",
                      multiple: "",
                      chips: "",
                      disabled: _vm.isUserRoleContractor,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.onSelectedAssociationChange()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _c(
                              "v-chip",
                              {
                                key: JSON.stringify(data.item),
                                staticClass: "chip--select-multi",
                                attrs: { selected: data.selected, close: "" },
                                on: {
                                  input: function ($event) {
                                    return _vm.onRemoveSelectedAssociation(
                                      data.item.name
                                    )
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(data.item.name) + " ")]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedAssociation,
                      callback: function ($$v) {
                        _vm.selectedAssociation = $$v
                      },
                      expression: "selectedAssociation",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.selectedAssociation, function (item) {
                return _c(
                  "v-flex",
                  { key: item, attrs: { md12: "", lg6: "", "pr-2": "" } },
                  [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("FileUpload", {
                              ref: "refAssociationDocuments",
                              refInFor: true,
                              attrs: {
                                id: _vm.contractorId,
                                "uploaded-files": _vm.getUploadedFilesFor(item),
                                "is-required-validation": true,
                                label: `Upload ` + item + ` Documents`,
                                "upload-document-for": item,
                                "is-disabled": _vm.isUserRoleContractor,
                                "show-date-time-picker": true,
                              },
                              on: {
                                removeSelectedAssociation:
                                  _vm.removeSelectedAssociation,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("h4", [_vm._v("Number of employees for home emergency")]),
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        wrap: "",
                        grey: "",
                        "lighten-3": "",
                        "px-2": "",
                      },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Direct Employed",
                              "data-vv-scope": _vm.formRef,
                              "data-vv-name": "Direct Employed",
                              "error-messages":
                                _vm.errors.collect("Direct Employed"),
                              required: "",
                            },
                            model: {
                              value: _vm.directEmployedHEWorker,
                              callback: function ($$v) {
                                _vm.directEmployedHEWorker = $$v
                              },
                              expression: "directEmployedHEWorker",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|numeric",
                                expression: "'required|numeric'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Sub Contracted",
                              "data-vv-scope": _vm.formRef,
                              "data-vv-name": "Sub Contracted",
                              "error-messages":
                                _vm.errors.collect("Sub Contracted"),
                              required: "",
                            },
                            model: {
                              value: _vm.subContractedHEWorker,
                              callback: function ($$v) {
                                _vm.subContractedHEWorker = $$v
                              },
                              expression: "subContractedHEWorker",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      "hide-details": "",
                      color: "primary",
                      label: "Are you licensed to carry waste?",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onChangeLicensedToCarryWaste($event)
                      },
                    },
                    model: {
                      value: _vm.hasLicenceToCarryWaste,
                      callback: function ($$v) {
                        _vm.hasLicenceToCarryWaste = $$v
                      },
                      expression: "hasLicenceToCarryWaste",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c("FileUpload", {
                    ref: "refLicensedToCarryWaste",
                    attrs: {
                      id: _vm.contractorId,
                      "is-required-validation": _vm.hasLicenceToCarryWaste,
                      "uploaded-files": _vm.getUploadedFilesFor(
                        "Waste Carries License Certificate"
                      ),
                      label: "Upload Waste Carries License Certificate",
                      "upload-document-for":
                        "Waste Carries License Certificate",
                      "is-disabled": !_vm.hasLicenceToCarryWaste,
                      "show-date-time-picker": true,
                      "is-date-validation-required": false,
                    },
                    on: {
                      removeSelectedAssociation: _vm.removeSelectedAssociation,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "bottom-align" },
        [
          _c("v-spacer"),
          !_vm.isUserRoleContractor
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" save ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.confirmationDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.confirmationDialog,
                callback: function ($$v) {
                  _vm.confirmationDialog = $$v
                },
                expression: "confirmationDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.confirmationDialogMessage))]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            flat: "",
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.onCancelDeleteDocuments },
                        },
                        [_vm._v("No")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            small: "",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.onConfirmDeleteDocuments },
                        },
                        [_vm._v(" Yes ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }