var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-switch", {
        attrs: {
          label: "Auto-Deploying Jobs",
          "hide-details": "",
          color: "primary",
        },
        model: {
          value: _vm.filterAutodeploying,
          callback: function ($$v) {
            _vm.filterAutodeploying = $$v
          },
          expression: "filterAutodeploying",
        },
      }),
      _c(
        "div",
        { staticClass: "search-fields" },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": "",
              clearable: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-autocomplete", {
            attrs: {
              label: "Filter by team",
              maxlength: "50",
              items: _vm.teams,
              "hide-details": "",
            },
            model: {
              value: _vm.team,
              callback: function ($$v) {
                _vm.team = $$v
              },
              expression: "team",
            },
          }),
        ],
        1
      ),
      _c("h3", { staticClass: "font-weight-medium" }, [_vm._v("Trades")]),
      _c(
        "div",
        { staticClass: "filters my-2 gap-2" },
        _vm._l(_vm.trades, function ([name, count]) {
          return _c("label", { key: name, staticClass: "filter" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.trade,
                  expression: "trade",
                },
              ],
              staticClass: "sr-only",
              attrs: { type: "radio", name: name },
              domProps: { value: name, checked: _vm._q(_vm.trade, name) },
              on: {
                change: function ($event) {
                  _vm.trade = name
                },
              },
            }),
            _vm._v(" " + _vm._s(name) + " "),
            _c("span", { staticClass: "count" }, [_vm._v(_vm._s(count))]),
          ])
        }),
        0
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 table no-wrap",
        attrs: {
          headers: _vm.headers,
          items: _vm.filtered,
          pagination: _vm.pagination,
          search: _vm.search,
          "custom-filter": _vm.searchFilter,
          "custom-sort": _vm.sort,
          "must-sort": "",
        },
        on: {
          "update:pagination": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function ({ item: { job, emergency, index, total } }) {
              return [
                _c("td", {}, [
                  _c(
                    "a",
                    {
                      staticClass: "font-weight-bold",
                      on: {
                        click: function ($event) {
                          return _vm.openJobView(job.jobId)
                        },
                      },
                    },
                    [_vm._v(_vm._s(job.jobId))]
                  ),
                  _vm._v("   "),
                  _c("span", { staticClass: "text--secondary" }, [
                    _vm._v(
                      _vm._s(total > 1 ? `(${index + 1} of ${total})` : "")
                    ),
                  ]),
                ]),
                _c("td", [_vm._v(_vm._s(emergency.vulnerabilityLevel || "-"))]),
                _c("td", [_vm._v(_vm._s(job.policyNumber))]),
                _c("td", [_vm._v(_vm._s(job.name))]),
                _c("td", [_vm._v(_vm._s(job.loggedByTeamName))]),
                _c("td", [_vm._v(_vm._s(job.address.postCode))]),
                _c("td", [_vm._v(_vm._s(job.address.addressLine3))]),
                _c("td", [_vm._v(_vm._s(emergency.tradesDescription))]),
                _c("td", { staticClass: "text-xs-center" }, [
                  emergency.isAutoDeploymentRunning
                    ? _c("span", [
                        _c("span", { staticClass: "blink_text" }, [
                          _vm._v("Auto Deploying"),
                        ]),
                        _vm._v(
                          " (" + _vm._s(emergency.deploymentAttempt) + ") "
                        ),
                      ])
                    : _c("span", [_vm._v(_vm._s(emergency.deploymentAttempt))]),
                ]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      emergency.loggedAtUtc
                        ? _vm.formatDateTime(emergency.loggedAtUtc)
                        : _vm.formatDateTime(job.createdAtUtc)
                    )
                  ),
                ]),
                _c("td", [_vm._v(_vm._s(job.escalationReason))]),
                _c("td", [_vm._v(_vm._s(job.lastVisitByUserName))]),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      job.lastVisitUtc
                        ? _vm.formatDateTime(job.lastVisitUtc)
                        : null
                    )
                  ),
                ]),
                _c(
                  "td",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        staticClass:
                                          "mx-0 mt-2 ml-2 deactivate-btn",
                                        attrs: {
                                          icon: "",
                                          disabled:
                                            _vm.isUserRoleSystemAdministrator,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeUDE(job.jobId)
                                          },
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [_c("span", [_vm._v("Delete")])]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      !_vm.expandedJob
        ? _c("Notifications", { ref: "notifications" })
        : _vm._e(),
      _c("PartialJobView", {
        attrs: { "job-id": _vm.expandedJob },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }