var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Job completion email sent on: ")]),
      _c("span", [_vm._v(_vm._s(_vm.helplineEmail))]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Complete Helpline Job")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }