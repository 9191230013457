var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "user" },
    [
      _c("EditUserDialog"),
      _c(
        "div",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 mb-2", attrs: { "primary-title": "" } },
            [
              _c("h3", { staticClass: "mb-0" }, [_vm._v("Users")]),
              _c("v-spacer"),
              _c("v-switch", {
                staticClass: "show-deleted",
                attrs: {
                  color: "primary",
                  small: "",
                  label: "Show Deleted Users",
                },
                model: {
                  value: _vm.showDeletedUsers,
                  callback: function ($$v) {
                    _vm.showDeletedUsers = $$v
                  },
                  expression: "showDeletedUsers",
                },
              }),
              _c("v-text-field", {
                staticClass: "mt-0 pt-0",
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "search",
                },
              }),
              _vm.canCreateUser
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mx-0 add-action-btn",
                                      attrs: { icon: "" },
                                      on: { click: _vm.createUser },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { color: "grey" } }, [
                                      _vm._v("add"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1950805996
                      ),
                    },
                    [_c("span", [_vm._v("Add User")])]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("v-data-table", {
                staticClass: "user-list grid-view",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.users,
                  loading: _vm.loading,
                  search: _vm.search,
                  pagination: _vm.pagination,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.pagination = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c(
                          "tr",
                          {
                            class: props.item.isActive
                              ? ""
                              : "deactivated-user",
                          },
                          [
                            _c("td", { staticClass: "team-member-img" }, [
                              _c("img", {
                                attrs: {
                                  src:
                                    props.item.profileImageThumbnail ||
                                    "/img/unknownuser.png",
                                  alt: "",
                                },
                              }),
                            ]),
                            _c("td", [_vm._v(_vm._s(props.item.name))]),
                            _c("td", [_vm._v(_vm._s(props.item.email))]),
                            _c("td", [_vm._v(_vm._s(props.item.roleName))]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.canAcceptEmergencies ? "Yes" : "No"
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.canAcceptPayments ? "Yes" : "No"
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.worksForThirdParty ? "Yes" : "No"
                                )
                              ),
                            ]),
                            _c(
                              "td",
                              [
                                _c("star-rating", {
                                  attrs: {
                                    "star-size": 20,
                                    rating: props.item.averageSkill,
                                    "max-rating": 5,
                                    "active-color": "#e7711b",
                                    increment: 0.1,
                                    "fixed-points": 1,
                                    "read-only": true,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "td",
                              { staticClass: "justify-center layout px-0" },
                              [
                                _vm.canUpdateUser(props.item)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        staticClass: "update-user",
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass: "mx-0",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.updateUser(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [_vm._v("edit")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Edit")])]
                                    )
                                  : _vm._e(),
                                _vm.canDeleteUser(props.item)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "mx-0 mt-2 ml-2 deactivate-btn",
                                                        attrs: { icon: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteUser(
                                                              props.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [_vm._v("delete")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Delete")])]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }