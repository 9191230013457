var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.job.sIJobDetail.jobPackage
    ? _c("span", [
        _vm._v(
          "Total Price: £" + _vm._s(_vm.job.sIJobDetail.jobPackage.totalPrice)
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }