var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("HealthAndSafetyQA", {
        ref: "healthAndSafetyQA",
        attrs: { "health-and-safety-q-a": _vm.vulnerabilityQAItem },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }