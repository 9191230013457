var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pr-2": "", "pl-0": "", "pt-1": "" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-right insurer-search claim-mgmt-company-dropdown",
                  attrs: { wrap: "" },
                },
                [
                  _c("v-autocomplete", {
                    staticClass: "pr-3 claim-management-company-select",
                    attrs: {
                      items: _vm.claimManagementCompanies,
                      "item-text": "description",
                      "item-value": "id",
                      "hide-details": "",
                      label: "Search Claim Management Company",
                      loading: _vm.loading.claimManagementCompanies,
                    },
                    model: {
                      value: _vm.selectedClaimManagementCompany,
                      callback: function ($$v) {
                        _vm.selectedClaimManagementCompany = $$v
                      },
                      expression: "selectedClaimManagementCompany",
                    },
                  }),
                  _c("v-autocomplete", {
                    staticClass: "insurer-select",
                    attrs: {
                      label: "Search Insurer",
                      items: _vm.insurers,
                      loading: _vm.loading.insurers,
                      "item-text": "name",
                      "item-value": "id",
                      "hide-details": "",
                      "menu-props": {
                        maxWidth: "30%",
                        minWidth: "30%",
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-list-tile-content",
                              [
                                _c(
                                  "v-list-tile-title",
                                  {
                                    class: {
                                      "bold-select": data.item.id === -1,
                                      "deleted-item": data.item.isDelete,
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(data.item.name) + " ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedInsurerId,
                      callback: function ($$v) {
                        _vm.selectedInsurerId = $$v
                      },
                      expression: "selectedInsurerId",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "mt-5" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pr-2": "", "pl-0": "", "pt-1": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _vm.insurers.length > 0 &&
                          _vm.claimManagementCompanies.length > 0
                            ? _c("JobFinancialSummaryDashboardSI", {
                                attrs: {
                                  "selected-insurer-id": _vm.selectedInsurerId,
                                  "claims-management-company-sales-ledgers":
                                    _vm.claimsManagementCompanySalesLedgers,
                                  "selected-claim-management-company":
                                    _vm.selectedClaimManagementCompany,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }