var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.contractor
    ? _c(
        "v-card",
        { staticClass: "tradeAppointedPreview" },
        [
          _vm.getAssignedContractor &&
          _vm.getAssignedContractor.status ===
            _vm.jobVisitStatus[_vm.jobVisitStatus.AutoDeploying]
            ? _c(
                "v-container",
                [
                  _c(
                    "v-layout",
                    { attrs: { grey: "", "lighten-4": "", "pa-2": "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "xs12" },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "engineer-map grey lighten-5",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.showFullScreenMap,
                                              expression: "!showFullScreenMap",
                                            },
                                          ],
                                          ref: "fullScreenIcon",
                                          staticClass: "fullScreenIcon",
                                          class: _vm.showFullScreenMap
                                            ? "fullScreenIconFixedStyle"
                                            : "",
                                          on: {
                                            click: _vm.toggleFullScreenMap,
                                          },
                                        },
                                        [_vm._v(" fullscreen ")]
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.showFullScreenMap,
                                              expression: "showFullScreenMap",
                                            },
                                          ],
                                          ref: "fullScreenIcon",
                                          staticClass: "fullScreenIcon",
                                          class: _vm.showFullScreenMap
                                            ? "fullScreenIconFixedStyle"
                                            : "",
                                          on: {
                                            click: _vm.toggleFullScreenMap,
                                          },
                                        },
                                        [_vm._v(" fullscreen_exit ")]
                                      ),
                                      _c("div", {
                                        ref: "mapView",
                                        attrs: { id: "customMap" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-progress-linear", {
                                        attrs: {
                                          active:
                                            _vm.progressBarEngAssignVisibility,
                                          indeterminate:
                                            _vm.progressBarEngAssignIndeterminate,
                                          query: _vm.progressBarEngAssignQuery,
                                          color:
                                            _vm.progressBarEngAssignCurrentColor,
                                        },
                                        model: {
                                          value: _vm.progressBarEngAssignValue,
                                          callback: function ($$v) {
                                            _vm.progressBarEngAssignValue = $$v
                                          },
                                          expression:
                                            "progressBarEngAssignValue",
                                        },
                                      }),
                                      _c("div", { staticClass: "my-2" }, [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.progressEngAssignInfoMessage
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-snackbar",
                                    {
                                      staticClass:
                                        "mt-5 pt-5 pl-5 ml-3 engineer-snackbar",
                                      attrs: {
                                        timeout: 0,
                                        top: "",
                                        color: "error",
                                      },
                                      model: {
                                        value: _vm.autodeployRunningMessage,
                                        callback: function ($$v) {
                                          _vm.autodeployRunningMessage = $$v
                                        },
                                        expression: "autodeployRunningMessage",
                                      },
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          " Auto Deploying " +
                                            _vm._s(
                                              _vm.autoDeploymentRunningTo
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "dot-animation" },
                                          [_c("span"), _c("span"), _c("span")]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-lg": "" } },
                [
                  _vm.getAssignedContractor &&
                  _vm.getAssignedContractor.callRecordingUrl &&
                  _vm.canPlayCall
                    ? _c(
                        "div",
                        { staticClass: "text-xs-right" },
                        [
                          _c(
                            "v-btn",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.getRecordingUrl.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("v-icon", [_vm._v("play_circle_outline")]),
                              _vm._v("  Play Call "),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { row: "", "map-main": "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { md12: "", lg6: "", "pl-0": "" } },
                        [
                          _c("div", { staticClass: "card-image" }, [
                            _c("img", {
                              staticClass: "img-responsive",
                              attrs: {
                                src: _vm.contractor.fileURL
                                  ? _vm.contractor.fileURL
                                  : "/img/unknownuser.png",
                              },
                            }),
                          ]),
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { md12: "", lg6: "", "pr-0": "" } },
                        [
                          _c("div", { staticClass: "card-stacked" }, [
                            _c(
                              "div",
                              { staticClass: "map" },
                              [
                                _c("Map", {
                                  staticStyle: { height: "180px" },
                                  attrs: {
                                    "map-id": new Date().getTime().toString(),
                                    "source-latitude": _vm.contractor.latitude,
                                    "source-longitude":
                                      _vm.contractor.longitude,
                                    "destination-latitude": _vm.jobLatitude,
                                    "destination-longitude": _vm.jobLongitude,
                                    "zoom-level": 5.5,
                                    coverage: _vm.contractor.coverage,
                                    "contractor-availability":
                                      _vm.contractor.contractorAvailability,
                                    "plot-contractor-open-job-addresses":
                                      _vm.contractorOpenJobAddresses,
                                    "show-marker": true,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-title",
                    { staticClass: "pt-3 pb-1 px-0" },
                    [
                      _c(
                        "v-container",
                        {
                          staticClass: "pa-0 mt-1",
                          attrs: { fluid: "", "grid-list-md": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "py-0",
                                  attrs: {
                                    xs12: "",
                                    md8: "",
                                    lg9: "",
                                    xl10: "",
                                  },
                                },
                                [
                                  _c("h3", { staticClass: "mb-0" }, [
                                    _vm._v(_vm._s(_vm.contractor.companyName)),
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "ratings" },
                                    [
                                      _c("star-rating", {
                                        attrs: {
                                          "star-size": 20,
                                          rating: _vm.contractor.rating,
                                          "show-rating": false,
                                          "read-only": true,
                                          increment: 0.01,
                                          "active-color": "orange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "mt-1" }, [
                                    _c("h5", { staticClass: "pb-0" }, [
                                      _vm._v(
                                        _vm._s(_vm.contractor.description)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _vm.getPreferredContractor(_vm.contractor) &&
                              _vm.getPreferredContractor(_vm.contractor) !==
                                "None"
                                ? _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: "",
                                        md4: "",
                                        lg3: "",
                                        xl2: "",
                                        "text-xs-right": "",
                                        "pt-0": "",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "img-responsive badges-img",
                                        attrs: {
                                          src:
                                            "/img/" +
                                            _vm.getPreferredContractor(
                                              _vm.contractor
                                            ) +
                                            "-Badge.png",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { staticClass: "py-0", attrs: { xs12: "" } },
                                [
                                  _c("v-divider", {
                                    staticClass: "mb-2",
                                    attrs: { xs12: "" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.contractor.address,
                                          expression: "contractor.address",
                                        },
                                      ],
                                      staticClass: "location-detail mb-2",
                                    },
                                    [
                                      _c("v-icon", [_vm._v("location_on")]),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.contractor.address)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm.timeSlots.length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "location-detail mb-2" },
                                        [
                                          _c("v-icon", [_vm._v("access_time")]),
                                          _c(
                                            "span",
                                            { staticClass: "available-time" },
                                            [
                                              _vm._v(" Weekdays - "),
                                              _vm._l(
                                                _vm.timeSlots,
                                                function (item, index) {
                                                  return [
                                                    item.slotId > 0
                                                      ? _c(
                                                          "span",
                                                          { key: item.slotId },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  new Date(
                                                                    item.startTime
                                                                  ).getHours()
                                                                ) +
                                                                " to " +
                                                                _vm._s(
                                                                  new Date(
                                                                    item.endTime
                                                                  ).getHours() ===
                                                                    0
                                                                    ? 24
                                                                    : new Date(
                                                                        item.endTime
                                                                      ).getHours()
                                                                ) +
                                                                _vm._s(
                                                                  index ===
                                                                    _vm
                                                                      .timeSlots
                                                                      .length -
                                                                      1
                                                                    ? ""
                                                                    : ","
                                                                ) +
                                                                "  "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.contractor.contactNumbers
                                    ? _vm._l(
                                        _vm.contractor.contactNumbers,
                                        function (contact, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass:
                                                "location-detail mb-2",
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("local_phone"),
                                              ]),
                                              _c("span", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "grey--text text--darken-2",
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass: "number",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onCallContractor(
                                                              contact.phoneNumber,
                                                              _vm.contractor.id
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              contact.phoneNumber
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "caption grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              contact.label
                                                                ? contact.label
                                                                : "Phone number"
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                  _vm.contractor.email
                                    ? _c(
                                        "div",
                                        { staticClass: "location-detail mb-2" },
                                        [
                                          _c("v-icon", [_vm._v("email")]),
                                          _vm.contractor.email
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "available-time",
                                                  attrs: {
                                                    href:
                                                      "mailto:" +
                                                      _vm.contractor.email,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.contractor.email
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.contractor.website,
                                          expression: "contractor.website",
                                        },
                                      ],
                                      staticClass: "location-detail mb-2",
                                    },
                                    [
                                      _c("v-icon", [_vm._v("public")]),
                                      _c("span", [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "website",
                                            attrs: {
                                              href: _vm.contractor.website,
                                              target: "_blank",
                                              title: "Website",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.contractor.website) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.todaySlots.length > 0 &&
                                            !_vm.getAssignedContractor &&
                                            !_vm.isThirdPartyContractor,
                                          expression:
                                            "todaySlots.length > 0 && !getAssignedContractor && !isThirdPartyContractor",
                                        },
                                      ],
                                      staticClass: "location-detail",
                                    },
                                    [
                                      _c("i", [
                                        _c("img", {
                                          staticClass: "img-icon",
                                          attrs: {
                                            src: "/./img/user-time.svg",
                                          },
                                        }),
                                      ]),
                                      _vm.isContractorAvailable()
                                        ? _c(
                                            "span",
                                            { staticClass: "green--text" },
                                            [
                                              _vm._v(
                                                " Contractor is working within customer availability. "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "red--text" },
                                            [
                                              _vm._v(
                                                "Contractor's working time is different from customer availability."
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _vm.emergency.engineerSiteSLA
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grey lighten-4 pa-2 mt-2",
                                        },
                                        [
                                          _c(
                                            "b",
                                            { staticClass: "error--text" },
                                            [
                                              _vm._v(
                                                "SLA cut off time : " +
                                                  _vm._s(_vm.getSLACutOffTime)
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.getAssignedContractor &&
                                  _vm.getAssignedContractor
                                    .additionalTravelTime &&
                                  !_vm.isThirdPartyContractor
                                    ? _c("v-divider", { attrs: { xs12: "" } })
                                    : _vm._e(),
                                  _vm.getAssignedContractor &&
                                  _vm.getAssignedContractor
                                    .additionalTravelTime &&
                                  !_vm.isThirdPartyContractor
                                    ? _c(
                                        "div",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "", "py-1": "" } },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  "Additonal Travel Time:"
                                                ),
                                              ]),
                                              _c(
                                                "b",
                                                {
                                                  staticClass:
                                                    "secondary--text",
                                                },
                                                [
                                                  _vm._v(
                                                    "   " +
                                                      _vm._s(
                                                        _vm.getFormatedTravelTime(
                                                          _vm
                                                            .getAssignedContractor
                                                            .additionalTravelTime
                                                        )
                                                      ) +
                                                      " (£" +
                                                      _vm._s(
                                                        _vm
                                                          .getAssignedContractor
                                                          .additionalCharge
                                                      ) +
                                                      ") "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "job-information pt-1" },
                                    [
                                      _c("v-divider", { attrs: { xs12: "" } }),
                                      _c(
                                        "v-container",
                                        {
                                          staticClass: "grid-list-md px-0 py-2",
                                          attrs: { fluid: "" },
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "h3",
                                                    { staticClass: "title" },
                                                    [_vm._v("Work summary")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grey lighten-4 blue--text fill-height pa-2 job-info",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Total Visits today"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "count",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.contractor
                                                                  .totalVisitsToday
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grey lighten-4 green--text pa-2 job-info open-job",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Total Open Jobs"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "count",
                                                        },
                                                        [
                                                          _vm.getOpenJobs &&
                                                          _vm.getOpenJobs.length
                                                            ? _c(
                                                                "v-btn",
                                                                {
                                                                  staticClass:
                                                                    "ma-0 map-btn",
                                                                  attrs: {
                                                                    icon: "",
                                                                    flat: "",
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.plotContractorOpenJobAddress(
                                                                          _vm
                                                                            .contractor
                                                                            .id
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "grey",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "place"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "v-menu",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                                "offset-y": "",
                                                                "content-class":
                                                                  "job-count-menu",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "span",
                                                                            _vm._g(
                                                                              {},
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.totalOpenJobCount
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm
                                                                                .getOpenJobs
                                                                                .length
                                                                                ? _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "md-icon",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "keyboard_arrow_down"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  false,
                                                                  4268362556
                                                                ),
                                                            },
                                                            [
                                                              _c(
                                                                "v-list",
                                                                [
                                                                  _vm
                                                                    .getOpenJobs
                                                                    .length
                                                                    ? _vm._l(
                                                                        _vm.getOpenJobs,
                                                                        function (
                                                                          openJob,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "v-list-tile",
                                                                            {
                                                                              key: index,
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list-tile-title",
                                                                                {
                                                                                  staticClass:
                                                                                    "body-1",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "img",
                                                                                    {
                                                                                      staticClass:
                                                                                        "emergancy-icon",
                                                                                      attrs:
                                                                                        {
                                                                                          src: openJob.img
                                                                                            ? openJob.img
                                                                                            : "/img/emergency.svg",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        openJob.key
                                                                                      ) +
                                                                                      " (" +
                                                                                      _vm._s(
                                                                                        openJob.value
                                                                                      ) +
                                                                                      ") "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                2
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "grey lighten-4 deep-orange--text fill-height pa-2 job-info",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          "Total engineers"
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "count",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.totalEngineerCount
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm.getEngineers &&
                                                      _vm.getEngineers.length
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "grey--text mt-1",
                                                            },
                                                            [
                                                              _vm.getEngineers
                                                                .length
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.emergencyEngineerType
                                                                        ) +
                                                                        " (" +
                                                                        _vm._s(
                                                                          _vm.emergencyEngineerCount
                                                                        ) +
                                                                        ") "
                                                                    ),
                                                                    _c("br"),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isContractorHasAutoDeployment &&
                                  !_vm.hideAutoDeploymentButton
                                    ? _c(
                                        "div",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c("v-divider", {
                                            staticClass: "mt-3",
                                            attrs: { xs12: "" },
                                          }),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "pa-0": "",
                                                "mt-2": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "location-detail green--text",
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("settings"),
                                                  ]),
                                                  _c("b", [
                                                    _vm._v(
                                                      " Ability to accept auto deployment of jobs " +
                                                        _vm._s(
                                                          _vm.autoDeploymentOnText(
                                                            _vm.item
                                                              .autoDeploymentType
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  (!_vm.isThirdPartyContractor &&
                                    _vm.isContractorUnavailable) ||
                                  _vm.isContractorReAttend ||
                                  _vm.isContractorCancelled
                                    ? _c("v-divider", { attrs: { xs12: "" } })
                                    : _vm._e(),
                                  (!_vm.isThirdPartyContractor &&
                                    _vm.isContractorUnavailable) ||
                                  _vm.isContractorCancelled
                                    ? _c(
                                        "div",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-2" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.isContractorUnavailable
                                                          ? "Contractor Unavailable"
                                                          : "Contractor Rejected"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c("b", [_vm._v("Reason:")]),
                                                  _c("br"),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .getAssignedContractor
                                                          .reasonType
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm.getAssignedContractor
                                                .reasonDescription
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v("Description:"),
                                                    ]),
                                                    _c("br"),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .getAssignedContractor
                                                            .reasonDescription
                                                        )
                                                      ),
                                                    ]),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : !_vm.isThirdPartyContractor &&
                                      _vm.isContractorReAttend
                                    ? _c(
                                        "div",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "h3",
                                                { staticClass: "mb-2" },
                                                [_vm._v("Contractor Re-attend")]
                                              ),
                                              _c("div", [
                                                _c("b", [_vm._v("Reason:")]),
                                                _c("br"),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getAssignedContractor
                                                        .reasonDescription
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showAutoDeploymentButton
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "mt-3",
                                          attrs: { wrap: "" },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "py-0": "",
                                                "text-xs-right": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mr-0 auto-deployment-btn",
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          _vm.manualAutoDeploymentRunning,
                                                        loading:
                                                          _vm.manualAutoDeploymentRunning,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.runManualAutoDeployment()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Auto Deploy " +
                                                          _vm._s(
                                                            _vm.autoDeploymentOnText(
                                                              _vm.item
                                                                .autoDeploymentType
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.showCallContractorButton,
                                          expression:
                                            "showCallContractorButton",
                                        },
                                      ],
                                      key: _vm.activeTileIndex,
                                      staticClass: "btn-block mt-3",
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                "py-0": "",
                                                "text-xs-right": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    left: "",
                                                    "close-on-content-click": false,
                                                    "nudge-width": 320,
                                                    "max-width": 320,
                                                    "z-index": 999,
                                                    fixed: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "callContractor-btn",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.showAllContactNumber = true
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  " Call " +
                                                                    _vm._s(
                                                                      _vm.item
                                                                        .companyName
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4217304292
                                                  ),
                                                },
                                                [
                                                  _c("v-card", [
                                                    _vm.showAllContactNumber
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "view-panel contact-list",
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-tile",
                                                              [
                                                                _c(
                                                                  "v-list-tile-content",
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "name primary--text mb-2",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .contractor
                                                                                .companyName
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "grey--text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "(Contractor)"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm
                                                                      .contractor
                                                                      .contactNumbers
                                                                      ? _vm._l(
                                                                          _vm
                                                                            .contractor
                                                                            .contactNumbers,
                                                                          function (
                                                                            contact,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key: index,
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "grey--text text--darken-2",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "a",
                                                                                      {
                                                                                        staticClass:
                                                                                          "number",
                                                                                        attrs:
                                                                                          {
                                                                                            href: "#",
                                                                                          },
                                                                                        on: {
                                                                                          click:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.onCallButtonClick(
                                                                                                contact.phoneNumber,
                                                                                                _vm
                                                                                                  .contractor
                                                                                                  .id
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              contact.phoneNumber
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "caption grey--text",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " - " +
                                                                                            _vm._s(
                                                                                              contact.label
                                                                                                ? contact.label
                                                                                                : "Phone number"
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn-block",
                                      attrs: { wrap: "" },
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            "py-0": "",
                                            "text-xs-right": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.isCallButtonClicked &&
                                                    _vm.isThirdPartyContractor,
                                                  expression:
                                                    "isCallButtonClicked && isThirdPartyContractor",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-0 warmTransfer-btn",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: _vm.isLoading,
                                                    loading: _vm.isLoading,
                                                  },
                                                  on: {
                                                    click: _vm.assignContractor,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Warm Transfer Complete "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    (!_vm.isContractorAssigned &&
                                                      _vm.isCallButtonClicked &&
                                                      !_vm.isThirdPartyContractor) ||
                                                    (_vm.isCallButtonClicked &&
                                                      _vm.getAssignedContractor &&
                                                      _vm.getAssignedContractor
                                                        .status ===
                                                        _vm.jobVisitStatus[
                                                          _vm.jobVisitStatus
                                                            .Unavailable
                                                        ] &&
                                                      ((_vm.getAssignedContractor &&
                                                        !_vm
                                                          .getAssignedContractor
                                                          .isUnavailable) ||
                                                        _vm.getIsSkillLevelAboveRequired)),
                                                  expression:
                                                    "\n                    (!isContractorAssigned && isCallButtonClicked && !isThirdPartyContractor) ||\n                    (isCallButtonClicked &&\n                      getAssignedContractor &&\n                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Unavailable] &&\n                      ((getAssignedContractor && !getAssignedContractor.isUnavailable) ||\n                        getIsSkillLevelAboveRequired))\n                  ",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-0 assignContractor-btn",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: _vm.assignContractor,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Assign to " +
                                                      _vm._s(
                                                        _vm.item.companyName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-0",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.actionDialog = true),
                                                        (_vm.isUnavailableButtonClicked = true)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.item.companyName
                                                      ) +
                                                      " unavailable "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.isThirdPartyContractor &&
                                                    _vm.isContractorAssigned &&
                                                    (_vm.getAssignedContractor
                                                      .status ===
                                                      _vm.jobVisitStatus[
                                                        _vm.jobVisitStatus
                                                          .Pending
                                                      ] ||
                                                      _vm.getAssignedContractor
                                                        .status ===
                                                        _vm.jobVisitStatus[
                                                          _vm.jobVisitStatus
                                                            .Accepted
                                                        ] ||
                                                      _vm.getAssignedContractor
                                                        .status ===
                                                        _vm.jobVisitStatus[
                                                          _vm.jobVisitStatus
                                                            .InProgress
                                                        ]),
                                                  expression:
                                                    "\n                    !isThirdPartyContractor &&\n                    isContractorAssigned &&\n                    (getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Pending] ||\n                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Accepted] ||\n                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.InProgress])\n                  ",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-0 changeContractorETA-btn",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.changeContractorETA,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Change " +
                                                      _vm._s(
                                                        _vm.item.companyName
                                                      ) +
                                                      " ETA "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.isThirdPartyContractor &&
                                                    _vm.isContractorAssigned &&
                                                    !_vm.isContractorCancelled &&
                                                    !_vm.isContractorUnavailable &&
                                                    !_vm.isSIJob,
                                                  expression:
                                                    "\n                    !isThirdPartyContractor &&\n                    isContractorAssigned &&\n                    !isContractorCancelled &&\n                    !isContractorUnavailable &&\n                    !isSIJob\n                  ",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-0 reAttendContractor-btn",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.reAttendContractor,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Re-Attend " +
                                                      _vm._s(
                                                        _vm.item.companyName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.isThirdPartyContractor &&
                                                    !_vm.isContractorCancelled &&
                                                    !_vm.isContractorUnavailable &&
                                                    _vm.getAssignedContractor &&
                                                    _vm.getAssignedContractor
                                                      .status !==
                                                      _vm.jobVisitStatus[
                                                        _vm.jobVisitStatus
                                                          .InProgress
                                                      ] &&
                                                    _vm.getAssignedContractor
                                                      .status !==
                                                      _vm.jobVisitStatus[
                                                        _vm.jobVisitStatus
                                                          .Completed
                                                      ],
                                                  expression:
                                                    "\n                    !isThirdPartyContractor &&\n                    !isContractorCancelled &&\n                    !isContractorUnavailable &&\n                    getAssignedContractor &&\n                    getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.InProgress] &&\n                    getAssignedContractor.status !== jobVisitStatus[jobVisitStatus.Completed]\n                  ",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-0",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.actionDialog = true),
                                                        (_vm.isCancelContractorButtonClicked = true)
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.isThirdPartyContractor &&
                                                    _vm.isContractorAssigned &&
                                                    _vm.getAssignedContractor
                                                      .visitNumber > 1 &&
                                                    (_vm.getAssignedContractor
                                                      .status ===
                                                      _vm.jobVisitStatus[
                                                        _vm.jobVisitStatus
                                                          .Pending
                                                      ] ||
                                                      _vm.getAssignedContractor
                                                        .status ===
                                                        _vm.jobVisitStatus[
                                                          _vm.jobVisitStatus
                                                            .Accepted
                                                        ] ||
                                                      _vm.getAssignedContractor
                                                        .status ===
                                                        _vm.jobVisitStatus[
                                                          _vm.jobVisitStatus
                                                            .InProgress
                                                        ]),
                                                  expression:
                                                    "\n                    !isThirdPartyContractor &&\n                    isContractorAssigned &&\n                    getAssignedContractor.visitNumber > 1 &&\n                    (getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Pending] ||\n                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.Accepted] ||\n                      getAssignedContractor.status === jobVisitStatus[jobVisitStatus.InProgress])\n                  ",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-0",
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.clearEtaConfirmation,
                                                  },
                                                },
                                                [_vm._v("Clear ETA")]
                                              ),
                                            ],
                                            1
                                          ),
                                          !_vm.isThirdPartyContractor &&
                                          _vm.isContractorAssigned &&
                                          _vm.getAssignedContractor.status ===
                                            _vm.jobVisitStatus[
                                              _vm.jobVisitStatus.Pending
                                            ] &&
                                          !_vm.isSIJob
                                            ? _c(
                                                "span",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-0",
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          _vm.loaderOnGettingEngineerList,
                                                        loading:
                                                          _vm.loaderOnGettingEngineerList,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openEngineerAssignDialog.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Assign Engineer "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-dialog",
                                    {
                                      attrs: {
                                        "max-width": "650",
                                        persistent: "",
                                        "content-class": "v-dialog--scrollable",
                                      },
                                      model: {
                                        value: _vm.actionDialog,
                                        callback: function ($$v) {
                                          _vm.actionDialog = $$v
                                        },
                                        expression: "actionDialog",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              attrs: {
                                                card: "",
                                                dark: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _vm.isAssignButtonClicked
                                                ? _c("v-toolbar-title", [
                                                    _vm._v("Set ETA"),
                                                  ])
                                                : _vm.isChangeETAButtonClicked
                                                ? _c("v-toolbar-title", [
                                                    _vm._v("Change ETA"),
                                                  ])
                                                : _vm.isUnavailableButtonClicked
                                                ? _c("v-toolbar-title", [
                                                    _vm._v(
                                                      "Contractor Unavailable"
                                                    ),
                                                  ])
                                                : _vm.isReattendButtonClicked
                                                ? _c("v-toolbar-title", [
                                                    _vm._v("Re-attend"),
                                                  ])
                                                : _vm.isCancelContractorButtonClicked
                                                ? _c("v-toolbar-title", [
                                                    _vm._v("Cancel Contractor"),
                                                  ])
                                                : _vm._e(),
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: _vm.closeDialog,
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "scroll-content-dialog px-4 pt-4",
                                            },
                                            [
                                              _vm.isAssignButtonClicked ||
                                              _vm.isChangeETAButtonClicked ||
                                              _vm.isReattendButtonClicked
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs6: "",
                                                            "pr-2": "",
                                                          },
                                                        },
                                                        [
                                                          _c("DateTimePicker", {
                                                            ref: "ETAfromdateTimePicker",
                                                            attrs: {
                                                              "date-time":
                                                                _vm
                                                                  .contractorAppointed
                                                                  .etaFrom,
                                                              "is-static-location": false,
                                                              "place-holder-text":
                                                                "ETA From",
                                                              "min-date":
                                                                _vm.minETADate,
                                                              "min-time":
                                                                _vm.minETATime,
                                                              "is-validation-required": true,
                                                              "allowed-dates":
                                                                _vm.getDatesFromCustomerAvailability,
                                                              "allowed-minutes-step": true,
                                                            },
                                                            on: {
                                                              "update:dateTime":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "etaFrom",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:date-time":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "etaFrom",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs6: "",
                                                            "pl-2": "",
                                                          },
                                                        },
                                                        [
                                                          _c("DateTimePicker", {
                                                            ref: "ETATodateTimePicker",
                                                            attrs: {
                                                              "date-time":
                                                                _vm
                                                                  .contractorAppointed
                                                                  .etaTo,
                                                              "is-static-location": false,
                                                              "place-holder-text":
                                                                "ETA To",
                                                              "min-date":
                                                                _vm.minETAToDate,
                                                              "min-time":
                                                                _vm.minETAToTime,
                                                              "max-date":
                                                                _vm.maxETAToDate,
                                                              "max-time":
                                                                _vm.maxETAToTime,
                                                              "is-validation-required": true,
                                                              "allowed-dates":
                                                                _vm.getDatesFromCustomerAvailability,
                                                              "allowed-minutes-step": true,
                                                            },
                                                            on: {
                                                              "update:dateTime":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "etaTo",
                                                                    $event
                                                                  )
                                                                },
                                                              "update:date-time":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "etaTo",
                                                                    $event
                                                                  )
                                                                },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.isWrongDateRange ||
                                                      _vm.isWrongTimeRange
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "mb-2": "",
                                                              },
                                                            },
                                                            [
                                                              _vm.isWrongDateRange
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "error--text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "To date must be greater than From date."
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.isWrongTimeRange
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "error--text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Customer is not available within contractor ETA. "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.availabilityOutsideSLA
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "mb-2": "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "warning--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "You are not allowed to deploy outside the SLA cut off time."
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm.isSIJob &&
                                                      _vm.isAssignButtonClicked
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "pr-0": "",
                                                                "pt-0": "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-autocomplete",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "validate",
                                                                      rawName:
                                                                        "v-validate",
                                                                      value:
                                                                        "required",
                                                                      expression:
                                                                        "'required'",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "required",
                                                                  attrs: {
                                                                    items:
                                                                      _vm.engineerList,
                                                                    label:
                                                                      "Select Engineer",
                                                                    "item-text":
                                                                      "name",
                                                                    "item-value":
                                                                      "id",
                                                                    "menu-props":
                                                                      {
                                                                        maxHeight:
                                                                          "auto",
                                                                      },
                                                                    chips: "",
                                                                    required:
                                                                      "",
                                                                    "data-vv-scope":
                                                                      "select-contractor-engineer",
                                                                    "data-vv-name":
                                                                      "Select Engineer",
                                                                    "error-messages":
                                                                      _vm.errors.collect(
                                                                        "Select Engineer"
                                                                      ),
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "selection",
                                                                          fn: function (
                                                                            data
                                                                          ) {
                                                                            return [
                                                                              _c(
                                                                                "v-chip",
                                                                                {
                                                                                  key: JSON.stringify(
                                                                                    data.item
                                                                                  ),
                                                                                  staticClass:
                                                                                    "chip--select-multi",
                                                                                  attrs:
                                                                                    {
                                                                                      selected:
                                                                                        data.selected,
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-avatar",
                                                                                    [
                                                                                      data
                                                                                        .item
                                                                                        .profileImageThumbnailUrl
                                                                                        ? _c(
                                                                                            "img",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  alt: "",
                                                                                                  src: data
                                                                                                    .item
                                                                                                    .profileImageThumbnailUrl,
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _c(
                                                                                            "img",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  alt: "",
                                                                                                  src: "/img/unknownuser.png",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        data
                                                                                          .item
                                                                                          .name
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                        {
                                                                          key: "item",
                                                                          fn: function (
                                                                            data
                                                                          ) {
                                                                            return [
                                                                              typeof data.item !==
                                                                              "object"
                                                                                ? [
                                                                                    _c(
                                                                                      "v-list-tile-content",
                                                                                      {
                                                                                        domProps:
                                                                                          {
                                                                                            textContent:
                                                                                              _vm._s(
                                                                                                data.name
                                                                                              ),
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    _c(
                                                                                      "v-list-tile-avatar",
                                                                                      [
                                                                                        data
                                                                                          .item
                                                                                          .profileImageThumbnailUrl
                                                                                          ? _c(
                                                                                              "img",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    alt: "",
                                                                                                    src: data
                                                                                                      .item
                                                                                                      .profileImageThumbnailUrl,
                                                                                                  },
                                                                                              }
                                                                                            )
                                                                                          : _c(
                                                                                              "img",
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    alt: "",
                                                                                                    src: "/img/unknownuser.png",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-list-tile-content",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-tile-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  data
                                                                                                    .item
                                                                                                    .name
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      837443576
                                                                    ),
                                                                  model: {
                                                                    value:
                                                                      _vm.selectedEngineer,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.selectedEngineer =
                                                                          $$v
                                                                      },
                                                                    expression:
                                                                      "selectedEngineer",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.contractor
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "pr-1",
                                                              attrs: {
                                                                xs6: "",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                staticClass:
                                                                  "travelTimeHour",
                                                                attrs: {
                                                                  items:
                                                                    _vm.travelTimeHourList,
                                                                  label:
                                                                    "Travel Time (Hours)",
                                                                  "item-text":
                                                                    "text",
                                                                  "item-value":
                                                                    "value",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.travelTimeHour,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.travelTimeHour =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "travelTimeHour",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.contractor
                                                        ? _c(
                                                            "v-flex",
                                                            {
                                                              staticClass:
                                                                "pl-1",
                                                              attrs: {
                                                                xs6: "",
                                                              },
                                                            },
                                                            [
                                                              _c("v-select", {
                                                                staticClass:
                                                                  "travelTimeMinute",
                                                                attrs: {
                                                                  items:
                                                                    _vm.travelTimeMinuteList,
                                                                  label:
                                                                    "Travel Time (Minutes)",
                                                                  "item-text":
                                                                    "text",
                                                                  "item-value":
                                                                    "value",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.travelTimeMinute,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.travelTimeMinute =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "travelTimeMinute",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            grey: "",
                                                            "lighten-3": "",
                                                          },
                                                        },
                                                        [
                                                          _c("DelayCodes", {
                                                            attrs: {
                                                              "has-delay":
                                                                _vm
                                                                  .contractorAppointed
                                                                  .hasDelay,
                                                              "delay-code":
                                                                _vm
                                                                  .contractorAppointed
                                                                  .delayCode,
                                                              "availability-outside-s-l-a":
                                                                _vm.availabilityOutsideSLA,
                                                            },
                                                            on: {
                                                              updateDelayCode:
                                                                _vm.onDelayCodeChange,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isReattendButtonClicked
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-textarea", {
                                                            directives: [
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "required",
                                                            attrs: {
                                                              label: "Reason",
                                                              required: "",
                                                              "data-vv-scope":
                                                                "formReference",
                                                              "data-vv-name":
                                                                "Reattend reason",
                                                              "error-messages":
                                                                _vm.errors.collect(
                                                                  "Reattend reason"
                                                                ),
                                                              rows: "5",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .contractorAppointed
                                                                  .reasonDescription,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "reasonDescription",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contractorAppointed.reasonDescription",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isUnavailableButtonClicked ||
                                              _vm.isCancelContractorButtonClicked
                                                ? _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-select", {
                                                            directives: [
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "required",
                                                            attrs: {
                                                              items:
                                                                _vm.contractorUnavailableReasons,
                                                              label: "Reason",
                                                              "item-value":
                                                                "description",
                                                              "item-text":
                                                                "description",
                                                              required: "",
                                                              "data-vv-scope":
                                                                "formReference",
                                                              "data-vv-name":
                                                                "Description",
                                                              "error-messages":
                                                                _vm.errors.collect(
                                                                  "Description"
                                                                ),
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.onReasonChange(
                                                                  $event,
                                                                  _vm.isUnavailableButtonClicked
                                                                )
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "item",
                                                                  fn: function (
                                                                    data
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-list-tile-content",
                                                                        [
                                                                          _c(
                                                                            "v-list-tile-title",
                                                                            {
                                                                              class:
                                                                                data
                                                                                  .item
                                                                                  .description ===
                                                                                "Configure"
                                                                                  ? "bold-select"
                                                                                  : "",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    data
                                                                                      .item
                                                                                      .description
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              3812441667
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .contractorAppointed
                                                                  .reasonType,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "reasonType",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contractorAppointed.reasonType",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              label:
                                                                "Description",
                                                              rows: "5",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .contractorAppointed
                                                                  .reasonDescription,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.contractorAppointed,
                                                                    "reasonDescription",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "contractorAppointed.reasonDescription",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card-actions",
                                            { staticClass: "px-4" },
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    flat: "flat",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.closeDialog.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Close")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-0 assignedContactor-btn",
                                                  attrs: {
                                                    color: "primary",
                                                    disabled: _vm.isLoading,
                                                    loading: _vm.isLoading,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.assignedContractorToJob,
                                                  },
                                                },
                                                [_vm._v(" Submit ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.configureUnavailableReasonDialog
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: {
                                            "max-width": "800",
                                            persistent: "",
                                            "content-class":
                                              "v-dialog--scrollable",
                                          },
                                          model: {
                                            value:
                                              _vm.configureUnavailableReasonDialog,
                                            callback: function ($$v) {
                                              _vm.configureUnavailableReasonDialog =
                                                $$v
                                            },
                                            expression:
                                              "configureUnavailableReasonDialog",
                                          },
                                        },
                                        [
                                          _c("ConfigureUnavailableReason", {
                                            attrs: {
                                              "record-type":
                                                _vm.recordType[
                                                  _vm.recordType
                                                    .ContractorUnavailableReason
                                                ],
                                            },
                                            on: {
                                              CloseConfigureUnavailableReasonDialog:
                                                _vm.onConfigureUnavailableReasonDialogClose,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.openAssignEngineerDialog
                                    ? _c(
                                        "v-dialog",
                                        {
                                          attrs: {
                                            "max-width": "450",
                                            persistent: "",
                                            "content-class":
                                              "v-dialog--scrollable",
                                          },
                                          model: {
                                            value: _vm.openAssignEngineerDialog,
                                            callback: function ($$v) {
                                              _vm.openAssignEngineerDialog = $$v
                                            },
                                            expression:
                                              "openAssignEngineerDialog",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-toolbar",
                                                {
                                                  attrs: {
                                                    card: "",
                                                    dark: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _c("v-toolbar-title", [
                                                    _vm._v("Assign Engineer"),
                                                  ]),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { icon: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.openAssignEngineerDialog = false
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("close"),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "scroll-content-dialog px-4 pt-2",
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    { attrs: { wrap: "" } },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        {
                                                          attrs: {
                                                            xs12: "",
                                                            "pr-2": "",
                                                          },
                                                        },
                                                        [
                                                          _c("v-autocomplete", {
                                                            directives: [
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "required",
                                                            attrs: {
                                                              items:
                                                                _vm.engineerList,
                                                              label:
                                                                "Select Engineer",
                                                              "item-text":
                                                                "name",
                                                              "item-value":
                                                                "id",
                                                              "menu-props": {
                                                                maxHeight:
                                                                  "auto",
                                                              },
                                                              chips: "",
                                                              required: "",
                                                              "data-vv-scope":
                                                                "selectedEngineer",
                                                              "data-vv-name":
                                                                "Select Engineer",
                                                              "error-messages":
                                                                _vm.errors.collect(
                                                                  "Select Engineer"
                                                                ),
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "selection",
                                                                  fn: function (
                                                                    data
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "v-chip",
                                                                        {
                                                                          key: JSON.stringify(
                                                                            data.item
                                                                          ),
                                                                          staticClass:
                                                                            "chip--select-multi",
                                                                          attrs:
                                                                            {
                                                                              selected:
                                                                                data.selected,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-avatar",
                                                                            [
                                                                              data
                                                                                .item
                                                                                .profileImageThumbnailUrl
                                                                                ? _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src: data
                                                                                            .item
                                                                                            .profileImageThumbnailUrl,
                                                                                        },
                                                                                    }
                                                                                  )
                                                                                : _c(
                                                                                    "img",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          src: "/img/unknownuser.png",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                data
                                                                                  .item
                                                                                  .name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: "item",
                                                                  fn: function (
                                                                    data
                                                                  ) {
                                                                    return [
                                                                      typeof data.item !==
                                                                      "object"
                                                                        ? [
                                                                            _c(
                                                                              "v-list-tile-content",
                                                                              {
                                                                                domProps:
                                                                                  {
                                                                                    textContent:
                                                                                      _vm._s(
                                                                                        data.name
                                                                                      ),
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        : [
                                                                            _c(
                                                                              "v-list-tile-avatar",
                                                                              [
                                                                                data
                                                                                  .item
                                                                                  .profileImageThumbnailUrl
                                                                                  ? _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: data
                                                                                              .item
                                                                                              .profileImageThumbnailUrl,
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: "/img/unknownuser.png",
                                                                                          },
                                                                                      }
                                                                                    ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-list-tile-content",
                                                                              [
                                                                                _c(
                                                                                  "v-list-tile-title",
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          data
                                                                                            .item
                                                                                            .name
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1395741432
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.selectedEngineer,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectedEngineer =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectedEngineer",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-actions",
                                                { staticClass: "px-4" },
                                                [
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        flat: "flat",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.openAssignEngineerDialog = false
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Close")]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "mr-0",
                                                      attrs: {
                                                        color: "primary",
                                                        disabled:
                                                          _vm.loaderOnAssignEngineer,
                                                        loading:
                                                          _vm.loaderOnAssignEngineer,
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.assignEngineerToEmergency.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" Submit ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm.saveSnackbarText
                                ? _c(
                                    "v-snackbar",
                                    {
                                      attrs: {
                                        timeout: 3000,
                                        bottom: true,
                                        left: true,
                                      },
                                      model: {
                                        value: _vm.saveSnackbar,
                                        callback: function ($$v) {
                                          _vm.saveSnackbar = $$v
                                        },
                                        expression: "saveSnackbar",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.saveSnackbarText) + " "
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            flat: "",
                                            color: "secondary",
                                          },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.saveSnackbar = false
                                            },
                                          },
                                        },
                                        [_vm._v("Close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }