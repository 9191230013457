var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(
      _vm.testingProgress.requiredTestingDocuments,
      function (docType, ind) {
        return _c(
          "v-flex",
          { key: ind },
          [
            _vm.missingDocument(docType)
              ? _c(
                  "v-alert",
                  {
                    staticClass: "custom-warning-error pa-2",
                    attrs: {
                      color: "error lighten-5 red--text",
                      value: true,
                      type: "error",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.titleForType(docType)) + " is required."
                      ),
                    ]),
                  ]
                )
              : _c(
                  "v-alert",
                  {
                    staticClass: "custom-warning-success pa-2",
                    attrs: {
                      color: "green lighten-5 green--text",
                      value: true,
                      type: "success",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.titleForType(docType)) + " uploaded."),
                    ]),
                  ]
                ),
          ],
          1
        )
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }