var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                {
                  attrs: {
                    fluid: "",
                    "grid-list-lg": "",
                    "pt-0": "",
                    "pa-0": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "po-info": "", "visit-info": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-container",
                            {
                              attrs: {
                                fluid: "",
                                "grid-list-lg": "",
                                "pt-0": "",
                                "pa-0": "",
                              },
                            },
                            [
                              _c(
                                "v-layout",
                                {
                                  attrs: {
                                    wrap: "",
                                    "po-info": "",
                                    "visit-info": "",
                                  },
                                },
                                _vm._l(
                                  Object.keys(_vm.getTopTiles),
                                  function (key) {
                                    return _c(
                                      "DashboardTopTile",
                                      _vm._b(
                                        {
                                          key: key,
                                          ref: "topTileBars",
                                          refInFor: true,
                                          attrs: {
                                            "active-filter-color":
                                              _vm.tileColorWithActiveFilter ===
                                              _vm.getTopTiles[key]
                                                .backgroundColor
                                                ? true
                                                : false,
                                            "dashboard-tile-view":
                                              "FinancialSummary",
                                          },
                                          on: {
                                            onTopTileFilter:
                                              _vm.onTopTileFilter,
                                          },
                                        },
                                        "DashboardTopTile",
                                        _vm.getTopTiles[key],
                                        false
                                      )
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "gridView mt-4", attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs4: "", "visit-info": "" } }, [
                    _c("h3", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.activeFilterValue ===
                              _vm.contractorFinancialSummaryWidget[
                                _vm.contractorFinancialSummaryWidget.Pending
                              ]
                              ? "Ready For Approvals"
                              : _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.ToReview
                                ]
                              ? "Awaiting Review from CET"
                              : _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.Disputed
                                ]
                              ? "Disputed"
                              : _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget
                                    .ReadyForInvoice
                                ]
                              ? "Ready for Invoice"
                              : "Outstanding"
                          ) +
                          " Visits "
                      ),
                    ]),
                  ]),
                  _vm.visitFinancialSummaryData.length > 0
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-right search-controls" },
                        [
                          _c(
                            "div",
                            { staticClass: "search-contractor" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "search",
                                  label: "Search",
                                  disabled: _vm.isLoading,
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.contractorPolicies.updateFinancial
                            ? [
                                _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.Pending
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 ml-2 mt-2 btn-approve-invoice",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.openConfirmationForVisitApprove()
                                          },
                                        },
                                      },
                                      [_vm._v(" Approve ")]
                                    )
                                  : _vm._e(),
                                _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.Pending
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0 ml-2 mt-2",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfirmationForVisitDispute()
                                          },
                                        },
                                      },
                                      [_vm._v(" Dispute ")]
                                    )
                                  : _vm._e(),
                                _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.Disputed
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0 ml-2 mt-2",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: _vm.onConfirmDisputingVisit,
                                        },
                                      },
                                      [_vm._v(" Approve ")]
                                    )
                                  : _vm._e(),
                                _vm.activeFilterValue ===
                                _vm.contractorFinancialSummaryWidget[
                                  _vm.contractorFinancialSummaryWidget.ToReview
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "ma-0 ml-2 mt-2",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click:
                                            _vm.openConfirmationForVisitToReview,
                                        },
                                      },
                                      [_vm._v(" Review Complete ")]
                                    )
                                  : _vm._e(),
                                _vm.activeFilterValue ===
                                  _vm.contractorFinancialSummaryWidget[
                                    _vm.contractorFinancialSummaryWidget
                                      .ReadyForInvoice
                                  ] &&
                                _vm.canViewFinance &&
                                _vm.selectedVisitList.length > 0
                                  ? _c(
                                      "v-btn",
                                      {
                                        ref: "export-button",
                                        staticClass: "ma-0 ml-2 mt-2",
                                        attrs: {
                                          slot: "activator",
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: _vm.openExportToExcelDialog,
                                        },
                                        slot: "activator",
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "md-icon" },
                                          [_vm._v("get_app")]
                                        ),
                                        _vm._v("   Export "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "elevation-1",
                      attrs: { xs12: "", "mt-2": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "invoice-list" },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "invoice-table",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.visitFinancialSummaryData,
                                pagination: _vm.pagination,
                                loading: _vm.isLoading,
                                "item-key": "contractorInvoiceDetailId",
                                "select-all": _vm.hasRightForMarking,
                                search: _vm.search,
                                "custom-filter": _vm.customFilter,
                              },
                              on: {
                                "update:pagination": function ($event) {
                                  _vm.pagination = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.expanded = !props.expanded
                                            },
                                          },
                                        },
                                        [
                                          _vm.hasRightForMarking
                                            ? _c(
                                                "td",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value: props.selected,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props,
                                                          "selected",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.selected",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("td", [
                                            _c("b", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "secondary--text action-btn",
                                                  attrs: {
                                                    href: "javascript:void(0)",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.openContractorJobDetailModal(
                                                        props.item.jobId,
                                                        props.item
                                                          .contractorAppointedDetailIds
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(props.item.jobId) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            props.item.clientNotes &&
                                            props.item.clientNotes.length > 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          "content-class":
                                                            "yellow lighten-2 grey--text text--darken-4 body-2",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "icon-arrow md-icon",
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          _vm._l(
                                                            props.item
                                                              .clientNotes,
                                                            function (
                                                              note,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "h3",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Note " +
                                                                          _vm._s(
                                                                            index +
                                                                              1
                                                                          ) +
                                                                          ":"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "- " +
                                                                          _vm._s(
                                                                            note
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.address)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTradeDescription(
                                                  props.item.trades
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getFormatedCurrency(
                                                      props.item.materialCost
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("b", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getFormatedCurrency(
                                                      props.item.labourCost
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getFormatedCurrency(
                                                        props.item
                                                          .additionalCharge
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              !props.item.overrideTotalCost
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormatedCurrency(
                                                            props.item
                                                              .materialCost +
                                                              props.item
                                                                .labourCost +
                                                              props.item
                                                                .additionalCharge
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _c(
                                                    "span",
                                                    [
                                                      props.item.materialCost ||
                                                      props.item.labourCost
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                right: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-icon",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2 md-icon",
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _vm._v(
                                                                                "info"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  " Auto calculated amount was " +
                                                                    _vm._s(
                                                                      _vm.getFormatedCurrency(
                                                                        props
                                                                          .item
                                                                          .materialCost +
                                                                          props
                                                                            .item
                                                                            .labourCost
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getFormatedCurrency(
                                                              props.item
                                                                .overrideTotalCost
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _vm._v(
                                                _vm._s(props.item.visitCount)
                                              ),
                                            ]
                                          ),
                                          _vm.activeFilterValue ===
                                          _vm.contractorFinancialSummaryWidget[
                                            _vm.contractorFinancialSummaryWidget
                                              .Disputed
                                          ]
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-center",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.item
                                                          .disputedReason
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "expand",
                                  fn: function (props) {
                                    return [
                                      props.item.contractorInvoiceSummary
                                        ? _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                flat: "",
                                                "elevation-0": "",
                                              },
                                            },
                                            _vm._l(
                                              props.item
                                                .contractorInvoiceSummary,
                                              function (invoiceSummary, index) {
                                                return _c(
                                                  "v-card-text",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "lighten-4 pb-1 engineer-visit-detail",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          [
                                                            _c("v-flex", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mt-2 ml-0 d-inline-block title-visit",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      "Visit " +
                                                                        _vm._s(
                                                                          index +
                                                                            1
                                                                        ) +
                                                                        ":"
                                                                    ),
                                                                  ]),
                                                                  _vm._v("   "),
                                                                  _c("label", [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Start Time:"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " (" +
                                                                        _vm._s(
                                                                          _vm.getFormatedDate(
                                                                            invoiceSummary.visitStartedAt
                                                                          )
                                                                        ) +
                                                                        ") "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "     "
                                                                  ),
                                                                  _c("label", [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "End Time:"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      " (" +
                                                                        _vm._s(
                                                                          _vm.getFormatedDate(
                                                                            invoiceSummary.visitCompletedAt
                                                                          )
                                                                        ) +
                                                                        ") "
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "     "
                                                                  ),
                                                                  invoiceSummary.visitCompletedBy
                                                                    ? _c(
                                                                        "label",
                                                                        [
                                                                          _c(
                                                                            "b",
                                                                            [
                                                                              _vm._v(
                                                                                "Attend By:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                invoiceSummary.visitCompletedBy
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(
                                                                    "     "
                                                                  ),
                                                                  _c(
                                                                    "label",
                                                                    [
                                                                      _c("b", [
                                                                        _vm._v(
                                                                          "Travel Time:"
                                                                        ),
                                                                      ]),
                                                                      invoiceSummary.additionalCharge &&
                                                                      invoiceSummary.additionalCharge >
                                                                        0 &&
                                                                      invoiceSummary.isInCoverageArea
                                                                        ? _c(
                                                                            "v-tooltip",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  right:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "activator",
                                                                                      fn: function ({
                                                                                        on,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            _vm._g(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml-2 md-icon",
                                                                                              },
                                                                                              on
                                                                                            ),
                                                                                            [
                                                                                              _vm._v(
                                                                                                "info"
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    "Requested travel time in coverage area"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          class:
                                                                            invoiceSummary.additionalCharge >
                                                                              0 &&
                                                                            invoiceSummary.isInCoverageArea
                                                                              ? "red--text"
                                                                              : "",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.getFormatedTravelTime(
                                                                                  invoiceSummary.additionalTravelTime
                                                                                )
                                                                              ) +
                                                                              " (" +
                                                                              _vm._s(
                                                                                _vm.getFormatedCurrency(
                                                                                  invoiceSummary.additionalCharge
                                                                                )
                                                                              ) +
                                                                              ") "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v("   "),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  "text-xs-right":
                                                                    "",
                                                                  "visit-total":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h3",
                                                                  {
                                                                    staticClass:
                                                                      "ma-2 title-visit",
                                                                  },
                                                                  [
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        "Visit Total:"
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      "   "
                                                                    ),
                                                                    _c(
                                                                      "b",
                                                                      {
                                                                        staticClass:
                                                                          "secondary--text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.getFormatedCurrency(
                                                                                invoiceSummary.totalVisitCharge
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        invoiceSummary.distanceToCustomerLocation ||
                                                        (invoiceSummary.engineerOnTheWayAt &&
                                                          invoiceSummary.engineerOnSiteAt)
                                                          ? _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: "",
                                                                  "pl-5": "",
                                                                  "ml-2": "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      "pl-1":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "mb-2 ml-0 d-inline-block title-visit",
                                                                      },
                                                                      [
                                                                        invoiceSummary.distanceToCustomerLocation
                                                                          ? _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Travel Distance:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      invoiceSummary.distanceToCustomerLocation
                                                                                    ) +
                                                                                    " KM "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          "   "
                                                                        ),
                                                                        invoiceSummary.engineerOnTheWayAt &&
                                                                        invoiceSummary.engineerOnSiteAt
                                                                          ? _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Travel Time:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getTravelTime(
                                                                                        invoiceSummary.engineerOnTheWayAt,
                                                                                        invoiceSummary.engineerOnSiteAt
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          "     "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              "px-2": "",
                                                              "pt-2": "",
                                                              "pb-0": "",
                                                              grey: "",
                                                              "lighten-3": "",
                                                              wrap: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  "pa-2": "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                      "fill-height":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "outer-col-height":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-title",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 secondary--text",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h3",
                                                                              [
                                                                                _vm._v(
                                                                                  "Labour"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticClass:
                                                                                  "secondary--text material-price",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getFormatedCurrency(
                                                                                        invoiceSummary.labourCost
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-layout",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                wrap: "",
                                                                                grey: "",
                                                                                "lighten-2":
                                                                                  "",
                                                                                "pa-2":
                                                                                  "",
                                                                                "equal-hegiht":
                                                                                  "",
                                                                                "item-list-content":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    xs12: "",
                                                                                    "mb-2":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-card-title",
                                                                                  {
                                                                                    staticClass:
                                                                                      "pa-0",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          invoiceSummary.canChargeCallout
                                                                                            ? "Callout Rate"
                                                                                            : "Follow-On Rate"
                                                                                        ) +
                                                                                        "   "
                                                                                    ),
                                                                                    _c(
                                                                                      "b",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "(" +
                                                                                            _vm._s(
                                                                                              _vm.calculateMinutes(
                                                                                                _vm.minimumCallOutMinutes
                                                                                              )
                                                                                            ) +
                                                                                            ")"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-divider",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mt-1",
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            invoiceSummary.additionalAuthorisedMinutes &&
                                                                            invoiceSummary.additionalAuthorisedMinutes >
                                                                              0
                                                                              ? _c(
                                                                                  "v-flex",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        xs12: "",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-card-title",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pa-0",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " Additional Minutes   "
                                                                                        ),
                                                                                        _c(
                                                                                          "b",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "(" +
                                                                                                _vm._s(
                                                                                                  _vm.calculateMinutes(
                                                                                                    invoiceSummary.additionalAuthorisedMinutes
                                                                                                  )
                                                                                                ) +
                                                                                                ")"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e(),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: "",
                                                                  "pa-2": "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                      "fill-height":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          "outer-col-height":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card-title",
                                                                          {
                                                                            staticClass:
                                                                              "pa-0 secondary--text",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "h3",
                                                                              [
                                                                                _vm._v(
                                                                                  "Material"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-spacer"
                                                                            ),
                                                                            _c(
                                                                              "b",
                                                                              {
                                                                                staticClass:
                                                                                  "secondary--text material-price",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.getFormatedCurrency(
                                                                                        _vm.getSumOfMaterialCost(
                                                                                          invoiceSummary.engineerVisitMaterials
                                                                                        )
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-layout",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                wrap: "",
                                                                                grey: "",
                                                                                "lighten-2":
                                                                                  "",
                                                                                "pa-2":
                                                                                  "",
                                                                                "equal-hegiht":
                                                                                  "",
                                                                                "item-list-content":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            invoiceSummary
                                                                              .engineerVisitMaterials
                                                                              .length >
                                                                            0
                                                                              ? _c(
                                                                                  "div",
                                                                                  _vm._l(
                                                                                    invoiceSummary.engineerVisitMaterials,
                                                                                    function (
                                                                                      material,
                                                                                      key
                                                                                    ) {
                                                                                      return _c(
                                                                                        "v-flex",
                                                                                        {
                                                                                          key: key,
                                                                                          attrs:
                                                                                            {
                                                                                              xs12: "",
                                                                                              "mb-1":
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card-title",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-0",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    material.materialName
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                              _c(
                                                                                                "v-spacer"
                                                                                              ),
                                                                                              _c(
                                                                                                "b",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "material-price",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.getFormatedCurrency(
                                                                                                          material.baseCost
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _c(
                                                                                            "v-divider",
                                                                                            {
                                                                                              staticClass:
                                                                                                "mt-1",
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "No data available"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "v-card",
                                            {
                                              staticClass: "px-0",
                                              attrs: {
                                                flat: "",
                                                "elevation-0": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "grey lighten-4 pb-1 pb-3",
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v("No Data Found"),
                                                  ]),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.selectedVisitList,
                                callback: function ($$v) {
                                  _vm.selectedVisitList = $$v
                                },
                                expression: "selectedVisitList",
                              },
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  slot: "progress",
                                  color: _vm.tileColorWithActiveFilter,
                                  indeterminate: "",
                                },
                                slot: "progress",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isLoading
            ? _c("div", { staticClass: "loader-content" })
            : _vm._e(),
        ],
        1
      ),
      _vm.contractorJobDetailDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1000",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.contractorJobDetailDialog,
                callback: function ($$v) {
                  _vm.contractorJobDetailDialog = $$v
                },
                expression: "contractorJobDetailDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "shadow-none" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm.contractorJobDetails.length > 0 &&
                        _vm.contractorJobDetails[0].jobDetail
                          ? _c("span", [
                              _vm._v(
                                " Job Id: " +
                                  _vm._s(
                                    _vm.contractorJobDetails[0].jobDetail.jobId
                                  ) +
                                  " - " +
                                  _vm._s(
                                    _vm.contractorJobDetails[0].getAddress(",")
                                  ) +
                                  " "
                              ),
                            ])
                          : _c("span", [_vm._v("Contractor Job Detail")]),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.contractorJobDetailDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass:
                        "grey lighten-3 job-detail-dlog-content scroll-content-dialog",
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "scoll-vb-content",
                          attrs: { "mt-0": "" },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _vm.totalEmergenciesLogged !==
                              _vm.contractorJobDetails.length
                                ? _c("v-progress-circular", {
                                    style: _vm.getLoaderStyle(70),
                                    attrs: {
                                      width: 2,
                                      size: 50,
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  })
                                : _vm._e(),
                              _vm.totalEmergenciesLogged === 1 &&
                              _vm.contractorJobDetails[0]
                                ? _c(
                                    "div",
                                    [
                                      _vm.contractorJobDetails[0]
                                        ? _c("ContractorJobDetail", {
                                            attrs: {
                                              "parent-component":
                                                "ContractorFinancialSummary",
                                              "contractor-job-detail-item":
                                                _vm.contractorJobDetails[0],
                                              engineers: _vm.engineers,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        display: "block",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          staticClass: "jobdetail-tabs",
                                          attrs: {
                                            color: "grey lighten-4",
                                            light: "",
                                            "slider-color": "white",
                                          },
                                          model: {
                                            value: _vm.childTabIndex,
                                            callback: function ($$v) {
                                              _vm.childTabIndex = $$v
                                            },
                                            expression: "childTabIndex",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.contractorJobDetails,
                                            function (contractorJobDetail, i) {
                                              return _c(
                                                "v-tab",
                                                {
                                                  key: i,
                                                  attrs: {
                                                    href: `#child-tab-${i}`,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        contractorJobDetail
                                                          .emergencyDetail
                                                          .tradeDescription
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _vm._l(
                                            _vm.contractorJobDetails,
                                            function (contractorJobDetail, j) {
                                              return _c(
                                                "v-tab-item",
                                                {
                                                  key: j,
                                                  attrs: {
                                                    value: `child-tab-${j}`,
                                                  },
                                                },
                                                [
                                                  _vm.childTabIndex ===
                                                  "child-tab-" + j
                                                    ? _c(
                                                        "ContractorJobDetail",
                                                        {
                                                          attrs: {
                                                            "parent-component":
                                                              "ContractorFinancialSummary",
                                                            "contractor-job-detail-item":
                                                              contractorJobDetail,
                                                            engineers:
                                                              _vm.engineers,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showDisputeDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showDisputeDialog,
                callback: function ($$v) {
                  _vm.showDisputeDialog = $$v
                },
                expression: "showDisputeDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Dispute Job")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDisputeDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog px-4 pt-4" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "pr-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Dispute Reason",
                                  required: "",
                                  "data-vv-scope": "jobDisputeReason",
                                  "data-vv-name": "Dispute Reason",
                                  "error-messages":
                                    _vm.errors.collect("Dispute Reason"),
                                },
                                model: {
                                  value: _vm.disputeReason,
                                  callback: function ($$v) {
                                    _vm.disputeReason = $$v
                                  },
                                  expression: "disputeReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showDisputeDialog = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            loading: _vm.onSubmitLoading,
                            disabled: _vm.onSubmitLoading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.disputeVisits.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.exportToExcelDialog &&
      _vm.canViewFinance &&
      _vm.selectedVisitList.length > 0
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.exportToExcelDialog,
                callback: function ($$v) {
                  _vm.exportToExcelDialog = $$v
                },
                expression: "exportToExcelDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Export To Excel")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 pt-0 scroll-content-dialog" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0", attrs: { "grid-list-xl": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Export Name" },
                                    model: {
                                      value: _vm.exportName,
                                      callback: function ($$v) {
                                        _vm.exportName = $$v
                                      },
                                      expression: "exportName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "export-excel",
                        {
                          staticClass: "btn ml-2",
                          attrs: {
                            "export-fields": _vm.exportToExcelHeaderFields,
                            fetch: _vm.exportContractorPO,
                            worksheet: "Contractor Purchase Order Details",
                            "before-generate": _vm.startExportGeneration,
                            "before-finish": _vm.stopExportGeneration,
                            type: "xls",
                            name: "contractorPOList.xls",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: "ExportToExcel",
                              attrs: {
                                color: "primary",
                                loading: _vm.exportToExcelLoader,
                                disabled: _vm.exportToExcelLoader,
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "md-icon" }, [
                                _vm._v("get_app"),
                              ]),
                              _vm._v("   Export "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }