var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        {
          staticStyle: { "justify-content": "flex-end" },
          attrs: { row: "", wrap: "", "align-center": "", xs12: "" },
        },
        [
          _c("v-text-field", {
            staticStyle: { "max-width": "300px" },
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1 gridView popup-table",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.clientJobs,
          "item-key": "jobId",
          search: _vm.search,
          loading: _vm.loadingData,
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function (props) {
              return [
                _c("td", [_c("b", [_vm._v(_vm._s(props.item.jobId))])]),
                _c("td", [_vm._v(_vm._s(props.item.customerName))]),
                _c("td", [_vm._v(_vm._s(props.item.address))]),
                _c(
                  "td",
                  _vm._l(props.item.emergencies, function (emergency, index) {
                    return _c(
                      "v-chip",
                      {
                        key: `${emergency.id}-${index}`,
                        staticClass: "emergency-chip",
                        attrs: {
                          small: "",
                          color: "teal",
                          "text-color": "white",
                        },
                      },
                      [
                        _c("v-avatar", { attrs: { size: 26 } }, [
                          emergency.iconUrl
                            ? _c("img", {
                                attrs: {
                                  src: emergency.iconUrl,
                                  alt: emergency.description,
                                },
                              })
                            : _c("img", {
                                attrs: {
                                  src: "/img/emergency.svg",
                                  alt: emergency.description,
                                },
                              }),
                        ]),
                        _vm._v(" " + _vm._s(emergency.description) + " "),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "td",
                  _vm._l(props.item.visits, function (visit, index) {
                    return _c(
                      "v-chip",
                      {
                        key: `${visit.id}-${index}`,
                        staticClass: "visit-chip",
                        attrs: { small: "", outline: "" },
                      },
                      [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openContractorJobDetailModal(
                                  props.item.jobId,
                                  visit.contractorAppointedId
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(visit.description) + " ")]
                        ),
                      ]
                    )
                  }),
                  1
                ),
                _c("td", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.formatDateRange(props.item.nextEta))),
                  ]),
                ]),
                _c("td", [_vm._v(_vm._s(props.item.status))]),
                _c(
                  "td",
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "white--text",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openEventStream(props.item.jobId)
                          },
                        },
                      },
                      [_vm._v(" Show Event Stream ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showEventStream
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showEventStream,
                callback: function ($$v) {
                  _vm.showEventStream = $$v
                },
                expression: "showEventStream",
              },
            },
            [
              _c("JobAuditLog", {
                attrs: { "job-id": _vm.jobId, "is-request-stream": true },
                on: {
                  closeModal: function ($event) {
                    _vm.showEventStream = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showJobDetailModal
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1000",
                persistent: "",
                "content-class":
                  "v-dialog--scrollable contractorjob-details-dialog",
              },
              model: {
                value: _vm.showJobDetailModal,
                callback: function ($$v) {
                  _vm.showJobDetailModal = $$v
                },
                expression: "showJobDetailModal",
              },
            },
            [
              _c("ContractorJobDetail", {
                attrs: {
                  "contractor-job-detail-item": _vm.selectedContractorDetail,
                  "show-engineer-details": false,
                  "show-load-more": true,
                },
                on: {
                  contractorJobDetailDialogClose:
                    _vm.closeContractorJobDetailModal,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }