var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _vm._m(0),
        _vm.engineerName
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Engineer: ")]),
              _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
            ])
          : _vm._e(),
        _vm.item.notes
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Notes: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.notes))]),
            ])
          : _vm._e(),
        _vm.item.status
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Status: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.status))]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update CCTV Control")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }