var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "task-col grey lighten-3" },
    [
      _c(
        "div",
        { staticClass: "task-header d-flex" },
        [
          _c("h3", { class: _vm.record.headerClass }, [
            _vm._v(_vm._s(_vm.usJobStatus)),
          ]),
          _vm.isNewHeader(_vm.usJobStatus)
            ? _c(
                "v-menu",
                {
                  staticClass: "status-filter",
                  attrs: {
                    bottom: "",
                    right: "",
                    "close-on-content-click": false,
                    "nudge-width": 240,
                    "offset-x": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ma-0 filter-btn",
                                  attrs: {
                                    slot: "activator",
                                    icon: "",
                                    color: "primary",
                                    flat: "",
                                    small: "",
                                  },
                                  slot: "activator",
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "md-icon",
                                    class: _vm.getFilterClass,
                                    attrs: { color: "red", dark: "" },
                                  },
                                  [_vm._v("filter_list")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3696617235
                  ),
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-2 pt-0" },
                    [
                      _c("v-select", {
                        staticClass: "visit-type-select",
                        attrs: {
                          placeholder: "Delay Type",
                          "item-text": "description",
                          "item-value": "description",
                          items: _vm.delayReasonList,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onDelayReasonChange($event)
                          },
                        },
                        model: {
                          value: _vm.selectedDelayReason,
                          callback: function ($$v) {
                            _vm.selectedDelayReason = $$v
                          },
                          expression: "selectedDelayReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-0 mx-3 mb-2" }),
      _vm.record.items.length
        ? _c(
            "div",
            { staticClass: "task-body px-3 pb-3 pt-1" },
            _vm._l(_vm.record.items, function (item, index) {
              return _c(
                "v-card",
                { key: index },
                [
                  _c(
                    "JobSummaryCard",
                    {
                      key: index,
                      attrs: {
                        job: item,
                        "title-generator": _vm.titleGenerator,
                      },
                      on: { redirectToJob: _vm.onRedirectToJob },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "JobCardDetails" },
                          slot: "JobCardDetails",
                        },
                        [
                          _c("div", { staticClass: "primary-title" }, [
                            _c(
                              "h4",
                              { staticClass: "date-title" },
                              [
                                item.companyName
                                  ? _c("span", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0)",
                                            name: "companyName-" + item.id,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.companyName) + " "
                                          ),
                                        ]
                                      ),
                                      !item.engineerName
                                        ? _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(" ( "),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    attach: "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                    name:
                                                                      "contractor-" +
                                                                      item.id,
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "call"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                _vm._l(
                                                  item.contactNumbers,
                                                  function (
                                                    contactNumber,
                                                    numberIndex
                                                  ) {
                                                    return _c(
                                                      "v-list",
                                                      { key: numberIndex },
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text cursor-pointer",
                                                                    attrs: {
                                                                      name:
                                                                        "contractor-" +
                                                                        numberIndex +
                                                                        "-" +
                                                                        item.id,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.onCallIconClick(
                                                                            item,
                                                                            numberIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "call"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          contactNumber.phoneNumber
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          contactNumber.label
                                                                            ? "(" +
                                                                                contactNumber.label +
                                                                                ")"
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        numberIndex !==
                                                        item.contactNumbers
                                                          .length -
                                                          1
                                                          ? _c("v-divider")
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v(" ) "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _c("span", [_vm._v("New Job")]),
                                item.commodityWorkType
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass:
                                                        "return-visit",
                                                      attrs: {
                                                        overlap: "",
                                                        color: "grey darken-2",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        _vm._g({}, on),
                                                        [
                                                          item.commodityWorkType ===
                                                          _vm.commodityWorkType[
                                                            _vm
                                                              .commodityWorkType
                                                              .Response
                                                          ]
                                                            ? _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " assignment_return "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "secondary",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "construction"
                                                                  ),
                                                                ]
                                                              ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.commodityWorkType)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.isNewHeader(_vm.usJobStatus) && item.delayCode
                              ? _c(
                                  "span",
                                  { staticClass: "delayCodeDetail" },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("av_timer")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Delay")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          disabled: !item.delayNotes,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c("span", _vm._g({}, on), [
                                                    _vm._v(
                                                      _vm._s(item.delayCode)
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.delayNotes)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          item.engineerName || item.engineerContactNumber
                            ? _c("div", { staticClass: "bottom-row" }, [
                                _c("span", { staticClass: "img-text" }, [
                                  _vm._v(" " + _vm._s(item.engineerName) + " "),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "primary--text" },
                                  [
                                    _vm._v(" ( "),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { attach: "", "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "a",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                          name:
                                                            "engineer-" +
                                                            item.id,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            small: "",
                                                          },
                                                        },
                                                        [_vm._v("call")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c("v-list-tile-title", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text cursor-pointer",
                                                      attrs: {
                                                        name:
                                                          "engineer-" + item.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.onCallIconClick(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("call"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.engineerContactNumber
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" ) "),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "unavailable-job-msg" }, [
            _c("span", [_vm._v("Data not available")]),
          ]),
      _c("PartialJobView", {
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }