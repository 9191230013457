var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "400" },
              model: {
                value: _vm.dialog,
                callback: function ($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog",
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { display: "none" },
                  attrs: { color: "primary", dark: "" },
                },
                [_vm._v("Open Dialog")]
              ),
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "pa-3" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.message))]),
                  ]),
                  _c(
                    "v-card-actions",
                    { staticClass: "pt-2 px-3 pb-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", small: "" },
                          on: { click: _vm.onConfirm },
                        },
                        [_vm._v(" " + _vm._s(_vm.positiveActionText) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", flat: "" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v(_vm._s(_vm.negativeActionText))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }