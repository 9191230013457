var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "user" }, [
    _c(
      "div",
      [
        _c(
          "v-card-title",
          { staticClass: "pa-0 mb-2", attrs: { "primary-title": "" } },
          [
            _c("h3", { staticClass: "mb-0" }, [_vm._v("Call Queue List")]),
            _c("v-spacer"),
            _c("v-text-field", {
              staticClass: "mt-0 pt-0",
              attrs: {
                "append-icon": "search",
                label: "Search",
                "single-line": "",
                "hide-details": "",
              },
              model: {
                value: _vm.search,
                callback: function ($$v) {
                  _vm.search = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm.callList && _vm.callList.length > 0
              ? [_vm._v(" " + _vm._s(_vm.startTimer()) + " ")]
              : _vm._e(),
            _c("v-data-table", {
              staticClass: "gridView call-queue-list-table",
              attrs: {
                headers: _vm.headers,
                items: _vm.callList,
                loading: _vm.isLoading,
                search: _vm.search,
                "hide-actions": "",
                pagination: _vm.pagination,
              },
              on: {
                "update:pagination": function ($event) {
                  _vm.pagination = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "items",
                  fn: function (props) {
                    return [
                      _c("td", [_vm._v(_vm._s(props.item.id))]),
                      _c("td", [_vm._v(_vm._s(props.item.fromNumber))]),
                      _c("td", [_vm._v(_vm._s(props.item.policyName))]),
                      _c("td", [
                        _vm._v(
                          " " +
                            _vm._s(
                              props.item.callType === "WorkInProgress"
                                ? "Work In Progress"
                                : props.item.callType
                            ) +
                            " "
                        ),
                      ]),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(props.item.position)),
                      ]),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _c("div", { staticClass: "setpriorty-colum" }, [
                          _c(
                            "a",
                            {
                              class:
                                _vm.showNumber &&
                                _vm.currentIndex === props.index
                                  ? "hide"
                                  : "show",
                              on: {
                                click: function ($event) {
                                  return _vm.editPriority(props.index)
                                },
                              },
                            },
                            [_c("b", [_vm._v(_vm._s(props.item.priority))])]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "setpriority",
                              class:
                                _vm.showNumber &&
                                _vm.currentIndex === props.index
                                  ? "show"
                                  : "hide",
                            },
                            [
                              _c(
                                "span",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "testing",
                                      name: "input-1",
                                      "hide-details": "",
                                      value: props.item.priority,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.changePriority(
                                          props.index,
                                          props.item.priority
                                        )
                                      },
                                    },
                                    model: {
                                      value: props.item.priority,
                                      callback: function ($$v) {
                                        _vm.$set(props.item, "priority", $$v)
                                      },
                                      expression: "props.item.priority",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "span",
                            { staticClass: "btn-content" },
                            [
                              props.item.position != 1
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        icon: "",
                                                        color: "secondary",
                                                        flat: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changePriority(
                                                            props.index,
                                                            999
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("arrow_upward")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Move top")])]
                                  )
                                : _vm._e(),
                              props.item.position != _vm.callList.length
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: {
                                                        icon: "",
                                                        color: "secondary",
                                                        flat: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changePriority(
                                                            props.index,
                                                            1
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("arrow_downward")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Move bottom")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("td", { staticClass: "text-xs-center" }, [
                        _vm._v(_vm._s(_vm.timerCounter(props.item))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }