var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: _vm.wrapperClass,
      attrs: {
        id:
          _vm.chatTypeEnum[_vm.chatTypeEnum.Pie] === _vm.chartType
            ? "isPie"
            : _vm.chatTypeEnum[_vm.chatTypeEnum.Bar] === _vm.chartType
            ? "isBar"
            : _vm.chatTypeEnum[_vm.chatTypeEnum.Line] === _vm.chartType
            ? "isLine"
            : "chart-area",
      },
    },
    [
      _vm.chatTypeEnum[_vm.chatTypeEnum.Pie] === _vm.chartType
        ? _c("canvas", {
            ref: "chartElement",
            attrs: { width: 100, height: 65 },
          })
        : _vm.chatTypeEnum[_vm.chatTypeEnum.Bar] === _vm.chartType
        ? _c("canvas", {
            ref: "chartElement",
            attrs: { width: 100, height: 65 },
          })
        : _vm.chatTypeEnum[_vm.chatTypeEnum.Line] === _vm.chartType
        ? _c("canvas", { ref: "chartElement", attrs: { width: 22, height: 5 } })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }