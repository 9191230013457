var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard", attrs: { id: _vm.tableName } },
    [
      _c(
        "div",
        [
          _c(
            "v-layout",
            {
              class: _vm.openJobView ? "hide-dashboard" : "show-dashboard",
              attrs: { wrap: "" },
            },
            [
              _c(
                "v-flex",
                { staticClass: "gridView" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "undeployed-jobcount": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "request-info": "" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0 pt-0" },
                            [
                              !_vm.isFromMainDashBoard
                                ? _c("h3", { staticClass: "pt-4" }, [
                                    _vm._v("Undeployed Emergencies"),
                                  ])
                                : _vm._e(),
                              _c("v-switch", {
                                class: [
                                  !_vm.isFromMainDashBoard ? "pl-4" : "",
                                  "mt-4",
                                ],
                                attrs: {
                                  color: "primary",
                                  small: "",
                                  label: "Auto-Deploying Jobs",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.includeAutoDeployingJobs,
                                  callback: function ($$v) {
                                    _vm.includeAutoDeployingJobs = $$v
                                  },
                                  expression: "includeAutoDeployingJobs",
                                },
                              }),
                              _c("v-spacer"),
                              _c("v-text-field", {
                                staticClass: "mr-4 fix-width50",
                                attrs: {
                                  "append-icon": "search",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.searchValueLocal,
                                  callback: function ($$v) {
                                    _vm.searchValueLocal = $$v
                                  },
                                  expression: "searchValueLocal",
                                },
                              }),
                              _c("v-autocomplete", {
                                staticClass: "fix-width50",
                                attrs: {
                                  label: "Search team",
                                  maxlength: "50",
                                  items: _vm.teamList,
                                  loading: _vm.getteamRunning,
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.selectedTeamName,
                                  callback: function ($$v) {
                                    _vm.selectedTeamName = $$v
                                  },
                                  expression: "selectedTeamName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "elevation-0 grey lighten-3 white--text",
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  staticClass:
                                    "px-2 pb-0 pt-2 grey--text grey--text text--darken-4",
                                },
                                [_vm._v(" Undeployed Trade Jobs ")]
                              ),
                              _vm.undeployedJobTrades.length > 0
                                ? _c("v-card-text", { staticClass: "pa-1" }, [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-chip",
                                          {
                                            staticClass:
                                              "grey grey--text text--darken-4",
                                            class:
                                              _vm.currentIndex === -1
                                                ? "lighten-1"
                                                : "lighten-2",
                                            attrs: { label: "" },
                                            on: {
                                              click: _vm.onFilterByAllTrades,
                                            },
                                          },
                                          [
                                            _vm._v(" All   "),
                                            _c("b", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.undeployedJobTrades.reduce(
                                                      (a, b) => a + b.jobCount,
                                                      0
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.undeployedJobTrades,
                                          function (undeployedJobTrade, i) {
                                            return _c(
                                              "span",
                                              { key: i },
                                              [
                                                _c(
                                                  "v-chip",
                                                  {
                                                    staticClass:
                                                      "grey grey--text text--darken-2",
                                                    class:
                                                      _vm.currentIndex === i
                                                        ? "lighten-1"
                                                        : "lighten-2",
                                                    attrs: { label: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onFilterByTrade(
                                                          undeployedJobTrade.trade,
                                                          i
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          undeployedJobTrade.trade
                                                        ) +
                                                        "   "
                                                    ),
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          undeployedJobTrade.jobCount
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "elevation-1",
                          attrs: { xs12: "", "mt-4": "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "table-responsive" },
                            [
                              _c("v-data-table", {
                                staticClass: "custom-data-table",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.jobFilteredItems,
                                  loading: _vm.isLoading,
                                  pagination: _vm.paginationLocal,
                                  search: _vm.searchValueLocal,
                                  "custom-filter": _vm.customFilter,
                                  "custom-sort": _vm.customSort,
                                },
                                on: {
                                  "update:pagination": function ($event) {
                                    _vm.paginationLocal = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c("tr", {
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.selected = !props.selected
                                            },
                                          },
                                        }),
                                        _c("tr", [
                                          _c("td", [
                                            _vm.isFromMainDashBoard
                                              ? _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.redirectToJob(
                                                          props.item.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(props.item.id)
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "primary--text",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(props.item.id)
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "pl-1 grey--text",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.item.indexCount
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item
                                                  .undeployedEmergencies[0]
                                                  .vulnerabilityLevel || "-"
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.policyNumber)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.name)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.loggedByTeamName
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.postCode)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.addressLine3)
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item
                                                    .undeployedEmergencies[0]
                                                    .tradesDescription
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-center" },
                                            [
                                              props.item
                                                .undeployedEmergencies[0]
                                                .isAutoDeploymentRunning
                                                ? _c("span", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "blink_text",
                                                      },
                                                      [_vm._v("Auto Deploying")]
                                                    ),
                                                    _vm._v(
                                                      " (" +
                                                        _vm._s(
                                                          props.item
                                                            .undeployedEmergencies[0]
                                                            .deploymentAttempt
                                                        ) +
                                                        ") "
                                                    ),
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          props.item
                                                            .undeployedEmergencies[0]
                                                            .deploymentAttempt
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item
                                                    .undeployedEmergencies[0]
                                                    .loggedAt
                                                    ? _vm.getFormattedDateTime(
                                                        props.item
                                                          .undeployedEmergencies[0]
                                                          .loggedAt
                                                      )
                                                    : _vm.getFormattedDateTime(
                                                        props.item.createdAt
                                                      )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item
                                                      .lastVisitByUserName
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.lastVisitAt
                                                    ? _vm.getFormattedDateTime(
                                                        props.item.lastVisitAt
                                                      )
                                                    : null
                                                ) +
                                                " "
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "snakbars-alert" },
        [
          _c(
            "v-snackbar",
            {
              staticClass: "pa-0",
              attrs: { bottom: true, left: true, timeout: _vm.snackbarTimeout },
              model: {
                value: _vm.showSnackbar,
                callback: function ($$v) {
                  _vm.showSnackbar = $$v
                },
                expression: "showSnackbar",
              },
            },
            [
              _vm.deploymentStatusSnackbar.length > 0
                ? _c(
                    "div",
                    { staticClass: "job-notification pt-3" },
                    _vm._l(_vm.deploymentStatusSnackbar, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "white--text grey darken-4",
                          class: item.items.length > 0 ? "mb-3 pa-2" : "pa-0",
                        },
                        [
                          item.items.length > 0
                            ? _c(
                                "div",
                                [
                                  _c("h3", { staticClass: "secondary--text" }, [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getDeploymentStatus(item.type)
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._l(item.items, function (jobHeader, j) {
                                    return _c(
                                      "div",
                                      { key: j },
                                      [
                                        _c("v-divider", {
                                          staticClass: "my-2 grey darken-3",
                                        }),
                                        _c("span", [_vm._v(_vm._s(jobHeader))]),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", flat: "" },
                  on: { click: _vm.onSnackbarClose },
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PartialJobView", {
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }