var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pt-0 px-2 pb-2 up-card customer-payment" },
    [
      _c("v-card-title", [
        _c("h2", { staticClass: "heading" }, [_vm._v("Payment Detail")]),
      ]),
      _c("v-divider"),
      _c(
        "div",
        { staticClass: "amount-info" },
        [
          _c(
            "v-layout",
            {
              attrs: {
                wrap: "",
                grey: "",
                "lighten-4": "",
                "my-2": "",
                "px-3": "",
                "pb-3": "",
              },
            },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c(
                  "div",
                  { staticClass: "prepenpound-icon" },
                  [
                    _c(
                      "span",
                      {
                        staticClass:
                          "primary--text headlinefont title pound-icon",
                      },
                      [_vm._v("£  ")]
                    ),
                    _c("v-text-field", {
                      attrs: {
                        "prepend-icon": "close",
                        label: "Amount",
                        readonly: _vm.transactionComplete,
                      },
                      model: {
                        value: _vm.amount,
                        callback: function ($$v) {
                          _vm.amount = $$v
                        },
                        expression: "amount",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "payment-form px-3" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _vm.job.emergencies.length > 1
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Emergency Type",
                          items: _vm.job.emergencies,
                          "item-text": "typeDescription",
                          "item-value": "typeId",
                          "data-vv-name": "EmergencyType",
                          "data-vv-scope": "frmCustomerToPayPreview",
                          required: "",
                          "error-messages":
                            _vm.validationMessage("EmergencyType"),
                          readonly: _vm.transactionComplete,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onEmergencyTypeChange($event)
                          },
                        },
                        model: {
                          value: _vm.emergencyTypeId,
                          callback: function ($$v) {
                            _vm.emergencyTypeId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "emergencyTypeId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.job.emergencies.length > 1
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Emergency Detail",
                          items: _vm.jobEmergencyDetailList,
                          "item-text": "detailDescription",
                          "item-value": "detailId",
                          "data-vv-name": "EmergencyDetail",
                          "data-vv-scope": "frmCustomerToPayPreview",
                          required: "",
                          "error-messages":
                            _vm.validationMessage("EmergencyDetail"),
                          readonly: _vm.transactionComplete,
                        },
                        model: {
                          value: _vm.emergencyDetailId,
                          callback: function ($$v) {
                            _vm.emergencyDetailId =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "emergencyDetailId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Card Holder Name",
                      readonly: _vm.transactionComplete,
                    },
                    model: {
                      value: _vm.customerToPayModel.cardHolderName,
                      callback: function ($$v) {
                        _vm.$set(_vm.customerToPayModel, "cardHolderName", $$v)
                      },
                      expression: "customerToPayModel.cardHolderName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { md12: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Billing Address same as job address",
                      color: "primary",
                      "hide-details": "",
                      disabled: _vm.transactionComplete,
                    },
                    model: {
                      value: _vm.addressCheckbox,
                      callback: function ($$v) {
                        _vm.addressCheckbox = $$v
                      },
                      expression: "addressCheckbox",
                    },
                  }),
                ],
                1
              ),
              !_vm.addressCheckbox
                ? _c(
                    "v-layout",
                    { attrs: { row: "", wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pr-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Address Line1",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value:
                                _vm.customerToPayModel.billingAddress.line1,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "line1",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.line1",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Address Line2",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value:
                                _vm.customerToPayModel.billingAddress.line2,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "line2",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.line2",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pr-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "City",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value: _vm.customerToPayModel.billingAddress.city,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "city",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.city",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "State",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value:
                                _vm.customerToPayModel.billingAddress.state,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "state",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.state",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pr-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Country",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value:
                                _vm.customerToPayModel.billingAddress.country,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "country",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.country",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-2", attrs: { xs6: "" } },
                        [
                          _c("v-text-field", {
                            ref: "postcode",
                            attrs: {
                              id: "postal-code",
                              name: "postal_code",
                              label: "Postal code",
                              readonly: _vm.transactionComplete,
                            },
                            model: {
                              value:
                                _vm.customerToPayModel.billingAddress.postcode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.customerToPayModel.billingAddress,
                                  "postcode",
                                  $$v
                                )
                              },
                              expression:
                                "customerToPayModel.billingAddress.postcode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticClass: "justify-end mt-3",
                    attrs: {
                      color: "primary",
                      label: "Take manual payment",
                      disabled: _vm.paymentToggleDisabled,
                    },
                    model: {
                      value: _vm.manualPaymentToggle,
                      callback: function ($$v) {
                        _vm.manualPaymentToggle = $$v
                      },
                      expression: "manualPaymentToggle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-layout",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.manualPaymentToggle,
                      expression: "manualPaymentToggle",
                    },
                  ],
                  attrs: {
                    wrap: "",
                    grey: "",
                    "lighten-4": "",
                    "px-3": "",
                    "pb-3": "",
                  },
                },
                [
                  !_vm.transactionComplete
                    ? _c(
                        "v-flex",
                        {
                          class: _vm.transactionComplete
                            ? "payment-card-disabled"
                            : "",
                          attrs: { xs12: "" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "group input-group input-fc" },
                            [
                              _c("div", {
                                ref: "card",
                                staticClass: "field input--focus",
                              }),
                              _c("label", [_vm._v("Card")]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.transactionComplete
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", "mt-3": "", "mb-1": "" } },
                        [
                          _c(
                            "p",
                            { staticClass: "mb-2" },
                            [
                              _c("v-icon", [_vm._v("credit_card")]),
                              _c("b", [
                                _vm._v(
                                  "**** **** **** " +
                                    _vm._s(
                                      _vm.customerToPayModel.cardLastFourDigit
                                    )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.manualPaymentToggle &&
              (_vm.currentCallSid || _vm.transactionComplete)
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "", grey: "", "lighten-4": "" } },
                    [
                      _c("AssistedPay", {
                        attrs: {
                          callSid: _vm.currentCallSid,
                          showCompleted: _vm.transactionComplete,
                        },
                        on: {
                          inProgressChanged: _vm.agentAssistedProgressChanged,
                          clearErrorMessage: _vm.clearErrorMessage,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.manualPaymentToggle &&
              !(_vm.currentCallSid || _vm.transactionComplete)
                ? _c(
                    "v-layout",
                    { attrs: { wrap: "", grey: "", "lighten-4": "" } },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "ma-3 font-weight-bold text-center pa-2 pl-3 call-sid-text",
                        },
                        [
                          _vm._v(
                            " !   You must be on a call to take payment details "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-checkbox", {
                    attrs: {
                      label: "Do you want a payment receipt emailed to you?",
                      color: "primary",
                      "hide-details": "",
                      readonly: _vm.transactionComplete,
                      disabled: _vm.transactionComplete,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onRequiredReceiptOptionChange(
                          _vm.requiredReceipt
                        )
                      },
                    },
                    model: {
                      value: _vm.requiredReceipt,
                      callback: function ($$v) {
                        _vm.requiredReceipt = $$v
                      },
                      expression: "requiredReceipt",
                    },
                  }),
                ],
                1
              ),
              _vm.requiredReceipt
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:50|email",
                            expression: "'required|max:50|email'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Email Address",
                          "data-vv-scope": "frmCustomerToPayPreview",
                          "data-vv-name": "Email Address",
                          "error-messages":
                            _vm.validationMessage("Email Address"),
                          required: "",
                          readonly: _vm.transactionComplete,
                          disabled: _vm.transactionComplete,
                        },
                        model: {
                          value: _vm.customerToPayModel.cardHolderEmail,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.customerToPayModel,
                              "cardHolderEmail",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "customerToPayModel.cardHolderEmail",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                {
                  class: !_vm.requiredReceipt ? "pt-3" : "",
                  attrs: { xs12: "" },
                },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          max: 22,
                          regex: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ]*)$/,
                          required: true,
                        },
                        expression:
                          "{ max: 22, regex: /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ]*)$/, required: true }",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Statement Description",
                      readonly: _vm.transactionComplete,
                      "data-vv-scope": "frmCustomerToPayPreview",
                      "data-vv-name": "Statement Description",
                      "error-messages": _vm.validationMessage(
                        "Statement Description"
                      ),
                      required: "",
                    },
                    model: {
                      value: _vm.customerToPayModel.statementDescription,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.customerToPayModel,
                          "statementDescription",
                          $$v
                        )
                      },
                      expression: "customerToPayModel.statementDescription",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  class: !_vm.requiredReceipt ? "pt-3" : "",
                  attrs: { xs12: "" },
                },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required sel-paymentType",
                    attrs: {
                      "data-vv-scope": "frmCustomerToPayPreview",
                      "data-vv-name": "Payment Type",
                      items: _vm.paymentTypes,
                      "item-label": "label",
                      "item-value": "value",
                      label: "Payment Type",
                      placeholder: "Please select a payment type",
                      readonly: _vm.transactionComplete,
                      "error-messages": _vm.validationMessage("Payment Type"),
                      required: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "flex flex-col" }, [
                              _c("div", [_vm._v(_vm._s(item.label))]),
                              _c(
                                "div",
                                { staticClass: "text--secondary text-lg" },
                                [_vm._v(_vm._s(item.description))]
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "selection",
                        fn: function ({ item }) {
                          return [_c("span", [_vm._v(_vm._s(item.label))])]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.paymentType,
                      callback: function ($$v) {
                        _vm.paymentType = $$v
                      },
                      expression: "paymentType",
                    },
                  }),
                ],
                1
              ),
              _c("v-flex", [
                _vm.errorMessage
                  ? _c("div", { staticClass: "mr-3 error-info error--text" }, [
                      _vm._v(_vm._s(_vm.errorMessage)),
                    ])
                  : _vm.customerToPayModel.isTransactionCancelled
                  ? _c("div", { staticClass: "mr-3 error-info error--text" }, [
                      _vm._v("Cancelled"),
                    ])
                  : _vm.transactionComplete
                  ? _c(
                      "div",
                      { staticClass: "mr-3 error-info success--text" },
                      [_c("b", [_vm._v("Payment done successfully!")])]
                    )
                  : _vm._e(),
              ]),
              _c("v-spacer"),
              _c(
                "v-flex",
                { staticClass: "text-xs-right" },
                [
                  (
                    _vm.job.status !== "Cancelled" &&
                    !_vm.job.cancellationReason
                      ? !_vm.transactionComplete
                      : false
                  )
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 btn-payment",
                          attrs: {
                            color: "primary",
                            type: "submit",
                            disabled:
                              _vm.transactionComplete ||
                              _vm.amount <= 0 ||
                              _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.pay },
                        },
                        [_vm._v(" Pay ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }