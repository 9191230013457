var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "mb-2", attrs: { wrap: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", "mb-2": "" } },
        [
          !_vm.areQuestionsAnswered
            ? _c(
                "v-alert",
                {
                  staticClass: "custom-warning-error pa-2",
                  attrs: {
                    value: !_vm.areQuestionsAnswered,
                    color: "error lighten-5 red--text",
                    type: "error",
                  },
                },
                [_c("span", [_vm._v("Questions outstanding!")])]
              )
            : _vm._e(),
          _vm.areQuestionsAnswered
            ? _c(
                "v-alert",
                {
                  staticClass: "custom-warning-success pa-2",
                  attrs: {
                    value: _vm.areQuestionsAnswered,
                    color: "green lighten-5 green--text",
                    type: "success",
                  },
                },
                [_c("span", [_vm._v("Questions confirmed!")])]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }