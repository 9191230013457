var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.cardHolderName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("CardHolder Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.cardHolderName))]),
        ])
      : _vm._e(),
    _vm.item.addressLine1
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line1: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine1))]),
        ])
      : _vm._e(),
    _vm.item.addressLine2
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Address Line2: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.addressLine2))]),
        ])
      : _vm._e(),
    _vm.item.city
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("City: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.city))]),
        ])
      : _vm._e(),
    _vm.item.state
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("State: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.state))]),
        ])
      : _vm._e(),
    _vm.item.country
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Country: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.country))]),
        ])
      : _vm._e(),
    _vm.item.postcode
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Post Code: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.postcode))]),
        ])
      : _vm._e(),
    _vm.item.amount
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Amount: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.amount))]),
        ])
      : _vm._e(),
    _vm.item.statementDescription
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Statement Description: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.statementDescription))]),
        ])
      : _vm._e(),
    _vm.item.cardBrand
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Card Brand: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.cardBrand))]),
        ])
      : _vm._e(),
    _vm.item.cardLastFourDigit
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Card Last 4 Digit: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.cardLastFourDigit))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Customer Paid: ")]),
      _c("span", [
        _vm._v(_vm._s(_vm.item.isTransactionCompleted ? "Yes" : "No")),
      ]),
    ]),
    _vm.getPaymentDate
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Payment Date: ")]),
          _c("span", [_vm._v(_vm._s(_vm.getPaymentDate))]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "mb-1" }, [
      _c("label", [_vm._v("Email: ")]),
      _c("span", [
        _vm._v(
          _vm._s(_vm.item.cardHolderEmail ? _vm.item.cardHolderEmail : "-")
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Customer to Pay")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }