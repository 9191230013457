var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.mobilePhone
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Mobile Phone: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.mobilePhone))]),
        ])
      : _vm._e(),
    _vm.item.email
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Email: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.email))]),
        ])
      : _vm._e(),
    _vm.item.contactPreference
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Contact Preference: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.contactPreference))]),
        ])
      : _vm._e(),
    _vm.item.reasonForChoosingCallOption
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Call Preference Reason: ")]),
          _c("pre", [_vm._v(_vm._s(_vm.item.reasonForChoosingCallOption))]),
        ])
      : _vm._e(),
    _vm.item.nomineeClientTitle
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Title: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeClientTitle))]),
        ])
      : _vm._e(),
    _vm.item.nomineeClientForename
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee First Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeClientForename))]),
        ])
      : _vm._e(),
    _vm.item.nomineeClientLastName
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Last Name: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeClientLastName))]),
        ])
      : _vm._e(),
    _vm.item.nomineeMobilePhone
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Mobile Phone: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeMobilePhone))]),
        ])
      : _vm._e(),
    _vm.item.nomineeEmail
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Email: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeEmail))]),
        ])
      : _vm._e(),
    _vm.item.nomineeContactPreference
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Contact Preference: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.nomineeContactPreference))]),
        ])
      : _vm._e(),
    _vm.item.nomineeReasonForChoosingCallOption
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Nominee Call Preference Reason: ")]),
          _c("pre", [
            _vm._v(_vm._s(_vm.item.nomineeReasonForChoosingCallOption)),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update Customer Detail")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }