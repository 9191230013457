var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Emergency Question")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeEmergencyQuestionDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-3 pb-3 mt-2 scroll-content-dialog" },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _vm.getEmergencyQuestionModel.id
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "border_zeroalert px-0 pb-1 pt-0",
                              attrs: {
                                value: true,
                                color: "warning",
                                icon: "priority_high",
                                outline: "",
                              },
                            },
                            [
                              _vm._v(
                                " It is important that the meaning of the question is not altered. "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasValidation
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-alert",
                            {
                              staticClass: "border_zeroalert px-0 pb-1 pt-0",
                              attrs: {
                                value: true,
                                color: "warning",
                                icon: "priority_high",
                                outline: "",
                              },
                            },
                            [
                              _vm._v(
                                " Validation and additional Information may be affected on changing answer type and additional values. "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:500",
                            expression: "'required|max:500'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Question",
                          maxlength: "500",
                          required: "",
                          "data-vv-scope": "frmEmergencyQuestion",
                          "data-vv-name": "Question",
                          "error-messages": _vm.errors.collect("Question"),
                        },
                        model: {
                          value: _vm.getEmergencyQuestionModel.text,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getEmergencyQuestionModel,
                              "text",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "getEmergencyQuestionModel.text",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items: _vm.getAnswertype,
                          label: "Answertype",
                          "item-text": "description",
                          "item-value": "id",
                          required: "",
                          "data-vv-scope": "frmEmergencyQuestion",
                          "data-vv-name": "Answertype",
                          "error-messages": _vm.errors.collect("Answertype"),
                        },
                        model: {
                          value: _vm.getEmergencyQuestionModel.answerTypeId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getEmergencyQuestionModel,
                              "answerTypeId",
                              $$v
                            )
                          },
                          expression: "getEmergencyQuestionModel.answerTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.getEmergencyQuestionModel.answerTypeId ===
                  _vm.answerType.Dropdown
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|max:2000",
                                expression: "'required|max:2000'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Additional Values",
                              maxlength: "2000",
                              hint: "Enter each option in separate line",
                              required: "",
                              "data-vv-scope": "frmEmergencyQuestion",
                              "data-vv-name": "Additional Values",
                              "error-messages":
                                _vm.errors.collect("Additional Values"),
                            },
                            model: {
                              value:
                                _vm.getEmergencyQuestionModel.additionalValues,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.getEmergencyQuestionModel,
                                  "additionalValues",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "getEmergencyQuestionModel.additionalValues",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getEmergencyQuestionModel.answerTypeId ===
                  _vm.answerType.Number
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:20",
                                expression: "'max:20'",
                              },
                            ],
                            attrs: {
                              label: "Unit",
                              maxlength: "20",
                              "data-vv-scope": "frmEmergencyQuestion",
                              "data-vv-name": "Unit",
                              "error-messages": _vm.errors.collect("Unit"),
                            },
                            model: {
                              value: _vm.getEmergencyQuestionModel.unit,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.getEmergencyQuestionModel,
                                  "unit",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "getEmergencyQuestionModel.unit",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: "Note",
                          "hide-details": "",
                          rows: "3",
                          name: "questionNote",
                          maxlength: "300",
                        },
                        model: {
                          value: _vm.getEmergencyQuestionModel.note,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.getEmergencyQuestionModel,
                              "note",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "getEmergencyQuestionModel.note",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.jobType === "HE"
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", "mt-4": "" } },
                        [
                          _c("AddEmergencyAgainstAnswer", {
                            ref: "addEmergencyAgainstAnswerRef",
                            attrs: {
                              "get-emergency-question-model":
                                _vm.getEmergencyQuestionModel,
                              "third-party-detail": _vm.thirdPartyDetail,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-4": "" } },
                    [
                      _c("EmergencyAssignTypesAgainstAnswer", {
                        ref: "tradeEmergencyAnswerComparison",
                        attrs: {
                          title: "Assign Trade Type based on Answer",
                          "emergency-answer-comparison-for": "Trade",
                          "get-emergency-question-model":
                            _vm.getEmergencyQuestionModel,
                          trades: _vm.trades,
                          "answer-type": _vm.answerType,
                          "add-button-tooltip-text": "Add trade against answer",
                          "duplicate-question-error-message":
                            "Selected operator and comparison value pair already exist.",
                        },
                        on: { isHasDuplicateTrade: _vm.isHasDuplicateTrade },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-4": "" } },
                    [
                      _c("EmergencyAssignTypesAgainstAnswer", {
                        ref: "asbestosEmergencyAnswerComparison",
                        attrs: {
                          title: "Assign Asbestos Risk based on Answer",
                          "emergency-answer-comparison-for": "Asbestos",
                          "get-emergency-question-model":
                            _vm.getEmergencyQuestionModel,
                          "answer-type": _vm.answerType,
                          "add-button-tooltip-text":
                            "Add Asbestos Risk against answer",
                          "duplicate-question-error-message":
                            "Selected operator and comparison value pair already exist.",
                        },
                        on: {
                          isHasDuplicateAsbestos: _vm.isHasDuplicateAsbestos,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "flat" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeEmergencyQuestionDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary",
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.saveEmergencyQuestion.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }