var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "internal-phone-number" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-layout",
            { attrs: { row: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "contact-number", attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    staticClass: "selectedCountry pt-0",
                    attrs: {
                      items: _vm.countries,
                      "append-icon": "",
                      "hide-details": "",
                      label: "Code",
                      "single-line": "",
                      disabled: _vm.isDisabled,
                    },
                    on: { change: _vm.changedCountryValue },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function (data) {
                          return [
                            _vm._v(" " + _vm._s(data.item.cc || "INT") + " "),
                          ]
                        },
                      },
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c("v-list-tile-avatar", [
                              _c("div", {
                                staticClass: "test",
                                class: "flag flag-" + data.item.code,
                              }),
                            ]),
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-sub-title", {
                                  domProps: {
                                    textContent: _vm._s(
                                      data.item.name +
                                        (data.item.cc
                                          ? " (" + data.item.cc + ")"
                                          : "")
                                    ),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedCountry,
                      callback: function ($$v) {
                        _vm.selectedCountry = $$v
                      },
                      expression: "selectedCountry",
                    },
                  }),
                  _vm.isValidationRequired
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        ref: "txtNumber",
                        staticClass: "call-number-txt pt-0 mt-0 required",
                        attrs: {
                          "single-line": "",
                          label: "Enter Number",
                          disabled: _vm.isDisabled,
                          required: "",
                          "data-vv-name": "Contact Number",
                          name: "ContactNumber",
                          "error-messages":
                            _vm.NumberValidationMessage("Contact Number"),
                        },
                        on: { input: _vm.phoneNumberUpdated },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function ($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber",
                        },
                      })
                    : _c("v-text-field", {
                        ref: "txtNumber",
                        staticClass: "call-number-txt pt-0 mt-0 not-required",
                        attrs: {
                          "single-line": "",
                          "hide-details": "",
                          disabled: _vm.isDisabled,
                          label: "Enter Number",
                        },
                        on: { input: _vm.phoneNumberUpdated },
                        model: {
                          value: _vm.phoneNumber,
                          callback: function ($$v) {
                            _vm.phoneNumber = $$v
                          },
                          expression: "phoneNumber",
                        },
                      }),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "mouse-hold",
                          rawName: "v-mouse-hold",
                          value: _vm.deleteHoldConfig,
                          expression: "deleteHoldConfig",
                        },
                      ],
                      staticClass: "backspace-icon ma-0",
                      attrs: { icon: "", small: "", flat: "", color: "grey" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.deleteDigit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("backspace"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "text-xs-right validation" },
                [
                  _vm.isValid || _vm.validationMessage
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "grey" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _vm.isValid
                                      ? _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                small: "",
                                                color: "green",
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("check_circle")]
                                        )
                                      : _vm.validationMessage
                                      ? _c(
                                          "v-icon",
                                          _vm._g(
                                            {
                                              attrs: {
                                                small: "",
                                                color: "red",
                                              },
                                            },
                                            on
                                          ),
                                          [_vm._v("error")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3601676307
                          ),
                        },
                        [
                          _vm.validationMessage
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.validationMessage)),
                              ])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "hide" },
            [
              _c(
                "v-flex",
                { staticClass: "conuntry-list" },
                _vm._l(_vm.countries, function (item, index) {
                  return _c(
                    "v-list",
                    { key: index, attrs: { "tow-line": "" } },
                    [
                      _c(
                        "v-list-tile",
                        [
                          _c("v-list-tile-avatar", [
                            _c("div", { class: "flag flag-" + item.code }),
                          ]),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-sub-title", {
                                domProps: {
                                  textContent: _vm._s(
                                    item.name + " (" + item.cc + ")"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }