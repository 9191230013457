var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { "pr-2": "", xs6: "" } },
            [
              _c(
                "v-flex",
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required returnVisitType",
                    attrs: {
                      items: _vm.returnVisitTypeList,
                      "item-text": "description",
                      "item-value": "description",
                      label: "What is the type for return visit",
                      required: "",
                      "data-vv-name": "Return Visit Type",
                      "error-messages": _vm.errors.collect("Return Visit Type"),
                    },
                    on: { change: _vm.onReturnVisitTypeChange },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-list-tile",
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c(
                                      "v-list-tile-title",
                                      {
                                        class:
                                          data.item.description === "Configure"
                                            ? "bold-select"
                                            : "",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(data.item.description) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.value.returnVisitType,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "returnVisitType", $$v)
                      },
                      expression: "value.returnVisitType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { "pl-2": "", xs6: "" } },
            [
              _c("DateTimePicker", {
                ref: "dateTimePickerAdditionalReturnVisitRequest",
                attrs: {
                  "date-time": _vm.returnVisitAtUtc,
                  "is-static-location": false,
                  "place-holder-text": "Select return visit date",
                  "min-date": _vm.minReturnVisitDate,
                  "is-current-time": true,
                  "show-present-time-icon": true,
                },
                on: {
                  "update:dateTime": function ($event) {
                    _vm.returnVisitAtUtc = $event
                  },
                  "update:date-time": function ($event) {
                    _vm.returnVisitAtUtc = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "pr-1", attrs: { xs6: "" } },
            [
              _c("v-select", {
                staticClass: "estimatedVisitTimeHour",
                attrs: {
                  items: _vm.estimatedVisitTimeHourList,
                  label: "Estimate Visit Time (Hours)",
                  "item-text": "text",
                  "item-value": "value",
                },
                on: {
                  change: function ($event) {
                    return _vm.onVisitTimeHourChange($event)
                  },
                },
                model: {
                  value: _vm.estimatedVisitTimeHour,
                  callback: function ($$v) {
                    _vm.estimatedVisitTimeHour = $$v
                  },
                  expression: "estimatedVisitTimeHour",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "pl-1", attrs: { xs6: "" } },
            [
              _c("v-select", {
                staticClass: "estimatedVisitTimeMinute",
                attrs: {
                  items: _vm.estimatedVisitTimeMinuteList,
                  label: "Estimate Visit Time (Minutes)",
                  "item-text": "text",
                  "item-value": "value",
                },
                on: {
                  change: function ($event) {
                    return _vm.onVisitTimeMinuteChange($event)
                  },
                },
                model: {
                  value: _vm.estimatedVisitTimeMinute,
                  callback: function ($$v) {
                    _vm.estimatedVisitTimeMinute = $$v
                  },
                  expression: "estimatedVisitTimeMinute",
                },
              }),
            ],
            1
          ),
          !_vm.isVisitTimeValid
            ? _c(
                "v-flex",
                {
                  staticClass: "wrong-visit-time",
                  attrs: { xs12: "", "mb-1": "" },
                },
                [
                  _c("span", { staticClass: "warning--text" }, [
                    _vm._v(
                      "Estimated visit duration time must be greater than 0."
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  id: "ReturnVisitReason",
                  name: "returnVisitRequestReason",
                  label: "Reason",
                  required: "",
                  "data-vv-name": "Return Visit Reason",
                  "error-messages": _vm.errors.collect("Return Visit Reason"),
                },
                model: {
                  value: _vm.value.reasonForReturnVisit,
                  callback: function ($$v) {
                    _vm.$set(_vm.value, "reasonForReturnVisit", $$v)
                  },
                  expression: "value.reasonForReturnVisit",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }