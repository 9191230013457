var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { md8: "", lg9: "", xs8: "", "mb-2": "" } }, [
        _c("div", { staticClass: "small-text" }, [
          _vm._v(_vm._s(_vm.getSoftFixedEmergency.description)),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }