var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.localQA
        ? _c(
            "v-card",
            {
              staticClass: "mb-3 vulnerable-card-preview",
              attrs: { elevation: _vm.embedded ? "0" : undefined },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs11: "" } }, [
                        _c("h3", [_vm._v(_vm._s(_vm.localQA.questionText))]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "", "text-xs-right": "" } },
                        [
                          _vm.syncing
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "processing-icon",
                                  attrs: { medium: "" },
                                },
                                [_vm._v("sync")]
                              )
                            : _vm.localQA.isHealthAffected !== null
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "success",
                                  },
                                },
                                [_vm._v("check_circle")]
                              )
                            : _vm.mandatory
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "orange",
                                  },
                                },
                                [_vm._v("info")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-radio-group",
                    {
                      on: {
                        focus: _vm.editStarted,
                        change: function ($event) {
                          return _vm.editComplete()
                        },
                      },
                      model: {
                        value: _vm.localQA.isHealthAffected,
                        callback: function ($$v) {
                          _vm.$set(_vm.localQA, "isHealthAffected", $$v)
                        },
                        expression: "localQA.isHealthAffected",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: "Yes",
                          name: "healthAffected",
                          color: "primary",
                          value: true,
                          disabled: _vm.getAcceptRejectQuestionCriteria,
                        },
                      }),
                      _c("v-radio", {
                        attrs: {
                          label: "No",
                          name: "healthAffected",
                          color: "primary",
                          value: false,
                          disabled: _vm.getAcceptRejectQuestionCriteria,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.localQA.level
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.getLevel,
                          label: "Level",
                          "item-text": "level",
                          "item-value": "id",
                          disabled: _vm.getAcceptRejectQuestionCriteria,
                        },
                        on: {
                          focus: _vm.editStarted,
                          input: function ($event) {
                            return _vm.editComplete()
                          },
                        },
                        model: {
                          value: _vm.localQA.level,
                          callback: function ($$v) {
                            _vm.$set(_vm.localQA, "level", $$v)
                          },
                          expression: "localQA.level",
                        },
                      })
                    : _vm._e(),
                  _vm.localQA.isHealthAffected
                    ? _c("v-textarea", {
                        attrs: {
                          label: "Details",
                          name: "healthAffectedDetails",
                          disabled: _vm.getAcceptRejectQuestionCriteria,
                        },
                        on: {
                          focus: _vm.editStarted,
                          blur: function ($event) {
                            return _vm.editComplete()
                          },
                        },
                        model: {
                          value: _vm.localQA.answerDetail,
                          callback: function ($$v) {
                            _vm.$set(_vm.localQA, "answerDetail", $$v)
                          },
                          expression: "localQA.answerDetail",
                        },
                      })
                    : _vm._e(),
                  _vm.mandatory && _vm.localQA.isHealthAffected === null
                    ? _c("div", { staticClass: "answer-missing" }, [
                        _vm._v(" an answer to this question is mandatory "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                attrs: {
                  color: "primary",
                  height: "3",
                  value: "15",
                  indeterminate: true,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }