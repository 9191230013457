var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-container", { attrs: { fluid: "", "pa-0": "" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ready,
                expression: "ready",
              },
            ],
          },
          [
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c(
                  "v-flex",
                  {
                    staticClass: "gridView",
                    attrs: { sm8: "", md7: "", lg8: "" },
                  },
                  [
                    true
                      ? _c("JobHistoryTable", {
                          attrs: { "table-name": "jobDashboard" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  {
                    attrs: {
                      sm4: "",
                      md5: "",
                      lg4: "",
                      "mt-3": "",
                      "pt-5": "",
                      "pl-4": "",
                    },
                  },
                  [
                    _c(
                      "v-container",
                      {
                        staticClass: "grid-list-lg pt-1 pa-0",
                        attrs: { fluid: "" },
                      },
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              wrap: "",
                              "calls-info": "",
                              "request-info": "",
                            },
                          },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "py-0": "", "mb-4": "" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "elevation-1 blue white--text pa-0",
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      { staticClass: "px-2 pt-1 pb-0" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.titleTotalJobsLoggedInLast15Days
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pa-0" },
                                      [
                                        _vm.loggedJobs
                                          ? _c("DashboardChart", {
                                              attrs: {
                                                "chart-data": _vm.loggedJobs,
                                                "chart-type":
                                                  _vm.chartTypeEnum[
                                                    _vm.chartTypeEnum.Line
                                                  ],
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", "mb-1": "", "py-0": "" } },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "elevation-1 white white--grey pa-0 piechart-jobdeshboard",
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticClass:
                                          "px-2 pt-2 pb-0 subheading",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.titleTop5Emergencies) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "pa-0" },
                                      [
                                        _vm.isTop5DataAvailable &&
                                        _vm.loggedEmergencies &&
                                        _vm.loggedEmergencies.length > 0
                                          ? _c("DashboardChart", {
                                              attrs: {
                                                "chart-data":
                                                  _vm.loggedEmergencies,
                                                "chart-type":
                                                  _vm.chartTypeEnum[
                                                    _vm.chartTypeEnum.Pie
                                                  ],
                                              },
                                            })
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "data-not-availble-msg grey--text text--lighten-0 title",
                                              },
                                              [_vm._v(" Data not available ")]
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.ready,
                expression: "!ready",
              },
            ],
          },
          [
            _c("v-progress-circular", {
              staticClass: "loading-spinner",
              attrs: {
                width: 2,
                size: 50,
                indeterminate: "",
                color: "primary",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }