var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getSoftFixedEmergency && _vm.getUser
    ? _c("span", [
        _vm._v(" accepted by "),
        _c("b", { staticClass: "text-secondary" }, [
          _vm._v(_vm._s(_vm.getUser)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }