var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ClientList"),
      _vm.selectedClientId
        ? _c("ClientReports", {
            attrs: {
              "insurer-id": _vm.selectedClientId,
              "bordereau-id-to-open-details": _vm.bordereauIdToOpenDetails,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }