var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("v-progress-circular", {
            style: _vm.getLoaderStyle(70),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          })
        : _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pt-0 px-2 pb-2" },
                [
                  _c(
                    "v-card-title",
                    [
                      _c(
                        "v-layout",
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", { staticClass: "heading" }, [
                              _c("span", { staticClass: "teal--text" }, [
                                _vm._v("Financial Summary"),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    [
                      _vm.jobSummaryData
                        ? _c(
                            "v-layout",
                            {
                              staticClass: "jobSummaryData",
                              attrs: {
                                wrap: "",
                                "pa-2": "",
                                "blue-grey": "",
                                "lighten-4": "",
                              },
                            },
                            [
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [
                                  _c("b", [_vm._v("Job Created At:")]),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.jobSummaryData.jobCreatedAt
                                          ? _vm.getFormattedDate(
                                              _vm.jobSummaryData.jobCreatedAt
                                            )
                                          : ""
                                      )
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [_c("b", [_vm._v("Policy:")])]),
                              ]),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "text-xs-left",
                                  attrs: { xs7: "" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.jobSummaryData.policyName)
                                    ),
                                  ]),
                                ]
                              ),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [
                                  _c("b", [_vm._v("Assumed Cover Status:")]),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.jobSummaryData.assumedCoverStatus
                                      )
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [_c("b", [_vm._v("Validated:")])]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.jobSummaryData.validated)
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [
                                  _c("b", [_vm._v("Number of Emergencies:")]),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.jobSummaryData.numberOfEmergencies
                                      )
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [
                                  _c("b", [_vm._v("Number of Visits:")]),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.jobSummaryData.numberOfVisits)
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs5: "", "mb-1": "" } }, [
                                _c("label", [
                                  _c("b", [_vm._v("Trades Used:")]),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.jobSummaryData.tradesUsed)
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.jobFinancialInvoiceItems &&
                      _vm.jobFinancialInvoiceItems.length > 0
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "mt-5": "" } },
                            [
                              _c(
                                "v-data-table",
                                {
                                  staticClass:
                                    "elevation-1 gridView jobFinancialFees",
                                  attrs: {
                                    headers: _vm.jobFinancialFeesHeaders,
                                    items: _vm.jobFinancialInvoiceItems,
                                    "hide-actions": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.invoiceName)
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getFormattedPlainDate(
                                                      props.item.invoiceDate
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.invoiceFor)
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item.invoiceStatus
                                                  )
                                                ),
                                              ]),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.item.sales != null
                                                          ? _vm.getFormatedCurrency(
                                                              props.item.sales
                                                            )
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.item.costs != null
                                                          ? _vm.getFormatedCurrency(
                                                              props.item.costs
                                                            )
                                                          : "-"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3900939487
                                  ),
                                },
                                [
                                  _c("template", { slot: "footer" }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-xs-right",
                                        attrs: { colspan: 4 },
                                      },
                                      [_c("strong", [_vm._v("Total")])]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-right pr-2" },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getFormatedCurrency(
                                                  _vm.jobFinancialInvoiceItems.reduce(
                                                    (a, b) => a + b.sales,
                                                    0
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-xs-right pr-2" },
                                      [
                                        _c("b", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getFormatedCurrency(
                                                  _vm.jobFinancialInvoiceItems.reduce(
                                                    (a, b) => a + b.costs,
                                                    0
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-flex",
                        [
                          _c("v-data-table", {
                            staticClass:
                              "elevation-1 gridView mt-5 invoicePdfTable",
                            attrs: {
                              headers: _vm.invoicePdfHeaders,
                              items: _vm.invoicePdfItems,
                              "hide-actions": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.policyName)),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFormattedDate(
                                              props.item.createdAt
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFormatedCurrency(
                                              props.item.totalCost
                                            )
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.openPDFDocument(
                                                    props.index,
                                                    props.item.pdfDetailUri
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("picture_as_pdf"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }