var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-layout", { staticClass: "mb-2", attrs: { wrap: "" } }, [
    _vm.vulnerabilityQAItem.isHealthAffected === true
      ? _c(
          "div",
          [
            _vm.vulnerabilityQAItem.level
              ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                  _c("span", [
                    _vm._v(" Level : "),
                    _c("b", { staticClass: "secondary--text" }, [
                      _vm._v(_vm._s(_vm.vulnerabilityQAItem.level)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.vulnerabilityQAItem.answerDetail
              ? _c("v-flex", { attrs: { xs12: "" } }, [
                  _c("div", { staticClass: "affected-details" }, [
                    _c("span", [_vm._v("Details :")]),
                    _c("span", { staticClass: "affected-description" }, [
                      _vm._v(
                        " " + _vm._s(_vm.vulnerabilityQAItem.answerDetail) + " "
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm.vulnerabilityQAItem.isHealthAffected === false
      ? _c(
          "div",
          [
            _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
              _c("span", [_vm._v("No Vulnerability")]),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
              _c("span", [_vm._v("Not answered yet.")]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }