var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.model && _vm.model.detailComponent
    ? _c(
        "div",
        {
          directives: [
            {
              name: "bar",
              rawName: "v-bar",
              value: { useScrollbarPseudo: true },
              expression: "{ useScrollbarPseudo: true }",
            },
          ],
          staticClass: "inner-content",
          class: _vm.model && _vm.model.detailComponent ? "contentShadow" : "",
        },
        [
          _c(
            "v-layout",
            { staticClass: "px-3 pt-3", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "text-xs-right mb-1", attrs: { xs12: "" } },
                [
                  _c(
                    "v-layout",
                    [
                      _vm.showSendQuestionModeLinkIcon
                        ? _c(
                            "v-flex",
                            { attrs: { "text-xs-left": "" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "ml-0 question-link",
                                                  attrs: {
                                                    flat: "",
                                                    icon: "",
                                                    color: "secondry",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.sendQuestionLinkClicked,
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/img/question-link.svg",
                                                    alt: "",
                                                  },
                                                }),
                                                _c(
                                                  "span",
                                                  { staticClass: "link-count" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getSendQuestionModeLinkCount
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1123688555
                                  ),
                                },
                                [_c("span", [_vm._v("Send Questions Link")])]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "primary",
                                disabled: _vm.getIsAutoDeploymentRunning,
                              },
                              on: { click: _vm.refreshDataClicked },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { dark: "", color: "primary" } },
                                [_vm._v("refresh")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "primary",
                                disabled: _vm.getIsAutoDeploymentRunning,
                              },
                              on: { click: _vm.closePreviewClicked },
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { dark: "", color: "primary" } },
                                [_vm._v("close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("div", { staticClass: "main-inner-content" }, [
                  _c(
                    "div",
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(_vm.model.detailComponent, {
                                tag: "component",
                                attrs: {
                                  "job-id": _vm.model.forJobId,
                                  "emergency-id": _vm.model.forEmergencyId,
                                  "item-id": _vm.model.forItemId,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }