var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "div",
        [
          _vm._m(0),
          _vm.engineerName
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Engineer: ")]),
                _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
              ])
            : _vm._e(),
          _vm.item.from
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("From: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.from))]),
              ])
            : _vm._e(),
          _vm.item.to
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("To: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.to))]),
              ])
            : _vm._e(),
          _vm.item.pipeMaterial
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Pipe Material: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.pipeMaterial))]),
              ])
            : _vm._e(),
          _vm.item.pipeDiameter
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Pipe Diameter: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.pipeDiameter))]),
              ])
            : _vm._e(),
          _vm.item.direction
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Direction: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.direction))]),
              ])
            : _vm._e(),
          _vm.item.function
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Function: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.function))]),
              ])
            : _vm._e(),
          _vm.item.invertLevelOne
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Invert Level: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.invertLevelOne))]),
              ])
            : _vm._e(),
          _vm.item.invertLevelTwo
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Invert Level: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.invertLevelTwo))]),
              ])
            : _vm._e(),
          _vm.item.sharedRun
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Shared Run: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.sharedRun))]),
              ])
            : _vm._e(),
          _vm.item.gullyCondition
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Gully Condition: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.gullyCondition))]),
              ])
            : _vm._e(),
          _vm.item.comments
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Comments: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.comments))]),
              ])
            : _vm._e(),
          _c("v-divider", { staticClass: "mb-1" }),
          _vm.item.distances.length > 0
            ? _c(
                "div",
                _vm._l(_vm.item.distances, function (distanceItem, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      distanceItem.distance
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Distance (m): ")]),
                            _c("span", [_vm._v(_vm._s(distanceItem.distance))]),
                          ])
                        : _vm._e(),
                      distanceItem.code
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Code: ")]),
                            _c("span", [_vm._v(_vm._s(distanceItem.code))]),
                          ])
                        : _vm._e(),
                      distanceItem.clockRefAt
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Clock Ref At: ")]),
                            _c("span", [
                              _vm._v(_vm._s(distanceItem.clockRefAt)),
                            ]),
                          ])
                        : _vm._e(),
                      distanceItem.clockRefTo
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Clock Ref To: ")]),
                            _c("span", [
                              _vm._v(_vm._s(distanceItem.clockRefTo)),
                            ]),
                          ])
                        : _vm._e(),
                      distanceItem.intrusionInPercentage
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Intrusion(%): ")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(distanceItem.intrusionInPercentage)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      distanceItem.intrusionInMM
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Intrusion(MM): ")]),
                            _c("span", [
                              _vm._v(_vm._s(distanceItem.intrusionInMM)),
                            ]),
                          ])
                        : _vm._e(),
                      distanceItem.remarks
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Remarks: ")]),
                            _c("span", [_vm._v(_vm._s(distanceItem.remarks))]),
                          ])
                        : _vm._e(),
                      index !== _vm.item.distances.length - 1
                        ? _c("v-divider", { staticClass: "mb-1" })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update CCTV Control Run")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }