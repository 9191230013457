var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "elevation-1 scrollable-table-wrapper",
      attrs: { "px-0": "", "py-0": "" },
    },
    [
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("rownumber")]),
            _c("th", [_vm._v("bordereauinvoicenumber")]),
            _c("th", [_vm._v("bordereauinvoicedate")]),
            _c("th", [_vm._v("policyno")]),
            _c("th", [_vm._v("name")]),
            _c("th", [_vm._v("address1")]),
            _c("th", [_vm._v("address2")]),
            _c("th", [_vm._v("address3")]),
            _c("th", [_vm._v("county")]),
            _c("th", [_vm._v("postcode")]),
            _c("th", [_vm._v("incidentdate")]),
            _c("th", [_vm._v("cetleadno")]),
            _c("th", [_vm._v("cover")]),
            _c("th", [_vm._v("lvproductcode")]),
            _c("th", [_vm._v("repaircompleted")]),
            _c("th", [_vm._v("firstinstructiontrade")]),
            _c("th", [_vm._v("secondinstructiontrade")]),
            _c("th", [_vm._v("firstinstructiondescription")]),
            _c("th", [_vm._v("partsfitted")]),
            _c("th", [_vm._v("vatable")]),
            _c("th", [_vm._v("nonvatable")]),
            _c("th", [_vm._v("vatrate")]),
            _c("th", [_vm._v("vat")]),
            _c("th", [_vm._v("grandtotal")]),
          ]),
        ]),
        _c(
          "tbody",
          _vm._l(_vm.selectedJobList, function (row, ind) {
            return _c("tr", { key: ind }, [
              _c("td", [_vm._v(_vm._s(ind + 1))]),
              _c("td", [_vm._v(_vm._s(_vm.selectedBordereauId))]),
              _c("td", [_vm._v(_vm._s(_vm.getFormatedDate(_vm.nowDate())))]),
              _c("td", [_vm._v(_vm._s(row.policyNumber))]),
              _c("td", [_vm._v(_vm._s(row.policyHolderName))]),
              _c("td", [_vm._v(_vm._s(row.addressLine1))]),
              _c("td", [_vm._v(_vm._s(row.addressLine2))]),
              _c("td", [_vm._v(_vm._s(row.addressLine3))]),
              _c("td", [_vm._v(_vm._s(row.addressLine4))]),
              _c("td", [_vm._v(_vm._s(row.postCode))]),
              _c("td", [
                _vm._v(_vm._s(_vm.getFormatedDate(row.jobLoggedDate))),
              ]),
              _c("td", [_vm._v(_vm._s(row.jobId))]),
              _c("td", [_vm._v(_vm._s(row.policyName))]),
              _c("td", [_vm._v(_vm._s(row.tradeTypeShortCode))]),
              _c("td", [
                _vm._v(
                  _vm._s(
                    _vm.getFormatedDate(row.firstEmergencyVisitCompleteDate)
                  )
                ),
              ]),
              _c("td", [_vm._v(_vm._s(row.firstEmergencyTradeName))]),
              _c("td", [_vm._v(_vm._s(row.secondEmergencyTradeName))]),
              _c("td", [_vm._v(_vm._s(row.firstEmergencyDescription))]),
              _c("td"),
              _c("td", [_vm._v(_vm._s(_vm.getFormatedCurrency(row.vatable)))]),
              _c("td", [
                _vm._v(_vm._s(_vm.getFormatedCurrency(row.nonVatable))),
              ]),
              _c("td", [_vm._v("20%")]),
              _c("td", [_vm._v(_vm._s(_vm.getFormatedCurrency(row.totalVAT)))]),
              _c("td", [
                _vm._v(
                  _vm._s(_vm.getFormatedCurrency(row.totalCostIncFeesAndVAT))
                ),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }