var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _vm.trialPitData.length > 0
          ? _c(
              "div",
              [
                _c(
                  "v-expansion-panel",
                  {
                    staticClass: "elevation-0",
                    model: {
                      value: _vm.selectedPanel,
                      callback: function ($$v) {
                        _vm.selectedPanel = $$v
                      },
                      expression: "selectedPanel",
                    },
                  },
                  _vm._l(_vm.trialPitData, function (trialPit, index) {
                    return _c(
                      "v-expansion-panel-content",
                      {
                        key: index,
                        staticClass: "trialpit-accordion grey lighten-5 mb-2",
                        class: _vm.getClassName(trialPit.status),
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass: "expansion-header btn-trialPitDetail",
                            attrs: { slot: "header" },
                            slot: "header",
                          },
                          [
                            _vm._v(
                              " Trial Pit Details for " +
                                _vm._s(trialPit.pitId) +
                                " "
                            ),
                          ]
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card",
                          { staticClass: "grey lighten-5" },
                          [
                            _c(
                              "v-card-text",
                              [
                                index === _vm.currentIndex
                                  ? _c("TrialPitDrawingWrapper", {
                                      ref: _vm.trialPitDrawingRef + (index + 1),
                                      refInFor: true,
                                    })
                                  : _vm._e(),
                                !_vm.isTrialPitAbandoned(trialPit)
                                  ? _c(
                                      "div",
                                      { staticClass: "text-xs-right" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "mb-1 mr-0 btn-saveDrawingDetail",
                                            attrs: {
                                              color: "primary",
                                              loading:
                                                _vm.loading
                                                  .trialPitDrawingUpdates,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.saveTrialPitDrawingDetails(
                                                  trialPit,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Save Pit Drawing ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("b", { staticClass: "my-3 d-block" }, [
                                  _c(
                                    "span",
                                    { staticClass: "secondary--text mr-2" },
                                    [_vm._v("Bore Hole Depth:")]
                                  ),
                                  _vm._v(
                                    " " + _vm._s(trialPit.boreHoleDepth) + "m "
                                  ),
                                ]),
                                _c("v-data-table", {
                                  staticClass:
                                    "gridView gridView-full-width trial-data-table",
                                  attrs: {
                                    headers: _vm.headers,
                                    items:
                                      trialPit.productChildLinks &&
                                      trialPit.productChildLinks.length > 0
                                        ? trialPit.productChildLinks
                                        : [],
                                    "hide-actions": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "td",
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      _vm.isTrialPitAbandoned(
                                                        trialPit
                                                      ),
                                                    color: "primary",
                                                    label: props.item.name,
                                                    value: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeTestLinkState(
                                                        $event,
                                                        props.item,
                                                        trialPit.pitTests
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      props.item.isSelected,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        props.item,
                                                        "isSelected",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "props.item.isSelected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("v-text-field", {
                                                  directives: [
                                                    {
                                                      name: "validate",
                                                      rawName: "v-validate",
                                                      value:
                                                        "numeric|min_value:1",
                                                      expression:
                                                        "'numeric|min_value:1'",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "number",
                                                    name: "quantity",
                                                    readonly:
                                                      _vm.isTrialPitAbandoned(
                                                        trialPit
                                                      ),
                                                  },
                                                  on: {
                                                    keypress: function (
                                                      $event
                                                    ) {
                                                      return _vm.numberKeyValidation(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: props.item.quantity,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        props.item,
                                                        "quantity",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "props.item.quantity",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              { staticClass: "text-xs-right" },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly:
                                                      _vm.isTrialPitAbandoned(
                                                        trialPit
                                                      ),
                                                  },
                                                  model: {
                                                    value: props.item.notes,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        props.item,
                                                        "notes",
                                                        typeof $$v === "string"
                                                          ? $$v.trim()
                                                          : $$v
                                                      )
                                                    },
                                                    expression:
                                                      "props.item.notes",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        trialPit.boreHoleLogs
                          ? _c(
                              "div",
                              _vm._l(
                                trialPit.boreHoleLogs,
                                function (boreHoleLog, index1) {
                                  return _c(
                                    "v-expansion-panel",
                                    {
                                      key: index1,
                                      staticClass:
                                        "sitedata-accordion elevation-0 px-3",
                                    },
                                    [
                                      _c(
                                        "v-expansion-panel-content",
                                        { staticClass: "white mb-2" },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              staticClass: "align-center",
                                              attrs: {
                                                slot: "header",
                                                wrap: "",
                                              },
                                              slot: "header",
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs6: "" } },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "px-2 header-borehole",
                                                      attrs: { slot: "header" },
                                                      slot: "header",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Bore Hole Log " +
                                                          _vm._s(
                                                            boreHoleLog.boreHoleRunNumber
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              !_vm.isTrialPitAbandoned(trialPit)
                                                ? _c(
                                                    "v-flex",
                                                    {
                                                      staticClass:
                                                        "text-xs-right pr-4",
                                                      attrs: { xs6: "" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "ma-0 close-icon btn-editBoreHoleDetail",
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            color: "primary",
                                                          },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.editBoreHoleDetails(
                                                                trialPit,
                                                                boreHoleLog
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("edit"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "ma-0 close-icon btn-deleteBoreHoleDetail",
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            color: "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.onDeleteBoreHoleDetails(
                                                                trialPit,
                                                                boreHoleLog
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("delete"),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c("v-divider"),
                                          _c(
                                            "v-card",
                                            { staticClass: "white" },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    wrap: "",
                                                    "px-3": "",
                                                    "pt-3": "",
                                                    "pb-2": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        lg6: "",
                                                        xs12: "",
                                                        "mb-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Boring Method:"
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              boreHoleLog.boringMethod
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        lg6: "",
                                                        xs12: "",
                                                        "mb-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("b", [
                                                          _vm._v(
                                                            "Ground Level:"
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              boreHoleLog.groundLevel
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        lg6: "",
                                                        xs12: "",
                                                        "mb-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("b", [
                                                          _vm._v("Diameter:"),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              boreHoleLog.diameter
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        lg6: "",
                                                        xs12: "",
                                                        "mb-2": "",
                                                      },
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c("b", [
                                                          _vm._v("Weather:"),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              boreHoleLog.weather
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                [
                                                  boreHoleLog.changes
                                                    ? _c(
                                                        "v-card-text",
                                                        _vm._l(
                                                          boreHoleLog.changes,
                                                          function (
                                                            changes,
                                                            index2
                                                          ) {
                                                            return _c(
                                                              "v-card",
                                                              {
                                                                key: index2,
                                                                staticClass:
                                                                  "elevation-0 mb-2 grey lighten-5",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card-text",
                                                                  {
                                                                    staticClass:
                                                                      "py-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-layout",
                                                                      {
                                                                        attrs: {
                                                                          wrap: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Depth:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    changes.depth
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Thickness:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      changes.thickness
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Test:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    changes.testType
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Result:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      changes.testResult
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Legend:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    changes.legend
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-flex",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                xl4: "",
                                                                                lg6: "",
                                                                                xs12: "",
                                                                                "my-1":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "label",
                                                                              [
                                                                                _c(
                                                                                  "b",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Soil Description:"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      changes.soilDescription
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _vm.isTrialPitAbandoned(trialPit)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "pa-3 grey lighten-4 mx-3 mt-2 mb-3 abandon-detail",
                              },
                              [
                                _c("div", { staticClass: "pb-1" }, [
                                  _c("label", [_c("b", [_vm._v("Notes:")])]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          trialPit.notes ? trialPit.notes : "-"
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "pb-1" }, [
                                  _c("label", [
                                    _c("b", [_vm._v("Abandon Reason:")]),
                                  ]),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _vm._v(
                                      _vm._s(trialPit.abandonedTrialPitReason)
                                    ),
                                  ]),
                                ]),
                                trialPit.abandonedTrialPitReasonNotes
                                  ? _c("div", [
                                      _c("label", [
                                        _c("b", [_vm._v("Abandon Note:")]),
                                      ]),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              trialPit.abandonedTrialPitReasonNotes
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isTrialPitAbandoned(trialPit)
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "text-xs-right" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "mr-3 mb-3 btn-addBoreHoleDetail",
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addBoreHoleDetails(
                                            trialPit
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Add Bore Hole Log ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "pa-3 grey lighten-4 mx-3 mb-2",
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Notes",
                                      maxlength: "2000",
                                      rows: "3",
                                    },
                                    model: {
                                      value: trialPit.notes,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          trialPit,
                                          "notes",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "trialPit.notes",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-xs-right mb-2" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "mr-3 mb-2 ml-0 btn-saveTrialPit",
                                      attrs: {
                                        color: "primary",
                                        loading:
                                          _vm.loading.trialPitSaveUpdates,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveTrialPitDetails(
                                            trialPit
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Save ")]
                                  ),
                                  trialPit.status !==
                                  _vm.siJobProductProgress[
                                    _vm.siJobProductProgress.Finished
                                  ]
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "mr-3 mb-2 ml-0 white--text btn-completeTrialPit",
                                          attrs: {
                                            color: "green",
                                            loading:
                                              _vm.loading
                                                .trialPitCompleteUpdates,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.completeTrialPit(
                                                trialPit
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Complete ")]
                                      )
                                    : _vm._e(),
                                  !_vm.isTrialPitAbandoned(trialPit)
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "mr-3 ml-0 mt-0 white--text btn-abandonTrialPit",
                                          attrs: { color: "red" },
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.openAbandonReasonDialog(
                                                trialPit
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Abandon ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _c("span", [_vm._v("No Data Found")]),
      ]),
      _vm.boreHoleDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "850",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.boreHoleDialog,
                callback: function ($$v) {
                  _vm.boreHoleDialog = $$v
                },
                expression: "boreHoleDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(_vm.isAddBoreHole ? "Add" : "Edit") +
                            " Bore Hole Log"
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-closeBoreHoleDetai",
                          attrs: { icon: "" },
                          on: { click: _vm.discardBoreHoleChangesConfirmation },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog px-4 pt-3" },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      items:
                                        _vm.getBoreHoleLogConfiguration
                                          .boringMethods,
                                      "item-value": "value",
                                      "item-text": "value",
                                      label: "Boring Method",
                                      required: "",
                                      "data-vv-name": "Boring Method",
                                      "error-messages":
                                        _vm.validationMessage("Boring Method"),
                                      "data-vv-scope": "selectedBoreHoleLog",
                                    },
                                    model: {
                                      value:
                                        _vm.selectedBoreHoleLogDetails
                                          .boringMethod,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedBoreHoleLogDetails,
                                          "boringMethod",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedBoreHoleLogDetails.boringMethod",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|min_value:0",
                                        expression: "'required|min_value:0'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Ground Level",
                                      required: "",
                                      "data-vv-scope": "selectedBoreHoleLog",
                                      "data-vv-name": "Ground Level",
                                      name: "Ground Level",
                                      "error-messages":
                                        _vm.errors.collect("Ground Level"),
                                    },
                                    on: {
                                      keypress: function ($event) {
                                        return _vm.numberKeyValidation($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.selectedBoreHoleLogDetails
                                          .groundLevel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedBoreHoleLogDetails,
                                          "groundLevel",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "selectedBoreHoleLogDetails.groundLevel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Diameter",
                                      items:
                                        _vm.getBoreHoleLogConfiguration
                                          .diameters,
                                      required: "",
                                      "data-vv-scope": "selectedBoreHoleLog",
                                      "data-vv-name": "Diameter",
                                      name: "Diameter",
                                      "error-messages":
                                        _vm.validationMessage("Diameter"),
                                    },
                                    model: {
                                      value:
                                        _vm.selectedBoreHoleLogDetails.diameter,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedBoreHoleLogDetails,
                                          "diameter",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedBoreHoleLogDetails.diameter",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", "py-0": "" } },
                                [
                                  _c("v-select", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Weather",
                                      items:
                                        _vm.getBoreHoleLogConfiguration
                                          .weathers,
                                      required: "",
                                      "data-vv-scope": "selectedBoreHoleLog",
                                      "data-vv-name": "Weather",
                                      name: "Weather",
                                      "error-messages":
                                        _vm.validationMessage("Weather"),
                                    },
                                    model: {
                                      value:
                                        _vm.selectedBoreHoleLogDetails.weather,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.selectedBoreHoleLogDetails,
                                          "weather",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "selectedBoreHoleLogDetails.weather",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.selectedBoreHoleLogDetails.changes
                            ? _c(
                                "div",
                                { staticClass: "changesWizard" },
                                _vm._l(
                                  _vm.selectedBoreHoleLogDetails.changes,
                                  function (changes, index4) {
                                    return _c(
                                      "v-card",
                                      {
                                        key: index4,
                                        staticClass:
                                          "grey lighten-4 elevation-0 mt-2 layer-card",
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "ma-0 close-icon btn-deleteLayer",
                                            attrs: {
                                              flat: "",
                                              icon: "",
                                              color: "secondary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.confirmLayerDelete(
                                                  changes.depth,
                                                  index4
                                                )
                                              },
                                            },
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          { staticClass: "pt-4" },
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs4: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value: {
                                                            rules: {
                                                              required: true,
                                                              min_value: 0,
                                                              max_value:
                                                                _vm.getTrialPitDrawingDepthById(
                                                                  _vm
                                                                    .selectedTrialPitData
                                                                    .trialPitDrawingId,
                                                                  _vm
                                                                    .selectedTrialPitData
                                                                    .boreHoleDepth
                                                                ),
                                                              decimal: 2,
                                                            },
                                                          },
                                                          expression:
                                                            "{\n                        rules: {\n                          required: true,\n                          min_value: 0,\n                          max_value: getTrialPitDrawingDepthById(\n                            selectedTrialPitData.trialPitDrawingId,\n                            selectedTrialPitData.boreHoleDepth\n                          ),\n                          decimal: 2,\n                        },\n                      }",
                                                        },
                                                      ],
                                                      staticClass: "required",
                                                      attrs: {
                                                        name: "Depth",
                                                        label: "Depth (m)",
                                                        required: "",
                                                        "data-vv-scope":
                                                          "selectedBoreHoleLog",
                                                        "data-vv-name":
                                                          "Depth" + index4,
                                                        "error-messages":
                                                          _vm.validationMessage(
                                                            "Depth" + index4
                                                          ),
                                                      },
                                                      on: {
                                                        keypress: function (
                                                          $event
                                                        ) {
                                                          return _vm.numberKeyValidation(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: changes.depth,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "depth",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "changes.depth",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs4: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            "min_value:0|decimal:2",
                                                          expression:
                                                            "'min_value:0|decimal:2'",
                                                        },
                                                      ],
                                                      attrs: {
                                                        name: "Thickness",
                                                        label: "Thickness (m)",
                                                      },
                                                      on: {
                                                        keypress: function (
                                                          $event
                                                        ) {
                                                          return _vm.numberKeyValidation(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          changes.thickness,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "thickness",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "changes.thickness",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs4: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        label: "Test",
                                                        items:
                                                          _vm
                                                            .getBoreHoleLogConfiguration
                                                            .testTypes,
                                                        name: "Test",
                                                      },
                                                      model: {
                                                        value: changes.testType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "testType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "changes.testType",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs6: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      directives: [
                                                        {
                                                          name: "validate",
                                                          rawName: "v-validate",
                                                          value:
                                                            changes.testType
                                                              ? "required"
                                                              : "",
                                                          expression:
                                                            "changes.testType ? 'required' : ''",
                                                        },
                                                      ],
                                                      class: changes.testType
                                                        ? "required"
                                                        : "",
                                                      attrs: {
                                                        name: "Result",
                                                        label: "Result",
                                                        required: "",
                                                        "data-vv-scope":
                                                          "selectedBoreHoleLog",
                                                        "data-vv-name":
                                                          "Result" + index4,
                                                        "error-messages":
                                                          _vm.validationMessage(
                                                            "Result" + index4
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          changes.testResult,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "testResult",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "changes.testResult",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs6: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-autocomplete", {
                                                      attrs: {
                                                        label: "Legend",
                                                        name: "Legend",
                                                        maxlength: "20",
                                                        items:
                                                          _vm
                                                            .getBoreHoleLogConfiguration
                                                            .legends,
                                                        "error-messages":
                                                          _vm.validationMessage(
                                                            "Legend" + index4
                                                          ),
                                                      },
                                                      model: {
                                                        value: changes.legend,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "legend",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "changes.legend",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      xs12: "",
                                                      "py-0": "",
                                                    },
                                                  },
                                                  [
                                                    _c("v-textarea", {
                                                      attrs: {
                                                        label:
                                                          "Soil Description",
                                                      },
                                                      model: {
                                                        value:
                                                          changes.soilDescription,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "soilDescription",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "changes.soilDescription",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "text-xs-right" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-3 ma-0 btn-addLayer",
                                  attrs: {
                                    color: "primary",
                                    loading: _vm.loading.boreHoleUpdates,
                                  },
                                  on: { click: _vm.addLayerToBoreHole },
                                },
                                [_vm._v(" Add Layer ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-closeBoreHoleDetail",
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.discardBoreHoleChangesConfirmation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Discard ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 btn-addUpdateBoreHole",
                          attrs: {
                            color: "primary",
                            loading: _vm.loading.selectedBoreHoleLog,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.addBoreHoleToList.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.isAddBoreHole ? "Add" : "Update") +
                              " Bore Hole "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAbandonReasonDialog
        ? _c("AbandonSiteDataDialog", {
            ref: "abandonSiteDataDialog",
            attrs: {
              "record-type": _vm.abandonRecordType,
              "abandon-reason-details": _vm.abandonReasonDetails,
            },
            on: {
              abandonReasonSave: _vm.onAbandonReasonSave,
              closeAbandonReasonDialog: function ($event) {
                _vm.showAbandonReasonDialog = false
              },
            },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }