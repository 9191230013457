var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [
        _vm._v(" Invoice Details Updated For "),
        _vm.requestBody.id
          ? _c("span", [_vm._v("Visit")])
          : _c("span", [_vm._v("Job")]),
      ]),
    ]),
    _vm.requestBody.id
      ? _c("div", [
          _vm.engineerName !== ""
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Engineer: ")]),
                _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
              ])
            : _vm._e(),
          _vm.emergencyName
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Emergency: ")]),
                _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
              ])
            : _vm._e(),
        ])
      : _c("div", [
          _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Invoice Recalculated: ")]),
            _c("span", [
              _vm._v(_vm._s(_vm.requestBody.recalculateAll ? "Yes" : "No")),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }