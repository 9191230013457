var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "close-on-content-click": true,
            "nudge-width": 320,
            "max-width": 320,
            "z-index": 999,
            "content-class": "preferred-contact-menu",
            left: "",
            fixed: "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "span",
                    _vm._g({ staticClass: "ma-0" }, on),
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          overlap: "",
                                          color:
                                            "grey small-size-badge darken-2",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-avatar",
                                          _vm._g(
                                            {
                                              staticClass:
                                                "elevation-5 btn-contactDetails",
                                              attrs: {
                                                color: "white",
                                                size: 35,
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "secondary--text md-icon",
                                                attrs: { dark: "" },
                                              },
                                              [_vm._v("perm_contact_calendar")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Contact Details")])]
                      ),
                      _c("span", { staticClass: "n-arrow" }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("v-card", [
            _c(
              "div",
              { staticClass: "view-panel contact-list" },
              [
                _c(
                  "v-list",
                  { attrs: { "two-line": "" } },
                  [
                    (_vm.jobItem.mobilePhone &&
                      (_vm.jobItem.contactPreference ==
                        _vm.contactPreference[_vm.contactPreference.Call] ||
                        _vm.jobItem.contactPreference ==
                          _vm.contactPreference[_vm.contactPreference.SMS])) ||
                    (_vm.jobItem.email &&
                      _vm.jobItem.contactPreference ==
                        _vm.contactPreference[_vm.contactPreference.Email])
                      ? _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                { staticClass: "name primary--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.jobItem.clientFullName) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v("(User)"),
                                  ]),
                                ]
                              ),
                              _vm.jobItem.mobilePhone &&
                              (_vm.jobItem.contactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Call
                                ] ||
                                _vm.jobItem.contactPreference ==
                                  _vm.contactPreference[
                                    _vm.contactPreference.SMS
                                  ])
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-2 number",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v("phone")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "number",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCallIconClick(
                                                _vm.jobItem.mobilePhone
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.jobItem.mobilePhone) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.jobItem.email &&
                              _vm.jobItem.contactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Email
                                ]
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "grey--text text--darken-2",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v("email")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "number",
                                          attrs: {
                                            href: "mailto:" + _vm.jobItem.email,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.jobItem.email) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.jobItem.mobilePhone &&
                              _vm.jobItem.reasonForChoosingCallOption &&
                              _vm.jobItem.contactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Call
                                ]
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-2 mt-1 ml-1 call-preference-Reason",
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          "Reason For selecting Call As a Preference:"
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.jobItem
                                              .reasonForChoosingCallOption
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.showEditContactPreferencePopup
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "action edit-contact-detail",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "elevation-1",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openContactPreferencePopup()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                btn: "",
                                                small: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("edit")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.jobItem.nomineeMobilePhone &&
                      (_vm.jobItem.nomineeContactPreference ==
                        _vm.contactPreference[_vm.contactPreference.Call] ||
                        _vm.jobItem.nomineeContactPreference ==
                          _vm.contactPreference[_vm.contactPreference.SMS])) ||
                    (_vm.jobItem.nomineeEmail &&
                      _vm.jobItem.nomineeContactPreference ==
                        _vm.contactPreference[_vm.contactPreference.Email])
                      ? _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                { staticClass: "name primary--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.jobItem.nomineeClientFullName
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v("(Nominee)"),
                                  ]),
                                ]
                              ),
                              _vm.jobItem.nomineeMobilePhone &&
                              (_vm.jobItem.nomineeContactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Call
                                ] ||
                                _vm.jobItem.nomineeContactPreference ==
                                  _vm.contactPreference[
                                    _vm.contactPreference.SMS
                                  ])
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-2 number",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v("phone")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "number",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onCallIconClick(
                                                _vm.jobItem.nomineeMobilePhone
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.jobItem.nomineeMobilePhone
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("br"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.jobItem.nomineeEmail &&
                              _vm.jobItem.nomineeContactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Email
                                ]
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "grey--text text--darken-2",
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "secondary",
                                          },
                                        },
                                        [_vm._v("email")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "number",
                                          attrs: {
                                            href:
                                              "mailto:" +
                                              _vm.jobItem.nomineeEmail,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.jobItem.nomineeEmail) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.jobItem.nomineeMobilePhone &&
                              _vm.jobItem.nomineeReasonForChoosingCallOption &&
                              _vm.jobItem.nomineeContactPreference ==
                                _vm.contactPreference[
                                  _vm.contactPreference.Call
                                ]
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "grey--text text--darken-2 mt-1 ml-1 nominee-call-preference-Reason",
                                    },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          "Reason For selecting Call As a Preference:"
                                        ),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.jobItem
                                              .nomineeReasonForChoosingCallOption
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.getContractorAppointedDetail.length > 0
                      ? _vm._l(
                          _vm.getContractorAppointedDetail,
                          function (contractor) {
                            return _c(
                              "v-list-tile",
                              { key: contractor.id },
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "name primary--text mb-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(contractor.companyName) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "grey--text" },
                                          [_vm._v("(Contractor)")]
                                        ),
                                      ]
                                    ),
                                    contractor.contactNumbers.length > 0
                                      ? _vm._l(
                                          contractor.contactNumbers,
                                          function (contact, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: index,
                                                staticClass:
                                                  "grey--text text--darken-2",
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass: "number",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onCallIconClick(
                                                          contact.phoneNumber,
                                                          contractor.contractorId
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          contact.phoneNumber
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "caption grey--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "- " +
                                                        _vm._s(
                                                          contact.label
                                                            ? contact.label
                                                            : "Phone number"
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm.changePreference && _vm.jobItem
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.changePreference,
                callback: function ($$v) {
                  _vm.changePreference = $$v
                },
                expression: "changePreference",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Change Contact Preference"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.closeContactPreferenceDetailPopup()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("ContactPreferenceDetails", {
                                ref: "contactPreferenceDetails",
                                attrs: {
                                  "customer-contact-preference-modal":
                                    _vm.customerContactPreferenceModal,
                                },
                                on: {
                                  updateContactPreference:
                                    _vm.updateContactPreference,
                                  isHasNominee: _vm.isHasNominee,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          on: {
                            click: function ($event) {
                              return _vm.closeContactPreferenceDetailPopup()
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateContactPreferenceDetail()
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }