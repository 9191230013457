var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
                [
                  _c("h3", [_vm._v("Reports")]),
                  _c(
                    "v-layout",
                    {
                      staticClass:
                        "mx-0 mt-3 mb-1 align-center px-3 grey lighten-4",
                      attrs: { wrap: "" },
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", xl2: "", "py-0": "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: ["Bordereau", "Invoice"],
                              "return-object": "",
                              label: "Type",
                            },
                            model: {
                              value: _vm.bordereauInvoiceFilter,
                              callback: function ($$v) {
                                _vm.bordereauInvoiceFilter = $$v
                              },
                              expression: "bordereauInvoiceFilter",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", xl2: "", "py-0": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "dateTimePickerFrom",
                            attrs: {
                              "date-time": _vm.searchFromDate,
                              "is-static-location": false,
                              "place-holder-text": "From",
                              "is-current-time": false,
                              "is-validation-required": false,
                              "show-present-time-icon": false,
                              "max-date": _vm.selectableDateTime(
                                _vm.searchToDate
                              ),
                              "hide-time-picker": true,
                              "display-format": _vm.dateFormat(),
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                _vm.searchFromDate = $event
                              },
                              "update:date-time": function ($event) {
                                _vm.searchFromDate = $event
                              },
                            },
                            model: {
                              value: _vm.searchFromDate,
                              callback: function ($$v) {
                                _vm.searchFromDate = $$v
                              },
                              expression: "searchFromDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs2: "", xl2: "", "py-0": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "dateTimePickerFrom",
                            attrs: {
                              "date-time": _vm.searchToDate,
                              "is-static-location": false,
                              "place-holder-text": "To",
                              "is-current-time": false,
                              "is-validation-required": false,
                              "show-present-time-icon": false,
                              "min-date": _vm.selectableDateTime(
                                _vm.searchFromDate
                              ),
                              "hide-time-picker": true,
                              "display-format": _vm.dateFormat(),
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                _vm.searchToDate = $event
                              },
                              "update:date-time": function ($event) {
                                _vm.searchToDate = $event
                              },
                            },
                            model: {
                              value: _vm.searchToDate,
                              callback: function ($$v) {
                                _vm.searchToDate = $$v
                              },
                              expression: "searchToDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "text-xs-right",
                          attrs: { xs6: "", xl6: "", "py-0": "", "pr-0": "" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-search",
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading,
                              },
                              on: { click: _vm.searchBordereau },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("filter_list"),
                              ]),
                              _vm._v(" Search "),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-clear",
                              attrs: { disabled: _vm.loading },
                              on: { click: _vm.clearFilter },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("close"),
                              ]),
                              _vm._v(" Clear "),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-0 btn-export",
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading,
                              },
                              on: { click: _vm.openExportToExcelDialog },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-2" }, [
                                _vm._v("vertical_align_bottom"),
                              ]),
                              _vm._v(" Export "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                staticClass: "elevation-1 gridView report-table",
                attrs: {
                  headers: _vm.bordereauHeaders,
                  items:
                    _vm.bordereauInvoiceFilter === "Bordereau"
                      ? _vm.bordereauList
                      : _vm.invoiceList,
                  loading: _vm.loading,
                  pagination: _vm.paginationLocal,
                },
                on: {
                  "update:pagination": function ($event) {
                    _vm.paginationLocal = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function (props) {
                      return [
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.bordereauInvoiceFilter === "Bordereau"
                                  ? props.item.bordereauName
                                  : props.item.id
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.getFormatedDate(props.item.createdAt))
                          ),
                        ]),
                        _vm.bordereauInvoiceFilter === "Bordereau"
                          ? _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(
                                " " + _vm._s(props.item.numberOfJobs) + " "
                              ),
                            ])
                          : _vm._e(),
                        _c("td", { staticClass: "text-xs-center" }, [
                          _c(
                            "b",
                            { class: _vm.getStatusColor(props.item.status) },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getStatusName(props.item.status)) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormatedCurrency(
                                  _vm.bordereauInvoiceFilter === "Bordereau"
                                    ? props.item.amount
                                    : props.item.totalCostIncVAT
                                )
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", { staticClass: "text-xs-right" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFormatedCurrency(props.item.amountPaid)
                              ) +
                              " "
                          ),
                        ]),
                        _c(
                          "td",
                          { staticClass: "text-xs-right" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          (_vm.bordereauInvoiceFilter ===
                                            "Bordereau" &&
                                            props.item.documentURL) ||
                                          (_vm.bordereauInvoiceFilter ===
                                            "Invoice" &&
                                            props.item.pdfDetailUri)
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "my-3 btn-download",
                                                    attrs: {
                                                      color: "primary",
                                                      flat: "",
                                                      icon: "",
                                                      disabled:
                                                        _vm.downloadDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                      loading:
                                                        _vm.downloadPDFDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openPDFDocument(
                                                          props.index,
                                                          _vm.bordereauInvoiceFilter ===
                                                            "Bordereau"
                                                            ? props.item
                                                                .documentURL
                                                            : props.item
                                                                .pdfDetailUri
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("picture_as_pdf"),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Download (PDF)")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          props.item.sanalCSVUri
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "my-3 btn-sanal-csv-download",
                                                    class:
                                                      _vm.downloadDocumentRunning &&
                                                      _vm.downloadDocumentCurrentIndex ===
                                                        props.index
                                                        ? "disable-custom-img"
                                                        : "",
                                                    attrs: {
                                                      color: "primary",
                                                      flat: "",
                                                      icon: "",
                                                      disabled:
                                                        _vm.downloadDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                      loading:
                                                        _vm.downloadSanalCSVDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openSanalCSVDocument(
                                                          props.index,
                                                          props.item.sanalCSVUri
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("img", {
                                                    staticClass: "custom-img",
                                                    attrs: {
                                                      src: "/img/csv-download.svg",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Download Sanal CSV")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          props.item.stranCSVUri
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "my-3 btn-stran-csv-download",
                                                    class:
                                                      _vm.downloadDocumentRunning &&
                                                      _vm.downloadDocumentCurrentIndex ===
                                                        props.index
                                                        ? "disable-custom-img"
                                                        : "",
                                                    attrs: {
                                                      color: "primary",
                                                      flat: "",
                                                      icon: "",
                                                      disabled:
                                                        _vm.downloadDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                      loading:
                                                        _vm.downloadStranCSVDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openStranCSVDocument(
                                                          props.index,
                                                          props.item.stranCSVUri
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("img", {
                                                    staticClass: "custom-img",
                                                    attrs: {
                                                      src: "/img/csv-download.svg",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Download Stran CSV")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          props.item.bordereauCSVUri
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "my-3 btn-bordereau-csv-download",
                                                    class:
                                                      _vm.downloadDocumentRunning &&
                                                      _vm.downloadDocumentCurrentIndex ===
                                                        props.index
                                                        ? "disable-custom-img"
                                                        : "",
                                                    attrs: {
                                                      color: "primary",
                                                      flat: "",
                                                      icon: "",
                                                      disabled:
                                                        _vm.downloadDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                      loading:
                                                        _vm.downloadBordereauCSVDocumentRunning &&
                                                        _vm.downloadDocumentCurrentIndex ===
                                                          props.index,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openBordereauCSVDocument(
                                                          props.index,
                                                          props.item
                                                            .bordereauCSVUri
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("img", {
                                                    staticClass: "custom-img",
                                                    attrs: {
                                                      src: "/img/csv-download.svg",
                                                      alt: "",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Download Bordereau CSV")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "my-3 btn-bordereau",
                                                attrs: {
                                                  color: "primary",
                                                  flat: "",
                                                  icon: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openBordereauDialog(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v("folder_open"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Open Bordereau")])]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "max-width": "1680px",
            "content-class": "v-dialog--scrollable",
          },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.bordereauDialog = false
            },
          },
          model: {
            value: _vm.bordereauDialog,
            callback: function ($$v) {
              _vm.bordereauDialog = $$v
            },
            expression: "bordereauDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _vm.selectedBordereau.bordereauName
                    ? _c("v-toolbar-title", [
                        _vm._v(
                          " Bordereau Details (" +
                            _vm._s(_vm.selectedBordereau.bordereauName) +
                            ") "
                        ),
                      ])
                    : _c("v-toolbar-title", [
                        _vm._v(
                          "Invoice Details (" +
                            _vm._s(_vm.selectedBordereau.id) +
                            ")"
                        ),
                      ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.bordereauDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1 gridView popup-table",
                    attrs: {
                      headers: _vm.bordereauJobDetailHeaders,
                      items: _vm.bordereauJobRows,
                      loading: _vm.isLoading,
                      pagination: _vm.paginationJobRows,
                    },
                    on: {
                      "update:pagination": function ($event) {
                        _vm.paginationJobRows = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function (props) {
                          return [
                            _c("td", [
                              _c("b", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "secondary--text",
                                    attrs: { href: "javascript:void(0)" },
                                  },
                                  [_vm._v(" " + _vm._s(props.item.jobId) + " ")]
                                ),
                              ]),
                            ]),
                            _c("td", [_vm._v(_vm._s(props.item.policyName))]),
                            _c("td", { staticClass: "address-col" }, [
                              _vm._v(_vm._s(props.item.address)),
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(_vm._s(props.item.policyNumber)),
                            ]),
                            _c(
                              "td",
                              { staticClass: "text-xs-center" },
                              _vm._l(
                                props.item.tradesEmployed,
                                function (trades, index) {
                                  return _c("span", { key: index }, [
                                    index !== 0
                                      ? _c("span", [_vm._v(",")])
                                      : _vm._e(),
                                    _vm._v(" " + _vm._s(trades) + " "),
                                  ])
                                }
                              ),
                              0
                            ),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    props.item.costAsFixedFee ? "Yes" : "No"
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getFormatedCurrency(
                                      props.item.policyLimit
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-xs-right" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getFormatedCurrency(
                                      props.item.totalCostIncVAT
                                    )
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("td", { staticClass: "text-xs-center" }, [
                              _vm._v(_vm._s(props.item.returnVisit)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.exportToExcelDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.exportToExcelDialog,
                callback: function ($$v) {
                  _vm.exportToExcelDialog = $$v
                },
                expression: "exportToExcelDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Export To Excel")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 pt-0 scroll-content-dialog" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-0", attrs: { "grid-list-xl": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Export Name" },
                                    model: {
                                      value: _vm.exportName,
                                      callback: function ($$v) {
                                        _vm.exportName = $$v
                                      },
                                      expression: "exportName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "" },
                          on: {
                            click: function ($event) {
                              _vm.exportToExcelDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "export-excel",
                        {
                          staticClass: "btn ml-2",
                          attrs: {
                            "export-fields": _vm.exportToExcelHeaderFields,
                            fetch: _vm.exportBordereau,
                            worksheet: "CTP Details",
                            "before-generate": _vm.startExportGeneration,
                            "before-finish": _vm.stopExportGeneration,
                            type: "csv",
                            name: _vm.exportName
                              ? _vm.exportName + ".xls"
                              : _vm.getFileName,
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              ref: "ExportToExcel",
                              attrs: {
                                color: "primary",
                                loading: _vm.exportToExcelLoader,
                                disabled: _vm.exportToExcelLoader,
                              },
                            },
                            [
                              _c("v-icon", { staticClass: "md-icon" }, [
                                _vm._v("get_app"),
                              ]),
                              _vm._v("   Export "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }