var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "peril-repeat", attrs: { wrap: "" } },
    [
      _vm.addEmergencyIndex > 0
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("v-spacer", { staticClass: "primary" })],
            1
          )
        : _vm._e(),
      _vm.value
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-2": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required emergency-type",
                        attrs: {
                          items: _vm.emergencyTypes,
                          "item-value": "emergencyId",
                          "item-text": "description",
                          label: "Emergency Type",
                          required: "",
                          "data-vv-name": "EmergencyType",
                          "error-messages":
                            _vm.validationMessage("EmergencyType"),
                          disabled: _vm.disabled,
                          name: "EmergencyType",
                        },
                        model: {
                          value: _vm.value.emergencyTypeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "emergencyTypeId", $$v)
                          },
                          expression: "value.emergencyTypeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-2": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required emergency-detail",
                        attrs: {
                          items: _vm.emergencyDetailsFiltered,
                          "item-value": "emergencyDetailId",
                          "item-text": "description",
                          label: "Emergency Detail",
                          required: "",
                          "data-vv-name": "EmergencyDetail",
                          "error-messages":
                            _vm.validationMessage("EmergencyDetail"),
                          disabled: _vm.disabled,
                          name: "EmergencyDetail",
                        },
                        model: {
                          value: _vm.value.emergencyDetailId,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "emergencyDetailId", $$v)
                          },
                          expression: "value.emergencyDetailId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pr-2": "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required trade-type",
                        attrs: {
                          items: _vm.trades,
                          "item-value": "tradeId",
                          "item-text": "description",
                          label: "Trade",
                          required: "",
                          "data-vv-name": "Trade",
                          "error-messages": _vm.validationMessage("Trade"),
                          disabled:
                            _vm.disabled ||
                            (_vm.tradesAutoSelect && !_vm.isUserSupervisor),
                        },
                        on: { change: _vm.tradeIdChanged },
                        model: {
                          value: _vm.value.tradeId,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "tradeId", $$v)
                          },
                          expression: "value.tradeId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "", "pl-2": "" } },
                    [
                      _c("DateTimePicker", {
                        ref: "dateTimePicker",
                        attrs: {
                          "date-time": _vm.getFirstNoticedAtUtc,
                          "is-static-location": false,
                          "place-holder-text": "First Noticed",
                          "max-date": _vm.maxFirstNoticedAtDate,
                          "max-time": _vm.maxFirstNoticedAtTime,
                          "is-current-time": true,
                          "is-validation-required": true,
                          "show-present-time-icon": true,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "update:dateTime": function ($event) {
                            _vm.getFirstNoticedAtUtc = $event
                          },
                          "update:date-time": function ($event) {
                            _vm.getFirstNoticedAtUtc = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.momentEffectiveDate &&
              _vm.getFirstNoticedAtUtc &&
              _vm.policyEffectiveWarningEnabled &&
              _vm.momentEffectiveDate > _vm.getFirstNoticedAtUtc
                ? _c(
                    "v-alert",
                    {
                      staticClass: "mb-5 mt-0 mx-2",
                      attrs: {
                        value: true,
                        color: "red",
                        icon: "priority_high",
                        outline: "",
                        name: "PolicyEffectiveError",
                      },
                    },
                    [_vm._v(" Repudiate as claim is before effective date. ")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }