var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "emergencies-coverd mb-3" },
        [
          _c(
            "v-card",
            { staticClass: "bg-gray" },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "emergencies-type-que pr-0 pt-2",
                      attrs: { md5: "", lg3: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "text-xs-right adp-type-text" },
                        [
                          !_vm.isUserRoleClientManager
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { top: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "md-icon ma-0 btn-addemergency",
                                                  attrs: {
                                                    flat: "",
                                                    icon: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      _vm.showEmergencyDialog = true
                                                      _vm.currentIndex = -1
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [_c("v-icon", [_vm._v("add")])],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3044832815
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "Add " +
                                        _vm._s(_vm.jobTypeLabel("emergency"))
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.showEmergencyDialog
                            ? _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    "content-class": "v-dialog--scrollable",
                                    "max-width": "400",
                                    persistent: "",
                                  },
                                  model: {
                                    value: _vm.showEmergencyDialog,
                                    callback: function ($$v) {
                                      _vm.showEmergencyDialog = $$v
                                    },
                                    expression: "showEmergencyDialog",
                                  },
                                },
                                [
                                  _c("EmergencyComponent", {
                                    ref: "refEmergency",
                                    attrs: {
                                      emergency: _vm.emergency,
                                      "job-type": _vm.jobType,
                                    },
                                    on: {
                                      closeEmergency:
                                        _vm.onCloseEmergencyDialog,
                                      saveEmergency: _vm.onSaveEmergency,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "bar",
                              rawName: "v-bar",
                              value: { useScrollbarPseudo: true },
                              expression: "{ useScrollbarPseudo: true }",
                            },
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "scroll-content pr-3" },
                            _vm._l(_vm.getEmergencyList, function (emergency) {
                              return _c(
                                "div",
                                {
                                  key: emergency.emergencyId,
                                  staticClass: "emergecny-list-sla",
                                },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "que-checkbox",
                                    class: emergency.isDeleted
                                      ? "deleted-item"
                                      : "",
                                    attrs: {
                                      "hide-details": true,
                                      label: emergency.description,
                                      value: emergency,
                                      color: "primary",
                                      name: "emergency",
                                      readonly: _vm.isUserRoleClientManager,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.emergencyListChange(
                                          _vm.emergencyList
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.emergencyList,
                                      callback: function ($$v) {
                                        _vm.emergencyList = $$v
                                      },
                                      expression: "emergencyList",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "primary--text sla-time",
                                      class:
                                        _vm.currentIndex ===
                                        emergency.emergencyId
                                          ? "sla-time-edit"
                                          : "",
                                    },
                                    [
                                      _vm.currentIndex !== emergency.emergencyId
                                        ? _c(
                                            "span",
                                            { staticClass: "sla-value" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    emergency.engineerSiteSLA
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _c("v-combobox", {
                                            staticClass:
                                              "mt-0 pt-0 engineerOnSiteSLA",
                                            attrs: {
                                              id: "engineerOnSiteSLA",
                                              items: _vm.engineerSiteSLAItems,
                                              "append-icon": "save",
                                              "single-line": "",
                                              disabled:
                                                _vm.currentIndex !==
                                                emergency.emergencyId,
                                              "hide-details": "",
                                              name: "engineerOnSiteSLA",
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                return _vm.saveEngineerSiteSLA(
                                                  emergency
                                                )
                                              },
                                              keypress: function ($event) {
                                                return _vm.numberKeyValidation(
                                                  $event
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.engineerSiteSLA,
                                              callback: function ($$v) {
                                                _vm.engineerSiteSLA = $$v
                                              },
                                              expression: "engineerSiteSLA",
                                            },
                                          }),
                                      _vm.currentIndex === emergency.emergencyId
                                        ? _c(
                                            "span",
                                            { staticClass: "sla-close" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ma-0 btn-closeEngineerOnSiteSLA",
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.cancelEditEngineerSiteSLA(
                                                        emergency
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      emergency.engineerSiteSLA &&
                                      _vm.currentIndex !== emergency.emergencyId
                                        ? _c(
                                            "span",
                                            { staticClass: "sla-text" },
                                            [_vm._v(" hrs ")]
                                          )
                                        : _vm._e(),
                                      !_vm.isUserRoleClientManager &&
                                      emergency.engineerSiteSLA
                                        ? _c(
                                            "span",
                                            {
                                              staticClass: "sla-action",
                                              class:
                                                _vm.currentIndex ===
                                                emergency.emergencyId
                                                  ? "hide"
                                                  : "",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "btn-editEngineerSiteSLA",
                                                  attrs: {
                                                    icon: "",
                                                    color: "white",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editEngineerSiteSLA(
                                                        emergency
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "secondary",
                                                      },
                                                    },
                                                    [_vm._v("edit")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : !_vm.isUserRoleClientManager &&
                                          _vm.emergencyList.indexOf(
                                            emergency
                                          ) !== -1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "primary--text sla-time",
                                              class:
                                                _vm.currentIndex !==
                                                emergency.emergencyId
                                                  ? "sla-time-edit"
                                                  : "hide",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "white",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editEngineerSiteSLA(
                                                        emergency
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        color: "secondary",
                                                      },
                                                    },
                                                    [_vm._v("edit")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("v-flex", { attrs: { xs9: "" } }, [
                    _c(
                      "div",
                      { staticClass: "emergencies-sel-que pa-4" },
                      [
                        !_vm.isUserRoleClientManager
                          ? _c("div", { staticClass: "control-right-side" }, [
                              _c(
                                "span",
                                [
                                  _c("v-checkbox", {
                                    staticClass: "mb-3 que-checkbox",
                                    attrs: {
                                      label: "Select All",
                                      color: "primary",
                                      "hide-details": true,
                                      name: "selectAllEmergencyDetail",
                                    },
                                    on: {
                                      change: _vm.selectAllEmergencyDetail,
                                    },
                                    model: {
                                      value: _vm.selectAll,
                                      callback: function ($$v) {
                                        _vm.selectAll = $$v
                                      },
                                      expression: "selectAll",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.showEmergencyDetailDialog
                          ? _c(
                              "v-dialog",
                              {
                                attrs: {
                                  "content-class": "v-dialog--scrollable",
                                  "max-width": "400",
                                  persistent: "",
                                },
                                model: {
                                  value: _vm.showEmergencyDetailDialog,
                                  callback: function ($$v) {
                                    _vm.showEmergencyDetailDialog = $$v
                                  },
                                  expression: "showEmergencyDetailDialog",
                                },
                              },
                              [
                                _c("EmergencyDetail", {
                                  ref: "refEmergencyDetail",
                                  attrs: {
                                    "job-type": _vm.jobType,
                                    emergencies: _vm.emergencies,
                                    trades: _vm.trades,
                                    "emergency-id": _vm.emergencyId,
                                    "emergency-detail": _vm.emergencyDetail,
                                  },
                                  on: {
                                    closeEmergencyDetail: function ($event) {
                                      _vm.showEmergencyDetailDialog =
                                        !_vm.showEmergencyDetailDialog
                                    },
                                    saveEmergencyDetail:
                                      _vm.onSaveEmergencyDetail,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.showThirdPartyDetailDialog
                          ? _c(
                              "v-dialog",
                              {
                                attrs: {
                                  "content-class": "v-dialog--scrollable",
                                  "max-width": "650",
                                  persistent: "",
                                },
                                model: {
                                  value: _vm.showThirdPartyDetailDialog,
                                  callback: function ($$v) {
                                    _vm.showThirdPartyDetailDialog = $$v
                                  },
                                  expression: "showThirdPartyDetailDialog",
                                },
                              },
                              [
                                _c("ThirdPartyDetail", {
                                  ref: "refThirdPartyDetail",
                                  attrs: {
                                    contractors: _vm.contractors,
                                    "emergency-id": _vm.emergencyId,
                                    "third-party-contractor-id":
                                      _vm.thirdPartyContractorId,
                                  },
                                  on: {
                                    saveThirdPartyDetail:
                                      _vm.onSaveThirdPartyDetail,
                                    closeThirdPartyDetail: function ($event) {
                                      _vm.showThirdPartyDetailDialog =
                                        !_vm.showThirdPartyDetailDialog
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                            staticClass: "policy-scroll-content",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "scroll-content" },
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { wrap: "" } },
                                  _vm._l(
                                    _vm.selectedEmergencies,
                                    function (emergency) {
                                      return _c(
                                        "v-flex",
                                        {
                                          key: emergency.emergencyId,
                                          attrs: { xs12: "", "mb-3": "" },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  staticClass:
                                                    "pb-0 selectedEmergencies",
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            emergency.description
                                                          ) +
                                                          " "
                                                      ),
                                                      !_vm.isUserRoleClientManager
                                                        ? _c(
                                                            "v-tooltip",
                                                            {
                                                              attrs: {
                                                                top: "",
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "activator",
                                                                      fn: function ({
                                                                        on,
                                                                      }) {
                                                                        return [
                                                                          _c(
                                                                            "v-btn",
                                                                            _vm._g(
                                                                              {
                                                                                staticClass:
                                                                                  "btn-addEmergencyDetail",
                                                                                style:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    flat: "",
                                                                                    color:
                                                                                      "primary",
                                                                                    icon: "",
                                                                                  },
                                                                                on: {
                                                                                  click:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      return _vm.addEmergencyDetail(
                                                                                        emergency.emergencyId
                                                                                      )
                                                                                    },
                                                                                },
                                                                              },
                                                                              on
                                                                            ),
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "add"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  "Add " +
                                                                    _vm._s(
                                                                      _vm.jobTypeLabel(
                                                                        "emergency detail"
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-spacer"),
                                                  _vm.showAddThirdPartyDetail(
                                                    emergency.emergencyId
                                                  ) &&
                                                  _vm.policyId !== 0 &&
                                                  !_vm.isUserRoleClientManager
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "ma-0 mr-1 btn-addThirdPartyDetail",
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.addEditThirdPartyDetail(
                                                                                  emergency.emergencyId
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "group_add"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Add Third Party Detail"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.showAddThirdPartyDetail(
                                                    emergency.emergencyId
                                                  ) &&
                                                  _vm.policyId !== 0 &&
                                                  !_vm.isUserRoleClientManager
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text ma-0 btn-updateThirdPartyDetail",
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addEditThirdPartyDetail(
                                                                                    emergency.emergencyId
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "group"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Update Third Party Detail"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isUserRoleClientManager
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text ma-0 btn-editEmergency",
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.editEmergency(
                                                                                    emergency.emergencyId
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "md-icon emergency-edit",
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "edit"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Edit " +
                                                                _vm._s(
                                                                  _vm.jobTypeLabel(
                                                                    "emergency"
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isUserRoleClientManager
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { top: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "secondary--text ma-0 btn-deleteEmergency",
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onDeleteEmergency(
                                                                                    emergency.emergencyId
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "md-icon",
                                                                            attrs:
                                                                              {
                                                                                dark: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Delete " +
                                                                _vm._s(
                                                                  _vm.jobTypeLabel(
                                                                    "emergency"
                                                                  )
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-container",
                                                {
                                                  attrs: {
                                                    fluid: "",
                                                    "grid-list-md": "",
                                                    "pa-2": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      staticClass:
                                                        "pt-0 policy-cov-list",
                                                      attrs: {
                                                        wrap: "",
                                                        "pa-3": "",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.filteredEmergencyDetails(
                                                        emergency.emergencyId
                                                      ),
                                                      function (
                                                        emergencyDetail
                                                      ) {
                                                        return _c(
                                                          "v-flex",
                                                          {
                                                            key: emergencyDetail.emergencyDetailId,
                                                            attrs: {
                                                              xs12: "",
                                                              md6: "",
                                                              lg4: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              {
                                                                staticClass:
                                                                  "pa-3 grey lighten-4 fill-height emergencyDetail-list",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-layout",
                                                                  {
                                                                    attrs: {
                                                                      wrap: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-flex",
                                                                      {
                                                                        staticClass:
                                                                          "policy-addemergency",
                                                                        attrs: {
                                                                          xs12: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-checkbox",
                                                                          {
                                                                            staticClass:
                                                                              "que-checkbox mt-1 mb-1",
                                                                            class:
                                                                              emergencyDetail.isDeleted
                                                                                ? "deleted-item"
                                                                                : "",
                                                                            attrs:
                                                                              {
                                                                                "hide-details": true,
                                                                                label:
                                                                                  emergencyDetail.description,
                                                                                value:
                                                                                  emergencyDetail,
                                                                                color:
                                                                                  "primary",
                                                                                name: "emergencyDetail",
                                                                                readonly:
                                                                                  _vm.isUserRoleClientManager,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.onEmergencyDetailCheckChange(
                                                                                    emergencyDetail,
                                                                                    $event
                                                                                  )
                                                                                  _vm.$emit(
                                                                                    "update:selectedEmergencyDetails",
                                                                                    _vm.emergencyDetailList
                                                                                  )
                                                                                },
                                                                            },
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  _vm.emergencyDetailList,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.emergencyDetailList =
                                                                                      $$v
                                                                                  },
                                                                                expression:
                                                                                  "emergencyDetailList",
                                                                              },
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !_vm.isUserRoleClientManager &&
                                                                                    emergencyDetail.isEmergencyCoverageExist &&
                                                                                    (!emergencyDetail.emergencyCoverage ||
                                                                                      (!emergencyDetail
                                                                                        .emergencyCoverage
                                                                                        .startMonth &&
                                                                                        !emergencyDetail
                                                                                          .emergencyCoverage
                                                                                          .endMonth &&
                                                                                        !emergencyDetail
                                                                                          .emergencyCoverage
                                                                                          .coverageDetails &&
                                                                                        !emergencyDetail
                                                                                          .emergencyCoverage
                                                                                          .exclusions &&
                                                                                        !emergencyDetail
                                                                                          .emergencyCoverage
                                                                                          .isMarkedAsCTP)),
                                                                                  expression:
                                                                                    "\n                                      !isUserRoleClientManager &&\n                                      emergencyDetail.isEmergencyCoverageExist &&\n                                      (!emergencyDetail.emergencyCoverage ||\n                                        (!emergencyDetail.emergencyCoverage.startMonth &&\n                                          !emergencyDetail.emergencyCoverage.endMonth &&\n                                          !emergencyDetail.emergencyCoverage.coverageDetails &&\n                                          !emergencyDetail.emergencyCoverage.exclusions &&\n                                          !emergencyDetail.emergencyCoverage.isMarkedAsCTP))\n                                    ",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "menu-activator btn-addEmergencyCoverage",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm.showEmergencyCoverageMenu,
                                                                                color:
                                                                                  "primary",
                                                                                dark: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.addEmergencyCoverage(
                                                                                    emergencyDetail,
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " add "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !_vm.isUserRoleClientManager &&
                                                                                    emergencyDetail.isEmergencyCoverageExist &&
                                                                                    emergencyDetail.emergencyCoverage &&
                                                                                    (emergencyDetail
                                                                                      .emergencyCoverage
                                                                                      .startMonth ||
                                                                                      emergencyDetail
                                                                                        .emergencyCoverage
                                                                                        .endMonth ||
                                                                                      emergencyDetail
                                                                                        .emergencyCoverage
                                                                                        .coverageDetails ||
                                                                                      emergencyDetail
                                                                                        .emergencyCoverage
                                                                                        .exclusions ||
                                                                                      emergencyDetail
                                                                                        .emergencyCoverage
                                                                                        .isMarkedAsCTP),
                                                                                  expression:
                                                                                    "\n                                      !isUserRoleClientManager &&\n                                      emergencyDetail.isEmergencyCoverageExist &&\n                                      emergencyDetail.emergencyCoverage &&\n                                      (emergencyDetail.emergencyCoverage.startMonth ||\n                                        emergencyDetail.emergencyCoverage.endMonth ||\n                                        emergencyDetail.emergencyCoverage.coverageDetails ||\n                                        emergencyDetail.emergencyCoverage.exclusions ||\n                                        emergencyDetail.emergencyCoverage.isMarkedAsCTP)\n                                    ",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "icon-edit menu-activator btn-editEmergencyCoverage",
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  _vm.showEmergencyCoverageMenu,
                                                                                color:
                                                                                  "secondary",
                                                                                dark: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.editEmergencyCoverage(
                                                                                    emergencyDetail,
                                                                                    $event
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " edit "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !_vm.isUserRoleClientManager &&
                                                                                    !emergencyDetail.isEmergencyCoverageExist,
                                                                                  expression:
                                                                                    "!isUserRoleClientManager && !emergencyDetail.isEmergencyCoverageExist",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "menu-activator md-icon emergency-edit btn-editEmergencyDetail",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                dark: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.editEmergencyDetail(
                                                                                    emergency.emergencyId,
                                                                                    emergencyDetail
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " edit "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "show",
                                                                                  rawName:
                                                                                    "v-show",
                                                                                  value:
                                                                                    !_vm.isUserRoleClientManager &&
                                                                                    !emergencyDetail.isEmergencyCoverageExist,
                                                                                  expression:
                                                                                    "!isUserRoleClientManager && !emergencyDetail.isEmergencyCoverageExist",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "menu-activator md-icon btn-deleteEmergencyDetail",
                                                                            attrs:
                                                                              {
                                                                                color:
                                                                                  "primary",
                                                                                dark: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  $event.stopPropagation()
                                                                                  return _vm.onDeleteEmergencyDetail(
                                                                                    emergencyDetail.emergencyDetailId
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " delete "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: {
            "offset-x": "",
            absolute: "",
            left: "",
            "position-x": _vm.xPosition,
            "position-y": _vm.yPosition,
            "close-on-content-click": false,
            "nudge-width": 200,
            "max-width": 300,
          },
          model: {
            value: _vm.showEmergencyCoverageMenu,
            callback: function ($$v) {
              _vm.showEmergencyCoverageMenu = $$v
            },
            expression: "showEmergencyCoverageMenu",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "pa-3": "" } },
                [
                  _vm.showEmergencyCoverageMenu
                    ? _c("EditEmergencyCoverage", {
                        attrs: { item: _vm.currentEmergencyCoverage },
                        on: {
                          "update:item": function ($event) {
                            _vm.currentEmergencyCoverage = $event
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.currentEmergencyCoverage.startMonth ||
                            _vm.currentEmergencyCoverage.endMonth ||
                            _vm.currentEmergencyCoverage.coverageDetails ||
                            _vm.currentEmergencyCoverage.exclusions ||
                            _vm.currentEmergencyCoverage.isMarkedAsCTP,
                          expression:
                            "\n            currentEmergencyCoverage.startMonth ||\n            currentEmergencyCoverage.endMonth ||\n            currentEmergencyCoverage.coverageDetails ||\n            currentEmergencyCoverage.exclusions ||\n            currentEmergencyCoverage.isMarkedAsCTP\n          ",
                        },
                      ],
                      staticClass: "btn-deleteEmergencyCoverage",
                      attrs: { color: "primary", flat: "", dark: "" },
                      on: { click: _vm.deleteEmergencyCoverage },
                    },
                    [_vm._v(" Delete ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-saveEmergencyCoverage",
                      attrs: { color: "primary", dark: "" },
                      on: { click: _vm.saveEmergencyCoverage },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }