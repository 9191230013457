var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.contractor
    ? _c(
        "div",
        { staticClass: "call-cnt-list" },
        [
          _c(
            "v-card",
            { class: _vm.getClassNames },
            [
              _c(
                "v-card-title",
                { staticClass: "px-2 pt-2 pb-0 full-width" },
                [
                  _c(
                    "h4",
                    {
                      class:
                        _vm.getAssignedContractor &&
                        _vm.getAssignedContractor.status !==
                          _vm.jobVisitStatus[_vm.jobVisitStatus.Unavailable]
                          ? "data-title secondary--text"
                          : "data-title",
                    },
                    [_vm._v(" " + _vm._s(_vm.contractor.companyName) + " ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "span",
                    { staticClass: "ratings" },
                    [
                      _vm.getPreferredContractor(_vm.contractor) &&
                      _vm.getPreferredContractor(_vm.contractor) !==
                        "Unverified"
                        ? _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "span",
                                          _vm._g(
                                            { staticClass: "badges-icon" },
                                            on
                                          ),
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  viewBox: "0 0 384 471",
                                                  width: "16",
                                                  height: "13",
                                                  fill: _vm.getContractorStatusIconColor,
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Forma 1",
                                                    d: "M341.33 1L42.67 1C19.09 1 0.21 20.08 0.21 43.64L0 319.39C0 334.1 7.47 347.1 18.77 354.78L191.89 470L365.01 354.78C376.32 347.1 383.79 334.1 383.79 319.39L384 43.64C384 20.08 364.91 1 341.33 1ZM149.33 320.77L42.67 214.18L72.85 184.02L149.33 260.44L311.15 98.74L341.33 128.91L149.33 320.77Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Forma 1",
                                                    d: "M341.33 1L42.67 1C19.09 1 0.21 20.08 0.21 43.64L0 319.39C0 334.1 7.47 347.1 18.77 354.78L191.89 470L365.01 354.78C376.32 347.1 383.79 334.1 383.79 319.39L384 43.64C384 20.08 364.91 1 341.33 1ZM149.33 320.77L42.67 214.18L72.85 184.02L149.33 260.44L311.15 98.74L341.33 128.91L149.33 320.77Z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 2",
                                                    d: "M53.77,206.77l-3.54,-3.54l-9,9l3.54,3.54z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 3",
                                                    d: "M76.69,185.84l-3.38,-3.69l-24,22l3.38,3.69z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 4",
                                                    d: "M147.17,263.71l3.65,-3.41l-72,-77l-3.65,3.41z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 5",
                                                    d: "M40,294h107v-83h-107z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 6",
                                                    d: "M89,214v0v36v0z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 7",
                                                    d: "M43,337h156v-144h-156z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 8",
                                                    d: "M55,228h66v-66h-66z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 9",
                                                    d: "M245,257h75v-138h-75z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 10",
                                                    d: "M173,299h108v-124h-108z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 11",
                                                    d: "M200,208h72v-49h-72z",
                                                  },
                                                }),
                                                _c("path", {
                                                  staticClass: "shp0",
                                                  attrs: {
                                                    id: "Shape 12",
                                                    d: "M276,170h69v-80h-69z",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2393299698
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getPreferredContractor(_vm.contractor)
                                  ) + " Contractor"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.hasAutoDeploymentOn(_vm.contractor.autoDeploymentType)
                        ? _c(
                            "span",
                            [
                              _vm.contractor.autoDeploymentType ===
                              _vm.autoDeploymentTypeEnum[
                                _vm.autoDeploymentTypeEnum.AutodeployToEngineer
                              ]
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "badges-icon ml-1 autodeployToEngineer",
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        staticStyle: {
                                                          "enable-background":
                                                            "new 0 0 535.806 535.807",
                                                        },
                                                        attrs: {
                                                          id: "Capa_1",
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          "xmlns:xlink":
                                                            "http://www.w3.org/1999/xlink",
                                                          version: "1.1",
                                                          x: "0px",
                                                          y: "0px",
                                                          width: "16px",
                                                          height: "16px",
                                                          viewBox:
                                                            "0 0 535.806 535.807",
                                                          "xml:space":
                                                            "preserve",
                                                          fill: _vm.setAutoDeploymentOnColor,
                                                        },
                                                      },
                                                      [
                                                        _c("g", [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479 c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262 c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592 C474.31,507.654,477.982,415.242,440.956,373.932z",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048 c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486 c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486 c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0 c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224 c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322 c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44 c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414 h-17.22L377.409,118.116z",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95 v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546 c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262 C239.598,131.58,243.881,134.028,248.777,134.028z",
                                                            },
                                                          }),
                                                        ]),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                        _c("g"),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3106413234
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " Auto Deployment To Engineers "
                                        ),
                                        _vm.contractor.autoDeployAlwaysActive
                                          ? _c("span", [_vm._v("for 24 hours")])
                                          : _c("span", [
                                              _vm._v(
                                                " from " +
                                                  _vm._s(
                                                    _vm.contractor
                                                      .autoDeployStartTime
                                                  ) +
                                                  " to " +
                                                  _vm._s(
                                                    _vm.contractor
                                                      .autoDeployEndTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "v-tooltip",
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "badges-icon ml-1 autodeployToContractor",
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "svg",
                                                      {
                                                        attrs: {
                                                          xmlns:
                                                            "http://www.w3.org/2000/svg",
                                                          "xmlns:xlink":
                                                            "http://www.w3.org/1999/xlink",
                                                          version: "1.1",
                                                          viewBox:
                                                            "0 0 297 297",
                                                          "enable-background":
                                                            "new 0 0 297 297",
                                                          width: "16px",
                                                          height: "16px",
                                                          fill: _vm.setAutoDeploymentOnColor,
                                                        },
                                                      },
                                                      [
                                                        _c("g", [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608 S116.193,117.216,148.51,117.216z",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        655576025
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " Auto Deployment To Contractor "
                                        ),
                                        _vm.contractor.autoDeployAlwaysActive
                                          ? _c("span", [_vm._v("for 24 hours")])
                                          : _c("span", [
                                              _vm._v(
                                                " from " +
                                                  _vm._s(
                                                    _vm.contractor
                                                      .autoDeployStartTime
                                                  ) +
                                                  " to " +
                                                  _vm._s(
                                                    _vm.contractor
                                                      .autoDeployEndTime
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                      ]),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-icon", { attrs: { small: "", color: "grey" } }, [
                        _vm._v("star"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.contractor.rating) + "/5 "),
                    ],
                    1
                  ),
                  _c("v-divider"),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-0 pb-2 px-2 cn-card-detail full-width" },
                [
                  _vm.timeSlots.length > 0
                    ? [
                        _c("span", [
                          _c(
                            "i",
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("access_time"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "available-time" },
                            [
                              _vm._v(" Weekdays - "),
                              _vm._l(_vm.timeSlots, function (item, index) {
                                return [
                                  item.slotId > 0
                                    ? _c("span", { key: item.slotId }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              new Date(
                                                item.startTime
                                              ).getHours()
                                            ) +
                                            " to " +
                                            _vm._s(
                                              new Date(
                                                item.endTime
                                              ).getHours() === 0
                                                ? 24
                                                : new Date(
                                                    item.endTime
                                                  ).getHours()
                                            ) +
                                            _vm._s(
                                              index === _vm.timeSlots.length - 1
                                                ? ""
                                                : ","
                                            ) +
                                            "  "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("br"),
                      ]
                    : _vm._e(),
                  _vm.contractor.contactNumbers
                    ? _vm._l(
                        _vm.contractor.contactNumbers,
                        function (contact, index) {
                          return _c("span", { key: index }, [
                            _c(
                              "i",
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("local_phone"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a",
                              { staticClass: "grey--text darken-2--text" },
                              [_vm._v(_vm._s(contact.phoneNumber))]
                            ),
                            _c("span", { staticClass: "caption grey--text" }, [
                              _vm._v(
                                "- " +
                                  _vm._s(
                                    contact.label
                                      ? contact.label
                                      : "Phone number"
                                  )
                              ),
                            ]),
                          ])
                        }
                      )
                    : _vm._e(),
                  _c("br"),
                  _vm.contractor.website
                    ? _c("span", [
                        _c(
                          "i",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("public"),
                            ]),
                          ],
                          1
                        ),
                        _c("a", { staticClass: "grey--text" }, [
                          _vm._v(_vm._s(_vm.contractor.website)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.contractor.distance
                    ? _c("span", [
                        _c(
                          "i",
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("near_me"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(
                          " " +
                            _vm._s(
                              (_vm.contractor.distance / 1000).toFixed(2)
                            ) +
                            " KM "
                        ),
                      ])
                    : _vm._e(),
                  _vm.getAssignedContractor &&
                  !_vm.getAssignedContractor.isContractorReAttend &&
                  _vm.getContractorEtaFrom &&
                  _vm.getAssignedContractor.status !==
                    _vm.jobVisitStatus[_vm.jobVisitStatus.Unavailable]
                    ? _c("span", [
                        _c("i", [
                          _c("img", {
                            staticClass: "icon-img-tile",
                            attrs: { src: "/img/user.svg" },
                          }),
                        ]),
                        _c("b", [_vm._v("ETA To:")]),
                        _vm._v(" " + _vm._s(_vm.getContractorEtaFrom) + " "),
                        _c("b", [_c("br"), _vm._v(" From ")]),
                        _vm._v(" " + _vm._s(_vm.getContractorEtaTo) + " "),
                      ])
                    : _vm.getAssignedContractor &&
                      _vm.getAssignedContractor.isContractorReAttend &&
                      _vm.getContractorEtaFrom &&
                      _vm.getAssignedContractor.status !==
                        _vm.jobVisitStatus[_vm.jobVisitStatus.Unavailable]
                    ? _c("span", [
                        _c("i", [
                          _c("img", {
                            staticClass: "icon-img-tile",
                            attrs: { src: "/img/refresh-user.svg" },
                          }),
                        ]),
                        _c("b", [_vm._v("ETA To:")]),
                        _vm._v(" " + _vm._s(_vm.getContractorEtaFrom) + " "),
                        _c("b", [_c("br"), _vm._v(" From ")]),
                        _vm._v(" " + _vm._s(_vm.getContractorEtaTo) + " "),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "text-xs-right" }),
                ],
                2
              ),
              _vm.emergenciesIcon.length > 0
                ? _c(
                    "v-card-actions",
                    {
                      staticClass: "pa-1 grey lighten-2 show-icons full-width",
                    },
                    _vm._l(_vm.emergenciesIcon, function (emergency, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "i",
                          { staticClass: "icons" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "img",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  src: emergency.fileURL,
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(emergency.description)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }