var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.usingUDEDashboardV2
    ? _c("div", [_c("UndeployedEmergencyTableV2")], 1)
    : _c(
        "div",
        [
          _vm.ready
            ? _c("UndeployedEmergencyTable", {
                attrs: {
                  items: _vm.jobItems,
                  "table-name": "undeployedEmergencyDashboard",
                  "is-loading": _vm.isLoading,
                  "is-from-main-dash-board": true,
                },
              })
            : _c(
                "div",
                [
                  _c("v-progress-circular", {
                    staticClass: "loading-spinner",
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }