var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "questions-list-content" },
    [
      _vm._l(_vm.items, function (item) {
        return _c("EmergencyQAEditControl", {
          key: item.id,
          attrs: {
            "job-id": _vm.jobId,
            "emergency-q-a-id": item.id,
            "is-job-completed": _vm.isJobCompleted,
          },
          on: { setSyncing: _vm.setSyncing },
        })
      }),
      _c(
        "div",
        { staticClass: "claim-btn-content grey lighten-3" },
        [
          _c(
            "v-card",
            { staticClass: "elevation-0 grey lighten-3", attrs: { wrap: "" } },
            [
              _c(
                "v-card-actions",
                { attrs: { xs12: "" } },
                [
                  _c("v-spacer"),
                  _vm.job.status !== "Completed"
                    ? _c(
                        "v-btn",
                        {
                          class: _vm.showHideButtonCriteria
                            ? "green white--text"
                            : _vm.isLoading
                            ? "grey"
                            : "",
                          attrs: {
                            disabled:
                              _vm.getCompleteJobButtonCriteria ||
                              _vm.isLoading ||
                              _vm.isSyncing,
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.onCompleteHelpline },
                        },
                        [_vm._v(" Complete Job ")]
                      )
                    : !_vm.clientClaimSubmitted
                    ? _c(
                        "v-btn",
                        {
                          class: _vm.showHideButtonCriteria
                            ? "green white--text"
                            : _vm.isLoading
                            ? "grey"
                            : "",
                          attrs: {
                            disabled:
                              !_vm.showHideButtonCriteria || _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: { click: _vm.onResendClientEmail },
                        },
                        [_vm._v(" Resend Email ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }