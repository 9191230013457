var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "mb-3 enablement" },
        [
          _c(
            "v-card-title",
            { staticClass: "pb-0" },
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs11: "" } }, [
                    _c("h3", [_vm._v("Enablement")]),
                  ]),
                  _vm.syncing
                    ? _c(
                        "v-flex",
                        { attrs: { xs1: "", "text-xs-right": "" } },
                        [
                          _vm.syncing
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "processing-icon",
                                  attrs: { medium: "" },
                                },
                                [_vm._v("sync")]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "pt-3" },
            [
              _c("v-switch", {
                attrs: {
                  color: "primary",
                  disabled: _vm.isNotesDisabled || _vm.syncing || _vm.saving,
                  label: "Enablement Required?",
                },
                on: { change: _vm.editComplete },
                model: {
                  value: _vm.localEnablement,
                  callback: function ($$v) {
                    _vm.localEnablement = $$v
                  },
                  expression: "localEnablement",
                },
              }),
              _vm.enablement
                ? _c("v-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "requirementNotes",
                    attrs: {
                      label: "Please enter the requirement notes...",
                      "data-vv-name": "Enablement Requirement Notes",
                      "error-messages": _vm.errors.collect(
                        "Enablement Requirement Notes"
                      ),
                      disabled:
                        _vm.isNotesDisabled || _vm.syncing || _vm.saving,
                    },
                    on: { focus: _vm.editStarted, blur: _vm.onSave },
                    model: {
                      value: _vm.requirementNotes,
                      callback: function ($$v) {
                        _vm.requirementNotes = $$v
                      },
                      expression: "requirementNotes",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("v-progress-linear", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.saving,
                expression: "saving",
              },
            ],
            attrs: {
              color: "primary",
              height: "3",
              value: "15",
              indeterminate: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }