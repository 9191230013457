var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.jobItem.email && !_vm.jobItem.nomineeEmail
        ? _c(
            "a",
            { attrs: { href: "mailto:" + _vm.jobItem.email } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-avatar",
                              _vm._g(
                                { attrs: { color: "white", size: 35 } },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary" } },
                                  [_vm._v("email")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2916552704
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.jobItem.email))])]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.jobItem.email && _vm.jobItem.nomineeEmail
        ? _c(
            "a",
            { attrs: { href: "mailto:" + _vm.jobItem.nomineeEmail } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-avatar",
                              _vm._g(
                                { attrs: { color: "white", size: 35 } },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary" } },
                                  [_vm._v("email")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2916552704
                  ),
                },
                [_c("span", [_vm._v(_vm._s(_vm.jobItem.nomineeEmail))])]
              ),
            ],
            1
          )
        : _vm.jobItem.email && _vm.jobItem.nomineeEmail
        ? _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "close-on-content-click": false,
                "nudge-width": 250,
                "max-width": 250,
                "z-index": 999,
                fixed: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on }) {
                    return [
                      _c(
                        "span",
                        _vm._g({ staticClass: "ma-0 pt-2" }, on),
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-badge",
                                          {
                                            attrs: {
                                              overlap: "",
                                              color:
                                                "grey small-size-badge darken-2",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                {
                                                  staticClass: "elevation-5",
                                                  attrs: {
                                                    color: "white",
                                                    size: 35,
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "secondary--text",
                                                    attrs: {
                                                      dark: "",
                                                      small: "",
                                                    },
                                                  },
                                                  [_vm._v("email")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Email")])]
                          ),
                          _c("span", { staticClass: "n-arrow" }),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("v-card", [
                _c(
                  "div",
                  { staticClass: "view-panel contact-list" },
                  [
                    _c(
                      "v-list",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                { staticClass: "name primary--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.jobItem.clientFullName) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-2" },
                                [_vm._v(" " + _vm._s(_vm.jobItem.email) + " ")]
                              ),
                              _c("span", { staticClass: "action" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "elevation-1",
                                    attrs: {
                                      href: "mailto:" + _vm.jobItem.email,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          small: "",
                                          color: "primary",
                                        },
                                      },
                                      [_vm._v("email")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-list-tile",
                          [
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                { staticClass: "name primary--text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.jobItem.nomineeClientFullName
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v("(Nominee)"),
                                  ]),
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "grey--text text--darken-2" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.jobItem.nomineeEmail) + " "
                                  ),
                                ]
                              ),
                              _c("span", { staticClass: "action" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "elevation-1",
                                    attrs: {
                                      href:
                                        "mailto:" + _vm.jobItem.nomineeEmail,
                                    },
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          btn: "",
                                          small: "",
                                          color: "primary",
                                        },
                                      },
                                      [_vm._v("email")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }