var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.getDrainageReport
        ? _c("v-flex", { attrs: { md8: "", lg9: "", xs8: "" } }, [
            _c("div", { staticClass: "small-text report-name" }, [
              _c("b", [_vm._v("Name:")]),
              _vm._v(" " + _vm._s(_vm.getDrainageReport.name) + " "),
            ]),
            _vm.getDrainageReport.updatedAt
              ? _c("div", { staticClass: "small-text report-updated-date" }, [
                  _c("b", [_vm._v("Updated At:")]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.getFormattedDate(_vm.getDrainageReport.updatedAt)
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ])
        : _c("v-flex", [
            _c("div", { staticClass: "small-text" }, [
              _vm._v("Drainage Report"),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }