var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-flex",
        [
          _c(
            "v-expansion-panel",
            _vm._l(_vm.cookieRecord.settings, function (setting, index) {
              return _c(
                "v-expansion-panel-content",
                {
                  key: `CookieSetting-${index}`,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("h4", { staticClass: "text-uppercase" }, [
                              _vm._v(_vm._s(_vm.generateSettingHeader(index))),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "cookie-setting" },
                    [
                      _vm.isPlaceholder(setting.id)
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "cookie-setting__placeholder green--text font-weight-bold",
                            },
                            [_vm._v(" PLACEHOLDER ")]
                          )
                        : _c(
                            "v-tooltip",
                            {
                              staticClass: "cookie-setting__placeholder",
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: "",
                                                flat: "",
                                                color: "primary",
                                                small: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeSetting(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [_c("v-icon", [_vm._v("close")])],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Remove Setting")])]
                          ),
                      _c(
                        "v-layout",
                        { staticClass: "pa-3", attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "pl-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "mb-3",
                                attrs: {
                                  label: "Cookie Setting Name",
                                  hint: "The name of the setting",
                                  value: setting.id,
                                  required: "",
                                  "data-vv-name": `cookieSettingId ${
                                    index + 1
                                  }`,
                                  "data-vv-scope": _vm.validationScope,
                                  "error-messages": _vm.errors.collect(
                                    `cookieSettingId ${index + 1}`
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCookieRecordSettings(
                                      $event,
                                      index,
                                      "id"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "", "pl-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "mb-3",
                                attrs: {
                                  label: "Description",
                                  hint: "A brief description about the setting",
                                  value: setting.description,
                                  required: "",
                                  "data-vv-name": `cookieSettingDescription ${
                                    index + 1
                                  }`,
                                  "data-vv-scope": _vm.validationScope,
                                  "error-messages": _vm.errors.collect(
                                    `cookieSettingDescription ${index + 1}`
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCookieRecordSettings(
                                      $event,
                                      index,
                                      "description"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "pl-2": "" } },
                            [
                              _c("v-textarea", {
                                staticClass: "mb-3",
                                attrs: {
                                  label:
                                    "Default Value (Optional - Not needed for placeholders)",
                                  hint: "Optional default value",
                                  value: setting.defaultValue,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCookieRecordSettings(
                                      $event,
                                      index,
                                      "defaultValue"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-btn",
            {
              on: {
                click: function ($event) {
                  return _vm.addSetting()
                },
              },
            },
            [_vm._v("Add Setting")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }