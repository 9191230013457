var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "cctv-accordion", attrs: { wrap: "" } },
    [
      _vm.cctvControlLogDetail &&
      _vm.cctvControlLogDetail.cctvControlRunLogs &&
      _vm.cctvControlLogDetail.cctvControlRunLogs.length > 0
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-expansion-panel",
                { staticClass: "trialpit-accordion elevation-0" },
                _vm._l(
                  _vm.cctvControlLogDetail.cctvControlRunLogs,
                  function (cctvControlLog, index) {
                    return _c(
                      "v-expansion-panel-content",
                      { key: index, staticClass: "grey lighten-4 mb-2" },
                      [
                        _c(
                          "v-layout",
                          {
                            staticClass: "align-center",
                            attrs: { slot: "header", wrap: "" },
                            slot: "header",
                          },
                          [
                            _c(
                              "v-flex",
                              {
                                class: _vm.isCCTVLogAbandoned ? "xs12" : "xs4",
                              },
                              [
                                _c("h3", [
                                  _vm._v(
                                    "Run " +
                                      _vm._s(cctvControlLog.cctvRunNumber)
                                  ),
                                ]),
                              ]
                            ),
                            !_vm.isCCTVLogAbandoned
                              ? _c(
                                  "v-flex",
                                  { attrs: { xs8: "", "text-xs-right": "" } },
                                  [
                                    _vm.job && _vm.job.jobType === "US"
                                      ? _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "ma-0 mr-3 btn-createRepairEstimate",
                                            attrs: {
                                              color: "primary",
                                              loading: _vm.loading.cctvRunLogs,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                _vm.showRepairEstimationDialog = true
                                              },
                                            },
                                          },
                                          [_vm._v(" Create Estimate ")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 close-icon btn-addUpdateCCTV",
                                        attrs: {
                                          flat: "",
                                          icon: "",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onAddUpdatCCTVControlRun(
                                              false,
                                              cctvControlLog
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("edit")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 close-icon btn-deleteCCTV",
                                        attrs: {
                                          flat: "",
                                          icon: "",
                                          color: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.onDeleteCCTVControlRun(
                                              cctvControlLog
                                            )
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c("CCTVControlRunLog", {
                          attrs: {
                            "cctv-control-run-log": cctvControlLog,
                            "video-source": _vm.getVideoSource(cctvControlLog),
                            "job-id": _vm.jobId,
                          },
                        }),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isCCTVLogAbandoned
        ? _c(
            "v-flex",
            { staticClass: "text-xs-right", attrs: { xs12: "" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mb-3 mr-0 btn-addNewRun",
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onAddUpdatCCTVControlRun(true)
                    },
                  },
                },
                [_vm._v("Add New Run")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.cctvControlLogDetail && !_vm.isCCTVLogAbandoned
        ? _c(
            "v-flex",
            {
              attrs: {
                xs12: "",
                "pl-1": "",
                "lighten-4": "",
                "px-3": "",
                "pt-2": "",
              },
            },
            [
              _c("v-textarea", {
                attrs: { rows: "3", label: "Notes" },
                model: {
                  value: _vm.cctvControlLogDetail.notes,
                  callback: function ($$v) {
                    _vm.$set(_vm.cctvControlLogDetail, "notes", $$v)
                  },
                  expression: "cctvControlLogDetail.notes",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.cctvControlLogDetail &&
      (_vm.isCCTVLogAbandoned ||
        _vm.cctvControlLogDetail.status ===
          _vm.siJobProductProgress[_vm.siJobProductProgress.Finished])
        ? _c(
            "v-flex",
            {
              attrs: {
                xs12: "",
                "pl-3": "",
                grey: "",
                "lighten-4": "",
                "pa-2": "",
              },
            },
            [
              _c(
                "b",
                { class: _vm.isCCTVLogAbandoned ? "red--text" : "text-green" },
                [
                  _vm._v(
                    " CCTV Log " +
                      _vm._s(
                        _vm.isCCTVLogAbandoned ? "Abandoned" : "Completed"
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.cctvControlLogDetail
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _vm.isCCTVLogAbandoned
              ? _c(
                  "div",
                  { staticClass: "pa-3 mb-2 abandon-detail grey lighten-4" },
                  [
                    _c("div", { staticClass: "pb-1" }, [
                      _c("label", [_c("b", [_vm._v("Notes:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.cctvControlLogDetail.notes
                                ? _vm.cctvControlLogDetail.notes
                                : "-"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "pb-1" }, [
                      _c("label", [_c("b", [_vm._v("Abandon Reason:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          _vm._s(_vm.cctvControlLogDetail.abandonedReason)
                        ),
                      ]),
                    ]),
                    _vm.cctvControlLogDetail.abandonedReasonNotes
                      ? _c("div", [
                          _c("label", [_c("b", [_vm._v("Abandon Note:")])]),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.cctvControlLogDetail.abandonedReasonNotes
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "text-xs-right" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-3 mr-0 btn-saveCCTVControl",
                        attrs: {
                          color: "primary",
                          loading: _vm.loading.saveCCTVControl,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.saveCCTVLog()
                          },
                        },
                      },
                      [_vm._v(" Save ")]
                    ),
                    _vm.cctvControlLogDetail.status !==
                      _vm.siJobProductProgress[
                        _vm.siJobProductProgress.Finished
                      ] &&
                    _vm.cctvControlLogDetail.cctvControlRunLogs &&
                    _vm.cctvControlLogDetail.cctvControlRunLogs.length > 0
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mb-3 mr-0 btn-completeCCTVControl",
                            attrs: {
                              color: "success",
                              loading: _vm.loading.completeCCTVControl,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveCCTVLog(true)
                              },
                            },
                          },
                          [_vm._v(" Complete ")]
                        )
                      : _vm._e(),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mb-3 mr-0 white--text btn-abandonCCTVLog",
                        attrs: { color: "red" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openAbandonReasonDialog()
                          },
                        },
                      },
                      [_vm._v(" Abandon ")]
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _vm.addUpdateRunDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "850",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.addUpdateRunDialog,
                callback: function ($$v) {
                  _vm.addUpdateRunDialog = $$v
                },
                expression: "addUpdateRunDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.isAddNewRun
                                ? "Add New Run"
                                : "Update Run Detail"
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-runDialogClose",
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.discardRunChangesConfirmation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog" },
                    [
                      _c("CCTVControlRunLog", {
                        ref: "refCCTVControlRunLog",
                        attrs: {
                          "is-add-update-mode": true,
                          "cctv-log-configuration": _vm.cctvLogConfiguration,
                          "cctv-control-run-log": _vm.cctvControlRunLogModel,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-runDialogClose",
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.discardRunChangesConfirmation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Close ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 btn-runDialogSubmit",
                          attrs: {
                            color: "primary",
                            disabled: _vm.loading.cctvRunLogs,
                            loading: _vm.loading.cctvRunLogs,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onAddUpdateRunDetail.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showAbandonReasonDialog
        ? _c("AbandonSiteDataDialog", {
            ref: "abandonSiteDataDialog",
            attrs: {
              "record-type": _vm.abandonRecordType,
              "abandon-reason-details": _vm.abandonReasonDetails,
            },
            on: {
              abandonReasonSave: _vm.onAbandonReasonSave,
              closeAbandonReasonDialog: function ($event) {
                _vm.showAbandonReasonDialog = false
              },
            },
          })
        : _vm._e(),
      _vm.showRepairEstimationDialog
        ? _c("RepairEstimationDialog", {
            ref: "repairEstimationDialog",
            attrs: {
              "policy-client-rate-package-id":
                _vm.job && _vm.job.policyClientRatePackageId
                  ? _vm.job.policyClientRatePackageId
                  : "",
              "job-id": _vm.jobId,
            },
            on: {
              closeRepairEstimationDialog: _vm.onCloseRepairEstimationDialog,
            },
          })
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }