var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.emergencyTypeDesc
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.emergencyTypeDesc))]),
        ])
      : _vm._e(),
    _vm.item.emergencyDetailDesc
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Emergency Detail: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.emergencyDetailDesc))]),
        ])
      : _vm._e(),
    _vm.item.tradeDesc
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Trade: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.tradeDesc))]),
        ])
      : _vm._e(),
    _vm.item.note
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Note: ")]),
          _c("span", [_vm._v(_vm._s(_vm.item.note))]),
        ])
      : _vm._e(),
    _vm.item.firstNoticedAtUtc
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("First Noticed: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.firstNoticedAtUtc))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Create Emergency")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }