var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobDelay
    ? _c(
        "v-layout",
        { staticClass: "mb-2", attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("span", [
              _c("b", [_vm._v("Reason:")]),
              _vm._v(" " + _vm._s(_vm.jobDelay.reason) + " "),
            ]),
          ]),
          _vm.jobDelay.notes
            ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
                _c("span", [
                  _c("b", [_vm._v("Note:")]),
                  _vm._v(" " + _vm._s(_vm.jobDelay.notes) + " "),
                ]),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }