var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "mb-2" }, [
      _c("h4", [
        _vm._v(_vm._s(_vm.item.id ? "Update" : "Add") + " Trial Pit Drawing"),
      ]),
    ]),
    _vm.item.testDetails
      ? _c("div", [
          _vm._m(0),
          _vm.item.testDetails.testId
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Test Id: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.testDetails.testId))]),
              ])
            : _vm._e(),
          _vm.item.testDetails.testDate
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Test Date: ")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.getFormattedDate(_vm.item.testDetails.testDate))
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.item.testDetails.actualTestDate
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Actual Test Date: ")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.getFormattedDate(_vm.item.testDetails.actualTestDate)
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.item.testDetails.testTime
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Test Time: ")]),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.getFormattedTime(_vm.item.testDetails.testTime))
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.item.testDetails.actualTestTime
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Actual Test Time: ")]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.getFormattedTime(_vm.item.testDetails.actualTestTime)
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.item.testDetails.testWeather
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Test Weather: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.testDetails.testWeather))]),
              ])
            : _vm._e(),
          _vm.item.testDetails.testExcavationMethod
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Test Excavation Method: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.item.testDetails.testExcavationMethod)),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.item.pitDetail
      ? _c(
          "div",
          [
            _c("v-divider", { staticClass: "mb-1" }),
            _vm._m(1),
            _vm.item.pitDetail.width
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Width(mm): ")]),
                  _c("span", [_vm._v(_vm._s(_vm.item.pitDetail.width))]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.height
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Height(mm): ")]),
                  _c("span", [_vm._v(_vm._s(_vm.item.pitDetail.height))]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.depth
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Depth(mm): ")]),
                  _c("span", [_vm._v(_vm._s(_vm.item.pitDetail.depth))]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.groundLevel
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Ground Level(mm): ")]),
                  _c("span", [_vm._v(_vm._s(_vm.item.pitDetail.groundLevel))]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.groundLevelLabel
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Ground Level Label: ")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.pitDetail.groundLevelLabel)),
                  ]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.aboveGroundMaterial
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Above Ground Material: ")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.pitDetail.aboveGroundMaterial)),
                  ]),
                ])
              : _vm._e(),
            _vm.item.pitDetail.strataBelowRef
              ? _c("div", { staticClass: "mb-1" }, [
                  _c("label", [_vm._v("Strata Below Ref: ")]),
                  _c("span", [
                    _vm._v(_vm._s(_vm.item.pitDetail.strataBelowRef)),
                  ]),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm.item.leftLayers.length > 0
      ? _c(
          "div",
          [
            _c("v-divider", { staticClass: "mb-1" }),
            _vm._m(2),
            _vm._l(_vm.item.leftLayers, function (layer, index) {
              return _c(
                "div",
                { key: index },
                [
                  layer.label
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Label: ")]),
                        _c("span", [_vm._v(_vm._s(layer.label))]),
                      ])
                    : _vm._e(),
                  layer.layerType
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Type: ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerType))]),
                      ])
                    : _vm._e(),
                  layer.topStep
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Top Step(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.topStep))]),
                      ])
                    : _vm._e(),
                  layer.layerDepth
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Depth(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerDepth))]),
                      ])
                    : _vm._e(),
                  layer.bottomStep
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Bottom Step(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.bottomStep))]),
                      ])
                    : _vm._e(),
                  layer.edgeType
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Edge Type: ")]),
                        _c("span", [_vm._v(_vm._s(layer.edgeType))]),
                      ])
                    : _vm._e(),
                  layer.layerDivider
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Divider: ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerDivider))]),
                      ])
                    : _vm._e(),
                  layer.horizontalMeasurement
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Horizontal Measurement: ")]),
                        _c("span", [
                          _vm._v(_vm._s(layer.horizontalMeasurement)),
                        ]),
                      ])
                    : _vm._e(),
                  layer.verticalMeasurement
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Vertical Measurement: ")]),
                        _c("span", [_vm._v(_vm._s(layer.verticalMeasurement))]),
                      ])
                    : _vm._e(),
                  _vm.item.leftLayers.length - 1 !== index
                    ? _c("v-divider", { staticClass: "mb-1 mr-2 ml-2" })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.item.rightLayers.length > 0
      ? _c(
          "div",
          [
            _c("v-divider", { staticClass: "mb-1" }),
            _vm._m(3),
            _vm._l(_vm.item.rightLayers, function (layer, index) {
              return _c(
                "div",
                { key: index },
                [
                  layer.label
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Label: ")]),
                        _c("span", [_vm._v(_vm._s(layer.label))]),
                      ])
                    : _vm._e(),
                  layer.layerType
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Type: ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerType))]),
                      ])
                    : _vm._e(),
                  layer.topStep
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Top Step(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.topStep))]),
                      ])
                    : _vm._e(),
                  layer.layerDepth
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Depth(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerDepth))]),
                      ])
                    : _vm._e(),
                  layer.bottomStep
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Bottom Step(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.bottomStep))]),
                      ])
                    : _vm._e(),
                  layer.edgeType
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Edge Type: ")]),
                        _c("span", [_vm._v(_vm._s(layer.edgeType))]),
                      ])
                    : _vm._e(),
                  layer.rootsObserved
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Roots Observed: ")]),
                        _c("span", [_vm._v(_vm._s(layer.rootsObserved))]),
                      ])
                    : _vm._e(),
                  layer.rootsMaxDiameter
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Roots Max Diameter(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.rootsMaxDiameter))]),
                      ])
                    : _vm._e(),
                  layer.layerDivider
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Layer Divider: ")]),
                        _c("span", [_vm._v(_vm._s(layer.layerDivider))]),
                      ])
                    : _vm._e(),
                  layer.horizontalMeasurement
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Horizontal Measurement: ")]),
                        _c("span", [
                          _vm._v(_vm._s(layer.horizontalMeasurement)),
                        ]),
                      ])
                    : _vm._e(),
                  layer.verticalMeasurement
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Vertical Measurement: ")]),
                        _c("span", [_vm._v(_vm._s(layer.verticalMeasurement))]),
                      ])
                    : _vm._e(),
                  _vm.item.rightLayers.length - 1 !== index
                    ? _c("v-divider", { staticClass: "mb-1 mr-2 ml-2" })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.item.features.length > 0
      ? _c(
          "div",
          [
            _c("v-divider", { staticClass: "mb-1" }),
            _vm._m(4),
            _vm._l(_vm.item.features, function (layer, index) {
              return _c(
                "div",
                { key: index },
                [
                  layer.type
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Type: ")]),
                        _c("span", [_vm._v(_vm._s(layer.type))]),
                      ])
                    : _vm._e(),
                  layer.label
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Label: ")]),
                        _c("span", [_vm._v(_vm._s(layer.label))]),
                      ])
                    : _vm._e(),
                  layer.alignment
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Alignment: ")]),
                        _c("span", [_vm._v(_vm._s(layer.alignment))]),
                      ])
                    : _vm._e(),
                  layer.orientation
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Orientation: ")]),
                        _c("span", [_vm._v(_vm._s(layer.orientation))]),
                      ])
                    : _vm._e(),
                  layer.position
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Position: ")]),
                        _c("span", [_vm._v(_vm._s(layer.position))]),
                      ])
                    : _vm._e(),
                  layer.shape
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Shape: ")]),
                        _c("span", [_vm._v(_vm._s(layer.shape))]),
                      ])
                    : _vm._e(),
                  layer.y1
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Y1: ")]),
                        _c("span", [_vm._v(_vm._s(layer.y1))]),
                      ])
                    : _vm._e(),
                  layer.y2
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Y2: ")]),
                        _c("span", [_vm._v(_vm._s(layer.y2))]),
                      ])
                    : _vm._e(),
                  layer.x1
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("X1: ")]),
                        _c("span", [_vm._v(_vm._s(layer.x1))]),
                      ])
                    : _vm._e(),
                  layer.x2
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("X2: ")]),
                        _c("span", [_vm._v(_vm._s(layer.x2))]),
                      ])
                    : _vm._e(),
                  layer.depth
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Depth(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.depth))]),
                      ])
                    : _vm._e(),
                  layer.width
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Width(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.width))]),
                      ])
                    : _vm._e(),
                  layer.height
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Height(mm): ")]),
                        _c("span", [_vm._v(_vm._s(layer.height))]),
                      ])
                    : _vm._e(),
                  _vm.item.features.length - 1 !== index
                    ? _c("v-divider", { staticClass: "mb-1 mr-2 ml-2" })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.item.tests.length > 0
      ? _c(
          "div",
          [
            _c("v-divider", { staticClass: "mb-1" }),
            _vm._m(5),
            _vm._l(_vm.item.tests, function (test, index) {
              return _c(
                "div",
                { key: index },
                [
                  test.testType
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Test Type: ")]),
                        _c("span", [_vm._v(_vm._s(test.testType))]),
                      ])
                    : _vm._e(),
                  test.distanceFromWall
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Distance From Wall(mm): ")]),
                        _c("span", [_vm._v(_vm._s(test.distanceFromWall))]),
                      ])
                    : _vm._e(),
                  test.depthOfSample
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Depth of Sample: ")]),
                        _c("span", [_vm._v(_vm._s(test.depthOfSample))]),
                      ])
                    : _vm._e(),
                  test.testResults
                    ? _c("div", { staticClass: "mb-1" }, [
                        _c("label", [_vm._v("Test Results: ")]),
                        _c("span", [_vm._v(_vm._s(test.testResults))]),
                      ])
                    : _vm._e(),
                  _vm.item.tests.length - 1 !== index
                    ? _c("v-divider", { staticClass: "mb-1 mr-2 ml-2" })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Test Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Pit Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Left Layer Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Right Layer Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Feature Details")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [_c("h4", [_vm._v("Tests")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }