var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { timeout: 0, bottom: "", left: "", vertical: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "TransitionGroup",
        { staticClass: "d-flex flex-col gap-2", attrs: { name: "slide" } },
        _vm._l(_vm.items, function ({ id, jobId, message }) {
          return _c("div", { key: Number(id), staticClass: "notification" }, [
            _c("h3", { staticClass: "secondary--text" }, [
              _c("b", [_vm._v(_vm._s(jobId))]),
            ]),
            _c("div", [_vm._v(_vm._s(message))]),
          ])
        }),
        0
      ),
      _c(
        "v-btn",
        { attrs: { color: "pink", flat: "" }, on: { click: _vm.clear } },
        [_c("v-icon", [_vm._v("close")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }