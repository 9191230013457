var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loader,
              expression: "loader",
            },
          ],
        },
        [
          _c("v-progress-circular", {
            style: _vm.getLoaderStyle(50),
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _c(
        "v-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loader,
              expression: "!loader",
            },
          ],
          staticClass: "pt-0 px-2 pb-2",
        },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "heading" }, [
                      _c("span", { staticClass: "teal--text" }, [
                        _vm._v(_vm._s(_vm.getSoftFixedEmergency.type)),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", [
            _vm.getSoftFixedType
              ? _c("div", [
                  _c("span", { staticClass: "softfix-type" }, [
                    _c("b", [_vm._v("Type:")]),
                    _c("span", [_vm._v(_vm._s(_vm.getSoftFixedType()))]),
                  ]),
                ])
              : _vm._e(),
            _vm.getSoftFixedEmergency.description
              ? _c("div", { staticClass: "mt-1" }, [
                  _c("span", { staticClass: "softfix-type" }, [
                    _c("b", [_vm._v("Description:")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getSoftFixedEmergency.description)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.getSoftFixedEmergency.description && _vm.getUser
              ? _c(
                  "div",
                  { staticClass: "mt-2 text-xs-right" },
                  [
                    _c("em", [
                      _vm._v(
                        "Accepted by " + _vm._s(_vm.getUser.displayValue) + "  "
                      ),
                    ]),
                    _c("v-avatar", { staticClass: "mr-2 avatar" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.getUser.profileImageUrlWithUnknownFallback,
                          alt: "Logged user",
                        },
                      }),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }