var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "fill-height client-section py-3" },
    [
      _c(
        "div",
        {
          staticClass: "clinet-inner-content fill-height",
          class: _vm.istRateTemplateTabSelected
            ? false
            : !_vm.insurerDropdown ||
              (_vm.isClientUserLoggedIn &&
                !_vm.showSelectJobTypeDropdown &&
                !_vm.showSelectVisitTypeDropdown)
            ? "client-rate-tab"
            : "",
        },
        [
          _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "v-dialog--scrollable",
                "max-width": "700",
                persistent: "",
              },
              model: {
                value: _vm.showInsurerDialog,
                callback: function ($$v) {
                  _vm.showInsurerDialog = $$v
                },
                expression: "showInsurerDialog",
              },
            },
            [
              _c("Insurer", {
                on: { CloseInsurerDialog: _vm.onInsurerDialogClose },
              }),
            ],
            1
          ),
          _vm.insurerDropdown || _vm.istRateTemplateTabSelected
            ? _c(
                "v-layout",
                {
                  staticClass: "text-right insurer-search",
                  class:
                    _vm.showSelectJobTypeDropdown ||
                    _vm.showSelectVisitTypeDropdown
                      ? "job-type-dropdown"
                      : "",
                  attrs: { row: "", wrap: "", "align-center": "", xs12: "" },
                },
                [
                  _vm.showSelectJobTypeDropdown
                    ? _c("v-select", {
                        staticClass: "pr-3 select-jobType",
                        attrs: {
                          items: _vm.getJobTypes,
                          label: "Job Type",
                          "item-text": "jobTypeDescription",
                          "item-value": "jobType",
                        },
                        model: {
                          value: _vm.selectedJobType,
                          callback: function ($$v) {
                            _vm.selectedJobType = $$v
                          },
                          expression: "selectedJobType",
                        },
                      })
                    : _vm._e(),
                  _vm.insurerDropdown
                    ? [
                        _vm.showJobViewToggle
                          ? _c("v-switch", {
                              staticClass: "enhanced-toggle",
                              attrs: {
                                color: "primary",
                                small: "",
                                label: "List View",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.showEnhancedJobView,
                                callback: function ($$v) {
                                  _vm.showEnhancedJobView = $$v
                                },
                                expression: "showEnhancedJobView",
                              },
                            })
                          : _vm._e(),
                        _vm.showSelectVisitTypeDropdown
                          ? _c("v-autocomplete", {
                              staticClass: "pr-3",
                              attrs: {
                                items: _vm.getVisitTypes,
                                "item-text": "alterText",
                                "item-value": "description",
                                label: "Visit Type",
                                loading: _vm.getVisitTypeRecordsRunning,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filterJobsBySelectedVisitType(
                                    $event
                                  )
                                },
                              },
                              model: {
                                value: _vm.selectedVisitType,
                                callback: function ($$v) {
                                  _vm.selectedVisitType = $$v
                                },
                                expression: "selectedVisitType",
                              },
                            })
                          : _vm._e(),
                        !_vm.isClientUserLoggedIn
                          ? _c("v-autocomplete", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "required insurer-list",
                              attrs: {
                                label: "Search insurer",
                                required: "",
                                items: _vm.insurers,
                                loading: _vm.insurerSearchRunning,
                                "item-text": "name",
                                "item-value": "id",
                                "data-vv-scope": "frmClientManagement",
                                "data-vv-name": "Search insurer",
                                "error-messages":
                                  _vm.errors.collect("Search insurer"),
                                disabled: _vm.insurerSearchRunning,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeInsurer($event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile-content",
                                          [
                                            _c(
                                              "v-list-tile-title",
                                              {
                                                class: {
                                                  "bold-select":
                                                    data.item.id === -1,
                                                  "deleted-item":
                                                    data.item.isDelete,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1651854071
                              ),
                              model: {
                                value: _vm.selectedInsurerId,
                                callback: function ($$v) {
                                  _vm.selectedInsurerId = $$v
                                },
                                expression: "selectedInsurerId",
                              },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "v-tabs",
            {
              attrs: { "icons-and-text": "", "hide-slider": true },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                  staticClass: "tab-wrap",
                },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-tab", { attrs: { href: _vm.clientAppUrl } }, [
                        _vm._v(" Client App "),
                        _c("img", {
                          staticClass: "mb-1",
                          attrs: {
                            src: "/img/material_box.svg",
                            width: "24",
                            alt: "Client App (beta)",
                          },
                        }),
                      ]),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-1" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectHomeTab.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Home "), _c("v-icon", [_vm._v("home")])],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-2" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectClientProfile.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Client Profile "),
                          _c(
                            "svg",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                version: "1.1",
                                viewBox: "0 0 297 297",
                                "enable-background": "new 0 0 297 297",
                                width: "25px",
                                height: "25px",
                              },
                            },
                            [
                              _c("g", [
                                _c("path", {
                                  attrs: {
                                    d: "M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z",
                                    fill: "#757575",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z",
                                    fill: "#757575",
                                  },
                                }),
                                _c("path", {
                                  attrs: {
                                    d: "m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z",
                                    fill: "#757575",
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-3" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectPolicyTab.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Policy Schedule "),
                          _c("v-icon", [_vm._v("insert_drive_file")]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-4" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectClientTemplateTab.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Template "),
                          _c("v-icon", [_vm._v("color_lens")]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "btn-rateTemplate",
                          attrs: { href: "#tab-5" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectRateTemplateTab.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Rate Template "),
                          _c("img", {
                            staticClass: "menu-icon menu-images",
                            attrs: {
                              src: "/img/pound-symbol-grey.svg",
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "active-menu-icon menu-images",
                            attrs: {
                              src: "/img/pound-symbol-pink.svg",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      !_vm.isUserRoleClientManager && !_vm.isClientUserLoggedIn
                        ? _c(
                            "v-tab",
                            {
                              attrs: { href: "#tab-6" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showInsurerDropdown.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(" Phone Numbers "),
                              _c("v-icon", [_vm._v("call")]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-7" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onSelectFinancialSummaryTab.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Financial Summary "),
                          _c("img", {
                            staticClass: "menu-icon menu-images",
                            attrs: { src: "/img/finance-grey.svg", alt: "" },
                          }),
                          _c("img", {
                            staticClass: "active-menu-icon menu-images",
                            attrs: { src: "/img/finance-pink.svg", alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-8" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showInsurerDropdown.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Reports "),
                          _c("v-icon", [_vm._v("bar_chart")]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          attrs: { href: "#tab-9" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showInsurerDropdown.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(" Client Users "),
                          _c("v-icon", { attrs: { size: "30" } }, [
                            _vm._v("person"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-1",
                      expression: "activeTab === 'tab-1'",
                    },
                  ],
                  staticClass: "px-4",
                  attrs: { value: "tab-1" },
                },
                [
                  _vm.showEnhancedJobView
                    ? _c("EnhancedAccessJobView")
                    : _c("ClientJobs", {
                        attrs: {
                          "previous-insurer-id": _vm.previousInsurerId,
                          "insurer-id": _vm.selectedInsurerId,
                          "client-jobs": _vm.clientJobs,
                          "visit-type": _vm.selectedVisitType,
                        },
                        on: {
                          "update:insurerId": function ($event) {
                            _vm.selectedInsurerId = $event
                          },
                          "update:insurer-id": function ($event) {
                            _vm.selectedInsurerId = $event
                          },
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-2",
                      expression: "activeTab === 'tab-2'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-2" },
                },
                [
                  _vm.selectedInsurerId
                    ? _c("ClientProfile", {
                        staticClass: "client-profile-content",
                        attrs: {
                          "insurer-detail": _vm.insurerDetail,
                          "is-profile-tab-selected": _vm.isProfileTabSelected,
                        },
                        on: { saveClientProfile: _vm.onSaveClientProfile },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-3",
                      expression: "activeTab === 'tab-3'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-3" },
                },
                [
                  _c("PolicySchedule", {
                    attrs: {
                      "all-client-rate-package-list":
                        _vm.selectedJobType === "US"
                          ? _vm.allUSClientRatePackageList
                          : _vm.allClientRatePackageList,
                      "selected-insurer-id": _vm.selectedInsurerId,
                      polices: _vm.getPolicyList,
                      "policy-search-running": _vm.policySearchRunning,
                      "job-type": _vm.selectedJobType,
                    },
                    on: {
                      "update:allClientRatePackageList": function ($event) {
                        _vm.selectedJobType === "US"
                          ? _vm.allUSClientRatePackageList
                          : (_vm.allClientRatePackageList = $event)
                      },
                      "update:all-client-rate-package-list": function ($event) {
                        _vm.selectedJobType === "US"
                          ? _vm.allUSClientRatePackageList
                          : (_vm.allClientRatePackageList = $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-4",
                      expression: "activeTab === 'tab-4'",
                    },
                  ],
                  staticClass: "py-1 make-portal-templates",
                  attrs: { value: "tab-4" },
                },
                [
                  _c("ClientTemplate", {
                    attrs: {
                      "selected-insurer-id": _vm.selectedInsurerId,
                      polices: _vm.getPublishedPolicies,
                      "policy-search-running": _vm.policySearchRunning,
                      templates: _vm.clientTemplates,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-5",
                      expression: "activeTab === 'tab-5'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-5" },
                },
                [
                  _vm.selectedJobType === "US"
                    ? _c("ClientRateUS", {
                        staticClass: "elevation-1",
                        attrs: {
                          "all-client-rate-package-list":
                            _vm.allUSClientRatePackageList,
                        },
                        on: {
                          "update:allClientRatePackageList": function ($event) {
                            _vm.allUSClientRatePackageList = $event
                          },
                          "update:all-client-rate-package-list": function (
                            $event
                          ) {
                            _vm.allUSClientRatePackageList = $event
                          },
                        },
                      })
                    : _c("ClientRate", {
                        staticClass: "elevation-1",
                        attrs: {
                          "all-client-rate-package-list":
                            _vm.allClientRatePackageList,
                        },
                        on: {
                          "update:allClientRatePackageList": function ($event) {
                            _vm.allClientRatePackageList = $event
                          },
                          "update:all-client-rate-package-list": function (
                            $event
                          ) {
                            _vm.allClientRatePackageList = $event
                          },
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-6",
                      expression: "activeTab === 'tab-6'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-6" },
                },
                [
                  _vm.selectedInsurerId &&
                  !_vm.isUserRoleClientManager &&
                  !_vm.isClientUserLoggedIn
                    ? _c("CustomerPhoneNumbers", {
                        attrs: { "selected-insurer-id": _vm.selectedInsurerId },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-7",
                      expression: "activeTab === 'tab-7'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-7" },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3 elevation-0" },
                    [
                      _vm.selectedInsurerId
                        ? _c("JobFinancialSummaryDashboard", {
                            attrs: {
                              "selected-insurer-id": _vm.selectedInsurerId,
                              "selected-job-type": _vm.selectedJobType,
                              "insurer-sales-ledgers": _vm.insurerSalesLedgers,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-8",
                      expression: "activeTab === 'tab-8'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-8" },
                },
                [
                  _vm.selectedInsurerId
                    ? _c("ClientReports", {
                        attrs: {
                          "insurer-id": _vm.selectedInsurerId,
                          "bordereau-id-to-open-details":
                            _vm.bordereauIdToOpenDetails,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-tab-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeTab === "tab-9",
                      expression: "activeTab === 'tab-9'",
                    },
                  ],
                  staticClass: "px-4 py-1",
                  attrs: { value: "tab-9" },
                },
                [
                  _vm.selectedInsurerId
                    ? _c("ClientUserList", {
                        attrs: {
                          "client-users": _vm.clientUsers,
                          "insurer-id": _vm.selectedInsurerId,
                          policies: _vm.polices,
                          "policy-search-running": _vm.policySearchRunning,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }