var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.weatherForecast != null
    ? _c("v-card", { attrs: { id: "weather" } }, [
        _c("div", { attrs: { id: "display" } }, [
          _c("div", { attrs: { id: "top" } }, [
            _c("div", { staticClass: "location" }, [
              _vm._v(_vm._s(_vm.locationTitle)),
            ]),
            _c("div", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.formatDate(_vm.selectedItem.forecastDate))),
            ]),
            _c("div", { staticClass: "status" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.selectedItem.daytimeForecast.weatherTypeDescription
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", { attrs: { id: "left-information" } }, [
            _c("img", {
              staticClass: "thumbnail",
              attrs: {
                src: _vm.getThumbnail(
                  _vm.selectedItem.daytimeForecast.weatherTypeCode
                ),
                alt: _vm.selectedItem.daytimeForecast.weatherTypeDescription,
              },
            }),
            _c("span", { staticClass: "temperature" }, [
              _vm._v(
                " " + _vm._s(_vm.selectedItem.daytimeForecast.temperature) + " "
              ),
            ]),
            _c("span", { staticClass: "unit" }, [_vm._v("°C")]),
          ]),
          _c(
            "div",
            { attrs: { id: "right-information" } },
            [
              _c("read-more", {
                staticClass: "regionalTextForecast",
                attrs: {
                  "more-str": "Read more...",
                  text: _vm.selectedItem.regionalText,
                  link: "#",
                  "less-str": "Read less",
                  "max-chars": 50,
                },
              }),
              _c("span", [
                _vm._v(
                  " Precipitation: " +
                    _vm._s(
                      _vm.selectedItem.daytimeForecast.precipitationProbability
                    ) +
                    "% "
                ),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "Humidity: " +
                    _vm._s(_vm.selectedItem.daytimeForecast.humidity) +
                    "%"
                ),
              ]),
              _c("br"),
              _c("span", [
                _vm._v(
                  "Wind speed: " +
                    _vm._s(_vm.selectedItem.daytimeForecast.windSpeed) +
                    " km/h"
                ),
              ]),
            ],
            1
          ),
          _c("div", { attrs: { id: "forecast" } }, [
            _c(
              "ul",
              [
                _vm._l(_vm.weatherForecast.forecastItems, function (f, i) {
                  return [
                    i < 5
                      ? _c(
                          "li",
                          {
                            key: i,
                            class: { selectedDay: _vm.selectedIndex === i },
                            on: {
                              click: function ($event) {
                                _vm.selectedIndex = i
                              },
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.dayOfWeek(f.forecastDate)) +
                                  " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.shortDate(f.forecastDate)) +
                                  " "
                              ),
                            ]),
                            _c("img", {
                              attrs: {
                                src: _vm.getThumbnail(
                                  f.daytimeForecast.weatherTypeCode
                                ),
                                alt: f.daytimeForecast.weatherTypeDescription,
                              },
                            }),
                            _c("div", [
                              _c("b", [
                                _vm._v(
                                  _vm._s(f.daytimeForecast.temperature) + "°"
                                ),
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(f.nighttimeForecast.temperature) +
                                  "° "
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }