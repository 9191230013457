var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", [
    _c(
      "span",
      { staticClass: "status-icon" },
      [
        _c(
          "v-tooltip",
          {
            attrs: { top: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function ({ on }) {
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        {
                          attrs: {
                            dark: "",
                            color: _vm.getColorByCoveredStatus,
                          },
                        },
                        on
                      ),
                      [_vm._v(" " + _vm._s(_vm.getCoveredIcon) + " ")]
                    ),
                  ]
                },
              },
            ]),
          },
          [_c("span", [_vm._v(_vm._s(_vm.getIconTooltip))])]
        ),
      ],
      1
    ),
    _vm.getAcceptedEmergency ||
    _vm.getRejectedEmergency ||
    _vm.getSoftFixedEmergency
      ? _c(
          "span",
          { staticClass: "chip-btn" },
          [
            _c(
              "v-chip",
              {
                attrs: {
                  color: _vm.getEmergencyStatusChipColor,
                  "text-color": "white",
                  small: "",
                },
              },
              [_vm._v(" " + _vm._s(_vm.getEmergencyStatus) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }