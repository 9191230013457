var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "mid-section-content contractor-section py-3" },
    [
      _c("v-progress-circular", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.ready,
            expression: "!ready",
          },
        ],
        style: _vm.getLoaderStyle(70),
        attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ready,
              expression: "ready",
            },
          ],
        },
        [
          !_vm.isContractorRegistrationCompleted && _vm.getContractor
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "px-3 progressbar-content" },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "px-0 pt-0 mb-0" },
                        [
                          _c(
                            "h4",
                            {
                              staticClass: "red--text text--darken-4",
                              class:
                                _vm.getFormCompletionStatusValue < 100
                                  ? "error--text"
                                  : "green--text",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getFormCompletionStatusText) +
                                  " "
                              ),
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-0 oca-submit-btn",
                              attrs: {
                                disabled:
                                  _vm.getFormCompletionStatusValue < 100,
                                color: "primary",
                              },
                              on: { click: _vm.submitContractorToReview },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                      _c("v-progress-linear", {
                        staticClass: "mb-0 mt-1",
                        attrs: {
                          color: "light-green darken-4",
                          height: "8",
                          value: _vm.getFormCompletionStatusValue,
                          striped: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _vm.isLoading
                        ? _c(
                            "div",
                            { staticClass: "full-height coa-loader" },
                            [
                              _c("v-progress-circular", {
                                attrs: {
                                  width: 2,
                                  size: 50,
                                  indeterminate: "",
                                  color: "primary",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-tabs",
                        {
                          attrs: { "icons-and-text": "", "hide-slider": true },
                          model: {
                            value: _vm.activeTab,
                            callback: function ($$v) {
                              _vm.activeTab = $$v
                            },
                            expression: "activeTab",
                          },
                        },
                        [
                          _c("v-tabs-slider", { attrs: { color: "yellow" } }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "bar",
                                  rawName: "v-bar",
                                  value: { useScrollbarPseudo: true },
                                  expression: "{ useScrollbarPseudo: true }",
                                },
                              ],
                              staticClass: "tab-wrap",
                            },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      class: _vm.getContractor.country
                                        ? "green--text text--darken-4"
                                        : "error--text text--darken-1",
                                      attrs: { href: "#tab-1" },
                                    },
                                    [
                                      _vm._v(" Contractor Profile "),
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            version: "1.1",
                                            viewBox: "0 0 297 297",
                                            "enable-background":
                                              "new 0 0 297 297",
                                            width: "25px",
                                            height: "25px",
                                          },
                                        },
                                        [
                                          _c("g", [
                                            _c("path", {
                                              attrs: {
                                                d: "M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z",
                                                fill: "#757575",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z",
                                                fill: "#757575",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z",
                                                fill: "#757575",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      class:
                                        _vm.getContractor.bankAccountDetail &&
                                        _vm.getContractor.bankAccountDetail.name
                                          ? "green--text text--darken-4"
                                          : "error--text text--darken-1",
                                      attrs: { href: "#tab-2" },
                                    },
                                    [
                                      _vm._v(" Bank Details "),
                                      _c(
                                        "v-icon",
                                        {
                                          class:
                                            _vm.getContractor
                                              .bankAccountDetail &&
                                            _vm.getContractor.bankAccountDetail
                                              .name
                                              ? "green--text text--darken-4"
                                              : "error--text text--darken-1",
                                        },
                                        [_vm._v(" credit_card ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      class:
                                        _vm.checkValidationForCompanyTrades()
                                          ? "green--text text--darken-4"
                                          : "error--text text--darken-1",
                                      attrs: { href: "#tab-3" },
                                    },
                                    [
                                      _vm._v(" Company Trades "),
                                      _c(
                                        "v-icon",
                                        {
                                          class:
                                            _vm.checkValidationForCompanyTrades()
                                              ? "green--text text--darken-4"
                                              : "error--text text--darken-1",
                                        },
                                        [_vm._v(" business ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "green--text text--darken-4",
                                      attrs: { href: "#tab-4" },
                                    },
                                    [
                                      _vm._v(" Coverage "),
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass:
                                            "green--text text--darken-4",
                                        },
                                        [_vm._v("place")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      class:
                                        _vm.checkValidationForTradesAssociation()
                                          ? "green--text text--darken-4"
                                          : "error--text text--darken-1",
                                      attrs: { href: "#tab-5" },
                                    },
                                    [
                                      _vm._v(" Trade Association Memberships "),
                                      _c(
                                        "v-icon",
                                        {
                                          class:
                                            _vm.checkValidationForTradesAssociation()
                                              ? "green--text text--darken-4"
                                              : "error--text text--darken-1",
                                        },
                                        [_vm._v(" people ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      class:
                                        _vm.checkHSWellbeingAndEnvironment()
                                          ? "green--text text--darken-4"
                                          : "error--text text--darken-1",
                                      attrs: { href: "#tab-6" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "d-inline-block mt-2" },
                                        [
                                          _vm._v(
                                            "Health, Safety, Wellbeing and the Environment"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "svg",
                                        {
                                          staticStyle: {
                                            "enable-background":
                                              "new 0 0 455 455",
                                          },
                                          attrs: {
                                            id: "Layer_1",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            version: "1.1",
                                            x: "0px",
                                            y: "0px",
                                            viewBox: "0 0 455 455",
                                            "xml:space": "preserve",
                                            fill: "#949494",
                                            width: "25px",
                                            height: "25px",
                                          },
                                        },
                                        [
                                          _c("g", [
                                            _c("polygon", {
                                              attrs: {
                                                points:
                                                  "370.5,158.498 310.5,158.498 310.5,227.498 241.5,227.498 241.5,287.498 310.5,287.498 310.5,356.498    370.5,356.498 370.5,287.498 439.5,287.498 439.5,227.498 370.5,227.498  ",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M211.5,197.498h69v-69h120v69h34.799c12.468-20,19.701-42.674,19.701-67.53C455,60.686,398.847,4.51,329.579,4.51   c-42.087,0-79.329,20.731-102.079,52.544C204.75,25.24,167.508,4.501,125.421,4.501C56.153,4.501,0,60.664,0,129.947   c0,30.118,10.612,57.752,28.299,79.376L227.5,450.499l53-64.169v-68.832h-69V197.498z",
                                              },
                                            }),
                                          ]),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      class:
                                        _vm.getContractor.signature &&
                                        _vm.getContractor.signature.name
                                          ? "green--text text--darken-4"
                                          : "error--text text--darken-1",
                                      attrs: { href: "#tab-7" },
                                    },
                                    [
                                      _vm._v(" Signature "),
                                      _c(
                                        "v-icon",
                                        {
                                          class:
                                            _vm.getContractor.signature &&
                                            _vm.getContractor.signature.name
                                              ? "green--text text--darken-4"
                                              : "error--text text--darken-1",
                                        },
                                        [_vm._v(" edit ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tab-item",
                            { staticClass: "py-3", attrs: { value: "tab-1" } },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "bar",
                                      rawName: "v-bar",
                                      value: { useScrollbarPseudo: true },
                                      expression:
                                        "{ useScrollbarPseudo: true }",
                                    },
                                  ],
                                  staticClass: "tab-wrap py-2 px-4",
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "grey lighten-2",
                                          attrs: { flat: "" },
                                        },
                                        [
                                          _c("ContractorProfile", {
                                            staticClass:
                                              "online-contractor-information",
                                            attrs: {
                                              "contractor-detail":
                                                Object.assign(
                                                  {},
                                                  _vm.getContractor
                                                ),
                                              "is-contractor-reviewed": false,
                                            },
                                            on: {
                                              updateContractorDetailUpdate:
                                                _vm.onContractorDetailUpdate,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm.activeTab === "tab-2"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3",
                                  attrs: { value: "tab-2" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      staticClass: "tab-wrap py-2 px-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c("v-card-title", [
                                                _c("h3", [
                                                  _vm._v("Bank Details"),
                                                ]),
                                              ]),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c("ContractorBankDetails", {
                                                    attrs: {
                                                      "contractor-bank-detail":
                                                        _vm.getContractor
                                                          .bankAccountDetail,
                                                      "contractor-id":
                                                        _vm.getContractorId,
                                                      "is-contractor-reviewed": false,
                                                    },
                                                    on: {
                                                      updateBankDetail:
                                                        _vm.onContractorBankDetailUpdate,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.activeTab === "tab-3"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3 px-4",
                                  attrs: { value: "tab-3" },
                                },
                                [
                                  _c(
                                    "v-card",
                                    { staticClass: "fill-height" },
                                    [
                                      _c("v-card-title", [
                                        _c("h3", [_vm._v("Company Trade")]),
                                      ]),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "contractorspecialization-content pr-0",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "bar",
                                                  rawName: "v-bar",
                                                  value: {
                                                    useScrollbarPseudo: true,
                                                  },
                                                  expression:
                                                    "{ useScrollbarPseudo: true }",
                                                },
                                              ],
                                              staticClass: "tab-wrap pr-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _vm.getContractor
                                                    ? _c(
                                                        "ContractorSpecialization",
                                                        {
                                                          staticClass:
                                                            "contractor-rs",
                                                          attrs: {
                                                            "contractor-id":
                                                              _vm.getContractor
                                                                .id,
                                                            contractor:
                                                              _vm.getContractor,
                                                            "is-at-contractor-registration": true,
                                                          },
                                                          on: {
                                                            updateContractorAvailability:
                                                              _vm.onContractorAvailabilityUpdate,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.activeTab === "tab-4"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3",
                                  attrs: { value: "tab-4" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      staticClass: "tab-wrap px-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _vm.getContractor
                                                    ? _c("ContractorArea", {
                                                        staticClass:
                                                          "contactor-rcov",
                                                        attrs: {
                                                          "contractor-id":
                                                            _vm.getContractor
                                                              .id,
                                                          coverage:
                                                            _vm.getContractorCoverage,
                                                          "contractor-availability":
                                                            _vm.getContractorAvailability,
                                                        },
                                                        on: {
                                                          coverageChange:
                                                            _vm.onCoverageChange,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.activeTab === "tab-5"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3",
                                  attrs: { value: "tab-5" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      staticClass: "tab-wrap px-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c("v-card-title", [
                                                _c("h3", [
                                                  _vm._v(
                                                    "Trade Association Memberships"
                                                  ),
                                                ]),
                                              ]),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _vm.getContractor
                                                    ? _c(
                                                        "ContractorTradeAssociationMemberships",
                                                        {
                                                          attrs: {
                                                            "contractor-id":
                                                              _vm.getContractor
                                                                .id,
                                                            contractor:
                                                              _vm.getContractor,
                                                            "document-detail-list":
                                                              _vm.documentDetailList,
                                                            "is-contractor-reviewed": false,
                                                          },
                                                          on: {
                                                            onSaveTradeMembershipDetails:
                                                              _vm.onSaveTradeMembershipDetails,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.activeTab === "tab-6"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3",
                                  attrs: { value: "tab-6" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      staticClass: "tab-wrap px-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c("v-card-title", [
                                                _c("h3", [
                                                  _vm._v(
                                                    "Health, Safety, Wellbeing and the Environment"
                                                  ),
                                                ]),
                                              ]),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _vm.getContractor
                                                    ? _c(
                                                        "ContractorHSAndWellbeingEnvironment",
                                                        {
                                                          attrs: {
                                                            "health-safety-wellbeing-environment":
                                                              _vm.getContractor
                                                                .healthSafetyWellbeingEnvironment,
                                                            "contractor-id":
                                                              _vm.getContractor
                                                                .id,
                                                            "document-detail-list":
                                                              _vm.documentDetailList,
                                                            "is-contractor-reviewed": false,
                                                          },
                                                          on: {
                                                            onSaveHealthSafetyWellbeingEnvironment:
                                                              _vm.onSaveHealthSafetyWellbeingEnvironment,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _c("v-divider"),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.activeTab === "tab-7"
                            ? _c(
                                "v-tab-item",
                                {
                                  staticClass: "py-3",
                                  attrs: { value: "tab-7" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "bar",
                                          rawName: "v-bar",
                                          value: { useScrollbarPseudo: true },
                                          expression:
                                            "{ useScrollbarPseudo: true }",
                                        },
                                      ],
                                      staticClass: "tab-wrap px-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "elevation-1 card-box-shadow-none",
                                              attrs: { flat: "" },
                                            },
                                            [
                                              _c("v-card-title", [
                                                _c("h3", [
                                                  _vm._v("Declaration"),
                                                ]),
                                              ]),
                                              _c("v-divider"),
                                              _c(
                                                "v-card-text",
                                                [
                                                  _vm.getContractor
                                                    ? _c(
                                                        "ContractorSignature",
                                                        {
                                                          attrs: {
                                                            "contractor-id":
                                                              _vm.getContractor
                                                                .id,
                                                            "contractor-signature":
                                                              _vm.getContractor
                                                                .signature,
                                                            "is-contractor-reviewed": false,
                                                          },
                                                          on: {
                                                            onSaveContractorSignatureDetails:
                                                              _vm.onSaveContractorSignatureDetails,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-snackbar",
                    {
                      attrs: {
                        timeout: _vm.snackbarTimeout,
                        left: "",
                        bottom: "",
                      },
                      model: {
                        value: _vm.snackbar,
                        callback: function ($$v) {
                          _vm.snackbar = $$v
                        },
                        expression: "snackbar",
                      },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", flat: "", color: "secondary" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.snackbar = false
                            },
                          },
                        },
                        [_vm._v("close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("div", { staticClass: "pa-4" }, [
                _vm._v(
                  " We’ve received your application and we’re currently reviewing it. We’ll get in touch with you with any questions or queries we might have. "
                ),
              ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }