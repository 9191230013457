var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.policyQuestion
    ? _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "pt-0 pb-1", attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:500",
                    expression: "'required|max:500'",
                  },
                ],
                staticClass: "required",
                attrs: {
                  maxlength: "500",
                  "data-vv-scope": "questionformRef",
                  "data-vv-name": "OverwrittenText",
                  "error-messages": _vm.errors.collect("OverwrittenText"),
                  required: "",
                },
                model: {
                  value: _vm.policyQuestion.overWrittenText,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.policyQuestion,
                      "overWrittenText",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "policyQuestion.overWrittenText",
                },
              }),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "grey lighten-3 pa-2 mb-3", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "switch-width-auto", attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    attrs: { "hide-details": "", color: "primary" },
                    on: { change: _vm.requiredForValidationChange },
                    model: {
                      value: _vm.policyQuestion.requiredForValidation,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.policyQuestion,
                          "requiredForValidation",
                          $$v
                        )
                      },
                      expression: "policyQuestion.requiredForValidation",
                    },
                  }),
                  _c("span", { staticClass: "mt-2 ml-2" }, [
                    _vm._v("Set Validation rules"),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-2": "",
                    "px-2": "",
                    "pt-2": "",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "caption blue--text",
                      class: _vm.policyQuestion.requiredForValidation
                        ? ""
                        : "text--lighten-2",
                    },
                    [
                      _vm._v(
                        " A claim must meet this following validation rule set up to be valid. "
                      ),
                    ]
                  ),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.policyQuestion.requiredForValidation
                          ? "required"
                          : "",
                        expression:
                          "policyQuestion.requiredForValidation ? 'required' : ''",
                      },
                    ],
                    class: _vm.policyQuestion.requiredForValidation
                      ? "required"
                      : "",
                    attrs: {
                      items: _vm.getOperatorsByAnswerType,
                      label: "Select",
                      disabled: !_vm.policyQuestion.requiredForValidation,
                      "item-text": "name",
                      "item-value": "id",
                      "data-vv-scope": "questionformRef",
                      "data-vv-name": "OperatorId",
                      "error-messages": _vm.errors.collect("OperatorId"),
                      required: _vm.policyQuestion.requiredForValidation
                        ? true
                        : false,
                    },
                    model: {
                      value: _vm.policyQuestion.operatorId,
                      callback: function ($$v) {
                        _vm.$set(_vm.policyQuestion, "operatorId", $$v)
                      },
                      expression: "policyQuestion.operatorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-2": "",
                    "px-2": "",
                    "pb-2": "",
                    "mb-2": "",
                  },
                },
                [
                  _vm.policyQuestion.question.answerTypeId ===
                    _vm.answerType.Text ||
                  _vm.policyQuestion.question.answerTypeId ===
                    _vm.answerType.Number
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: _vm.policyQuestion.mandatory,
                                decimal:
                                  _vm.policyQuestion.question.answerTypeId ===
                                  _vm.answerType.Number,
                                max: 200,
                              },
                            },
                            expression:
                              "{\n          rules: {\n            required: policyQuestion.mandatory,\n            decimal: policyQuestion.question.answerTypeId === answerType.Number,\n            max: 200,\n          },\n        }",
                          },
                        ],
                        class: _vm.policyQuestion.requiredForValidation
                          ? "required"
                          : "",
                        attrs: {
                          label: "Comparison Value",
                          maxlength: "200",
                          hint: _vm.policyQuestion.question.unit
                            ? "Comparison value should be in " +
                              _vm.policyQuestion.question.unit +
                              "."
                            : "",
                          disabled: !_vm.policyQuestion.requiredForValidation,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "ComparisonValue",
                          "error-messages":
                            _vm.errors.collect("ComparisonValue"),
                          required: _vm.policyQuestion.requiredForValidation
                            ? true
                            : false,
                        },
                        model: {
                          value: _vm.policyQuestion.comparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "comparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyQuestion.comparisonValue",
                        },
                      })
                    : _vm._e(),
                  _vm.policyQuestion.question.answerTypeId ===
                  _vm.answerType.Dropdown
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.policyQuestion.requiredForValidation
                              ? "required|max:200"
                              : "max:200",
                            expression:
                              "policyQuestion.requiredForValidation ? 'required|max:200' : 'max:200'",
                          },
                        ],
                        class: _vm.policyQuestion.requiredForValidation
                          ? "required"
                          : "",
                        attrs: {
                          items: _vm.getdropdownOptions,
                          label: "Comparison Value",
                          maxlength: "200",
                          multiple:
                            _vm.policyQuestion.operatorId ===
                              _vm.operatorType.In ||
                            _vm.policyQuestion.operatorId ===
                              _vm.operatorType.NotIn,
                          chips:
                            _vm.policyQuestion.operatorId ===
                              _vm.operatorType.In ||
                            _vm.policyQuestion.operatorId ===
                              _vm.operatorType.NotIn,
                          disabled: !_vm.policyQuestion.requiredForValidation,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "ComparisonValue",
                          "error-messages":
                            _vm.errors.collect("ComparisonValue"),
                          required: _vm.policyQuestion.requiredForValidation
                            ? true
                            : false,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onDropdpwnChange(
                              $event,
                              _vm.policyQuestion
                            )
                          },
                        },
                        model: {
                          value: _vm.policyQuestion.comparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "comparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyQuestion.comparisonValue",
                        },
                      })
                    : _vm._e(),
                  _vm.policyQuestion.question.answerTypeId ===
                  _vm.answerType.Checkbox
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.policyQuestion.requiredForValidation
                              ? "required|max:200"
                              : "max:200",
                            expression:
                              "policyQuestion.requiredForValidation ? 'required|max:200' : 'max:200'",
                          },
                        ],
                        class: _vm.policyQuestion.requiredForValidation
                          ? "required"
                          : "",
                        attrs: {
                          items: _vm.getcheckboxOptions,
                          label: "Comparison Value",
                          maxlength: "200",
                          disabled: !_vm.policyQuestion.requiredForValidation,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "ComparisonValue",
                          "error-messages":
                            _vm.errors.collect("ComparisonValue"),
                          required: _vm.policyQuestion.requiredForValidation
                            ? true
                            : false,
                        },
                        model: {
                          value: _vm.policyQuestion.comparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "comparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "policyQuestion.comparisonValue",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-2": "",
                    "px-2": "",
                    "pt-2": "",
                  },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "caption blue--text",
                      class: _vm.policyQuestion.requiredForValidation
                        ? ""
                        : "text--lighten-2",
                    },
                    [
                      _vm._v(
                        " If the answer to this questions makes the claim not valid, use this repudiation reason. "
                      ),
                    ]
                  ),
                  _c("v-autocomplete", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    class: _vm.policyQuestion.requiredForValidation
                      ? "required"
                      : "",
                    attrs: {
                      label: "Repudiation reason",
                      items: _vm.repudiations,
                      "item-text": "description",
                      "item-value": "id",
                      disabled: !_vm.policyQuestion.requiredForValidation,
                      required: _vm.policyQuestion.requiredForValidation
                        ? true
                        : false,
                      "data-vv-scope": "questionformRef",
                      "data-vv-name": "Repudiation reason",
                      "error-messages":
                        _vm.errors.collect("Repudiation reason"),
                    },
                    model: {
                      value: _vm.policyQuestion.repudiationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.policyQuestion, "repudiationId", $$v)
                      },
                      expression: "policyQuestion.repudiationId",
                    },
                  }),
                ],
                1
              ),
              _vm.repudiationMessage
                ? _c(
                    "v-flex",
                    {
                      attrs: {
                        xs12: "",
                        grey: "",
                        "lighten-2": "",
                        "px-2": "",
                        "pb-2": "",
                        "mb-2": "",
                      },
                    },
                    [
                      _c("label", [
                        _c("b", [_vm._v("Repudiation message:")]),
                        _c("span", [_vm._v(_vm._s(_vm.repudiationMessage))]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "grey lighten-3 pa-2", attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "switch-width-auto", attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    attrs: { "hide-details": "", color: "primary" },
                    on: { change: _vm.requiredForCommentChange },
                    model: {
                      value: _vm.policyQuestion.hasComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.policyQuestion, "hasComment", $$v)
                      },
                      expression: "policyQuestion.hasComment",
                    },
                  }),
                  _c("span", { staticClass: "mt-2 ml-2" }, [
                    _vm._v("Get Additional Information"),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.policyQuestion.hasComment ? "required" : "",
                        expression:
                          "policyQuestion.hasComment ? 'required' : ''",
                      },
                    ],
                    class: _vm.policyQuestion.hasComment ? "required" : "",
                    attrs: {
                      items: _vm.getOperatorsByAnswerType,
                      label: "Select",
                      disabled: !_vm.policyQuestion.hasComment,
                      "item-text": "name",
                      "item-value": "id",
                      "data-vv-scope": "questionformRef",
                      "data-vv-name": "CommentOperatorId",
                      "error-messages": _vm.errors.collect("CommentOperatorId"),
                      required: _vm.policyQuestion.hasComment ? true : false,
                    },
                    model: {
                      value: _vm.policyQuestion.forCommentOperatorId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.policyQuestion,
                          "forCommentOperatorId",
                          $$v
                        )
                      },
                      expression: "policyQuestion.forCommentOperatorId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.policyQuestion.question.answerTypeId ===
                    _vm.answerType.Text ||
                  _vm.policyQuestion.question.answerTypeId ===
                    _vm.answerType.Number
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: _vm.policyQuestion.mandatory,
                                decimal:
                                  _vm.policyQuestion.question.answerTypeId ===
                                  _vm.answerType.Number,
                                max: 200,
                              },
                            },
                            expression:
                              "{\n          rules: {\n            required: policyQuestion.mandatory,\n            decimal: policyQuestion.question.answerTypeId === answerType.Number,\n            max: 200,\n          },\n        }",
                          },
                        ],
                        class: _vm.policyQuestion.hasComment ? "required" : "",
                        attrs: {
                          label: "Comment Comparison value",
                          maxlength: "200",
                          hint: _vm.policyQuestion.question.unit
                            ? "Comment Comparison value should be in " +
                              _vm.policyQuestion.question.unit +
                              "."
                            : "",
                          disabled: !_vm.policyQuestion.hasComment,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "CommentComparisonValue",
                          "error-messages": _vm.errors.collect(
                            "CommentComparisonValue"
                          ),
                          required: _vm.policyQuestion.hasComment
                            ? true
                            : false,
                        },
                        model: {
                          value: _vm.policyQuestion.forCommentComparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "forCommentComparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "policyQuestion.forCommentComparisonValue",
                        },
                      })
                    : _vm._e(),
                  _vm.policyQuestion.question.answerTypeId ===
                  _vm.answerType.Dropdown
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.policyQuestion.hasComment
                              ? "required|max:200"
                              : "max:200",
                            expression:
                              "policyQuestion.hasComment ? 'required|max:200' : 'max:200'",
                          },
                        ],
                        class: _vm.policyQuestion.hasComment ? "required" : "",
                        attrs: {
                          items: _vm.getdropdownOptions,
                          label: "Comment Comparison Value",
                          maxlength: "200",
                          multiple:
                            _vm.policyQuestion.forCommentOperatorId ===
                              _vm.operatorType.In ||
                            _vm.policyQuestion.forCommentOperatorId ===
                              _vm.operatorType.NotIn,
                          chips:
                            _vm.policyQuestion.forCommentOperatorId ===
                              _vm.operatorType.In ||
                            _vm.policyQuestion.forCommentOperatorId ===
                              _vm.operatorType.NotIn,
                          disabled: !_vm.policyQuestion.hasComment,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "CommentComparisonValue",
                          "error-messages": _vm.errors.collect(
                            "CommentComparisonValue"
                          ),
                          required: _vm.policyQuestion.hasComment
                            ? true
                            : false,
                        },
                        model: {
                          value: _vm.policyQuestion.forCommentComparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "forCommentComparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "policyQuestion.forCommentComparisonValue",
                        },
                      })
                    : _vm._e(),
                  _vm.policyQuestion.question.answerTypeId ===
                  _vm.answerType.Checkbox
                    ? _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.policyQuestion.hasComment
                              ? "required|max:200"
                              : "max:200",
                            expression:
                              "policyQuestion.hasComment ? 'required|max:200' : 'max:200'",
                          },
                        ],
                        class: _vm.policyQuestion.hasComment ? "required" : "",
                        attrs: {
                          items: _vm.getcheckboxOptions,
                          label: "Comment Comparison Value",
                          maxlength: "200",
                          disabled: !_vm.policyQuestion.hasComment,
                          "data-vv-scope": "questionformRef",
                          "data-vv-name": "CommentComparisonValue",
                          "error-messages": _vm.errors.collect(
                            "CommentComparisonValue"
                          ),
                          required: _vm.policyQuestion.hasComment
                            ? true
                            : false,
                        },
                        model: {
                          value: _vm.policyQuestion.forCommentComparisonValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.policyQuestion,
                              "forCommentComparisonValue",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression:
                            "policyQuestion.forCommentComparisonValue",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-textarea", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.policyQuestion.hasComment
                          ? "required|max:500"
                          : "max:500",
                        expression:
                          "policyQuestion.hasComment ? 'required|max:500' : 'max:500'",
                      },
                    ],
                    attrs: {
                      label: "Comment label",
                      maxlength: "500",
                      disabled: !_vm.policyQuestion.hasComment,
                    },
                    model: {
                      value: _vm.policyQuestion.commentLabel,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.policyQuestion,
                          "commentLabel",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "policyQuestion.commentLabel",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }