<template>
  <v-layout wrap pa-2>
    <v-flex xs12 px-2 py-1>
      <h4>Please select an engineer to assign the job to</h4>
      <v-select
        v-model="selectedEngineerId"
        :items="availableEngineers"
        label="Select Engineer"
        item-text="name"
        item-value="id"
        persistent-hint
        class="hide-option"
        @change="assignEngineer"
      >
        <template #selection="{ item }">
          <v-avatar>
            <img v-if="item.profileImageThumbnailUrl" :src="item.profileImageThumbnailUrl" />
            <img v-else src="/img/unknownuser.png" />
          </v-avatar>
          {{ item.firstName }} {{ item.lastName }}
        </template>
        <template #item="{ item }">
          <v-list-tile-avatar>
            <img v-if="item.profileImageThumbnailUrl" :src="item.profileImageThumbnailUrl" />
            <img v-else src="/img/unknownuser.png" />
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title> {{ item.firstName }} {{ item.lastName }} </v-list-tile-title>
            <v-list-tile-sub-title>
              {{ item.email }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </template>
      </v-select>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { useDeploymentStore } from '@/pinia/deployment'
import Vue from 'vue'
import Component from 'vue-class-component'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'

@Component
export default class AssignEngineer extends Vue {
  public selectedEngineerId = ''

  public deploymentsStore = useDeploymentStore()

  public async assignEngineer(engineerId: string) {
    const displayErrorMessage = (message: string) =>
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar(message))

    const previouslyAssignedEngineerId = this.deploymentsStore.jobOffer?.contractorAppointedModel.engineerId
    if (previouslyAssignedEngineerId === engineerId) {
      displayErrorMessage('Engineer has already been assigned to this job, unable to accept')
    }

    try {
      const isSuccess = await this.deploymentsStore.assignEngineerToJob(engineerId)
      if (isSuccess) {
        this.$emit('engineerAssigned')
      } else {
        displayErrorMessage('Unable to assign engineer due to missing data. Please contact support.')
      }
    } catch {
      displayErrorMessage('Error assigning job to an engineer, please try again')
    }
  }

  public get availableEngineers() {
    if (!this.deploymentsStore.engineers || !this.deploymentsStore.jobOffer) {
      return []
    }

    return this.deploymentsStore.engineers
      .filter((e) => e.engineerAvailability !== null)
      .filter(
        (e) =>
          e.engineerAvailability.some(
            (t) => t.tradeId === this.deploymentsStore.jobOffer!.contractorAppointedModel.tradeId
          ) || e.id === null
      )
  }
}
</script>
