var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _vm.showContractorJobDetail
            ? _c("ContractorJobDetail", {
                ref: "contractorJobDetail",
                attrs: {
                  "parent-component": "SIJobDetail",
                  "contractor-job-detail-item": _vm.contractorJobDetail,
                },
                on: {
                  contractorJobDetailDialogClose: _vm.closeSIJobDetailDialog,
                },
              })
            : _c("v-progress-circular", {
                staticStyle: {
                  position: "absolute",
                  top: "400px",
                  left: "50%",
                },
                attrs: {
                  width: 2,
                  size: 50,
                  indeterminate: "",
                  color: "primary",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }