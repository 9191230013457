var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-stepper",
        {
          attrs: { vertical: "" },
          model: {
            value: _vm.currentStep,
            callback: function ($$v) {
              _vm.currentStep = $$v
            },
            expression: "currentStep",
          },
        },
        [
          _c(
            "v-stepper-step",
            {
              attrs: {
                step: 1,
                complete: _vm.step1validated && _vm.currentStep > 1,
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    !_vm.isUserRoleClientManager
                      ? "Select / Create a Policy"
                      : "Select a Policy"
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 1 } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "off" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pr-3 pl-2", attrs: { xs6: "" } },
                        [
                          _vm.isUserRoleClientManager
                            ? _c("v-autocomplete", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Search policy",
                                  maxlength: "100",
                                  required: "",
                                  "return-object": "",
                                  items: _vm.polices,
                                  loading: _vm.policySearchRunning,
                                  "item-text": "policyBasicDetail.name",
                                  "item-value": "policyBasicDetail.policyId",
                                  "data-vv-scope": _vm.step1formRef,
                                  "data-vv-name": "Search policy",
                                  "error-messages":
                                    _vm.errors.collect("Search policy"),
                                },
                                model: {
                                  value: _vm.selectedPolicy,
                                  callback: function ($$v) {
                                    _vm.selectedPolicy = $$v
                                  },
                                  expression: "selectedPolicy",
                                },
                              })
                            : _c("v-combobox", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required searchPolicy",
                                attrs: {
                                  label: "Search policy",
                                  maxlength: "100",
                                  required: "",
                                  "return-object": "",
                                  items: _vm.polices,
                                  loading: _vm.policySearchRunning,
                                  "item-text": "policyBasicDetail.name",
                                  "item-value": "policyBasicDetail.policyId",
                                  "data-vv-scope": _vm.step1formRef,
                                  "data-vv-name": "Search policy",
                                  name: "searchPolicy",
                                  "error-messages":
                                    _vm.errors.collect("Search policy"),
                                },
                                model: {
                                  value: _vm.selectedPolicy,
                                  callback: function ($$v) {
                                    _vm.selectedPolicy = $$v
                                  },
                                  expression: "selectedPolicy",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "btn-continue",
                              attrs: {
                                color: "primary",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onCompleteStep1.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Continue ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step2",
              attrs: {
                step: 2,
                complete: _vm.step2validated && _vm.currentStep > 2,
                editable: _vm.step1validated && _vm.currentStep > 2,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(2)
                },
              },
            },
            [_vm._v(" Policy Details ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 2 } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "off" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pl-2 pr-2", attrs: { xs12: "" } },
                        [
                          _c("PolicySubDetail", {
                            ref: "policySubDetail",
                            attrs: {
                              "policy-model": _vm.policyBasicDetailModel,
                              "all-client-rate-package-list":
                                _vm.allClientRatePackageList,
                              "job-type": _vm.jobType,
                              "selected-policy-is-new": _vm.selectedPolicyIsNew,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-savePolicyBasicDetail",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.processCurrentPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isUserRoleClientManager
                              ? "Next"
                              : "Save And Continue"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step3",
              attrs: {
                step: 3,
                complete: _vm.step3validated && _vm.currentStep > 3,
                editable:
                  _vm.step1validated &&
                  _vm.step2validated &&
                  _vm.currentStep > 3 &&
                  _vm.jobType === "HE",
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(3)
                },
              },
            },
            [_vm._v(" Communication ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 3 } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "off" } },
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "pa-0",
                      attrs: { fluid: "", "grid-list-xl": "" },
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "communication-content": "" } },
                        [
                          _c("AudioUpload", {
                            ref: "announcementMessageRef",
                            attrs: {
                              "audio-attachment-field-label":
                                "Announcement Message",
                              "form-reference": _vm.step3formRef,
                              "is-text-audio-message":
                                _vm.policyCommunicationDetail
                                  .announcementMessageIsText,
                              message: _vm.announcementMessage,
                            },
                            on: {
                              changeAudioMesssage:
                                _vm.OnAnnouncementMessageChange,
                            },
                          }),
                          _c("AudioUpload", {
                            ref: "reassuranceMessageOneRef",
                            attrs: {
                              "audio-attachment-field-label":
                                "Reassurance Message one",
                              "form-reference": _vm.step3formRef,
                              "is-text-audio-message":
                                _vm.policyCommunicationDetail
                                  .reassuranceMessageOneIsText,
                              message: _vm.reassuranceMessageOne,
                            },
                            on: {
                              changeAudioMesssage:
                                _vm.OnReassuranceMessageOneChange,
                            },
                          }),
                          _c("AudioUpload", {
                            ref: "reassuranceMessageTwoRef",
                            attrs: {
                              "audio-attachment-field-label":
                                "Reassurance Message two",
                              "form-reference": _vm.step3formRef,
                              "is-text-audio-message":
                                _vm.policyCommunicationDetail
                                  .reassuranceMessageTwoIsText,
                              message: _vm.reassuranceMessageTwo,
                            },
                            on: {
                              changeAudioMesssage:
                                _vm.OnReassuranceMessageTwoChange,
                            },
                          }),
                          _c("AudioUpload", {
                            ref: "surgeMessageRef",
                            attrs: {
                              "audio-attachment-field-label": "Surge Message",
                              "form-reference": _vm.step3formRef,
                              "is-text-audio-message":
                                _vm.policyCommunicationDetail
                                  .surgeMessageIsText,
                              message: _vm.surgeMessage,
                            },
                            on: {
                              changeAudioMesssage: _vm.OnSurgeMessageChange,
                            },
                          }),
                          _c("v-flex", { attrs: { sm6: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "link-portal-text" },
                              [
                                _c("v-select", {
                                  staticClass: "portalLink",
                                  attrs: {
                                    items: _vm.customerPortals,
                                    label: "Link to online portal",
                                    "item-text": "name",
                                    "hide-details": "",
                                    "item-value": "link",
                                    name: "portalLink",
                                    readonly: _vm.isUserRoleClientManager,
                                  },
                                  model: {
                                    value:
                                      _vm.policyCommunicationDetail.portalLink,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.policyCommunicationDetail,
                                        "portalLink",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policyCommunicationDetail.portalLink",
                                  },
                                }),
                                _c("div", { staticClass: "link-switch" }, [
                                  _c("span", { staticClass: "grey--text" }, [
                                    _vm._v("Enabled?"),
                                  ]),
                                  _c(
                                    "span",
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          disabled: _vm.isHelplinePolicy,
                                          color: "primary",
                                          small: "",
                                          name: "isPortalLinkEnabled",
                                          readonly: _vm.isUserRoleClientManager,
                                        },
                                        model: {
                                          value:
                                            _vm.policyCommunicationDetail
                                              .isPortalLinkEnabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.policyCommunicationDetail,
                                              "isPortalLinkEnabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "policyCommunicationDetail.isPortalLinkEnabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", { attrs: { sm6: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "average-hold-text" },
                              [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v(
                                    "Read out the average hold time to customers?"
                                  ),
                                ]),
                                _c("v-switch", {
                                  staticClass: "average-hold-switch",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    name: "readAverageHoldTime",
                                    readonly: _vm.isUserRoleClientManager,
                                  },
                                  model: {
                                    value:
                                      _vm.policyCommunicationDetail
                                        .readAverageHoldTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.policyCommunicationDetail,
                                        "readAverageHoldTime",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policyCommunicationDetail.readAverageHoldTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "average-hold-text" },
                              [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v("Offer callback option to customers?"),
                                ]),
                                _c("v-switch", {
                                  staticClass: "average-hold-switch",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    name: "callbackEnabled",
                                    readonly: _vm.isUserRoleClientManager,
                                  },
                                  model: {
                                    value:
                                      _vm.policyCommunicationDetail
                                        .callbackEnabled,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.policyCommunicationDetail,
                                        "callbackEnabled",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policyCommunicationDetail.callbackEnabled",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "average-hold-text" },
                              [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v("Digital Assistant Enabled"),
                                ]),
                                _c("v-switch", {
                                  staticClass: "average-hold-switch",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    name: "voiceFNOLEnabled",
                                    readonly: _vm.isUserRoleClientManager,
                                  },
                                  model: {
                                    value:
                                      _vm.policyCommunicationDetail
                                        .voiceFNOLEnabled,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.policyCommunicationDetail,
                                        "voiceFNOLEnabled",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policyCommunicationDetail.voiceFNOLEnabled",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("v-flex", { attrs: { sm6: "", md4: "" } }, [
                            _c(
                              "div",
                              { staticClass: "average-hold-text" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "grey--text mr-2 ml-2" },
                                  [_vm._v("Effort Score")]
                                ),
                                _c("v-switch", {
                                  staticClass: "effort-score-switch",
                                  attrs: {
                                    color: "primary",
                                    small: "",
                                    name: "effortScore",
                                    readonly: _vm.isUserRoleClientManager,
                                  },
                                  model: {
                                    value:
                                      _vm.policyCommunicationDetail.effortScore,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.policyCommunicationDetail,
                                        "effortScore",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "policyCommunicationDetail.effortScore",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "", md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Total surveys against a job per day",
                                  color: "primary",
                                  name: "totalSurveysPerJobPerDay",
                                  readonly: _vm.isUserRoleClientManager,
                                  disabled:
                                    !_vm.policyCommunicationDetail.effortScore,
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.numberKeyValidation($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.policyCommunicationDetail
                                      .totalSurveysPerJobPerDay,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.policyCommunicationDetail,
                                      "totalSurveysPerJobPerDay",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "policyCommunicationDetail.totalSurveysPerJobPerDay",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "", md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Total surveys against a job",
                                  color: "primary",
                                  name: "totalSurveysPerJob",
                                  readonly: _vm.isUserRoleClientManager,
                                  disabled:
                                    !_vm.policyCommunicationDetail.effortScore,
                                },
                                on: {
                                  keypress: function ($event) {
                                    return _vm.numberKeyValidation($event)
                                  },
                                },
                                model: {
                                  value:
                                    _vm.policyCommunicationDetail
                                      .totalSurveysPerJob,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.policyCommunicationDetail,
                                      "totalSurveysPerJob",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression:
                                    "policyCommunicationDetail.totalSurveysPerJob",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-savePolicyCommunicationDetail",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.processCurrentPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isUserRoleClientManager
                              ? "Next"
                              : "Save And Continue"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step4",
              attrs: {
                step: 4,
                complete: _vm.step4validated && _vm.currentStep > 4,
                editable:
                  _vm.step1validated &&
                  _vm.step2validated &&
                  _vm.step3validated &&
                  _vm.currentStep > 4,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(4)
                },
              },
            },
            [_vm._v(" Linked to policy Holder Database ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 4 } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "off" } },
                [
                  _c("PolicyRouteToMarket", {
                    ref: "policyRouteToMarket",
                    attrs: { "policy-routeto-market": _vm.policyRoutetoMarket },
                  }),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 ml-0 btn-savePolicyRouteToMarket",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.processCurrentPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isUserRoleClientManager
                              ? "Next"
                              : "Save And Continue"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step5",
              attrs: {
                step: 5,
                complete: _vm.step5validated && _vm.currentStep > 5,
                editable:
                  _vm.step1validated &&
                  _vm.step2validated &&
                  _vm.step3validated &&
                  _vm.step4validated &&
                  _vm.currentStep > 5,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(5)
                },
              },
            },
            [_vm._v(" Accommodation ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 5 } },
            [
              _c(
                "v-form",
                { attrs: { autocomplete: "off" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "pl-2 pr-2", attrs: { xs12: "" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label:
                                "If the home is not safe to live in, does the policy offer alternative accommodation?",
                              readonly: _vm.isUserRoleClientManager,
                              name: "includesAccommodation",
                            },
                            model: {
                              value:
                                _vm.policyAccommodationDetail
                                  .includesAccommodation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.policyAccommodationDetail,
                                  "includesAccommodation",
                                  $$v
                                )
                              },
                              expression:
                                "policyAccommodationDetail.includesAccommodation",
                            },
                          }),
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              label:
                                "Does the policy also offer transport to the alternative accommodation?",
                              readonly: _vm.isUserRoleClientManager,
                              name: "includesTransportToAccommodation",
                            },
                            model: {
                              value:
                                _vm.policyAccommodationDetail
                                  .includesTransportToAccommodation,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.policyAccommodationDetail,
                                  "includesTransportToAccommodation",
                                  $$v
                                )
                              },
                              expression:
                                "policyAccommodationDetail.includesTransportToAccommodation",
                            },
                          }),
                          _vm._v(
                            " Is there a separate value limit for alternative accommodation, in addition to the policy limit? "
                          ),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "max:8",
                                expression: "'max:8'",
                              },
                            ],
                            attrs: {
                              label: "Additional value",
                              placeholder: "0",
                              type: "number",
                              "data-vv-scope": _vm.step5formRef,
                              "data-vv-name": "Additional value",
                              "error-messages":
                                _vm.errors.collect("Additional value"),
                              name: "Additionalvalue",
                              readonly: _vm.isUserRoleClientManager,
                            },
                            model: {
                              value:
                                _vm.policyAccommodationDetail
                                  .accommodationExtraValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.policyAccommodationDetail,
                                  "accommodationExtraValue",
                                  _vm._n($$v)
                                )
                              },
                              expression:
                                "policyAccommodationDetail.accommodationExtraValue",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "btn-saveAccommodationDetail",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.processCurrentPage.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.isUserRoleClientManager
                              ? "Next"
                              : "Save And Continue"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step6",
              attrs: {
                step: 6,
                complete: _vm.step6validated && _vm.currentStep > 6,
                editable:
                  _vm.jobType === "HE"
                    ? _vm.step1validated &&
                      _vm.step2validated &&
                      _vm.step3validated &&
                      _vm.step4validated &&
                      _vm.step5validated &&
                      _vm.currentStep > 6 &&
                      !_vm.isHelplinePolicy
                    : _vm.step1validated &&
                      _vm.step2validated &&
                      _vm.currentStep > 6,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(6)
                },
              },
            },
            [_vm._v(" Select " + _vm._s(_vm.jobTypeLabel("emergency")) + " ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 6 } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pl-2 pr-2", attrs: { xs12: "" } },
                    [
                      _c("PolicyQuestionSelection", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isHelplinePolicy,
                            expression: "!isHelplinePolicy",
                          },
                        ],
                        ref: "policyQuestionSelection",
                        attrs: {
                          "policy-id": _vm.selectedPolicy
                            ? _vm.selectedPolicy.policyBasicDetail &&
                              _vm.selectedPolicy.policyBasicDetail.policyId
                              ? _vm.selectedPolicy.policyBasicDetail.policyId
                              : 0
                            : 0,
                          "insurer-id": _vm.selectedInsurerId,
                          "third-party-detail": _vm.getThirdPartyDetail,
                          "selected-emergencies": _vm.selectedEmergencies,
                          "selected-emergency-details":
                            _vm.selectedEmergencyDetails,
                          "is-reset-current-index": _vm.isResetCurrentIndex,
                          "job-type": _vm.jobType,
                        },
                        on: {
                          "update:selectedEmergencies": function ($event) {
                            _vm.selectedEmergencies = $event
                          },
                          "update:selected-emergencies": function ($event) {
                            _vm.selectedEmergencies = $event
                          },
                          "update:selectedEmergencyDetails": function ($event) {
                            _vm.selectedEmergencyDetails = $event
                          },
                          "update:selected-emergency-details": function (
                            $event
                          ) {
                            _vm.selectedEmergencyDetails = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2 btn-savePolicyEmergencyCoverage",
                  attrs: {
                    color: "primary",
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.processCurrentPage.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isUserRoleClientManager
                          ? "Next"
                          : "Save And Continue"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              staticClass: "switch-to-step7",
              attrs: {
                step: 7,
                complete: _vm.step7validated && _vm.currentStep > 7,
                editable:
                  _vm.jobType === "HE"
                    ? _vm.step1validated &&
                      _vm.step2validated &&
                      _vm.step3validated &&
                      _vm.step4validated &&
                      _vm.step5validated &&
                      (!_vm.isHelplinePolicy ? _vm.step6validated : true) &&
                      _vm.currentStep > 7
                    : _vm.step1validated &&
                      _vm.step2validated &&
                      _vm.step6validated &&
                      _vm.currentStep > 7,
              },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.switchToStep(7)
                },
              },
            },
            [
              _vm._v(
                " Select " +
                  _vm._s(_vm.jobTypeLabel("emergency")) +
                  " Questions "
              ),
            ]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 7 } },
            [
              _c("SelectedPolicyQuestionList", {
                ref: "selectedPolicyQuestionList",
                attrs: {
                  "selected-entities": _vm.selectedEntityListForQuestion,
                  "policy-question-list": _vm.policyQuestionList,
                  "selected-emergencies": _vm.selectedEmergencies,
                  "selected-emergency-details": _vm.selectedEmergencyDetails,
                  "third-party-detail": _vm.getThirdPartyDetail,
                  "policy-list": _vm.policyList,
                  "policy-id": _vm.selectedPolicy
                    ? _vm.selectedPolicy.policyBasicDetail &&
                      _vm.selectedPolicy.policyBasicDetail.policyId
                      ? _vm.selectedPolicy.policyBasicDetail.policyId
                      : 0
                    : 0,
                  trades: _vm.trades,
                  "job-type": _vm.jobType,
                  "is-helpline-policy": _vm.isHelplinePolicy,
                },
                on: {
                  bindPolicyQuestions: _vm.bindPolicyQuestions,
                  showSnackbarMessage: _vm.onShowSnackbarMessage,
                },
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4 btn-savePolicyQuestionList",
                  attrs: {
                    color: "primary",
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.processCurrentPage.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isUserRoleClientManager
                          ? "Next"
                          : "Save And Continue"
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-stepper-step",
            {
              attrs: {
                step: 8,
                complete: _vm.step8validated && _vm.currentStep > 8,
                editable:
                  _vm.step1validated &&
                  _vm.step2validated &&
                  _vm.step3validated &&
                  _vm.step4validated &&
                  _vm.step5validated &&
                  (!_vm.isHelplinePolicy ? _vm.step6validated : true) &&
                  _vm.step7validated &&
                  _vm.currentStep > 7,
              },
            },
            [_vm._v(" Select Document Template ")]
          ),
          _c(
            "v-stepper-content",
            { attrs: { step: 8 } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "pl-2 pr-2", attrs: { xs12: "" } },
                    [
                      _c("PolicyDocumentTemplate", {
                        ref: "policyDocumentTemplate",
                        attrs: {
                          "policy-document-templates":
                            _vm.policyModel.policyDocumentTemplates,
                        },
                      }),
                      !_vm.isUserRoleClientManager
                        ? _c(
                            "v-btn",
                            {
                              staticClass:
                                "mt-4 btn-savePolicyDocumentTemplate",
                              attrs: {
                                color: "primary",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.processCurrentPage.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-snackbar",
                        {
                          attrs: {
                            timeout: _vm.saveSnackbarTimeout,
                            bottom: true,
                            left: true,
                          },
                          model: {
                            value: _vm.saveSnackbar,
                            callback: function ($$v) {
                              _vm.saveSnackbar = $$v
                            },
                            expression: "saveSnackbar",
                          },
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "secondary" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.saveSnackbar = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }