var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", md6: "", lg8: "" } }, [
            _c("h3", { staticClass: "sub-title mt-4" }, [
              _vm._v("Below listed are the selected questions"),
            ]),
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "", md6: "", lg4: "" } },
            [
              !_vm.isHelplinePolicy && _vm.questionTypeList.length > 0
                ? _c("v-autocomplete", {
                    staticClass: "pr-1",
                    attrs: {
                      items: _vm.questionTypeList,
                      label: "Question Types",
                      "item-text": "name",
                      "item-value": "id",
                      "hide-details": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.OnChangeQuestionType($event)
                      },
                    },
                    model: {
                      value: _vm.selectedQuestionType,
                      callback: function ($$v) {
                        _vm.selectedQuestionType = $$v
                      },
                      expression: "selectedQuestionType",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "mt-3 gridView em-questionList tbody-scroll-table",
        attrs: { headers: _vm.headers, items: _vm.items, "hide-actions": "" },
        scopedSlots: _vm._u([
          {
            key: "headers",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  [
                    _vm._l(props.headers, function (header, index) {
                      return _c(
                        "th",
                        {
                          key: index,
                          staticClass: "column",
                          class:
                            header.text === "Mandatory" ||
                            header.text === "Validation"
                              ? "text-xs-center"
                              : "text-xs-left",
                        },
                        [_vm._v(" " + _vm._s(header.text) + " ")]
                      )
                    }),
                    !_vm.isUserRoleClientManager
                      ? _c(
                          "th",
                          {
                            staticClass: "column text-xs-center em-que-action",
                          },
                          [_vm._v("Actions")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
          },
          {
            key: "items",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    key: _vm.itemKey(props.item),
                    staticClass: "sortableRow",
                    attrs: { active: props.item.mandatory },
                  },
                  [
                    _c(
                      "td",
                      {
                        staticClass: "px-1 text-xs-left",
                        staticStyle: { width: "0.1%" },
                      },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "span",
                                        _vm._g(
                                          {
                                            staticClass: "sortHandle icon",
                                            staticStyle: { cursor: "move" },
                                          },
                                          on
                                        ),
                                        [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "24",
                                                height: "24",
                                                viewBox: "0 0 24 24",
                                                fill: "#757575",
                                              },
                                            },
                                            [
                                              _c("circle", {
                                                attrs: {
                                                  cy: "6.5",
                                                  cx: "9.5",
                                                  r: "1.5",
                                                },
                                              }),
                                              _c("circle", {
                                                attrs: {
                                                  cy: "6.5",
                                                  cx: "14.5",
                                                  r: "1.5",
                                                },
                                              }),
                                              _c("circle", {
                                                attrs: {
                                                  cy: "12.5",
                                                  cx: "9.5",
                                                  r: "1.5",
                                                },
                                              }),
                                              _c("circle", {
                                                attrs: {
                                                  cy: "12.5",
                                                  cx: "14.5",
                                                  r: "1.5",
                                                },
                                              }),
                                              _c("circle", {
                                                attrs: {
                                                  cy: "18.5",
                                                  cx: "9.5",
                                                  r: "1.5",
                                                },
                                              }),
                                              _c("circle", {
                                                attrs: {
                                                  cy: "18.5",
                                                  cx: "14.5",
                                                  r: "1.5",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Drag to change order")])]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      { staticClass: "text-xs-center" },
                      [
                        _c("v-checkbox", {
                          attrs: {
                            color: "primary",
                            "hide-details": "",
                            "input-value": props.item.mandatory,
                            disabled: _vm.isUserRoleClientManager,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.onMandatoryChange(props.item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c("td", [_vm._v(_vm._s(props.item.overWrittenText))]),
                    _c("td", [_vm._v(_vm._s(props.item.entityName))]),
                    _c(
                      "td",
                      { staticClass: "text-xs-center" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  props.item
                                                    .requiredForValidation,
                                                expression:
                                                  "props.item.requiredForValidation",
                                              },
                                            ],
                                            staticClass: "px-1",
                                            attrs: { color: "primary" },
                                          },
                                          on
                                        ),
                                        [_vm._v(" check_circle ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Validation Applied")])]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: props.item.hasComment,
                                                expression:
                                                  "props.item.hasComment",
                                              },
                                            ],
                                            staticClass: "px-1",
                                            attrs: { color: "primary" },
                                          },
                                          on
                                        ),
                                        [_vm._v("info")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Additional Information Added")])]
                        ),
                        _c(
                          "v-icon",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !props.item.requiredForValidation &&
                                  !props.item.hasComment,
                                expression:
                                  "!props.item.requiredForValidation && !props.item.hasComment",
                              },
                            ],
                            staticClass: "px-1",
                            attrs: { color: "primary" },
                          },
                          [_vm._v(" remove ")]
                        ),
                      ],
                      1
                    ),
                    !_vm.isUserRoleClientManager
                      ? _c(
                          "td",
                          { staticClass: "text-xs-center actions" },
                          [
                            _c(
                              "v-tooltip",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !props.item.keyQuestion,
                                    expression: "!props.item.keyQuestion",
                                  },
                                ],
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mr-2 dark",
                                                attrs: { left: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onKeyQuestionClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v("star_border")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Mark as key question")])]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: props.item.keyQuestion,
                                    expression: "props.item.keyQuestion",
                                  },
                                ],
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              {
                                                staticClass: "mr-2 dark",
                                                attrs: { left: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.onKeyQuestionClick(
                                                      props.item
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v("star")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Remove from key question")])]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-2",
                                attrs: { left: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editQuestion(props.item)
                                  },
                                },
                              },
                              [_vm._v("edit")]
                            ),
                            _c(
                              "v-icon",
                              {
                                staticClass: "mr-0",
                                attrs: { left: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onDeleteClick(props.item)
                                  },
                                },
                              },
                              [_vm._v("delete")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "dialog-content v-dialog--scrollable",
                "max-width": "600px",
                persistent: "",
              },
              model: {
                value: _vm.showDialog,
                callback: function ($$v) {
                  _vm.showDialog = $$v
                },
                expression: "showDialog",
              },
            },
            [
              _vm.policyQuestion
                ? _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [
                            _vm._v("Edit selected question"),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.showDialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "px-3 scroll-content-dialog" },
                        [
                          _c(
                            "v-form",
                            { attrs: { autocomplete: "off" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c("EditPolicyQuestion", {
                                    ref: "editPolicyQuestion",
                                    attrs: {
                                      question: _vm.policyQuestion,
                                      repudiations: _vm.repudiations,
                                    },
                                    on: {
                                      setQuestionDetail: _vm.setQuestionDetail,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", flat: "" },
                              on: {
                                click: function ($event) {
                                  _vm.showDialog = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-0",
                              attrs: { color: "primary" },
                              on: { click: _vm.saveQuestion },
                            },
                            [_vm._v("Save")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }