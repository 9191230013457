var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "div",
        [
          _vm._m(0),
          _vm.item.boreHoleRunNumber
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("BoreHole Run Number: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.boreHoleRunNumber))]),
              ])
            : _vm._e(),
          _vm.item.boringMethod
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Boring Method: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.boringMethod))]),
              ])
            : _vm._e(),
          _vm.item.diameter
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Diameter: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.diameter))]),
              ])
            : _vm._e(),
          _vm.item.weather
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Weather: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.weather))]),
              ])
            : _vm._e(),
          _vm.item.remarks
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Remarks: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.remarks))]),
              ])
            : _vm._e(),
          _vm.item.groundLevel
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Ground Level: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.groundLevel))]),
              ])
            : _vm._e(),
          _vm.item.changes.length > 0
            ? [
                _c("v-divider", { staticClass: "mb-1" }),
                _vm._l(_vm.item.changes, function (changeItem, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      changeItem.depth
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Depth: ")]),
                            _c("span", [_vm._v(_vm._s(changeItem.depth))]),
                          ])
                        : _vm._e(),
                      changeItem.thickness
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Thikness: ")]),
                            _c("span", [_vm._v(_vm._s(changeItem.thickness))]),
                          ])
                        : _vm._e(),
                      changeItem.testType
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Test: ")]),
                            _c("span", [_vm._v(_vm._s(changeItem.testType))]),
                          ])
                        : _vm._e(),
                      changeItem.testResult
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Result: ")]),
                            _c("span", [_vm._v(_vm._s(changeItem.testResult))]),
                          ])
                        : _vm._e(),
                      changeItem.legend
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Legend: ")]),
                            _c("span", [_vm._v(_vm._s(changeItem.legend))]),
                          ])
                        : _vm._e(),
                      changeItem.soilDescription
                        ? _c("div", { staticClass: "mb-1" }, [
                            _c("label", [_vm._v("Soil Description: ")]),
                            _c("span", [
                              _vm._v(_vm._s(changeItem.soilDescription)),
                            ]),
                          ])
                        : _vm._e(),
                      index !== _vm.item.changes.length - 1
                        ? _c("v-divider", { staticClass: "mb-1" })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Update Trial Pit Borehole Details")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }