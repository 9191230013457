var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-card",
                { staticClass: "elevation-1 pa-0 card-content" },
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pl-3": "", "pr-4": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Search by postcode",
                                      "prepend-icon": "search",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.searchInput,
                                      callback: function ($$v) {
                                        _vm.searchInput = $$v
                                      },
                                      expression: "searchInput",
                                    },
                                  }),
                                ],
                                1
                              ),
                              !_vm.filteredPostcodes.length
                                ? _c(
                                    "v-flex",
                                    { attrs: { "pl-3": "", "mt-2": "" } },
                                    [_c("span", [_vm._v("Record not found.")])]
                                  )
                                : _vm._e(),
                              _c(
                                "v-flex",
                                { staticClass: "checkbox-list" },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      directives: [
                                        { name: "bar", rawName: "v-bar" },
                                      ],
                                      attrs: {
                                        xs12: "",
                                        "px-3": "",
                                        "mt-2": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "postcode-list pb-3 pr-0",
                                        },
                                        [
                                          _vm.allAvailablePostcodes.length > 0
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  _vm.filteredPostcodes,
                                                  function (postcode) {
                                                    return _c(
                                                      "v-layout",
                                                      {
                                                        key: postcode.areaCode,
                                                      },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          [
                                                            _c(
                                                              "v-expansion-panel",
                                                              {
                                                                staticClass:
                                                                  "elevation-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "checkbox-list__expansion-list",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-expansion-panel-content",
                                                                      {
                                                                        attrs: {
                                                                          lazy: true,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                slot: "header",
                                                                              },
                                                                            slot: "header",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        postcode.areaCode +
                                                                                          " - " +
                                                                                          postcode.description
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          postcode.outcodes,
                                                                          function (
                                                                            outcode
                                                                          ) {
                                                                            return _c(
                                                                              "v-card",
                                                                              {
                                                                                key: outcode,
                                                                                staticClass:
                                                                                  "elevation-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-card-text",
                                                                                  {
                                                                                    staticClass:
                                                                                      "checkbox-list__expansion-items d-flex",
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkbox-list__outcode",
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            outcode
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-select",
                                                                                      {
                                                                                        staticClass:
                                                                                          "checkbox-list__select",
                                                                                        attrs:
                                                                                          {
                                                                                            value:
                                                                                              _vm.regions.find(
                                                                                                (
                                                                                                  region
                                                                                                ) =>
                                                                                                  region.outcodes.includes(
                                                                                                    outcode
                                                                                                  )
                                                                                              ),
                                                                                            "item-text":
                                                                                              "name",
                                                                                            items:
                                                                                              _vm.regions,
                                                                                            loading:
                                                                                              _vm.isLoading,
                                                                                            "return-object":
                                                                                              "",
                                                                                          },
                                                                                        on: {
                                                                                          change:
                                                                                            function (
                                                                                              $event
                                                                                            ) {
                                                                                              return _vm.changeRegion(
                                                                                                $event,
                                                                                                outcode
                                                                                              )
                                                                                            },
                                                                                        },
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          }
                                                                        ),
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }