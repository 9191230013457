var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.ready,
              expression: "ready",
            },
          ],
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0 pb-2" },
            [
              _c("v-spacer"),
              _c("v-select", {
                staticClass: "mr-2 pt-2 mw-350 jobType",
                attrs: {
                  items: _vm.getJobTypes,
                  label: "Job Type",
                  "item-text": "jobTypeDescription",
                  "item-value": "jobType",
                },
                model: {
                  value: _vm.selectedJobType,
                  callback: function ($$v) {
                    _vm.selectedJobType = $$v
                  },
                  expression: "selectedJobType",
                },
              }),
              _c("v-autocomplete", {
                staticClass: "mw-350 pt-0 mt-0 mr-3",
                attrs: {
                  items: _vm.userListForFilter,
                  "single-line": "",
                  "item-text": "displayValue",
                  "item-value": "id",
                  label: "Assigned to",
                  "return-object": "",
                  "hide-details": "",
                },
                on: {
                  change: function ($event) {
                    return _vm.onSearchInputChanged($event)
                  },
                },
                model: {
                  value: _vm.searchUserValue,
                  callback: function ($$v) {
                    _vm.searchUserValue = $$v
                  },
                  expression: "searchUserValue",
                },
              }),
              _c("v-text-field", {
                staticClass: "en-request-search pt-0 mt-0",
                attrs: {
                  "single-line": "",
                  label: "Search by JobId",
                  "hide-details": "",
                  "append-icon": "search",
                },
                on: {
                  keypress: function ($event) {
                    return _vm.jobIdValidation($event)
                  },
                },
                model: {
                  value: _vm.searchInput,
                  callback: function ($$v) {
                    _vm.searchInput = $$v
                  },
                  expression: "searchInput",
                },
              }),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "en-request-section", attrs: { xs12: "" } },
                [
                  _c(
                    "v-tabs",
                    {
                      staticClass: "en-request-detail",
                      attrs: { "icons-and-text": "", "hide-slider": true },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c("v-tabs-slider", { attrs: { color: "yellow" } }),
                      _c(
                        "div",
                        {
                          staticClass: "tab-wrapper",
                          class: _vm.activeTab === "tab-1" ? "active" : "",
                        },
                        [
                          _c("span", [_vm._v("New Job")]),
                          _c("v-tab", { attrs: { href: "#tab-1" } }, [
                            _vm._v(" New Job "),
                            _c("b", [
                              _vm._v(
                                "(" + _vm._s(_vm.getNewJobsList.length) + ")"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-wrapper",
                          class:
                            _vm.activeTab === "tab-2" ||
                            _vm.activeTab === "tab-3"
                              ? "active"
                              : "",
                        },
                        [
                          _c("span", [_vm._v("Pre-Start")]),
                          _c("v-tab", { attrs: { href: "#tab-2" } }, [
                            _vm._v(" Customer not present "),
                            _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.getPolicyHolderNotPresentList.length
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]),
                          _c("v-tab", { attrs: { href: "#tab-3" } }, [
                            _vm._v(" Engineer Reject Visit "),
                            _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.getEngineerRejectVisitList.length
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]),
                          _c("v-tab", { attrs: { href: "#tab-7" } }, [
                            _vm._v(" Running Late "),
                            _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.getRunningLateRequestList.length) +
                                  ")"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-wrapper",
                          class:
                            _vm.activeTab === "tab-4" ||
                            _vm.activeTab === "tab-5"
                              ? "active"
                              : "",
                        },
                        [
                          _c("span", [_vm._v("Urgent from Site")]),
                          _c("v-tab", { attrs: { href: "#tab-4" } }, [
                            _vm._v(" Engineer Abandoned Visit "),
                            _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    _vm.engineerAbandonedVisitList.length
                                  ) +
                                  ")"
                              ),
                            ]),
                          ]),
                          _c("v-tab", { attrs: { href: "#tab-5" } }, [
                            _vm._v(" Resource Request "),
                            _c("b", [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.getResourceRequestList.length) +
                                  ")"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-wrapper",
                          class: _vm.activeTab === "tab-6" ? "active" : "",
                        },
                        [
                          _c("span", [_vm._v("Completion")]),
                          _c("v-tab", { attrs: { href: "#tab-6" } }, [
                            _vm._v(" Job Complete "),
                            _c("b", [
                              _vm._v(
                                "(" + _vm._s(_vm.VisitCompleteList.length) + ")"
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("v-tab-item", { attrs: { value: "tab-1" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm._v(" " + _vm._s(_vm.startTimer()) + " "),
                                _vm.getNewJobsList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.getNewJobsList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              request
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "request-details",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "jobnumber mb-2",
                                                        },
                                                        [
                                                          _c("h4", [
                                                            _vm._v(
                                                              " Job Number: "
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "secondary--text",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.redirectToJob(
                                                                        request
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      request.jobId
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          request.assignedStatus ===
                                                          _vm
                                                            .iMRequestProcessStatus[
                                                            _vm
                                                              .iMRequestProcessStatus
                                                              .Assigned
                                                          ]
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "request-time",
                                                                  class:
                                                                    request.assignedStatus ===
                                                                      _vm
                                                                        .iMRequestProcessStatus[
                                                                        _vm
                                                                          .iMRequestProcessStatus
                                                                          .Assigned
                                                                      ] &&
                                                                    !_vm.isAssignedUserLoggedIn(
                                                                      request.assignedUserId
                                                                    )
                                                                      ? "primary--text"
                                                                      : "secondary--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "timer"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "countdown-time",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.timerCounter(
                                                                                  request
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c("br"),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                      request.engineerRequests &&
                                                      request.engineerRequests
                                                        .length > 0 &&
                                                      request
                                                        .engineerRequests[0]
                                                        .address
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "address mb-2",
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Address :"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    request
                                                                      .engineerRequests[0]
                                                                      .address
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "div",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                              },
                                                            },
                                                            [
                                                              request.engineerRequests &&
                                                              request
                                                                .engineerRequests
                                                                .length > 0 &&
                                                              request
                                                                .engineerRequests[0]
                                                                .loggedAt
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Logged Date:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.getFormattedDate(
                                                                              request
                                                                                .engineerRequests[0]
                                                                                .loggedAt
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              request.forEmergencyTypeId
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Emergency Type:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.getEmergencyTypeName(
                                                                                  request.forEmergencyTypeId
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                              },
                                                            },
                                                            [
                                                              request.engineerRequests &&
                                                              request
                                                                .engineerRequests
                                                                .length > 0 &&
                                                              request
                                                                .engineerRequests[0]
                                                                .loggedVia
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "By:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                request
                                                                                  .engineerRequests[0]
                                                                                  .loggedVia
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              request.forEmergencyDetailId
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Emergency Details:"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.getEmergencyDetailName(
                                                                                  request.forEmergencyDetailId
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs4: "",
                                                                    "text-xs-right":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "action-engineer",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm.isUserSupervisor &&
                                                                          request.assignedStatus ===
                                                                            _vm
                                                                              .iMRequestProcessStatus[
                                                                              _vm
                                                                                .iMRequestProcessStatus
                                                                                .Assigned
                                                                            ]
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Assigned To: " +
                                                                                      _vm._s(
                                                                                        _vm.getAssignedUserName(
                                                                                          request.assignedUserId
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _vm.isUserSupervisor
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "supervisor-assign",
                                                                            },
                                                                            [
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Pending
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.activeIndex ===
                                                                                            index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openAssignJobDialog(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assign "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Assigned
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.activeIndex ===
                                                                                            index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openAssignJobDialog(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Reassign "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Assigned
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCompleteJobDialog(
                                                                                              request
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Complete "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Pending
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.isLoading &&
                                                                                            _vm.activeIndex ===
                                                                                              index,
                                                                                          loading:
                                                                                            _vm.isLoading &&
                                                                                            _vm.activeIndex ===
                                                                                              index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.assignJob(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assign To Me "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : request.assignedStatus ===
                                                                                    _vm
                                                                                      .iMRequestProcessStatus[
                                                                                      _vm
                                                                                        .iMRequestProcessStatus
                                                                                        .Assigned
                                                                                    ] &&
                                                                                  _vm.isAssignedUserLoggedIn(
                                                                                    request.assignedUserId
                                                                                  )
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCompleteJobDialog(
                                                                                              request
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Complete "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assigned To: " +
                                                                                          _vm._s(
                                                                                            _vm.getAssignedUserName(
                                                                                              request.assignedUserId
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-2" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.getPolicyHolderNotPresentList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.getPolicyHolderNotPresentList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "request-time",
                                                          class:
                                                            request.assignedStatus ===
                                                              _vm
                                                                .iMRequestProcessStatus[
                                                                _vm
                                                                  .iMRequestProcessStatus
                                                                  .Assigned
                                                              ] &&
                                                            !_vm.isAssignedUserLoggedIn(
                                                              request.assignedUserId
                                                            )
                                                              ? "primary--text"
                                                              : "secondary--text",
                                                        },
                                                        [
                                                          request.assignedStatus ===
                                                          _vm
                                                            .iMRequestProcessStatus[
                                                            _vm
                                                              .iMRequestProcessStatus
                                                              .Assigned
                                                          ]
                                                            ? _c(
                                                                "b",
                                                                {
                                                                  staticClass:
                                                                    "timer",
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          request.engineerOnSite
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _vm.showEnginnerOnSiteIcon(
                                                                                request.engineerOnSite
                                                                              )
                                                                                ? _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "request-icon mb-2",
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "engineer-time",
                                                                                          attrs:
                                                                                            {
                                                                                              src: "img/engineer_on_site.svg",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Engineer is waiting on site"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  request
                                                    ? _c(
                                                        "EngineerRequestDetailView",
                                                        {
                                                          attrs: {
                                                            "request-data":
                                                              request,
                                                            "emergency-type-name":
                                                              _vm.getEmergencyTypeName(
                                                                request.forEmergencyTypeId
                                                              ),
                                                            "emergency-detail-name":
                                                              _vm.getEmergencyDetailName(
                                                                request.forEmergencyDetailId
                                                              ),
                                                            "users-list":
                                                              _vm.usersList,
                                                            "is-loading":
                                                              _vm.isLoading,
                                                            index: index,
                                                          },
                                                          on: {
                                                            openCompleteJobDialog:
                                                              _vm.openCompleteJobDialog,
                                                            openAssignJobDialog:
                                                              _vm.openAssignJobDialog,
                                                            assignJob:
                                                              _vm.assignJob,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-3" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.getEngineerRejectVisitList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.getEngineerRejectVisitList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      request.assignedStatus ===
                                                      _vm
                                                        .iMRequestProcessStatus[
                                                        _vm
                                                          .iMRequestProcessStatus
                                                          .Assigned
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "request-time",
                                                              class:
                                                                request.assignedStatus ===
                                                                  _vm
                                                                    .iMRequestProcessStatus[
                                                                    _vm
                                                                      .iMRequestProcessStatus
                                                                      .Assigned
                                                                  ] &&
                                                                !_vm.isAssignedUserLoggedIn(
                                                                  request.assignedUserId
                                                                )
                                                                  ? "primary--text"
                                                                  : "secondary--text",
                                                            },
                                                            [
                                                              _c(
                                                                "b",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("br"),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  request
                                                    ? _c(
                                                        "EngineerRequestDetailView",
                                                        {
                                                          attrs: {
                                                            "request-data":
                                                              request,
                                                            "emergency-type-name":
                                                              _vm.getEmergencyTypeName(
                                                                request.forEmergencyTypeId
                                                              ),
                                                            "emergency-detail-name":
                                                              _vm.getEmergencyDetailName(
                                                                request.forEmergencyDetailId
                                                              ),
                                                            "users-list":
                                                              _vm.usersList,
                                                            "is-loading":
                                                              _vm.isLoading,
                                                            index: index,
                                                          },
                                                          on: {
                                                            openCompleteJobDialog:
                                                              _vm.openCompleteJobDialog,
                                                            openAssignJobDialog:
                                                              _vm.openAssignJobDialog,
                                                            assignJob:
                                                              _vm.assignJob,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-7" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.getRunningLateRequestList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.getRunningLateRequestList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      request.assignedStatus ===
                                                      _vm
                                                        .iMRequestProcessStatus[
                                                        _vm
                                                          .iMRequestProcessStatus
                                                          .Assigned
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "request-time",
                                                              class:
                                                                request.assignedStatus ===
                                                                  _vm
                                                                    .iMRequestProcessStatus[
                                                                    _vm
                                                                      .iMRequestProcessStatus
                                                                      .Assigned
                                                                  ] &&
                                                                !_vm.isAssignedUserLoggedIn(
                                                                  request.assignedUserId
                                                                )
                                                                  ? "primary--text"
                                                                  : "secondary--text",
                                                            },
                                                            [
                                                              _c(
                                                                "b",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Contractor:"
                                                                ),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      request
                                                                        .engineerRequests[0]
                                                                        .contractor
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                              _vm._v("   "),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Reason:"
                                                                ),
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    request
                                                                      .engineerRequests[0]
                                                                      .reasonForLate
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mb-2" },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Engineer Name:"
                                                                ),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      request
                                                                        .engineerRequests[0]
                                                                        .engineer
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                              _vm._v("   "),
                                                              request
                                                                .engineerRequests[0]
                                                                .engineerPhoneNumber
                                                                ? _c(
                                                                    "b",
                                                                    {
                                                                      staticClass:
                                                                        "primary--text phone-number",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " ( "
                                                                      ),
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              href: "Javascript:void(0)",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "primary--text",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "call"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                request
                                                                                  .engineerRequests[0]
                                                                                  .engineerPhoneNumber
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " ) "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Reach on Site At:"
                                                                ),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getFormattedDate(
                                                                        request
                                                                          .engineerRequests[0]
                                                                          .newEtaTo
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "Customer Name:"
                                                                ),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      request
                                                                        .engineerRequests[0]
                                                                        .customer
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs4: "",
                                                                "text-xs-right":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "action-engineer",
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _vm.isUserSupervisor &&
                                                                    request.assignedStatus ===
                                                                      _vm
                                                                        .iMRequestProcessStatus[
                                                                        _vm
                                                                          .iMRequestProcessStatus
                                                                          .Assigned
                                                                      ]
                                                                      ? _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "primary--text",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Assigned To: " +
                                                                                _vm._s(
                                                                                  _vm.getAssignedUserName(
                                                                                    request.assignedUserId
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ]),
                                                                  _vm.isUserSupervisor
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "supervisor-assign",
                                                                        },
                                                                        [
                                                                          request.assignedStatus ===
                                                                          _vm
                                                                            .iMRequestProcessStatus[
                                                                            _vm
                                                                              .iMRequestProcessStatus
                                                                              .Pending
                                                                          ]
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      disabled:
                                                                                        _vm.activeIndex ===
                                                                                        index,
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openAssignJobDialog(
                                                                                          request,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Assign "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          request.assignedStatus ===
                                                                          _vm
                                                                            .iMRequestProcessStatus[
                                                                            _vm
                                                                              .iMRequestProcessStatus
                                                                              .Assigned
                                                                          ]
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      disabled:
                                                                                        _vm.activeIndex ===
                                                                                        index,
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openAssignJobDialog(
                                                                                          request,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Reassign "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          request.assignedStatus ===
                                                                          _vm
                                                                            .iMRequestProcessStatus[
                                                                            _vm
                                                                              .iMRequestProcessStatus
                                                                              .Assigned
                                                                          ]
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openCompleteJobDialog(
                                                                                          request
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Complete "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          request.assignedStatus ===
                                                                          _vm
                                                                            .iMRequestProcessStatus[
                                                                            _vm
                                                                              .iMRequestProcessStatus
                                                                              .Pending
                                                                          ]
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      disabled:
                                                                                        _vm.isLoading &&
                                                                                        _vm.activeIndex ===
                                                                                          index,
                                                                                      loading:
                                                                                        _vm.isLoading &&
                                                                                        _vm.activeIndex ===
                                                                                          index,
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.assignJob(
                                                                                          request,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Assign To Me "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : request.assignedStatus ===
                                                                                _vm
                                                                                  .iMRequestProcessStatus[
                                                                                  _vm
                                                                                    .iMRequestProcessStatus
                                                                                    .Assigned
                                                                                ] &&
                                                                              _vm.isAssignedUserLoggedIn(
                                                                                request.assignedUserId
                                                                              )
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-0",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      small:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openCompleteJobDialog(
                                                                                          request
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Complete "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Assigned To: " +
                                                                                      _vm._s(
                                                                                        _vm.getAssignedUserName(
                                                                                          request.assignedUserId
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-4" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.engineerAbandonedVisitList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.engineerAbandonedVisitList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      request.assignedStatus ===
                                                      _vm
                                                        .iMRequestProcessStatus[
                                                        _vm
                                                          .iMRequestProcessStatus
                                                          .Assigned
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "request-time",
                                                              class:
                                                                request.assignedStatus ===
                                                                  _vm
                                                                    .iMRequestProcessStatus[
                                                                    _vm
                                                                      .iMRequestProcessStatus
                                                                      .Assigned
                                                                  ] &&
                                                                !_vm.isAssignedUserLoggedIn(
                                                                  request.assignedUserId
                                                                )
                                                                  ? "primary--text"
                                                                  : "secondary--text",
                                                            },
                                                            [
                                                              _c(
                                                                "b",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("br"),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  request
                                                    ? _c(
                                                        "EngineerRequestDetailView",
                                                        {
                                                          attrs: {
                                                            "request-data":
                                                              request,
                                                            "emergency-type-name":
                                                              _vm.getEmergencyTypeName(
                                                                request.forEmergencyTypeId
                                                              ),
                                                            "emergency-detail-name":
                                                              _vm.getEmergencyDetailName(
                                                                request.forEmergencyDetailId
                                                              ),
                                                            "users-list":
                                                              _vm.usersList,
                                                            "is-loading":
                                                              _vm.isLoading,
                                                            index: index,
                                                          },
                                                          on: {
                                                            openCompleteJobDialog:
                                                              _vm.openCompleteJobDialog,
                                                            openAssignJobDialog:
                                                              _vm.openAssignJobDialog,
                                                            assignJob:
                                                              _vm.assignJob,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-5" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.getResourceRequestList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.getResourceRequestList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "request-time",
                                                          class:
                                                            request.assignedStatus ===
                                                              _vm
                                                                .iMRequestProcessStatus[
                                                                _vm
                                                                  .iMRequestProcessStatus
                                                                  .Assigned
                                                              ] &&
                                                            !_vm.isAssignedUserLoggedIn(
                                                              request.assignedUserId
                                                            )
                                                              ? "primary--text"
                                                              : "secondary--text",
                                                        },
                                                        [
                                                          request.assignedStatus ===
                                                          _vm
                                                            .iMRequestProcessStatus[
                                                            _vm
                                                              .iMRequestProcessStatus
                                                              .Assigned
                                                          ]
                                                            ? _c(
                                                                "b",
                                                                {
                                                                  staticClass:
                                                                    "timer",
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          request.engineerOnSite
                                                            ? _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _vm.showEnginnerOnSiteIcon(
                                                                                request.engineerOnSite
                                                                              )
                                                                                ? _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      {
                                                                                        staticClass:
                                                                                          "request-icon mb-2",
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          staticClass:
                                                                                            "engineer-time",
                                                                                          attrs:
                                                                                            {
                                                                                              src: "img/engineer_on_site.svg",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Engineer is waiting on site"
                                                                    ),
                                                                  ]),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "inner-request-details",
                                                    },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { wrap: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs5: "",
                                                                "mb-2": "",
                                                              },
                                                            },
                                                            [
                                                              request
                                                                .engineerRequests
                                                                .length > 0
                                                                ? _c(
                                                                    "label",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "assignment"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " Summary Description: "
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "address-wrap",
                                                                },
                                                                _vm._l(
                                                                  request.engineerRequests,
                                                                  function (
                                                                    material,
                                                                    index1
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index1,
                                                                        staticClass:
                                                                          "address",
                                                                      },
                                                                      [
                                                                        material.requestType ===
                                                                        _vm
                                                                          .engineerRequestType[
                                                                          _vm
                                                                            .engineerRequestType
                                                                            .Material
                                                                        ]
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      material.materialName.split(
                                                                                        ","
                                                                                      )
                                                                                        .length
                                                                                    ) +
                                                                                    " x parts "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        material.requestType ===
                                                                        _vm
                                                                          .engineerRequestType[
                                                                          _vm
                                                                            .engineerRequestType
                                                                            .Time
                                                                        ]
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm.checkIfMaterialTypeExist(
                                                                                  request.engineerRequests
                                                                                )
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          ","
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.calculateMinutes(
                                                                                        material.extraMinutes
                                                                                      )
                                                                                    ) +
                                                                                    " hours extra time "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        material.requestType ===
                                                                        _vm
                                                                          .engineerRequestType[
                                                                          _vm
                                                                            .engineerRequestType
                                                                            .ReturnVisit
                                                                        ]
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm.checkIfMaterialOrTimeTypeExist(
                                                                                  request.engineerRequests
                                                                                )
                                                                                  ? _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          ","
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " additional visit "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs4: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Requested At:"
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.getFormattedDate(
                                                                        request.createdAt
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs3: "",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "Status:"
                                                                  ),
                                                                ]),
                                                              ]),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "engineer-requests-statu",
                                                                  class:
                                                                    request.requestStatus ===
                                                                    "Pending"
                                                                      ? "orange--text"
                                                                      : request.requestStatus ===
                                                                        "Approved"
                                                                      ? "green--text"
                                                                      : request.requestStatus ===
                                                                        "Rejected"
                                                                      ? "red--text"
                                                                      : "",
                                                                },
                                                                [
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        request.requestStatus
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._l(
                                                        request.engineerRequests,
                                                        function (
                                                          material,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            { key: index },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: "",
                                                                  },
                                                                },
                                                                [
                                                                  material.materialName
                                                                    ? _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs4: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "img-icon",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: "/img/material_box_p.svg",
                                                                                              alt: "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " Material Name: "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    material.materialName
                                                                                  ) +
                                                                                  " ( "
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                [
                                                                                  _vm._v(
                                                                                    "£ " +
                                                                                      _vm._s(
                                                                                        material.materialCost
                                                                                      )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " ) "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  material.extraMinutes
                                                                    ? _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs4: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "access_time"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " Additional Hours: "
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.calculateMinutes(
                                                                                          material.extraMinutes
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  material.returnVisitType
                                                                    ? _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              xs4: "",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "mb-2",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _c(
                                                                                    "i",
                                                                                    {
                                                                                      staticClass:
                                                                                        "img-icon",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "img",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              src: "/img/user-time-p.svg",
                                                                                              alt: "",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " Return Visit Type: "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        material.returnVisitType
                                                                                      ) +
                                                                                      " (at " +
                                                                                      _vm._s(
                                                                                        _vm.getFormattedDate(
                                                                                          material.returnVisitAt
                                                                                        )
                                                                                      ) +
                                                                                      ") "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mb-2 mt-2",
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs4: "",
                                                                    "text-xs-right":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "action-engineer",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm.isUserSupervisor &&
                                                                          request.assignedStatus ===
                                                                            _vm
                                                                              .iMRequestProcessStatus[
                                                                              _vm
                                                                                .iMRequestProcessStatus
                                                                                .Assigned
                                                                            ]
                                                                            ? _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Assigned To: " +
                                                                                      _vm._s(
                                                                                        _vm.getAssignedUserName(
                                                                                          request.assignedUserId
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      ),
                                                                      _vm.isUserSupervisor
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "supervisor-assign",
                                                                            },
                                                                            [
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Pending
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.activeIndex ===
                                                                                            index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openAssignJobDialog(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assign "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Assigned
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.activeIndex ===
                                                                                            index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openAssignJobDialog(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Reassign "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Assigned
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary btn-complete",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCompleteJobDialog(
                                                                                              request
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Complete "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              request.assignedStatus ===
                                                                              _vm
                                                                                .iMRequestProcessStatus[
                                                                                _vm
                                                                                  .iMRequestProcessStatus
                                                                                  .Pending
                                                                              ]
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          disabled:
                                                                                            _vm.isLoading &&
                                                                                            _vm.activeIndex ===
                                                                                              index,
                                                                                          loading:
                                                                                            _vm.isLoading &&
                                                                                            _vm.activeIndex ===
                                                                                              index,
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.assignJob(
                                                                                              request,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assign To Me "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : request.assignedStatus ===
                                                                                    _vm
                                                                                      .iMRequestProcessStatus[
                                                                                      _vm
                                                                                        .iMRequestProcessStatus
                                                                                        .Assigned
                                                                                    ] &&
                                                                                  _vm.isAssignedUserLoggedIn(
                                                                                    request.assignedUserId
                                                                                  )
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-0",
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "primary",
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openCompleteJobDialog(
                                                                                              request
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Complete "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "primary--text",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        " Assigned To: " +
                                                                                          _vm._s(
                                                                                            _vm.getAssignedUserName(
                                                                                              request.assignedUserId
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", { attrs: { value: "tab-6" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "bar",
                                rawName: "v-bar",
                                value: { useScrollbarPseudo: true },
                                expression: "{ useScrollbarPseudo: true }",
                              },
                            ],
                          },
                          [
                            _c(
                              "v-card",
                              { staticClass: "pr-1 elevation-0 py-0 pl-0" },
                              [
                                _vm.VisitCompleteList.length
                                  ? _c(
                                      "v-list",
                                      { staticClass: "request-list" },
                                      _vm._l(
                                        _vm.VisitCompleteList,
                                        function (request, index) {
                                          return _c(
                                            "v-list-tile",
                                            { key: index },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "request-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "jobnumber mb-2",
                                                    },
                                                    [
                                                      _c("h4", [
                                                        _vm._v(" Job Number: "),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "secondary--text",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.redirectToJob(
                                                                  request
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  request.jobId
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                      request.assignedStatus ===
                                                      _vm
                                                        .iMRequestProcessStatus[
                                                        _vm
                                                          .iMRequestProcessStatus
                                                          .Assigned
                                                      ]
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "request-time",
                                                              class:
                                                                request.assignedStatus ===
                                                                  _vm
                                                                    .iMRequestProcessStatus[
                                                                    _vm
                                                                      .iMRequestProcessStatus
                                                                      .Assigned
                                                                  ] &&
                                                                !_vm.isAssignedUserLoggedIn(
                                                                  request.assignedUserId
                                                                )
                                                                  ? "primary--text"
                                                                  : "secondary--text",
                                                            },
                                                            [
                                                              _c(
                                                                "b",
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "timer"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "countdown-time",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.timerCounter(
                                                                              request
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  request
                                                    ? _c(
                                                        "EngineerRequestDetailView",
                                                        {
                                                          attrs: {
                                                            "request-data":
                                                              request,
                                                            "emergency-type-name":
                                                              _vm.getEmergencyTypeName(
                                                                request.forEmergencyTypeId
                                                              ),
                                                            "emergency-detail-name":
                                                              _vm.getEmergencyDetailName(
                                                                request.forEmergencyDetailId
                                                              ),
                                                            "users-list":
                                                              _vm.usersList,
                                                            "is-loading":
                                                              _vm.isLoading,
                                                            index: index,
                                                          },
                                                          on: {
                                                            openCompleteJobDialog:
                                                              _vm.openCompleteJobDialog,
                                                            openAssignJobDialog:
                                                              _vm.openAssignJobDialog,
                                                            assignJob:
                                                              _vm.assignJob,
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    )
                                  : _c("div", { staticClass: "nodata-text" }, [
                                      _vm._v("No Data Available"),
                                    ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("PartialJobView", {
                    attrs: {
                      "job-id": _vm.selectedJobIdToExpand,
                      "record-id": _vm.engineerRequestId,
                      "request-type": _vm.requestType,
                    },
                    on: { closeJobView: _vm.closeJobView },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.selectedJobIdToComplete
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "650",
                    persistent: "",
                    "content-class": "v-dialog--scrollable",
                  },
                  model: {
                    value: _vm.completeJobDialog,
                    callback: function ($$v) {
                      _vm.completeJobDialog = $$v
                    },
                    expression: "completeJobDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", dark: "", color: "primary" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Complete Job")]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.completeJobDialog = false
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-text",
                        { staticClass: "px-3 scroll-content-dialog" },
                        [
                          _c("v-textarea", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Description",
                              required: "",
                              "data-vv-scope": "completeEngineerRequest",
                              "data-vv-name": "Description",
                              "error-messages":
                                _vm.errors.collect("Description"),
                            },
                            model: {
                              value: _vm.requestCompletionNote,
                              callback: function ($$v) {
                                _vm.requestCompletionNote = $$v
                              },
                              expression: "requestCompletionNote",
                            },
                          }),
                          _vm.errorMessage
                            ? _c(
                                "div",
                                { staticClass: "common-error-message" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "red pa-2 d-block lighten-4",
                                    },
                                    [
                                      _c("span", [
                                        _c("b", [
                                          _vm._v(_vm._s(_vm.errorMessage)),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        { staticClass: "px-3" },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", flat: "flat" },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.completeJobDialog = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-0",
                              attrs: {
                                color: "primary btn-complete-request",
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.completeJob(
                                    _vm.selectedJobIdToComplete
                                  )
                                },
                              },
                            },
                            [_vm._v(" Submit ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.ready,
              expression: "!ready",
            },
          ],
        },
        [
          _c("v-progress-circular", {
            staticClass: "loading-spinner",
            attrs: { width: 2, size: 50, indeterminate: "", color: "primary" },
          }),
        ],
        1
      ),
      _vm.showAssignRequestDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                "content-class": "v-dialog--scrollable",
                persistent: "",
              },
              model: {
                value: _vm.showAssignRequestDialog,
                callback: function ($$v) {
                  _vm.showAssignRequestDialog = $$v
                },
                expression: "showAssignRequestDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Assign Request")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.closeAssignJobDialog.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _vm.assigningMessage
                        ? _c("span", { staticClass: "info--text" }, [
                            _vm._v(" " + _vm._s(_vm.assigningMessage) + " "),
                          ])
                        : _vm._e(),
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.usersList,
                          "item-text": "displayValue",
                          "item-value": "id",
                          label: "Assign to",
                          "return-object": "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.currentAssignedUserId,
                          callback: function ($$v) {
                            _vm.currentAssignedUserId = $$v
                          },
                          expression: "currentAssignedUserId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-auto",
                          attrs: { color: "primary", flat: "" },
                          on: { click: _vm.closeAssignJobDialog },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "m-auto",
                          attrs: {
                            color: "primary",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.assignRequestToUser(
                                _vm.currentAssignedUserId
                              )
                            },
                          },
                        },
                        [_vm._v(" Assign ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }