var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _vm.getlocalTimeSlot
        ? _vm._l(
            Object.keys(_vm.getlocalTimeSlot),
            function (keyItem, keyIndex) {
              return _c("div", { key: keyIndex }, [
                _c(
                  "div",
                  { staticClass: "mb-1" },
                  [
                    _c("label", [_vm._v(_vm._s(keyItem) + ": ")]),
                    _vm._l(
                      _vm.getlocalTimeSlot[keyItem],
                      function (ValueItem, valueIndex) {
                        return _c("span", { key: valueIndex }, [
                          _vm._v(
                            " " +
                              _vm._s(ValueItem) +
                              _vm._s(
                                valueIndex ===
                                  _vm.getlocalTimeSlot[keyItem].length - 1
                                  ? ""
                                  : ","
                              ) +
                              " "
                          ),
                        ])
                      }
                    ),
                  ],
                  2
                ),
              ])
            }
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Customer Availability TimeSlot")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }