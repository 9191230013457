var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2" },
        [
          _c("v-card-title", { staticClass: "px-0 py-2" }, [
            _c("h3", { staticClass: "heading" }, [
              _vm._v(" " + _vm._s(_vm.item.title) + " "),
              _vm.item.noteType
                ? _c("span", { staticClass: "teal--text" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getNoteTypeDescription(_vm.item.noteType)) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0 mt-2" },
            [
              _c("pre", [_vm._v(_vm._s(_vm.item.description))]),
              _c(
                "v-layout",
                [
                  _vm.item &&
                  _vm.canPlayCall &&
                  (_vm.item.callRecordingUrl || _vm.item.callSid)
                    ? _c(
                        "v-flex",
                        { staticClass: "px-0 mt-1", attrs: { xs12: "" } },
                        [
                          _vm.item.description ? _c("v-divider") : _vm._e(),
                          _c("LinkedPhoneCallDetails", {
                            attrs: {
                              "recording-url": _vm.item.callRecordingUrl,
                              "call-sid": _vm.item.callSid,
                              "job-id": _vm.item.jobId,
                              "show-header": _vm.showHeader,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }