var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { staticClass: "wrap" },
            [
              _c(
                "v-flex",
                { attrs: { xs3: "", "py-0": "" } },
                [
                  _c("v-combobox", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { rules: { required: !_vm.isSIJob } },
                        expression: "{ rules: { required: !isSIJob } }",
                      },
                    ],
                    staticClass: "client-title",
                    class: !_vm.isSIJob ? "required" : "",
                    attrs: {
                      label: "Title",
                      maxlength: "20",
                      "return-object": "",
                      items: _vm.clientTitleList,
                      required: !_vm.isSIJob,
                      "data-vv-name": "Title",
                      name: "Title",
                      "error-messages": _vm.errors.collect("Title"),
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedClientTitle,
                          "clientTitle"
                        ) || _vm.disabled,
                      "search-input": _vm.clientTitle,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.clientTitle = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.clientTitle = $event
                      },
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.clientTitle,
                      callback: function ($$v) {
                        _vm.clientTitle = $$v
                      },
                      expression: "clientTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs5: "", "py-0": "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "forenameField",
                    staticClass: "required",
                    attrs: {
                      label: "First Name",
                      maxlength: "50",
                      required: "",
                      "data-vv-name": "First Name",
                      name: "FirstName",
                      "error-messages": _vm.errors.collect("First Name"),
                      disabled:
                        _vm.isFieldEditable(_vm.firstName, "clientForename") ||
                        _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.firstName,
                      callback: function ($$v) {
                        _vm.firstName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "firstName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "", "py-0": "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Last Name",
                      maxlength: "50",
                      required: "",
                      "data-vv-name": "Last Name",
                      name: "LastName",
                      "error-messages": _vm.errors.collect("Last Name"),
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedLastName,
                          "clientLastName"
                        ) || _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.lastName,
                      callback: function ($$v) {
                        _vm.lastName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "lastName",
                    },
                  }),
                ],
                1
              ),
              _vm.showSecondaryName
                ? [
                    _c(
                      "v-flex",
                      { attrs: { xs3: "", "py-0": "" } },
                      [
                        _c("v-combobox", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Secondary Title",
                            maxlength: "20",
                            "return-object": "",
                            items: _vm.clientTitleList,
                            name: "SecondaryTitle",
                            required: "",
                            "data-vv-name": "Secondary Title",
                            "error-messages":
                              _vm.errors.collect("Secondary Title"),
                            disabled:
                              _vm.isFieldEditable(
                                _vm.passedSecondaryClientTitle,
                                "secondaryClientTitle"
                              ) || _vm.disabled,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.addressUpdated()
                            },
                          },
                          model: {
                            value: _vm.secondaryClientTitle,
                            callback: function ($$v) {
                              _vm.secondaryClientTitle =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "secondaryClientTitle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs5: "", "py-0": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "forenameField",
                          staticClass: "required",
                          attrs: {
                            label: "Secondary First Name",
                            maxlength: "50",
                            name: "SecondaryFirstName",
                            "data-vv-name": "Secondary First Name",
                            "error-messages": _vm.errors.collect(
                              "Secondary First Name"
                            ),
                            disabled:
                              _vm.isFieldEditable(
                                _vm.passedSecondaryFirstName,
                                "secondaryClientForename"
                              ) || _vm.disabled,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.addressUpdated()
                            },
                          },
                          model: {
                            value: _vm.secondaryFirstName,
                            callback: function ($$v) {
                              _vm.secondaryFirstName =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "secondaryFirstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs4: "", "py-0": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Secondary Last Name",
                            maxlength: "50",
                            name: "SecondaryLastName",
                            "data-vv-name": "Secondary Last Name",
                            "error-messages": _vm.errors.collect(
                              "Secondary Last Name"
                            ),
                            disabled:
                              _vm.isFieldEditable(
                                _vm.passedSecondaryLastName,
                                "secondaryClientLastName"
                              ) || _vm.disabled,
                          },
                          on: {
                            blur: function ($event) {
                              return _vm.addressUpdated()
                            },
                          },
                          model: {
                            value: _vm.secondaryLastName,
                            callback: function ($$v) {
                              _vm.secondaryLastName =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "secondaryLastName",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isDisplayPolicyNumber
                    ? _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: { required: _vm.isPolicyNumberRequired },
                            },
                            expression:
                              "{ rules: { required: isPolicyNumberRequired } }",
                          },
                        ],
                        ref: "policyNumberField",
                        staticClass: "displayPolicyText",
                        class: _vm.isPolicyNumberRequired ? "required" : "",
                        attrs: {
                          label: "Policy Number",
                          maxlength: "50",
                          disabled: _vm.disabledPolicyNumberField,
                          required: _vm.isPolicyNumberRequired,
                          "data-vv-name": "Policy Number",
                          "error-messages": _vm.errors.collect("Policy Number"),
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.addressUpdated()
                          },
                        },
                        model: {
                          value: _vm.policyNumber,
                          callback: function ($$v) {
                            _vm.policyNumber =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "policyNumber",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm.isDisplayPolicyNumber &&
              _vm.isHEJob &&
              _vm.isAssumedCoverPolicy &&
              !_vm.policyNumber
                ? _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required policy-not-present-reason",
                        attrs: {
                          label: "Policy Number Not Present Reason",
                          required: "",
                          "data-vv-name": "Reason",
                          "error-messages": _vm.errors.collect("Reason"),
                          rows: "3",
                        },
                        on: {
                          blur: function ($event) {
                            return _vm.addressUpdated()
                          },
                        },
                        model: {
                          value: _vm.policyNumberNotPresentReason,
                          callback: function ($$v) {
                            _vm.policyNumberNotPresentReason =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "policyNumberNotPresentReason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isOnline
                ? _c(
                    "v-flex",
                    {
                      staticClass: "change-address-btn",
                      attrs: { xs12: "", "text-xs-right": "", "pa-0": "" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-0 pa-0",
                          attrs: {
                            flat: "",
                            color: "primary",
                            disabled:
                              _vm.isFieldEditable(
                                _vm.passedAddressLine1,
                                "addressLine1"
                              ) || _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              _vm.activeAddressSearch =
                                _vm.activeAddressSearch == true ? false : true
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              class: _vm.activeAddressSearch ? "hide" : " show",
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("place"),
                              ]),
                              _c("span", { staticClass: "v-align" }, [
                                _vm._v("Pick Address"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              class: _vm.activeAddressSearch ? "show" : " hide",
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v("close"),
                              ]),
                              _c("span", { staticClass: "v-align" }, [
                                _vm._v("Close"),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-flex",
                {
                  class: _vm.activeAddressSearch ? "show" : " hide",
                  attrs: { xs12: "", "maping-alert": "" },
                },
                [
                  _c("v-autocomplete", {
                    ref: "searchAddressField",
                    attrs: {
                      tabindex: "0",
                      label: "Search address",
                      loading: _vm.searchAddressRunning,
                      items: _vm.findAddress,
                      "browser-autocomplete": "off",
                      "search-input": _vm.searchAddress,
                      "item-text": "Description",
                      "item-value": "item",
                      autofocus: "",
                      "return-object": "",
                      filter: _vm.customAddressFilter,
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.searchAddress = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.searchAddress = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-list-tile-content",
                              [
                                _c("v-list-tile-title", [
                                  _vm._v(
                                    " " + _vm._s(data.item.Description) + " "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.selectedAddressSearch,
                      callback: function ($$v) {
                        _vm.selectedAddressSearch = $$v
                      },
                      expression: "selectedAddressSearch",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Address Line1",
                      maxlength: "100",
                      required: "",
                      name: "AddressLine1",
                      "data-vv-name": "Address Line1",
                      "error-messages": _vm.errors.collect("Address Line1"),
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedAddressLine1,
                          "addressLine1"
                        ) || _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.addressLine1,
                      callback: function ($$v) {
                        _vm.addressLine1 =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addressLine1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Address Line2",
                      maxlength: "100",
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedAddressLine2,
                          "addressLine2"
                        ) || _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.addressLine2,
                      callback: function ($$v) {
                        _vm.addressLine2 =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addressLine2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Address Line3",
                      maxlength: "100",
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedAddressLine3,
                          "addressLine3"
                        ) || _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.addressLine3,
                      callback: function ($$v) {
                        _vm.addressLine3 =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addressLine3",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Address Line4",
                      maxlength: "100",
                      disabled:
                        _vm.isFieldEditable(
                          _vm.passedAddressLine4,
                          "addressLine4"
                        ) || _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.addressLine4,
                      callback: function ($$v) {
                        _vm.addressLine4 =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "addressLine4",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Postcode",
                      required: "",
                      maxlength: "20",
                      "data-vv-name": "Postcode",
                      name: "Postcode",
                      "error-messages": _vm.errors.collect("Postcode"),
                      disabled:
                        _vm.isFieldEditable(_vm.passedPostCode, "postCode") ||
                        _vm.disabled,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.addressUpdated()
                      },
                    },
                    model: {
                      value: _vm.postCode,
                      callback: function ($$v) {
                        _vm.postCode =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "postCode",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }