var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-flex", { attrs: { md4: "", lg2: "", "mb-3": "" } }, [
    _c(
      "div",
      { staticClass: "team-divider" },
      [
        _c(
          "v-card",
          { staticClass: "grey lighten-3 elevation-0" },
          [
            _c("v-card-title", { staticClass: "pa-2 py-3" }, [
              _c("h3", { staticClass: "team-title text-xs-center pb-0 mb-0" }, [
                _vm._v(_vm._s(_vm.team.title)),
              ]),
              _c(
                "span",
                {
                  staticClass: "teambadge text-xs-center",
                  attrs: { "data-v-43b78cc0": "" },
                },
                [_vm._v(" " + _vm._s(_vm.getTotalOnlineWorker) + " ")]
              ),
            ]),
            _c("v-divider"),
            _c(
              "v-card-text",
              {
                directives: [
                  {
                    name: "bar",
                    rawName: "v-bar",
                    value: { useScrollbarPseudo: true },
                    expression: "{ useScrollbarPseudo: true }",
                  },
                ],
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "v-container",
                      { staticClass: "pa-0 mt-3" },
                      _vm._l(_vm.workersSplit, function (group, i) {
                        return _c(
                          "div",
                          { key: i },
                          [
                            group.length === 3
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "three-item text-xs-center",
                                    attrs: { row: "" },
                                  },
                                  _vm._l(group, function (worker) {
                                    return _c(
                                      "v-flex",
                                      { key: worker.sid, attrs: { xs4: "" } },
                                      [
                                        _c("dashboard-worker", {
                                          attrs: {
                                            worker: worker,
                                            "show-all-worker":
                                              _vm.showAllWorker,
                                          },
                                          on: {
                                            showOfflineWorkers:
                                              _vm.showOfflineWorkers,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : group.length === 2
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "two-item text-xs-center",
                                    attrs: { row: "" },
                                  },
                                  _vm._l(group, function (worker) {
                                    return _c(
                                      "v-flex",
                                      { key: worker.sid, attrs: { xs6: "" } },
                                      [
                                        _c("dashboard-worker", {
                                          attrs: {
                                            worker: worker,
                                            "show-all-worker":
                                              _vm.showAllWorker,
                                          },
                                          on: {
                                            showOfflineWorkers:
                                              _vm.showOfflineWorkers,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              : _c(
                                  "v-layout",
                                  { staticClass: "text-xs-center" },
                                  _vm._l(group, function (worker) {
                                    return _c(
                                      "v-flex",
                                      { key: worker.sid, attrs: { xs12: "" } },
                                      [
                                        _c("dashboard-worker", {
                                          attrs: {
                                            worker: worker,
                                            "show-all-worker":
                                              _vm.showAllWorker,
                                          },
                                          on: {
                                            showOfflineWorkers:
                                              _vm.showOfflineWorkers,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }