var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.emergencyCoverage
    ? _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "pr-2", attrs: { xs6: "" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.monthList,
                  "hide-details": true,
                  label: "Start month",
                  "item-text": "name",
                  "item-value": "id",
                },
                model: {
                  value: _vm.emergencyCoverage.startMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.emergencyCoverage, "startMonth", $$v)
                  },
                  expression: "emergencyCoverage.startMonth",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "pl-2", attrs: { xs6: "" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.monthList,
                  "hide-details": true,
                  label: "End month",
                  "item-text": "name",
                  "item-value": "id",
                },
                model: {
                  value: _vm.emergencyCoverage.endMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.emergencyCoverage, "endMonth", $$v)
                  },
                  expression: "emergencyCoverage.endMonth",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Coverage details",
                  rows: "2",
                  "hide-details": true,
                  "no-resize": true,
                  maxlength: "2000",
                },
                model: {
                  value: _vm.emergencyCoverage.coverageDetails,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emergencyCoverage,
                      "coverageDetails",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "emergencyCoverage.coverageDetails",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Exclusions",
                  rows: "2",
                  "hide-details": true,
                  "no-resize": true,
                  maxlength: "2000",
                },
                model: {
                  value: _vm.emergencyCoverage.exclusions,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.emergencyCoverage,
                      "exclusions",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "emergencyCoverage.exclusions",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-checkbox", {
                attrs: {
                  "hide-details": "",
                  color: "primary",
                  label: "Tag as CTP",
                },
                model: {
                  value: _vm.emergencyCoverage.isMarkedAsCTP,
                  callback: function ($$v) {
                    _vm.$set(_vm.emergencyCoverage, "isMarkedAsCTP", $$v)
                  },
                  expression: "emergencyCoverage.isMarkedAsCTP",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }