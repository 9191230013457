var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "counter" }, [
    _vm._v(" " + _vm._s(_vm.counterLabel) + " "),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }