var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [_vm._v("Set ETA")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.closeDialog } },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-text",
        { staticClass: "px-4 pt-4 eta-detail-dialog-content" },
        [
          _c(
            "v-layout",
            { staticClass: "scoll-vb-content" },
            [
              _vm.isLoading
                ? _c("v-progress-circular", {
                    style: _vm.getLoaderStyle(50),
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  })
                : _c(
                    "v-layout",
                    { attrs: { xs12: "", wrap: "" } },
                    [
                      _vm.isMaterialPriceNotIncluded
                        ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                            _c("div", { staticClass: "common-error-message" }, [
                              _c(
                                "div",
                                { staticClass: "red pa-2 d-block lighten-4" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "error-materialPrice" },
                                    [
                                      _c("b", [
                                        _vm._v(
                                          "Cannot set ETA, follow-on visit includes a parts request with no price detail."
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "pr-2": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "ETAfromdateTimePicker",
                            attrs: {
                              "date-time": _vm.contractorAppointed.etaFrom,
                              "is-static-location": false,
                              "place-holder-text": "ETA From",
                              "min-date": _vm.minFromDate,
                              "min-time": _vm.minFromTime,
                              "max-date": _vm.maxFromDate,
                              "max-time": _vm.maxFromTime,
                              "is-validation-required": true,
                              "allowed-minutes-step": true,
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.contractorAppointed,
                                  "etaFrom",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.contractorAppointed,
                                  "etaFrom",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs6: "", "pl-2": "" } },
                        [
                          _c("DateTimePicker", {
                            ref: "ETATodateTimePicker",
                            attrs: {
                              "date-time": _vm.contractorAppointed.etaTo,
                              "is-static-location": false,
                              "place-holder-text": "ETA To",
                              "min-date": _vm.minToDate,
                              "min-time": _vm.minToTime,
                              "max-date": _vm.maxToDate,
                              "max-time": _vm.maxToTime,
                              "is-validation-required": true,
                              "allowed-minutes-step": true,
                            },
                            on: {
                              "update:dateTime": function ($event) {
                                return _vm.$set(
                                  _vm.contractorAppointed,
                                  "etaTo",
                                  $event
                                )
                              },
                              "update:date-time": function ($event) {
                                return _vm.$set(
                                  _vm.contractorAppointed,
                                  "etaTo",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.toDateIsAfterFromDateError || _vm.datesHavePassedError
                        ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
                            _vm.toDateIsAfterFromDateError
                              ? _c("span", { staticClass: "error--text" }, [
                                  _vm._v(
                                    "To date must be greater than From date."
                                  ),
                                ])
                              : _vm._e(),
                            _vm.datesHavePassedError
                              ? _c("span", { staticClass: "error--text" }, [
                                  _vm._v("ETA dates have already passed."),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c(
                        "v-flex",
                        { staticClass: "pr-1", attrs: { xs6: "" } },
                        [
                          _c("v-select", {
                            staticClass: "travelTimeHour",
                            attrs: {
                              items: _vm.travelTimeHourList,
                              label: "Travel Time (Hours)",
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.travelTimeHour,
                              callback: function ($$v) {
                                _vm.travelTimeHour = $$v
                              },
                              expression: "travelTimeHour",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "pl-1", attrs: { xs6: "" } },
                        [
                          _c("v-select", {
                            staticClass: "travelTimeMinute",
                            attrs: {
                              items: _vm.travelTimeMinuteList,
                              label: "Travel Time (Minutes)",
                              "item-text": "text",
                              "item-value": "value",
                            },
                            model: {
                              value: _vm.travelTimeMinute,
                              callback: function ($$v) {
                                _vm.travelTimeMinute = $$v
                              },
                              expression: "travelTimeMinute",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", grey: "", "lighten-3": "" } },
                        [
                          _c("DelayCodes", {
                            attrs: {
                              "has-delay": _vm.contractorAppointed.hasDelay,
                              "delay-code": _vm.contractorAppointed.delayCode,
                            },
                            on: { updateDelayCode: _vm.onDelayCodeChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "px-4" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "primary", flat: "flat" },
              on: { click: _vm.closeDialog },
            },
            [_vm._v("Close")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary",
                disabled:
                  _vm.isSubmitETALoading ||
                  !_vm.validate ||
                  _vm.isMaterialPriceNotIncluded,
                loading: _vm.isSubmitETALoading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }