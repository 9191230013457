var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "pa-2" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.headerText,
                  expression: "headerText",
                },
              ],
              staticClass: "cnt-time-head",
              class:
                _vm.headerText || _vm.dateSelection
                  ? "xs6 select-date"
                  : "xs12",
            },
            [
              _c(
                "v-icon",
                { staticClass: "mt-2 mr-2", attrs: { color: "secondary" } },
                [_vm._v("date_range")]
              ),
              _c(
                "b",
                { staticClass: "chosen-date" },
                [
                  _vm._v(
                    " " + _vm._s(_vm.dateSelection ? "" : _vm.headerText) + " "
                  ),
                  _vm.dateSelection
                    ? _c(
                        "v-menu",
                        {
                          ref: "datePickerMenu",
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            lazy: "",
                            transition: "scale-transition",
                            "offset-y": "",
                            "full-width": "",
                            "min-width": "290px",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        {
                                          attrs: {
                                            label: "Select Date",
                                            readonly: "",
                                            "hide-details": "",
                                          },
                                          model: {
                                            value: _vm.chosenFormattedDate,
                                            callback: function ($$v) {
                                              _vm.chosenFormattedDate = $$v
                                            },
                                            expression: "chosenFormattedDate",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1068222834
                          ),
                          model: {
                            value: _vm.showDatePickerSelection,
                            callback: function ($$v) {
                              _vm.showDatePickerSelection = $$v
                            },
                            expression: "showDatePickerSelection",
                          },
                        },
                        [
                          _c(
                            "v-date-picker",
                            {
                              staticClass: "date-picker",
                              attrs: {
                                "no-title": "",
                                scrollable: "",
                                min: _vm.setMinDate,
                              },
                              model: {
                                value: _vm.chosenDate,
                                callback: function ($$v) {
                                  _vm.chosenDate = $$v
                                },
                                expression: "chosenDate",
                              },
                            },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { flat: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.showDatePickerSelection = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "select-date-btn",
                                  attrs: { flat: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onSelectDatePicker(
                                        _vm.chosenDate
                                      )
                                    },
                                  },
                                },
                                [_vm._v("OK")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          (_vm.dateSelection ? _vm.checkIsOldDate() : true)
            ? _c(
                "v-flex",
                { class: _vm.headerText ? "xs6" : "xs12" },
                [
                  _c("v-select", {
                    ref: "multipleSelectionTimeSlots",
                    staticClass: "hide-option select-slots",
                    attrs: {
                      id: _vm.headerText,
                      items: _vm.todayTimeSlot,
                      disabled: _vm.disableTimeSlots,
                      multiple: "",
                      "return-object": "",
                      "item-text": "slot",
                      "item-value": "slotId",
                      "persistent-hint": "",
                      label: "Select Time Slot(s)",
                      "hide-details": "",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onSlotChange(_vm.selectedSlots, $event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function (selection) {
                            return undefined
                          },
                        },
                        {
                          key: "item",
                          fn: function (data) {
                            return [
                              _c("div", { staticClass: "custom-slot" }, [
                                _c(
                                  "div",
                                  { staticClass: "custom-slot__checkbox" },
                                  [
                                    _c("v-checkbox", {
                                      attrs: {
                                        "input-value": _vm.isSlotSelected(
                                          data.item.slot
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "custom-slot__text" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          data.item.slot === "24hrs"
                                            ? _vm.retrieveCustomSlotLabel(
                                                data.item.slot
                                              )
                                            : data.item.slot
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2260352599
                    ),
                    model: {
                      value: _vm.selectedSlots,
                      callback: function ($$v) {
                        _vm.selectedSlots = $$v
                      },
                      expression: "selectedSlots",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { staticClass: "mt-2 mb-2", attrs: { xs12: "" } },
            [
              _vm._l(_vm.selectedSlots, function (item) {
                return [
                  item.slotId > 0
                    ? _c(
                        "v-chip",
                        {
                          key: JSON.stringify(item.slotId),
                          staticClass: "white--text selected-slots",
                          attrs: {
                            flat: "",
                            outline: "",
                            disabled: _vm.disableTimeSlots,
                            color: "primary",
                            dark: "",
                            small: "",
                            close: _vm.dateSelection
                              ? _vm.checkIsOldDate()
                              : true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.removeSelectedSlot(
                                item,
                                _vm.selectedSlots,
                                _vm.todayTimeSlot
                              )
                            },
                          },
                        },
                        [_c("b", [_vm._v(_vm._s(item.slot))])]
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }