var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.jobId
    ? _c(
        "v-card",
        {
          staticClass: "pr-1 elevation-0 pt-3 pl-2 pb-4 jobview",
          class: _vm.jobId ? "jobview-show" : "",
        },
        [
          _c(
            "span",
            { staticClass: "jobview-hide d-inline-block" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "elevation-1 secondary white--text",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeJobView")
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { large: "", color: "white" } }, [
                    _vm._v("keyboard_arrow_down"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.btnText) + " "),
                ],
                1
              ),
            ],
            1
          ),
          _c("JobView", {
            attrs: {
              "job-id": _vm.jobId,
              "record-id": _vm.recordId,
              "request-type": _vm.requestType,
              "record-type": _vm.recordType,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }