var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.jobStatus
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Status: ")]),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.item.jobStatus === "Cancelled" ? "Cancelled" : "Completed"
                ) +
                " "
            ),
          ]),
        ])
      : _vm._e(),
    _vm.item.totalPaidAmount
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Total Paid Amount: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormatedCurrency(_vm.item.totalPaidAmount))),
          ]),
        ])
      : _vm._e(),
    _vm.item.processAtUtc
      ? _c("div", { staticClass: "mb-1" }, [
          _c("label", [_vm._v("Process At: ")]),
          _c("span", [
            _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.processAtUtc))),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Job Complete")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }