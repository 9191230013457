var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { card: "", dark: "", color: "primary" } },
        [
          _c("v-toolbar-title", [
            _vm._v(_vm._s(_vm.jobTypeLabel("emergency"))),
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.closeEmergencyDialog.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "px-3 scroll-content-dialog" },
        [
          _c(
            "v-form",
            { attrs: { autocomplete: "off" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:50",
                            expression: "'required|max:50'",
                          },
                        ],
                        attrs: {
                          label: _vm.jobTypeLabel("emergency"),
                          maxlength: "50",
                          "data-vv-scope": "frmEmergency",
                          "data-vv-name": _vm.jobTypeLabel("emergency"),
                          "error-messages": _vm.errors.collect(
                            _vm.jobTypeLabel("emergency")
                          ),
                        },
                        model: {
                          value: _vm.description,
                          callback: function ($$v) {
                            _vm.description =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          label: "Engineer Site SLA",
                          items: _vm.engineerSiteSLAItems,
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.engineerSiteSLA,
                          callback: function ($$v) {
                            _vm.engineerSiteSLA = $$v
                          },
                          expression: "engineerSiteSLA",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.jobType === "HE"
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "", "mb-2": "" } },
                        [
                          _c("v-switch", {
                            staticClass: "mb-1 hide-on-portal",
                            attrs: {
                              label: "Exclude From Customer Portal",
                              color: "primary",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.hideOnCustomerPortals,
                              callback: function ($$v) {
                                _vm.hideOnCustomerPortals = $$v
                              },
                              expression: "hideOnCustomerPortals",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "pl-0 text-xs-center icon-validation",
                      attrs: { xs12: "" },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Logo",
                          placeholder: "Select logo",
                          "prepend-icon": "attach_file",
                        },
                        on: { click: _vm.pickFile },
                        model: {
                          value: _vm.imageName,
                          callback: function ($$v) {
                            _vm.imageName = $$v
                          },
                          expression: "imageName",
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: false,
                                image: true,
                                size: _vm.LogoSize,
                                mimes: "image/*",
                              },
                            },
                            expression:
                              "{\n              rules: {\n                required: false,\n                image: true,\n                size: LogoSize,\n                mimes: 'image/*',\n              },\n            }",
                          },
                        ],
                        ref: "image",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          name: "logo",
                          "data-vv-scope": "frmEmergency",
                        },
                        on: { change: _vm.onFilePicked },
                      }),
                      _c("div", { staticClass: "input-group__details" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-group__messages input-group__error input-group--error error--text",
                          },
                          [
                            _vm.imageValidationMessage(_vm.errors)
                              ? _c("span", [
                                  _vm._v(" " + _vm._s(_vm.logoErrorMsg) + " "),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { staticClass: "pl-3 ml-3", attrs: { xs12: "" } },
                    [
                      _vm.imageUrl
                        ? _c(
                            "div",
                            { staticClass: "show-up-logo" },
                            [
                              _c("img", { attrs: { src: _vm.imageUrl } }),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "lighten-3",
                                  attrs: { icon: "", color: "grey", small: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeImage.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        small: "",
                                        dark: "",
                                        color: "primary",
                                      },
                                    },
                                    [_vm._v("delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { flat: "flat" },
              on: { click: _vm.closeEmergencyDialog },
            },
            [_vm._v("Cancel")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary",
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
              },
              on: { click: _vm.saveEmergency },
            },
            [_vm._v(" Submit ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }