var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pr-2": "", "pl-0": "", "pt-1": "" } },
            [
              _c(
                "div",
                {
                  staticClass: "text-right insurer-search job-type-dropdown",
                  attrs: { wrap: "" },
                },
                [
                  _c("v-select", {
                    staticClass: "pr-3",
                    attrs: {
                      label: "Job Type",
                      required: "",
                      "item-text": "jobTypeDescription",
                      "item-value": "jobType",
                      items: _vm.jobTypes,
                    },
                    model: {
                      value: _vm.selectedJobType,
                      callback: function ($$v) {
                        _vm.selectedJobType = $$v
                      },
                      expression: "selectedJobType",
                    },
                  }),
                  _c("ClientList"),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-flex",
            { staticClass: "mt-5" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pr-2": "", "pl-0": "", "pt-1": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _vm.selectedInsurerId
                            ? _c("JobFinancialSummaryDashboardNew", {
                                attrs: {
                                  "selected-job-type": _vm.selectedJobType,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }