var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "clientrate elevation-0" },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-1" },
                [
                  !_vm.showRateTemplateInDialog
                    ? _c("v-card-title", [_c("h3", [_vm._v("Client Rate")])])
                    : _vm._e(),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-container",
                        {
                          attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "align-center",
                              attrs: { wrap: "" },
                            },
                            [
                              _c("v-flex", { attrs: { xs7: "" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "newindicator-chip search-field",
                                  },
                                  [
                                    !_vm.isAccessToCreateRatePackage
                                      ? _c("v-autocomplete", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "selectedRatePackage",
                                          attrs: {
                                            label: "Search package",
                                            "return-object": "",
                                            items: _vm.getClientRatePackageList,
                                            "item-text": "packageName",
                                            "item-value": "id",
                                            "data-vv-scope": "frmClientRate",
                                            "data-vv-name": "Search package",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Search package"
                                              ),
                                            disabled:
                                              _vm.fromJobCompletionScreen,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onClientRatePackageChange(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedRatePackage,
                                            callback: function ($$v) {
                                              _vm.selectedRatePackage = $$v
                                            },
                                            expression: "selectedRatePackage",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm.isAccessToCreateRatePackage
                                      ? _c("v-combobox", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          attrs: {
                                            label: "Search package",
                                            "return-object": "",
                                            items: _vm.getClientRatePackageList,
                                            "item-text": "packageName",
                                            "item-value": "id",
                                            "data-vv-scope": "frmClientRate",
                                            "data-vv-name": "Search package",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Search package"
                                              ),
                                            "data-vv-delay":
                                              _vm.validationDelay,
                                            disabled:
                                              _vm.fromJobCompletionScreen,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onClientRatePackageChange(
                                                $event
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.selectedRatePackage,
                                            callback: function ($$v) {
                                              _vm.selectedRatePackage = $$v
                                            },
                                            expression: "selectedRatePackage",
                                          },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          disabled: "",
                                          value: _vm.isNewRatePackage,
                                        },
                                      },
                                      [_vm._v("New")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "fee-switch py-0",
                                  attrs: { xs5: "" },
                                },
                                [
                                  _c("v-switch", {
                                    staticClass: "pt-0 mt-0",
                                    attrs: {
                                      name: "fixedFeePolicy",
                                      color: "primary",
                                      label: "Fixed Fee Policy",
                                      readonly: _vm.isUserRoleClientManager,
                                    },
                                    on: {
                                      change: _vm.onFixedFeePolicyValueChange,
                                    },
                                    model: {
                                      value:
                                        _vm.clientRatePackageModel
                                          .isFixedFeePolicy,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clientRatePackageModel,
                                          "isFixedFeePolicy",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "clientRatePackageModel.isFixedFeePolicy",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "mt-0": "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: {
                                        fluid: "",
                                        "grid-list-lg": "",
                                        "pa-0": "",
                                        "pb-4": "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs12: "",
                                                lg7: "",
                                                "pb-0": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "elevation-1 height-100 fees-card",
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "py-0" },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "align-center",
                                                          attrs: { wrap: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "my-3": "",
                                                              },
                                                            },
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Handling Fees"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-container",
                                                        {
                                                          attrs: {
                                                            fluid: "",
                                                            "grid-list-xl": "",
                                                            "pa-0": "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Claim Handling",
                                                                              maxlength:
                                                                                "9",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "ClaimHandling",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .claimHandling,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "claimHandling",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.claimHandling",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isClaimHandlingVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isClaimHandlingVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isClaimHandlingVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isClaimHandlingVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Repudiated Fee (Post Deployment)",
                                                                              maxlength:
                                                                                "9",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "postDeploymentRepudiatedFee",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .postDeploymentRepudiatedFee,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "postDeploymentRepudiatedFee",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.postDeploymentRepudiatedFee",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isPostDeploymentRepudiatedFeeVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isPostDeploymentRepudiatedFeeVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isPostDeploymentRepudiatedFeeVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isPostDeploymentRepudiatedFeeVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Cancelled Fee (Pre Deployment)",
                                                                              maxlength:
                                                                                "9",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "preDeploymentCancelledFee",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .preDeploymentCancelledFee,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "preDeploymentCancelledFee",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.preDeploymentCancelledFee",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isPreDeploymentCancelledFeeVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isPreDeploymentCancelledFeeVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isPreDeploymentCancelledFeeVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isPreDeploymentCancelledFeeVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Aborted Visit",
                                                                              maxlength:
                                                                                "5",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "abortedVisit",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .abortedVisit,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "abortedVisit",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.abortedVisit",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isAbortedVisitVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isAbortedVisitVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isAbortedVisitVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isAbortedVisitVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Cancelled Fee (Post Deployment)",
                                                                              maxlength:
                                                                                "5",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "postDeploymentCancelledFee",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .postDeploymentCancelledFee,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "postDeploymentCancelledFee",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.postDeploymentCancelledFee",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isPostDeploymentCancelledFeeVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isPostDeploymentCancelledFeeVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isPostDeploymentCancelledFeeVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isPostDeploymentCancelledFeeVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Soft Fix",
                                                                              maxlength:
                                                                                "5",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "softFix",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .softFix,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "softFix",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.softFix",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isSoftFixVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isSoftFixVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isSoftFixVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isSoftFixVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs6: "",
                                                                    "py-0": "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "fixed-price",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                "Repudiated Fee (Pre Deployment)",
                                                                              maxlength:
                                                                                "5",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "preDeploymentRepudiatedFee",
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .preDeploymentRepudiatedFee,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "preDeploymentRepudiatedFee",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.preDeploymentRepudiatedFee",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              name: "isPreDeploymentRepudiatedFeeVATable",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .isPreDeploymentRepudiatedFeeVATable,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.clientRatePackageModel,
                                                                                    "isPreDeploymentRepudiatedFeeVATable",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.isPreDeploymentRepudiatedFeeVATable",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "right-fee-col",
                                              attrs: {
                                                lg5: "",
                                                xs12: "",
                                                "pb-0": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "elevation-1 height-100 fees-card",
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "py-0" },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        {
                                                          staticClass:
                                                            "align-center fixed-fee",
                                                          attrs: { wrap: "" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                                "my-3": "",
                                                              },
                                                            },
                                                            [
                                                              _c("h3", [
                                                                _vm._v(
                                                                  "Other Fees"
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        { attrs: { wrap: "" } },
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "align-center",
                                                                  attrs: {
                                                                    wrap: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "BER included in Fixed Fee?"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: "berIncluded",
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              disabled:
                                                                                !_vm.getIsAnyFixedFeeTradeSelected,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .berIncluded,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .clientRatePackageModel
                                                                                      .clientFixedFeeRates,
                                                                                    "berIncluded",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.clientFixedFeeRates.berIncluded",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider"),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "align-center",
                                                                  attrs: {
                                                                    wrap: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Alternative Accommodation included in Fixed Fee?"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: "alternativeAccommodationIncluded",
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              disabled:
                                                                                !_vm.getIsAnyFixedFeeTradeSelected,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .alternativeAccommodationIncluded,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .clientRatePackageModel
                                                                                      .clientFixedFeeRates,
                                                                                    "alternativeAccommodationIncluded",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "\n                                      clientRatePackageModel.clientFixedFeeRates.alternativeAccommodationIncluded\n                                    ",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider"),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "align-center",
                                                                  attrs: {
                                                                    wrap: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Materials included in Fixed Fee?"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: "materialsIncluded",
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              disabled:
                                                                                !_vm.getIsAnyFixedFeeTradeSelected,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .materialsIncluded,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .clientRatePackageModel
                                                                                      .clientFixedFeeRates,
                                                                                    "materialsIncluded",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.clientFixedFeeRates.materialsIncluded",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs4: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          staticClass:
                                                                            "prepend-icon-image percent pt-0 mt-0",
                                                                          attrs:
                                                                            {
                                                                              name: "otherFeeMaterialUplift",
                                                                              "append-icon":
                                                                                "close",
                                                                              maxlength:
                                                                                "5",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              disabled:
                                                                                !_vm.getIsAnyFixedFeeTradeSelected ||
                                                                                !_vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .materialsIncluded,
                                                                            },
                                                                          on: {
                                                                            keypress:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.numberKeyValidation(
                                                                                  $event
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .materialUplift,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .clientRatePackageModel
                                                                                      .clientFixedFeeRates,
                                                                                    "materialUplift",
                                                                                    _vm._n(
                                                                                      $$v
                                                                                    )
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.clientFixedFeeRates.materialUplift",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider"),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  staticClass:
                                                                    "align-center",
                                                                  attrs: {
                                                                    wrap: "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            "Heaters included in Fixed Fee?"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs2: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              name: "heatersIncluded",
                                                                              color:
                                                                                "primary",
                                                                              readonly:
                                                                                _vm.isUserRoleClientManager,
                                                                              disabled:
                                                                                !_vm.getIsAnyFixedFeeTradeSelected,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .clientRatePackageModel
                                                                                  .clientFixedFeeRates
                                                                                  .heatersIncluded,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .clientRatePackageModel
                                                                                      .clientFixedFeeRates,
                                                                                    "heatersIncluded",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "clientRatePackageModel.clientFixedFeeRates.heatersIncluded",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-data-table", {
                                    staticClass:
                                      "elevation-1 client-rate-table",
                                    attrs: {
                                      headers: _vm.headers,
                                      items:
                                        _vm.clientRatePackageModel
                                          .clientTradeRates,
                                      "item-key": "tradeId",
                                      "hide-actions": "",
                                      expand: "",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "headers",
                                        fn: function (props) {
                                          return [
                                            _c(
                                              "tr",
                                              _vm._l(
                                                props.headers,
                                                function (header) {
                                                  return _c(
                                                    "th",
                                                    {
                                                      key: header.text,
                                                      class: header.class,
                                                      attrs: {
                                                        align: header.align,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(header.text) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                      },
                                      {
                                        key: "items",
                                        fn: function (props) {
                                          return [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-select", {
                                                    directives: [
                                                      {
                                                        name: "validate",
                                                        rawName: "v-validate",
                                                        value: "required",
                                                        expression:
                                                          "'required'",
                                                      },
                                                    ],
                                                    staticClass: "tradeList",
                                                    attrs: {
                                                      items: _vm.getTrades,
                                                      "item-value": "tradeId",
                                                      "item-text":
                                                        "description",
                                                      loading:
                                                        _vm.getTradesRunning,
                                                      "data-vv-scope":
                                                        "frmClientRate",
                                                      "data-vv-name":
                                                        "Trade" + props.index,
                                                      name:
                                                        "Trade" + props.index,
                                                      "error-messages":
                                                        _vm.validationMessage(
                                                          "Trade" + props.index,
                                                          props.item
                                                        ),
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "v-list-tile",
                                                                [
                                                                  _c(
                                                                    "v-list-tile-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-tile-title",
                                                                        {
                                                                          class:
                                                                            _vm.getClass(
                                                                              data
                                                                                .item
                                                                                .tradeId
                                                                            ),
                                                                          domProps:
                                                                            {
                                                                              innerHTML:
                                                                                _vm._s(
                                                                                  data
                                                                                    .item
                                                                                    .description
                                                                                ),
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: props.item.tradeId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "tradeId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.tradeId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "fixed-price",
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "prepend-icon-image",
                                                      attrs: {
                                                        maxlength: "8",
                                                        "prepend-icon": "close",
                                                        disabled:
                                                          !props.item
                                                            .useFixedRate,
                                                        readonly:
                                                          _vm.isUserRoleClientManager,
                                                        name: "fixedRate",
                                                      },
                                                      on: {
                                                        keypress: function (
                                                          $event
                                                        ) {
                                                          return _vm.numberKeyValidation(
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          props.item.fixedRate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "fixedRate",
                                                            _vm._n($$v)
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.fixedRate",
                                                      },
                                                    }),
                                                    _c("v-switch", {
                                                      attrs: {
                                                        color: "primary",
                                                        label: "",
                                                        value: "",
                                                        readonly:
                                                          _vm.isUserRoleClientManager,
                                                        name: "useFixedRate",
                                                      },
                                                      model: {
                                                        value:
                                                          props.item
                                                            .useFixedRate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            props.item,
                                                            "useFixedRate",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "props.item.useFixedRate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "prepend-icon-image",
                                                    attrs: {
                                                      maxlength: "8",
                                                      "prepend-icon": "close",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "visit1",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: props.item.visit1,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "visit1",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.visit1",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "prepend-icon-image",
                                                    attrs: {
                                                      maxlength: "8",
                                                      "prepend-icon": "close",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "visit2",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: props.item.visit2,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "visit2",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.visit2",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "prepend-icon-image",
                                                    attrs: {
                                                      maxlength: "8",
                                                      "prepend-icon": "close",
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "visit3",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: props.item.visit3,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "visit3",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.visit3",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      color: "primary",
                                                      disabled:
                                                        props.item.useFixedRate,
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "isContainMaterials",
                                                    },
                                                    model: {
                                                      value:
                                                        props.item
                                                          .isContainMaterials,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "isContainMaterials",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.isContainMaterials",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  _c("v-text-field", {
                                                    staticClass:
                                                      "prepend-icon-image percent",
                                                    attrs: {
                                                      maxlength: "5",
                                                      "append-icon": "close",
                                                      disabled:
                                                        props.item.useFixedRate,
                                                      readonly:
                                                        _vm.isUserRoleClientManager,
                                                      name: "materialUplift",
                                                    },
                                                    on: {
                                                      keypress: function (
                                                        $event
                                                      ) {
                                                        return _vm.numberKeyValidation(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        props.item
                                                          .materialUplift,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          props.item,
                                                          "materialUplift",
                                                          _vm._n($$v)
                                                        )
                                                      },
                                                      expression:
                                                        "props.item.materialUplift",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "td",
                                                [
                                                  !_vm.isUserRoleClientManager &&
                                                  !_vm.fromJobCompletionScreen
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _vm
                                                                      .clientRatePackageModel
                                                                      .clientTradeRates
                                                                      .length >
                                                                    1
                                                                      ? _c(
                                                                          "v-btn",
                                                                          _vm._g(
                                                                            {
                                                                              staticClass:
                                                                                "mr-0 btn-remove",
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                  icon: "",
                                                                                  color:
                                                                                    "primary",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.removeAdditionalRate(
                                                                                      props.item
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            on
                                                                          ),
                                                                          [
                                                                            _c(
                                                                              "v-icon",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    dark: "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "close"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "Remove Row"
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "text-xs-right mt-2" },
                                    [
                                      !_vm.isUserRoleClientManager &&
                                      !_vm.fromJobCompletionScreen
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mr-0 btn-add",
                                                              attrs: {
                                                                icon: "",
                                                                color:
                                                                  "primary",
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addAdditionalRate,
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "white--text",
                                                                attrs: {
                                                                  dark: "",
                                                                  small: "",
                                                                },
                                                              },
                                                              [_vm._v("add")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2456163247
                                              ),
                                            },
                                            [_c("span", [_vm._v("Add Row")])]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      !_vm.isUserRoleClientManager &&
                      !_vm.fromJobCompletionScreen
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "btn-saveOrAssignRatePackage",
                              attrs: {
                                disabled: _vm.isLoading,
                                loading: _vm.isLoading,
                                color: "primary",
                              },
                              on: { click: _vm.SaveOrAssignRatePackage },
                            },
                            [_vm._v(" Save ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    timeout: _vm.saveSnackbarTimeout,
                    bottom: true,
                    left: true,
                  },
                  model: {
                    value: _vm.saveSnackbar,
                    callback: function ($$v) {
                      _vm.saveSnackbar = $$v
                    },
                    expression: "saveSnackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.saveSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }