var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { attrs: { id: "healthAndSafety" } }, [
      _c(
        "div",
        {
          staticClass: "pl-1",
          staticStyle: {
            display: "flex",
            width: "100%",
            height: "auto",
            "align-items": "center",
          },
        },
        [
          _c(
            "h3",
            {
              staticClass: "mb-2 primary--text mr-0",
              staticStyle: { width: "90%" },
            },
            [_vm._v("Job Summary")]
          ),
          _c(
            "v-btn",
            {
              ref: "btnGenPDF",
              attrs: {
                color: "primary mt-0",
                disabled: _vm.generatePDFInProgress,
                loading: _vm.generatePDFInProgress,
              },
              on: {
                click: () => {
                  _vm.generateReport("healthAndSafety", "Health and Safety")
                },
              },
            },
            [_vm._v(" Generate PDF ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "grey lighten-4 pa-2" }, [
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Job ID:")]),
          _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.jobId
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.getHealthSafetyQA.jobId) + " "),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Emergency Type:")]),
          _vm.emergency && _vm.emergency.typeDescription
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.emergency.typeDescription) + " "),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Emergency Detail:")]),
          _vm.emergency && _vm.emergency.detailDescription
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.emergency.detailDescription) + " "),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Contractor Name:")]),
          _vm.engineerVisitDetail && _vm.engineerVisitDetail.companyName
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.engineerVisitDetail.companyName) + " "),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Engineer Name:")]),
          _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("H&S Completion Date:")]),
          _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.createdAt
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getFormattedDate(_vm.getHealthSafetyQA.createdAt)
                    ) +
                    " "
                ),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
        _c("div", { staticClass: "pb-1 mb-2" }, [
          _c("b", [_vm._v("Job Address:")]),
          _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.jobAddress
            ? _c("span", [
                _vm._v(" " + _vm._s(_vm.getHealthSafetyQA.jobAddress) + " "),
              ])
            : _c("span", [_vm._v("---")]),
        ]),
      ]),
      _c("h3", { staticClass: "mb-2 primary--text pt-2" }, [
        _vm._v("Assessment questions and answers:"),
      ]),
      _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.assessmentQAs
        ? _c(
            "div",
            { staticClass: "grey lighten-4 pa-2" },
            _vm._l(
              _vm.getHealthSafetyQA.assessmentQAs,
              function (assessmentQA, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticClass: "pt-2" }, [
                    _c("b", [_vm._v("Question:")]),
                    _vm._v(" " + _vm._s(assessmentQA.text) + " "),
                  ]),
                  _c("div", [
                    _c("b", [_vm._v("Answer: ")]),
                    assessmentQA.answer === "true"
                      ? _c("span", [_vm._v("Yes")])
                      : _c("span", [_vm._v("No")]),
                  ]),
                  assessmentQA.additionalQuestion
                    ? _c("div", [
                        _c("b", [_vm._v("Additional Question: ")]),
                        _c("span", [
                          _vm._v(_vm._s(assessmentQA.additionalQuestion.text)),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Answer: ")]),
                          assessmentQA.additionalQuestion.answer === "true"
                            ? _c("span", [_vm._v("Yes")])
                            : _c("span", [_vm._v("No")]),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Note: ")]),
                          assessmentQA.additionalQuestion.note
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(assessmentQA.additionalQuestion.note)
                                ),
                              ])
                            : _c("span", [_vm._v("No notes added")]),
                        ]),
                      ])
                    : _vm._e(),
                ])
              }
            ),
            0
          )
        : _c("div", { staticClass: "grey lighten-4 pa-2" }, [
            _c("h3", { staticClass: "mb-2 primary--text pt-2" }, [
              _vm._v("On-site assessment questions and answers:"),
            ]),
            _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.onSiteAssessmentQAs
              ? _c(
                  "div",
                  { staticClass: "grey lighten-4 pa-2" },
                  _vm._l(
                    _vm.getHealthSafetyQA.onSiteAssessmentQAs,
                    function (onSiteQA, index) {
                      return _c("div", { key: index }, [
                        _c("div", { staticClass: "pt-2" }, [
                          _c("b", [_vm._v("Que:")]),
                          _vm._v(
                            " " +
                              _vm._s(onSiteQA.text) +
                              "? (" +
                              _vm._s(onSiteQA.toolTip) +
                              ") "
                          ),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Ans:")]),
                          onSiteQA.answer === "true"
                            ? _c("span", [_vm._v("Yes")])
                            : _c("span", [_vm._v("No")]),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Note:")]),
                          onSiteQA.note
                            ? _c("span", [_vm._v(_vm._s(onSiteQA.note))])
                            : _c("span", [_vm._v("No Notes Added")]),
                        ]),
                      ])
                    }
                  ),
                  0
                )
              : _c("div", { staticClass: "grey lighten-4 pa-2" }, [
                  _c("span", [_vm._v("---")]),
                ]),
            _c("h3", { staticClass: "mb-2 primary--text pt-4" }, [
              _vm._v("Final assessment questions and answers:"),
            ]),
            _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.finalAssessmentQAs
              ? _c(
                  "div",
                  { staticClass: "grey lighten-4 pa-2" },
                  _vm._l(
                    _vm.getHealthSafetyQA.finalAssessmentQAs,
                    function (finalQA, index) {
                      return _c("div", { key: "final-" + index }, [
                        _c("div", { staticClass: "pt-2" }, [
                          _c("b", [_vm._v("Que:")]),
                          _vm._v(" " + _vm._s(finalQA.text) + " "),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Ans:")]),
                          finalQA.answer === "true"
                            ? _c("span", [_vm._v("Yes")])
                            : _c("span", [_vm._v("No")]),
                        ]),
                        _c("div", [
                          _c("b", [_vm._v("Note:")]),
                          finalQA.note
                            ? _c("span", [_vm._v(_vm._s(finalQA.note))])
                            : _c("span", [_vm._v("No Notes Added")]),
                        ]),
                      ])
                    }
                  ),
                  0
                )
              : _c("div", { staticClass: "grey lighten-4 pa-2" }, [
                  _c("span", [_vm._v("---")]),
                ]),
          ]),
      _vm.getHealthSafetyQA &&
      _vm.getHealthSafetyQA.hasSignOff &&
      _vm.getHealthSafetyQA.signOffSignatureURL
        ? _c("div", [
            _c("h3", { staticClass: "pb-2 pt-4 primary--text" }, [
              _vm._v("Engineer's Signature:"),
            ]),
            _c("img", {
              staticClass: "profile-img",
              staticStyle: { width: "auto", height: "50px" },
              attrs: { src: _vm.getHealthSafetyQA.signOffSignatureURL },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "pb-1 mb-2 mt-3" }, [
        _c("b", [_vm._v("Engineer Name: ")]),
        _c("span", [_vm._v(_vm._s(_vm.engineerName))]),
      ]),
      _c("div", { staticClass: "pb-1 mb-2" }, [
        _c("b", [_vm._v("H&S Completion Date: ")]),
        _vm.getHealthSafetyQA && _vm.getHealthSafetyQA.createdAt
          ? _c("span", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getFormattedDate(_vm.getHealthSafetyQA.createdAt)
                  ) +
                  " "
              ),
            ])
          : _c("span", [_vm._v("---")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }