var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _vm.showContractorProfileDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showContractorProfileDialog,
                callback: function ($$v) {
                  _vm.showContractorProfileDialog = $$v
                },
                expression: "showContractorProfileDialog",
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "bar",
                      rawName: "v-bar",
                      value: { useScrollbarPseudo: true },
                      expression: "{ useScrollbarPseudo: true }",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    [
                      _c("ContractorProfile", {
                        attrs: {
                          "contractor-detail": _vm.contractorProfileData,
                          "total-engineer": _vm.totalEngineer,
                          "show-contractor-profile-in-dialog": true,
                        },
                        on: {
                          saveContractor: _vm.saveContractor,
                          onCancelChanges: function ($event) {
                            _vm.showContractorProfileDialog = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _c(
            "v-snackbar",
            {
              staticClass: "mapwaittime",
              attrs: {
                timeout: 120000,
                top: true,
                color: "error",
                absolute: "",
                center: "",
              },
              model: {
                value: _vm.mapWaitTime,
                callback: function ($$v) {
                  _vm.mapWaitTime = $$v
                },
                expression: "mapWaitTime",
              },
            },
            [
              _c("b", { staticClass: "white--text" }, [
                _vm._v("Please wait for a few seconds while map is loading"),
              ]),
              _c(
                "v-btn",
                {
                  attrs: { color: "white", flat: "" },
                  on: {
                    click: function ($event) {
                      _vm.mapWaitTime = false
                    },
                  },
                },
                [_c("v-icon", { attrs: { small: "" } }, [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs3: "", "offset-lg6": "", "offset-sm6": "" } },
                [
                  _c("v-select", {
                    staticClass: "pr-3",
                    attrs: {
                      items: _vm.getJobTypes,
                      label: "Job Type",
                      "item-text": "jobTypeDescription",
                      "item-value": "jobType",
                    },
                    on: { change: _vm.onJobTypeChange },
                    model: {
                      value: _vm.selectedJobType,
                      callback: function ($$v) {
                        _vm.selectedJobType = $$v
                      },
                      expression: "selectedJobType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.trades,
                      label: "Select Trade",
                      "item-text": "description",
                      "item-value": "tradeId",
                      "single-line": "",
                      loading: _vm.loading,
                    },
                    on: { change: _vm.onTrdeSelectionChange },
                    model: {
                      value: _vm.selectedTrade,
                      callback: function ($$v) {
                        _vm.selectedTrade = $$v
                      },
                      expression: "selectedTrade",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs8: "" } },
                [
                  _c("v-card", { staticClass: "pa-3 network-map" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.loading,
                            expression: "!loading",
                          },
                        ],
                        staticClass: "show-emergency",
                      },
                      [
                        _c("NetworkManagerDashboardMap", {
                          ref: "networkManagerDashboardMap",
                          staticStyle: { height: "600px" },
                          attrs: {
                            "map-id": new Date().getTime().toString(),
                            "zoom-level": 7,
                            coverage: _vm.coverage,
                          },
                          on: { setMapWaitTime: _vm.onSetMapWaitTime },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                      },
                      [
                        _c("v-progress-circular", {
                          staticClass: "loading-spinner",
                          attrs: {
                            width: 2,
                            size: 50,
                            indeterminate: "",
                            color: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "", "mt-2": "", "pl-4": "" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "grid-list-lg pa-0", attrs: { fluid: "" } },
                    [
                      _c(
                        "v-layout",
                        {
                          attrs: {
                            wrap: "",
                            "calls-info": "",
                            "request-info": "",
                          },
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mb-1": "", "py-0": "" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "elevation-1 white white--grey pa-0 piechart-jobdeshboard",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.loading,
                                          expression: "!loading",
                                        },
                                      ],
                                      staticClass: "show-preferred-chart",
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "px-2 pb-0 subheading" },
                                        [_vm._v("Contractor Badges")]
                                      ),
                                      _c(
                                        "v-card-text",
                                        {
                                          staticClass:
                                            "pb-0 pa-0 chart-height-100 mt-5",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.contractorTypes !==
                                                    null,
                                                  expression:
                                                    "contractorTypes !== null",
                                                },
                                              ],
                                              staticClass:
                                                "chart-line chart-height-100",
                                            },
                                            [
                                              _c("canvas", {
                                                ref: "pieChartElement",
                                                staticClass: "planet-pie-chart",
                                                attrs: {
                                                  width: "60",
                                                  height: "50px",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.contractorTypes ===
                                                    null,
                                                  expression:
                                                    "contractorTypes === null",
                                                },
                                              ],
                                              staticClass:
                                                "data-not-availble-msg grey--text text--lighten-0 title",
                                            },
                                            [_vm._v(" Data not available ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-progress-circular", {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticClass: "loading-spinner",
                                    attrs: {
                                      width: 2,
                                      size: 50,
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "gridView mt-3", attrs: { sm12: "" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs4: "",
                                sm6: "",
                                lg8: "",
                                "request-info": "",
                              },
                            },
                            [
                              _vm.isApprovedContractor
                                ? _c("h3", { staticClass: "mt-4" }, [
                                    _vm._v("Approved Contractors"),
                                  ])
                                : _vm.isPreferredContractor
                                ? _c("h3", { staticClass: "mt-4" }, [
                                    _vm._v("Preferred Contractors"),
                                  ])
                                : _vm.isPlatinumContractor
                                ? _c("h3", { staticClass: "mt-4" }, [
                                    _vm._v("Platinum Contractors"),
                                  ])
                                : _vm.isUnverifiedContractor
                                ? _c("h3", { staticClass: "mt-4" }, [
                                    _vm._v("Unverified Contractors"),
                                  ])
                                : _c("h3", { staticClass: "mt-4" }, [
                                    _vm._v("Contractors"),
                                  ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs8: "",
                                sm6: "",
                                lg4: "",
                                "job-dashboard-seach": "",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "search-content" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "prepend-icon": "search",
                                      label: "Search",
                                      "single-line": "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "mt-2": "" } },
                            [
                              _c("v-data-table", {
                                class: _vm.isApprovedContractor
                                  ? "approved-contractor elevation-1"
                                  : _vm.isPreferredContractor
                                  ? "preferred-contractor elevation-1"
                                  : _vm.isPlatinumContractor
                                  ? "platinum-contractor elevation-1"
                                  : _vm.isUnverifiedContractor
                                  ? "unverified-contractor elevation-1"
                                  : "elevation-1",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.filteredContractors,
                                  search: _vm.search,
                                  "item-key": "name",
                                  loading: _vm.loading,
                                  pagination: _vm.pagination,
                                },
                                on: {
                                  "update:pagination": function ($event) {
                                    _vm.pagination = $event
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c("tr", {
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.selected = !props.selected
                                            },
                                          },
                                        }),
                                        _c("tr", [
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "primary--text",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onCompanyNameClick(
                                                        props.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        props.item.companyName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    flat: "",
                                                    color: "blue",
                                                    small: "",
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.redirectToContractorPortal(
                                                        props.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("launch")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.email)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.item.totalOpenJobs !==
                                                    null &&
                                                    props.item.totalOpenJobs !==
                                                      undefined &&
                                                    Object.values(
                                                      props.item.totalOpenJobs
                                                    ).length > 0
                                                    ? Object.values(
                                                        props.item.totalOpenJobs
                                                      ).reduce((a, b) => a + b)
                                                    : 0
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.totalEngineerCount
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.preferredContractor
                                              )
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { dark: "", flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }