var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v(_vm._s(_vm.getLogTitle))]),
        ]),
        _vm.item.companyName
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Contractor: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.companyName))]),
            ])
          : _vm._e(),
        _vm.emergencyName
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Emergency: ")]),
              _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
            ])
          : _vm._e(),
        _vm.item.status === _vm.jobVisitStatus[_vm.jobVisitStatus.Unavailable]
          ? _c("div", [
              _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Reason: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.reasonType))]),
              ]),
              _vm.item.reasonDescription
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Description: ")]),
                    _c("span", [_vm._v(_vm._s(_vm.item.reasonDescription))]),
                  ])
                : _vm._e(),
            ])
          : _c("div", [
              _vm.item.etaFrom
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Date From: ")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.etaFrom))),
                    ]),
                  ])
                : _vm._e(),
              _vm.item.etaTo
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Date To: ")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.etaTo))),
                    ]),
                  ])
                : _vm._e(),
              _vm.item.additionalTravelTime
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", [_vm._v("Additional Travel Time: ")]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.getFormatedTravelTime(
                            _vm.item.additionalTravelTime
                          )
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        "(Charge: " +
                          _vm._s(
                            _vm.getFormatedCurrency(_vm.item.additionalCharge)
                          ) +
                          ")"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }