var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "user contractor-registration" },
    [
      _vm._m(0),
      _c(
        "v-card",
        {
          staticClass: "elevation-0 px-3",
          class: !_vm.isRegistrationCompleted
            ? ""
            : "completed-msg grey lighten-5",
          attrs: { flat: "" },
        },
        [
          _c(
            "div",
            { staticClass: "inner-content" },
            [
              _c("v-card-title", { staticClass: "pb-0 mb-3" }, [
                !_vm.isRegistrationCompleted &&
                !_vm.showSetPasswordScreen &&
                !_vm.showErrorBlock
                  ? _c("h1", [_vm._v("Contractor Registration")])
                  : _vm.isRegistrationCompleted &&
                    _vm.showSetPasswordScreen &&
                    !_vm.showErrorBlock
                  ? _c("h1", [_vm._v("Setup Password")])
                  : _vm.showErrorBlock
                  ? _c("h1", { staticClass: "color--red" }, [
                      _vm._v("Account verification failed"),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { staticClass: "pa-0 grid-list-xl" },
                    [
                      !_vm.isRegistrationCompleted &&
                      !_vm.showSetPasswordScreen &&
                      !_vm.showErrorBlock
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Company Name",
                                      maxlength: "100",
                                      required: "",
                                      "data-vv-scope": "createOnlineContractor",
                                      "data-vv-name": "Company Name",
                                      "error-messages":
                                        _vm.errors.collect("Company Name"),
                                    },
                                    model: {
                                      value:
                                        _vm.onlineContractorApplicationModel
                                          .companyName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onlineContractorApplicationModel,
                                          "companyName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "onlineContractorApplicationModel.companyName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "First Name",
                                      maxlength: "50",
                                      required: "",
                                      "data-vv-scope": "createOnlineContractor",
                                      "data-vv-name": "First Name",
                                      "error-messages":
                                        _vm.errors.collect("First Name"),
                                    },
                                    model: {
                                      value:
                                        _vm.onlineContractorApplicationModel
                                          .firstName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onlineContractorApplicationModel,
                                          "firstName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "onlineContractorApplicationModel.firstName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Last Name",
                                      maxlength: "50",
                                      required: "",
                                      "data-vv-scope": "createOnlineContractor",
                                      "data-vv-name": "Last Name",
                                      "error-messages":
                                        _vm.errors.collect("Last Name"),
                                    },
                                    model: {
                                      value:
                                        _vm.onlineContractorApplicationModel
                                          .lastName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onlineContractorApplicationModel,
                                          "lastName",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "onlineContractorApplicationModel.lastName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|email",
                                        expression: "'required|email'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Email Address",
                                      maxlength: "100",
                                      required: "",
                                      "data-vv-scope": "createOnlineContractor",
                                      "data-vv-name": "Email Address",
                                      "error-messages":
                                        _vm.errors.collect("Email Address"),
                                    },
                                    model: {
                                      value:
                                        _vm.onlineContractorApplicationModel
                                          .email,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onlineContractorApplicationModel,
                                          "email",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "onlineContractorApplicationModel.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("PhoneNumber", {
                                    ref: "contractorContactNumber",
                                    staticClass: "contractor-phone-number",
                                    attrs: {
                                      "passed-phone-number":
                                        _vm.onlineContractorApplicationModel
                                          .phoneNumber,
                                      "is-validation-required": true,
                                    },
                                    on: {
                                      phoneNumberUpdated:
                                        _vm.phoneNumberUpdated,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "d-inline-block",
                                    class: _vm.isTCChecked
                                      ? ""
                                      : "red-border-checkbox",
                                    attrs: {
                                      color: "primary",
                                      label: `Terms & Conditions`,
                                    },
                                    model: {
                                      value:
                                        _vm.onlineContractorApplicationModel
                                          .isAgreedToTermsAndCondition,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.onlineContractorApplicationModel,
                                          "isAgreedToTermsAndCondition",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "onlineContractorApplicationModel.isAgreedToTermsAndCondition",
                                    },
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "pl-2 teams-condition-text",
                                      attrs: { href: "Javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          _vm.openTCDialogue = true
                                        },
                                      },
                                    },
                                    [_c("u", [_vm._v("T&C")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.isRegistrationCompleted &&
                          _vm.showSetPasswordScreen &&
                          !_vm.showErrorBlock
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Password",
                                      maxlength: "64",
                                      required: "",
                                      name: "password",
                                      "data-vv-name": "password",
                                      "data-vv-scope":
                                        _vm.passwordValidationScope,
                                      "error-messages":
                                        _vm.errors.collect("password"),
                                      type: _vm.showPassword
                                        ? "text"
                                        : "password",
                                      "append-icon": _vm.showPassword
                                        ? "visibility"
                                        : "visibility_off",
                                      hint: "Password length must be between 8 to 64 characters",
                                      counter: "64",
                                    },
                                    on: {
                                      input: _vm.checkPasswordCriteria,
                                      "click:append": function ($event) {
                                        _vm.showPassword = !_vm.showPassword
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.contractorSignupPasswordDetailsModel
                                          .password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.contractorSignupPasswordDetailsModel,
                                          "password",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "contractorSignupPasswordDetailsModel.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|confirmed:password",
                                        expression:
                                          "'required|confirmed:password'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Confirm Password",
                                      maxlength: "50",
                                      required: "",
                                      "data-vv-scope":
                                        _vm.passwordValidationScope,
                                      "data-vv-name": "password_confirmation",
                                      "error-messages":
                                        _vm.retrieveErrorMessages(
                                          "password_confirmation"
                                        ),
                                      type: _vm.showConfirmPassword
                                        ? "text"
                                        : "password",
                                      "append-icon": _vm.showConfirmPassword
                                        ? "visibility"
                                        : "visibility_off",
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.showConfirmPassword =
                                          !_vm.showConfirmPassword
                                      },
                                    },
                                    model: {
                                      value: _vm.verifyPassword,
                                      callback: function ($$v) {
                                        _vm.verifyPassword =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "verifyPassword",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pt-0": "" } },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      "The password must have all of the following:"
                                    ),
                                  ]),
                                  _vm._l(
                                    _vm.passwordSetCriteria,
                                    function (criteria, index) {
                                      return _c(
                                        "p",
                                        {
                                          key: index,
                                          staticClass: "mb-1",
                                          class: criteria.color,
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(criteria.text) + " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm.isRegistrationCompleted &&
                          !_vm.showSetPasswordScreen &&
                          !_vm.showCompletedSetPasswordScreen &&
                          !_vm.showErrorBlock
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c(
                                  "h1",
                                  { staticClass: "primary--text mb-4" },
                                  [
                                    _vm._v(
                                      "Registration completed successfully!"
                                    ),
                                  ]
                                ),
                                _c("h3", { staticClass: "mb-3" }, [
                                  _vm._v(" Welcome to SIMPLIFi "),
                                  _c("b", { staticClass: "secondary--text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.onlineContractorApplicationModel
                                            .firstName
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.onlineContractorApplicationModel
                                            .lastName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._v(" ! "),
                                ]),
                                _c("p", { staticClass: "mb-0 mb-3" }, [
                                  _vm._v(
                                    "We are excited to welcome you onboard."
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    " Kindly verify yourself by email we sent to "
                                  ),
                                  _c("u", { staticClass: "secondary--text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.onlineContractorApplicationModel
                                            .email
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._v(" . "),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm.isRegistrationCompleted &&
                          !_vm.showSetPasswordScreen &&
                          _vm.showCompletedSetPasswordScreen &&
                          !_vm.showErrorBlock
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h3", { staticClass: "mb-3" }, [
                                  _vm._v(" Welcome to SIMPLIFi "),
                                  _c("b", { staticClass: "secondary--text" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.onlineContractorApplicationModel
                                            .firstName
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm.onlineContractorApplicationModel
                                            .lastName
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _vm._v(" ! "),
                                ]),
                                _c("p", { staticClass: "mb-0 mb-3" }, [
                                  _vm._v(
                                    "We are excited to welcome you onboard."
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v("Kindly Login to proceed further."),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm.showErrorBlock
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("h3", { staticClass: "mb-3" }, [
                                  _vm._v(
                                    "Something went wrong with your registration."
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-0 mb-3" }, [
                                  _vm._v(
                                    "Kindly try again or contact to the SIMPLIFi if you rapidly face this error."
                                  ),
                                ]),
                                _c("p", { staticClass: "mb-0" }, [
                                  _vm._v(
                                    "Kindly Register again to proceed further."
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass: "bottom-align",
                  class: !_vm.isRegistrationCompleted ? "" : "px-3",
                },
                [
                  (_vm.showErrorBlock ? false : !_vm.isRegistrationCompleted)
                    ? _c("v-spacer")
                    : _vm._e(),
                  !_vm.isRegistrationCompleted &&
                  !_vm.showSetPasswordScreen &&
                  !_vm.showErrorBlock
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.contractorSignupInProcess,
                            loading: _vm.contractorSignupInProcess,
                          },
                          on: { click: _vm.registerContractor },
                        },
                        [_vm._v(" Register ")]
                      )
                    : _vm.isRegistrationCompleted &&
                      _vm.showSetPasswordScreen &&
                      !_vm.showErrorBlock
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled:
                              _vm.contractorSignupInProcess ||
                              _vm.disableSetPassword,
                            loading: _vm.contractorSignupInProcess,
                          },
                          on: { click: _vm.setContractorPassword },
                        },
                        [_vm._v(" Set Password ")]
                      )
                    : _vm.isRegistrationCompleted &&
                      !_vm.showSetPasswordScreen &&
                      _vm.showCompletedSetPasswordScreen &&
                      !_vm.showErrorBlock
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.redirectToLogin },
                        },
                        [_vm._v(" Login ")]
                      )
                    : _vm.showErrorBlock
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.redirectToSignup },
                        },
                        [_vm._v("Signup")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.error,
                      expression: "error",
                    },
                  ],
                  staticClass: "ma-3",
                  attrs: { dense: "", outlined: "", type: "error" },
                },
                [_vm._v(" " + _vm._s(_vm.error) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.openTCDialogue
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.openTCDialogue,
                callback: function ($$v) {
                  _vm.openTCDialogue = $$v
                },
                expression: "openTCDialogue",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Terms And Conditions")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.openTCDialogue = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-4 pt-4" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", "pr-2": "" } }, [
                            _c("p", [
                              _vm._v(
                                " The details you supply in this application will, together with the CET Terms of Business Agreement form your agreement with CET. "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " All questions MUST be completed by all Contractors wishing to be considered for work with CET. The completion and submission of this questionnaire does not mean that the Contractor has passed the selection criteria used by CET, and/or that CET will employ the services of the Contractor. "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " Answers will NOT in any way relieve the Contractor of their statutory and contractual Health, Safety and Environmental responsibilities. "
                              ),
                            ]),
                            _c("p", [
                              _vm._v(
                                " Any information provided in response to this application will remain strictly confidential and will NOT be disclosed to any Third Party other than as may be required by law. "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.openTCDialogue = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "background-content-section" }, [
      _c("div", { staticClass: "background-image" }, [
        _c("span", { staticClass: "overlay" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }