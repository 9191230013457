var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.requestType === _vm.reqType[_vm.reqType.AddVulnerabilityAnswer]
      ? _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v("Vulnerability Answer")]),
        ])
      : _vm.requestType === _vm.reqType[_vm.reqType.AddHealthAndSafetyAnswer] &&
        (_vm.hasList ? _vm.index === 0 : true)
      ? _c("div", { staticClass: "mb-2" }, [
          _c("h4", [_vm._v("Health & Safety Answer")]),
        ])
      : _vm._e(),
    _vm.item.isHealthAffected !== null || _vm.item.level
      ? _c("div", [
          _c("div", { class: _vm.hasList ? "" : "mb-1" }, [
            _c("label", [_vm._v("Health affected: ")]),
            _c("span", [
              _vm._v(_vm._s(_vm.item.isHealthAffected ? "Yes" : "No")),
            ]),
          ]),
          _c(
            "div",
            {
              class: _vm.hasList
                ? !(_vm.item.isHealthAffected && _vm.item.answerDetail)
                  ? "mb-2"
                  : ""
                : "mb-1",
            },
            [
              _c("label", [_vm._v("Health affected level: ")]),
              _c("span", [
                _vm._v(_vm._s(_vm.item.level ? _vm.item.level : "")),
              ]),
            ]
          ),
          _vm.item.isHealthAffected && _vm.item.answerDetail
            ? _c("div", { class: _vm.hasList ? "mb-2" : "mb-1" }, [
                _c("label", [_vm._v("Answer Detail: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.answerDetail))]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }