var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v("Cookie Management")]),
      _vm._l(_vm.cookieRecords, function (cookieRecord, index) {
        return _c("div", { key: `${cookieRecord.name}${index}` }, [
          _c("div", { staticClass: "cookie-detail" }, [
            _c("div", [
              _c("h3", { staticClass: "cookie-detail__header" }, [
                _vm._v(_vm._s(cookieRecord.name)),
              ]),
              _c("h4", [
                _vm._v("Description: " + _vm._s(cookieRecord.description)),
              ]),
            ]),
            _c(
              "div",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      flat: "",
                                      icon: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openCookieModal(cookieRecord)
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { dark: "", color: "primary" } },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v("Edit Cookie Record")])]
                ),
              ],
              1
            ),
          ]),
        ])
      }),
      _c(
        "v-btn",
        {
          on: {
            click: function ($event) {
              return _vm.openCookieModal()
            },
          },
        },
        [_vm._v("Add new cookie record")]
      ),
      _vm.showCookieModal
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "800",
                persistent: "",
                "content-class": "v-dialog--scrollable",
                "hide-overlay": "",
              },
              model: {
                value: _vm.showCookieModal,
                callback: function ($$v) {
                  _vm.showCookieModal = $$v
                },
                expression: "showCookieModal",
              },
            },
            [
              _c("CookieConfiguration", {
                on: {
                  closeModal: function ($event) {
                    _vm.showCookieModal = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }