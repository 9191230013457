var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "mb-2", attrs: { wrap: "" } },
    [
      _vm.ivsDetail.cost > 0
        ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
            _c("span", [
              _vm._v(" Cost : "),
              _c("b", { staticClass: "secondary--text" }, [
                _vm._v("£" + _vm._s(_vm.ivsDetail.cost)),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.ivsDetail.completionDate
        ? _c("v-flex", { attrs: { xs12: "", "mb-2": "" } }, [
            _c("span", [
              _vm._v(" Completion Date : "),
              _c("b", { staticClass: "secondary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.formatDate(_vm.ivsDetail.completionDate)) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }