var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "step-header" },
    [
      _c(
        "v-flex",
        [
          _c(
            "v-expansion-panel",
            _vm._l(_vm.stepHeaders, function (stepHeader, index) {
              return _c(
                "v-expansion-panel-content",
                {
                  key: `StepHeader-${index}`,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("h4", { staticClass: "step-header__title" }, [
                              _vm._v("Step - " + _vm._s(index + 1)),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "v-layout",
                        { staticClass: "pa-3", attrs: { row: "", wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "", "pl-2": "" } }, [
                            _c(
                              "label",
                              { attrs: { for: `stepHeading-${index + 1}` } },
                              [_vm._v("Heading")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "step-header__textarea",
                              class: {
                                "step-header__textarea--error":
                                  _vm.retrieveError(
                                    `stepHeading ${index + 1}`
                                  ) !== "",
                              },
                              attrs: {
                                id: `stepHeading-${index + 1}`,
                                placeholder: "Please enter a heading",
                                name: `stepHeading-${index + 1}`,
                                "data-vv-as": `Step ${index + 1} Header`,
                                "data-vv-name": `stepHeading ${index + 1}`,
                                "data-vv-scope": _vm.validationScope,
                              },
                              domProps: { value: stepHeader.heading },
                              on: {
                                input: function ($event) {
                                  return _vm.setHeader(
                                    "heading",
                                    index + 1,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "step-header__error" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.retrieveError(`stepHeading ${index + 1}`)
                                )
                              ),
                            ]),
                          ]),
                          _c("v-flex", { attrs: { xs12: "", "pl-2": "" } }, [
                            _c(
                              "label",
                              { attrs: { for: `stepHeader-${index}` } },
                              [_vm._v("Description")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "step-header__textarea",
                              class: {
                                "step-header__textarea--error":
                                  _vm.retrieveError(
                                    `stepDescription ${index + 1}`
                                  ) !== "",
                              },
                              attrs: {
                                id: `stepDescription-${index}`,
                                placeholder:
                                  "Please enter a description for the step",
                                name: `stepDescription-${index}`,
                                "data-vv-as": `Step ${index + 1} Description`,
                                "data-vv-name": `stepDescription ${index + 1}`,
                                "data-vv-scope": _vm.validationScope,
                              },
                              domProps: { value: stepHeader.description },
                              on: {
                                input: function ($event) {
                                  return _vm.setHeader(
                                    "description",
                                    index + 1,
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("span", { staticClass: "step-header__error" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.retrieveError(
                                    `stepDescription ${index + 1}`
                                  )
                                )
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }