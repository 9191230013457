var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.item.escalationReason
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", [_c("b", [_vm._v("Escalation Reason:")])]),
            _c("span", [_vm._v(_vm._s(_vm.item.escalationReason))]),
          ])
        : _vm._e(),
      _vm.item.escalationDescription
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", [_c("b", [_vm._v("Escalation Description:")])]),
            _c("span", [_vm._v(_vm._s(_vm.item.escalationDescription))]),
          ])
        : _vm._e(),
      _vm.item.deEscalationDescription
        ? _c(
            "v-flex",
            { staticClass: "de-escalationDescription", attrs: { xs12: "" } },
            [
              _c("label", [_c("b", [_vm._v("De-Escalation Description:")])]),
              _c("span", [_vm._v(_vm._s(_vm.item.deEscalationDescription))]),
            ]
          )
        : _vm._e(),
      _vm.item.deEscalationDescription && _vm.item.deEscalatedAt
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("label", [_c("b", [_vm._v("De-Escalated At:")])]),
            _c("span", [
              _vm._v(_vm._s(_vm.getFormatedDate(_vm.item.deEscalatedAt))),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }