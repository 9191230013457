var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { staticClass: "icon-validation", attrs: { sm6: "", md4: "" } },
    [
      _c("v-textarea", {
        staticClass: "audio-attachment",
        attrs: {
          label: _vm.audioAttachmentFieldLabel,
          placeholder: "Select audio or type a message",
          "append-icon":
            _vm.getMessageIsText == false && _vm.audioMessage != ""
              ? "close"
              : "",
          readonly:
            (_vm.getMessageIsText == false && _vm.audioMessage != "") ||
            _vm.isUserRoleClientManager,
          "prepend-icon": "attach_file",
          "hide-details": true,
          rows: "3",
          name: "Audio Message",
        },
        on: {
          "click:append": _vm.removeAudioAttachment,
          "click:prepend": _vm.pickFile,
          blur: _vm.onAudioMessageChange,
          focus: _vm.onInputFocus,
        },
        model: {
          value: _vm.audioMessage,
          callback: function ($$v) {
            _vm.audioMessage = $$v
          },
          expression: "audioMessage",
        },
      }),
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: {
              rules: {
                required: false,
                size: _vm.AudioAttachmentSize,
                mimes: ["audio/mp3", "audio/wav"],
                ext: ["mp3", "wav"],
              },
            },
            expression:
              "{\n      rules: {\n        required: false,\n        size: AudioAttachmentSize,\n        mimes: ['audio/mp3', 'audio/wav'],\n        ext: ['mp3', 'wav'],\n      },\n    }",
          },
        ],
        ref: "audio",
        staticStyle: { display: "none" },
        attrs: {
          type: "file",
          accept: "audio/*",
          name: "audioAttachment",
          "data-vv-scope": _vm.formReference,
          disabled: _vm.isUserRoleClientManager,
        },
        on: { change: _vm.onFilePicked },
      }),
      _c("div", { staticClass: "input-group__details" }, [
        _c(
          "div",
          {
            staticClass:
              "input-group__messages input-group__error input-group--error error--text",
          },
          [
            _vm.audioFileValidationMessage(_vm.errors)
              ? _c("span", [_vm._v(" " + _vm._s(_vm.audioErrorMsg) + " ")])
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }