var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.customer
    ? _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("VIP Customers")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.Close } },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", { staticClass: "pa-3 scroll-content-dialog" }, [
            _c(
              "form",
              [
                _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { sm12: "" } },
                      [
                        _c("v-combobox", {
                          attrs: {
                            label: "Title",
                            maxlength: "20",
                            "return-object": "",
                            items: _vm.clientTitleList,
                          },
                          model: {
                            value: _vm.customer.title,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customer,
                                "title",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customer.title",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { sm12: "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "First Name",
                            required: "",
                            "data-vv-scope": "frmVIPCustomer",
                            "data-vv-name": "First Name",
                            "error-messages": _vm.errors.collect("First Name"),
                          },
                          model: {
                            value: _vm.customer.firstName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customer,
                                "firstName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customer.firstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { sm12: "", "mb-3": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Last Name",
                            required: "",
                            "data-vv-scope": "frmVIPCustomer",
                            "data-vv-name": "Last Name",
                            "error-messages": _vm.errors.collect("Last Name"),
                          },
                          model: {
                            value: _vm.customer.lastName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.customer,
                                "lastName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "customer.lastName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                      [
                        _c("PhoneNumber", {
                          ref: "customerHomeNumber",
                          staticClass: "vip-customer-number",
                          attrs: {
                            "passed-phone-number": _vm.customer.homePhone,
                            "is-validation-required": true,
                          },
                          on: {
                            phoneNumberUpdated: _vm.homePhoneNumberUpdated,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pl-2": "" } },
                      [
                        _c("PhoneNumber", {
                          ref: "customerMobileNumber",
                          staticClass: "vip-customer-number",
                          attrs: {
                            "passed-phone-number": _vm.customer.mobilePhone,
                            "is-validation-required": true,
                          },
                          on: {
                            phoneNumberUpdated: _vm.mobilePhoneNumberUpdated,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-3" },
            [
              _c("v-spacer"),
              _c("v-btn", { on: { click: _vm.Close } }, [_vm._v("Close")]),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0",
                  attrs: {
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                    color: "primary",
                  },
                  on: { click: _vm.Save },
                },
                [_vm._v("submit")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }