var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-flex",
        [
          _c(
            "v-expansion-panel",
            _vm._l(_vm.cookieRecord.scripts, function (script, index) {
              return _c(
                "v-expansion-panel-content",
                {
                  key: `CookieScript-${index + 1}`,
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("h4", { staticClass: "text-uppercase" }, [
                              _vm._v("Script " + _vm._s(index + 1)),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c(
                    "div",
                    { staticClass: "cookie-script" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          staticClass: "cookie-script cookie-script--remove",
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            flat: "",
                                            color: "primary",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeCookieScript(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [_c("v-icon", [_vm._v("close")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Remove Script")])]
                      ),
                      _c(
                        "v-layout",
                        { staticClass: "pa-3", attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.scriptsValidation(script),
                                    expression: "scriptsValidation(script)",
                                  },
                                ],
                                staticClass: "mb-3",
                                attrs: {
                                  label: "Source",
                                  hint: "The source of the script",
                                  value: script.source,
                                  required: "",
                                  "data-vv-name": `cookieScriptSource ${
                                    index + 1
                                  }`,
                                  "data-vv-scope": _vm.validationScope,
                                  "error-messages": _vm.errors.collect(
                                    `cookieScriptSource ${index + 1}`
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCookieRecordScripts(
                                      $event,
                                      index,
                                      "source"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            [
                              _c(
                                "v-expansion-panel",
                                [
                                  _c(
                                    "v-expansion-panel-content",
                                    {
                                      staticClass: "expansion-header",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "header",
                                            fn: function () {
                                              return [
                                                _c(
                                                  "h4",
                                                  {
                                                    staticClass:
                                                      "text-uppercase",
                                                  },
                                                  [_vm._v("Attributes")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._l(
                                        script.attributes,
                                        function (att, attIndex) {
                                          return _c(
                                            "v-flex",
                                            { key: `${att.key}-${attIndex}` },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cookie-attribute",
                                                },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      staticClass:
                                                        "cookie-attribute--close",
                                                      attrs: { top: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        icon: "",
                                                                        flat: "",
                                                                        color:
                                                                          "primary",
                                                                        small:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.removeCookieAttribute(
                                                                              index,
                                                                              attIndex
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Remove Attribute"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        row: "",
                                                        wrap: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs6: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            directives: [
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "mb-3 pa-3",
                                                            attrs: {
                                                              label:
                                                                "Attribute Name",
                                                              hint: "The name of the attribute associated with the script",
                                                              value: att.Key,
                                                              required: "",
                                                              "data-vv-name": `cookieScriptAttibuteKey ${
                                                                attIndex + 1
                                                              }`,
                                                              "data-vv-scope":
                                                                _vm.validationScope,
                                                              "error-messages":
                                                                _vm.errors.collect(
                                                                  `cookieScriptAttibuteKey ${
                                                                    attIndex + 1
                                                                  }`
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateScriptAttributes(
                                                                  $event,
                                                                  index,
                                                                  attIndex,
                                                                  "Key"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs6: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            directives: [
                                                              {
                                                                name: "validate",
                                                                rawName:
                                                                  "v-validate",
                                                                value:
                                                                  "required",
                                                                expression:
                                                                  "'required'",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "mb-3 pa-3",
                                                            attrs: {
                                                              label:
                                                                "Attribute Value",
                                                              hint: "The value of the attribute associated with the script",
                                                              value: att.Value,
                                                              required: "",
                                                              "data-vv-name": `cookieScriptAttibuteValue ${
                                                                attIndex + 1
                                                              }`,
                                                              "data-vv-scope":
                                                                _vm.validationScope,
                                                              "error-messages":
                                                                _vm.errors.collect(
                                                                  `cookieScriptAttibuteValue ${
                                                                    attIndex + 1
                                                                  }`
                                                                ),
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                return _vm.updateScriptAttributes(
                                                                  $event,
                                                                  index,
                                                                  attIndex,
                                                                  "Value"
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addCookieAttribute(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Add Attribute")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: _vm.scriptsValidation(script),
                                    expression: "scriptsValidation(script)",
                                  },
                                ],
                                staticClass: "mb-3",
                                attrs: {
                                  label: "Body",
                                  hint: "The body of the script",
                                  value: script.body,
                                  required: "",
                                  "data-vv-name": `cookieBody ${index + 1}`,
                                  "data-vv-scope": _vm.validationScope,
                                  "error-messages": _vm.errors.collect(
                                    `cookieBody ${index + 1}`
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.setCookieRecordScripts(
                                      $event,
                                      index,
                                      "body"
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-btn",
            {
              on: {
                click: function ($event) {
                  return _vm.addCookieScript()
                },
              },
            },
            [_vm._v("Add Script")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }