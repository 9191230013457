var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-layout",
        {
          class: _vm.openJobView ? "hide-dashboard" : "show-dashboard",
          attrs: { wrap: "" },
        },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-container",
                {
                  attrs: {
                    fluid: "",
                    "grid-list-lg": "",
                    "pt-0": "",
                    "pa-0": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "job-info": "", "request-info": "" } },
                    _vm._l(Object.keys(_vm.getTopTiles), function (key) {
                      return _c(
                        "DashboardTopTile",
                        _vm._b(
                          {
                            key: key,
                            attrs: {
                              "dashboard-tile-view": "FinancialSummary",
                            },
                            on: { onTopTileFilter: _vm.onTopTileFilter },
                          },
                          "DashboardTopTile",
                          _vm.getTopTiles[key],
                          false
                        )
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "gridView mt-4", attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs4: "", "request-info": "" } }, [
                    _c("h3", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.getCurrentSelectedFilter ===
                              _vm.jobFinancialSummaryWidget[
                                _vm.jobFinancialSummaryWidget.ReadyForApproval
                              ]
                              ? "Ready For Approvals"
                              : _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.ToReview
                                ]
                              ? "Awaiting Review from CET"
                              : _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.Disputed
                                ]
                              ? "Disputed"
                              : "Ready for Invoice"
                          ) +
                          " Jobs "
                      ),
                    ]),
                  ]),
                  _vm.getJobListLength > 0
                    ? _c(
                        "v-flex",
                        { staticClass: "text-xs-right search-controls" },
                        [
                          _c(
                            "div",
                            { ref: "search-job", staticClass: "search-job" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "search",
                                  label: "Search",
                                  "single-line": "",
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function ($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.hasInvoicingSkill
                            ? [
                                _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.ReadyForApproval
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 ml-2 mt-2 approveJob-btn",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfirmationForJobApprove(
                                              _vm.financialRequestStatus[
                                                _vm.financialRequestStatus
                                                  .Approve
                                              ]
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Approve ")]
                                    )
                                  : _vm._e(),
                                _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.Disputed
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 ml-2 mt-2 disputedJob-btn",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: _vm.onConfirmDisputingJob,
                                        },
                                      },
                                      [_vm._v(" Approve ")]
                                    )
                                  : _vm._e(),
                                _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.ReadyForApproval
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 ml-2 mt-2 rejectJob-btn",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openJobRejectReasonDialogue()
                                          },
                                        },
                                      },
                                      [_vm._v(" Reject ")]
                                    )
                                  : _vm._e(),
                                _vm.getCurrentSelectedFilter ===
                                _vm.jobFinancialSummaryWidget[
                                  _vm.jobFinancialSummaryWidget.ToReview
                                ]
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "ma-0 ml-2 mt-2 reviewComplete-btn",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.isLoading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openConfirmationForJobToReview(
                                              _vm.financialRequestStatus[
                                                _vm.financialRequestStatus
                                                  .ReviewComplete
                                              ]
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" Review Complete ")]
                                    )
                                  : _vm._e(),
                                _vm.getCurrentSelectedFilter ===
                                  _vm.jobFinancialSummaryWidget[
                                    _vm.jobFinancialSummaryWidget
                                      .ReadyForInvoice
                                  ] && _vm.selectedJobList.length > 0
                                  ? [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "ma-0 ml-2 mt-2 export-bordereau",
                                          attrs: { color: "primary" },
                                          on: { click: _vm.openInvoiceDialog },
                                        },
                                        [_vm._v(" Preview Invoice ")]
                                      ),
                                    ]
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "elevation-1",
                      attrs: { xs12: "", "mt-2": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "job-list grey" },
                        [
                          _c(
                            "v-data-table",
                            {
                              key: _vm.jobList.length,
                              ref: "jobFinancialDataTable",
                              staticClass: "job-table jobFinancialDataTable",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.jobList,
                                loading: _vm.isLoading,
                                pagination: _vm.pagination,
                                "item-key": "clientInvoiceDetailId",
                                "select-all": _vm.hasInvoicingSkill,
                                name: "jobFinancialDataTable",
                                search: _vm.search,
                              },
                              on: {
                                "update:pagination": function ($event) {
                                  _vm.pagination = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          staticClass: "record-item",
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.selected = !props.selected
                                            },
                                          },
                                        },
                                        [
                                          _vm.hasInvoicingSkill
                                            ? _c(
                                                "td",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value":
                                                        props.selected,
                                                      color: "primary",
                                                      "hide-details": "",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c("td", [
                                            _c("b", [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "secondary--text link-jobId",
                                                  attrs: {
                                                    href: "Javascript:void(0)",
                                                    name: "openJobDetailButton",
                                                  },
                                                  on: {
                                                    click: [
                                                      function ($event) {
                                                        return _vm.redirectToJob(
                                                          props.item.jobId
                                                        )
                                                      },
                                                      function ($event) {
                                                        $event.stopPropagation()
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(props.item.jobId) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            props.item.clientNotes &&
                                            props.item.clientNotes.length > 0
                                              ? _c(
                                                  "span",
                                                  { staticClass: "pl-2" },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: {
                                                          right: "",
                                                          "content-class":
                                                            "yellow lighten-2 grey--text text--darken-4 body-2",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "icon-arrow md-icon",
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "info"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          _vm._l(
                                                            props.item
                                                              .clientNotes,
                                                            function (
                                                              note,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "h3",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Note " +
                                                                          _vm._s(
                                                                            index +
                                                                              1
                                                                          ) +
                                                                          ":"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "- " +
                                                                          _vm._s(
                                                                            note
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item
                                                  .claimManagementCompany
                                                  ? props.item
                                                      .claimManagementCompany
                                                  : "-"
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.insurer)),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.productDescription
                                                  ? props.item
                                                      .productDescription
                                                  : "-"
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-right" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getFormatedCurrency(
                                                    props.item.totalCost
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.policyHolderName
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.postCode)),
                                          ]),
                                          _vm.getCurrentSelectedFilter ===
                                          _vm.jobFinancialSummaryWidget[
                                            _vm.jobFinancialSummaryWidget
                                              .Disputed
                                          ]
                                            ? _c("td", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      props.item.disputedReason
                                                    ) +
                                                    " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.selectedJobList,
                                callback: function ($$v) {
                                  _vm.selectedJobList = $$v
                                },
                                expression: "selectedJobList",
                              },
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: {
                                  slot: "progress",
                                  color: _vm.tileColorWithActiveFilter,
                                  indeterminate: "",
                                },
                                slot: "progress",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "loader-content" },
                [
                  _c("v-progress-circular", {
                    staticClass: "loading-spinner",
                    attrs: {
                      width: 2,
                      size: 50,
                      indeterminate: "",
                      color: "primary",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("PartialJobView", {
        ref: "refPartialJobView",
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
      _vm.showDisputeDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showDisputeDialog,
                callback: function ($$v) {
                  _vm.showDisputeDialog = $$v
                },
                expression: "showDisputeDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Dispute Job")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.showDisputeDialog = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog px-4 pt-4" },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", "pr-2": "" } },
                            [
                              _c("v-text-field", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "required",
                                attrs: {
                                  label: "Dispute Reason",
                                  required: "",
                                  "data-vv-scope": "jobFinancialSummaryScope",
                                  "data-vv-name": "Dispute Reason",
                                  name: "disputeReason",
                                  "error-messages":
                                    _vm.errors.collect("Dispute Reason"),
                                },
                                model: {
                                  value: _vm.disputedReason,
                                  callback: function ($$v) {
                                    _vm.disputedReason = $$v
                                  },
                                  expression: "disputedReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-4" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showDisputeDialog = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 submitForRejectedJob-button",
                          attrs: {
                            color: "primary",
                            loading: _vm.onSubmitLoading,
                            disabled: _vm.onSubmitLoading,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.rejectJobs.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.exportToInvoiceDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "600",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.exportToInvoiceDialog,
                callback: function ($$v) {
                  _vm.exportToInvoiceDialog = $$v
                },
                expression: "exportToInvoiceDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Export to Invoice")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", flat: "" },
                          on: { click: _vm.closeInvoiceDialog },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "scroll-content-dialog" },
                    [
                      _c(
                        "v-container",
                        { staticClass: "py-0" },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("span", [
                              _vm._v(
                                "Create a new Invoice for job " +
                                  _vm._s(
                                    _vm.selectedJobList.length
                                      ? _vm.selectedJobList[0].jobId
                                      : ""
                                  ) +
                                  "?"
                              ),
                            ]),
                          ]),
                          _c(
                            "v-layout",
                            { attrs: { xs12: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { staticClass: "px-0 pb-0" },
                                    [
                                      _vm.selectedBordereauId
                                        ? _c(
                                            "span",
                                            { staticClass: "bordereau-id" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedBordereauId)
                                              ),
                                            ]
                                          )
                                        : _c("v-progress-linear", {
                                            attrs: {
                                              height: "4",
                                              indeterminate: "",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs4: "" } },
                                [
                                  _c("v-combobox", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required salesLedgerCode",
                                    attrs: {
                                      label:
                                        _vm.salesLedgerCodeList.length > 0
                                          ? "Select Sales Ledger Code"
                                          : "Create Sales Ledger Code",
                                      items: _vm.salesLedgerCodeList,
                                      maxlength: "20",
                                      required: "",
                                      "data-vv-scope":
                                        "jobFinancialSummaryScope",
                                      "data-vv-name": "Sales Ledger Code",
                                      name: "salesLedgerCode",
                                      "error-messages":
                                        _vm.errors.collect("Sales Ledger Code"),
                                    },
                                    model: {
                                      value: _vm.salesLedgerCode,
                                      callback: function ($$v) {
                                        _vm.salesLedgerCode = $$v
                                      },
                                      expression: "salesLedgerCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("BordereauPreviewTable", {
                                attrs: {
                                  "selected-job-list": _vm.selectedJobList,
                                  "selected-bordereau-id":
                                    _vm.selectedBordereauId,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", flat: "" },
                            on: { click: _vm.closeInvoiceDialog },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "create-bordereau",
                            attrs: {
                              color: "primary",
                              disabled: _vm.bordereauGenerateLoader,
                              loading: _vm.bordereauConfirmLoader,
                            },
                            on: { click: _vm.confirmCreateInvoice },
                          },
                          [_vm._v(" Create ")]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }