var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ContractorJobs", {
        attrs: {
          id: _vm.insurerId,
          "contractor-jobs": _vm.getClientJobList,
          "show-engineer-details": false,
          "total-job-count": _vm.totalJobCount,
          "show-load-more": true,
          "visit-type": _vm.visitType,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }