var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 grid-list-md", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Bank Name",
                      required: "",
                      "error-messages": _vm.errors.collect("Bank Name"),
                      "data-vv-name": "Bank Name",
                      name: "bankName",
                      "data-vv-scope": _vm.formRef,
                    },
                    model: {
                      value: _vm.bankDetail.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bankDetail.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    attrs: { label: "Preferred Reference" },
                    model: {
                      value: _vm.bankDetail.preferredReference,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail,
                          "preferredReference",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bankDetail.preferredReference",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|numeric|max:8",
                        expression: "'required|numeric|max:8'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Account Number",
                      required: "",
                      "error-messages": _vm.errors.collect("Account Number"),
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Account Number",
                    },
                    model: {
                      value: _vm.bankDetail.accountNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail,
                          "accountNumber",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bankDetail.accountNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|numeric|length:6",
                        expression: "'required|numeric|length:6'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Sort Code",
                      required: "",
                      "error-messages": _vm.errors.collect("Sort Code"),
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Sort Code",
                    },
                    model: {
                      value: _vm.bankDetail.sortCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail,
                          "sortCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bankDetail.sortCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Account Holder Name ",
                      required: "",
                      "error-messages": _vm.errors.collect(
                        "Account Holder Name"
                      ),
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Account Holder Name",
                    },
                    model: {
                      value: _vm.bankDetail.accountHolderName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail,
                          "accountHolderName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "bankDetail.accountHolderName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "is-bank-contact-different",
                  attrs: { xs12: "" },
                },
                [
                  _c("v-checkbox", {
                    attrs: {
                      color: "primary",
                      "hide-details": "",
                      label: `If Different from above. Account Department Information Address`,
                    },
                    on: { change: _vm.onContactDiffChange },
                    model: {
                      value: _vm.isBankContactDifferent,
                      callback: function ($$v) {
                        _vm.isBankContactDifferent =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "isBankContactDifferent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          rules: {
                            required: _vm.isBankContactDifferent ? true : false,
                          },
                        },
                        expression:
                          "{\n            rules: { required: isBankContactDifferent ? true : false },\n          }",
                      },
                    ],
                    class: _vm.isBankContactDifferent ? "required" : "",
                    attrs: {
                      disabled: !_vm.isBankContactDifferent,
                      name: "contactName",
                      label: "Contact Name",
                      "error-messages": _vm.errors.collect("Contact Name"),
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Contact Name",
                    },
                    model: {
                      value:
                        _vm.bankDetail.bankAccountContactDetail.contactName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail.bankAccountContactDetail,
                          "contactName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "bankDetail.bankAccountContactDetail.contactName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("PhoneNumber", {
                    ref: "contractorContactNumber",
                    staticClass: "bank-phone-number",
                    attrs: {
                      "passed-phone-number":
                        _vm.bankDetail.bankAccountContactDetail.contactNumber,
                      "is-validation-required": _vm.isBankContactDifferent,
                      "is-disabled": !_vm.isBankContactDifferent,
                    },
                    on: { phoneNumberUpdated: _vm.phoneNumberUpdated },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          rules: {
                            required: _vm.isBankContactDifferent ? true : false,
                            email: true,
                          },
                        },
                        expression:
                          "{\n            rules: {\n              required: isBankContactDifferent ? true : false,\n              email: true,\n            },\n          }",
                      },
                    ],
                    staticClass: "contact-email",
                    class: _vm.isBankContactDifferent ? "required" : "",
                    attrs: {
                      disabled: !_vm.isBankContactDifferent,
                      label: "Contact Email",
                      "error-messages": _vm.errors.collect("Contact Email"),
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Contact Email",
                      name: "contactEmail",
                    },
                    model: {
                      value:
                        _vm.bankDetail.bankAccountContactDetail.contactEmail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.bankDetail.bankAccountContactDetail,
                          "contactEmail",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "bankDetail.bankAccountContactDetail.contactEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { staticClass: "text-xs-right mt-3", attrs: { xs12: "" } },
                [
                  _c("v-divider", { staticClass: "mb-2" }),
                  !_vm.isUserRoleContractor
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 btn-save-bank-detail",
                          attrs: {
                            color: "primary",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading,
                          },
                          on: { click: _vm.saveBankDetail },
                        },
                        [_vm._v(" Save ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-snackbar",
                {
                  attrs: {
                    timeout: _vm.saveSnackbarTimeout,
                    bottom: true,
                    left: true,
                  },
                  model: {
                    value: _vm.saveSnackbar,
                    callback: function ($$v) {
                      _vm.saveSnackbar = $$v
                    },
                    expression: "saveSnackbar",
                  },
                },
                [
                  _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "", color: "secondary" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.saveSnackbar = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }