var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    { staticClass: "elevation-1 pa-0 profile-sec engineer-dialog" },
    [
      _c("v-card-text", { staticClass: "pa-0 scroll-content-dialog" }, [
        _c(
          "div",
          { staticClass: "full-height engineerCard" },
          [
            _c(
              "v-form",
              { attrs: { autocomplete: "off" } },
              [
                _c(
                  "v-layout",
                  {
                    staticClass: "cntp-upper-sec primary pt-3",
                    attrs: { wrap: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      {
                        staticClass: "text-xs-center cntp-logo-sec",
                        attrs: { xs12: "" },
                      },
                      [
                        _c("div", { staticClass: "cntp-logo-sec-content" }, [
                          _vm.imageUrl
                            ? _c(
                                "span",
                                { staticClass: "rm-logo elevation-1 pa-0" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-0",
                                      attrs: {
                                        flat: "",
                                        icon: "",
                                        color: "primary",
                                        small: "",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.removeImage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: { dark: "", color: "primary" },
                                        },
                                        [_vm._v("delete")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "cntp-logo-img mb-3",
                              on: { click: _vm.pickFile },
                            },
                            [
                              _c("img", {
                                staticClass: "elevation-1",
                                attrs: {
                                  src: _vm.imageUrl
                                    ? _vm.imageUrl
                                    : "img/unknownuser.png",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "cntp-logo-img-edit" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: { color: "secondary", large: "" },
                                    },
                                    [_vm._v("edit")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  rules: {
                                    required: false,
                                    image: true,
                                    size: _vm.profileImageSize,
                                    mimes: [
                                      "image/jpeg",
                                      "image/jpg",
                                      "image/png",
                                    ],
                                    ext: ["jpg", "jpeg", "png"],
                                  },
                                },
                                expression:
                                  "{\n                  rules: {\n                    required: false,\n                    image: true,\n                    size: profileImageSize,\n                    mimes: ['image/jpeg', 'image/jpg', 'image/png'],\n                    ext: ['jpg', 'jpeg', 'png'],\n                  },\n                }",
                              },
                            ],
                            ref: "image",
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              accept: "image/*",
                              name: "logo",
                              "data-vv-scope": "frmProfile",
                            },
                            on: { change: _vm.onFilePicked },
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "input-group__details imageError-wrapper",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "input-group__messages input-group__error input-group--error error--text",
                                },
                                [
                                  _vm.imageValidationMessage(_vm.errors)
                                    ? _c("span", [
                                        _vm._v(
                                          " " + _vm._s(_vm.logoErrorMsg) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  {
                    staticClass: "px-3 pt-3 mt-5",
                    attrs: { row: "", wrap: "" },
                  },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "First Name",
                            maxlength: "50",
                            required: "",
                            "data-vv-scope": "frmEngineer",
                            "data-vv-name": "First Name",
                            "error-messages": _vm.errors.collect("First Name"),
                          },
                          model: {
                            value: _vm.engineer.firstName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.engineer,
                                "firstName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "engineer.firstName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pl-2": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Last Name",
                            maxlength: "50",
                            required: "",
                            "data-vv-scope": "frmEngineer",
                            "data-vv-name": "Last Name",
                            "error-messages": _vm.errors.collect("Last Name"),
                          },
                          model: {
                            value: _vm.engineer.lastName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.engineer,
                                "lastName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "engineer.lastName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                      [
                        _c("v-text-field", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          staticClass: "required",
                          attrs: {
                            label: "Email",
                            maxlength: "50",
                            required: "",
                            "data-vv-scope": "frmEngineer",
                            "data-vv-name": "Email",
                            "error-messages": _vm.errors.collect("Email"),
                            name: "Email",
                            readonly: _vm.isUserRoleContractor,
                          },
                          model: {
                            value: _vm.engineer.email,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.engineer,
                                "email",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "engineer.email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", "pl-2": "" } },
                      [
                        _c("PhoneNumber", {
                          ref: "engineerContactNumber",
                          staticClass: "internal-phone-number engineer-number",
                          attrs: {
                            "passed-phone-number": _vm.engineer.contactNumber,
                            "is-validation-required": true,
                          },
                          on: { phoneNumberUpdated: _vm.phoneNumberUpdated },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-textarea", {
                          attrs: {
                            label: "Address",
                            maxlength: "500",
                            rows: "3",
                          },
                          model: {
                            value: _vm.engineer.address,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.engineer,
                                "address",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "engineer.address",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", "mt-1": "" } },
                      [
                        _c("v-switch", {
                          attrs: {
                            color: "primary",
                            small: "",
                            label: "Ability to use Portal",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.engineer.hasPortalAccess,
                            callback: function ($$v) {
                              _vm.$set(_vm.engineer, "hasPortalAccess", $$v)
                            },
                            expression: "engineer.hasPortalAccess",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isUpdateEngineer
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "my-2": "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                "hide-details": "",
                                label: "Engineer not available",
                              },
                              on: { change: _vm.IsEngineerOutOfDateChange },
                              model: {
                                value: _vm.isEngineerOutOfDate,
                                callback: function ($$v) {
                                  _vm.isEngineerOutOfDate = $$v
                                },
                                expression: "isEngineerOutOfDate",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isUpdateEngineer && _vm.isEngineerOutOfDate
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                          [
                            _c(
                              "v-dialog",
                              {
                                ref: "fromDatePickerDialog",
                                attrs: {
                                  "return-value": _vm.getOutsideFromDate,
                                  persistent: "",
                                  lazy: "",
                                  "full-width": "",
                                  width: "290px",
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    _vm.getOutsideFromDate = $event
                                  },
                                  "update:return-value": function ($event) {
                                    _vm.getOutsideFromDate = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "From Date",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                },
                                                model: {
                                                  value: _vm.getOutsideFromDate,
                                                  callback: function ($$v) {
                                                    _vm.getOutsideFromDate = $$v
                                                  },
                                                  expression:
                                                    "getOutsideFromDate",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2754433774
                                ),
                                model: {
                                  value: _vm.showFromDatePicker,
                                  callback: function ($$v) {
                                    _vm.showFromDatePicker = $$v
                                  },
                                  expression: "showFromDatePicker",
                                },
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: {
                                      color: "primary",
                                      min: _vm.setMinDate,
                                      scrollable: "",
                                    },
                                    model: {
                                      value: _vm.getOutsideFromDate,
                                      callback: function ($$v) {
                                        _vm.getOutsideFromDate = $$v
                                      },
                                      expression: "getOutsideFromDate",
                                    },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { flat: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showFromDatePicker = false
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { flat: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.fromDatePickerDialog.save(
                                              _vm.getOutsideFromDate
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("OK")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isUpdateEngineer && _vm.isEngineerOutOfDate
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", sm6: "", "pr-2": "" } },
                          [
                            _c(
                              "v-dialog",
                              {
                                ref: "toDatePickerDialog",
                                attrs: {
                                  "return-value": _vm.getOutsideToDate,
                                  persistent: "",
                                  lazy: "",
                                  "full-width": "",
                                  width: "290px",
                                  disabled: !_vm.getOutsideFromDate,
                                },
                                on: {
                                  "update:returnValue": function ($event) {
                                    _vm.getOutsideToDate = $event
                                  },
                                  "update:return-value": function ($event) {
                                    _vm.getOutsideToDate = $event
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  label: "To Date",
                                                  "prepend-icon": "event",
                                                  readonly: "",
                                                  disabled:
                                                    !_vm.getOutsideFromDate,
                                                },
                                                model: {
                                                  value: _vm.getOutsideToDate,
                                                  callback: function ($$v) {
                                                    _vm.getOutsideToDate = $$v
                                                  },
                                                  expression:
                                                    "getOutsideToDate",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3703158888
                                ),
                                model: {
                                  value: _vm.showToDatePicker,
                                  callback: function ($$v) {
                                    _vm.showToDatePicker = $$v
                                  },
                                  expression: "showToDatePicker",
                                },
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: {
                                      color: "primary",
                                      min: _vm.getOutsideFromDate,
                                      scrollable: "",
                                    },
                                    model: {
                                      value: _vm.getOutsideToDate,
                                      callback: function ($$v) {
                                        _vm.getOutsideToDate = $$v
                                      },
                                      expression: "getOutsideToDate",
                                    },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { flat: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showToDatePicker = false
                                          },
                                        },
                                      },
                                      [_vm._v("Cancel")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { flat: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$refs.toDatePickerDialog.save(
                                              _vm.getOutsideToDate
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("OK")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isUpdateEngineer
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "", "mb-4": "" } },
                          [
                            _c("v-select", {
                              staticClass: "hide-option trade-items",
                              attrs: {
                                items: _vm.tradeList,
                                "item-disabled": _vm.disableTradeList,
                                multiple: "",
                                "return-object": "",
                                "item-text": "description",
                                "item-value": "tradeId",
                                "persistent-hint": "",
                                label: "Select Trade",
                                "hide-details": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onTradeSelectionChange(
                                    _vm.getSelectedTradeList,
                                    $event
                                  )
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c(
                                              "v-list-tile-action",
                                              [
                                                _c("v-checkbox", {
                                                  attrs: { color: "primary" },
                                                  model: {
                                                    value:
                                                      _vm.tradeCheckboxes[
                                                        _vm.tradeList.indexOf(
                                                          data.item
                                                        )
                                                      ].checked,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.tradeCheckboxes[
                                                          _vm.tradeList.indexOf(
                                                            data.item
                                                          )
                                                        ],
                                                        "checked",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "tradeCheckboxes[tradeList.indexOf(data.item)].checked",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-tile-content",
                                              [
                                                _c("v-list-tile-title", {
                                                  class: data.item.isDeleted
                                                    ? "deleted-item"
                                                    : "",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      data.item.description
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1634256645
                              ),
                              model: {
                                value: _vm.getSelectedTradeList,
                                callback: function ($$v) {
                                  _vm.getSelectedTradeList = $$v
                                },
                                expression: "getSelectedTradeList",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isUpdateEngineer
                      ? _vm._l(_vm.getSelectedTradeList, function (trade) {
                          return _c(
                            "v-flex",
                            {
                              key: trade.tradeId,
                              staticClass: "engineer-timeslot",
                              attrs: { xs12: "" },
                            },
                            [
                              _c("TimeSlotPicker", {
                                staticClass: "engineer-availability",
                                attrs: {
                                  "header-text": trade.description,
                                  "filter-by-date": false,
                                  "slot-type": "today",
                                  model: trade,
                                  "slot-items": _vm.getTradeAvailabilityByTrade(
                                    trade.tradeId
                                  ),
                                },
                                on: {
                                  updateSlotItems: _vm.onSlotSelectionChange,
                                },
                              }),
                            ],
                            1
                          )
                        })
                      : _vm._e(),
                    _c(
                      "v-flex",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errorMsg,
                            expression: "errorMsg",
                          },
                        ],
                        attrs: { xs12: "", "pl-2": "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pt-3 common-error-message" },
                          [
                            _c(
                              "div",
                              { staticClass: "red pa-2 d-block lighten-4" },
                              [
                                _c("span", [
                                  _c("b", [_vm._v(_vm._s(_vm.errorMsg))]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("v-divider"),
      _c(
        "v-card-actions",
        { staticClass: "px-3" },
        [
          _c("v-spacer"),
          _c("v-btn", { on: { click: _vm.Close } }, [_vm._v("Close")]),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: {
                color: "primary save-engineer",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: { click: _vm.Save },
            },
            [_vm._v(" Save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }