var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._m(0),
    _vm.item.visitIds.length > 0
      ? _c(
          "div",
          _vm._l(_vm.item.visitIds, function (visitId, index) {
            return _c(
              "div",
              { key: index },
              [
                _vm.visitDetail(visitId)
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Visit Created At: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getFormattedDate(
                              _vm.visitDetail(visitId).visitDate
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.visitDetail(visitId)
                  ? _c("div", { staticClass: "mb-1" }, [
                      _c("label", [_vm._v("Cost: ")]),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.getFormatedCurrency(
                              _vm.visitDetail(visitId).cost
                            )
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
                index !== _vm.item.visitIds.length - 1
                  ? _c("v-divider", { staticClass: "mb-1" })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Monitoring Visit Invoice")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }