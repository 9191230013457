var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _vm._m(0),
        _vm.getCancelAssignedJobContractorDetail
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Contractor: ")]),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.getCancelAssignedJobContractorDetail.companyName)
                ),
              ]),
            ])
          : _vm._e(),
        _vm.item.status
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Status: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.status))]),
            ])
          : _vm._e(),
        _vm.item.reasonType
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Reason: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.reasonType))]),
            ])
          : _vm._e(),
        _vm.item.reasonDescription
          ? _c("div", { staticClass: "mb-1" }, [
              _c("label", [_vm._v("Description: ")]),
              _c("span", [_vm._v(_vm._s(_vm.item.reasonDescription))]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Remove contractor from job")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }