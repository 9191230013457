var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "mb-2", attrs: { wrap: "" } },
    [
      _vm.complaint.severity
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("span", [
              _vm._v(" Complaint Severity: "),
              _c("b", [_vm._v(_vm._s(_vm.complaint.severity))]),
            ]),
          ])
        : _vm._e(),
      _vm.getComplaintType
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("span", [
              _vm._v(" Complaint Type: "),
              _c("b", [_vm._v(_vm._s(_vm.getComplaintType))]),
            ]),
          ])
        : _vm._e(),
      _vm.complaint && _vm.complaint.resolvedByUserName
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("span", [
              _vm._v(" Agent Name: "),
              _c("b", [_vm._v(_vm._s(_vm.complaint.resolvedByUserName))]),
            ]),
          ])
        : _vm._e(),
      _vm.complaint && _vm.complaint.isEscalated
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("span", [
              _vm._v(" Complaint Escalated: "),
              _c("b", [
                _vm._v(_vm._s(_vm.complaint.isEscalated ? "Yes" : "No")),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.complaint
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("span", [
              _vm._v(" Complaint Resolved: "),
              _c("b", [
                _vm._v(_vm._s(_vm.complaint.isResolved ? "Yes" : "No")),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.complaint && _vm.complaint.isResolved
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("span", [
              _vm._v(" Resolved Date: "),
              _c("b", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.complaint.resolvedDate
                        ? _vm.getFormattedDateTime(_vm.complaint.resolvedDate)
                        : "-"
                    ) +
                    " "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.complaint && _vm.complaint.reopenedDate && !_vm.complaint.isResolved
        ? _c("v-flex", { attrs: { xs12: "", "mt-1": "" } }, [
            _c("br"),
            _c("span", [
              _vm._v(
                "(Reopened " +
                  _vm._s(_vm.getFormattedDateTime(_vm.complaint.reopenedDate)) +
                  ")"
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }