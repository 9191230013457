var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    { staticClass: "contractcard-list", attrs: { xs3: "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            {
              class:
                _vm.statusText === "Pending"
                  ? "list-pending"
                  : _vm.statusText === "Accepted"
                  ? "list-accepted"
                  : _vm.statusText === "In progress"
                  ? "list-in-progress"
                  : _vm.statusText === "Completed"
                  ? "list-completed"
                  : "",
              attrs: { xs12: "" },
            },
            [
              _vm.contractorJobList.length > 0
                ? _vm._l(_vm.contractorJobList, function (job) {
                    return _c(
                      "v-card",
                      {
                        key: job.id,
                        staticClass: "card-box-shadow-none",
                        class: _vm.getCardStyle(job),
                      },
                      [
                        _c(
                          "v-card-title",
                          {
                            staticClass: "pa-2",
                            attrs: { "primary-title": "" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "pl-1" },
                              [
                                job.tradeId && _vm.getTradeDescById(job.tradeId)
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        staticClass: "tradeDescription",
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      { attrs: { small: "" } },
                                                      on
                                                    ),
                                                    [_vm._v("build")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                job.tradeId
                                                  ? _vm.getTradeDescById(
                                                      job.tradeId
                                                    )
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "pl-2" }, [
                              _vm.isUserRoleAdministrator
                                ? _c("h4", [
                                    _vm._v(
                                      " Job: " +
                                        _vm._s(job.jobId) +
                                        _vm._s(
                                          job.totalVisit > 1
                                            ? "-" + job.visitNumber
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _c("h4", [
                                    _vm._v(
                                      " Job: " +
                                        _vm._s(job.jobId) +
                                        _vm._s(
                                          _vm.contractorHasMultipleVisit(
                                            job.jobId
                                          )
                                            ? "-" + job.visitNumber
                                            : ""
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "job-code grey--text text--darken-1",
                              },
                              [_vm._v(" " + _vm._s(job.postcode) + " ")]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { top: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.isContractorPageLoaded &&
                                          _vm.statusText === "Pending" &&
                                          job.isContractorReAttend
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "part-icon mr-0 btn-addAdditionalRequest",
                                                    attrs: {
                                                      icon: "",
                                                      flat: "",
                                                      color: "primary",
                                                      small: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.openAdditionalRequestDialog(
                                                          job
                                                        )
                                                      },
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "/img/part.svg",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        job.previousEngineerVisitId
                                          ? "Add additional request"
                                          : "No visit created for the job"
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          { staticClass: "pa-2 jb-detail-content" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "primary-title pb-2 primary-card-title",
                              },
                              [
                                _c("h3", { staticClass: "date-title" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "padding-left": "3px",
                                        "padding-right": "6px",
                                        width: "16px",
                                      },
                                    },
                                    [
                                      _vm.getEmergencyDescription(
                                        job.forEmergencyTypeId,
                                        job.forEmergencyDetailId
                                      )
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _vm.getEmergencyIcon(
                                                          job.jobId
                                                        )
                                                          ? _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "category-icon-img",
                                                                  attrs: {
                                                                    src: _vm.getEmergencyIcon(
                                                                      job.jobId
                                                                    ),
                                                                    alt: "",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            )
                                                          : _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "category-icon-img",
                                                                  attrs: {
                                                                    src: "/img/emergency.svg",
                                                                    alt: "",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "emergency-detail",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getEmergencyDescription(
                                                          job.forEmergencyTypeId,
                                                          job.forEmergencyDetailId
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : [
                                            _c("img", {
                                              staticClass:
                                                "category-icon-img emergency-not-found",
                                              attrs: {
                                                src: "/img/emergency.svg",
                                                alt: "",
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openContractorJobDetailModal(
                                            job.jobId,
                                            job.id
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(job.customerName) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "primary-card-icons" },
                                  [
                                    _vm.showCallCustomerButton(job)
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "icon call-customer-icon",
                                                            attrs: {
                                                              href: "javascript:void(0)",
                                                              small: "",
                                                              color: "white",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                                return _vm.showProxyNumberDialogue(
                                                                  job
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [_vm._v("phone")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Call customer"),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                    job.isContractorReAttend
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { top: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "return-visit",
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: "/img/return-visit.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Return Visit")])]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "details-row" },
                              [
                                _c("v-icon", [_vm._v("place")]),
                                _c("address", [_vm._v(_vm._s(job.address))]),
                              ],
                              1
                            ),
                          ]
                        ),
                        _c(
                          "v-card-actions",
                          { class: _vm.getCardActionStyle(job) },
                          [
                            _c(
                              "div",
                              { staticClass: "card-alert" },
                              [
                                job.isVulnerability
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        class:
                                                          _vm.getHealthAndSafetyTooltipStyle(
                                                            job.vulnerabilityLevel
                                                          ),
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/shield.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [
                                            _vm._v("Vulnerability Issue"),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                job.isHealthAffected
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        class:
                                                          _vm.getHealthAndSafetyTooltipStyle(
                                                            job.healthAndSafetyLevel
                                                          ),
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/health-care.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [
                                            _vm._v("Health and Safety Issue"),
                                          ]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                job.visitPreviouslyAbandoned
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "red alert-inner-content",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/abandon.svg",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _c("b", [_vm._v("Abandoned Visit")]),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c("v-flex", { attrs: { xs12: "" } }, [
                                  !_vm.showEngineerDetails
                                    ? _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "job-in-process-engineer engineer-Unassigned",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/img/unknownuser.png",
                                              },
                                            }),
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    job.engineerName
                                                      ? job.engineerName
                                                      : "Unassigned"
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ])
                                    : _c("div", [
                                        job.status ===
                                          _vm.jobVisitStatus[
                                            _vm.jobVisitStatus.Pending
                                          ] ||
                                        job.status ===
                                          _vm.jobVisitStatus[
                                            _vm.jobVisitStatus.Accepted
                                          ]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "select-engineer mb-0",
                                              },
                                              [
                                                _c("v-select", {
                                                  staticClass:
                                                    "hide-option engineer-list",
                                                  attrs: {
                                                    items: _vm.getEngineers(
                                                      job.tradeId
                                                    ),
                                                    "return-object": "",
                                                    "item-value": "id",
                                                    "persistent-hint": "",
                                                    "hide-details": "",
                                                    disabled:
                                                      _vm.showETASetDialog(job),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.assignEngineerToJob(
                                                        job,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.onClickAssignEngineer(
                                                        job
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "selection",
                                                        fn: function (data) {
                                                          return [
                                                            _c("v-avatar", [
                                                              data.item
                                                                .profileImageThumbnailUrl
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src: data
                                                                        .item
                                                                        .profileImageThumbnailUrl,
                                                                    },
                                                                  })
                                                                : _c("img", {
                                                                    attrs: {
                                                                      src: "/img/unknownuser.png",
                                                                    },
                                                                  }),
                                                            ]),
                                                            _vm._v(
                                                              "   " +
                                                                _vm._s(
                                                                  data.item
                                                                    .firstName +
                                                                    " " +
                                                                    data.item
                                                                      .lastName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: "item",
                                                        fn: function (data) {
                                                          return [
                                                            typeof data.item !==
                                                            "object"
                                                              ? [
                                                                  _c(
                                                                    "v-list-tile-content",
                                                                    {
                                                                      domProps:
                                                                        {
                                                                          textContent:
                                                                            _vm._s(
                                                                              data.item
                                                                            ),
                                                                        },
                                                                    }
                                                                  ),
                                                                ]
                                                              : [
                                                                  _c(
                                                                    "v-list-tile-avatar",
                                                                    [
                                                                      data.item
                                                                        .profileImageThumbnailUrl
                                                                        ? _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: data
                                                                                    .item
                                                                                    .profileImageThumbnailUrl,
                                                                                },
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "img",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  src: "/img/unknownuser.png",
                                                                                },
                                                                            }
                                                                          ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-list-tile-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-tile-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                data
                                                                                  .item
                                                                                  .firstName +
                                                                                  " " +
                                                                                  data
                                                                                    .item
                                                                                    .lastName
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-list-tile-sub-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                data
                                                                                  .item
                                                                                  .email
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value: job.engineerId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        job,
                                                        "engineerId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "job.engineerId",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm.getEngineerDetail(
                                                job.engineerId
                                              )
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "job-in-process-engineer",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: _vm.getEngineerDetail(
                                                            job.engineerId
                                                          )
                                                            .profileImageThumbnailUrl
                                                            ? _vm.getEngineerDetail(
                                                                job.engineerId
                                                              )
                                                                .profileImageThumbnailUrl
                                                            : "/img/unknownuser.png",
                                                        },
                                                      }),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getEngineerDetail(
                                                                job.engineerId
                                                              )
                                                                ? _vm.getEngineerDetail(
                                                                    job.engineerId
                                                                  ).firstName +
                                                                    " " +
                                                                    _vm.getEngineerDetail(
                                                                      job.engineerId
                                                                    ).lastName
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getEngineerDetail(
                                                                job.engineerId
                                                              )
                                                                ? _vm.getEngineerDetail(
                                                                    job.engineerId
                                                                  ).email
                                                                : ""
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                      ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "pt-1 eta-info",
                                      class: !_vm.showEngineerDetails
                                        ? "mt-1"
                                        : "",
                                    },
                                    [
                                      !_vm.showETASetDialog(job)
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("date_range")]
                                              ),
                                              _c("b", [
                                                _vm._v(" ETA: "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "eta-date-from",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text--darken-1",
                                                      },
                                                      [_vm._v("From")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            job.etaFrom
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "eta-date-to",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text text--darken-1",
                                                      },
                                                      [_vm._v(" To")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getFormattedDate(
                                                            job.etaTo
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    small: "",
                                                    color: "primary btn-setETA",
                                                  },
                                                  on: {
                                                    click: () =>
                                                      _vm.showETADialog(job),
                                                  },
                                                },
                                                [_vm._v("Set ETA")]
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : _vm._e(),
              _vm.showLoadMore &&
              _vm.contractorJobList.length > 0 &&
              _vm.totalJobCount >= _vm.defaultLoadMoreRecordCount &&
              _vm.contractorJobList.length !== _vm.totalJobCount &&
              _vm.contractorJobList.length >= _vm.defaultLoadMoreRecordCount
                ? _c(
                    "div",
                    {
                      staticClass:
                        "task-action text-xs-center grey lighten-2 px-2",
                    },
                    [
                      _vm.loadMoreJobsStarted
                        ? _c("v-progress-linear", {
                            attrs: { indeterminate: true, height: "4" },
                          })
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            flat: "",
                            small: "",
                            block: "",
                          },
                          on: { click: _vm.loadMore },
                        },
                        [_c("b", [_vm._v("Load More")])]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm.statusText === "Pending" && _vm.etaDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.etaDialogOpen,
                callback: function ($$v) {
                  _vm.etaDialogOpen = $$v
                },
                expression: "etaDialogOpen",
              },
            },
            [
              _c("ETADialogContent", {
                attrs: { "contractor-appointed-detail": _vm.etaDialogJob },
                on: { close: _vm.closeETADialog },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.contractorJobDetailDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1000",
                persistent: "",
                "content-class":
                  "v-dialog--scrollable contractorjob-details-dialog",
              },
              model: {
                value: _vm.contractorJobDetailDialog,
                callback: function ($$v) {
                  _vm.contractorJobDetailDialog = $$v
                },
                expression: "contractorJobDetailDialog",
              },
            },
            [
              _c("ContractorJobDetail", {
                attrs: {
                  "parent-component": "ContractorJobCard",
                  "contractor-job-detail-item": _vm.contractorJobDetailModel,
                  engineers: _vm.engineers,
                },
                on: {
                  contractorJobDetailDialogClose:
                    _vm.contractorJobDetailDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showProxyNumber
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "400", persistent: "" },
              model: {
                value: _vm.showProxyNumber,
                callback: function ($$v) {
                  _vm.showProxyNumber = $$v
                },
                expression: "showProxyNumber",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Customer Number")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.closeProxyNumberDialog },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "scroll-content-dialog pa-3 customer-number",
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isProxyNumberLoaded,
                              expression: "!isProxyNumberLoaded",
                            },
                          ],
                          attrs: { xs12: "" },
                        },
                        [
                          _c("v-select", {
                            staticClass: "contact-number",
                            attrs: {
                              items: _vm.contactNumberList,
                              label: "Select Contact Number",
                              "item-text": "label",
                              "item-value": "id",
                            },
                            model: {
                              value: _vm.selectedContactNumber,
                              callback: function ($$v) {
                                _vm.selectedContactNumber = $$v
                              },
                              expression: "selectedContactNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isProxyNumberLoaded,
                              expression: "isProxyNumberLoaded",
                            },
                          ],
                        },
                        [
                          _vm.proxyNumber
                            ? _c("h3", { staticClass: "mb-2" }, [
                                _vm._v(
                                  "Customer Phone: " + _vm._s(_vm.proxyNumber)
                                ),
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "mb-2" }, [
                            _vm._v(" You may contact the customer from "),
                            _c("b", [_vm._v(_vm._s(_vm.contractorNumber))]),
                          ]),
                          _vm.proxyExpiration
                            ? _c("span", [
                                _vm._v(
                                  "Session valid until: " +
                                    _vm._s(
                                      _vm.getFormattedDate(_vm.proxyExpiration)
                                    )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isProxyNumberLoaded,
                          expression: "!isProxyNumberLoaded",
                        },
                      ],
                      staticClass: "px-4",
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          on: { click: _vm.closeProxyNumberDialog },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0 btn-saveProxyNumber",
                          attrs: {
                            color: "primary",
                            loading: _vm.proxyInfoLoading,
                            disabled: _vm.proxyInfoLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.generateProxyNumber()
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.additionalRequestDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.additionalRequestDialog,
                callback: function ($$v) {
                  _vm.additionalRequestDialog = $$v
                },
                expression: "additionalRequestDialog",
              },
            },
            [
              _c("AdditionalRequest", {
                ref: "additionalRequest",
                attrs: {
                  "previous-engineer-visit-detail":
                    _vm.previousEngineerVisitDetail,
                  "is-for-contractor-job-card": true,
                },
                on: {
                  closeAdditionalRequestDialog:
                    _vm.closeAdditionalRequestDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.snackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }