var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c("Availability", {
        attrs: { "customer-availability": _vm.customerAvailability },
      }),
      _vm.getDelayCode
        ? _c("v-flex", { attrs: { xs12: "" } }, [
            _c("span", { staticClass: "delay-code" }, [
              _c("b", [_vm._v("DelayCode:")]),
              _vm._v(" " + _vm._s(_vm.getDelayCode) + " "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }