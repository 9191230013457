var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "", "bg-white": "" } },
        [
          _vm.getSIProductItems
            ? _c(
                "v-flex",
                { attrs: { xs12: "", "pa-3": "" } },
                [
                  _c("h3", { staticClass: "pb-2" }, [_vm._v("Product Items")]),
                  _c("v-divider"),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-2": "" } },
                    [
                      _vm.siJobProductItems && _vm.siJobProductItems.length > 0
                        ? _c(
                            "v-data-table",
                            {
                              staticClass: "gridView elevation-1 si-products",
                              attrs: {
                                items: _vm.siJobProductItems,
                                "hide-headers": "",
                                "hide-actions": "",
                                width: "500",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c("tr", [
                                          _c(
                                            "td",
                                            {
                                              class: {
                                                "font-weight-bold":
                                                  props.item.isProductTitle,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item.description
                                                  ) +
                                                  " "
                                              ),
                                              props.item.isMonitoringProduct &&
                                              props.item.isProductTitle
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { right: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "md-icon pr-2 pl-1 mt-1",
                                                                      attrs: {
                                                                        color:
                                                                          "blue",
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "Monitoring products will be excluded from job invoice."
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-right" },
                                            [
                                              !props.item.isProductTitle
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "product-amount",
                                                      class: {
                                                        "red--text":
                                                          props.item
                                                            .isMonitoringProduct,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.getFormatedCurrency(
                                                              props.item.amount
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                241298344
                              ),
                            },
                            [
                              _c("template", { slot: "footer" }, [
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("strong", [_vm._v("Total")]),
                                ]),
                                _c("td", { staticClass: "text-xs-right" }, [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormatedCurrency(
                                          _vm.getTotalProductPrice
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                              ]),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "text-xs-center pt-2" }, [
                            _vm._v("No data available."),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.getCustomerToPay && _vm.getCustomerToPay.length > 0
            ? _c(
                "v-flex",
                { attrs: { xs12: "", "pa-3": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("h3", { staticClass: "pb-2" }, [
                            _vm._v("Customer To Pay"),
                          ]),
                          _c("v-divider"),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mt-2": "" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "gridView elevation-1 ctptable",
                              attrs: {
                                headers: _vm.ctpHeaders,
                                items: _vm.getCustomerToPay,
                                "hide-actions": "",
                                width: "500",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "items",
                                    fn: function (props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getFormattedDate(
                                                  props.item.createdAt
                                                )
                                              )
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-right" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getFormatedCurrency(
                                                      props.item.amount
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            {
                                              staticClass: "text-xs-right",
                                              style:
                                                "color:" +
                                                (props.item
                                                  .isTransactionCompleted
                                                  ? "green"
                                                  : "red"),
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item
                                                      .isTransactionCompleted
                                                      ? "Yes"
                                                      : "No"
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2776003287
                              ),
                            },
                            [
                              _c("template", { slot: "footer" }, [
                                _c("td", [_c("strong", [_vm._v("Total")])]),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-right pr-2" },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFormatedCurrency(
                                            _vm.getTotalCTPAmount
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-xs-right pr-2" },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getFormatedCurrency(
                                            _vm.getTotalPaidAmount
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "", "pa-2": "", "text-xs-right": "" } },
            [
              _c("v-divider"),
              !_vm.job.cancellationReason
                ? _c(
                    "v-btn",
                    {
                      staticClass: "red white--text btn-not-ready-to-close",
                      attrs: {
                        disabled:
                          _vm.isLoading || _vm.notReadyToCloseJobLoading,
                        loading: _vm.notReadyToCloseJobLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeJobStatusToNotReadyToClose()
                        },
                      },
                    },
                    [_vm._v(" Not Ready to Close ")]
                  )
                : _vm._e(),
              _vm.job.cancellationReason
                ? _c(
                    "v-btn",
                    {
                      staticClass: "red white--text btn-cancel",
                      attrs: {
                        disabled:
                          _vm.isLoading || _vm.notReadyToCloseJobLoading,
                        loading: _vm.isLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCompleteJobConfirm("Cancelled")
                        },
                      },
                    },
                    [_vm._v(" Cancel ")]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "green white--text btn-complete",
                      attrs: {
                        disabled:
                          _vm.isLoading || _vm.notReadyToCloseJobLoading,
                        loading: _vm.isLoading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onCompleteJobConfirm("Completed")
                        },
                      },
                    },
                    [_vm._v(" Complete ")]
                  ),
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: _vm.saveSnackbarTimeout,
                bottom: true,
                left: true,
              },
              model: {
                value: _vm.saveSnackbar,
                callback: function ($$v) {
                  _vm.saveSnackbar = $$v
                },
                expression: "saveSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.saveSnackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }