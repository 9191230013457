var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.isSearchValueNotSet },
                    expression: "{ required: isSearchValueNotSet }",
                  },
                ],
                attrs: {
                  "data-vv-scope": _vm.validationScope,
                  "data-vv-name": "Forename",
                  label: "Forename",
                  maxlength: "50",
                  name: "Forename",
                },
                model: {
                  value: _vm.policySearch.forename,
                  callback: function ($$v) {
                    _vm.$set(_vm.policySearch, "forename", $$v)
                  },
                  expression: "policySearch.forename",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.isSearchValueNotSet },
                    expression: "{ required: isSearchValueNotSet }",
                  },
                ],
                attrs: {
                  "data-vv-scope": _vm.validationScope,
                  "data-vv-name": "Surname",
                  label: "Surname",
                  maxlength: "50",
                  name: "Surname",
                },
                model: {
                  value: _vm.policySearch.surname,
                  callback: function ($$v) {
                    _vm.$set(_vm.policySearch, "surname", $$v)
                  },
                  expression: "policySearch.surname",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.isSearchValueNotSet },
                    expression: "{ required: isSearchValueNotSet }",
                  },
                ],
                attrs: {
                  "data-vv-name": "PolicyNumber",
                  "data-vv-scope": _vm.validationScope,
                  label: "Policy Number",
                  maxlength: "50",
                  name: "PolicyNumber",
                },
                model: {
                  value: _vm.policySearch.policyNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.policySearch, "policyNumber", $$v)
                  },
                  expression: "policySearch.policyNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm6: "" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: _vm.isSearchValueNotSet,
                      regex:
                        "^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{0,2}|[Gg][Ii][Rr] ?0[0-9][A-Za-z]{2})$",
                    },
                    expression:
                      "{\n          required: isSearchValueNotSet,\n          regex: '^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{0,2}|[Gg][Ii][Rr] ?0[0-9][A-Za-z]{2})$',\n        }",
                  },
                ],
                attrs: {
                  "data-vv-name": "Postcode",
                  "data-vv-scope": _vm.validationScope,
                  "error-messages": _vm.errorMessage("Postcode"),
                  label: "Postcode",
                  maxlength: "50",
                  name: "Postcode",
                },
                model: {
                  value: _vm.policySearch.postcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.policySearch, "postcode", $$v)
                  },
                  expression: "policySearch.postcode",
                },
              }),
            ],
            1
          ),
          _c(
            "v-flex",
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: _vm.isSearchValueNotSet },
                    expression: "{ required: isSearchValueNotSet }",
                  },
                ],
                attrs: {
                  "data-vv-name": "AddressLine1",
                  "data-vv-scope": _vm.validationScope,
                  label: "Address Line 1",
                  maxlength: "50",
                  name: "AddressLine1",
                },
                model: {
                  value: _vm.policySearch.addressLine1,
                  callback: function ($$v) {
                    _vm.$set(_vm.policySearch, "addressLine1", $$v)
                  },
                  expression: "policySearch.addressLine1",
                },
              }),
            ],
            1
          ),
          _c("v-flex", { attrs: { xs12: "" } }, [
            _vm.isSearchCriteriaRequired
              ? _c(
                  "span",
                  {
                    staticClass: "red--text",
                    attrs: { name: "SearchCriteriaError" },
                  },
                  [_vm._v("Please enter data into at least one field ")]
                )
              : _vm._e(),
          ]),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    name: "PolicySearch",
                    color: "primary",
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.submitPolicySearch },
                },
                [_vm._v("Search For Policy")]
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.policies.length
                ? _c(
                    "h3",
                    { attrs: { name: "CustomPolicySearchResultHeader" } },
                    [_vm._v("Policies (" + _vm._s(_vm.policies.length) + ")")]
                  )
                : _vm._e(),
              _vm._l(_vm.policies, function (item, index) {
                return _c(
                  "v-card",
                  {
                    key: `customPolicyResult-${index}`,
                    staticClass: "my-3 policy-item",
                    on: {
                      click: function ($event) {
                        return _vm.selectPolicy(item)
                      },
                    },
                  },
                  [
                    _c("v-card-title", { staticClass: "py-1" }, [
                      _vm._v(_vm._s(item.customerName) + " - "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(item.policyNumber)),
                      ]),
                    ]),
                    _c("v-card-text", [
                      _vm._v(
                        _vm._s(item.customerAddressLine1) +
                          " - " +
                          _vm._s(item.customerPostcode)
                      ),
                    ]),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm.assumedCoverResults.length
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "h3",
                    { attrs: { name: "AssumedCoverSearchResultHeader" } },
                    [
                      _vm._v(
                        "Default Policies (" +
                          _vm._s(_vm.assumedCoverResults.length) +
                          ")"
                      ),
                    ]
                  ),
                  _vm._l(_vm.assumedCoverResults, function (item, index) {
                    return _c(
                      "v-card",
                      {
                        key: `assumedCoverResult-${index}`,
                        staticClass: "my-3 policy-item",
                        on: {
                          click: function ($event) {
                            return _vm.selectAssumedCoverPolicy(item)
                          },
                        },
                      },
                      [
                        _c("v-card-title", { staticClass: "py-1" }, [
                          _vm._v(_vm._s(item.name) + " - "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(" " + _vm._s(item.id)),
                          ]),
                        ]),
                        _c(
                          "v-card-text",
                          { staticClass: "text-uppercase text-orange" },
                          [_vm._v(_vm._s(item.message))]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }