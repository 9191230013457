var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.contractorAppointment
    ? _c("v-layout", [_vm._v("No appointed contractor details found")])
    : _c(
        "v-layout",
        { staticClass: "wrapper", attrs: { row: "", "fill-height": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "", xs6: "" } },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    { staticClass: "ml-4 mb-2" },
                    [
                      _c(
                        "v-layout",
                        { staticClass: "pa-1", attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Notifications"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.contractorAppointment.notifications,
                        function (notification, index) {
                          return _c(
                            "v-layout",
                            {
                              key: `${notification.type}-${index}`,
                              staticClass: "px-1",
                              attrs: { row: "" },
                            },
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(notification.type) +
                                      " Sent At: "
                                  ),
                                ]),
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatDateTime(notification.date)
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "v-layout",
                        { staticClass: "pa-1", attrs: { row: "" } },
                        [
                          _c("v-flex", { attrs: { xs6: "" } }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Status:"),
                            ]),
                          ]),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "v-chip",
                                {
                                  attrs: {
                                    small: "",
                                    color: _vm.statusColor,
                                    "text-color": "white",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.formattedStatusText))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isAcceptedOrRejected(_vm.contractorAppointment)
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-layout",
                                { staticClass: "pa-1", attrs: { row: "" } },
                                [
                                  _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formattedStatusText) +
                                            " At:"
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.formatDateTime(
                                            _vm.contractorAppointment.updatedAt
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-layout",
                                { staticClass: "pa-1", attrs: { row: "" } },
                                [
                                  _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.formattedStatusText) +
                                            " By:"
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("v-flex", { attrs: { xs6: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.contractorAppointment
                                            .updatedByUserName
                                        )
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      Boolean(_vm.contractorAppointment.updateReasonDescription)
                        ? _c(
                            "v-layout",
                            { staticClass: "pa-1", attrs: { row: "" } },
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.formattedStatusText) +
                                        " Reason:"
                                    ),
                                  ]
                                ),
                              ]),
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.contractorAppointment
                                        .updateReasonDescription
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }