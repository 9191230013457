var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.job.jobCompletedBy
    ? _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.job.cancellationReason ? "Cancelled by" : "Completed by"
            ) +
            " "
        ),
        _c("b", { staticClass: "secondary--text" }, [
          _vm._v(_vm._s(_vm.getUserName(_vm.job.jobCompletedBy))),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }