var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-form",
        [
          _c(
            "v-card",
            { staticClass: "mb-3 enablement" },
            [
              _c(
                "v-card-title",
                { staticClass: "pb-0" },
                [
                  _c(
                    "v-layout",
                    [
                      _c("v-flex", { attrs: { xs11: "" } }, [
                        _c("h3", [_vm._v("IVS Detail")]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs1: "", "text-xs-right": "" } },
                        [
                          _vm.syncing
                            ? _c(
                                "v-icon",
                                {
                                  staticClass: "processing-icon",
                                  attrs: { medium: "" },
                                },
                                [_vm._v("sync")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: {
                                    medium: "",
                                    dark: "",
                                    color: "success",
                                  },
                                },
                                [_vm._v("check_circle")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Notes",
                              "data-vv-name": "Notes",
                              name: "Notes",
                              disabled: _vm.disableControls,
                            },
                            on: { focus: _vm.editStarted },
                            model: {
                              value: _vm.localIVSDetailModel.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.localIVSDetailModel, "notes", $$v)
                              },
                              expression: "localIVSDetailModel.notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|min_value:0",
                                expression: "'required|min_value:0'",
                              },
                            ],
                            staticClass: "required",
                            attrs: {
                              label: "Cost",
                              type: "number",
                              disabled: _vm.disableControls,
                              "data-vv-name": "Cost",
                              name: "Cost",
                              "error-messages": _vm.errors.collect("Cost"),
                            },
                            model: {
                              value: _vm.localIVSDetailModel.cost,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.localIVSDetailModel,
                                  "cost",
                                  _vm._n($$v)
                                )
                              },
                              expression: "localIVSDetailModel.cost",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isIVSCompleted
                ? _c(
                    "v-card-actions",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "update-button",
                          class: !_vm.saving ? "green white--text" : "grey",
                          attrs: {
                            type: "submit",
                            loading: _vm.saving,
                            disabled: _vm.saving || _vm.syncing,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.saveNotes.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" update ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "confirm-button",
                          class: !_vm.saving ? "green white--text" : "grey",
                          attrs: {
                            type: "submit",
                            loading: _vm.saving,
                            disabled: _vm.saving || _vm.syncing,
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.editComplete.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Complete ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                attrs: {
                  color: "primary",
                  height: "3",
                  value: "15",
                  indeterminate: true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }