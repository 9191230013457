import { render, staticRenderFns } from "./DocumentTemplate.vue?vue&type=template&id=2be3b722&scoped=true"
import script from "./DocumentTemplate.vue?vue&type=script&lang=ts"
export * from "./DocumentTemplate.vue?vue&type=script&lang=ts"
import style0 from "./DocumentTemplate.vue?vue&type=style&index=0&id=2be3b722&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be3b722",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2be3b722')) {
      api.createRecord('2be3b722', component.options)
    } else {
      api.reload('2be3b722', component.options)
    }
    module.hot.accept("./DocumentTemplate.vue?vue&type=template&id=2be3b722&scoped=true", function () {
      api.rerender('2be3b722', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/DocumentTemplate.vue"
export default component.exports