var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "task-col grey lighten-3" },
    [
      _c(
        "div",
        { staticClass: "task-header d-flex" },
        [
          _c("h3", { class: _vm.record.headerClass }, [
            _vm._v(
              _vm._s(_vm.siJobStatus) + " (" + _vm._s(_vm.totalJobsCount) + ")"
            ),
          ]),
          _vm.isTestingHeader(_vm.siJobStatus) ||
          _vm.isNewHeader(_vm.siJobStatus)
            ? _c(
                "v-menu",
                {
                  staticClass: "status-filter",
                  attrs: {
                    bottom: "",
                    right: "",
                    "close-on-content-click": false,
                    "nudge-width": 240,
                    "offset-x": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ma-0 filter-btn",
                                  attrs: {
                                    slot: "activator",
                                    icon: "",
                                    color: "primary",
                                    flat: "",
                                    small: "",
                                  },
                                  slot: "activator",
                                },
                                on
                              ),
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "md-icon",
                                    class: _vm.getFilterClass(_vm.siJobStatus),
                                    attrs: {
                                      color: _vm.getFilterColor(
                                        _vm.siJobStatus
                                      ),
                                      dark: "",
                                    },
                                  },
                                  [_vm._v(" filter_list ")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1253734123
                  ),
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-2 pt-0" },
                    [
                      _vm.isTestingHeader(_vm.siJobStatus)
                        ? _c("v-select", {
                            staticClass: "visit-type-select",
                            attrs: {
                              placeholder: "Visit Type",
                              items: _vm.postVisitActionFilterList,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.postVisitActionFilterChange($event)
                              },
                            },
                            model: {
                              value: _vm.selectedPostVisitAction,
                              callback: function ($$v) {
                                _vm.selectedPostVisitAction = $$v
                              },
                              expression: "selectedPostVisitAction",
                            },
                          })
                        : _c("v-select", {
                            staticClass: "visit-type-select",
                            attrs: {
                              placeholder: "Delay",
                              items: _vm.delayReasonList,
                              "item-text": "description",
                              "item-value": "description",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.postDelayReasonFilterChange($event)
                              },
                            },
                            model: {
                              value: _vm.selectedDelayReason,
                              callback: function ($$v) {
                                _vm.selectedDelayReason = $$v
                              },
                              expression: "selectedDelayReason",
                            },
                          }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-0 mx-3 mb-2" }),
      _vm.record.items.length
        ? _c(
            "div",
            { staticClass: "task-body px-3 pb-3 pt-1" },
            _vm._l(_vm.record.items, function (item, index) {
              return _c(
                "v-card",
                { key: index },
                [
                  _c(
                    "JobSummaryCard",
                    {
                      key: index,
                      attrs: {
                        job: item,
                        "title-generator": _vm.titleGenerator,
                      },
                      on: { redirectToJob: _vm.onRedirectToJob },
                    },
                    [
                      _c(
                        "div",
                        {
                          attrs: { slot: "JobCardDetails" },
                          slot: "JobCardDetails",
                        },
                        [
                          _c("div", { staticClass: "primary-title" }, [
                            _c(
                              "h4",
                              { staticClass: "date-title" },
                              [
                                item.claimManagementCompany && !item.companyName
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass:
                                              "claim-management-icon",
                                            attrs: { color: "secondary" },
                                          },
                                          [_vm._v("business")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "ml-1 pl-4",
                                            attrs: {
                                              href: "javascript:void(0)",
                                              name: "companyName-" + item.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.openSIJobDetailModal(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.claimManagementCompany
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.visitNumber > 1
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      staticClass:
                                                        "return-visit",
                                                      attrs: {
                                                        overlap: "",
                                                        color: "grey darken-2",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "badge",
                                                            small: "",
                                                          },
                                                          slot: "badge",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.visitNumber -
                                                                1
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        _vm._g({}, on),
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: "/img/return-visit.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Return Visit")])]
                                    )
                                  : _vm._e(),
                                item.companyName
                                  ? _c("span", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "javascript:void(0)",
                                            name: "companyName-" + item.id,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.openSIJobDetailModal(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.companyName) + " "
                                          ),
                                        ]
                                      ),
                                      !item.engineerName
                                        ? _c(
                                            "span",
                                            { staticClass: "primary--text" },
                                            [
                                              _vm._v(" ( "),
                                              _c(
                                                "v-menu",
                                                {
                                                  attrs: {
                                                    attach: "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "a",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    href: "javascript:void(0)",
                                                                    name:
                                                                      "contractor-" +
                                                                      item.id,
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "secondary",
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "call"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                _vm._l(
                                                  item.contactNumbers,
                                                  function (
                                                    contactNumber,
                                                    numberIndex
                                                  ) {
                                                    return _c(
                                                      "v-list",
                                                      { key: numberIndex },
                                                      [
                                                        _c(
                                                          "v-list-tile",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text cursor-pointer",
                                                                    attrs: {
                                                                      name:
                                                                        "contractor-" +
                                                                        numberIndex +
                                                                        "-" +
                                                                        item.id,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          return _vm.onCallIconClick(
                                                                            item,
                                                                            numberIndex
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "call"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          contactNumber.phoneNumber
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "primary--text",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          contactNumber.label
                                                                            ? "(" +
                                                                                contactNumber.label +
                                                                                ")"
                                                                            : ""
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        numberIndex !==
                                                        item.contactNumbers
                                                          .length -
                                                          1
                                                          ? _c("v-divider")
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v(" ) "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.isNewHeader(_vm.siJobStatus) && item.delayCode
                              ? _c(
                                  "span",
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        attrs: { color: "red" },
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("av_timer")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [_c("span", [_vm._v("Delay")])]
                                    ),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: "",
                                          disabled: !item.delayNotes,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c("span", _vm._g({}, on), [
                                                    _vm._v(
                                                      _vm._s(item.delayCode)
                                                    ),
                                                  ]),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(item.delayNotes)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _vm.isTesting(item)
                            ? _c(
                                "div",
                                { staticClass: "bottom-row" },
                                [
                                  _vm._l(
                                    item.itemsToVerify,
                                    function (itemType, ind) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: ind + "i",
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    !_vm.isProductTypeVerified(
                                                      item,
                                                      itemType
                                                    )
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                dark: "",
                                                                color: "orange",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                dark: "",
                                                                color:
                                                                  "success",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "check_circle"
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.itemTypeTitle(itemType)
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._l(
                                    item.requiredTestingDocuments,
                                    function (docType, ind) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: ind,
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    !_vm.testingDocumentStatus(
                                                      item,
                                                      docType
                                                    )
                                                      ? _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                dark: "",
                                                                color: "orange",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [_vm._v("info")]
                                                        )
                                                      : _c(
                                                          "v-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                dark: "",
                                                                color:
                                                                  "success",
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "check_circle"
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.documentTypeTitle(docType)
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : item.engineerName || item.engineerContactNumber
                            ? _c("div", { staticClass: "bottom-row" }, [
                                _c("span", { staticClass: "img-text" }, [
                                  _vm._v(" " + _vm._s(item.engineerName) + " "),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "primary--text" },
                                  [
                                    _vm._v(" ( "),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { attach: "", "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "a",
                                                    _vm._g(
                                                      {
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                          name:
                                                            "engineer-" +
                                                            item.id,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "secondary",
                                                            small: "",
                                                          },
                                                        },
                                                        [_vm._v("call")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-tile",
                                              [
                                                _c("v-list-tile-title", [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "primary--text cursor-pointer",
                                                      attrs: {
                                                        name:
                                                          "engineer-" + item.id,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.onCallIconClick(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("call"),
                                                      ]),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.engineerContactNumber
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" ) "),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          item.visitStartedAt && item.visitStartedAt.isValid()
                            ? _c(
                                "div",
                                { staticClass: "bottom-date" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("date_range")]
                                  ),
                                  _c("span", [_vm._v("Job Start:")]),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getETADateFormat(
                                          item.visitStartedAt
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          )
        : _c("div", { staticClass: "unavailable-job-msg" }, [
            _c("span", [_vm._v("Data not available")]),
          ]),
      _c("PartialJobView", {
        attrs: { "job-id": _vm.selectedJobIdToExpand },
        on: { closeJobView: _vm.closeJobView },
      }),
      _vm.siJobDetailDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "1000",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.siJobDetailDialog,
                callback: function ($$v) {
                  _vm.siJobDetailDialog = $$v
                },
                expression: "siJobDetailDialog",
              },
            },
            [
              _c("SIJobDetail", {
                ref: "SIJobDetailRef",
                attrs: { "job-id": _vm.selectedJobId },
                on: { closeSIJobDetailDialog: _vm.closeSIJobDetailDialog },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }