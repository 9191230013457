var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "gridView", attrs: { sm12: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "filter-date",
                                  attrs: { "pr-3": "" },
                                },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "directory-list",
                                    attrs: {
                                      items: _vm.directories,
                                      label: "Select Month",
                                    },
                                    on: { change: _vm.onDirectoryChange },
                                    model: {
                                      value: _vm.searchDirectory,
                                      callback: function ($$v) {
                                        _vm.searchDirectory = $$v
                                      },
                                      expression: "searchDirectory",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "filter-date",
                                  attrs: { "pr-3": "" },
                                },
                                [
                                  _c("DateTimePicker", {
                                    ref: "dateTimePickerFrom",
                                    attrs: {
                                      "date-time": _vm.searchFromDateTime,
                                      "is-static-location": false,
                                      "place-holder-text": "From",
                                      "is-current-time": false,
                                      "is-validation-required": false,
                                      "show-present-time-icon": false,
                                      "min-date": _vm.minDate,
                                      "max-date": _vm.maxDate,
                                    },
                                    on: {
                                      "update:dateTime": function ($event) {
                                        _vm.searchFromDateTime = $event
                                      },
                                      "update:date-time": function ($event) {
                                        _vm.searchFromDateTime = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.searchFromDateTime,
                                      callback: function ($$v) {
                                        _vm.searchFromDateTime = $$v
                                      },
                                      expression: "searchFromDateTime",
                                    },
                                  }),
                                  _vm.searchFromDateTime
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cleardate clear-from-date",
                                          on: { click: _vm.clearFromDate },
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "filter-date",
                                  attrs: { "px-3": "" },
                                },
                                [
                                  _c("DateTimePicker", {
                                    ref: "dateTimePickerTo",
                                    attrs: {
                                      "date-time": _vm.searchToDateTime,
                                      "is-static-location": false,
                                      "place-holder-text": "To",
                                      "is-current-time": false,
                                      "is-validation-required": false,
                                      "show-present-time-icon": false,
                                      "min-date": _vm.minSearchToDate,
                                      "max-date": _vm.maxDate,
                                    },
                                    on: {
                                      "update:dateTime": function ($event) {
                                        _vm.searchToDateTime = $event
                                      },
                                      "update:date-time": function ($event) {
                                        _vm.searchToDateTime = $event
                                      },
                                    },
                                    model: {
                                      value: _vm.searchToDateTime,
                                      callback: function ($$v) {
                                        _vm.searchToDateTime = $$v
                                      },
                                      expression: "searchToDateTime",
                                    },
                                  }),
                                  _vm.searchToDateTime
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cleardate clear-to-date",
                                          on: { click: _vm.clearToDate },
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { "px-3": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "append-icon": "search",
                                      label: "Search",
                                      "single-line": "",
                                      name: "search-value",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function ($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", "mt-2": "" } },
                        [
                          _c("v-data-table", {
                            staticClass: "unlinked-calls-table elevation-1",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.unlinkedPhoneCallsItems,
                              loading: _vm.isLoading,
                              search: _vm.searchCriteria,
                              "custom-filter": _vm.customFilter,
                              "item-key": "name",
                              pagination: _vm.paginationLocal,
                            },
                            on: {
                              "update:pagination": function ($event) {
                                _vm.paginationLocal = $event
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "items",
                                fn: function (props) {
                                  return [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass:
                                            "recording-img text-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "img-responsive mt-1",
                                            attrs: {
                                              src: _vm.getFileExtention(
                                                props.item.recordingId
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFormattedDateTime(
                                              props.item.callDateTime
                                            )
                                          )
                                        ),
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.from)),
                                      ]),
                                      _c("td", [_vm._v(_vm._s(props.item.to))]),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.calculateMinutes(
                                              props.item.duration
                                            )
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-center" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.item.policyScheduleName
                                                  ? props.item
                                                      .policyScheduleName
                                                  : "-"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-center" },
                                        [
                                          _vm.canPlayCall
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { top: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "play-call",
                                                                  attrs: {
                                                                    icon: "",
                                                                  },
                                                                  nativeOn: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.playAudio(
                                                                          props
                                                                            .item
                                                                            .recordingUrl
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "play_circle_outline"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Play")])]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "assign-job",
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openAssignPopup(
                                                                      props.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "work_outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Assign")])]
                                          ),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "mark-as-noise",
                                                              attrs: {
                                                                icon: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.markAsNoiseButtonPress(
                                                                      props.item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "phonelink_erase"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Mark as Noise"),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.phonecallAssign
        ? _c(
            "v-dialog",
            {
              attrs: {
                "content-class": "v-dialog--scrollable",
                "max-width": "650",
                persistent: "",
              },
              model: {
                value: _vm.phonecallAssign,
                callback: function ($$v) {
                  _vm.phonecallAssign = $$v
                },
                expression: "phonecallAssign",
              },
            },
            [
              _c("PhoneCallJobsAssign", {
                attrs: {
                  "job-item-list": _vm.jobList,
                  "unlinked-phone-call": _vm.unlinkedPhoneCallDetail,
                  "is-data-loaded": _vm.isDataLoaded,
                },
                on: { onJobAssignClose: _vm.closeJobAssign },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }