var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "mb-1" }, [
        _c("label", [_vm._v("Emergency Name: ")]),
        _c("span", [_vm._v(_vm._s(_vm.emergencyName))]),
      ]),
      _vm._l(_vm.item.contractorIds, function (contractorId, index) {
        return _c("div", { key: index, staticClass: "mb-1" }, [
          _vm.companyName(contractorId)
            ? _c("label", [_vm._v("Company Name: ")])
            : _vm._e(),
          _c("span", [_vm._v(_vm._s(_vm.companyName(contractorId)))]),
        ])
      }),
      _c("div", { staticClass: "mb-1" }, [
        _c("label", [_vm._v("Started At: ")]),
        _c("span", [
          _vm._v(
            _vm._s(_vm.getFormattedDate(_vm.item.autoDeploymentStartedAt))
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "mb-2" }, [
      _c("h4", [_vm._v("Engineer Auto Deployment")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }