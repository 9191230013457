var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      !_vm.isAddUpdateMode && _vm.cctvControlDistanceLocal
        ? _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Distance (m):")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlDistanceLocal.distance)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Code:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlDistanceLocal.code)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Clock Ref At:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlDistanceLocal.clockRefAt)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Clock Ref To:")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.cctvControlDistanceLocal.clockRefTo)),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Intrusion (%):")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.cctvControlDistanceLocal.intrusionInPercentage
                            ) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xl4: "", lg6: "", xs12: "", "mb-1": "" } },
                    [
                      _c("label", [_c("b", [_vm._v("Intrusion (mm):")])]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          _vm._s(_vm.cctvControlDistanceLocal.intrusionInMM)
                        ),
                      ]),
                    ]
                  ),
                  _c("v-flex", { attrs: { xs12: "", "mb-1": "" } }, [
                    _c("label", [_c("b", [_vm._v("Remarks:")])]),
                    _c("span", { staticClass: "ml-1" }, [
                      _vm._v(_vm._s(_vm.cctvControlDistanceLocal.remarks)),
                    ]),
                  ]),
                  _vm.getSnapshotUrls.length > 0
                    ? _c(
                        "v-flex",
                        {
                          staticClass: "defectsnapshotImage",
                          attrs: { xs12: "", "mb-1": "" },
                        },
                        [
                          _c("label", [_c("b", [_vm._v("Defect Snapshot:")])]),
                          _c("PictureUpload", {
                            attrs: {
                              "job-id": _vm.jobId,
                              "picture-list": _vm.getSnapshotUrls,
                              "show-claim-thumb": false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isAddUpdateMode
        ? _c(
            "v-flex",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pr-1": "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: {
                              rules: {
                                required: true,
                                decimal: 2,
                                min_value: _vm.getMinDistanceValue,
                              },
                            },
                            expression:
                              "{\n            rules: {\n              required: true,\n              decimal: 2,\n              min_value: getMinDistanceValue,\n            },\n          }",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          name: "Distance",
                          label: "Distance (m)",
                          required: "",
                          "data-vv-name": "Distance",
                          "error-messages": _vm.errors.collect("Distance"),
                        },
                        on: {
                          keypress: function ($event) {
                            return _vm.numberKeyValidation($event)
                          },
                        },
                        model: {
                          value: _vm.cctvControlDistanceLocal.distance,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "distance",
                              _vm._n($$v)
                            )
                          },
                          expression: "cctvControlDistanceLocal.distance",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pl-1": "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.codes
                              ? _vm.cctvLogConfiguration.codes
                              : [],
                          label: "Code",
                          required: "",
                          "data-vv-name": "Code",
                          "error-messages": _vm.errors.collect("Code"),
                        },
                        model: {
                          value: _vm.cctvControlDistanceLocal.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.cctvControlDistanceLocal, "code", $$v)
                          },
                          expression: "cctvControlDistanceLocal.code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.clockRefAtTo
                              ? _vm.cctvLogConfiguration.clockRefAtTo
                              : [],
                          label: "Clock Ref At",
                        },
                        model: {
                          value: _vm.cctvControlDistanceLocal.clockRefAt,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "clockRefAt",
                              $$v
                            )
                          },
                          expression: "cctvControlDistanceLocal.clockRefAt",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pl-1": "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.clockRefAtTo
                              ? _vm.cctvLogConfiguration.clockRefAtTo
                              : [],
                          label: "Clock Ref To",
                        },
                        model: {
                          value: _vm.cctvControlDistanceLocal.clockRefTo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "clockRefTo",
                              $$v
                            )
                          },
                          expression: "cctvControlDistanceLocal.clockRefTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pr-1": "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.intrusionInPercentage
                              ? _vm.cctvLogConfiguration.intrusionInPercentage
                              : [],
                          label: "Intrusion(%)",
                        },
                        model: {
                          value:
                            _vm.cctvControlDistanceLocal.intrusionInPercentage,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "intrusionInPercentage",
                              $$v
                            )
                          },
                          expression:
                            "cctvControlDistanceLocal.intrusionInPercentage",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs4: "", "pl-1": "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items:
                            _vm.cctvLogConfiguration &&
                            _vm.cctvLogConfiguration.intrusionInMM
                              ? _vm.cctvLogConfiguration.intrusionInMM
                              : [],
                          label: "Intrusion(MM)",
                        },
                        model: {
                          value: _vm.cctvControlDistanceLocal.intrusionInMM,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "intrusionInMM",
                              $$v
                            )
                          },
                          expression: "cctvControlDistanceLocal.intrusionInMM",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "pl-1": "" } },
                    [
                      _c("v-textarea", {
                        attrs: { rows: "3", label: "Remarks" },
                        model: {
                          value: _vm.cctvControlDistanceLocal.remarks,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cctvControlDistanceLocal,
                              "remarks",
                              $$v
                            )
                          },
                          expression: "cctvControlDistanceLocal.remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }