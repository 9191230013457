var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "bar",
          rawName: "v-bar",
          value: { useScrollbarPseudo: true },
          expression: "{ useScrollbarPseudo: true }",
        },
      ],
      class: _vm.activeCardIndex > -1 ? "side-bar-active" : "vb",
      staticStyle: { height: "100%" },
    },
    [
      _c(
        "v-layout",
        { staticClass: "pr-4 additional-chips", attrs: { wrap: "" } },
        [
          _vm._l(_vm.cardList, function (card, i) {
            return [
              _vm.displaySelectedEmergencyCard(card)
                ? _c(
                    "TimeLineCard",
                    {
                      key: i,
                      attrs: {
                        model: card,
                        selected: _vm.activeCardIndex === i,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.timeLineCardClicked(i)
                        },
                      },
                    },
                    [
                      card.cardAdditionalChipsComponent
                        ? _c(
                            "template",
                            { slot: "additional-chips" },
                            [
                              _c(card.cardAdditionalChipsComponent, {
                                tag: "component",
                                attrs: {
                                  "job-id": card.forJobId,
                                  "emergency-id": card.forEmergencyId,
                                  "item-id": card.forItemId,
                                },
                                on: {
                                  cardClassChanged: (nc) =>
                                    _vm.cardClassChanged(i, nc),
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "template",
                        { slot: "body" },
                        [
                          _c(card.cardBodyComponent, {
                            tag: "component",
                            attrs: {
                              "job-id": card.forJobId,
                              "emergency-id": card.forEmergencyId,
                              "item-id": card.forItemId,
                              "card-index": i,
                            },
                            on: { cardClicked: _vm.timeLineCardClicked },
                          }),
                        ],
                        1
                      ),
                      card.cardFooterComponent
                        ? _c(
                            "template",
                            { slot: "footer" },
                            [
                              _c(card.cardFooterComponent, {
                                tag: "component",
                                attrs: {
                                  "job-id": card.forJobId,
                                  "emergency-id": card.forEmergencyId,
                                  "item-id": card.forItemId,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }