var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.item
        ? _c("v-flex", [
            _c("div", { staticClass: "small-text item-description" }, [
              _vm._v(
                _vm._s(_vm.item.subject) +
                  " (" +
                  _vm._s(_vm.documentTemplate) +
                  ")"
              ),
            ]),
            _vm.item.sentToNominee &&
            _vm.job.nomineeClientTitle &&
            _vm.job.nomineeClientForename &&
            _vm.job.nomineeClientLastName
              ? _c("div", { staticClass: "my-1" }, [
                  _vm._v(" Notification has been sent on "),
                  _c("b", [_vm._v(_vm._s(_vm.item.to))]),
                  _vm._v(" to Nominee "),
                  _c("b", [_vm._v(_vm._s(_vm.job.nomineeClientFullName))]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }