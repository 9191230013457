var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dashboard fill-height lighten-3 dynamic-dashboard",
      class: _vm.isEditMode ? "open-sidebar" : "",
    },
    [
      _vm.dashboardId && _vm.ready
        ? _c(
            "span",
            { staticClass: "dashboard-edit" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "elevation-1",
                  class: _vm.isEditMode ? "hide" : "show",
                  attrs: {
                    color: "primary",
                    disabled: _vm.isLoading,
                    large: "",
                    icon: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toggleEditMode(_vm.isEditMode)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("edit")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-container",
        {
          staticClass: "drop-dontant",
          attrs: { fluid: "", "pa-0": "", "fill-height": "" },
        },
        [
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ready,
                  expression: "ready",
                },
              ],
              attrs: { wrap: "" },
            },
            [
              _c(
                "div",
                { staticClass: "sidebar" },
                [
                  _c(
                    "div",
                    { staticClass: "dashboard-drag-search" },
                    [
                      _vm.dashboardId
                        ? _c(
                            "v-card-title",
                            {
                              staticClass: "sidebar-header grey lighten-4 py-2",
                            },
                            [
                              !_vm.updateDashboardName
                                ? _c("h3", { staticClass: "grey--text" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.dashboardName) + " "
                                    ),
                                  ])
                                : _c("v-text-field", {
                                    attrs: {
                                      id: "dashboardName",
                                      placeholder: "Dashboard Name",
                                      "hide-details": true,
                                    },
                                    model: {
                                      value: _vm.dashboardName,
                                      callback: function ($$v) {
                                        _vm.dashboardName = $$v
                                      },
                                      expression: "dashboardName",
                                    },
                                  }),
                              !_vm.updateDashboardName
                                ? _c("v-spacer")
                                : _vm._e(),
                              !_vm.updateDashboardName
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.updateDashboardName = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "primary--text",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("edit")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.updateDashboardName
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-0",
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.updateDashboardName = false
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "primary--text",
                                          attrs: { small: "" },
                                        },
                                        [_vm._v("close")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "px-3" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              id: "testing",
                              name: "input-1",
                              label: "Search",
                              "append-icon": "search",
                            },
                            model: {
                              value: _vm.searchInput,
                              callback: function ($$v) {
                                _vm.searchInput = $$v
                              },
                              expression: "searchInput",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-navigation-drawer",
                    {
                      attrs: {
                        stateless: "",
                        value: "true",
                        "hide-overlay": true,
                      },
                    },
                    [
                      _vm._l(
                        _vm.filteredDynamicElementsList,
                        function (element, index) {
                          return _c(
                            "v-list",
                            { key: index },
                            [
                              _c(
                                "v-list-tile",
                                {
                                  class: _vm.checkIsElementExist(element.id)
                                    ? "compnent-active grey lighten-3"
                                    : _vm.isLoading
                                    ? "disable-list-tile"
                                    : "",
                                  attrs: {
                                    disabled:
                                      _vm.currentElementId === element.id &&
                                      _vm.isLoading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addDynamicElement(element)
                                    },
                                  },
                                },
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(element.text)),
                                  ]),
                                  _vm.currentElementId === element.id &&
                                  _vm.isLoading
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          indeterminate: "",
                                          width: 3,
                                          color: "primary",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.checkIsElementExist(element.id)
                                    ? _c("v-icon", [_vm._v("check")])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm.filteredDynamicElementsList.length === 0
                        ? _c("span", { staticClass: "empty-data" }, [
                            _vm._v("No Data Found"),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-xs-right sidebar-actions grey lighten-4 pt-2",
                    },
                    [
                      _vm.isEditMode
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled:
                                  _vm.isLoading || !_vm.isElementsExists,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.dashboardDeleteConfiramtion = true
                                },
                              },
                            },
                            [_vm._v(" Delete ")]
                          )
                        : _vm._e(),
                      _vm.isEditMode
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled:
                                  _vm.isLoading || !_vm.isElementsExists,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleEditMode(_vm.isEditMode)
                                },
                              },
                            },
                            [_vm._v(" Save ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", "fill-height": "" } },
                [
                  _c(
                    "grid-layout",
                    {
                      staticClass: "grey lighten-5",
                      attrs: {
                        layout: _vm.dynamicLayout,
                        "col-num": 12,
                        "row-height": 30,
                        "is-draggable": _vm.isEditMode,
                        "is-resizable": false,
                        "is-mirrored": false,
                        "vertical-compact": false,
                        margin: [15, 14],
                        "use-css-transforms": false,
                      },
                      on: {
                        "update:layout": function ($event) {
                          _vm.dynamicLayout = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { class: _vm.isEditMode ? "edit-mode-grid" : "" },
                        _vm._l(_vm.dynamicLayout, function (item, index) {
                          return _c(
                            "grid-item",
                            {
                              key: index,
                              staticClass: "dragble-box",
                              attrs: {
                                x: item.x,
                                y: item.y,
                                w: item.w,
                                h: item.h,
                                i: item.i,
                              },
                            },
                            [
                              (item.i ===
                                _vm.dynamicComponentsEnum.CallPickupRate ||
                                item.i ===
                                  _vm.dynamicComponentsEnum.CurrentCalls ||
                                item.i ===
                                  _vm.dynamicComponentsEnum
                                    .CurrentCallsInQueue ||
                                item.i ===
                                  _vm.dynamicComponentsEnum.LongestWait ||
                                item.i ===
                                  _vm.dynamicComponentsEnum
                                    .ContractorCallsInQueue) &&
                              item.elementBody
                                ? _c(
                                    "div",
                                    { staticClass: "card-small-tiles" },
                                    [
                                      _vm.isEditMode
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "close-tile elevation-1",
                                              attrs: { icon: "", small: "" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeDynamicElement(
                                                    item.i
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("close")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "DashboardTopTile",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "dashboard-tile-view":
                                                "DynamicDashboardSmallTile",
                                            },
                                          },
                                          "DashboardTopTile",
                                          _vm.topTiles[item.i],
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  )
                                : (item.i ===
                                    _vm.dynamicComponentsEnum
                                      .TotalOpenComplaint ||
                                    item.i ===
                                      _vm.dynamicComponentsEnum
                                        .TotalCloseComplaint ||
                                    item.i ===
                                      _vm.dynamicComponentsEnum
                                        .TotalOverdueAction ||
                                    item.i ===
                                      _vm.dynamicComponentsEnum
                                        .SettlementPaidMTD) &&
                                  item.elementBody
                                ? _c(
                                    "div",
                                    [
                                      _vm.isEditMode
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "close-tile elevation-1",
                                              attrs: { icon: "", small: "" },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.removeDynamicElement(
                                                    item.i
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("close")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "DashboardTopTile",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "dashboard-tile-view":
                                                "DynamicDashboardMediumTile",
                                            },
                                          },
                                          "DashboardTopTile",
                                          _vm.dashboardComplaintTopTiles[
                                            item.i
                                          ],
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .UndeployedJobHistoryTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      item.elementBody
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "white elevation-1 custom-table-layout pb-4 undeployed-job-gridview",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.ready
                                                ? _c(
                                                    "UndeployedEmergencyTable",
                                                    {
                                                      attrs: {
                                                        items: item.elementBody,
                                                        "table-name":
                                                          "undeployedEmergencyDashboard",
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .UnlinkedPhoneCallsTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      item.elementBody
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "white elevation-1 custom-table-layout pb-4 unlinked-phone-gridview",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.ready
                                                ? _c(
                                                    "UnlinkedPhoneCallsTable",
                                                    {
                                                      attrs: {
                                                        items: item.elementBody,
                                                        "table-name":
                                                          "unlinkedPhoneCallsDashboard",
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .ManagerSupervisorTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      item.elementBody
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "white elevation-1 custom-table-layout pb-4 manager-superviour-gridview large-size-row-table",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.ready
                                                ? _c("ManagerSupervisorTable", {
                                                    attrs: {
                                                      workers:
                                                        item.elementBody
                                                          .workers,
                                                      "table-name":
                                                        "manageSupervisorDashboard",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum.IncidentManagerTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      item.elementBody
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "white elevation-1 pa-3 custom-table-layout pb-4 incident-manager-gridview large-size-row-table",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              true
                                                ? _c("IncidentManagerTable", {
                                                    attrs: {
                                                      "table-name":
                                                        "incidentManagerDashboard",
                                                      "incident-manager-list-items":
                                                        item.elementBody,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .OutstandingComplaintsTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      item.elementBody
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "white elevation-1 custom-table-layout pb-4 pa-3 outstanding-complaints-gridview large-size-row-table",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("OutstandingComplaintsTable", {
                                                attrs: {
                                                  "table-name":
                                                    "outstandingComplaintsDashboard",
                                                  complaints:
                                                    _vm
                                                      .complaintListForDashBoard
                                                      .complaints,
                                                  "loading-complaint-list": false,
                                                  ready: _vm.ready,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum.JobHistoryTable
                                ? _c(
                                    "div",
                                    { staticClass: "table-layout-grid" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "white elevation-1 custom-table-layout pb-4 px-3 job-history-gridview",
                                        },
                                        [
                                          _vm.isEditMode
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "close-tile elevation-1",
                                                  attrs: {
                                                    icon: "",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeDynamicElement(
                                                        item.i
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("close")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          true
                                            ? _c("JobHistoryTable", {
                                                attrs: {
                                                  "table-name": "jobDashboard",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .TotalJobsLoggedInLast15Days
                                ? _c(
                                    "div",
                                    [
                                      item.elementBody
                                        ? _c(
                                            "v-flex",
                                            {
                                              staticClass:
                                                "dyanamic-dashboard-chart",
                                            },
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "elevation-1 blue white--text pa-0",
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "px-2 pt-1 pb-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.titleTotalJobsLoggedInLast15Days
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-card-text",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c("DashboardChart", {
                                                        attrs: {
                                                          "chart-data":
                                                            item.elementBody,
                                                          "chart-type":
                                                            _vm.chartTypeEnum[
                                                              _vm.chartTypeEnum
                                                                .Line
                                                            ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum.Top5Emergency
                                ? _c(
                                    "div",
                                    [
                                      item.elementBody
                                        ? _c(
                                            "v-flex",
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "v-card",
                                                {
                                                  staticClass:
                                                    "elevation-1 white white--grey pa-0 piechart-jobdeshboard",
                                                },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      staticClass:
                                                        "px-2 pt-2 pb-0 subheading",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.titleTop5Emergencies
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-card-text",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "chart-pie chart-top-info",
                                                        },
                                                        [
                                                          _c("canvas", {
                                                            ref: "chartElement",
                                                            refInFor: true,
                                                            attrs: {
                                                              id: "pieChartElement",
                                                              width: 50,
                                                              height: 33,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : item.i ===
                                  _vm.dynamicComponentsEnum
                                    .DashboardContractorPanel
                                ? _c(
                                    "div",
                                    [
                                      _vm.isEditMode
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "close-tile elevation-1",
                                              attrs: { icon: "", small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeDynamicElement(
                                                    item.i
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { small: "" } },
                                                [_vm._v("close")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "DashboardContractorPanel",
                                        _vm._b(
                                          {
                                            attrs: {
                                              "flex-class":
                                                "flex syetsm-dashboard system-info",
                                            },
                                          },
                                          "DashboardContractorPanel",
                                          _vm.contractorPanel,
                                          false
                                        )
                                      ),
                                    ],
                                    1
                                  )
                                : item.elementBody &&
                                  item.elementBody &&
                                  item.i ===
                                    _vm.getUniqueInsurerId(
                                      _vm.dynamicComponentsEnum
                                        .DashboardInsurerPanel,
                                      item.elementBody.insurerId
                                    )
                                ? _c(
                                    "div",
                                    [
                                      item.elementBody
                                        ? _c(
                                            "v-flex",
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "DashboardPanel",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "flex-class":
                                                        "flex syetsm-dashboard system-info",
                                                    },
                                                  },
                                                  "DashboardPanel",
                                                  _vm.panels[
                                                    item.elementBody.insurerId
                                                  ],
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : item.elementBody &&
                                  item.elementBody &&
                                  item.i === item.elementBody.clientTemplateId
                                ? _c(
                                    "div",
                                    [
                                      item.elementBody
                                        ? _c(
                                            "v-flex",
                                            [
                                              _vm.isEditMode
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "close-tile elevation-1",
                                                      attrs: {
                                                        icon: "",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeDynamicElement(
                                                            item.i
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("close")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "DashboardPanel",
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      "flex-class":
                                                        "flex syetsm-dashboard system-info",
                                                    },
                                                  },
                                                  "DashboardPanel",
                                                  _vm.clientPortalpanels[
                                                    item.elementBody
                                                      .clientTemplateId
                                                  ],
                                                  false
                                                )
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : item.i !== 0
                                ? _c("div", { staticClass: "error-block" }, [
                                    _c("div", [
                                      _c("span", [
                                        _vm._v("Something went wrong"),
                                      ]),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.ready,
                  expression: "!ready",
                },
              ],
            },
            [
              _c("v-progress-circular", {
                staticClass: "loading-spinner",
                attrs: {
                  width: 2,
                  size: 50,
                  indeterminate: "",
                  color: "primary",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "650",
            persistent: "",
            "content-class": "v-dialog--scrollable",
          },
          model: {
            value: _vm.showSaveDashboardDialog,
            callback: function ($$v) {
              _vm.showSaveDashboardDialog = $$v
            },
            expression: "showSaveDashboardDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { card: "", dark: "", color: "primary" } },
                [
                  _c("v-toolbar-title", [_vm._v("Save Dashboard")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showSaveDashboardDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                { staticClass: "px-3 scroll-content-dialog" },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Dashboard Title",
                      required: "",
                      "data-vv-scope": "dynamicDashboardform",
                      "data-vv-name": "Dashboard Title",
                      "error-messages": _vm.errors.collect("Dashboard Title"),
                    },
                    model: {
                      value: _vm.dashboardName,
                      callback: function ($$v) {
                        _vm.dashboardName =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "dashboardName",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                { staticClass: "px-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "flat" },
                      nativeOn: {
                        click: function ($event) {
                          _vm.showSaveDashboardDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-0",
                      attrs: {
                        color: "primary",
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.onSaveDashboard },
                    },
                    [_vm._v(" Submit ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showSelectInsurerOption
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showSelectInsurerOption,
                callback: function ($$v) {
                  _vm.showSelectInsurerOption = $$v
                },
                expression: "showSelectInsurerOption",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Select Insurer")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showSelectInsurerOption = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          items: _vm.insurerList,
                          "item-value": "id",
                          "item-text": "name",
                          label: "Select",
                          "return-object": "",
                          "data-vv-scope": "frmDynamicDashboard",
                          "data-vv-name": "Insurer",
                          "error-messages": _vm.errors.collect("Insurer"),
                        },
                        model: {
                          value: _vm.selectedInsurer,
                          callback: function ($$v) {
                            _vm.selectedInsurer = $$v
                          },
                          expression: "selectedInsurer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showSelectInsurerOption = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getInsurerPanelData(
                                _vm.selectedInsurer
                              )
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showSelectInsurerPortalOption
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "650",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.showSelectInsurerPortalOption,
                callback: function ($$v) {
                  _vm.showSelectInsurerPortalOption = $$v
                },
                expression: "showSelectInsurerPortalOption",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", dark: "", color: "primary" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Select Insurer Portal")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showSelectInsurerPortalOption = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-text",
                    { staticClass: "px-3 scroll-content-dialog" },
                    [
                      _c("v-autocomplete", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          items: _vm.insurerPortalList,
                          "item-value": "id",
                          "item-text": "subDomain",
                          label: "Select",
                          "return-object": "",
                          "data-vv-scope": "frmDynamicDashboardInsurerPortal",
                          "data-vv-name": "InsurerPortal",
                          "error-messages": _vm.errors.collect("InsurerPortal"),
                        },
                        model: {
                          value: _vm.selectedInsurerPortal,
                          callback: function ($$v) {
                            _vm.selectedInsurerPortal = $$v
                          },
                          expression: "selectedInsurerPortal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-card-actions",
                    { staticClass: "px-3" },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", flat: "flat" },
                          nativeOn: {
                            click: function ($event) {
                              _vm.showSelectInsurerPortalOption = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-0",
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getInsurerPortalPanelData(
                                _vm.selectedInsurerPortal
                              )
                            },
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.dashboardDeleteConfiramtion
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "425" },
              model: {
                value: _vm.dashboardDeleteConfiramtion,
                callback: function ($$v) {
                  _vm.dashboardDeleteConfiramtion = $$v
                },
                expression: "dashboardDeleteConfiramtion",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", { staticClass: "mb-3" }, [
                    _c("h3", [
                      _vm._v("Are you sure you want to delete a dashboard?"),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "flat" },
                          on: {
                            click: function ($event) {
                              _vm.dashboardDeleteConfiramtion = false
                            },
                          },
                        },
                        [_vm._v("cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.isLoading,
                            loading: _vm.isLoading,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.deleteDashboard()
                            },
                          },
                        },
                        [_vm._v("Delete")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }