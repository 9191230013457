var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showSearchbar()
    ? _c(
        "div",
        {
          class: {
            "header-search": _vm.parentComponent === "App",
          },
        },
        [
          _c(
            "div",
            { staticClass: "grow relative" },
            [
              _c("v-autocomplete", {
                ref: "jobHeaderSearch",
                attrs: {
                  items: _vm.searchItems,
                  flat: "",
                  "solo-inverted": _vm.parentComponent == "App" ? true : false,
                  "prepend-inner-icon": "search",
                  "append-icon": _vm.onSearchInputFocus ? "close" : "",
                  loading: _vm.searchRunning,
                  "browser-autocomplete": "off",
                  "search-input": _vm.searchValue,
                  "item-text": "displayValue",
                  "item-value": "id",
                  maxlength: "50",
                  filter: () => true,
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.searchValue = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.searchValue = $event
                  },
                  "click:append": _vm.onClearClick,
                  focus: _vm.onFocus,
                  blur: _vm.onBlur,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (data) {
                        return [
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.sanitize(
                                      _vm.highlightPhrase(
                                        _vm.searchValue,
                                        data.item.displayValue
                                      )
                                    )
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  611140175
                ),
                model: {
                  value: _vm.selectedSearchId,
                  callback: function ($$v) {
                    _vm.selectedSearchId = $$v
                  },
                  expression: "selectedSearchId",
                },
              }),
              _c("v-btn", {
                staticClass: "search-loader",
                attrs: {
                  icon: "",
                  flat: "",
                  tabindex: "-1",
                  loading: _vm.searchRunning,
                  color: "grey",
                },
              }),
            ],
            1
          ),
          _vm.showMultiSearchDropdown &&
          !_vm.isUserRoleContractor &&
          !_vm.isClientUserLoggedIn
            ? _c(
                "div",
                { staticClass: "search-category" },
                [
                  _c("v-autocomplete", {
                    staticClass: "mx-0",
                    attrs: {
                      flat: "",
                      items: _vm.getSearchTypes,
                      "item-text": "label",
                      "item-value": "type",
                      "hide-no-data": "",
                      "hide-details": "",
                      label: "Search by",
                      "solo-inverted": "",
                    },
                    model: {
                      value: _vm.type,
                      callback: function ($$v) {
                        _vm.type = $$v
                      },
                      expression: "type",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }