var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "jobView page-container",
      class: _vm.getIsAutoDeploymentRunning ? "auto-deployment" : "",
    },
    [
      _c("div", {
        staticClass: "overlay-msg",
        class: _vm.getIsAutoDeploymentRunning ? "show" : "hide",
      }),
      _c("div", { staticClass: "page-content" }, [
        _c(
          "div",
          {
            staticClass: "page-content-side jobview-card-sidebar",
            class:
              _vm.job && _vm.job.additionalCostsAuthorised
                ? "additional-payment"
                : "",
          },
          [
            _c("v-progress-circular", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.job === undefined,
                  expression: "job === undefined",
                },
              ],
              style: _vm.getLoaderStyle(70),
              attrs: {
                width: 2,
                size: 50,
                indeterminate: "",
                color: "primary",
              },
            }),
            _vm.job
              ? _c("TimeLine", {
                  ref: "timeLine",
                  attrs: {
                    "job-id": _vm.jobId,
                    "record-id": _vm.recordId,
                    "request-type": _vm.requestType,
                    "record-type": _vm.recordType,
                  },
                  on: { cardWithDetailActivated: _vm.cardWithDetailActivated },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "page-content-main" },
          [
            !_vm.liveSync
              ? _c(
                  "v-system-bar",
                  { attrs: { window: "", color: "secondary" } },
                  [
                    _c("v-toolbar-title", { staticStyle: { color: "white" } }, [
                      _vm._v(" Live Sync Status: "),
                      !_vm.liveSync &&
                      _vm.getSignalRConnectionStatus == "connected"
                        ? _c("span", [
                            _vm._v(" disconnected, "),
                            _c(
                              "a",
                              {
                                staticStyle: {
                                  color: "white",
                                  "text-decoration": "underline",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.reconnectToGroup()
                                  },
                                },
                              },
                              [_vm._v("click to re-connect")]
                            ),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(_vm.getSignalRConnectionStatus)),
                          ]),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.previewModel
              ? _c("TimeLinePreview", {
                  attrs: { model: _vm.previewModel },
                  on: {
                    refreshData: _vm.refreshData,
                    closePreview: _vm.clearSelection,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "bar",
                rawName: "v-bar",
                value: { useScrollbarPseudo: true },
                expression: "{ useScrollbarPseudo: true }",
              },
            ],
            staticClass: "page-content-side map-content-sidebar pl-4",
          },
          [
            _c("div", { staticClass: "main-inner-content" }, [
              _c(
                "div",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm.job
                        ? _c(
                            "v-flex",
                            { staticClass: "map" },
                            [
                              _c("Map", {
                                staticStyle: { height: "380px" },
                                attrs: {
                                  "map-id": new Date().getTime().toString(),
                                  "source-latitude": _vm.job.latitude,
                                  "source-longitude": _vm.job.longitude,
                                  "source-address": _vm.job.getAddress(", "),
                                  "zoom-level": 13,
                                  "show-place-card": true,
                                  "show-travel-mode": true,
                                  "disable-default-u-i-control": true,
                                },
                              }),
                              _vm.job && !_vm.job.policyEnquiry
                                ? _c("CustomerCard", {
                                    attrs: { "job-id": _vm.jobId },
                                    on: {
                                      CustomerToPay: _vm.customerToPayClicked,
                                      complaintRaised: _vm.complaintClicked,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.job &&
                      !_vm.job.policyEnquiry &&
                      !_vm.job.helplinePolicy
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "btn-card-filter text-wrap-chip",
                              attrs: { xs12: "" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "chip-btn" },
                                _vm._l(
                                  _vm.job.emergencies,
                                  function (emergency) {
                                    return _c(
                                      "span",
                                      { key: emergency.id },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          {
                                                            staticClass:
                                                              "emergency-chip",
                                                            class:
                                                              emergency.displayInTimeline
                                                                ? "active-car-flbtn"
                                                                : "",
                                                            attrs: {
                                                              color: "teal",
                                                              "text-color":
                                                                "white",
                                                              small: true,
                                                            },
                                                            nativeOn: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.emergencyChipClicked(
                                                                  emergency.id
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-avatar",
                                                            {
                                                              attrs: {
                                                                size: 26,
                                                              },
                                                            },
                                                            [
                                                              emergency.fileURL !==
                                                                "" &&
                                                              emergency.fileURL !==
                                                                null
                                                                ? _c("img", {
                                                                    attrs: {
                                                                      src: emergency.fileURL,
                                                                      alt: "",
                                                                    },
                                                                  })
                                                                : _c("img", {
                                                                    attrs: {
                                                                      src: "/img/emergency.svg",
                                                                      alt: "",
                                                                    },
                                                                  }),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                emergency.detailDescription
                                                              ) +
                                                              " "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    emergency.typeDescription
                                                      ? emergency.typeDescription
                                                      : emergency.detailDescription
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              ),
                              _vm.job.status !== "Completed" &&
                              !_vm.job.cancellationReason &&
                              _vm.job.jobType === _vm.jobType
                                ? _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        icon: "",
                                                        color: "primary",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.openAddEmergency.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          dark: "",
                                                          color: "primary",
                                                        },
                                                      },
                                                      [_vm._v("add")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1332057379
                                      ),
                                    },
                                    [_c("span", [_vm._v("Add Emergency")])]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.job
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("WeatherWidgetAndJobNote", {
                                attrs: { "job-id": _vm.jobId },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: {
                        "max-width": "650",
                        "content-class": "v-dialog--scrollable",
                        persistent: "",
                      },
                      model: {
                        value: _vm.showEmergencyDialog,
                        callback: function ($$v) {
                          _vm.showEmergencyDialog = $$v
                        },
                        expression: "showEmergencyDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            { attrs: { card: "", dark: "", color: "primary" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("New HE Emergency"),
                              ]),
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.closeAddHEEmergencyDialog.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("close")])],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-text",
                            { staticClass: "px-3 scroll-content-dialog" },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c("AddHEEmergency", {
                                    ref: "addEmergency",
                                    attrs: {
                                      "add-emergency-index": 0,
                                      "policy-url": _vm.policyUrl,
                                    },
                                    model: {
                                      value: _vm.addEmergency,
                                      callback: function ($$v) {
                                        _vm.addEmergency = $$v
                                      },
                                      expression: "addEmergency",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-card-actions",
                            { staticClass: "px-3" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", flat: "flat" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.closeAddHEEmergencyDialog.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-0",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.isLoading,
                                    loading: _vm.isLoading,
                                  },
                                  on: { click: _vm.saveEmergency },
                                },
                                [_vm._v(" Submit ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showNewJobConfirmationOnAddEmergency
                    ? _c(
                        "v-dialog",
                        {
                          attrs: {
                            "max-width": "650",
                            "content-class": "v-dialog--scrollable",
                            persistent: "",
                          },
                          model: {
                            value: _vm.showNewJobConfirmationOnAddEmergency,
                            callback: function ($$v) {
                              _vm.showNewJobConfirmationOnAddEmergency = $$v
                            },
                            expression: "showNewJobConfirmationOnAddEmergency",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  attrs: {
                                    card: "",
                                    dark: "",
                                    color: "primary",
                                  },
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v("New Emergency Confirmation"),
                                  ]),
                                  _c("v-spacer"),
                                  !_vm.jobCreationInProcess
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          nativeOn: {
                                            click: function ($event) {
                                              _vm.showNewJobConfirmationOnAddEmergency = false
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("close")])],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-text",
                                { staticClass: "px-3 scroll-content-dialog" },
                                [
                                  _c("v-layout", { attrs: { wrap: "" } }, [
                                    _c("span", [
                                      _vm._v(
                                        " Are you sure that this Emergency is related to the same Peril as the first emergency? If you are, then click yes. "
                                      ),
                                    ]),
                                    _c("span", { staticClass: "pt-2" }, [
                                      _vm._v(
                                        " If this additional emergency should actually be a new job, then click, new job. "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                "v-card-actions",
                                { staticClass: "px-3" },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        flat: "flat",
                                        loading:
                                          _vm.jobCreationInProcess &&
                                          _vm.isLoading,
                                        disabled: _vm.jobCreationInProcess,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveClaim(true)
                                        },
                                      },
                                    },
                                    [_vm._v(" New Job ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-0",
                                      attrs: {
                                        color: "primary",
                                        disabled: _vm.jobCreationInProcess,
                                      },
                                      on: { click: _vm.AddNewEmergencyToJob },
                                    },
                                    [_vm._v(" Yes ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c("ClearEtaDialog", { attrs: { "job-id": _vm.jobId } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }