var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false,
        "nudge-width": 300,
        "max-width": 300,
        "z-index": 999,
        fixed: "",
        "position-y": 56,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "notification-bell",
                    attrs: { icon: "", large: "" },
                  },
                  on
                ),
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [
                      _c(
                        "v-badge",
                        { attrs: { overlap: "", color: "secondary" } },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: _vm.warningColor, size: 34 } },
                            [
                              _c("v-icon", { attrs: { dark: "", small: "" } }, [
                                _vm._v("warning"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "n-arrow" }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("v-card", { staticClass: "notification-menu" }, [
        _c(
          "div",
          { staticClass: "inner-content view-panel" },
          [
            _c(
              "v-list",
              { attrs: { "two-line": "" } },
              [
                _vm.getIsVipCustomer && _vm.showHideWarning(_vm.job)
                  ? _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-avatar",
                          {
                            staticClass: "icon-img vip-customer",
                            attrs: { color: "blue", size: 32 },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/vip-customer.svg" },
                            }),
                          ]
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c("b", { staticClass: "blue--text" }, [
                                  _vm._v("VIP Customer"),
                                ]),
                              ]),
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  { staticClass: "grey--text text--lighten-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          (_vm.job && _vm.job.clientTitle
                                            ? _vm.job.clientTitle + " "
                                            : "") +
                                            _vm.job.clientForename +
                                            " " +
                                            _vm.job.clientLastName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.vulnerabilityQAItem &&
                _vm.showHideWarning(_vm.vulnerabilityQAItem)
                  ? _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-avatar",
                          {
                            staticClass: "icon-img",
                            attrs: {
                              color: _vm.getColor(_vm.vulnerabilityQAItem),
                              size: 32,
                            },
                          },
                          [_c("img", { attrs: { src: "/img/shield.svg" } })]
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  {
                                    class:
                                      _vm.getColor(_vm.vulnerabilityQAItem) +
                                      "--text",
                                  },
                                  [_vm._v("Vulnerable Customer")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.healthAndSafetyQAItem, function (item, index) {
                  return [
                    item && _vm.showHideWarning(item)
                      ? _c(
                          "v-list-tile",
                          { key: index },
                          [
                            _c(
                              "v-list-tile-avatar",
                              {
                                staticClass: "icon-img",
                                attrs: { color: _vm.getColor(item), size: 32 },
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/img/health-care.svg" },
                                }),
                              ]
                            ),
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                [
                                  _c("v-list-tile-sub-title", [
                                    _c(
                                      "b",
                                      { class: _vm.getColor(item) + "--text" },
                                      [_vm._v("Health and Safety Issue")]
                                    ),
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    _c(
                                      "b",
                                      {
                                        staticClass:
                                          "grey--text text--lighten-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getEmergencyName(
                                                item.forEmergencyTypeId
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
                _vm.customerToPay && _vm.showHideWarning(_vm.customerToPay)
                  ? _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-avatar",
                          {
                            staticClass: "icon-img",
                            attrs: { color: "blue", size: 32 },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/autorized-customer.svg" },
                            }),
                          ]
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c("b", { staticClass: "blue--text" }, [
                                  _vm._v("Authorized Customer"),
                                ]),
                              ]),
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  { staticClass: "grey--text text--lighten-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.customerToPay.cardHolderName
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.complaint && _vm.showHideWarning(_vm.complaint)
                  ? _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-avatar",
                          {
                            attrs: {
                              color: _vm.getSeverityColor(_vm.complaint),
                              size: 32,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "", small: "" } }, [
                              _vm._v("announcement"),
                            ]),
                          ],
                          1
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  {
                                    class:
                                      _vm.getSeverityColor(_vm.complaint) +
                                      "--text",
                                  },
                                  [_vm._v("Complaint")]
                                ),
                              ]),
                              _c("v-list-tile-sub-title", [
                                _vm.getComplaintType(_vm.complaint)
                                  ? _c(
                                      "b",
                                      {
                                        staticClass:
                                          "grey--text text--lighten-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getComplaintType(
                                                _vm.complaint
                                              ) +
                                                " - Severity " +
                                                _vm.complaint.severity
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "b",
                                      {
                                        staticClass:
                                          "grey--text text--lighten-1",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "Severity " +
                                                _vm.complaint.severity
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.asbestosItems, function (item, index) {
                  return [
                    item
                      ? _c(
                          "v-list-tile",
                          { key: index },
                          [
                            _c(
                              "v-list-tile-avatar",
                              {
                                staticClass: "icon-img",
                                attrs: {
                                  color: _vm.getAsbestosEffectColor(item),
                                  size: 32,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "asbestos-icon",
                                  attrs: { src: "/img/asbestos.svg" },
                                }),
                              ]
                            ),
                            _c("v-list-tile-content", [
                              _c(
                                "span",
                                [
                                  _c("v-list-tile-sub-title", [
                                    _c(
                                      "b",
                                      {
                                        class:
                                          _vm.getAsbestosEffectColor(item) +
                                          "--text",
                                      },
                                      [_vm._v("Asbestos Issue")]
                                    ),
                                  ]),
                                  _c("v-list-tile-sub-title", [
                                    !item.forEmergencyTypeId &&
                                    !item.forEmergencyDetailId
                                      ? _c(
                                          "b",
                                          {
                                            staticClass:
                                              "grey--text text--lighten-1",
                                          },
                                          [_vm._v(" Policy Level ")]
                                        )
                                      : _vm._e(),
                                    item.forEmergencyTypeId
                                      ? _c(
                                          "b",
                                          {
                                            staticClass:
                                              "grey--text text--lighten-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getEmergencyName(
                                                    item.forEmergencyTypeId
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    item.forEmergencyDetailId
                                      ? _c(
                                          "b",
                                          {
                                            staticClass:
                                              "grey--text text--lighten-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getEmergencyName(
                                                    item.forEmergencyTypeId,
                                                    item.forEmergencyDetailId
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
                _vm.escalationDetails &&
                _vm.showHideWarning(_vm.escalationDetails)
                  ? _c(
                      "v-list-tile",
                      {
                        staticClass: "escalation-Details",
                        on: { click: _vm.openJobEscalationCard },
                      },
                      [
                        _c(
                          "v-list-tile-avatar",
                          { attrs: { color: "red", size: 32 } },
                          [
                            _c("v-icon", { attrs: { dark: "", small: "" } }, [
                              _vm._v("forward"),
                            ]),
                          ],
                          1
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  { staticClass: "grey--text text--lighten-1" },
                                  [_vm._v("Job Escalated")]
                                ),
                              ]),
                              _c("v-list-tile-sub-title", [
                                _c(
                                  "b",
                                  { staticClass: "grey--text text--lighten-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.escalationDetails.escalationReason
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.job &&
                _vm.job.jobType === "US" &&
                _vm.showHideWarning(_vm.job)
                  ? _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-avatar",
                          {
                            staticClass: "icon-img vip-customer",
                            attrs: { color: "orange", size: 32 },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/pounds-symbol.svg" },
                            }),
                          ]
                        ),
                        _c("v-list-tile-content", [
                          _c(
                            "span",
                            [
                              _c("v-list-tile-sub-title", [
                                _c("b", { staticClass: "warning--text" }, [
                                  _vm._v(
                                    " Outstanding Excess Amount: " +
                                      _vm._s(
                                        _vm.getFormatedCurrency(
                                          _vm.excessValue - _vm.totalPaidAmount
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }