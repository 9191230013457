var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "peril-repeat", attrs: { wrap: "" } },
    [
      _vm.value
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        staticClass: "note-types",
                        attrs: {
                          items: _vm.noteTypes,
                          label: "Type",
                          "item-text": "description",
                          "item-value": "id",
                        },
                        model: {
                          value: _vm.value.noteType,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "noteType",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "value.noteType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Title",
                          required: "",
                          "data-vv-name": "Title",
                          name: "Title",
                          "error-messages": _vm.validationMessage("Title"),
                        },
                        model: {
                          value: _vm.value.title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "title",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "value.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.jobItem && !_vm.jobItem.helplinePolicy
                    ? _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            staticClass: "required emergency-Detail",
                            attrs: {
                              label: _vm.jobTypeLabel("emergency detail"),
                              items: _vm.jobEmergencyDetailList,
                              "item-text": "description",
                              "item-value": "emergencyDetailId",
                              "data-vv-name":
                                _vm.jobTypeLabel("emergency detail"),
                              required: "",
                              "error-messages": _vm.validationMessage(
                                _vm.jobTypeLabel("emergency detail")
                              ),
                            },
                            on: { input: _vm.selectedEmergencyDetail },
                            model: {
                              value: _vm.value.forEmergencyDetailId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.value,
                                  "forEmergencyDetailId",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "value.forEmergencyDetailId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Description",
                          required: "",
                          rows: "5",
                          "data-vv-name": "Description",
                          name: "Description",
                          "error-messages":
                            _vm.validationMessage("Description"),
                        },
                        model: {
                          value: _vm.value.description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.value,
                              "description",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "value.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }