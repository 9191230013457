var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "py-0" },
        [
          _c("h3", [_vm._v("Policy Summary")]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mr-0",
              attrs: { icon: "" },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c("v-card-text", { staticClass: "scroll-content-dialog" }, [
        _c(
          "div",
          { staticClass: "policysummary-details" },
          [
            _c("v-progress-circular", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.policySummary,
                  expression: "!policySummary",
                },
              ],
              style: _vm.getLoaderStyle(70),
              attrs: {
                width: 2,
                size: 50,
                indeterminate: "",
                color: "primary",
              },
            }),
            _vm.policySummary
              ? _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [_c("b", [_vm._v("Policy Name:")])]),
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.policySummary.name))]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [_c("b", [_vm._v("Policy Number:")])]),
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.job.policyNumber))]),
                        ]),
                        _vm.job && _vm.job.policyNumberNotPresentReason
                          ? _c(
                              "div",
                              { staticClass: "mt-1 policy-number-reason" },
                              [
                                _c("b", [
                                  _vm._v("Policy Number Not Present Reason:"),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.job.policyNumberNotPresentReason)
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [_c("b", [_vm._v("Insurer Reference:")])]),
                        _c("div", [
                          _c("span", [
                            _c("b", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.policySummary.insurerReference) +
                                  " "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.jobTypeLabel("customer ref")) + ":"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", [
                            _c("b", { staticClass: "secondary--text" }, [
                              _vm._v(_vm._s(_vm.job.customerRef)),
                            ]),
                          ]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [_c("b", [_vm._v("Intention:")])]),
                        _c("div", [
                          _c("pre", [
                            _vm._v(_vm._s(_vm.policySummary.intention)),
                          ]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.jobTypeLabel("emergency definition")) +
                                ":"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("pre", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sanitizeHTML(
                                  _vm.policySummary.emergencyDefinition
                                )
                              ),
                            },
                          }),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.jobTypeLabel("policy limit")) + ":"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", [
                            _c("b", { staticClass: "secondary--text" }, [
                              _vm._v(
                                "£" + _vm._s(_vm.policySummary.policyLimit)
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.policyLimitText
                                    ? _vm.policyLimitText + "."
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [_vm._v("Accommodation Coverage status:")]),
                        ]),
                        _c("div", [
                          _vm.policySummary.includesAccommodation
                            ? _c("span", [_vm._v("Yes")])
                            : _c("span", [_vm._v("No")]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [_vm._v("Transport to accommodation:")]),
                        ]),
                        _c("div", [
                          _vm.policySummary.includesTransportToAccommodation
                            ? _c("span", [_vm._v("Yes")])
                            : _c("span", [_vm._v("No")]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("b", [
                            _vm._v(
                              "Separate " +
                                _vm._s(_vm.jobTypeLabel("policy limit")) +
                                " for accommodation:"
                            ),
                          ]),
                        ]),
                        _c("div", [
                          _c("span", { staticClass: "mt-1" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.policySummary.accommodationExtraValue
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "div",
                          [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.jobTypeLabel("emergency type")) +
                                  " Covered:"
                              ),
                            ]),
                            _vm._l(
                              _vm.policySummaryEmergencies,
                              function (coveredEmergencies) {
                                return _c(
                                  "div",
                                  {
                                    key: coveredEmergencies.emergency,
                                    staticClass: "mt-1",
                                  },
                                  [
                                    _c(
                                      "b",
                                      {
                                        staticClass:
                                          "grey--text text--darken-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              coveredEmergencies.emergency
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      " - " +
                                        _vm._s(
                                          coveredEmergencies.emergencyDetail
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c("v-divider", { staticClass: "my-2" }),
                      ],
                      1
                    ),
                    _c("div", [
                      _c("b", [_vm._v("Coverage and Exclusion Information:")]),
                    ]),
                    _vm._l(
                      _vm.policyCoverageDetails,
                      function (coveredEmergencies, index) {
                        return _c(
                          "v-flex",
                          { key: index, attrs: { xs12: "" } },
                          [
                            _c(
                              "div",
                              { staticClass: "grey pa-2 my-2 lighten-3" },
                              [
                                _c("div", [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        coveredEmergencies.emergencyDetail
                                      ) +
                                        " (" +
                                        _vm._s(coveredEmergencies.emergency) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                                _c("div", { staticClass: "mt-1" }, [
                                  _c(
                                    "b",
                                    {
                                      staticClass: "grey--text text--darken-2",
                                    },
                                    [_vm._v("Coverage Details:")]
                                  ),
                                  coveredEmergencies.coverageDetail
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              coveredEmergencies.coverageDetail
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("remove")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                                _c("div", { staticClass: "mt-1" }, [
                                  _c(
                                    "b",
                                    {
                                      staticClass: "grey--text text--darken-2",
                                    },
                                    [_vm._v("Exclusions:")]
                                  ),
                                  coveredEmergencies.exclusions
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              coveredEmergencies.exclusions
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("remove")]
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                                _c("div", { staticClass: "mt-1" }, [
                                  _c(
                                    "b",
                                    {
                                      staticClass: "grey--text text--darken-2",
                                    },
                                    [_vm._v("Marked as CTP:")]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "emergency-detail-ctp" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            coveredEmergencies.isMarkedAsCTP
                                              ? "Yes"
                                              : "No"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }