var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-form",
    [
      _c(
        "v-container",
        { staticClass: "pa-0 grid-list-md", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|alpha_num",
                        expression: "'required|alpha_num'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Who is responsible for Health and Safety?",
                      required: "",
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Responsible Name",
                      "error-messages": _vm.errors.collect("Responsible Name"),
                    },
                    model: {
                      value: _vm.healthSafetyWellbeingEnvironmentModel.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.healthSafetyWellbeingEnvironmentModel,
                          "name",
                          $$v
                        )
                      },
                      expression: "healthSafetyWellbeingEnvironmentModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs6: "" } },
                [
                  _c("v-autocomplete", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required position-list",
                    attrs: {
                      items: _vm.positionList,
                      label: "Select Position",
                      "item-value": "description",
                      "item-text": "description",
                      loading: _vm.isLoadingPosition,
                      required: "",
                      "data-vv-scope": _vm.formRef,
                      "data-vv-name": "Postion",
                      "error-messages": _vm.errors.collect("Postion"),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.OnPositionChange($event)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (data) {
                          return [
                            _c(
                              "v-list-tile-content",
                              [
                                _c(
                                  "v-list-tile-title",
                                  {
                                    class:
                                      data.item.description === "Configure"
                                        ? "bold-select"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(data.item.description) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.healthSafetyWellbeingEnvironmentModel.position,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.healthSafetyWellbeingEnvironmentModel,
                          "position",
                          $$v
                        )
                      },
                      expression:
                        "healthSafetyWellbeingEnvironmentModel.position",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-tooltip",
                    { attrs: { top: "", "nudge-bottom": "30" } },
                    [
                      _c("FileUpload", {
                        ref: "refRiskAssessmentBehalfOfCET",
                        attrs: {
                          slot: "activator",
                          id: _vm.contractorId,
                          "is-required-validation": true,
                          "uploaded-files":
                            _vm.getUploadedFilesFor("Risk Assessment"),
                          label:
                            "Upload documents of risk assessment for work you propose to undertake on behalf of CET",
                          "upload-document-for": "Risk Assessment",
                          "is-disabled": _vm.isUserRoleContractor,
                        },
                        slot: "activator",
                      }),
                      _c("span", [
                        _vm._v(
                          "Upload documents of risk assessment for work you propose to undertake on behalf of CET"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-tooltip",
                    { attrs: { top: "", "nudge-bottom": "30" } },
                    [
                      _c("FileUpload", {
                        ref: "refSafeMethodsBehalfOfCET",
                        attrs: {
                          slot: "activator",
                          id: _vm.contractorId,
                          "is-required-validation": true,
                          "uploaded-files": _vm.getUploadedFilesFor(
                            "Safe methods of work"
                          ),
                          label:
                            "Upload documents of safe methods of work for work you propose to undertake on behalf of CET",
                          "upload-document-for": "Safe methods of work",
                          "is-disabled": _vm.isUserRoleContractor,
                        },
                        slot: "activator",
                      }),
                      _c("span", [
                        _vm._v(
                          "Upload documents of safe methods of work for work you propose to undertake on behalf of CET"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("h3", { staticClass: "primary--text" }, [
                  _vm._v(
                    "Are Your workforce trained in the following Health & Safety topics?"
                  ),
                ]),
              ]),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v("Manual Handling Awareness"),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name": "Manual Handling Awareness",
                                "error-messages": _vm.errors.collect(
                                  "Manual Handling Awareness"
                                ),
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .manualHandlingAwareness,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "manualHandlingAwareness",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.manualHandlingAwareness",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v("Asbestos Awareness"),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name": "Asbestos Awareness",
                                "error-messages":
                                  _vm.errors.collect("Asbestos Awareness"),
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .asbestosAwareness,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "asbestosAwareness",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.asbestosAwareness",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v("Work at Height"),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name": "Work at Height",
                                "error-messages":
                                  _vm.errors.collect("Work at Height"),
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .workAtHeight,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "workAtHeight",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.workAtHeight",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v("Slips, Trips & Falls Awareness"),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name": "Slips, Trips, Falls Awareness",
                                "error-messages": _vm.errors.collect(
                                  "Slips, Trips, Falls Awareness"
                                ),
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .slipsTripsFallsAwareness,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "slipsTripsFallsAwareness",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.slipsTripsFallsAwareness",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v(
                            " Do you provide your workforce with any form of Health Surveillance or Health Monitoring? "
                          ),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name":
                                  "Provide Health Surveillance Or Monitoring",
                                "error-messages": _vm.errors.collect(
                                  "Provide Health Surveillance Or Monitoring"
                                ),
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .provideHealthSurveillanceOrMonitoring,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "provideHealthSurveillanceOrMonitoring",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.provideHealthSurveillanceOrMonitoring",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    grey: "",
                    "lighten-3": "",
                    "mt-1": "",
                    "px-2": "",
                  },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs8: "" } }, [
                        _c("b", { staticClass: "hs-que" }, [
                          _vm._v(
                            "Have you had any RIDDOR reportable incidents in the past three years?"
                          ),
                        ]),
                        _vm._v("   "),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-radio-group",
                            {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              staticClass: "d-inline-block",
                              attrs: {
                                "hide-details": "",
                                row: "",
                                "data-vv-scope": _vm.formRef,
                                "data-vv-name": "Riddor Reportable Incidents",
                                "error-messages": _vm.errors.collect(
                                  "Riddor Reportable Incidents"
                                ),
                                name: "riddorReportableIncidents",
                              },
                              model: {
                                value:
                                  _vm.healthSafetyWellbeingEnvironmentModel
                                    .riddorReportableIncidents,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.healthSafetyWellbeingEnvironmentModel,
                                    "riddorReportableIncidents",
                                    $$v
                                  )
                                },
                                expression:
                                  "healthSafetyWellbeingEnvironmentModel.riddorReportableIncidents",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: {
                                  label: "YES",
                                  color: "secondary",
                                  value: true,
                                },
                              }),
                              _c("v-radio", {
                                attrs: {
                                  label: "NO",
                                  color: "secondary",
                                  value: false,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.healthSafetyWellbeingEnvironmentModel
                    .riddorReportableIncidents
                    ? _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "More Info",
                          required: "",
                          "data-vv-scope": _vm.formRef,
                          "data-vv-name": "More Info",
                          name: "moreInfo",
                          "error-messages": _vm.errors.collect("More Info"),
                        },
                        model: {
                          value:
                            _vm.healthSafetyWellbeingEnvironmentModel
                              .riddorReportableIncidentsMoreInfo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.healthSafetyWellbeingEnvironmentModel,
                              "riddorReportableIncidentsMoreInfo",
                              $$v
                            )
                          },
                          expression:
                            "healthSafetyWellbeingEnvironmentModel.riddorReportableIncidentsMoreInfo",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-2" }),
      _c(
        "v-card-actions",
        { staticClass: "bottom-align" },
        [
          _c("v-spacer"),
          !_vm.isUserRoleContractor
            ? _c(
                "v-btn",
                {
                  staticClass: "btn-save",
                  attrs: {
                    color: "primary",
                    disabled: _vm.loading,
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" save ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.saveSnackbarTimeout, bottom: true, left: true },
          model: {
            value: _vm.saveSnackbar,
            callback: function ($$v) {
              _vm.saveSnackbar = $$v
            },
            expression: "saveSnackbar",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { flat: "", color: "secondary" },
              nativeOn: {
                click: function ($event) {
                  _vm.saveSnackbar = false
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "820",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                ref: "configureDropdown",
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog: _vm.onConfigureDropdownDialog,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }