var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.getWorkers
    ? _c(
        "div",
        { staticClass: "dashboard", attrs: { id: _vm.tableName } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pt-1": "", "px-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs6: "", "request-info": "" } }, [
                    !_vm.isFromMainDashBoard
                      ? _c("h3", { staticClass: "pt-4" }, [
                          _vm._v("Manager Supervisor"),
                        ])
                      : _vm._e(),
                  ]),
                  _c("v-flex", { attrs: { xs6: "" } }, [
                    _c(
                      "div",
                      { staticClass: "search-supervisor" },
                      [
                        _c("v-text-field", {
                          staticClass: "mt-0 pt-0",
                          attrs: {
                            "append-icon": "search",
                            label: "Search",
                            "single-line": "",
                            "hide-details": "",
                          },
                          model: {
                            value: _vm.search,
                            callback: function ($$v) {
                              _vm.search = $$v
                            },
                            expression: "search",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-flex",
                    {
                      staticClass: "gridView",
                      attrs: { xs12: "", "mt-2": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "supervisor-list" },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.getAllWorker,
                              search: _vm.search,
                              "custom-filter": _vm.customFilter,
                              pagination: _vm.pagination,
                            },
                            on: {
                              "update:pagination": function ($event) {
                                _vm.pagination = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "headers",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        [
                                          _vm._l(
                                            props.headers,
                                            function (header) {
                                              return _c(
                                                "th",
                                                {
                                                  key: header.text,
                                                  class: [
                                                    "column sortable",
                                                    _vm.pagination.descending
                                                      ? "desc"
                                                      : "asc",
                                                    header.value ===
                                                    _vm.pagination.sortBy
                                                      ? "active"
                                                      : "",
                                                  ],
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeSort(
                                                        header.value
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(header.text) +
                                                      " "
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [_vm._v("arrow_upward")]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm.teamList.length > 0
                                            ? _c(
                                                "th",
                                                {
                                                  class: [
                                                    "column sortable teamname-supervisor",
                                                    _vm.pagination.descending
                                                      ? "desc"
                                                      : "asc",
                                                    "teamname" ===
                                                    _vm.pagination.sortBy
                                                      ? "active"
                                                      : "",
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { small: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeSort(
                                                            "teamName"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("arrow_upward")]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.teamList,
                                                      placeholder: "Team Name",
                                                      "item-text": "teamName",
                                                      "item-value": "teamName",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedTeamName,
                                                      callback: function ($$v) {
                                                        _vm.selectedTeamName =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedTeamName",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c("th", [_vm._v("Team Name")]),
                                        ],
                                        2
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "items",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "tr",
                                        {
                                          attrs: { active: props.selected },
                                          on: {
                                            click: function ($event) {
                                              props.selected = !props.selected
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticClass: "team-member-img",
                                              class:
                                                props.item.activityName ===
                                                "Offline"
                                                  ? "b-grey"
                                                  : "",
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm.getImageUrl(
                                                    props.item.attributes.userId
                                                  ),
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.attributes.firstName
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.attributes.lastName
                                              )
                                            ),
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.activityName)
                                            ),
                                          ]),
                                          _c(
                                            "td",
                                            {
                                              staticClass:
                                                "justify-center layout px-0",
                                            },
                                            [
                                              props.item.activityName ===
                                                "Busy" &&
                                              !_vm.onCall &&
                                              !_vm.supervisorCallDetails
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "action-btn-team",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { small: "" },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.joinConference(
                                                                props.item.id,
                                                                false
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Barge")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { small: "" },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.joinConference(
                                                                props.item.id,
                                                                true
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Listen")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { small: "" },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.coach(
                                                                props.item.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Coach")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.onCall &&
                                              _vm.supervisorCallDetails &&
                                              _vm.supervisorCallDetails
                                                .incidentManagerWorkerSid ==
                                                props.item.id
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "action-btn-team",
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: { small: "" },
                                                          nativeOn: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.leaveConference.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("Leave")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : props.item.activityName ===
                                                    "Idle" ||
                                                  props.item.activityName ===
                                                    "BackOffice"
                                                ? _c(
                                                    "div",
                                                    [
                                                      props.item
                                                        .activityName === "Idle"
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateWorkerQueueStatus(
                                                                      props.item
                                                                        .id,
                                                                      false
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Take out of Queue "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      props.item
                                                        .activityName ===
                                                      "BackOffice"
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateWorkerQueueStatus(
                                                                      props.item
                                                                        .id,
                                                                      true
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Put back into Queue "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            [
                                              _c("star-rating", {
                                                attrs: {
                                                  "star-size": 14,
                                                  rating:
                                                    props.item.attributes
                                                      .rating,
                                                  "max-rating": 5,
                                                  "active-color": "#e7711b",
                                                  increment: 0.1,
                                                  "fixed-points": 1,
                                                  "read-only": true,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.attributes.teamName
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3255312692
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }