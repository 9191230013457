var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-flex",
    {
      staticClass: "timeline-cards",
      class: _vm.selected ? "card-active" : "",
      style: _vm.getDynamicCardStyle,
      attrs: { xs12: "" },
    },
    [
      _c(
        "div",
        [
          _c(
            "v-card",
            {
              staticClass: "mb-3 title-text right-arrow",
              class: _vm.getCardClass,
              attrs: { ripple: true },
            },
            [
              _c(
                "span",
                { staticClass: "timeline-icon" },
                [_c("v-icon", [_vm._v(_vm._s(_vm.model.icon))])],
                1
              ),
              _c("span", { staticClass: "left-bar" }),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showFlatCard,
                    expression: "!showFlatCard",
                  },
                ],
                staticClass: "arrow",
              }),
              _c("span", { staticClass: "date-text" }, [
                _vm._v(_vm._s(_vm.getElapsedDuration)),
              ]),
              _c("div", { staticClass: "card-inner-detail" }, [
                !_vm.showFlatCard
                  ? _c(
                      "h3",
                      {
                        staticClass: "pa-3",
                        class:
                          _vm.model.status &&
                          _vm.model.status === "Follow On Required"
                            ? "status-title visit-complete-chip"
                            : _vm.model.status
                            ? "status-title"
                            : "",
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.model.title))]),
                        _vm.model.status && _vm.isEngineerVisitCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                class:
                                  _vm.model.status ===
                                  _vm.engineerJobVisitStatus[
                                    _vm.engineerJobVisitStatus.ReAssigned
                                  ]
                                    ? "re-assigned-visit"
                                    : "",
                                attrs: {
                                  color: _vm.getEngineerVisitStatusColor,
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getEngineerVisitStatus(
                                        _vm.model.status
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isJobEscalationCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: _vm.getJobEscalationStatusColor,
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getJobEscalationStatus) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.model.status && _vm.isConfirmDateCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: _vm.getConfirmDateStatusColor,
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getConfirmDateStatus(_vm.model.status)
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isTestingCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color: _vm.testingStatusColor,
                                  small: "",
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.testingStatus) + " ")]
                            )
                          : _vm._e(),
                        _vm.model.status && _vm.isIVSCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: { color: _vm.getIVSColor, small: "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getIVSStatus(_vm.model.status)) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.model.status &&
                        !_vm.isEngineerVisitCard &&
                        !_vm.isConfirmDateCard &&
                        !_vm.isIVSCard
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  color:
                                    _vm.model.status ===
                                    _vm.jobVisitStatus[
                                      _vm.jobVisitStatus.Unavailable
                                    ]
                                      ? ""
                                      : _vm.model.status ===
                                        _vm.jobVisitStatus[
                                          _vm.jobVisitStatus.Cancelled
                                        ]
                                      ? "red"
                                      : "green",
                                  small: "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.model.status ===
                                        _vm.jobVisitStatus[
                                          _vm.jobVisitStatus.Unavailable
                                        ]
                                        ? ""
                                        : _vm.model.status ===
                                          _vm.jobVisitStatus[
                                            _vm.jobVisitStatus.Cancelled
                                          ]
                                        ? "Cancelled"
                                        : _vm.model.status ===
                                          _vm.jobVisitStatus[
                                            _vm.jobVisitStatus.Completed
                                          ]
                                        ? "Completed"
                                        : _vm.model.status === "AutoDeploying"
                                        ? "Auto Deploying"
                                        : _vm.model.status ===
                                          "Follow On Required"
                                        ? "Follow On Required"
                                        : "Assigned"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.model.cardBodyComponent == "SoftFixCard"
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: { color: "green", small: "" },
                              },
                              [_vm._v(" Emergency Complete ")]
                            )
                          : _vm._e(),
                        _vm.model.cardBodyComponent === "CustomerToPayCard" &&
                        _vm.showCustomerToPayCardChip
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "white--text",
                                attrs: { color: "red", small: "" },
                              },
                              [_vm._v(" Expired ")]
                            )
                          : _vm._e(),
                        _vm.model.cardAdditionalChipsComponent
                          ? _c("span", [_vm._t("additional-chips")], 2)
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "question-title-desc",
                    class: !_vm.showFlatCard ? "px-3 pt-3 pb-2 " : "px-3 py-1",
                  },
                  [
                    _vm._t("body"),
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c("v-flex", { attrs: { lg7: "", xs12: "" } }, [
                          _c(
                            "div",
                            { staticClass: "small-text text-gray" },
                            [_vm._t("footer")],
                            2
                          ),
                        ]),
                        _c(
                          "v-flex",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.showFlatCard,
                                expression: "!showFlatCard",
                              },
                            ],
                            attrs: { lg5: "", xs12: "" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "small-text text-gray text-xs-right date-outer",
                              },
                              [
                                _c(
                                  "v-icon",
                                  { attrs: { color: "secondary" } },
                                  [_vm._v("date_range")]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getCreatedDateForDisplay) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm.showEmergency
                  ? _c(
                      "div",
                      { staticClass: "emergency px-0 py-1" },
                      [
                        !_vm.showFlatCard
                          ? _c("v-divider", {
                              staticClass: "grey mb-1 lighten-3",
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          { staticClass: "chip-btn px-3" },
                          _vm._l(_vm.getEmergencies, function (emergency) {
                            return _c(
                              "span",
                              { key: emergency.id },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "emergency-chip",
                                                    attrs: {
                                                      "text-color": "white",
                                                      small: true,
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    { attrs: { size: 26 } },
                                                    [
                                                      emergency.fileURL !==
                                                        "" &&
                                                      emergency.fileURL !== null
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: emergency.fileURL,
                                                              alt: "",
                                                            },
                                                          })
                                                        : _c("img", {
                                                            attrs: {
                                                              src: "/img/emergency.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        emergency.detailDescription
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            emergency.typeDescription
                                              ? emergency.typeDescription
                                              : emergency.detailDescription
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }