var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.material
    ? _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { card: "", dark: "", color: "primary" } },
            [
              _c("v-toolbar-title", [_vm._v("Material")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { icon: "" }, on: { click: _vm.Close } },
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            { staticClass: "pa-3 scroll-content-dialog" },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          id: "materialNameField",
                          label: "Material Name",
                          required: "",
                          "data-vv-scope": "frmMaterial",
                          "data-vv-name": "Material Name",
                          "error-messages": _vm.errors.collect("Material Name"),
                          name: "materialName",
                        },
                        model: {
                          value: _vm.material.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.material, "name", $$v)
                          },
                          expression: "material.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value: _vm.material.isAdditionalInfoRequired,
                          label: "Additional Information Required",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.material.isAdditionalInfoRequired,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.material,
                              "isAdditionalInfoRequired",
                              $$v
                            )
                          },
                          expression: "material.isAdditionalInfoRequired",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value: _vm.material.isExcludedFromClientInvoicing,
                          label: "Exclude from Client Invoice",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.material.isExcludedFromClientInvoicing,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.material,
                              "isExcludedFromClientInvoicing",
                              $$v
                            )
                          },
                          expression: "material.isExcludedFromClientInvoicing",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value:
                            _vm.material.isCostExcludedFromContractorFixedFee,
                          label: "Cost Excluded from Contractor Fixed Fee",
                          color: "primary",
                          "hide-details": "",
                          name: "isCostExcludedFromContractorFixedFee",
                        },
                        model: {
                          value:
                            _vm.material.isCostExcludedFromContractorFixedFee,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.material,
                              "isCostExcludedFromContractorFixedFee",
                              $$v
                            )
                          },
                          expression:
                            "material.isCostExcludedFromContractorFixedFee",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "mt-1": "" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          value:
                            _vm.material.excludeFromCustomerApprovalUpdates,
                          label:
                            "Exclude from Updates to Customer after Approval",
                          color: "primary",
                          "hide-details": "",
                        },
                        model: {
                          value:
                            _vm.material.excludeFromCustomerApprovalUpdates,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.material,
                              "excludeFromCustomerApprovalUpdates",
                              $$v
                            )
                          },
                          expression:
                            "material.excludeFromCustomerApprovalUpdates",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            { staticClass: "px-3" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "btn-close",
                  nativeOn: {
                    click: function ($event) {
                      return _vm.Close.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mr-0 submit-btn",
                  attrs: {
                    disabled: _vm.isLoading,
                    loading: _vm.isLoading,
                    color: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.Save.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" submit ")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }