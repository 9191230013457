var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "login-callback" }, [
    _c("div", { staticClass: "login-callback__status" }, [
      _c("span", { staticClass: "status__message" }, [
        _vm._v(_vm._s(_vm.statusMessage)),
      ]),
    ]),
    _vm.errorMessage
      ? _c("div", { staticClass: "login-callback__error" }, [
          _c("div", { staticClass: "error__message" }, [
            _c("label", { staticClass: "message__label" }, [_vm._v("Error:")]),
            _c("span", { staticClass: "message__text" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
          ]),
          _c("div", { staticClass: "error__session" }, [
            _c("label", { staticClass: "session__label" }, [
              _vm._v("Session ID:"),
            ]),
            _c("span", { staticClass: "session__text" }, [
              _vm._v(_vm._s(_vm.sessionId)),
            ]),
          ]),
          _c("div", { staticClass: "error__retry" }, [
            _c("div", [
              _vm._v(
                "Failed to log you in, please retry and if this issue happens again contact support for assistance."
              ),
            ]),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.redirectHome.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Retry")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }