var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "signatureform" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0 grid-list-md", attrs: { fluid: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("p", [
                    _vm._v(
                      "I hereby confirm that in signing this Pre-Qualification Questionnaire;"
                    ),
                  ]),
                  _c("p", [
                    _vm._v(" a. That the "),
                    _c("strong", [
                      _vm._v(
                        "information supplied in this application is true and complete"
                      ),
                    ]),
                    _vm._v(" and; "),
                  ]),
                  _c("p", [
                    _vm._v(" b. I understand that "),
                    _c("strong", [_vm._v("CET")]),
                    _vm._v(
                      " will reply upon these answers in consideration of our suitability to become a contractor and; "
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      " c. I will comply with the requirements of the document; "
                    ),
                    _c("i", [
                      _c("strong", [
                        _vm._v("Health, Safety & Environmental Requirements."),
                      ]),
                    ]),
                  ]),
                  _c("p", [
                    _vm._v(
                      " d. I have read, understood and will comply with the CET "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "toba-link",
                        attrs: { href: _vm.getTOBAUrl(), target: "_blank" },
                      },
                      [
                        _c("i", [
                          _c("strong", [
                            _vm._v("Terms of Business Agreement (TOBA)"),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("p", [
                    _vm._v(" e. "),
                    _c("strong", [
                      _vm._v(
                        "Where any subsequent changes in the information provided is identified"
                      ),
                    ]),
                    _vm._v(
                      " or change in circumstances as described in this application are known, I will notify "
                    ),
                    _c("strong", [_vm._v("CET")]),
                    _vm._v(" immediately. "),
                  ]),
                  _c("v-spacer"),
                  _c("i", [
                    _c("strong", { staticClass: "red--text" }, [
                      _c("h4", [_vm._v("Important:")]),
                      _c("p", [
                        _vm._v(" Any contract with "),
                        _c("span", { staticClass: "black--text" }, [
                          _vm._v("CET"),
                        ]),
                        _vm._v(
                          " is entered into on the basis of the information provided within this online application process and the "
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "toba-link",
                            attrs: { href: _vm.getTOBAUrl(), target: "_blank" },
                          },
                          [
                            _c("u", [
                              _vm._v("Terms of Business Agreement (TOBA)"),
                            ]),
                          ]
                        ),
                        _vm._v(" document. "),
                      ]),
                      _c("p", [
                        _vm._v(
                          " Failure to disclose any subsequent changes in the information provided may result in termination of any such contract and proceedings in consideration of any loss, damage or injury arising. All information provided will be treated as strictly confidential and used for processing this application only. "
                        ),
                      ]),
                      _c("p"),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      label: "Name",
                      maxlength: "50",
                      required: "",
                      "data-vv-scope": "frmSignature",
                      "data-vv-name": "Name",
                      "error-messages": _vm.errors.collect("Name"),
                    },
                    model: {
                      value: _vm.contractorSignatureModel.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.contractorSignatureModel,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "contractorSignatureModel.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticClass: "required",
                    attrs: {
                      items: _vm.position,
                      label: "Select Position",
                      required: "",
                      "data-vv-scope": "frmSignature",
                      "data-vv-name": "Position",
                      "error-messages": _vm.errors.collect("Position"),
                    },
                    model: {
                      value: _vm.contractorSignatureModel.position,
                      callback: function ($$v) {
                        _vm.$set(_vm.contractorSignatureModel, "position", $$v)
                      },
                      expression: "contractorSignatureModel.position",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs4: "" } },
                [
                  _c(
                    "v-menu",
                    {
                      ref: "toDatePickerMenu",
                      attrs: {
                        "close-on-content-click": false,
                        "nudge-right": 40,
                        lazy: "",
                        transition: "scale-transition",
                        "offset-y": "",
                        "full-width": "",
                        "min-width": "290px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "required",
                                    attrs: {
                                      label: "Date",
                                      "append-icon": "date_range",
                                      readonly: "",
                                      "data-vv-scope": "frmSignature",
                                      "data-vv-name": "Date",
                                      "error-messages":
                                        _vm.errors.collect("Date"),
                                    },
                                    model: {
                                      value: _vm.chosenSignatureOnFormattedDate,
                                      callback: function ($$v) {
                                        _vm.chosenSignatureOnFormattedDate = $$v
                                      },
                                      expression:
                                        "chosenSignatureOnFormattedDate",
                                    },
                                  },
                                  on
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.showSignatureOnDateSelection,
                        callback: function ($$v) {
                          _vm.showSignatureOnDateSelection = $$v
                        },
                        expression: "showSignatureOnDateSelection",
                      },
                    },
                    [
                      _c(
                        "v-date-picker",
                        {
                          attrs: { "no-title": "", scrollable: "" },
                          model: {
                            value: _vm.chosenSignatureOnDate,
                            callback: function ($$v) {
                              _vm.chosenSignatureOnDate = $$v
                            },
                            expression: "chosenSignatureOnDate",
                          },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.showSignatureOnDateSelection = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { flat: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onSelectSignatureOnDatePicker(
                                    _vm.chosenSignatureOnDate
                                  )
                                },
                              },
                            },
                            [_vm._v("OK")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-checkbox", {
                    staticClass: "d-inline-block",
                    class: _vm.isAgreedToTermsAndCondition
                      ? ""
                      : "red-border-checkbox",
                    attrs: {
                      color: "primary",
                      label: "I Agree to the terms and conditions",
                    },
                    model: {
                      value: _vm.isAgreedToTermsAndCondition,
                      callback: function ($$v) {
                        _vm.isAgreedToTermsAndCondition = $$v
                      },
                      expression: "isAgreedToTermsAndCondition",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mt-3" }),
      _c(
        "v-card-actions",
        { staticClass: "bottom-align" },
        [
          _c("v-spacer"),
          !_vm.isUserRoleContractor
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                  },
                  on: { click: _vm.saveSignature },
                },
                [_vm._v(" save ")]
              )
            : _vm._e(),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: _vm.saveSnackbarTimeout,
                bottom: true,
                left: true,
              },
              model: {
                value: _vm.saveSnackbar,
                callback: function ($$v) {
                  _vm.saveSnackbar = $$v
                },
                expression: "saveSnackbar",
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.saveSnackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", color: "secondary" },
                  nativeOn: {
                    click: function ($event) {
                      _vm.saveSnackbar = false
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }