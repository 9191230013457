var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "heading" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.item.direction == "outbound-api"
                              ? "Outgoing SMS"
                              : "Incoming SMS"
                          ) +
                          " "
                      ),
                      _vm.documentTemplate
                        ? _c("span", { staticClass: "teal--text" }, [
                            _vm._v("(" + _vm._s(_vm.documentTemplate) + ")"),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("v-card-text", [_c("pre", [_vm._v(_vm._s(_vm.item.body))])]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }