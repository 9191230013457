var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-2" }, [
        _c("h4", [_vm._v(_vm._s(_vm.getRequestTitle))]),
      ]),
      _vm.isDeleteComplaintDocument
        ? _c("div", { staticClass: "mb-1" }, [
            _c("label", [_vm._v("Document Name: ")]),
            _c("span", [_vm._v(_vm._s(_vm.getImageId(_vm.item.documentURL)))]),
          ])
        : _vm._l(_vm.item.documentURLs, function (fileURL) {
            return _c("div", { key: fileURL, staticClass: "mb-1" }, [
              _c("label", [_vm._v("Document Name: ")]),
              _c("span", [_vm._v(_vm._s(_vm.getImageId(fileURL)))]),
            ])
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }