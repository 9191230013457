var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.showCardPreview()
        },
      },
    },
    [
      _c("p", { staticClass: "mb-1" }, [
        _vm.unableToCompleteReason
          ? _c("span", [_vm._v(_vm._s(_vm.unableToCompleteReason))])
          : _c("span", [_vm._v(" Your next action is to complete the job ")]),
      ]),
      _c(
        "div",
        { staticClass: "question-title-desc" },
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-0",
              attrs: {
                color: "green white--text",
                large: "",
                ripple: true,
                disabled: !!_vm.unableToCompleteReason,
              },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [_vm._v("work")]),
              _vm._v("    Complete Job "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }