var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-flex", { attrs: { xs12: "" } }, [
    _vm.job
      ? _c(
          "div",
          { staticClass: "bg-white pa-3" },
          [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", { attrs: { xs12: "" } }, [
                  _vm.job && _vm.job.isStarredPolicy
                    ? _c("h3", [_vm._v("Logged as Assumed Cover")])
                    : _vm._e(),
                  _vm.job && !_vm.job.isStarredPolicy
                    ? _c("h3", [
                        _vm._v("Validated against Policy Holder Database"),
                      ])
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mt-2 mb-3" }),
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c("v-flex", { attrs: { lg5: "", xs12: "", "pr-2": "" } }, [
                  _c("div", { staticClass: "prperty-detail pro-address" }, [
                    _vm.job
                      ? _c(
                          "address",
                          {
                            staticClass:
                              "px-3 pt-3 pb-3 grey lighten-4 address",
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "change-ad-btn grey lighten-2",
                                attrs: {
                                  icon: "",
                                  small: "",
                                  disabled: !_vm.showUpdateCustomerDetailButton,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChangeAddressPopup(true)
                                  },
                                },
                              },
                              [
                                _c("v-icon", [_vm._v("edit")]),
                                _vm.job.addressModifiedCount > 0
                                  ? _c("span", { staticClass: "edit-number" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.job.addressModifiedCount) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-icon", { staticClass: "map-icon" }, [
                              _vm._v("place"),
                            ]),
                            _vm._v(" " + _vm._s(_vm.job.clientFullName) + ", "),
                            _vm.job.secondaryClientFullName
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.job.secondaryClientFullName) +
                                      ","
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(_vm.job.getAddress(", ")) + " "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.job.nomineeClientForename &&
                  _vm.job.nomineeClientLastName &&
                  _vm.job.nomineeContactPreference != "" &&
                  (_vm.job.nomineeEmail || _vm.job.nomineeMobilePhone)
                    ? _c(
                        "div",
                        { staticClass: "mb-4 mt-2" },
                        [
                          _c("h3", { staticClass: "mb-3" }, [
                            _vm._v("Nominee Information"),
                          ]),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("div", { staticClass: "mb-1" }, [
                                  _c("b", { staticClass: "fb" }, [
                                    _vm._v("Name:"),
                                  ]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.job.nomineeClientFullName)
                                    ),
                                  ]),
                                ]),
                                _vm.job.relation
                                  ? _c("div", { staticClass: "mb-1" }, [
                                      _c("b", [_vm._v("Relation:")]),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getRelationWithNominee(
                                              _vm.job.relation
                                            )
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.job.nomineeContactPreference == "Email" &&
                                _vm.job.nomineeEmail
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mb-1 primary--text prefrance-contact-icon",
                                      },
                                      [
                                        _c(
                                          "b",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "md-icon",
                                                attrs: { color: "primary" },
                                              },
                                              [_vm._v("mail")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "mailto:" +
                                                _vm.job.nomineeEmail,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "  " +
                                                _vm._s(_vm.job.nomineeEmail)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.job.nomineeContactPreference == "SMS" ||
                                (_vm.job.nomineeContactPreference == "Call" &&
                                  _vm.job.nomineeMobilePhone)
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mb-1 primary--text prefrance-contact-icon",
                                      },
                                      [
                                        _c(
                                          "b",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "md-icon",
                                                attrs: { color: "primary" },
                                              },
                                              [_vm._v("call")]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("span", [
                                          _vm._v(
                                            "  " +
                                              _vm._s(_vm.job.nomineeMobilePhone)
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c("v-flex", { attrs: { lg7: "", xs12: "" } }, [
                  _c(
                    "div",
                    { staticClass: "prperty-detail" },
                    [
                      _vm.job && _vm.job.latitude && _vm.job.longitude
                        ? _c("Map", {
                            staticStyle: { height: "250px" },
                            attrs: {
                              "map-id": new Date().getTime().toString(),
                              "source-latitude": _vm.job.latitude,
                              "source-longitude": _vm.job.longitude,
                              "show-street-view": true,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _vm.job.policyEnquiry
                  ? _c(
                      "v-flex",
                      { staticClass: "mx-3 mb-3 mt-0", attrs: { xs12: "" } },
                      [
                        _c("div", [
                          _c("h4", { staticClass: "mt-2" }, [
                            _vm._v("Category:"),
                          ]),
                          _c("div", [
                            _vm._v(_vm._s(_vm.job.policyEnquiry.category)),
                          ]),
                        ]),
                        _c("div", { staticClass: "mt-3" }, [
                          _c("h4", [_vm._v("Enquiry For:")]),
                          _c("div", [
                            _c("pre", [
                              _vm._v(_vm._s(_vm.job.policyEnquiry.description)),
                            ]),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
                !_vm.job.helplinePolicy &&
                !_vm.job.policyEnquiry &&
                _vm.job.jobType !== "SI"
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", "mt-4": "", "mb-2": "" } },
                      [
                        _c(
                          "h3",
                          { staticClass: "mb-3 emergency-details-header" },
                          [
                            _vm._v(
                              _vm._s(_vm.jobTypeLabel("emergency")) + " Details"
                            ),
                          ]
                        ),
                        _vm._l(_vm.getEmergencyList, function (emergency) {
                          return _c(
                            "div",
                            {
                              key: emergency.id,
                              staticClass: "fnol-emergency-details",
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("span", _vm._g({}, on), [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "img-content",
                                                    },
                                                    [
                                                      emergency.fileURL !==
                                                        null &&
                                                      emergency.fileURL !== ""
                                                        ? _c("img", {
                                                            attrs: {
                                                              src: emergency.fileURL,
                                                            },
                                                          })
                                                        : _c("img", {
                                                            attrs: {
                                                              src: "/img/emergency.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "em-title-content",
                                                    },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            emergency.detailDescription +
                                                              ": "
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              emergency.typeDescription
                                                ? emergency.typeDescription
                                                : emergency.detailDescription
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getFormattedDateTime(
                                          emergency.firstNoticedAt
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm.job.jobType === "US" && emergency.note
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-1 input-notes" },
                                        [
                                          _c("pre", [
                                            _c("b", [_vm._v("Notes: ")]),
                                            _vm._v(_vm._s(emergency.note)),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.showRemoveEmergencyButton
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "ml-2 em-delete",
                                                          attrs: {
                                                            color: "secondary",
                                                            dark: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openConfirmationDialogToRemoveEmergency(
                                                                emergency.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [_vm._v(" delete ")]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Cancel Emergency"),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.job.removedEmergencies &&
                _vm.job.removedEmergencies.length > 0
                  ? _c("v-divider", { staticClass: "pa-1" })
                  : _vm._e(),
                _vm.job.removedEmergencies &&
                _vm.job.removedEmergencies.length > 0
                  ? _c(
                      "v-flex",
                      { staticClass: "mb-2", attrs: { xs12: "" } },
                      [
                        _c("h3", { staticClass: "mb-2 secondary--text" }, [
                          _vm._v("Cancelled Emergencies"),
                        ]),
                        _vm._l(
                          _vm.job.removedEmergencies,
                          function (emergency) {
                            return _c(
                              "div",
                              {
                                key: emergency.emergencyId,
                                staticClass:
                                  "grey lighten-4 pa-2 mb-1 remove-emergency-details",
                              },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c("span", _vm._g({}, on), [
                                                _c(
                                                  "i",
                                                  {
                                                    staticClass: "img-content",
                                                  },
                                                  [
                                                    _vm.getEmergencyFileURL(
                                                      emergency.typeId
                                                    )
                                                      ? _c("img", {
                                                          attrs: {
                                                            src: _vm.getEmergencyFileURL(
                                                              emergency.typeId
                                                            ),
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: "/img/emergency.svg",
                                                            alt: "",
                                                          },
                                                        }),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "em-title-content",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          emergency.detailDescription
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(emergency.typeDescription)),
                                    ]),
                                  ]
                                ),
                                _c("v-divider", { staticClass: "mt-1" }),
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "mt-1",
                                    attrs: { row: "", wrap: "" },
                                  },
                                  [
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("span", { staticClass: "pr-2" }, [
                                        _c("b", [_vm._v("Reason:")]),
                                      ]),
                                      _c("span", { staticClass: "mb-0" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getRemoveEmergencyReason(
                                                emergency.reason
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("v-flex", { attrs: { xs12: "" } }, [
                                      _c("span", { staticClass: "pr-2" }, [
                                        _c("b", [_vm._v("Description:")]),
                                      ]),
                                      _c("span", {}, [
                                        _vm._v(_vm._s(emergency.description)),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.job.jobType === "US" && _vm.job.linkedJobId
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-2": "", "mt-2": "" } },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Linked Job:")]),
                          _vm._v(" " + _vm._s(_vm.job.linkedJobId) + " "),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.job.jobType === "SI" && _vm.job.sIJobDetail
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", "mb-2": "", "mt-2": "" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "grey lighten-4 pa-2 mb-1 remove-emergency-details",
                          },
                          [
                            _c("h3", [
                              _c("b", [
                                _vm._v("Claim Management Company Details"),
                              ]),
                            ]),
                            _c("v-divider", { staticClass: "mt-1 mb-1" }),
                            _vm.job.sIJobDetail.claimManagementCompany
                              ? _c("div", { staticClass: "mb-1" }, [
                                  _c("b", [_vm._v("Name:")]),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.job.sIJobDetail
                                          .claimManagementCompany
                                      )
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.job.sIJobDetail
                              .claimManagementCompanyMainContact
                              ? _c("div", { staticClass: "mb-1" }, [
                                  _c("b", [_vm._v("Main Contact:")]),
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.job.sIJobDetail
                                            .claimManagementCompanyMainContact
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm.job.sIJobDetail.claimManagementCompanyPhone
                              ? _c("div", { staticClass: "mb-1" }, [
                                  _c("b", [_vm._v("Phone:")]),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "javascript:void(0)",
                                        name: "companyPhoneNumber",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onPhoneNumberClick(
                                            _vm.job.sIJobDetail
                                              .claimManagementCompanyPhone
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.job.sIJobDetail
                                              .claimManagementCompanyPhone
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm.job.sIJobDetail.claimManagementCompanyEmail
                              ? _c("div", { staticClass: "mb-1" }, [
                                  _c("b", [_vm._v("Email:")]),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "mailto:" +
                                          _vm.job.sIJobDetail
                                            .claimManagementCompanyEmail,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.job.sIJobDetail
                                              .claimManagementCompanyEmail
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                (_vm.job.jobType === "SI" &&
                  !_vm.siJobDelay &&
                  !_vm.job.isTestingOnlyJob) ||
                (_vm.job.jobType === "US" && !_vm.usJobDelay)
                  ? _c(
                      "v-flex",
                      { staticClass: "text-xs-right", attrs: { xs12: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-addDelay mr-0",
                            attrs: { color: "primary" },
                            on: { click: _vm.addJobDelayCard },
                          },
                          [_vm._v("Add Delay")]
                        ),
                        _c("v-divider"),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isUploadDocumentAllowed
                  ? _c(
                      "v-flex",
                      [
                        _c("DocumentList", {
                          attrs: {
                            "job-id": _vm.jobId,
                            "job-type": _vm.job.jobType,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.job &&
                _vm.canPlayCall &&
                (_vm.job.fnolCallRecordingUrl || _vm.job.fnolCallSid)
                  ? _c(
                      "v-flex",
                      {
                        staticClass: "mb-2",
                        class:
                          !_vm.job.helplinePolicy && !_vm.job.policyEnquiry
                            ? "mt-0"
                            : "mt-4",
                        attrs: { xs12: "" },
                      },
                      [
                        _c("v-divider"),
                        _c("LinkedPhoneCallDetails", {
                          attrs: {
                            "recording-url": _vm.job.fnolCallRecordingUrl,
                            "call-sid": _vm.job.fnolCallSid,
                            "job-id": _vm.job.jobId,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "client-detail text-xs-right" },
                          [
                            _vm.job &&
                            _vm.job.loggedByUserId &&
                            _vm.job.loggedByUserName
                              ? _c(
                                  "em",
                                  [
                                    _vm._v(
                                      " Logged by " +
                                        _vm._s(_vm.job.loggedByUserName) +
                                        "   "
                                    ),
                                    _c("v-avatar", { staticClass: "mr-2" }, [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.job.loggedByUser
                                            .profileThumbnailUrlWithUnknownFallback,
                                          alt: "Logged user",
                                        },
                                      }),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm.job && _vm.job.loggedVia
                              ? _c("em", [
                                  _vm._v(
                                    "Logged via " + _vm._s(_vm.job.loggedVia)
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.changeAddress
              ? _c(
                  "v-dialog",
                  {
                    staticStyle: { position: "absolute" },
                    attrs: {
                      "max-width": "800",
                      persistent: "",
                      "content-class": "v-dialog--scrollable",
                    },
                    model: {
                      value: _vm.changeAddress,
                      callback: function ($$v) {
                        _vm.changeAddress = $$v
                      },
                      expression: "changeAddress",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { card: "", dark: "", color: "primary" } },
                          [
                            _c("v-toolbar-title", [
                              _vm._v("Update Client Information"),
                            ]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChangeAddressPopup(false)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          { staticClass: "px-3 scroll-content-dialog" },
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("CustomerAddress", {
                                      ref: "addCustomerAddress",
                                      attrs: {
                                        "passed-client-title":
                                          _vm.getClientTitle,
                                        "passed-first-name": _vm.getFirstName,
                                        "passed-last-name": _vm.getLastName,
                                        "passed-secondary-client-title":
                                          _vm.getSecondaryClientTitle,
                                        "passed-secondary-first-name":
                                          _vm.getSecondaryFirstName,
                                        "passed-secondary-last-name":
                                          _vm.getSecondaryLastName,
                                        "passed-address-line1":
                                          _vm.getAddressLine1,
                                        "passed-address-line2":
                                          _vm.getAddressLine2,
                                        "passed-address-line3":
                                          _vm.getAddressLine3,
                                        "passed-address-line4":
                                          _vm.getAddressLine4,
                                        "passed-post-code": _vm.getPostCode,
                                        "is-display-policy-number": false,
                                        "is-s-i-job": _vm.job.jobType === "SI",
                                      },
                                      on: {
                                        "update:passedClientTitle": function (
                                          $event
                                        ) {
                                          _vm.getClientTitle = $event
                                        },
                                        "update:passed-client-title": function (
                                          $event
                                        ) {
                                          _vm.getClientTitle = $event
                                        },
                                        "update:passedFirstName": function (
                                          $event
                                        ) {
                                          _vm.getFirstName = $event
                                        },
                                        "update:passed-first-name": function (
                                          $event
                                        ) {
                                          _vm.getFirstName = $event
                                        },
                                        "update:passedLastName": function (
                                          $event
                                        ) {
                                          _vm.getLastName = $event
                                        },
                                        "update:passed-last-name": function (
                                          $event
                                        ) {
                                          _vm.getLastName = $event
                                        },
                                        "update:passedSecondaryClientTitle":
                                          function ($event) {
                                            _vm.getSecondaryClientTitle = $event
                                          },
                                        "update:passed-secondary-client-title":
                                          function ($event) {
                                            _vm.getSecondaryClientTitle = $event
                                          },
                                        "update:passedSecondaryFirstName":
                                          function ($event) {
                                            _vm.getSecondaryFirstName = $event
                                          },
                                        "update:passed-secondary-first-name":
                                          function ($event) {
                                            _vm.getSecondaryFirstName = $event
                                          },
                                        "update:passedSecondaryLastName":
                                          function ($event) {
                                            _vm.getSecondaryLastName = $event
                                          },
                                        "update:passed-secondary-last-name":
                                          function ($event) {
                                            _vm.getSecondaryLastName = $event
                                          },
                                        "update:passedAddressLine1": function (
                                          $event
                                        ) {
                                          _vm.getAddressLine1 = $event
                                        },
                                        "update:passed-address-line1":
                                          function ($event) {
                                            _vm.getAddressLine1 = $event
                                          },
                                        "update:passedAddressLine2": function (
                                          $event
                                        ) {
                                          _vm.getAddressLine2 = $event
                                        },
                                        "update:passed-address-line2":
                                          function ($event) {
                                            _vm.getAddressLine2 = $event
                                          },
                                        "update:passedAddressLine3": function (
                                          $event
                                        ) {
                                          _vm.getAddressLine3 = $event
                                        },
                                        "update:passed-address-line3":
                                          function ($event) {
                                            _vm.getAddressLine3 = $event
                                          },
                                        "update:passedAddressLine4": function (
                                          $event
                                        ) {
                                          _vm.getAddressLine4 = $event
                                        },
                                        "update:passed-address-line4":
                                          function ($event) {
                                            _vm.getAddressLine4 = $event
                                          },
                                        "update:passedPostCode": function (
                                          $event
                                        ) {
                                          _vm.getPostCode = $event
                                        },
                                        "update:passed-post-code": function (
                                          $event
                                        ) {
                                          _vm.getPostCode = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.job.jobType === "SI"
                              ? _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-text-field", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          ref: "clientReferenceRef",
                                          staticClass: "required",
                                          attrs: {
                                            label: "Client Reference",
                                            required: "",
                                            name: "ClientReference",
                                            "data-vv-name": "Client Reference",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Client Reference"
                                              ),
                                          },
                                          model: {
                                            value: _vm.customerRef,
                                            callback: function ($$v) {
                                              _vm.customerRef = $$v
                                            },
                                            expression: "customerRef",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          { staticClass: "px-3" },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "close-btn",
                                attrs: { color: "primary", flat: "flat" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChangeAddressPopup(false)
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-0 save-btn",
                                attrs: {
                                  color: "primary",
                                  disabled: _vm.isLoading,
                                  loading: _vm.isLoading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveAddress()
                                  },
                                },
                              },
                              [_vm._v(" Submit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.removeEmergencyDialog
              ? _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "650",
                      "content-class": "v-dialog--scrollable",
                      persistent: "",
                    },
                    model: {
                      value: _vm.removeEmergencyDialog,
                      callback: function ($$v) {
                        _vm.removeEmergencyDialog = $$v
                      },
                      expression: "removeEmergencyDialog",
                    },
                  },
                  [
                    _c(
                      "v-card",
                      [
                        _c(
                          "v-toolbar",
                          { attrs: { card: "", dark: "", color: "primary" } },
                          [
                            _c("v-toolbar-title", [_vm._v("Remove Emergency")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.removeEmergencyDialog = false
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("close")])],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-text",
                          { staticClass: "scroll-content-dialog px-3" },
                          [
                            _c(
                              "v-form",
                              [
                                _c(
                                  "v-layout",
                                  { attrs: { row: "", wrap: "" } },
                                  [
                                    _c(
                                      "v-flex",
                                      [
                                        _c("v-select", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            items:
                                              _vm.removeEmergencyReasonList,
                                            "item-text": "description",
                                            "item-value": "id",
                                            label:
                                              "What is the reason for removing this emergency?",
                                            required: "",
                                            loading: _vm.isLoading,
                                            "data-vv-scope":
                                              "frmRemoveEmergency",
                                            "data-vv-name":
                                              "Emergency Remove Reason",
                                            "error-messages":
                                              _vm.errors.collect(
                                                "Emergency Remove Reason"
                                              ),
                                          },
                                          on: {
                                            change:
                                              _vm.onRemoveEmergencyReasonChange,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function (data) {
                                                  return [
                                                    _c(
                                                      "v-list-tile",
                                                      [
                                                        _c(
                                                          "v-list-tile-content",
                                                          [
                                                            _c(
                                                              "v-list-tile-title",
                                                              {
                                                                class:
                                                                  data.item
                                                                    .description ===
                                                                  "Configure"
                                                                    ? "bold-select"
                                                                    : "",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.item
                                                                        .description
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3659597833
                                          ),
                                          model: {
                                            value:
                                              _vm.removeEmergencyFromJobModel
                                                .reason,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.removeEmergencyFromJobModel,
                                                "reason",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "removeEmergencyFromJobModel.reason",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs12: "" } },
                                      [
                                        _c("v-textarea", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: "required",
                                              expression: "'required'",
                                            },
                                          ],
                                          staticClass: "required",
                                          attrs: {
                                            label: "Description",
                                            required: "",
                                            "data-vv-scope":
                                              "frmRemoveEmergency",
                                            "data-vv-name": "Description",
                                            "error-messages":
                                              _vm.errors.collect("Description"),
                                          },
                                          model: {
                                            value:
                                              _vm.removeEmergencyFromJobModel
                                                .description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.removeEmergencyFromJobModel,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "removeEmergencyFromJobModel.description",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-card-actions",
                          { staticClass: "px-3" },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", flat: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.removeEmergencyDialog = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-0 btn-removeEmergency",
                                attrs: {
                                  color: "primary",
                                  loading: _vm.removeEmergencyLoading,
                                  disabled: _vm.removeEmergencyLoading,
                                },
                                on: { click: _vm.removeEmergency },
                              },
                              [_vm._v(" Submit ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.configureDropdownDialog
              ? _c(
                  "v-dialog",
                  {
                    attrs: {
                      "max-width": "700",
                      persistent: "",
                      "content-class": "v-dialog--scrollable",
                    },
                    model: {
                      value: _vm.configureDropdownDialog,
                      callback: function ($$v) {
                        _vm.configureDropdownDialog = $$v
                      },
                      expression: "configureDropdownDialog",
                    },
                  },
                  [
                    _c("ConfigureDropdown", {
                      attrs: { "record-type": _vm.configureDropdownType },
                      on: {
                        CloseConfigureDropdownDialog:
                          _vm.onConfigureDropdownDialogClose,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-snackbar",
              {
                attrs: { timeout: _vm.snackbarTimeout, left: "", bottom: "" },
                model: {
                  value: _vm.snackbar,
                  callback: function ($$v) {
                    _vm.snackbar = $$v
                  },
                  expression: "snackbar",
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
                _c(
                  "v-btn",
                  {
                    attrs: { dark: "", flat: "", color: "secondary" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.snackbar = false
                      },
                    },
                  },
                  [_vm._v("close")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }