var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.financialSummary
    ? _c(
        "div",
        { staticClass: "question-title-desc" },
        [
          _c("div", { staticClass: "small-text" }, [
            _vm._v(
              " Job will be invoiced at £" +
                _vm._s(
                  _vm.financialSummary.invoice
                    ? _vm.financialSummary.invoice.totalCost
                    : 0
                ) +
                " "
            ),
          ]),
          _c("div", { staticClass: "small-text" }, [
            _vm._v(
              " Job will cost £" +
                _vm._s(
                  _vm.financialSummary.purchase
                    ? _vm.financialSummary.purchase.totalCost
                    : 0
                ) +
                " "
            ),
          ]),
          _c(
            "v-layout",
            { staticClass: "mt-2", attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { lg7: "", xs12: "" } }, [
                _c("div", { staticClass: "small-text text-gray" }, [
                  _vm._v("Awaiting PO and Invoice"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }