var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "td",
    { class: _vm.letterType },
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      { attrs: { dark: "", color: _vm.letterStatus.color } },
                      on
                    ),
                    [_vm._v(" " + _vm._s(_vm.letterStatus.icon) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("span", [
            _vm._v(
              " Required by " +
                _vm._s(_vm.getFormattedDate(_vm.letterDate.due)) +
                " "
            ),
            _c("br"),
            _vm.letterDate.sent && _vm.letterDate.sent.isValid()
              ? _c("span", [
                  _vm._v(
                    " Sent on " +
                      _vm._s(_vm.getFormattedDate(_vm.letterDate.sent)) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }