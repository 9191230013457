var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _c(
        "v-card",
        { staticClass: "pt-0 px-2 pb-2 reopen-job-card" },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-layout",
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("h3", { staticClass: "heading" }, [
                      _c("span", { staticClass: "teal--text" }, [
                        _vm._v("Job Reopen Reason Detail"),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required reopen-reason-list",
                        attrs: {
                          items: _vm.reopenReasonTypeList,
                          "item-text": "description",
                          "item-value": "id",
                          label: "Reason For Reopen",
                          required: "",
                          "data-vv-scope": "frmReopenReason",
                          "data-vv-name": "Reason",
                          "error-messages": _vm.errors.collect("Reason"),
                        },
                        on: { change: _vm.onReasonTypeChange },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-tile",
                                  [
                                    _c(
                                      "v-list-tile-content",
                                      [
                                        _c(
                                          "v-list-tile-title",
                                          {
                                            class:
                                              data.item.description ===
                                              "Configure"
                                                ? "bold-select"
                                                : "",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.description) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.reopenReasonId,
                          callback: function ($$v) {
                            _vm.reopenReasonId = $$v
                          },
                          expression: "reopenReasonId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "required",
                        attrs: {
                          label: "Description",
                          rows: "5",
                          name: "Description",
                          required: "",
                          "data-vv-scope": "frmReopenReason",
                          "data-vv-name": "Description",
                          "error-messages": _vm.errors.collect("Description"),
                        },
                        model: {
                          value: _vm.reopenDescription,
                          callback: function ($$v) {
                            _vm.reopenDescription =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "reopenDescription",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c(
                      "div",
                      { staticClass: "text-xs-right" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-0 btn-submit",
                            attrs: {
                              color: "primary",
                              disabled: _vm.isLoading,
                              loading: _vm.isLoading,
                            },
                            on: { click: _vm.reopenJob },
                          },
                          [_vm._v(" Submit ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.configureDropdownDialog
        ? _c(
            "v-dialog",
            {
              attrs: {
                "max-width": "700",
                persistent: "",
                "content-class": "v-dialog--scrollable",
              },
              model: {
                value: _vm.configureDropdownDialog,
                callback: function ($$v) {
                  _vm.configureDropdownDialog = $$v
                },
                expression: "configureDropdownDialog",
              },
            },
            [
              _c("ConfigureDropdown", {
                ref: "refConfigureDropdownDialog",
                attrs: { "record-type": _vm.configureDropdownType },
                on: {
                  CloseConfigureDropdownDialog:
                    _vm.onConfigureDropdownDialogClose,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }