var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { attrs: { wrap: "" } },
    [
      _vm.items
        ? _c("v-flex", [
            _c("div", {
              staticClass: "small-text item-description",
              domProps: {
                innerHTML: _vm._s(_vm.sanitize(_vm.items.description)),
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }