var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.item
    ? _c("div", [
        _c("div", { staticClass: "mb-2" }, [
          _c("h4", [
            _vm._v(
              _vm._s(
                _vm.item.id ? "Update Drainage Report" : "Add Drainage Report"
              )
            ),
          ]),
        ]),
        _c("div", [
          _vm.item.name
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Name: ")]),
                _c("span", [_vm._v(_vm._s(_vm.item.name))]),
              ])
            : _vm._e(),
          _vm.item.updatedAt
            ? _c("div", { staticClass: "mb-1" }, [
                _c("label", [_vm._v("Updated At: ")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.getFormattedDate(_vm.item.updatedAt))),
                ]),
              ])
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }